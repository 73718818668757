import { useEffect, useMemo, useState } from "react";
import { Col, Container, Form, FormControl, FormGroup, InputGroup, Row } from "react-bootstrap";
import { FaChild, FaSearch, FaTrash, FaUserPlus } from "react-icons/fa";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { MdPerson } from "react-icons/md";

import { useTranslateContext } from "../../contexts/translate.context";

import TableContainer from "../../components/table-container.component";
import { calculateAge, dateToString, toString, toLowerCase, maxNumber, toInt, inArray } from "../../utilities/auxiliary-functions";
import { Phones } from "../../components/sub-render.component";
import { isEmpty } from "../../utilities/validators";
import { getCategoriesNaval, getGender, VesselStatusTypes } from "../../utilities/types";
import { VESSEL_JETSKI } from "../../utilities/constants";
import { Box, Button, Tab, Tabs, createTheme, TextareaAutosize } from "@mui/material";
import { BsSliders } from "react-icons/bs";
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import { ThemeProvider } from '@mui/material/styles';
import { bgBG as corePTBr } from '@mui/material/locale';
import { ptBR as ptBRMaterial } from '@mui/x-data-grid';
import styles from './movement-vessel.record.module.scss'
import { v4 as uuid } from "uuid";

export interface IMovementVesselRecordProps {
    title: any,
    isEdit: boolean,
    hasRecord: boolean,

    id?: any,
    created?: any,
    updated?: any,
    dateMovement?: any,
    enrollment?: any,
    vesselName?: any,
    departure?: any,
    estimated?: any,
    forecast?: any,
    status?: any,
    arrival?: any,
    indInformPassenger?: any,
    indResponsibleAware?: any,
    quantityPeople?: any,
    quantityChildren?: any,
    vessel?: any,
    crews?: any,
    accessories?: any,
    passengers?: any,
    navigationPlan?: any,

    setCreated?: any,
    setUpdated?: any
    setDateMovement?: any,
    setEnrollment?: any,
    setDeparture?: any,
    setEstimated?: any,
    setForecast?: any,
    setStatus?: any,
    setArrival?: any,
    setIndInformPassenger?: any,
    setIndResponsibleAware?: any,
    setQuantityPeople?: any,
    setQuantityChildren?: any,
    setVessel?: any,
    setAccessories?: any,
    setCrews?: any,
    setPassengers?: any,
    setNavigationPlan?: any,

    calculateEstimated?: any,
    searchVessel?: any,
    addCrew?: any,
    deleteCrew?: any,
    addPassenger?: any,
    deletePassenger?: any,
    updateAccessoryItem?: any
    showFilterVesselModal?: any
    showSelectReservationModal?: any
    shared?: any
}

const theme = createTheme(
    {
        palette: {
            primary: {
                main: '#3366FF'
            },
            secondary: {
                main: '#FFFFFF'
            },
            error: {
                main: '#D32E2E'
            },
            warning: {
                main: '#FFC000'
            },
            mode: 'dark'
        },
        typography: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            button: {
                textTransform: 'none'
            }
        },
        components: {
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        backgroundColor: 'white',
                        color: 'rgba(0, 0, 0, 0.87)',
                        fontSize: 14,
                        textAlign: 'center'
                    }
                }
            }
        },
    
    }, 
    ptBRMaterial, 
    corePTBr
)

const originsOptions = [
    { label: 'Nacional', value: 'nacional' },
    { label: 'Importado', value: 'importado' }
]

const MenuProps = {
    PaperProps: {
        style: {
            width: 225,
            maxHeight: 500
        }
    }
}

export const MovementVesselRecord: React.FC<IMovementVesselRecordProps> = (props: IMovementVesselRecordProps) => {

    const { display } = useTranslateContext();

    const [ equipmentGroups, setEquipmentGroups ] = useState([] as any);
    const [ mandatoryGroup, setMandatoryGroup ] = useState([] as any);
    const [ additionalSalvageGroup, setAdditionalSalvageGroup ] = useState([] as any);
    const [ accessoriesGroup, setAccessoriesGroup ] = useState([] as any);
    const [ otherGroup, setOtherGroup ] = useState([] as any);
    const [ activeTab, setActiveTab ] = useState(0)
    const [ occurrence, setOccurrence] = useState<string>('')
    const [ otherItem, setOtherItem ] = useState({
        id: uuid(),
        group : 4,
        name : 'Outro',
        category : '',
        description : '',
        brand : '',
        model : '',
        origin : '',
        checked: true,
        unity : '',
        quantity : 1,
        amount : '',
        order : 0
    })

    function renderSection(children: any) {
        return (
            <div className={`${styles.acessoriesSection}`}>{children}</div>
        );
    }
    
    function renderGroup(equipaments: any[]) {
        let divOut: any;
        let divData = [] as any;
        let divSecion = [] as any;
    
        for (let ind = 0; ind < equipaments.length; ind++) {
            divData.push(renderSectionData( equipaments[ind] ));
                
            if (inArray(ind + 1, [3, 6, 9, 12, 15]) > -1) {
                divSecion.push(renderSection(divData));
                divData = [] as any;
            }
            if (ind === equipaments.length - 1 && divData.length > 0) {
                divSecion.push(renderSection(divData));
            }
        }
        divOut = divSecion;
        return ( divOut );
    }

    function renderSectionData(equipament: any) {

        return (
            <div className="d-flex flex-column">
                {equipament.checked && (
                <div className={`d-flex justify-content-between`}>
                    <p>{equipament.description}</p>
                    <p className="mb-0">{equipament.quantity}</p>
                </div>
                )}
            </div>
        );
            
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue)
    }

    useEffect(() => {
        if (props?.vessel?.equipments?.length > 0) {
            const groups = [] as any;
            props.vessel.equipments.forEach((equipment: any) => { 
                const exist = groups.find((group: any) => { return group.id === equipment.group })
                if (!exist)
                    groups.push({ id: equipment.group, name: equipment.name });
            });

            const exist = groups.find((group: any) => { return group.id === 4 })
            if (!exist)
                groups.push({ id: 4, name: 'Outro' });
            setEquipmentGroups(groups);
            groups.sort(( objA: any, objB: any ) => (objA.id > objB.id) ? 1 : ((objB.id > objA.id) ? -1 : 0));

            setMandatoryGroup(props.vessel.equipments.filter((equipment: any) => equipment.group === 1));
            setAdditionalSalvageGroup(props.vessel.equipments.filter((equipment: any) => equipment.group === 2));
            setAccessoriesGroup(props.vessel.equipments.filter((equipment: any) => equipment.group === 3));
            setOtherGroup(props.vessel.equipments.filter((equipment: any) => equipment.group === 4));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ props.vessel.equipments ]);
    
    const handleFilterVesselModal = (e: any) => {
        props.showFilterVesselModal(e)
    }

    const handleSelectReservationModal = (e: any) => {
        props.showSelectReservationModal(e)
    }

    const columnsCrew = useMemo(() => [{
        Header: 'Nome',
        accessor: "conductor.people.fullname"
    },{
        Header: display.label.registration,
        Cell: (props: any) => {
            const movementRow = props.row.original;

            if (movementRow.conductor.people.documentsNautical.length > 0) {
                const enrollment =  movementRow.conductor.people.documentsNautical[0].enrollment;

                return (
                    <div className="white-space">{enrollment}</div>
                );
            }
        }
    },{
            Header: display.label.category,
            Cell: (props: any) => {
                const movementRow = props.row.original;

                if (movementRow.conductor.people.documentsNautical.length > 0) {
                    const category =  movementRow.conductor.people.documentsNautical[0].category;
                    if (category) {
                        const categories = category.split(';');
                        return (
                            <>
                                {categories.map((category: any, idx: number) => (
                                    <div key={idx} className="white-space">{display.label[toLowerCase(getCategoriesNaval(category).name)]}</div>
                                ))}
                            </>
                        );
                    }
                }
            }
        },{


            Header: display.label.validity,
            Cell: (props: any) => {
                const movementRow = props.row.original;
                if (movementRow.conductor.people.documentsNautical.length > 0) {
                    const validity = movementRow.conductor.people.documentsNautical[0].validity;
    
                    return (
                        <div className="white-space">{dateToString(validity, 'dd/mm/yyyy')}</div>
                    );
                }
            }
        },{
            Header: display.legend.actions,
            accessor: "actions",
            Cell: (props: any) => {
                let crewRows = [] as any;
                const originals = props.rows;
                for (var o = 0; o < originals.length; o++) {
                    crewRows[crewRows.length] = originals[o].original;
                }
                const crewRow = props.row.original;

                return (
                    <div className="white-space table-actions">
                        <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.delete}</Tooltip>}>
                        <span onClick={(e) => onClick_DeleteCrew(e, crewRows, crewRow)}>
                            <FaTrash size={18} />
                        </span>
                        </OverlayTrigger>
                    </div>
                );
            },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }],[]);
 
    const columnsPassenger = useMemo(() => [
        {
            Header: display.label.fullname,
            accessor: 'people.fullname',
            Cell: (row: any) => (<div className="white-space">{row.value}</div>)
        }, {
            Header: display.label.gender,
            accessor: "people.gender",
            Cell: (row: any) => (<div className="white-space">{display.label[toLowerCase(getGender(row.value).name)]}</div>)
        },{
            Header: display.label.age,
            accessor: 'people.birth',
            Cell: (row: any) => (<div className="white-space">{calculateAge(new Date(row.value))}</div>)
        },{
            Header: display.label.telephone,
            accessor: 'people.phones',
            Cell: (row: any) => (<div className="white-space">{<Phones values={row.value} />}</div>)
        },{
            Header: display.legend.actions,
            accessor: "actions",
            Cell: (props: any) => {
                let passengerRows = [] as any;
                const originals = props.rows;
                for (var o = 0; o < originals.length; o++) {
                    passengerRows[passengerRows.length] = originals[o].original;
                }
                const passengerRow = props.row.original;

                return (
                    <div className="white-space table-actions">
                        <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.delete}</Tooltip>}>
                        <span onClick={(e) => onClick_DeletePassenger(e, passengerRows, passengerRow)}>
                            <FaTrash size={18} />
                        </span>
                        </OverlayTrigger>
                    </div>
                );
            },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }],[]);

    function onClick_SearchVessel(event: any) {
        event.preventDefault();
        props.searchVessel(event);
    }

    function onClick_AddCrew(event: any) {
        event.preventDefault();
        props.addCrew(event);
    }

    function onClick_DeleteCrew(event: any, crews: any, crew: any) {
        event.preventDefault();
        props.deleteCrew(event, crews, crew);
    }

    function onClick_AddPassenger(event: any) {
        event.preventDefault();
        props.addPassenger(event);
    }
    
    function onClick_DeletePassenger(event: any, passengers: any, passenger: any) {
        event.preventDefault();
        props.deletePassenger(event, passengers, passenger);
    }

    function onChange_UpdateAccessoryItem(event: any, position: number, field: string, item: string) {
        event.preventDefault();
        props.updateAccessoryItem(position, field, item);
    }

    function checkInformPassenger(event: any) {
        props.setIndInformPassenger(!props.indInformPassenger);
        if (!props.indInformPassenger) {
            props.setIndResponsibleAware(false);
            props.setQuantityPeople(0);
            props.setQuantityChildren(0);
            props.setPassengers([]);
        }
    }

    function TabPanel(props: TabProps) {
        const { children, value, index, ...other } = props
        
        return (
            <div
                role="tab"
                hidden={value !== index}
                id={`full-width-tab-${index}`}
                aria-labelledby={`full-width-tab-${index}`}
                {...other}
            >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
            </div>
        )
    }
        
    function aProps(index: number) {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tab-${index}`
        }
    }
    
    interface TabProps {
        dir?: string
        index: number
        children?: React.ReactNode
        value: number
    }

    return (
        <>
            <Row style={{maxHeight: '80vh', overflowY: 'scroll'}}>
                <Col sm="12">
                    <fieldset key={0}>
                    <div className="d-flex align-items-center justify-content-start mt-2">

                        <legend style={{width: 'fit-content'}}>{display.legend.general_data}</legend>
                        {(!props.isEdit) && (
                            <div className="float-right">
                                    <Button className="mb-2 ms-3" size="small" variant="contained" color="secondary" onClick={ (e: any) => handleFilterVesselModal(e)  }>
                                        {display.legend.search_vessel} <BsSliders className="ms-3" />
                                    </Button>
                            </div>
                        ) }
                        </div>
                        <Row>
                            <Col sm="3">
                                <Form.Label htmlFor="form-enrollment">{display.label.enrollment}</Form.Label>
                                <InputGroup className="mb-2">
                                    <FormControl id="form-enrollment" name="enrollment" placeholder={display.example.enrollment}
                                        value={toString(props.enrollment)}
                                        onChange={ e => props.setEnrollment(e.target.value) }
                                        readOnly={!props.hasRecord}
                                    />
                                    <InputGroup.Text>
                                        <a href="/#" onClick={(e) => onClick_SearchVessel(e)}>
                                            <FaSearch />
                                        </a>
                                    </InputGroup.Text>
                                </InputGroup>
                            </Col>
                            <Col sm="9">
                                <Form.Label htmlFor="form-id-vessel-name">{display.label.vessel}</Form.Label>
                                <FormControl id="form-vessel-name" name="vesselName" placeholder={display.example.vessel_name}
                                    defaultValue={props.vesselName}
                                    readOnly />
                            </Col>
                        </Row>
                        <div className="d-flex align-items-center justify-content-start mt-2">

                        <legend style={{width: 'fit-content'}}>Dados do Agendamento</legend>
                        {(!props.isEdit) && (
                            <div className="float-right">
                                <Button className="mb-2 ms-3" size="small" variant="contained" color="secondary" onClick={ (e: any) => handleSelectReservationModal(e)  }>
                                    Horários Disponíveis <QueryBuilderIcon className="ms-3" />
                                </Button>
                            </div>
                        ) }
                        </div>
                        <Row>
                            <Col sm="3">
                                <FormGroup>
                                    <Form.Label htmlFor="form-forecast">{display.label.date_movement}</Form.Label>
                                    <FormControl id="form-forecast" name="forecast" placeholder="DD/MM/AAAA" required
                                        maxLength={5}
                                        value={toString(props.dateMovement)}
                                        disabled
                                        readOnly />
                                </FormGroup>
                            </Col>
                            <Col sm="3">
                                <FormGroup>
                                    <Form.Label htmlFor="form-departure">{display.label.output_forecast}</Form.Label>
                                    <FormControl id="form-departure" name="departure" placeholder={display.example.output_forecast} required
                                        maxLength={5}
                                        value={toString(props.departure)}
                                        disabled
                                        readOnly />
                                </FormGroup>
                            </Col>
                            <Col sm="3">
                                <FormGroup>
                                    <Form.Label htmlFor="form-forecast">{display.label.arrival_forecast}</Form.Label>
                                    <FormControl id="form-forecast" name="forecast" placeholder={display.example.arrival_forecast} required
                                        maxLength={5}
                                        value={toString(props.forecast)}
                                        disabled
                                        readOnly />
                                </FormGroup>
                            </Col>
                            <Col sm="3">
                                <FormGroup>
                                    <Form.Label htmlFor="form-status">{display.label.status}</Form.Label>
                                    <Form.Select id="form-status" name="status" required
                                        value={toString(props.status)}
                                        onChange={(e: any) => props.setStatus(e.target.value)}
                                        disabled={!props.isEdit && props.hasRecord} 
                                    >
                                        { isEmpty(props.status) ? (
                                            <option value="">...</option>
                                        ) : null }
                                        { VesselStatusTypes.map((iterator: any, idx: number) => (<option key={idx} value={iterator.id}>{iterator.description}</option>)) }
                                    </Form.Select>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12">
                                <FormGroup>
                                    <Form.Label htmlFor="form-navigation-plan">{display.label.navigation_plan}</Form.Label>
                                    <Form.Control as="textarea" rows={3} type="" id="form-navigation-plan" name="navigationPlan" 
                                        placeholder="Digite o seu plano de navegação (não obrigatório para moto aquática)"
                                        required={props.vessel && toInt(props.vessel.typeVessel) !== VESSEL_JETSKI}
                                        value={toString(props.navigationPlan)}
                                        onChange={e => props.setNavigationPlan(e.target.value)}
                                        // readOnly={!props.isEdit && props.hasRecord}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        {/*
                        <div className="d-flex justify-content-end mt-3">
                            <Button variant="contained" color="primary" 
                                disabled={props.navigationPlan.length < 20}
                            ><SaveIcon className="me-2" /> Salvar</Button>
                        </div>
                        */}
                    </fieldset>
                    {props.enrollment && (
                        <>
                        
                        <fieldset key={1}>
                            <legend>Tripulação (Obrigatório)</legend>
                            <Row>
                                <Col sm="12">
                                    {!props.hasRecord &&
                                    <div className="card-actions float-right">
                                    <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.add} {display.tooltips.conductor}</Tooltip>}>
                                        <a href="/#" onClick={(e) => onClick_AddCrew(e) }>
                                            <FaUserPlus size={18} />
                                        </a>
                                    </OverlayTrigger>    
                                    </div>
                                    }
                                    <TableContainer columns={columnsCrew} data={props.crews} />
                                </Col>
                            </Row>
                        </fieldset>
                        {(toInt(props.vessel.typeVessel) !== VESSEL_JETSKI) && 
                        <>
                        <fieldset key={2}>
                            <Row>
                                <Col sm="5">
                                    <div className="react-switch">
                                        <span className="react-switch-description">Não desejo identificar os passageiros</span>
                                        <FormControl type="checkbox" className="react-switch-checkbox" id="form-ind-inform-passenger" name="indInformPassenger" 
                                            checked={props.indInformPassenger}
                                            onChange={(e) => checkInformPassenger(e)}
                                        />
                                        <Form.Label className="react-switch-label" htmlFor="form-ind-inform-passenger">
                                            <span className={`react-switch-button`} /> 
                                        </Form.Label>
                                    </div>
                                </Col>
                                <Col sm="3" className="align-right">
                                    {props.indInformPassenger && <span className="vertical-align-middle">Passageiros</span>}
                                </Col>
                                <Col sm="4">
                                    {props.indInformPassenger && <InputGroup className="mb-3">
                                        <InputGroup.Text><MdPerson /></InputGroup.Text>
                                        <FormControl type="text" id="form-quantity-people" name="quantityPeople" placeholder=""
                                            maxLength={2}
                                            value={props.quantityPeople}
                                            onChange={(e) => props.setQuantityPeople(maxNumber(e.target.value, 50))}
                                        />
                                        <InputGroup.Text><FaChild /></InputGroup.Text>
                                        <FormControl type="text" id="form-quantity-children" name="quantityChildren" placeholder=""
                                            maxLength={2}
                                            value={props.quantityChildren}
                                            onChange={(e) => props.setQuantityChildren(maxNumber(e.target.value, 10))}
                                        />
                                    </InputGroup>}
                                </Col>
                            </Row>
                        </fieldset>
                        { !props.indInformPassenger && <fieldset key={3}>
                            <legend>{display.legend.passengers_on_the_vessel}</legend>
                            <Row>
                                <Col sm="12">
                                    {!props.hasRecord &&
                                    <div className="card-actions float-right">
                                    <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.add} {display.tooltips.passengers}</Tooltip>}>   
                                        <a href="/#" onClick={(e) => { onClick_AddPassenger(e) }}>
                                            <FaUserPlus size={18} />
                                        </a>
                                    </OverlayTrigger>    
                                    </div>
                                    }
                                    <TableContainer columns={columnsPassenger} data={props.passengers} />
                                </Col>
                            </Row>
                        </fieldset> }
                        </>
                        }
                        {props.vessel.shared === 1 && (
                            <>
                                <h4 className="text-center mt-3">Conferência de
                                    Equipamentos e Acessórios</h4>
                                <Container fluid className={`container-min p-0 ${styles.container}`}>
                                    <Tabs className={styles.tabs} value={activeTab} onChange={handleChange}
                                        indicatorColor="primary" textColor="primary" variant="fullWidth">
                                        {equipmentGroups.map((group: any, index: number) => (
                                        <Tab label={group.name} {...aProps(index)} />
                                        ))}
                                    </Tabs>
                                    <ThemeProvider theme={theme}>
                                        {equipmentGroups.map((group: any, indexGroup: number) => (
                                        <TabPanel value={activeTab} index={indexGroup} dir={theme.direction}>
                                            <div className='d-flex justify-content-between gap-4'>
                                                {(group.id === 1) ? (
                                                renderGroup(mandatoryGroup)
                                                ) : (group.id === 2) ? (
                                                renderGroup(additionalSalvageGroup)
                                                ) : (group.id === 3) ? (
                                                renderGroup(accessoriesGroup)
                                                ) : (
                                                    
                                                    <>
                                                    {renderGroup(otherGroup)}
                                                    
                                                </>
                                                )}
                                            </div>
                                        </TabPanel>
                                        ))}
                                    </ThemeProvider>
                                </Container>
                                <h5 className="mt-3">Inconsistências de Equipamentos e Acessórios</h5>
                                    <TextareaAutosize
                                        minRows={3}
                                        value={occurrence}
                                        onChange={e =>
                                        setOccurrence(e.target.value)
                                        }
                                        style={{
                                        minHeight: 120,
                                        minWidth: '100%',
                                        // maxWidth: 640,
                                        maxHeight: 120,
                                        borderRadius: 6,
                                        marginTop: 5
                                        }}
                                        placeholder="Descreva caso haja inconsistência de informações ou quantidade.
                                        Exemplo: Cliente informa que uma churrasqueira foi danificada. GPS foi perdido"
                                        onResize={undefined}
                                        onResizeCapture={undefined}
                                    />
                                {/* <fieldset key={4}>
                                    <legend>{display.legend.equipment_and_accessories_conference}</legend>
                                    <Row>
                                        <Col sm="12">
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>{display.label.equipment_and_accessories}</th>
                                                    <th>{display.label.volume}</th>
                                                    <th>{display.label.withdrawn}</th>
                                                    <th>{display.label.returned}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {props.vessel.equipments && props.vessel.equipments.map((item: any, index: number)
                                                => {
                                                return (
                                                <tr key={index}>
                                                    <td className="col-sm-6">
                                                        <Form.Label htmlFor="form-withdrawn">
                                                            { item.group } {'/ '}
                                                            { item.category } {' - '}
                                                            { item.name }
                                                        </Form.Label>
                                                    </td>
                                                    <td className="col-sm-2">{ item.quantity }{' '}{ item.unity }
                                                        <FormControl type="hidden" id="form-quantity" name="quantity"
                                                            placeholder="" value={toString(item.quantity)}
                                                            onChange={(e)=> onChange_UpdateAccessoryItem(e, index,
                                                            'quantity', e.target.value)}
                                                            />
                                                    </td>
                                                    <td className="col-sm-2">
                                                        <FormControl id="form-withdrawn" name="withdrawn" placeholder=""
                                                            value={toString(item.withdrawn)} onChange={(e)=>
                                                            onChange_UpdateAccessoryItem(e, index, 'withdrawn',
                                                            e.target.value)}
                                                            />
                                                    </td>
                                                    <td className="col-sm-2">
                                                        <FormControl id="form-returned" name="returned" placeholder=""
                                                            value={toString(item.returned)} onChange={(e)=>
                                                            onChange_UpdateAccessoryItem(e, index, 'returned',
                                                            e.target.value)}
                                                            />
                                                    </td>
                                                </tr>
                                                );
                                                })}
                                            </tbody>
                                        </Table>
                                        </Col>
                                    </Row>
                                </fieldset> */}
                            </>
                        )}
                        </>
                    )}

                </Col>
            </Row>
        </>
    );

}

export default MovementVesselRecord;
