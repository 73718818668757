import { useEffect, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { BsBarChartFill, BsCashCoin, BsFillCartCheckFill, BsWallet2 } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

import { usePartnerContext } from "../../contexts/partner.context";
import { useTranslateContext } from "../../contexts/translate.context";

import { Bar, BarChart, CartesianGrid, Cell, LabelList, Legend, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import Jet from '../../assets/Jetski.svg';
import Lancha from '../../assets/Lancha.svg';
import { useAlertMessageContext } from "../../contexts/alert-message.context";
import lclService from "../../services/lcl.service";
import { dateToString, formatCurrency, toFloat, toInt, toLowerCase, toStringFloat } from "../../utilities/auxiliary-functions";
import { VESSEL_JETSKI } from "../../utilities/constants";
import { floatMask } from "../../utilities/masks";
import { AlertMessageEnum } from "../../utilities/types";
import DashboardHeader from "../headers/dashboard.header";
import { TFilter } from "../../types/FilterType";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { isEmpty } from "../../utilities/validators";

interface IFinancialDashboardProps {
    
}

export const FinancialDashboard: React.FC<IFinancialDashboardProps> = () => {
    
	const { display } = useTranslateContext();
	const { partner } = usePartnerContext();
    const alertContext = useAlertMessageContext();
    const navigator = useNavigate();

    const title = "Gestão Financeira";
    const description = "";

    const [ countLancha, setCountLancha ]  = useState(0);
    const [ countJetski, setCountJetski ]  = useState(0);

    const [ sumPaid, setSumPaid ] = useState('0.00');
    const [ sumWaiting, setSumWaiting ] = useState('0.00');
    const [ sumDelay, setSumDelay ] = useState('0.00');
    const [ sumParcel, setSumParcel ] = useState('0.00');
    const [ selectedYear, setSelectedYear ] = useState('2024');

    const [ paymentPerForm, setPaymentPerForm ] = useState({} as any);
    const [ paymentPerAccompany, setPaymentPerAccompany ] = useState({} as any);

    function getMonthName(monthNumber: any) {
        let month = monthNumber.substring(6, 4)
        if (month.split('0')[0] === '') {
            month = month.split('0')[1]
        }
        var months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
        return months.slice(month -1)[0];
    }

    async function searchfilter() {
        const dateStart = dateToString(new Date(), 'yyyy-mm-ddTHH:MM:ss.sssZ');
        const dateEnd = dateToString(new Date(), 'yyyy-mm-ddTHH:MM:ss.sssZ');

        if (!isEmpty(partner)) {
            try {
                const filter = {
                    where: { 
                        'partners.partnerId': partner.id
                    }
                } as TFilter;
                let vesselFilter = await lclService.vesselFilter(filter); // {count: 0, rows: []} as any; 
                const vessels = vesselFilter.rows;

                let countJetski = 0;
                let countLancha = 0;
                vessels.forEach((vessel: any) => {
                    if (toInt(vessel.typeVessel) === VESSEL_JETSKI)
                        countJetski++
                    else
                        countLancha++
                });
                setCountJetski(countJetski);
                setCountLancha(countLancha);

                const resultMonthlyPayments = await lclService.monthlyPayment(partner.id, dateStart, dateEnd);
                if (resultMonthlyPayments.length > 0) {
                    const resultMonthlyPayment = resultMonthlyPayments[0];
                    
                    setSumPaid(floatMask(resultMonthlyPayment.sumPaid));
                    setSumWaiting(floatMask(resultMonthlyPayment.sumWaiting));
                    setSumParcel(floatMask(resultMonthlyPayment.sumParcel));
                    setSumDelay(floatMask(resultMonthlyPayment.sumDelay));
                }
                
                const resultMonthlyPaymentPerForms = await lclService.monthlyPaymentPerForm(partner.id, dateStart, dateEnd);
                const localPaymentPerForm = [] as any;
                for ( var mf = 0; mf < resultMonthlyPaymentPerForms.length; mf++ ) {
                    localPaymentPerForm[localPaymentPerForm.length] = {
                        name: resultMonthlyPaymentPerForms[mf].paymentForm,
                        value: toFloat(resultMonthlyPaymentPerForms[mf].sumParcel)
                    }
                }
                setPaymentPerForm(localPaymentPerForm);

                const resultMonthlyPaymentPerAccompanies = await lclService.monthlyPaymentPerAccompany(partner.id, dateStart, dateEnd);
                const localPaymentPerAccompany = [] as any;
                for ( var ma = 0; ma < resultMonthlyPaymentPerAccompanies.length; ma++ ) {
                    localPaymentPerAccompany[localPaymentPerAccompany.length] = {
                        name: getMonthName((resultMonthlyPaymentPerAccompanies[ma].monthy).toString()),
                        provided: toFloat(resultMonthlyPaymentPerAccompanies[ma].sumProvided),
                        realized: toFloat(resultMonthlyPaymentPerAccompanies[ma].sumRealized)
                    }
                }
                setPaymentPerAccompany(localPaymentPerAccompany);

            } catch(error: any) {
                await alertContext.show(AlertMessageEnum.FAIL, title, error);
            }
        } else {
            await alertContext.show(AlertMessageEnum.FAIL, title, 'Não foi definido uma Marina para exibir as finanças !');
            navigator(`/portal`);
        }
    } // searchfilter

    useEffect(() => {
        searchfilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
    const RADIAN = Math.PI / 180;
    
    const renderFormLabel = (props: any) => {

        const {
            cx,
            cy,
            midAngle,
            outerRadius,
            fill,
            payload,
            value,
        } = props;
        
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 30;
        const ey = my;
        const textAnchor = cos >= 0 ? "start" : "end";
        
        return (
            <g>
                <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                <text style={{ fontWeight: "bold" }} x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill={fill} >
                    {display.label[toLowerCase(payload.name)]}
                </text>
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999" >
                    {toStringFloat(value)}
                </text>
            </g>
        );
    };

    const CustomTooltip = (props: any) => {

        const { active, payload } = props;

        if (active && payload && payload.length) {
            return (
                <div className="financial-accompany-tooltip">
                    <span style={{color : payload[0].color }}>{`${payload[0].name} : ${toStringFloat(payload[0].value)}`}</span>
                    <span style={{color : payload[1].color }}>{`${payload[1].name} : ${toStringFloat(payload[1].value)}`}</span>
                </div>
            );
        }
        return null;
    };

    const renderCustomizedLabel = (props: any) => {
        const {
          x, y, width, height,
        } = props;
      
      const offset = 10;
        return (
            <text x={x + width -offset} y={y + height - 5} fill="#fff" textAnchor="end">
              {formatCurrency(props.value)}
            </text>
        );
      };

    const DropdownProps = {
        PaperProps: {
            style: {
            width: 160,
            maxHeight: 500
            }
        }
    }    

    const yearOptions = [
        {
          label: '2024',
          value: '2024'
        },
        {
          label: '2023',
          value: '2023'
        }
      ]  

    function viewDashboard() {

        return (
            <Container fluid className="dashboard-body financial">
                <Row>
                    <Col sm="12" className="dashboard-sweet">
                        <Row>
                            <Col sm="12">
                                <div className="financial-card">
                                    <div className="card-item ship">
                                        <div className="d-flex flex-column align-items-center">
                                            <h4 className="card-label">Embarcações</h4>
                                            <div className="data">
                                                <div className="card-data-group me-5">
                                                    <div className="img">
                                                        <Image style={{
                        height: 46}} src={Jet} className="jet mt-1"/>
                                                    </div>
                                                    <div className="card-data">{countJetski}</div>
                                                </div>
                                                <div className="card-data-group">
                                                    <div className="img">
                                                        <Image style={{
                        height: 46}} src={Lancha} className="jet mt-1"/>

                                                    </div>
                                                    <div className="card-data">{countLancha}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-item total">
                                        <div className="d-flex flex-column align-items-center">
                                            <h4 className="card-label">Total Previsto</h4>
                                            <div className="data">
                                                <div>
                                                    <div className="img">
                                                        <BsCashCoin size={38} />
                                                    </div>
                                                    <div className="card-data">{`R$ ${sumParcel}`}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-item monthly-payment">
                                        <div className="d-flex flex-column align-items-center">
                                            <h4 className="card-label">Recebido</h4>
                                            <div className="data">
                                                <div style={{display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center'}}>
                                                    <div>
                                                        <div className="img">
                                                            <BsFillCartCheckFill className="mb-1" size={38} />
                                                        </div>
                                                        <div className="card-data">{`R$ ${sumPaid}`}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-item delay">
                                        <div className="d-flex flex-column align-items-center">
                                            <h4 className="card-label">Em Atraso</h4>
                                            <div className="data">
                                                <div>
                                                    <div className="img">
                                                        <BsWallet2 size={38} />
                                                    </div>
                                                    <div className="card-data">{`R$ ${sumDelay}`}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-item opened">
                                        <div className="d-flex flex-column align-items-center">
                                            <h4 className="card-label">A Vencer</h4>
                                            <div className="data">
                                                <div>
                                                    <div className="img">
                                                        <BsBarChartFill size={38} />
                                                    </div>
                                                    <div className="card-data">{`R$ ${sumWaiting}`}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-4 d-flex justify-content-end">
                            <FormControl
                                variant="standard"
                                sx={{ minWidth: 160, maxWidth: 160, fontSize: 26, marginRight: 2 }}
                                >
                                <InputLabel id="account-person-type">Ano</InputLabel>
                                <Select
                                    sx={{fontSize: 30}}
                                    labelId="account-person-type"
                                    id="account-person-type-select"
                                    value={selectedYear}
                                    onChange={e =>
                                    setSelectedYear(e.target.value)
                                    }
                                    label="Ano"
                                    MenuProps={DropdownProps}
                                >
                                    {yearOptions?.map(
                                    (
                                        option: any,
                                        index: {
                                        toString: () => React.Key | null | undefined
                                        }
                                    ) => (
                                        <MenuItem
                                        selected={
                                            selectedYear === option.value
                                        }
                                        key={index.toString()}
                                        value={option.value}
                                        >
                                        {option.label}
                                        </MenuItem>
                                    )
                                    )}
                                </Select>
                            </FormControl>
                        </Row>
                        <Row style={{ marginTop: 30 }}>
                            <Col sm="8">
                                <h4 className="title text-center mb-3">Previsto X Realizado</h4>
                                <ResponsiveContainer width="100%" height={400}>
                                    <BarChart
                                        width={500}
                                        height={300}
                                        data={paymentPerAccompany}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" tick={{ fill: '#FFFFFF' }} />
                                        <YAxis tick={{ fill: '#FFFFFF' }}/>
                                        <Tooltip content={<CustomTooltip />} />
                                        <Legend />
                                        <Bar barSize={100} dataKey="provided" name={display.label.provided} fill="#00C49F"> 
                                        </Bar>
                                        <Bar barSize={100} dataKey="realized" name={display.label.realized} fill="#FF8042">
                                        </Bar>
                                    </BarChart>
                                </ResponsiveContainer>
                            </Col>
                            <Col sm="4">
                                <h4 className="title text-center">Tipo de Pagamento</h4>
                                {paymentPerForm && (
                                    <PieChart width={400} height={400}>
                                        <Pie
                                            data={paymentPerForm}
                                            //cx={120}
                                            //cy={200}
                                            innerRadius={50}
                                            outerRadius={80}
                                            fill="#8884d8"
                                            paddingAngle={5}
                                            dataKey="value"
                                            label={ renderFormLabel }
                                        >
                                            {Object.keys(paymentPerForm).length > 0 && paymentPerForm.map((form: any, index: number) => (
                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}> 
                                                    {`R$ ${form}`}
                                                </Cell>
                                            ))}
                                        </Pie>
                                        <PieChart width={400} height={400}></PieChart>
                                    </PieChart>
                                )}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        );
    }

    return (
        <div className="dashboard">
            <DashboardHeader title={title} description={description} />
            {viewDashboard()}
        </div>
    );
}

export default FinancialDashboard;
