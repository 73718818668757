import React, { useEffect, useState } from 'react'

import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear';
import { FormControl, FormHelperText, Input, InputAdornment, InputLabel, LinearProgress } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Card, Col, Container, Row } from 'react-bootstrap'
import theme from '../../theme'
import OperationHeader from '../headers/operation.header'
import styles from './StockSearch.module.scss'
import { toStringFloat } from '../../utilities/auxiliary-functions'
import authService from '../../services/auth.service'
import srvService from '../../services/srv.service'
import { AlertMessageEnum, getMaterial } from '../../utilities/types'
import { TFilter } from '../../types/FilterType'
import { useAlertMessageContext } from '../../contexts/alert-message.context'
import { useSearchParams } from 'react-router-dom'
import { useTranslateContext } from '../../contexts/translate.context';

export interface IStockSearchProps {
}

export const StockSearch: React.FC<IStockSearchProps> = (props: IStockSearchProps) => {

    const alertContext = useAlertMessageContext();
    const { display } = useTranslateContext();

    const title : string = display.title.stock_filter;

    let reloadPage = false;
    const [ isLoading, setLoading ] = useState(false);

    let noPaged = true;
    const [ searchParams, setSearchParams ] = useSearchParams();

    const [ forceFilter, setForceFilter ] = useState(false);
    const [ textSearch, setTextSearch ] = useState('');
    const [ pageSize, setPageSize ] = useState(10);
    const [ page, setPage ] = useState(0);
    const [ pageFilled, setPageFilled ] = useState<number[]>([]);

    const [ recordCount, setRecordCount ] = useState(0);
    const [ record, setRecord ] = useState({} as any);
    const [ records, setRecords ] = useState([] as any);
    const [ originalRecords, setOriginalRecords ] = useState([]);

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        ChartDataLabels,
        Title,
        Tooltip,
        Legend,
        ArcElement
    )

    const formatCurrency = (value: string) => {
        if (Number(value))
            return new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
            }).format(Number(value))
        return value
    }

    function getCode(param: any) {
        const { product } = param.row;
        return product.code;
    }

    function getDescription(param: any) {
        const { product } = param.row;
        return product.description;
    }

    function getCategory(param: any) {
        const { product } = param.row;
        let name = 'Sem categoria';
        if (product !== null) {
            name = getMaterial(product.category.material) || '';
        }
        return name;
    }

    function getAllowSaleWithoutStockDef(param: any) {
        const { control } = param.row;
        return control > 0 ?  'Sim' : 'Não';
    }

    function getActualStock(param: any) {
        const { actual } = param.row;
        return actual;
    }

    function getMinStock(param: any) {
        const { minimum } = param.row;
        return minimum;
    }
    
    function getMarginDef(param: any) {
        const { margin } = param.row;
        return `${toStringFloat(margin)}%`;
    }

    function getPrice(param: any) {
        const { sale } = param.row;
        return formatCurrency(sale)
    }

    /*
    const records = [
        {id: 1, code: 1000, description: 'Produto teste', category: 'Categoria', stock: 100, minStock: 5, allowSaleWithoutStock: false, margin: 21, price: 19.90},
        {id: 2, code: 2000, description: 'Produto teste 2', category: 'Categoria', stock: 90, minStock: 15, allowSaleWithoutStock: true, margin: 25, price: 9.90},
        {id: 3, code: 3000, description: 'Produto teste 3', category: 'Categoria', stock: 2, minStock: 1, allowSaleWithoutStock: false, margin: 19, price: 23.90}
    ]
    */
    const columns: GridColDef[] = [
        { field: 'code', headerName: 'Cód.', minWidth: 110, align: 'center', headerAlign: 'center', valueGetter: getCode },
        { field: 'description', headerName: 'Desc.', minWidth: 180, flex: 1, align: 'center', headerAlign: 'center', valueGetter: getDescription },
        { field: 'category', headerName: 'Cat.', width: 110, flex: 1, align: 'center', headerAlign: 'center', valueGetter: getCategory },
        { field: 'actualStock', headerName: 'Estq. Atual', minWidth: 100, align: 'center', headerAlign: 'center', valueGetter: getActualStock },
        { field: 'minStock', headerName: 'Estq. Mín', width: 110, align: 'center', headerAlign: 'center', valueGetter: getMinStock },
        { field: 'allowSaleWithoutStock', headerName: 'Venda s/ Estq.', width: 120, align: 'center', headerAlign: 'center', valueGetter: getAllowSaleWithoutStockDef },
        { field: 'margin', headerName: 'Margem (%)', width: 120, align: 'center', headerAlign: 'center', valueGetter: getMarginDef },
        { field: 'price', headerName: 'Valor Venda', width: 120, align: 'center', headerAlign: 'center', valueGetter: getPrice }
    ];

    async function searchFilter() { // pageSize: number, page: number
        setLoading(true);
        try {
            let localPageSize = pageSize;
            let localPage = page;

            if (noPaged) {
                if (searchParams.has('limit')) {
                    let paramPageSize = searchParams.get('limit');
                    if (paramPageSize) {
                        // eslint-disable-next-line react-hooks/exhaustive-deps
                        localPageSize = Number.parseInt(paramPageSize);
                        setPageSize(localPageSize);
                    }
                }
                if (searchParams.has('offset')) {
                    let paramPage = searchParams.get('offset');
                    if (paramPage) {
                        // eslint-disable-next-line react-hooks/exhaustive-deps
                        localPage = Number.parseInt(paramPage);
                        setPage(localPage);
                    }
                }
            }

            const partner = authService.currentPartner();
            if (pageFilled.indexOf(page) === -1) {

                const filter = {
                    attributes: [ 'id', 'control', 'notify', 'sellZero', 'minimum', 'actual', 'margin', 'sale', 
                        'product.code', 'product.reference', 'product.description', 'product.usability', 'product.unit',
                        'product.category.id', 'product.category.material', 'product.category.team', 'product.category.group', 'product.category.subgroup', 'product.category.name'
                    ],
                    where: {
                        partnerId: partner.id
                    },
                    limit: pageSize,
                    offset: page * pageSize
                } as TFilter;

                if (textSearch.length >= 4) {
                    filter.where['or'] = [
                        { 'product.code': { like: `%${textSearch}%` }},
                        { 'product.description': { like: `%${textSearch}%` }}
                    ]
                }

                const stockFilter = await srvService.stockFilter(filter);
                const array = [] as any;
                records.forEach((record: any) => array.push(record));
                stockFilter.rows.forEach((record: any) => array.push(record));

                setRecords(array);
                if (textSearch.length < 4) {
                    setRecordCount(stockFilter.count);
                    setOriginalRecords(array);
                }

                if (array.length > 0)
                    pageFilled.push(localPage);
            }
        } catch(error: any) {
            await alertContext.show(AlertMessageEnum.FAIL, title, error);
        } finally {
            setLoading(false);
        }
    }

    const onChangePageSize = (newPageSize: number) => {
        setSearchParams({...searchParams, 'limit': `${newPageSize}`, 'offset': `${0}`});

        setRecords([]);
        setOriginalRecords([]);
        setRecordCount(0);
        setPageFilled([]);
        setPageSize(newPageSize);
        setPage(0);
    }
    
    const onChangePage = (newPage: number) => {
        setSearchParams({...searchParams, 'offset': `${newPage}`});

        setPage(newPage);
    }
    
    let effectForPage = false;

    useEffect(() => {
        if (forceFilter)
            searchFilter();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceFilter]);

    useEffect(() => {
        noPaged = false;
        let paramPage = searchParams.get('offset');
        if (paramPage) {
            searchFilter();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSize, page]); // useEffect

    useEffect(() => {
        if (!isLoading && !reloadPage) {
            noPaged = true;
            searchFilter();
        }

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            reloadPage = !reloadPage;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // useEffect

    function onClick_Search(event: any) {
        event.preventDefault();
        if (textSearch.length >= 4) {
            setRecords([]);
            //setOriginalRecords([]);
            setRecordCount(0);
            setPageFilled([]);

            if (forceFilter) {
                setTextSearch('');
                setRecords(originalRecords);
            }
            setForceFilter(!forceFilter);
        }
    }

    const filtered = (key?: string, value?: string) => {
        const filtered: any = [];
        console.log(records);
        
        records.filter((record: any) => {
            if (
                record.product.code
                    .toLocaleLowerCase()
                    .includes(textSearch.toLocaleLowerCase()) ||
                (getMaterial(record.product.category.material) || '')
                    .toLocaleLowerCase()
                    .includes(textSearch.toLocaleLowerCase()) ||
                record.product.description
                    .toLocaleLowerCase()
                    .includes(textSearch.toLocaleLowerCase())
            )
            filtered.push(record);
            return record;
        })
        setRecords(filtered);
        return '';
    }

    useEffect(() => {
        if (textSearch.length >= 3) {
            filtered();
        } else if (textSearch.length < 3) {
            setRecords(originalRecords);
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [textSearch])

    function viewPage() {
        return (
            <Container fluid className={`page-body movements ${styles.serviceCenter}`}>
                <Row>
                    <Col md={12} className='mb-4 d-flex justify-content-between w-100'>
                        <FormControl className='ms-3' fullWidth sx={{width: 350 }} variant="standard">
                            <InputLabel className={styles.label} htmlFor="standard-adornment-amount">Pesquisar</InputLabel>
                            <Input id="standard-adornment-amount"
                                value={textSearch}
                                onChange={(e) => setTextSearch(e.target.value)}
                                endAdornment = {
                                    <InputAdornment position="end">
                                        {!forceFilter ?
                                            <SearchIcon className='isClickable' onClick={(e)=> onClick_Search(e)} />
                                        : <ClearIcon className='isClickable' onClick={(e)=> onClick_Search(e)} />
                                        }
                                    </InputAdornment>
                                }
                            />
                            <FormHelperText id="component-helper-text">
                                Digite Código, Nome ou Categoria
                            </FormHelperText>
                        </FormControl>
                        <div className='d-flex flex-column align-items-center' style={{width: 280}}>
                            <h5>Produtos Cadastrados</h5>
                            <h2 className='lighter'>{ recordCount }</h2>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className="page-sweet">
                        <Card >
                            <Card.Header>
                                <div className="card-actions float-right d-flex justify-content-end">
                                    <div className="card-actions-time" />
                                </div>
                                <Card.Title>Produtos</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <ThemeProvider theme={theme}>
                                    <div style={{ height: 402, width: '100%', marginTop: 0 }}>
                                    <DataGrid
                                        rows={records}
                                        rowCount={recordCount}
                                        getRowId={row => row.id}
                                        loading={isLoading}
                                        columns={columns}
                                        pageSize={pageSize}
                                        onPageSizeChange={newPageSize => onChangePageSize(newPageSize)}
                                        page={page}
                                        onPageChange={newPage => onChangePage(newPage) }
                                        
                                        rowsPerPageOptions={[10, 25, 50, 75, 100]}
                                        disableSelectionOnClick
                                        // onCellClick={(e) => console.log(e)}
                                        onRowClick={(row) => {
                                            const dataRow = records[row.id];
                                            setRecord(dataRow); 
                                        }}
                                        components={{
                                            LoadingOverlay: LinearProgress
                                        }}
                                    />
                                    </div>
                                </ThemeProvider>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }

    return (
        <div className="page">
            <OperationHeader title="Consulta Estoque" description="" />
            {viewPage()}
        </div>
    )
}

export default StockSearch
