import { useNavigate } from "react-router-dom";

import { useTranslateContext } from "../../contexts/translate.context";
import { usePartnerContext } from "../../contexts/partner.context";
import { useAlertMessageContext } from "../../contexts/alert-message.context";

import DashboardHeader from "../headers/dashboard.header";
import { Container, Image } from "react-bootstrap";

import styles from './MaritimeDashboardV2.module.scss';

import Jet from '../../assets/Jetski.svg';
import Lancha from '../../assets/Lancha.svg';
import Navegando from '../../assets/Navegando.svg';
import Abastecimento from '../../assets/Abastecimento.svg';
import Manutencao_externa from '../../assets/Manutencao_externa.svg';
import Movimento_entrada from '../../assets/Movimento_entrada.svg';
import Movimento_saida from '../../assets/Movimento_saida.svg';
import Higiene from '../../assets/Limpeza.svg';
import Warehouse from '../../assets/Warehouse.svg';
import Users from '../../assets/Users.svg';
import ClockHistory from '../../assets/ClockHistory.svg';
import SportsScore from '../../assets/SportsScore.svg';
import Tools from '../../assets/Tools.svg';
import Selo from '../../assets/selo.png';

import { Box } from "@mui/material";
import { ClockAndClimate } from "./components/ClockAndClimate";
import { ResumeBox, ResumeBoxCard } from "./components/ResumeBox";
import { GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import Loader from "../../components/loader/loader";
import { TFilter } from "../../types/FilterType";
import { removeFormatDate } from "../../utilities/masks";
import lclService from "../../services/lcl.service";
import { arrivalForecast, browsingDelay, dateToString, notNullToDate, toDate, toFloat, toInt } from "../../utilities/auxiliary-functions";
import { isEmpty } from "../../utilities/validators";
import { getVessel } from "../../utilities/types";
import { VESSEL_JETSKI, VESSEL_LANCHA } from "../../utilities/constants";
import { CardDataGrid } from "./components/CardDataGrid";
import { useMediaContext } from "../../contexts/media.context";

interface IMaritimeDashboardProps {
}

export const MaritimeDashboardV2: React.FC<IMaritimeDashboardProps> = () => {

    const { isTv } = useMediaContext();

    const { display } = useTranslateContext();
    const { partner } = usePartnerContext();

    const alertContext = useAlertMessageContext();
    const navigator = useNavigate();

	const title: string = "Gestão Diária";
	const description: string = "";

    let reloadPage: boolean = false;
    const [ loading, setLoading ] = useState(false);
    
    const [ peoples, setPeoples ] = useState(0);
	const [ browsing, setBrowsing ] = useState(0);
	const [ delay, setDelay ] = useState(0);
	const [ finished, setFinished ] = useState(0);
	const [ averageNavigationTime, setAverageNavigationTime ] = useState("00:00");

	const [ vessels, setVessels ] = useState<{jets: number, lanchas: number}>();

    const [ isChangedReload, setChangedReload ] = useState(false);
    const [ isChangeMaximized, setChangeMaximized ] = useState(false);
    // const [ intervalTimeGrid, setIntervalTimeGrid ] = useState<NodeJS.Timer>();
    const [ timeGridReload, setTimeGridReload ] = useState(0);

    const movementColumns: GridColDef[] = [
        { field: 'vessel.identifyKey', headerName: 'HORA | ID', maxWidth: 120, align: 'center', headerAlign: 'center', renderCell: renderDatetimeAndId },
        { field: 'vessel.type', headerName: 'TIPO | COR', maxWidth: 120, align: 'center', headerAlign: 'center', renderCell: renderNormAndColor},
        { field: 'vessel.name', headerName: 'NOME EMB.', flex: 0.7, align: 'center', headerAlign: 'center', renderCell: renderName},
        { field: 'vessel.brand', headerName: 'MARCA', flex: 0.3, align: 'center', headerAlign: 'center', renderCell: renderBrand},
        { field: 'vessel.model', headerName: 'MOD. | PROP.', maxWidth: 250, align: 'center', headerAlign: 'center', renderCell: renderModelAndOwner},
        { field: 'arrival', headerName: 'PREV. RET', maxWidth: 120, align: 'center', headerAlign: 'center', renderCell: renderArrival},
        { field: 'status', headerName: 'STATUS', maxWidth: 120, align: 'center', headerAlign: 'center', renderCell: renderStatus}
    ];
    const [ movements, setMovements ] = useState([] as any);
    
    async function searchFilter() {
        let date = new Date();
        let baseDate = notNullToDate(date, "dd/mm/yyyy");
        console.log(baseDate);

        const filter: TFilter = {
            attributes: [ 
                'id', 'departure', 'dateMovement', 'estimated', 'arrival', 'quantityPeople', 'status', 'move', 'updated', 
                'vessel.typeVessel', 'vessel.identifyKey', 'vessel.name', 'vessel.color', 'vessel.brand', 'vessel.model', 'vessel.model', 'vessel.vesselSize',
                'crews.kind', 'crews.conductor.peopleId',
                'crews.conductor.people.fullname', 'crews.conductor.people.gender', 'crews.conductor.people.registrationPf'
            ], 
            where: {
                partnerId: partner.id,
                dateMovement: {
                    start: removeFormatDate(baseDate + " 00:00:00"),
                    end: removeFormatDate(baseDate + " 23:59:59")
                }
            },
            order: [["departure", "ASC"]]
        };
        const movementVesselFilter = await lclService.movementVesselFilter(filter);
        // setMovements(movementVesselFilter.rows);
        const movements = movementVesselFilter.rows;

        let movementList = [{}] as any;
        let localPeoples = 0;
        let localBrowsing = 0;
        let localDelay = 0;
        let localAverageNavigationTime = "00:00";
        let localVesselInTheWater = 0;

        if (movements !== "undefined") {
            movementList = movements;

            localPeoples = 0;
            localBrowsing = 0;
            localDelay = 0;
            localAverageNavigationTime = "00:00";
            localVesselInTheWater = 0;

            let countTimeRamp = 0;
            let sumTimeRamp = 0;
            let countNavigationTime = 0;
            let sumNavigationTime = 0;

            movementList.forEach((movement: any) => {
                localPeoples += toInt(movement.quantityPeople);
                if (movement.status === "5") {
                    localBrowsing += 1;

                    const isDelay = browsingDelay(
                        movement.status,
                        notNullToDate(movement.dateMovement, "dd/mm/yyyy"),
                        notNullToDate(movement.departure, "HH:MM"),
                        movement.estimated
                    );
                    if (isDelay) localDelay += 1;
                } else if (movement.status === "2" || movement.status === "3") {
                    countTimeRamp += 1;

                    const forecast = new Date(movement.updated).getTime() - new Date(movement.departure).getTime();
                    sumTimeRamp += forecast;
                } else if (movement.status === "1") {
                } else if ((movement.status === "8")|| (movement.status === "9")) {
                    countNavigationTime += 1;
                    let arrivaleCalculate = new Date(movement.departure).setHours(new Date(movement.departure).getHours() + toFloat(movement.estimated))
                    const forecast = new Date(arrivaleCalculate).getTime() - new Date(movement.departure).getTime();
                    sumNavigationTime += forecast;
                }
                if (movement.status === "5" || movement.status === "8") {
                    localVesselInTheWater += 1;
                //} else if(movement.status === "7"){
                    //  localDelay += 1;
                }
            });

            let averageTimeRamp = sumTimeRamp / countTimeRamp;
            if (isNaN(averageTimeRamp)) averageTimeRamp = 0;

            let avarageNavigationTime = sumNavigationTime / countNavigationTime;
            if (isNaN(avarageNavigationTime)) avarageNavigationTime = 0;
            localAverageNavigationTime = notNullToDate(new Date(new Date().setTime(avarageNavigationTime)), "HH:MM");
    
            setPeoples(localPeoples);
            setBrowsing(localBrowsing);
            setDelay(localDelay);
            setFinished(movementList.filter((movement: any) => movement.move === 6).length)
            setAverageNavigationTime(localAverageNavigationTime);
            setVessels(separateVessels(movementList))

            // setMovements(refresh > 1 ? movementList.filter((movement: any) => movement.move !== 6) : movementList);
            setMovements(movementList.filter((movement: any) => movement.move !== 6));
        } else {
            setPeoples(0);
            setBrowsing(0);
            setDelay(0);
            setFinished(0);
            setAverageNavigationTime("00:00");
            setVessels({ jets: 0, lanchas: 0})

            setMovements([]);
        }

        if (timeGridReload === 0) {
            setChangedReload(true);
            setTimeGridReload(1);
        }
    } // searchFilter
    
    function onClick_GridRefresh() {
        searchFilter();
    }

    function onChange_GridReload(minute: number) {
        setChangedReload(true);
        setTimeGridReload(minute);
    }

    function onChange_Maximized(isMaximized: boolean) {
        setChangeMaximized(isMaximized);
    }

    useEffect(() => {
        if (isChangedReload) {
            setChangedReload(false);

            const time = timeGridReload * (60 * 1000);
            const internal = setInterval(() => {
                searchFilter();
            }, time);
            return () => clearInterval(internal); 
        }
    }, [timeGridReload]);

    useEffect(() => {
        if (!reloadPage) {
            reloadPage = true;
            setLoading(true);
            searchFilter().finally(() => {
                setLoading(false);
            });
        }
    }, []);

    const separateVessels = (movements: Array<any>) => {
        const jets = movements?.filter(movement => toInt(movement.vessel?.typeVessel) === VESSEL_JETSKI)?.length || 0
        const lanchas = movements?.filter(movement => toInt(movement.vessel?.typeVessel) === VESSEL_LANCHA)?.length || 0

        return {
            jets, lanchas
        }
    }

    const getResponsible = (param: any) => {
        const movement = param.row;
        const crews = movement.crews;

        if ((crews) && (crews.length > 0)) {
            return crews[0]?.conductor?.people?.fullname?.split(' ')[0]
        }
        return '-'
    }

    function calculateEstimated(movement: any) {
        let arrival = arrivalForecast(
            notNullToDate(movement.dateMovement, "dd/mm/yyyy"),
            notNullToDate(movement.departure, "HH:MM"),
            movement.estimated
        );
        if (!isEmpty(movement.arrival)) 
            arrival = dateToString(movement.arrival, "HH:MM");
        else
            arrival = dateToString(toDate(arrival, "dd/mm/yyyy HH:MM:ss"), "HH:MM");
        return arrival;
    }

    const getIcons = (movement: any) => {
        const refresh = 1; // Number(localStorage.getItem('refresh'))

        if (movement.move === 6 && refresh >= 1)
            return (
                <div className="d-flex flex-column align-items-center">
                    <Image src={SportsScore} className="jet" style={{ height: 23 , width: 23}}/>
                    <small className={styles.finished}>Finalizado</small>
                </div>
            )
        if (movement.status === '1')
            return (
                <div className="d-flex flex-column align-items-center">
                    <Image src={Warehouse} className="jet" style={{ height: 23 , width: 23}}/>
                    <small>Garagem</small>
                </div>
            )

        if (movement.status === '2')
            return (
                <div className="d-flex flex-column align-items-center">
                    <Image src={Abastecimento} className="jet" style={{ height: 23 , width: 23}}/>
                    <small>Abastecimento</small>
                </div>
            )
        if (movement.status === '3')
            return (
                <p style={{marginBottom: 0, marginTop: 3, backgroundColor: 'green', padding: 2, paddingLeft: 6, paddingRight: 6, borderRadius: '8px'}}>Finalizado</p>
            )
        if (movement.status === '4')
            return (
                <div className="d-flex flex-column align-items-center">
                    <Image src={Higiene} className="jet" style={{height: 28 , width: 28}}/>
                    <small>Limpeza</small>
                </div>
            )
        if (movement.status === '5')
            return (
                <div className="d-flex flex-column align-items-center">
                    <div className="rotating">
                        <Image src={Navegando} className="jet" style={{ height: 23 , width: 23}}/>
                    </div>
                    <small>Navegando</small>
                </div>
            )
        if (movement.status === '6')
            return (
                <div className="d-flex flex-column align-items-center">
                    <Image src={Manutencao_externa} className="jet" style={{ height: 30 , width: 30}}/>
                    <small>Reparo Externo</small>
                </div>
            )
        if (movement.status === '7')
            return (
                <div className="d-flex flex-column align-items-center">
                    <Image src={Movimento_entrada} className="jet" style={{ height: 32 , width: 32}}/>
                    <small>Entrando</small>
                </div>
            )
        if (movement.status === '8')
            return (
                <div className="d-flex flex-column align-items-center">
                    <Image src={Movimento_saida} className="jet" style={{ height: 32 , width: 32}}/>
                    <small>Saindo</small>
                </div>
            )
        if (movement.status === '9')
            return (
                <div className="d-flex flex-column align-items-center">
                    <Image src={Tools} className="jet" style={{ height: 32 , width: 32}}/>
                    <small>Oficina</small>
                </div>
            )
    }

    function renderDatetimeAndId(param: any) {
        const movement = param.row;

        return (
            <div className="d-flex flex-column align-items-center justify-content-center mb-2">
                <div className="white-space" style={{fontSize: isTv || isChangeMaximized ? '1.75rem' : '1.5rem'}}>{notNullToDate(movement.departure, "HH:MM")}</div>
                <div className="white-space" style={{fontSize: '1.0rem', fontWeight: '500'}}>{movement.vessel.identifyKey}</div>
            </div>
        );
    }
    
    function renderNormAndColor(param: any) {
        const movement = param.row;
        const vessel = movement.vessel;

        return getVessel(vessel?.typeVessel).id === VESSEL_LANCHA ? (
            <div className="d-flex flex-column align-items-center justify-content-center mb-2">
                <div className="mt-3 d-flex justify-content-center mb-2">
                    <Image src={Lancha} className="jet" style={{height: 32}}/>
                    <small className="ms-2">{`${vessel.vesselSize}'`}</small>
                </div>
                <span className={styles.vesselColor} style={{backgroundColor: `${vessel?.color}`}}></span>
            </div>
        ) : (
            <div className="d-flex flex-column align-items-center justify-content-center mb-2">
                <div className="d-flex justify-content-center mb-2">
                    <Image src={Jet} className="jet mt-3" style={{height: 32}}/>
                    <small className="ms-1 mt-3">{`10'`}</small>
                </div>
                <span className={styles.vesselColor} style={{backgroundColor: `${vessel?.color}`}}></span>
            </div>
        );
    }

    function renderName(param: any) {
        const movement = param.row;
        const vessel = movement.vessel;

        return (<div style={{fontSize: isTv || isChangeMaximized ? '1.75rem' : '1.25rem'}}>{vessel.name || '-'}</div>)
    }

    function renderBrand(param: any) {
        const movement = param.row;
        const vessel = movement.vessel;

        return (<div style={{fontSize: isTv || isChangeMaximized ? '1.75rem' : '1.25rem'}}>{vessel.brand || '-'}</div>)
    }

    function renderModelAndOwner(param: any) {
        const movement = param.row;
        const vessel = movement.vessel;

        return (
            <div className="d-flex flex-column justify-content-center">
                <div style={{fontSize: '1.25rem'}}>{vessel.model || '-'}</div>
                <div>{getResponsible(param)}</div>
            </div>
        )
    }

    function renderArrival(param: any) {
        const movement = param.row;

        return (<div style={{fontSize: isTv || isChangeMaximized ? '1.75rem' : '1.25rem'}}>{calculateEstimated(movement) || '-'}</div>)
    }

    function renderStatus(param: any) {
        const movement = param.row;

        return (<div style={{fontSize: '1.25rem'}}>{getIcons(movement) || '-'}</div>)
    }
    
    function viewDashboard() {
        return (
            <Container fluid className="page-body dashboards">
                <Box className={styles.mainTitle}>
                    <h1>MOVIMENTAÇÃO</h1>
                </Box>
                <Box className={styles.mainInfo}>
                    <ClockAndClimate />
                </Box>
                <ResumeBox>
                    <ResumeBoxCard label="EMBARCAÇÕES" datas={[{ image: Lancha, value: String(vessels?.jets || 0) }, { image: Jet, value: String(vessels?.lanchas || 0) }]} />
                    <ResumeBoxCard label="VIDAS" image={Users} value={String(peoples)} />
                    <ResumeBoxCard label="NAVEGANDO" image={Navegando} value={String(browsing)} rotating={true}/>
                    <ResumeBoxCard label="ATRASO" image={ClockHistory} value={String(delay)} />
                    <ResumeBoxCard label="FINALIZADOS" image={SportsScore} value={String(finished)} />
                    <ResumeBoxCard label="TEMP. MED. NAVEG." value={averageNavigationTime} />
                </ResumeBox>
                <CardDataGrid
                    columns={movementColumns}
                    rows={movements}
                    onClickRefresh={onClick_GridRefresh}
                    onChangeReload={onChange_GridReload}
                    onChangeMaximized={onChange_Maximized}
                />
                <Image src={Selo} className={`${styles.selo}`} style={{filter: 'invert(80%) sepia(100%) saturate(2%) hue-rotate(234deg) brightness(200%) contrast(101%)'}} />
            </Container>
        );
    }
    
    return (
        <div className="dashboard">
            <DashboardHeader title={title} description={description} />
            {loading ? <Loader /> : viewDashboard()}
        </div>
    );
}

export default MaritimeDashboardV2;
