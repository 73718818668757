import { Link } from "react-router-dom";

export interface IPartnerPageProps {
}

export const PartnerPage: React.FC<IPartnerPageProps> = (props: IPartnerPageProps) => {

    return (
        <>
            <div>Partners</div>
            <Link to="/">retornar a página inicial</Link>
        </>
    )
}

export default PartnerPage;
