import { useEffect, useState } from "react";
import { Form, Modal, Spinner } from "react-bootstrap";
import { FaRegIdCard, FaTimes } from "react-icons/fa";
import { useAlertMessageContext } from "../../contexts/alert-message.context";
import { useConfirmMessageContext } from "../../contexts/confirm-message.context";
import lclService from "../../services/lcl.service";
import { arrivalForecast, calculateAge, dateToString, toInt, estimate, toDate, stringFormat, calcArrival } from "../../utilities/auxiliary-functions";
import { VESSEL_JETSKI } from "../../utilities/constants";
import { removeFormatDate, horaMask } from "../../utilities/masks";
import { isEmpty } from "../../utilities/validators";
import MovementVesselRecord from "../records/movement-vessel.record";
import PeopleFilter from "../filters/people.filter";
import VesselConductorFilter from "../filters/vessel-conductor.filter";
import VesselFilter from "../filters/vessel.filter";
import { Button } from '@mui/material'
import { AlertMessageEnum } from "../../utilities/types";
import moment from "moment";
import SelectReservationModal from "../filters/select-reservation-filter";
import { TFilter } from "../../types/FilterType";
import { useTranslateContext } from "../../contexts/translate.context";
import { usePartnerContext } from "../../contexts/partner.context";

export interface IMovementCadastreModalProps {
    isEdit: boolean,
    show: boolean,
    movementId: string,
    record: any;
    records: any;
    onSubmitModal: any,
    onCancelModal: any
}

export const MovementCadastreModal: React.FC<IMovementCadastreModalProps> = (props: IMovementCadastreModalProps) => {

    const alertContext = useAlertMessageContext();
    const confirmContext = useConfirmMessageContext();
    const { display } = useTranslateContext();
    const { partner } = usePartnerContext();

    const title : string = display.title.movement_cadastre;

    const [ invalidatedCadastreForm, setInvalidatedCadastreForm ] = useState(false);

    const [ sending, setSending ] = useState(false);
    const [ showFilterVessel, setShowFilterVessel ] = useState(false);
    const [ showSelectReservation, setShowSelectReservation ] = useState(false);
    const [ showFilterCrew, setShowFilterCrew ] = useState(false);
    const [ showFilterPassenger, setShowFilterPassenger ] = useState(false);

    // Movement
    const [ movementId, setMovementId ] = useState(0);
    const [ created, setCreated ] = useState(dateToString(new Date(), 'dd/mm/yyyy HH:MM:ss'));
    const [ updated, setUpdated ] = useState(dateToString(new Date(), 'dd/mm/yyyy HH:MM:ss'));

    const [ dateMovement, setDateMovement ] = useState(dateToString(new Date(), 'dd/mm/yyyy'));
    const [ enrollment, setEnrollment ] = useState('');
    const [ vesselName, setVesselName ] = useState('');
    const [ departure, setDeparture ] = useState('');
    const [ estimated, setEstimated ] = useState('');
    const [ forecast, setForecast ] = useState('');
    const [ status, setStatus ] = useState('');
    const [ arrival, setArrival ] = useState('');
    const [ indInformPassenger, setIndInformPassenger ] = useState(false);
    let [ indResponsibleAware, setIndResponsibleAware ] = useState(false);
    let [ quantityPeople, setQuantityPeople ] = useState(0);
    let [ quantityChildren, setQuantityChildren ] = useState(0);

    const [ vessel, setVessel ] = useState({} as any);
    const [ accessories, setAccessories ] = useState([] as any);
    const [ crews, setCrews ] = useState([] as any);
    const [ passengers, setPassengers ] = useState([] as any);
    const [ navigationPlan, setNavigationPlan ] = useState('');

    const [ crewInList, setCrewInList ] = useState([] as any);
    const [ passengerInList, setPassengerInList ] = useState([] as any);


    async function isValidForm() {
        let result = true;
        let emptyRequired = false;

        if (isEmpty(dateMovement))
            emptyRequired = true;
        //if (isEmpty(enrollment))
        //    emptyRequired = true;
        if (isEmpty(departure))
            emptyRequired = true;
        // if (isEmpty(estimated))
        //     emptyRequired = true;
        if (isEmpty(forecast))
            emptyRequired = true;
        if (isEmpty(status))
            emptyRequired = true;
        //if (isEmpty(navigationPlan))
        //    emptyRequired = true;

        let sailStart = moment(stringFormat('{0} {1}:00', dateMovement, departure), 'DD/MM/YYYY HH:mm:ss');
        let sailEnd = moment(stringFormat('{0} {1}:00', dateMovement, forecast), 'DD/MM/YYYY HH:mm:ss');

        try {
            let canSailStart = true;
            let canSailEnd = true;

            const filter: TFilter = {
                attributes: [
                    'id', 'dateMovement', 'departure', 'estimated', 'status', 'locator', 'move', 'checkIn', 'registrationCheckIn', 'dateCheckIn', 
                    'sailorRequested', 'queuePosition', 'arrival', 'quantityPeople', 'quantityChildren',
                    'crews.kind', 
                    'crews.conductorId', 
                    'crews.conductor.peopleId',
                    'crews.conductor.people.fullname', 
                    'crews.conductor.people.gender', 
                    'crews.conductor.people.registrationPf',
                    'vessel.identifyKey', 'vessel.name', 'vessel.enrollment', 'vessel.typeVessel', 'vessel.vesselSize', 'vessel.color', 'vessel.quantityPeople', 'vessel.brand', 'vessel.model',
                ],
                where: { 
                    partnerId: partner.id,
                    dateMovement: removeFormatDate(dateMovement), 
                    vesselId: vessel.id,
                    id: { notIn: [ movementId ] }
                },
                order: [[ 'departure', 'ASC' ]]
            }
            const movementVesselFilter = await lclService.movementVesselFilter(filter);
            const movements = movementVesselFilter.rows;

            for ( var m = 0; m < movements.length; m++) {
                let movement = movements[m];

                let currentStart = moment(dateToString(movement.departure, 'dd/mm/yyyy HH:MM:ss'), 'DD/MM/YYYY HH:mm:ss');
                let hours = Number(movement.estimated.split(':')[0]);
                let minutes = Number(movement.estimated.split(':')[1]);
                let currentEnd = moment(currentStart)
                    .add(hours, 'hours')
                    .add(minutes, 'minutes');

                if (sailStart.isBetween(currentStart, currentEnd))
                    canSailStart = false;
                if (sailEnd.isBetween(currentStart, currentEnd))
                    canSailEnd = false;

                if (sailStart.isSame(currentStart) || sailStart.isSame(currentEnd))
                    canSailStart = false;
                if (sailEnd.isSame(currentStart) || sailEnd.isSame(currentEnd))
                    canSailEnd = false;

                if (currentStart.isBetween(sailStart, sailEnd))
                    canSailStart = false;
                if (currentEnd.isBetween(sailStart, sailEnd))
                    canSailEnd = false;

            }

            if (!(canSailStart && canSailEnd)) {
                await alertContext.show(AlertMessageEnum.FAIL, title, 'A movimentação foi bloqueada devido ao conflito de horários !!!');
                result = false;
                return;
            }
        } catch(error: any) {
            await alertContext.show(AlertMessageEnum.FAIL, title, error);
            result = false;
            return;
        }

        if (movementId > 0) {

            if (crews.length === 0) {
                await alertContext.show(AlertMessageEnum.FAIL, title, display.message.no_crew_was_informed);
                result = false;
                return;
            }

            if (toInt(vessel.typeVessel) !== VESSEL_JETSKI) {

                if (isEmpty(navigationPlan))
                    emptyRequired = true;

                if (!indInformPassenger) {
                    quantityPeople = crews.length + passengers.length;
                    let qtdChildren = 0;
                    for (var i = 0; i < passengers.length; i++) {
                        const age = calculateAge(new Date(passengers[i].birth));
                        if (age < 12) {
                            qtdChildren++;
                        }
                    }
                    quantityChildren = qtdChildren;
                }

                if ((!indInformPassenger) && (((toInt(quantityPeople) + toInt(quantityChildren)) - crews.length) === 0)) {
                    const isConfirmed = await confirmContext.show(title, 'Você não informou nenhum passageiro, Deseja continuar ?');
                    if (!isConfirmed) {
                        result = false;
                    } else  {
                        indResponsibleAware = true;
                        result = true;
                    }
                } else {
                    if ((toInt(quantityPeople) + toInt(quantityChildren)) > vessel.quantityPeople) {
                        const isConfirmed = await confirmContext.show(title, `Quantidade de pessoas ${(toInt(quantityPeople) + toInt(quantityChildren))} excede a ${vessel.quantityPeople} capacidade da embarcação, Deseja continuar ?`);
                        if (!isConfirmed) {
                            result = false;
                        } else  {
                            indResponsibleAware = true;
                            result = true;
                        }
                    }
                }
            }
        }

        if (emptyRequired) {
            await alertContext.show(AlertMessageEnum.FAIL, title, display.message.invalid.required);
            result = false;
        }
    
        setInvalidatedCadastreForm(!result);
        return result;
    }

    function onClick_FilterVessel(event: any) {
        event.preventDefault();
        setShowFilterVessel(true);
    }

    function selectReservation(event: any) {
        event.preventDefault();
        setShowSelectReservation(true);
    }

    async function onClick_ConfirmFilterVessel(event: any, record: any) {
        event.preventDefault();
        setVesselName('');

        try {
            const vessel = await lclService.vesselById(record.id);
            fillRecordVessel(vessel);

            setSending(false);
        } catch (error: any) {
            await alertContext.show(AlertMessageEnum.FAIL, title, error);
        }
    }

    async function onClick_ConfirmFilterCrew(event: any, conductor: any) {
        event.preventDefault();

        if (!crews) {
            setCrews([{ id : 0, kind : 1, conductorId : conductor.id, conductor }]);
        } else
            setCrews([...crews, { id : 0, kind : 1, conductorId : conductor.id, conductor }]);
    }
    
    async function onClick_ConfirmFilterPassenger(event: any, people: any) {
        event.preventDefault();

        if (!passengers) {
            setPassengers([{ id : 0, peopleId : people.id, people }]);
        } else
            setPassengers([...passengers, { id : 0, peopleId : people.id, people }]);
    }

    async function onClick_Confirm(event: any) {
        event.preventDefault();

        const isValid = await isValidForm();
        const movement = fillMovement();

        if (isValid) {
            try {
                let isConfirmed = false;
                if (movementId === 0)
                    isConfirmed = await confirmContext.show(title, 'Declaro que as informações descritas são verdadeiras.');
                else 
                    isConfirmed = await confirmContext.show(title, display.message.confirm_record);

                if (isConfirmed) {
                    setSending(true);
                    let returned = null;

                    if (movementId > 0)
                        returned = await lclService.saveMovementVessel(movement.id, movement);
                    else {
                        returned = await lclService.createMovementVessel(movement);
                        movement.id = returned.id;
                        setMovementId(returned.id);
                    }

                    setSending(false);
                    if (movementId === 0) {
                        await alertContext.show(AlertMessageEnum.SUCCESS, title, 'PN cadastrado com sucesso, insira as demais informações e tenha uma ótima navegação !');
                    } else {
                        await alertContext.show(AlertMessageEnum.SUCCESS, title, display.message.the_record_has_been_saved_successfully);
                        props.onSubmitModal(event, movement);
                    }
                }
            } catch (error: any) {
                setSending(false);
                await alertContext.show(AlertMessageEnum.FAIL, title, error);
            }
        }
    }

    async function onClick_Cancel(event : any) {
        event.preventDefault();

        setInvalidatedCadastreForm(false);
        props.onCancelModal(event);
    }

    function clearMovement() {
        // Movement
        setMovementId(0);
        setCreated('');
        setUpdated('');

        setDateMovement(dateToString(new Date(), 'dd/mm/yyyy'));
        setEnrollment('');
        setVesselName('');
        setDeparture('');
        setEstimated('');
        setForecast('');
        setStatus('');
        setArrival('');
        setIndInformPassenger(false);
        setIndResponsibleAware(false);
        setQuantityPeople(1);
        setQuantityChildren(0);
        setVessel({} as any);
        setAccessories([] as any);
        setCrews([] as any);
        setPassengers([] as any);
        setNavigationPlan('');
    }

    function fillRecordVessel(vessel: any) {
        if (vessel != null) {
            setVessel(vessel);

            setVesselName(vessel.name);
            setEnrollment(vessel.enrollment);

            let localAccessories = new Array<any>();
            const equipments = vessel.equipments;

            if (equipments) {
                for (var idx = 0; idx < equipments.length; idx++) {
                    const equipment = equipments[idx];
                    const itemAccessory = {
                        id : 0,
                        equipmentId : equipment.id,
                        vesselId : vessel.id,
                        movementId : 0, 
                        group : equipment.group,
                        category : equipment.category, 
                        name : equipment.name,
                        unity : equipment.unity, 
                        quantity : equipment.quantity,
                        withdrawn : '',
                        returned : ''
                    }
                    localAccessories[idx] = itemAccessory;
                }
            }

            setAccessories(localAccessories);
        }
    }

    function fillRecord(movement: any) {

        if (movement != null) {
            setMovementId(movement.id);
            setCreated(movement.created);
            setUpdated(movement.updated);
            
            const arrival = arrivalForecast(dateToString(movement.dateMovement, 'dd/mm/yyyy'), dateToString(movement.departure, 'HH:MM'), movement.estimated)
            let dateForecast = toDate(arrival, 'dd/mm/yyyy HH:MM:ss');

            setDateMovement(dateToString(movement.dateMovement, 'dd/mm/yyyy'));
            setDeparture(dateToString(movement.departure, 'HH:MM'));
            setEstimated(movement.estimated);
            setForecast(dateToString(dateForecast, 'HH:MM'));

            setStatus(movement.status);
            setArrival(movement.arrival);
            
            var quantity = movement.quantityPeople + movement.crews.length;
            setQuantityPeople(quantity);
            setQuantityChildren(movement.quantityChildren);
        
            fillRecordVessel(movement.vessel);

            setCrews(movement.crews);
            setPassengers(movement.passengers);
            setNavigationPlan(movement.navigationPlan);

            let localAccessories = new Array<any>();
            if (movement.accessories) {
                const accessories = movement.accessories;
                for (var idx = 0; idx < accessories.length; idx++) {
                    const accessory = accessories[idx];
                    const equipment = accessories[idx].equipment;
                    if (equipment) {
                        const itemAccessory = {
                            id : accessory.id,
                            equipmentId : accessory.equipmentId,
                            vesselId : accessory.vesselId,
                            movementId : accessory.movementId, 
                            group : equipment.group,
                            category : equipment.category, 
                            name : equipment.name,
                            unity : equipment.unity, 
                            quantity : equipment.quantity,
                            withdrawn : accessory.withdrawn,
                            returned : accessory.returned
                        }
                        localAccessories[idx] = itemAccessory;
                    }
                }
            }
            setAccessories(localAccessories);
        }
    } // fillRecord

    function fillMovement() {

        const dataCrews = [] as any;
        for (var idx_Crw = 0; idx_Crw < crews.length; idx_Crw++) {
            dataCrews[idx_Crw] = {
                id : crews[idx_Crw].id,
                kind : crews[idx_Crw].kind,
                conductorId : crews[idx_Crw].conductor.id,
                vesselId : vessel.id,
                movementId: movementId,
                created : removeFormatDate(created),
                updated : removeFormatDate(updated)
            }
        }

        const dataPassengers = [] as any;
        for (var idx_Psg = 0; idx_Psg < passengers.length; idx_Psg++) {
            dataPassengers[idx_Psg] = {
                id : passengers[idx_Psg].id,
                peopleId : passengers[idx_Psg].people.id,
                vesselId : vessel.id,
                movementId: movementId,
                created : removeFormatDate(created),
                updated : removeFormatDate(updated)
            }
        }

        const dataaAcessories = [] as any;
        for (var idx_Acs = 0; idx_Acs < accessories.length; idx_Acs++) {
            dataaAcessories[idx_Acs] = {
                id : accessories[idx_Acs].id,
                vesselId : vessel.id,
                equipmentId : accessories[idx_Acs].equipmentId,
                movementId : movementId,
                quantity : accessories[idx_Acs].quantity,
                withdrawn : accessories[idx_Acs].withdrawn,
                returned : accessories[idx_Acs].returned,
                created : removeFormatDate(created),
                updated : removeFormatDate(updated)
            }
        }

        let navigators = isEmpty(quantityPeople) ? 1 : quantityPeople;
        if (indInformPassenger){
            navigators = Number(navigators) + Number(crews.length);
        }   
        let childrens = isEmpty(quantityChildren) ? 0 : quantityChildren;
        let localEstimated = estimate(dateMovement, departure, forecast)
        let localArrival = calcArrival(`${dateMovement} ${departure}:00`, localEstimated);

        const dataMovement = {
            id: movementId,
            dateMovement : removeFormatDate(dateMovement),
            partnerId : partner.id,
            partner : partner,
            vesselId : vessel.id,
            // vessel : vessel,
            departure : removeFormatDate(dateMovement +' '+ departure +':00'),
            estimated : localEstimated,
            status,
            arrival : removeFormatDate(localArrival),
            quantityPeople : navigators,
            quantityChildren : childrens,
            indInformPassenger,
            indResponsibleAware,
            accessories : dataaAcessories,
            crews : dataCrews,
            passengers : dataPassengers,
            navigationPlan,
            created : removeFormatDate(created),
            updated : removeFormatDate(updated)
        };
        return dataMovement;
    }

    async function initialize() {
        if (props.isEdit) {
            try {
                const movement = await lclService.movementVesselById(Number(props.movementId));
                fillRecord(movement);
            } catch(error: any) {
                await alertContext.show(AlertMessageEnum.FAIL, title, error);
            }
        } else {
            clearMovement();
            setStatus('1');
        }
    }

    useEffect(() => {
        if (props.show)
            initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show]);

    /* FUNCTION EXCLUSIVE OF FORM - START */

    async function calculateEstimated(event: any) {
        event.preventDefault();
        if ((departure !== '') && (forecast !== ''))
            setEstimated(estimate(dateMovement, departure, forecast));

            if(toInt(departure.substring(0, 2)) < toInt(event.target.value.substring(0, 2))){
                setForecast(horaMask(event.target.value))
            }  else {
                setForecast('')
                await alertContext.show(AlertMessageEnum.FAIL, title, display.message.the_return_forecast_cannot_be_earlier_than_the_departure_time);
            }
    }

    async function searchVessel(event: any) {
        event.preventDefault();

        if (!isEmpty(enrollment)) {
            try {
                const vessel = await lclService.findByEnrollment(enrollment)

                if(!isEmpty(vessel)){
                    fillRecordVessel(vessel)
                } else {
                    const filter = {
                        where: { 
                            'partners.partnerId': partner.id
                        }
                    } as TFilter;
                    let vesselFilter = await lclService.vesselFilter(filter); // {count: 0, rows: []} as any; 
                    const vessels = vesselFilter.rows;
                    const vesselId = vessels.find((obj: any) => { 
                        return obj.enrollment === enrollment 
                    })
                    if (vessels && vessels.length > 0) {
                        const vessel = await lclService.vesselById(vesselId.id);
                        fillRecordVessel(vessel);
                    } else {
                        await alertContext.show(AlertMessageEnum.FAIL, title, display.message.requested_record_not_found);
                    }
                }
            } catch (error: any) {
                await alertContext.show(AlertMessageEnum.FAIL, title, error);
            }
        } else {
            await alertContext.show(AlertMessageEnum.FAIL, title, display.message.invalid.search_key_not_provided);
        }
    }

    function addCrew(event: any) {
        event.preventDefault();

        const crewList = Array<number>();
        crews.forEach((crew: any) => {
            crewList.push(crew.conductor.id);
        });
        setCrewInList(crewList);
        setShowFilterCrew(true);
    }

    function deleteCrew(event: any, crews: any, crew: any) {
        event.preventDefault();

        const crewList = Array<any>();
        crews.forEach((element: any) => {
            if (element.id !== crew.id)
                crewList.push(element);
        });
        setCrews(crewList);
    }

    function addPassenger(event: any) {
        event.preventDefault();

        const passengerList = Array<number>();
        passengers.forEach((passenger: any) => {
            passengerList.push(passenger.peopleId);
        });
        setPassengerInList(passengerList);
        setShowFilterPassenger(true);
    }

    function deletePassenger(event: any, passengers: any, passenger: any) {
        event.preventDefault();

        const passengerList = Array<any>();
        passengers.forEach((element: any) => {
            if (element.id !== passenger.id)
                passengerList.push(element);
        });
        setPassengers(passengerList);
    }

    function updateAccessoryItem(position: number, field: string, item: string) {
        const updatedInfoItemsAccessory = accessories.map((accessoryItem: any, index: number) => {
            if (index === position) {
                return { ...accessoryItem, [field]: item };
            }
            return accessoryItem;
        });
        setAccessories(updatedInfoItemsAccessory);
    }

    /* FUNCION EXCLUVISE OF FORM - END */


  const handleCloseModal = (event: any, reason: any) => {
    if (reason && reason === 'backdropClick') return
    setShowSelectReservation(false)
  }

  const fillReservationSchedule = (reservationSchedule: any) => {
    handleCloseModal('event', 'reason')
    setDeparture(reservationSchedule.departure)
    setForecast(reservationSchedule.arrives)
    setDateMovement(new Date(reservationSchedule.date).toLocaleDateString())
  }

    function viewCadastreForm() {
        return (
            <>
                <Modal show={((props.show && !showFilterVessel && !showSelectReservation) && (props.show && !showFilterCrew) && (props.show && !showFilterPassenger))}
                    size="lg"
                    aria-labelledby="modal-cadastre-movement-form"
                    centered
                >
                    <Modal.Header>
                        <Modal.Title id="modal-cadastre-movement-form"><FaRegIdCard />{title}</Modal.Title>
                        <FaTimes className="isClickable" onClick={ onClick_Cancel } size={26}/>
                    </Modal.Header>
                    <Modal.Body>
                        <Form className="form" id="form-cadastre-movement" validated={invalidatedCadastreForm}>
                            <MovementVesselRecord
                                title={title}
                                isEdit={props.isEdit}
                                hasRecord={!vessel.id}

                                created={created}
                                updated={updated}
                                id={movementId}
                                dateMovement={dateMovement}
                                enrollment={enrollment}
                                vesselName={vesselName}
                                departure={departure}
                                estimated={estimated}
                                forecast={forecast}
                                status={status}
                                arrival={arrival}
                                indInformPassenger={indInformPassenger}
                                indResponsibleAware={indResponsibleAware}
                                quantityPeople={quantityPeople}
                                quantityChildren={quantityChildren}
                                vessel={vessel}
                                accessories={accessories}
                                crews={crews}
                                passengers={passengers}
                                navigationPlan={navigationPlan}
                                setCreated={(value: any) => { setCreated(value); }}
                                setUpdated={(value: any) => { setUpdated(value); }}
                                setDateMovement={(value: any) => { setDateMovement(value); }}
                                setEnrollment={(value: any) => { setEnrollment(value); }}
                                setDeparture={(value: any) => { setDeparture(value); }}
                                setEstimated={(value: any) => { setEstimated(value); }}
                                setForecast={(value: any) => { setForecast(value); }}
                                setStatus={(value: any) => { setStatus(value); }}
                                setArrival={(value: any) => { setArrival(value); }}
                                setIndInformPassenger={(value: any) => { setIndInformPassenger(value); }}
                                setIndResponsibleAware={(value: any) => { setIndResponsibleAware(value); }}
                                setQuantityPeople={(value: any) => { setQuantityPeople(value); }}
                                setQuantityChildren={(value: any) => { setQuantityChildren(value); }}
                                setVessel={(value: any) => { setVessel(value); }}
                                setAccessories={(value: any) => { setAccessories(value); }}
                                setCrews={(value: any) => { setCrews(value); }}
                                setPassengers={(value: any) => { setPassengers(value); }}
                                setNavigationPlan={(value: any) => { setNavigationPlan(value); }}

                                calculateEstimated={(event: any) => { calculateEstimated(event); }}
                                searchVessel={(event: any) => { searchVessel(event); }}
                                addCrew={(event: any) => { addCrew(event); }}
                                deleteCrew={(event: any, crews: any, crew: any) => { deleteCrew(event, crews, crew); }}
                                addPassenger={(event: any) => { addPassenger(event); }}
                                deletePassenger={(event: any, passengers: any, passenger: any) => { deletePassenger(event, passengers, passenger); }}
                                updateAccessoryItem={(position: number, field: string, item: string) => { updateAccessoryItem(position, field, item); }}
                                showFilterVesselModal={(event: any) => { onClick_FilterVessel(event); }}
                                showSelectReservationModal={(event: any) => { selectReservation(event); }}
                            />
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="contained" color="secondary" onClick={ onClick_Cancel }>{display.buttom.cancel}</Button>
                        <Button variant="contained" color="primary" onClick={ onClick_Confirm } 
                            disabled={ sending || (toInt(vessel.typeVessel) === VESSEL_JETSKI) ? false : (navigationPlan.length < 20) ? true : false }>
                            { sending ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null }{'  '}
                            { display.buttom.confirm }
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

    return (
        <>
            { viewCadastreForm() }
            <VesselFilter 
                show={showFilterVessel}

                onClick_Close={(event: any) => { 
                    setShowFilterVessel(false);
                }}
                onClick_Confirm={(event: any, value: any) => { 
                    setShowFilterVessel(false);
                    onClick_ConfirmFilterVessel(event, value); 
                }}
            />
            <SelectReservationModal 
                show={showSelectReservation}

                onClick_Close={(event: any) => { 
                    setShowSelectReservation(false);
                }}
                onClick_Confirm={(event: any, value: any) => { 
                    setShowSelectReservation(false);
                    fillReservationSchedule(event); 
                }}
            />
            <VesselConductorFilter 
                vessel={vessel}
                crewNotInList={crewInList}
                show={showFilterCrew}

                onClick_Close={(event: any) => { 
                    setShowFilterCrew(false);
                }}
                onClick_Confirm={(event: any, value: any) => { 
                    setShowFilterCrew(false);
                    onClick_ConfirmFilterCrew(event, value); 
                }}
            />
            <PeopleFilter 
                peopleNotInList={passengerInList}
                show={showFilterPassenger}

                onClick_Close={(event: any) => { 
                    setShowFilterPassenger(false);
                }}
                onClick_Confirm={(event: any, value: any) => { 
                    setShowFilterPassenger(false);
                    onClick_ConfirmFilterPassenger(event, value); 
                }}
            />
        </>
    )
}

export default MovementCadastreModal
