import ptBR from 'date-fns/locale/pt-BR';
import moment from "moment";
import { useEffect, useState } from "react";
import { Button, Card, Col, Container, Image, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { FaEye, FaSpinner } from "react-icons/fa";
import Jet from '../../assets/Jetski.svg';
import Lancha from '../../assets/Lancha.svg';
import { useNavigate, useSearchParams } from "react-router-dom";
import { PickerButtom } from "../../components/sub-render.component";
import { useAlertMessageContext } from "../../contexts/alert-message.context";
import authService from "../../services/auth.service";
import lclService from "../../services/lcl.service";
import { calcArrival, dateToString, toDate, toInt, toLowerCase } from "../../utilities/auxiliary-functions";
import { PermissionEnum, VESSEL_LANCHA } from "../../utilities/constants";
import { cnpjMask, phoneMask, removeFormatDate, zipcodeMask } from "../../utilities/masks";
import { AlertMessageEnum, getColorDefault } from "../../utilities/types";
import OperationHeader from "../headers/operation.header";
import PlanMovementReport, { TPlanMovementReport } from "../reports/plan-movement.report";
import cduService from '../../services/cdu.service';
import { FormControl, FormHelperText, Input, InputAdornment, InputLabel, LinearProgress } from '@mui/material';
import { DataGrid, GridColDef, GridSelectionModel, GridToolbar } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search'
import { ThemeProvider } from '@mui/material/styles';
import theme from "../../theme";
import styles from './movement-history.operation.module.scss'
import { TFilter } from '../../types/FilterType';
import PersonIcon from '@mui/icons-material/Person';
import { Tooltip as TooltipMaterial } from '@mui/material';
import { useTranslateContext } from '../../contexts/translate.context';
import { usePartnerContext } from '../../contexts/partner.context';
import { isEmpty } from '../../utilities/validators';

export interface IMovementsHistoryOperationProps {
    viewOnly?: boolean
}

export const MovementsHistoryOperation: React.FC<IMovementsHistoryOperationProps> = (props: IMovementsHistoryOperationProps) => {
    
    const navigator = useNavigate();

    const alertContext = useAlertMessageContext();
    const { display } = useTranslateContext();
    const { partner } = usePartnerContext();

    const title = display.title.movement_history;
    const description = 'Histórico';

    const [ dateTimeStart, setDateTimeStart ] = useState(dateToString(new Date(), 'dd/mm/yyyy') + ' 00:00:00');
    const [ dateTimeEnd, setDateTimeEnd ] = useState(dateToString(new Date(), 'dd/mm/yyyy') + ' 23:59:59');
    
    let reloadPage = false;
    const [ isLoading, setLoading ] = useState(false);
    const [ textSearch, setTextSearch ] = useState('');
    const [ showFilter, setShowFilter]  = useState(false);
    const [ sending, setSending ] = useState(false);
    const [ selectionModel, setSelectionModel ] = useState<GridSelectionModel>([]);

    let noPaged = true;
    const [ searchParams, setSearchParams ] = useSearchParams();

    const [ pageSize, setPageSize ] = useState(10);
    const [ page, setPage ] = useState(0);
    const [ pageFilled, setPageFilled ] = useState<number[]>([]);

    const [ recordCount, setRecordCount ] = useState(0);
    const [ records, setRecords ] = useState([]);
    const [ originalRecords, setOriginalRecords ] = useState([]);

    const columns: GridColDef[] = [
        { field: 'dateMovement', headerName: display.label.date_movement, width: 110, flex: 1, align: 'center', headerAlign: 'center', valueGetter: getDateMovement },
        { field: 'identifyKey', headerName: 'Nome | ID', flex: 1, align: 'center', headerAlign: 'center', renderCell: renderNameAndId },
        { field: 'vessel.enrollment', headerName: display.label.enrollment, flex:1, align: 'center', headerAlign: 'center', valueGetter: getVesselEnrollment },
        { field: 'vessel.typeVessel', headerName: 'Tipo | Cor',flex:1,  align: 'center', headerAlign: 'center', renderCell: renderTypeAndColor },
        { field: 'vessel.brand', headerName: 'Mar | Mod',flex:1,  align: 'center', headerAlign: 'center', renderCell: renderBrandAndModel },
        { field: 'locator', headerName: 'Localizador' ,flex:1,  width: 70, align: 'center', headerAlign: 'center' },
        { field: 'departure', headerName: display.label.departure,flex:1,  width: 70, align: 'center', headerAlign: 'center', valueGetter: getDeparture },
        { field: 'arrival', headerName: 'Retorno', width: 70,flex:1,  align: 'center', headerAlign: 'center', valueGetter: getArrival },
        { field: 'estimated', headerName: 'Navegação', width: 70,flex:1,  align: 'center', headerAlign: 'center', valueGetter: getEstimated },
        { field: 'vessel.captain', headerName: 'Capitão', flex: 1, align: 'center', headerAlign: 'center', renderCell: getVesselCaptain },
        {
            field: 'action',
            headerName: 'Visualizar',
            headerAlign: 'center',
            renderCell: renderButtons,
            disableExport: true,
        }
    ];


    function renderNameAndId(param: any) {
        const {vessel} = param.row;
        return (
            <div className="d-flex flex-column align-items-center justify-content-center">
                <div className="white-space mt-2">{(vessel.name) ? vessel.name : '-'}</div>
                <div className="white-space mt-1">{vessel.identifyKey}</div>
            </div>
        );
    }

    function renderCaptain(param: any) {
        const {vessel} = param.row;
        return (
            <Tooltip className="isClickable" title={vessel.captain}>
            <div className="d-flex align-items-center justify-content-center mt-1">
                <PersonIcon />
            </div>
        </Tooltip>
        );
    }

    function getDateMovement(param: any) {
        const movement = param.row;
        return dateToString(movement.dateMovement, 'dd/mm/yyyy');
    }


    function getVesselEnrollment(param: any) {
        const movement = param.row;
        return movement.vessel?.enrollment || '-';
    }

    function renderTypeAndColor(param: any) {
        const movement = param.row;

        return movement.vessel?.typeVessel === VESSEL_LANCHA ? (
            <div className="d-flex flex-column align-items-center justify-content-center mb-2">
                <div className="mt-3 d-flex justify-content-center mb-2">
                    <Image src={Lancha} className="jet" style={{height: 32}}/>
                    <small className="ms-2">{`${movement.vessel?.vesselSize}'`}</small>
                </div>
                <span className={styles.vesselColor} style={{backgroundColor: `${movement.vessel?.color}`}}></span>
            </div>
        ):(
            <div className="d-flex flex-column align-items-center justify-content-center mb-2">
                <div className="d-flex justify-content-center mb-2">
                    <Image src={Jet} className="jet mt-3" style={{height: 32}}/>
                    <small className="ms-1 mt-3">{`10'`}</small>
                </div>
                <span className={styles.vesselColor} style={{backgroundColor: `${movement.vessel?.color}`}}></span>
            </div>
        );
    }

    function renderBrandAndModel(param: any) {
        const movement = param.row;

        return (
            <div className="d-flex flex-column justify-content-center mb-2">
                <div className="mt-3">{movement.vessel?.brand || '-'}</div>
                <div className="mt-1">{movement.vessel?.model || '-'}</div>
            </div>
        );
    }

    function getDeparture(param: any) {
        const movement = param.row;
        return dateToString(movement.departure, 'HH:MM');
    }

    function getEstimated(param: any) {
        const movement = param.row;
        return movement.estimated;
    }

    function getArrival(param: any) {
        const movement = param.row;
        const landing = toDate(removeFormatDate(calcArrival(movement.departure, movement.estimated)), 'yyyy-mm-ddTHH:MM:ss.sssZ');

        return dateToString(landing, 'HH:MM')
    }

    function getVesselCaptain(param: any) {
        const movement = param.row;

        let captainName = '-';
        if (movement?.crews) {
            for (let ind = 0; ind < movement?.crews.length; ind++) {
                const crew = movement?.crews[ind];
                if (crew.conductor) {
                    if (crew.kind === '1') {
                        captainName = crew.conductor.people?.fullname;
                        break;
                    }
                }
            };
            
        }
        return (
            <TooltipMaterial className="isClickable" title={captainName}>
                <div className="d-flex align-items-center justify-content-center mt-1">
                    <PersonIcon />
                </div>
        </TooltipMaterial>
        )
    }

    function renderButtons(param: any) {
        let movementRows = [] as any;
        const originals = param.row;
        for (var o = 0; o < originals.length; o++) {
            movementRows[movementRows.length] = originals[o].original;
        }
        const movementRow = param.row;

        return (
            <div className="d-flex w-100 justify-content-center">
                {authService.hasPermission(PermissionEnum.OPERATION_MOVEMENT_EDIT) && 
                    <OverlayTrigger overlay={<Tooltip id="tooltip">Visualizar Plano de Navegação</Tooltip>}>
                        <span onClick={(e) => onClick_EditMovement(e, movementRow)}>
                            <FaEye size={18} />
                        </span>
                    </OverlayTrigger>
                }
            </div>
        );
    }

    const onChange_DateTimeStart = async (date: any) => {
        const dateStop = toDate(dateTimeEnd, 'dd/mm/yyyy HH:MM:ss');
        if (date > dateStop) {
            setDateTimeStart(dateToString(dateStop, 'dd/mm/yyyy') + ' 00:00:00');
        } else {
            setDateTimeStart(dateToString(date, 'dd/mm/yyyy HH:MM:ss'));

            let parameterMaximumDaysInConsultations = await cduService.parameterByCode(partner.companyId, '10');
            let fim = moment(date).day(toInt(parameterMaximumDaysInConsultations.content) + 1);
            setDateTimeEnd(fim.format('DD/MM/YYYY 23:59:59'));
        }

        setRecords([]);
        setOriginalRecords([]);
        setRecordCount(0);
        setPageFilled([]);
    }

    const onChange_DateTimeStop = (date: any) => {
        const dateStart = toDate(dateTimeStart, 'dd/mm/yyyy HH:MM:ss');
        if (date < dateStart) {
            setDateTimeEnd(dateToString(dateStart, 'dd/mm/yyyy') + ' 23:59:59');
        } else {
            setDateTimeEnd(dateToString(date, 'dd/mm/yyyy HH:MM:ss'));
        }

        setRecords([]);
        setOriginalRecords([]);
        setRecordCount(0);
        setPageFilled([]);
    }

    function onClick_RefreshMovement(event: any) {
        event.preventDefault();
        
        setSending(true);
        searchFilter(dateTimeStart, dateTimeEnd);
    } // onClick_RefreshMovement
    
    async function onClick_EditMovement(event: any, movement: any) {
        event.preventDefault();

        const currentMovement = await lclService.movementVesselById(movement.id);
        const address = await cduService.addressById(partner.company.addressId);
        const planMovement : TPlanMovementReport = {
            company: {
                image: partner.company.image,
                socialReason: partner.company.socialReason,
                fantasy: partner.company.fantasy,
                registrationPj: cnpjMask(partner.company.registrationPj),
                telephone: phoneMask(partner.company.telephone),
                email: partner.company.email,
                address: `${address.place} - ${address.district} - ${address.city} - ${address.state} - ${zipcodeMask(address.zipcode)}`
            },
            movement: {
                departure: dateToString(currentMovement.departure, 'dd/mm/yyyy'),
                estimated: dateToString(currentMovement.departure, 'HH:MM'),
                arrival: dateToString(toDate(removeFormatDate(calcArrival(currentMovement.departure, currentMovement.estimated)), 'yyyy-mm-ddTHH:MM:ss.sssZ'), 'HH:MM'),
                locator: currentMovement.locator,
                navigationPlan: currentMovement.navigationPlan
            },
            vessels: [{
                identifyKey: currentMovement.vessel.identifyKey,
                enrollment: currentMovement.vessel.enrollment,
                name: currentMovement.vessel.name,
                color: display.label[toLowerCase(getColorDefault(currentMovement.vessel.color).name)],
                brand: currentMovement.vessel.brand,
                model: currentMovement.vessel.model
            }],
            captain: {
                name: currentMovement?.crews[0]?.conductor?.people?.fullname,
                enrollment: currentMovement?.crews[0]?.conductor?.people?.documentsNautical[0]?.enrollment,
                expedition: dateToString(currentMovement?.crews[0]?.conductor?.people?.documentsNautical[0]?.expedition, 'dd/mm/yyyy'),
                validity: dateToString(currentMovement?.crews[0]?.conductor?.people?.documentsNautical[0]?.validity, 'dd/mm/yyyy'),
                limits: currentMovement?.crews[0]?.conductor?.people?.documentsNautical[0]?.limits
            },
            passengers: currentMovement.passengers.map((passenger: any) => {
                return {
                    name: passenger.people.fullname
                }
            }),
            payday: dateToString(new Date(), 'dd/mm/yyyy'),
        }
        PlanMovementReport(planMovement);
    } // onClick_EditRecord

    async function searchFilter(dateTimeStart: string, dateTimeEnd: string) {
        setLoading(true);

        /* Sempre incluir o partner na pesquisa */
        if (!isEmpty(partner)) {
            try {
                setDateTimeStart(dateTimeStart);
                setDateTimeEnd(dateTimeEnd);

                let localPageSize = pageSize;
                let localPage = page;

                if (noPaged) {
                    if (searchParams.has('limit')) {
                        let paramPageSize = searchParams.get('limit');
                        if (paramPageSize) {
                            // eslint-disable-next-line react-hooks/exhaustive-deps
                            localPageSize = Number.parseInt(paramPageSize);
                            setPageSize(localPageSize);
                        }
                    }
                    if (searchParams.has('offset')) {
                        let paramPage = searchParams.get('offset');
                        if (paramPage) {
                            // eslint-disable-next-line react-hooks/exhaustive-deps
                            localPage = Number.parseInt(paramPage);
                            setPage(localPage);
                        }
                    }
                }

                if (pageFilled.indexOf(localPage) === -1) {
                    const filter = {
                        attributes: [
                           'id', 'dateMovement', 'departure', 'estimated', 'status', 'locator', 'move', 'checkIn', 'registrationCheckIn', 'dateCheckIn', 
                            'sailorRequested', 'queuePosition', 'arrival', 'quantityPeople', 'quantityChildren',
                            'crews.kind', 
                            'crews.conductorId', 
                            'crews.conductor.peopleId',
                            'crews.conductor.people.fullname', 
                            'crews.conductor.people.gender', 
                            'crews.conductor.people.registrationPf',
                            'vessel.identifyKey', 'vessel.name', 'vessel.enrollment', 'vessel.typeVessel', 'vessel.vesselSize', 'vessel.color', 'vessel.quantityPeople', 'vessel.brand', 'vessel.model',
                        ],
                        where: {
                            partnerId: partner.id,
                            dateMovement: { start: removeFormatDate(dateTimeStart), end: removeFormatDate(dateTimeEnd) }
                        },
                        order: [ [ 'departure', 'ASC' ] ],
                        limit: localPageSize, 
                        offset: localPage * localPageSize
                    } as TFilter;
                    const movementVesselFilter = await lclService.movementVesselFilter(filter);
                    setRecordCount(movementVesselFilter.count);
                    const array = [] as any;
                    records.forEach((record: any) => array.push(record));
                    movementVesselFilter.rows.forEach((record: any) => array.push(record));

                    setRecords(array);
                    setOriginalRecords(array);
                    setSending(false);

                    if (array.length > 0)
                        pageFilled.push(localPage);
                }
            } catch(error: any) {
                await alertContext.show(AlertMessageEnum.FAIL, title, error);
            } finally {
                setSending(false);
                setLoading(false);
                setShowFilter(false);
            }
        } else {
            await alertContext.show(AlertMessageEnum.FAIL, title, 'Não foi definido uma Marina para exibir o cadastro !');
            setLoading(false);
            navigator(`/portal`);
        }
    } // searchFilter

    const onChangePageSize = (newPageSize: number) => {
        setSearchParams({...searchParams, 'limit': `${newPageSize}`, 'offset': `${0}`});

        setRecords([]);
        setOriginalRecords([]);
        setRecordCount(0);
        setPageFilled([]);
        setPageSize(newPageSize);
        setPage(0);
    }
    
    const onChangePage = (newPage: number) => {
        setSearchParams({...searchParams, 'offset': `${newPage}`});

        setPage(newPage);
    }

    let effectForPage = false;

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        noPaged = false;
        let paramPage = searchParams.get('offset');
        if (paramPage) {
            searchFilter(dateTimeStart, dateTimeEnd);
            effectForPage = true;
        }
    }, [pageSize, page]); // useEffect

    useEffect(() => {
        if (!isLoading && !reloadPage) {
            noPaged = true;
            if (!effectForPage) {
                let inicio = moment().day(0); // domingo desta semana
                let fim = moment().day(6); // sábado desta semana

                let startDate = inicio.format('DD/MM/YYYY 00:00:00');
                let endDate = fim.format('DD/MM/YYYY 23:59:59');
                
                searchFilter(startDate, endDate);
                effectForPage = false;
            }
        }

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            reloadPage = !reloadPage;
        }
    }, []); // useEffect

    const filtered = (key?: string, value?: string) => {
        const filtered: any = []
        records.filter((record: any) => {
            if (
                record.vesselIdentifyKey
                ?.toLocaleLowerCase()
                ?.includes(textSearch?.toLocaleLowerCase()) ||
                record.vesselName
                ?.toLocaleLowerCase()
                ?.includes(textSearch?.toLocaleLowerCase()) ||
                record.vesselEnrollment
                ?.toLocaleLowerCase()
                ?.includes(textSearch?.toLocaleLowerCase())
            )
            filtered.push(record)
            return record
        })
        setRecords(filtered)
        return ''
    }

    useEffect(() => {
        if (textSearch.length >= 3) {
            filtered()
        } else if (textSearch.length < 3) {
            setRecords(originalRecords)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [textSearch])

    function viewPage() {

        return (
            <Container fluid className="page-body movements">
                <Row>
                    <Col md={12} className="page-sweet">
                        <Card className={styles.movements} style={{ minHeight : 500 }}>
                            <Card.Header>
                                <div className="card-actions d-flex justify-content-start">
                                    <div className="card-actions-time me-2">
                                        <DatePicker
                                            locale={ptBR}
                                            selected={toDate(dateTimeStart, 'dd/mm/yyyy HH:MM:ss')}
                                            onChange={(date: any) => onChange_DateTimeStart(date) }
                                            dateFormat="dd/MM/yyyy"
                                            customInput={<PickerButtom />}
                                            selectsStart
                                            startDate={toDate(dateTimeStart, 'dd/mm/yyyy HH:MM:ss')}
                                            endDate={toDate(dateTimeEnd, 'dd/mm/yyyy HH:MM:ss')}

                                            popperClassName="some-custom-class"
                                            popperPlacement="top-end"
                                            popperModifiers={[
                                                {
                                                    name : "offset",
                                                    options : {
                                                        offset : [5, 10]
                                                    }
                                                },{
                                                    name : "preventOverflow",
                                                    options : {
                                                        rootBoundary : "viewport",
                                                        tether : false,
                                                        altAxis : true
                                                    }
                                                }
                                            ]}
                                        /> 
                                        <DatePicker
                                            locale={ptBR}
                                            selected={toDate(dateTimeEnd, 'dd/mm/yyyy HH:MM:ss')}
                                            onChange={(date: any) => onChange_DateTimeStop(date) }
                                            dateFormat="dd/MM/yyyy"
                                            customInput={<PickerButtom />}
                                            selectsEnd
                                            startDate={toDate(dateTimeStart, 'dd/mm/yyyy HH:MM:ss')}
                                            endDate={toDate(dateTimeEnd, 'dd/mm/yyyy HH:MM:ss')}
                                            minDate={toDate(dateTimeStart, 'dd/mm/yyyy HH:MM:ss')}

                                            popperClassName="some-custom-class"
                                            popperPlacement="top-end"
                                            popperModifiers={[
                                                {
                                                    name : "offset",
                                                    options : {
                                                        offset : [5, 10]
                                                    }
                                                },{
                                                    name : "preventOverflow",
                                                    options : {
                                                        rootBoundary : "viewport",
                                                        tether : false,
                                                        altAxis : true
                                                    }
                                                }
                                            ]}
                                        />
                                    </div>
                                     <Button variant="secondary" onClick={ (e) => onClick_RefreshMovement(e) } disabled={sending}>
                                        { sending ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <FaSpinner size={22} /> } {' '}
                                        {display.buttom.refresh}
                                    </Button>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <FormControl fullWidth sx={{width: 300, marginBottom: 2 }} variant="standard">
                                    <InputLabel className={styles.label} htmlFor="standard-adornment-amount">Pesquisar</InputLabel>
                                    <Input id="standard-adornment-amount"
                                        value={textSearch}
                                        onChange={(e) => setTextSearch(e.target.value)}
                                        endAdornment = {
                                            <InputAdornment position="end">
                                                <SearchIcon className='isClickable' />
                                            </InputAdornment>
                                        }
                                    />
                                    <FormHelperText id="component-helper-text">
                                        ID, Nome ou Registro da Embarcação
                                    </FormHelperText>
                                </FormControl>
                                <ThemeProvider theme={theme}>
                                    <div style={{ height: 402, width: '100%', marginTop: 0 }}>
                                        <DataGrid
                                            rows={records}
                                            rowCount={recordCount}
                                            getRowId={row => row.id}
                                            loading={isLoading}
                                            columns={columns}
                                            pageSize={pageSize}
                                            onPageSizeChange={newPageSize => onChangePageSize(newPageSize)}
                                            page={page}
                                            onPageChange={newPage => onChangePage(newPage) }
                                            
                                            rowsPerPageOptions={[10, 25, 50, 75, 100]}
                                            disableSelectionOnClick
                                            rowHeight={70}
                                            // onCellClick={(e) => console.log(e)}
                                            onSelectionModelChange={(newSelectionModel: any) => {
                                                setSelectionModel(newSelectionModel)
                                            }}
                                            components={{
                                                Toolbar: GridToolbar,
                                                LoadingOverlay: LinearProgress
                                            }}
                                            selectionModel={selectionModel}
                                        />
                                    </div>
                                </ThemeProvider>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    } // viewPage

    return (
        <div className="page">
            <OperationHeader title={title} description={description} />
            { viewPage() }
        </div>
    );

}

export default MovementsHistoryOperation;