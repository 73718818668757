import { useEffect, useState } from 'react';
import { Image, Nav } from 'react-bootstrap';
import { FaBook, FaDesktop, FaFileContract, FaMoneyCheckAlt, FaRegCalendarCheck, FaShip, FaSignOutAlt, FaStoreAlt, FaTools, FaTractor, FaUserCog, FaUsers, FaWarehouse } from 'react-icons/fa';
import { MdAddBusiness, MdAssessment, MdDashboard } from 'react-icons/md';

import { MenuItem } from './side-menu-item.component';

import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import BadgeIcon from '@mui/icons-material/Badge';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import HistoryIcon from '@mui/icons-material/History';
import { BiCategory } from 'react-icons/bi';
import { ImUserTie } from 'react-icons/im';
import { RiBillLine, RiContactsBookFill } from 'react-icons/ri';
import Logo from '../assets/logo_registrada.png';
import authService from '../services/auth.service';
import styles from './side-menu.component.module.scss';
import InventoryIcon from '@mui/icons-material/Inventory';
import PersonIcon from '@mui/icons-material/Person';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder'
import SearchIcon from '@mui/icons-material/Search';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ShareIcon from '@mui/icons-material/Share';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import WashIcon from '@mui/icons-material/Wash';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import { useTranslateContext } from '../contexts/translate.context';

export const menuItems = [
    {
        name: 'dashboard',
        to: '/portal/dashboard',
        iconClassName: <FaDesktop size={23} />,
        subMenus: [
            /*{ name: 'customize', to: '/portal/dashboard/customize', iconClassName: <DashboardCustomizeIcon /> },*/
            { name: 'management_daily', to: '/portal/dashboard/maritime_v2', iconClassName: <MdDashboard size={23} /> },
            /*{ name: 'cleaning_management', to: '/portal/dashboard/cleaning_v2', iconClassName: <WashIcon className={styles.menuIcon} /> },*/
            { name: 'management_financial', to: '/portal/dashboard/financial_v2', iconClassName: <RiBillLine size={23} /> },
            { name: 'cleaning_management', to: '/portal/dashboard/cleaning_v2', iconClassName: <WashIcon className={styles.menuIcon} /> },
            /*{ name: 'stock', to: '/portal/dashboard/stock_v2', iconClassName: <InventoryIcon className={styles.menuIcon} /> },
            { name: 'sharing_management', to: '/portal/dashboard/sharing_v2', iconClassName: <ShareIcon className={styles.menuIcon} /> },
            { name: 'service_center_management', to: '/portal/dashboard/service-center_v2', iconClassName: <FaTools size={23} /> },
            { name: 'delivery_management', to: '/portal/dashboard/delivery_v2', iconClassName: <FastfoodIcon className={styles.menuIcon} /> },
            { name: 'hourly_occupation', to: '/portal/dashboard/hourly-occupation_v2', iconClassName: <QueryBuilderIcon className={styles.menuIcon} /> },
            { name: 'hourly_prediction', to: '/portal/dashboard/hourly-prediction_v2', iconClassName: <HistoryIcon className={styles.menuIcon} /> },*/
            { divider: true },
            /*{ name: 'management_daily', to: '/portal/dashboard/maritime', iconClassName: <MdDashboard size={23} /> },*/
            /*{ name: 'management_financial', to: '/portal/dashboard/financial', iconClassName: <RiBillLine size={23} /> },*/
            /*{ name: 'cleaning_management', to: '/portal/dashboard/cleaning', iconClassName: <WashIcon className={styles.menuIcon} /> },*/
            { name: 'stock', to: '/portal/dashboard/stock', iconClassName: <InventoryIcon className={styles.menuIcon} /> },
            { name: 'sharing_management', to: '/portal/dashboard/sharing', iconClassName: <ShareIcon className={styles.menuIcon} /> },
            { name: 'service_center_management', to: '/portal/dashboard/service-center', iconClassName: <FaTools size={23} /> },
            { name: 'delivery_management', to: '/portal/dashboard/delivery', iconClassName: <FastfoodIcon className={styles.menuIcon} /> },
            { name: 'hourly_occupation', to: '/portal/dashboard/hourly-occupation', iconClassName: <QueryBuilderIcon className={styles.menuIcon} /> },
            { name: 'hourly_prediction', to: '/portal/dashboard/hourly-prediction', iconClassName: <HistoryIcon className={styles.menuIcon} /> },
        ],
    },
    {
        name: 'cadastre',
        to: '/portal/cadastre',
        iconClassName: <FaBook size={23} />,
        subMenus: [
            { name: 'category', to: '/portal/cadastre/category', iconClassName: <BiCategory size={23} /> },
            { name: 'product', to: '/portal/cadastre/product', iconClassName: <ShoppingCartIcon className={styles.menuIconSmall} /> },
            { divider: true },
            { name: 'partner', to: '/portal/cadastre/partner', iconClassName: <MdAddBusiness size={23} /> },
            { name: 'member', to: '/portal/cadastre/member', iconClassName: <FaUsers size={23} /> },
            { name: 'vessel', to: '/portal/cadastre/vessel', iconClassName: <FaShip size={23} /> },
            { divider: true },
            { name: 'purveyor', to: '/portal/cadastre/purveyor', iconClassName: <ImUserTie size={23} /> },
            { name: 'employee', to: '/portal/cadastre/employee', iconClassName: <PersonIcon className={styles.menuIcon} /> },
            { name: 'mechanic', to: '/portal/cadastre/mechanic', iconClassName: <FaUserCog size={23} /> }
        ],
    },
    { divider: true },
    { 
        name: 'member', 
        to: '/portal/member', 
        iconClassName: <FaUsers size={23} /> 
    },
    { 
        name: 'vessels', 
        to: '/portal/vessel', 
        iconClassName: <FaShip size={23} /> 
    },
    { 
        name: 'employee', 
        to: '/portal/employee',
        iconClassName: <PersonIcon className={styles.menuIcon} /> 
    },
    { 
        name: 'mechanic', 
        to: '/portal/mechanic', 
        iconClassName: <FaUserCog className={styles.menuIcon} /> 
    },
    {
        name: 'products',
        to: '/portal/product',
        iconClassName: <ShoppingCartIcon className={styles.menuIcon} />,
    },
    { divider: true },
    { 
        name: 'delivery', 
        to: '/portal/delivery', 
        iconClassName: <FastfoodIcon className={styles.menuIcon} /> 
    },
    { 
        name: 'provideds', 
        to: '/portal/provideds', 
        iconClassName: <SettingsSuggestIcon className={styles.menuIcon} />,
        subMenus: [
            { name: 'cleaning', to: '/portal/provideds/cleaning', iconClassName: <WashIcon className={styles.menuIcon} /> },
        ],
    },
    {
        name: 'stock',
        to: '/portal/stock',
        iconClassName: <InventoryIcon className={styles.menuIcon} />,
        subMenus: [
            { name: 'movement', to: '/portal/stock/search', iconClassName: <SearchIcon className={styles.menuIcon} /> },
        ],
    },
    /*{
        name: 'navigation',
        to: '/portal/navigation',
        iconClassName: <img
        alt="Navegando"
        src={Navegando}
        className={styles.menuIcon}
        style={{ height: 23, width: 23 }}
      />,
        subMenus: [
            { name: 'schedule', to: '/portal/navigation/schedule', iconClassName: <TodayIcon className={styles.menuIconSmall} /> },
            { name: 'navigation_plan', to: '/portal/navigation/navigation_plan', iconClassName: <ContentPasteIcon className={styles.menuIconSmall} /> },
            { name: 'history', to: '/portal/navigation/history', iconClassName: <HistoryIcon className={styles.menuIconSmall} /> },
        ],
    },*/
    {
        name: 'operation',
        to: '/portal/operation',
        iconClassName: <FaStoreAlt size={23} />,
        subMenus: [
            { name: 'movement', to: '/portal/operation/movement', iconClassName: <FaTractor size={23} /> },
            { name: 'navigation_plan', to: '/portal/operation/movement-history', iconClassName: <ContentPasteIcon className={styles.menuIconSmall} /> },
            { name: 'keep_boat', to: '/portal/operation/keep_boat', iconClassName: <FaWarehouse size={23} /> },
            { divider: true },
            { name: 'assessment', to: '/portal/operation/assessment', iconClassName: <MdAssessment size={23} /> }
        ],
    },
    {
        name: 'office',
        to: '/portal/office',
        iconClassName: <FaTools size={23} />,
        subMenus: [
            { name: 'budget', to: '/portal/office/budget', iconClassName: <FormatListBulletedIcon className={styles.menuIcon} /> },
            { name: 'schedule', to: '/portal/office/schedule', iconClassName: <FaRegCalendarCheck size={23} /> }
            // { divider: true },
        ],
    },
    {
        name: 'financial',
        to: '/portal/financial',
        iconClassName: <AccountBalanceWalletIcon fontSize="medium" className={styles.menuIcon} />,
        subMenus: [
            { name: 'contract', to: '/portal/financial/contract', iconClassName: <FaFileContract size={23} /> },
            { name: 'contract_management', to: '/portal/financial/contract-management', iconClassName: <BadgeIcon className={styles.menuIcon} /> },
            { name: 'contract_customer', to: '/portal/financial/contract-customer', iconClassName: <RiContactsBookFill className={styles.menuIcon} /> },
            { name: 'launcher', to: '/portal/financial/launcher', iconClassName: <FaMoneyCheckAlt size={23} /> }
            //{ name: 'conciliation', to: '/portal/financial/conciliation', iconClassName: <FaCoins size={23} /> }
        ],
    }
];

export interface ISideMenuProps {
    onCollapse: any,
    onCloseSideMenu: any,
    onSignout: any
}

export const SideMenu: React.FC<ISideMenuProps> = (props: ISideMenuProps) => {

    const { display } = useTranslateContext();

    const [ menuInactive, setMenuInactive ] = useState([{ isOpened: false, name: '' }] as any);

    const removeActiveClassFromSubMenu = () => {
        const elements = document.querySelectorAll(".sidebar-item");
        elements.forEach((element) => {
            element.classList.remove("active");
        });
    };

    function onClick_Logout(event: any) {
        event.preventDefault();

        authService.logout();
        window.location.href = '/';
    }
    
    useEffect(() => {
        let localMenuItems = document.querySelectorAll(".sidebar-item");
        localMenuItems.forEach((el) => {
            el.addEventListener("click", (e) => {
                removeActiveClassFromSubMenu();
                localMenuItems.forEach((el) => el.classList.remove("active"));
                el.classList.toggle("active");
            });
        });

        let localMenuInactive = new Array(menuItems.length);
        for (var i = 0; i < menuItems.length; i++) {
            const item = menuItems[i];
            localMenuInactive[i] = {
                isOpened: false,
                name: item.name
            }; 
        }
        setMenuInactive(localMenuInactive);
    }, []);

    return (
        <>
            <Nav className={`sidebar ${props.onCollapse ? "toggled" : ""}`}>
                <div className="sidebar-content">
                    <a className="sidebar-brand" href="/">
                        <Image src={Logo} className="logo" />
                        <span className="align-middle"></span>
                    </a>
                    <ul className="sidebar-nav">
                        {menuItems.map((menuItem, index) => (
                            menuItem.divider ? (
                                <li className="sidebar-item" key={index}><span className="sidebar-link"><span className="sidebar-divider"></span></span></li>
                            ) : (
                                authService.permissionIn(menuItem.name || '') &&
                                <MenuItem
                                    key={index}
                                    name={menuItem.name}
                                    to={menuItem.to}
                                    subMenus={menuItem.subMenus || []}
                                    iconClassName={menuItem.iconClassName}
                                    onClick={(event: any) => {
                                        const updatedMenuInactive = menuInactive.map((standardItem: any, position: number) => {
                                            if ((index === position) && (event.target.innerText === display.label[standardItem.name])) {
                                                return { ...standardItem, isOpened: !standardItem.isOpened };
                                            }
                                            return standardItem;
                                        });
                                        setMenuInactive(updatedMenuInactive);
                                    }}
                                    expanded={menuInactive[index]}
                                    onCloseSideMenu={props.onCloseSideMenu}
                                />
                            )
                        ))}
                    </ul>
                    <div className="sidebar-logout" onClick={(e) => onClick_Logout(e)}>
                        <FaSignOutAlt />{display.label.go_out}
                    </div>
                </div>
            </Nav>
        </>
    )

}
