import { useEffect, useState } from "react";
import { Col, Dropdown, Form, FormGroup, InputGroup, Modal, Row, Spinner } from "react-bootstrap";
import { FaMinusSquare, FaPlusSquare, FaRegIdCard, FaTimes } from "react-icons/fa";
import { useAlertMessageContext } from "../../contexts/alert-message.context";
import { useConfirmMessageContext } from "../../contexts/confirm-message.context";
import { Bookcase } from "../../models/keep-bookcase.model";
import lclService from "../../services/lcl.service";
import { dateToString, leftPad, toLowerCase } from "../../utilities/auxiliary-functions";
import { removeFormatDate } from "../../utilities/masks";
import { AlertMessageEnum, ColorDefaultType } from "../../utilities/types";
import { isEmpty } from "../../utilities/validators";
import { Button } from '@mui/material'
import { useTranslateContext } from "../../contexts/translate.context";
import { usePartnerContext } from "../../contexts/partner.context";

export interface IBookcaseCadastreModalProps {
    isEdit: boolean,
    show: boolean,
    bookcaseId: string,
    record: any;

    onSubmitModal: any,
    onCancelModal: any
}

export const BookcaseCadastreModal: React.FC<IBookcaseCadastreModalProps> = (props: IBookcaseCadastreModalProps) => {

    const alertContext = useAlertMessageContext();
    const confirmContext = useConfirmMessageContext();
    const { display } = useTranslateContext();
    const { partner } = usePartnerContext();

    const title : string = display.title.bookcase_cadastre;

    const [ invalidatedCadastreForm, setInvalidatedCadastreForm ] = useState(false);

    // const [ cadastreFormShow, setCadastreFormShow ] = useState(true);
    const [ sending, setSending ] = useState(false);
    const [ isSequential, setSequential ] = useState(false);
    const [ ordinal, setOrdinal ] = useState(0);

    // Bookcase
    const [ id, setId ] = useState(0);
    const [ created, setCreated ] = useState(dateToString(new Date(), 'dd/mm/yyyy HH:MM:ss'));
    const [ updated, setUpdated ] = useState(dateToString(new Date(), 'dd/mm/yyyy HH:MM:ss'));

    const [ name, setName ] = useState('');
    const [ shelves, setShelves ] = useState([] as any);
    
    async function isValidForm(bookcase: any) {
        let result = true;
        let emptyRequired = false;

        if (isEmpty(bookcase.name))
            emptyRequired = true;

        if (bookcase.shelves.length === 0) {
            await alertContext.show(AlertMessageEnum.FAIL, title, display.message.no_shelf_was_informed);
            result = false;
        }

        if (bookcase.shelves.length > 0) {
            for (var i = 0; i < bookcase.shelves.length; i++) {
                const shelf = bookcase.shelves[i];
                if (isEmpty(shelf.name))
                    emptyRequired = true;
            }
        }

        if (emptyRequired) {
            await alertContext.show(AlertMessageEnum.FAIL, title, display.message.invalid.required);
            result = false;
        }
        
        setInvalidatedCadastreForm(!result);
        return result;
    }

    async function onClick_Confirm(event: any) {
        event.preventDefault();

        const bookcase = fillBookcase();
        const isValid = await isValidForm(bookcase);
        if (isValid) {
            try {
                const isConfirmed = await confirmContext.show(title, display.message.confirm_record);
                if (isConfirmed) {
                    setSending(true);

                    if (bookcase.id > 0)
                        await lclService.saveBookcase(bookcase.id, bookcase);
                    else
                        await lclService.createBookcase(bookcase);

                    setSending(false);
                    await alertContext.show(AlertMessageEnum.SUCCESS, title, display.message.the_record_has_been_saved_successfully);
                    props.onSubmitModal(event, bookcase);
                }
            } catch (error: any) {
                setSending(false);
            }
        }
    }

    async function onClick_Cancel(event : any) {
        event.preventDefault();

        setInvalidatedCadastreForm(false);
        props.onCancelModal(event);
    }

    function clearBookcase() {
        // Bookcase
        setId(0);
        setName('');
        setShelves([]);
        setCreated('');
        setUpdated('');
    }

    function fillRecord(bookcase: any) {
        if (bookcase != null) {
            setId(bookcase.id);
            setName(bookcase.name);
            setShelves(bookcase.shelves);
            setCreated(bookcase.created);
            setUpdated(bookcase.updated);
        }
    } // fillRecord

    function fillBookcase() {

        const dataShelves = [] as any;
        for (var idx_Shv = 0; idx_Shv < shelves.length; idx_Shv++) {
            const shelf = shelves[idx_Shv];

            const dataDrawers = [] as any;
            for (var idx_Drw = 0; idx_Drw < shelf.drawers.length; idx_Drw++) {
                const drawer = shelf.drawers[idx_Drw];

                dataDrawers[idx_Drw] = {
                    id : drawer.id,
                    shelfId : shelf.id,
                    order : drawer.order,
                    name : drawer.name,
                    color : drawer.color,
                    source : drawer.source,
                    identy : drawer.identy,
                    created : removeFormatDate(created),
                    updated : removeFormatDate(updated)
                }
            }    

            dataShelves[idx_Shv] = {
                id : shelf.id,
                bookcaseId : id,
                order : shelf.order,
                name : shelf.name,
                drawers : dataDrawers, //shelf.drawers,
                created : removeFormatDate(created),
                updated : removeFormatDate(updated)
            }
        }

        const dataBookcase = {
            id,
            partnerId : partner.id,
            name,
            order : 0,
            shelves : dataShelves,
            created : removeFormatDate(created),
            updated : removeFormatDate(updated)
        };
        return dataBookcase;
    }

    async function initialize() {
        if (props.isEdit) {
            try {
                const bookcase = await lclService.bookcaseById(Number(props.bookcaseId));
                fillRecord(bookcase);
            } catch(error: any) {
                await alertContext.show(AlertMessageEnum.FAIL, title, error);
            }
        } else {
            clearBookcase();
            setOrdinal(0);

            const bookcase = new Bookcase();
            fillRecord(bookcase);
        }
    }

    useEffect(() => {
        if (props.show)
            initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show]);

    /* FUNCTION EXCLUSIVE OF FORM - START */

    function onChange_Shelves(event: any) {
        event.preventDefault();

        const value = event.target.value;

        const localShelves = [] as any;
        for (var i = 0; i < shelves.length; i++) {
            localShelves[localShelves.length] = shelves[i];
        }

        if (value > shelves.length) {
            localShelves.push({id: 0, bookcaseId: id, order: shelves.length, name: '', drawers: []});
        } else if (value < shelves.length) {
            localShelves.pop();
        }
        setShelves(localShelves);
    }

    function onChange_ShelfItem(event: any, shelfIndex: number, field: string) {
        const updatedShelvesItems = shelves.map((shelfItem: any, indexShelf: number) => {
            if (indexShelf === shelfIndex) {
                const value = event.target.value;
                return { ...shelfItem, [field]: value };
            }
            return shelfItem;
        });
        setShelves(updatedShelvesItems);
    }

    function onChange_DrawerItem(event: any, shelfIndex: number, drawerIndex: number, source: string, field: string) {
        let value = event;
        if (typeof value !== 'string')
            value = event.target.value

        const updatedShelvesItems = shelves.map((shelfItem: any, indexShelf: number) => {
            if (indexShelf === shelfIndex) {
                const drawers = shelfItem.drawers;
                const updatedDrawerItems = drawers.map((drawerItem: any, indexDrawer: number) => {
                    if (indexDrawer === drawerIndex) {
                        return { ...drawerItem, [field]: value };
                    }
                    return drawerItem;
                });
                return { ...shelfItem, [source]: updatedDrawerItems };
            }
            return shelfItem;
        });
        setShelves(updatedShelvesItems);
    }

    function onClick_AddDrawer(event: any, shelfIndex: number) {
        event.preventDefault();
        var sequential = ordinal + 1;
        setOrdinal(sequential);
            
        const updatedShelvesItems = shelves.map((shelfItem: any, indexShelf: number) => {
            if (indexShelf === shelfIndex) {
                const drawers = shelfItem.drawers;
                if (!isSequential)
                    sequential = drawers.length + 1;
                drawers.push({id: 0, shelfId: shelfItem.id, order: drawers.length, name: leftPad(String(sequential), 3, '0'), color: '#E1EBF3', source: '', identy: ''});
            }
            return shelfItem;
        });
        setShelves(updatedShelvesItems);
    }

    function onClick_RemDrawer(event: any, shelfIndex: number) {
        event.preventDefault();
        var sequential = ordinal - 1;
        setOrdinal(sequential);

        const updatedShelvesItems = shelves.map((shelfItem: any, indexShelf: number) => {
            if (indexShelf === shelfIndex) {
                const drawers = shelfItem.drawers;
                drawers.pop();
            }
            return shelfItem;
        });
        setShelves(updatedShelvesItems);
    }

    /* FUNCION EXCLUVISE OF FORM - END */

    function viewCadastreForm() {

        return (
            <>
                <Modal show={props.show}
                    size="lg"
                    aria-labelledby="modal-cadastre-bookcase-form"
                    centered
                >
                    <Modal.Header>
                        <Modal.Title id="modal-cadastre-bookcase-form"><FaRegIdCard />{title}</Modal.Title>
                        <div className="d-flex align-items-center">
                            <FaTimes className="isClickable" onClick={ onClick_Cancel } size={26}/>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <Form className="form" id="form-cadastre-bookcase" validated={invalidatedCadastreForm}>
                    
                            <fieldset>
                                <legend>
                                    {display.legend.bookcase}
                                    <div className="card-actions float-right">
                                        <div>Ordem Sequencial
                                        <Form.Check type="switch"
                                            checked={isSequential} 
                                            onChange={(e) => setSequential(!isSequential)}
                                        />
                                        </div>
                                    </div>
                                </legend>
                                <Row>
                                    <Col>
                                        <FormGroup as={Row}>
                                            <InputGroup>
                                                <Form.Label className="w-10 text-center white-space">{display.label.name}: </Form.Label>
                                                <Form.Control type="text" className="w-50" value={name} onChange={(e) => setName(e.target.value) } required />
                                                <Form.Label className="w-10 text-center white-space">{display.label.shelves}: </Form.Label>
                                                <Form.Control type="number" className="w-20" min={0} max={5} value={shelves.length} onChange={(e) => onChange_Shelves(e)} />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col><hr /></Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="card-bookcase">
                                            {shelves.map((shelf: any, shelfIndex: number) => (
                                                <div className="card-shelf" key={shelfIndex}>
                                                    <div className="card-shelf-item">
                                                        <div className="card-shelf-fields">
                                                            <FormGroup as={Row}>
                                                                <InputGroup>
                                                                    <Form.Label className="w-20 text-center white-space">{display.label.shelf_name}: </Form.Label>
                                                                    <Form.Control type="text" className="w-50" 
                                                                        value={shelf.name} 
                                                                        onChange={(e) => onChange_ShelfItem(e, shelfIndex, 'name')} 
                                                                        maxLength={15}
                                                                        required
                                                                    />
                                                                </InputGroup>
                                                            </FormGroup>
                                                        </div>
                                                        <div className="card-shelf-items-2">
                                                            {shelf.drawers && shelf.drawers.map((drawer: any, drawerIndex: number) => (
                                                                <div className="card-drawer-2" key={shelfIndex +''+ drawerIndex}>
                                                                    <div className="card-drawer-title">Posição</div>
                                                                    <div className="card-drawer-fields">
                                                                        <FormGroup>
                                                                            <Dropdown className="dropdown-color">
                                                                                <Dropdown.Toggle className="dropdown-color-toggle" variant="none">
                                                                                    <div className="dropdown-color-selected" style={{backgroundColor: drawer.color}}></div>
                                                                                </Dropdown.Toggle>
                                                                                <Dropdown.Menu className="dropdown-color-menu">
                                                                                { ColorDefaultType.map((iterator: any, idx: any) => (
                                                                                    <Dropdown.Item key={idx} className="dropdown-color-item" onClick={e => onChange_DrawerItem(iterator.id, shelfIndex, drawerIndex, 'drawers', 'color')}>
                                                                                        <span className="dropdown-color-item-block" style={{backgroundColor: `${iterator.id}`}}></span>
                                                                                        {display.label[toLowerCase(iterator.name)]}
                                                                                    </Dropdown.Item>
                                                                                )) }
                                                                                </Dropdown.Menu>
                                                                            </Dropdown>
                                                                        </FormGroup>
                                                                        <FormGroup className="w-100">
                                                                            <Form.Control 
                                                                                style={{minWidth:60}}
                                                                                value={drawer.name}
                                                                                onChange={(e) => onChange_DrawerItem(e, shelfIndex, drawerIndex, 'drawers', 'name')}
                                                                                required
                                                                            />
                                                                        </FormGroup>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    <div className="card-shelf-command">
                                                        <a href="/#" onClick={(e) => onClick_AddDrawer(e, shelfIndex) } ><FaPlusSquare /></a>
                                                        <a href="/#" onClick={(e) => onClick_RemDrawer(e, shelfIndex) } ><FaMinusSquare /></a>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </Col>
                                </Row>
                            </fieldset>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="contained" color="secondary" onClick={ onClick_Cancel }>{display.buttom.cancel}</Button>
                        <Button variant="contained" color="primary" onClick={ onClick_Confirm } disabled={sending}>
                            { sending ? <Spinner className="ms-2" as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null }{'  '}
                            { display.buttom.confirm }
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

    return (
        <>
            { viewCadastreForm() }
        </>
    )
}

export default BookcaseCadastreModal
