import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Dropdown, Form, FormControl, FormGroup, InputGroup, OverlayTrigger, Row, Tab, Table, Tabs, Tooltip as ToolTipBootstrap } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { BsCashCoin } from "react-icons/bs";
import { FaEdit, FaMinusSquare, FaTrash, FaTrashAlt, FaWifi } from "react-icons/fa";
import QRCode from "react-qr-code";

import { useTranslateContext } from '../../contexts/translate.context';

import { CameraAltOutlined } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import Check from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Box, Button as ButtonMaterial, FormControlLabel, FormControl as FormControlMaterial, IconButton, InputLabel, LinearProgress, MenuItem, Select, Switch, Tab as TabMaterial, Tabs as TabsMaterial, TextField, Tooltip as TooltipMaterial, createTheme } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { bgBG as corePTBr } from '@mui/material/locale';
import { ThemeProvider } from '@mui/material/styles';
import { DataGrid, GridColDef, GridSelectionModel, ptBR as ptBRMaterial, GridToolbar } from '@mui/x-data-grid';
import { ptBR } from 'date-fns/locale'
import { v4 as uuid } from "uuid";
import ImageLightBox from "../../components/image-lightbox/ImageLightbox";
import { PickerInput } from "../../components/sub-render.component";
import TableContainer from "../../components/table-container.component";
import { useAlertMessageContext } from "../../contexts/alert-message.context";
import addressService from "../../services/address.service";
import authService from '../../services/auth.service';
import { dateToString, floatToString, inArray, toDate, toFloat, toInt, toLowerCase, toString, toUpperCase } from '../../utilities/auxiliary-functions';
import { PermissionEnum, VESSEL_JETSKI, VESSEL_LANCHA } from '../../utilities/constants';
import { cnpjMask, cpfCnpjMask, dataMask, floatMask, phoneMask, sizeMask } from "../../utilities/masks";
import { AlertMessageEnum, ClassificationTypes, ColorDefaultType, EngineTypes, FuelTypes, HarborType, HullTypes, JetCapacity, MakerTypes, ManufacturerTypes, OriginType, ScheduleTitleType, TrackerSituationType, TrailerType, VesselType, getManufacturerName } from "../../utilities/types";
import { isEmpty } from "../../utilities/validators";
import { openDocument } from '../../utilities/view-document';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import styles from './vessel.record.module.scss';
import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip
} from 'chart.js'
import { Doughnut } from 'react-chartjs-2';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ChartDataLabels from 'chartjs-plugin-datalabels'

const MenuProps = {
    PaperProps: {
        style: {
            width: 225,
            maxHeight: 500
        }
    }
}

function TabPanel(props: TabProps) {
    const { children, value, index, ...other } = props
    
    return (
        <div
            role="tab"
            hidden={value !== index}
            id={`full-width-tab-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
        {value === index && (
            <Box sx={{ p: 3 }}>
                {children}
            </Box>
        )}
        </div>
    )
}
    
function aProps(index: number) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tab-${index}`
    }
}

interface TabProps {
    dir?: string
    index: number
    children?: React.ReactNode
    value: number
}

export interface IVesselRecordProps {
    title: any,
    isEdit: boolean,
    viewOnly?: boolean,
    columnsResponsible: any,
    columnsConductor: any,

    // Dados Gerais
    id?: any,
    indAlert?: any,
    indRestriction?: any,
    indMaintenance?: any,
    shared?: any,
    identifyKey?: any,
    name?: any,
    year?: any,
    typeVessel?: any,
    hasTrailer?: any,
    typeTrailer?: any,
    plateTrailer?: any,
    classification?: any,
    harborState?: any,
    harbor?: any,
    enrollment?: any,
    manufacturer?: any,
    color?: any,
    brand?: any,
    model?: any,
    hullMaterial?: any,
    quantityPeople?: any,
    potency?:any,
    vesselSize?: any,
    numberTie?: any,
    validityTie?: any,
    validityTr?: any,
    // Cotas
    quotasQuantity?: any,
    quotasAvailable?: any,
    quotasAmount?: any,
    // Motor
    engines?: any,
    // Dados do Seguro
    insuranceRegistry?: any,
    insuranceName?: any,
    insuranceNumber?: any,
    insuranceValidity?: any,
    insuranceCost?: any,
    insuranceContact?: any,
    insurancePhone1?: any,
    insurancePhone2?: any,
    insuranceEmail?: any,
    // Valor da Embarcação
    vesselCost?: any,
    hullCost?: any,
    engineCost?: any,
    generatorsCost?: any,
    soundsystemCost?: any,
    accessoriesCost?: any,
    othersCost?: any,
    sailsAndMastsCost?: any,
    // Rastreador
    trackerBrand?: any,
    trackerModel?: any,
    trackerSerial?: any,
    trackerInstallerName?: any,
    trackerInstallerRegistry?: any,
    trackerInstallerDate?: any,
    trackerResponsible?: any,
    trackerSituation?: any,
    // Proprietario e Autorizações
    ownerType?: any,
    ownerId?: any,
    ownerName?: any,
    ownerRegistry?: any,
    // Documentos
    vesselDocument?: any,
    insurancePolicyDocument?: any,
    driversLicenseDocument?: any,

    responsibles?: any,
    conductors?: any,
    equipments?: any,
    schedules?: any,
    keep: any,
    // Maintenance and Supply
    resumeMaintenance?: any,
    maintenances?: any,
    resumeSupply?: any,
    supplies?: any,

    // Dados Gerais
    setIndAlert?: any,
    setIndRestriction?: any,
    setIndMaintenance?: any,
    setShared?: any,
    setIdentifyKey?: any,
    setName?: any,
    setYear?: any,
    setTypeVessel?: any,
    setHasTrailer?: any,
    setTypeTrailer?: any,
    setPlateTrailer?: any,
    setClassification?: any,
    setHarborState?: any,
    setHarbor?: any,
    setEnrollment?: any,
    setManufacturer?: any,
    setColor?: any,
    setBrand?: any,
    setModel?: any,
    setHullMaterial?: any,
    setQuantityPeople?: any,
    setPotency?: any,
    setVesselSize?: any,
    setNumberTie?: any,
    setValidityTie?: any,
    setValidityTr?: any,
    // Cotas
    setQuotasQuantity?: any,
    setQuotasAvailable?: any,
    setQuotasAmount?: any,
    // Motor
    setEngines?: any,
    // Dados do Seguro
    setInsuranceRegistry?: any,
    setInsuranceName?: any,
    setInsuranceNumber?: any,
    setInsuranceValidity?: any,
    setInsuranceCost?: any,
    setInsuranceContact?: any,
    setInsurancePhone1?: any,
    setInsurancePhone2?: any,
    setInsuranceEmail?: any,
    // Valor da Embarcação
    setVesselCost?: any,
    setHullCost?: any,
    setEngineCost?: any,
    setGeneratorsCost?: any,
    setSoundsystemCost?: any,
    setAccessoriesCost?: any,
    setOthersCost?: any,
    setSailsAndMastsCost?: any,
    // Rastreador
    setTrackerBrand?: any,
    setTrackerModel?: any,
    setTrackerSerial?: any,
    setTrackerInstallerName?: any,
    setTrackerInstallerRegistry?: any,
    setTrackerInstallerDate?: any,
    setTrackerResponsible?: any,
    setTrackerSituation?: any,
    // Proprietario e Autorizações
    setOwnerType?: any,
    setOwnerId?: any,
    setOwnerName?: any,
    setOwnerRegistry?: any,
    // Documentos
    changeVesselDocument?: any,
    changeInsurancePolicyDocument?: any,
    changeDriversLicenseDocument?: any,
    // Maintenance and Supply
    setResumeMaintenance?: any,
    setMaintenances?: any,
    setResumeSupply?: any,
    setSupplies?: any,

    addEngine?: any,
    updateEngineItem?: any,
    removeEngineItem?: any,
    changeImageEngineItem?: any,
    searchOwner?: any,
    addResponsible?: any,
    addConductor?: any,
    addEquipment?: any,
    sumVesselCost?: any,
    updateEquipmentItem?: any,
    updateEquipmentValue?: any,
    removeEquipmentItem?: any,
    openTracking?: any,

    scheduleProhibited?: any,
    scheduleTitle?: any,
    scheduleComments?: any,

    setScheduleProhibited?: any,
    setScheduleTitle?: any,
    setScheduleComments?: any,

    image?: any,
    changeImage?: any,

    images?: any,
    changeImageVesselItem?: any,

    prowImage?: any,
    sternImage?: any,
    portboardImage?: any,
    starboardImage?: any,
    panelImage?: any,
    interiorImage?: any,
    cabinImage?: any,

    clickSchedule?: any,
    validIdentifyKey?: any,
    changeQuotas?: any
}

export const VesselRecord: React.FC<IVesselRecordProps> = (props: IVesselRecordProps) => {

    const alertContext = useAlertMessageContext();
    const { display } = useTranslateContext();

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        ChartDataLabels,
        Title,
        Tooltip,
        Legend,
        ArcElement
    )

    const [ listStates, setListStates ] = useState([] as any);
    const [ listHarbor, setListHarbor ] = useState([] as any);
    const [ activeTab, setActiveTab ] = React.useState(0)
    const [ pageSize, setPageSize ] = React.useState(5);
    const [ editMode, setEditMode ] = React.useState(false);
    const [ selectionModel, setSelectionModel ] = useState<GridSelectionModel>([]);

    const [ equipmentGroups, setEquipmentGroups ] = useState([] as any);
    const [ mandatoryGroup, setMandatoryGroup ] = useState([] as any);
    const [ additionalSalvageGroup, setAdditionalSalvageGroup ] = useState([] as any);
    const [ accessoriesGroup, setAccessoriesGroup ] = useState([] as any);
    const [ otherGroup, setOtherGroup ] = useState([] as any);

    const [ otherItem, setOtherItem ] = React.useState({
        id: uuid(),
        group : 4,
        name : 'Outros',
        category : '',
        description : '',
        brand : '',
        model : '',
        origin : '',
        checked: true,
        unity : '',
        quantity : 1,
        amount : '',
        order : 0
    })

    const undo = () => {
        setOtherItem({
            id: uuid(),
            group : 4,
            name : 'Outros',
            category : '',
            description : '',
            brand : '',
            model : '',
            origin : '',
            checked: true,
            unity : '',
            quantity : 1,
            amount : '',
            order : 0
        })
        setEditMode(false)
    }
    
    const columns: GridColDef[] = [
        { field: 'description', flex: 1, headerName: 'Descrição', minWidth: 110, align: 'center', headerAlign: 'center' },
        { field: 'quantity', headerName: 'Quantidade', minWidth: 110, align: 'center', headerAlign: 'center' },
        { field: 'brand', headerName: 'Marca', minWidth: 180, align: 'center', headerAlign: 'center', flex: 1 },
        { field: 'model', headerName: 'Modelo', width: 110, flex: 1, align: 'center', headerAlign: 'center' },
        { field: 'origin', headerName: 'origem', minWidth: 100, align: 'center', flex: 1, headerAlign: 'center', valueGetter: getOrigin },
        { field: 'amount', headerName: 'Valor Aprox.', width: 110, flex: 1, align: 'center', headerAlign: 'center', valueGetter: getValue },
        { field: 'action', headerName: 'Ações', headerAlign: 'center', renderCell: renderButtons, disableExport: true }
    ];

    function renderButtons(param: any) {
        const productRow = param.row;
        return (
            <div className="d-flex w-100 justify-content-center">
                {authService.hasPermission(PermissionEnum.CADASTRE_PRODUCT_EDIT) && 
                    <TooltipMaterial className="isClickable" title="Editar Produto">
                        <span className="me-3"  onClick={(e) => onEditOtherItem(productRow)}>
                            <FaEdit size={18} />
                        </span>
                    </TooltipMaterial>
                }
                {authService.hasPermission(PermissionEnum.CADASTRE_PRODUCT_REMOVE) && 
                    <TooltipMaterial className="isClickable" title="Excluir Produto">
                        <span onClick={(e) => onRemoveOtherItem(productRow, e)}>
                            <FaTrash size={18} />
                        </span>
                    </TooltipMaterial>
                }
            </div>
        );
    }

    const updateOtherItem = () => {
        console.log('updateOtherItem');
        /*
        const filteredOtherItems = checkListItems.otherItems.filter((other: any) => other.id !== otherItem.id) 
        filteredOtherItems.push(otherItem)
        setCheckListItems({
            ...checkListItems,
            otherItems: filteredOtherItems
        })
        setOtherItem({
            id: uuid(),
            description: '',
            amount: 1,
            brand: '',
            model: '',
            origin: '',
            value: ''
        })
        setEditMode(false)
        */
    }

    const onEditOtherItem = (otherItemData: any) => {
        console.log('onEditOtherItem');
        setEditMode(true)
        setOtherItem(otherItemData)
    }

    const onRemoveOtherItem = (otherItemData: any, event: any) => {
        props.removeEquipmentItem(otherItemData, event);

        /*
        const filteredOtherItems = checkListItems.otherItems?.filter((other: any) => other.id !== otherItemData.id)
        setCheckListItems({...checkListItems, otherItems: filteredOtherItems})
        if (filteredOtherItems.length === 0 ) {
            setOtherItem({
                id: uuid(),
                description: '',
                amount: 1,
                brand: '',
                model: '',
                origin: '',
                value: ''
            })
            setEditMode(false)
        }
        */
    }

    const handleOtherCheckListItems = (event: any) => {
        props.addEquipment(otherItem, event);
        /*
        setCheckListItems({
            ...checkListItems,
            otherItems: [...checkListItems.otherItems, otherItem]
        })
        setOtherItem({
            id: uuid(),
            description: '',
            amount: 1,
            brand: '',
            model: '',
            origin: '',
            value: ''
        })
        */
    }

    function updateEquipmentItem(position: number, itens: any, event: any) {
        event.preventDefault();
        props.updateEquipmentItem(position, itens, event);
    }

    const originsOptions = [
        { label: 'Nacional', value: 'nacional' },
        { label: 'Importado', value: 'importado' }
    ]

    function getOrigin(param: any) {
        const { origin } = param.row;
        if (origin === 'importado') return 'Importado'
        return 'Nacional';
    }

    function getValue(param: any) {
        const { amount } = param.row;
        return formatCurrency(amount);
    }

    const formatCurrency = (value: string) => {
        if (Number(value))
            return new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
            }).format(Number(value))
        return value
    }

    const windowQrcode = window.location.origin +"/card/vessel/"+ props.enrollment;

    const theme = createTheme(
        {
            palette: {
                primary: {
                    main: '#3366FF'
                },
                secondary: {
                    main: '#FFFFFF'
                },
                error: {
                    main: '#D32E2E'
                },
                warning: {
                    main: '#FFC000'
                },
                mode: 'dark'
            },
            typography: {
                fontFamily: ['Montserrat', 'sans-serif'].join(','),
                button: {
                    textTransform: 'none'
                }
            },
            components: {
                MuiTooltip: {
                    styleOverrides: {
                        tooltip: {
                            backgroundColor: 'white',
                            color: 'rgba(0, 0, 0, 0.87)',
                            fontSize: 14,
                            textAlign: 'center'
                        }
                    }
                }
            },
        
        }, 
        ptBRMaterial, 
        corePTBr
    )

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue)
    }
   
    async function fillStates() {
        try {
            const states = await addressService.listStates();
            setListStates(states);
        } catch (error: any) {
            await alertContext.show(AlertMessageEnum.FAIL, props.title, error);
        }
    }

    function treatHarbor(state: string): void {
        const listHarborLocal = new Array({} as any);
        HarborType.forEach((iterator: any) => {
            if (iterator.uf === state) {
                listHarborLocal.push(iterator);
            }
        });
        setListHarbor(listHarborLocal);
    }

    function onClick_QrCode(event: any) {
        event.preventDefault();

        var url = windowQrcode;
        
        var w = 500;
        var h = 850;
        var left = 200;
        var tops = 100;
        
        const queries = url.split('/');
        if (queries[queries.length - 1] !== '')
            return window.open(url, '', 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+ w +', height='+ h +', top='+ tops +', left='+ left);
        
        /*
        var win = window.open('', '',
            "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=yes,width=500,height=830,top=100,left=200"
        );
        if (win) {
            win.document.write(`<html>`);
            win.document.write(`<head>`);
            win.document.write(`<title>Cartão da Embarcação</title>`);
            win.document.write(`<meta http-equiv="Content-Type" content="text/html; charset=iso-8859-1" />`);
            win.document.write(`<style style rel="stylesheet" type="text/css">body { font-size: 1em; color: #fff; background: #303A4A; width: vw; height: vh; margin: 0; padding: 0; }</style>`);
            win.document.write(`</head>`);
            win.document.write(`<body>`);
            win.document.write(`<object type="application/pdf" width="100%" height="100%" data="${url}">The “object” tag is not supported by your browser.</object>`);
            win.document.write(`</body>`);
            win.document.write(`</html>`);
        }
        */
    }

    function onChange_State(state: string) {
        props.setHarborState(state);
        treatHarbor(state);
    }


    function onClick_AddResponsible(event: any) {
        event.preventDefault();
        props.addResponsible(event);
    }
    
    function onClick_AddConductor(event: any) {
        event.preventDefault();
        props.addConductor(event);
    }
    
    function onClick_AddEngine(event: any) {
        event.preventDefault();
        if (props.engines.length < 4){
            props.addEngine(event);
        }
    }

    function onBlur_SumVesselCost(event: any) {
        event.preventDefault();
        props.sumVesselCost(event);
    }
    
    function onChange_UpdateEngineItem(event: any, position: number, field: string, item: any) {
        event.preventDefault();
        props.updateEngineItem(position, field, item);
    }

    function onClick_RemoveEngineItem(event: any, index: number) {
        props.removeEngineItem(event, index);
    }

    function onChange_VesselDocument(event: any) {
        event.preventDefault();
        props.changeVesselDocument(event);
    }

    function onChange_InsurancePolicyDocument(event: any) {
        event.preventDefault();
        props.changeInsurancePolicyDocument(event);
    }

    function onChange_DriversLicenseDocument(event: any) {
        event.preventDefault();
        props.changeDriversLicenseDocument(event);
    }

    function onChange_Image(event: any) {
        event.preventDefault();
        props.changeImage(event);
    }

    function onChange_ImageEngineItem(event: any, index: number) {
        event.preventDefault();
        props.changeImageEngineItem(event, index);
    }
    
    function onChange_ImageVesselItem(event: any, order: string, index: number) {
        event.preventDefault();
        props.changeImageVesselItem(event, order, index);
    }

    function onClick_Tracker(event: any) {
        event.preventDefault();
        props.openTracking(event);
    }

    function onClick_ViewDocument(event: any, title: string, url: any) {
        event.preventDefault();
        openDocument(title, url);
    } 

    function onChange_Prohibited(event: any, date: any) {
        props.setScheduleProhibited(dateToString(date, 'dd/mm/yyyy HH:MM:SS'));
    }

    function onClick_Schedule(event: any) {
        event.preventDefault();
        props.clickSchedule(event);
    }

    function onClick_Color(event: any, value: string) {
        props.setColor(value);
    }

    function onBlue_ValidIdentifyKey(event: any) {
        props.validIdentifyKey(event);
    }

    function onChange_Manufacturer(event: any) {
        event.preventDefault();

        let manufacturer = event.target.value;
        props.setManufacturer(manufacturer);
        let brand = manufacturer === '16' ? props.brand : getManufacturerName(manufacturer);
        props.setBrand(brand);
    }

    async function initialize() {
        await fillStates();
    }

    useEffect(() => {
        if (props.harborState)
            treatHarbor(props.harborState);
    }, [ props.harborState ]);

    useEffect(() => {
        if (props.equipments.length > 0) {
            const groups = [] as any;
            props.equipments.forEach((equipment: any) => { 
                const exist = groups.find((group: any) => { return group.id === equipment.group })
                if (!exist)
                    groups.push({ id: equipment.group, name: equipment.name });
            });

            const exist = groups.find((group: any) => { return group.id === 4 })
            if (!exist)
                groups.push({ id: 4, name: 'Outros' });
            setEquipmentGroups(groups);
            groups.sort(( objA: any, objB: any ) => (objA.id > objB.id) ? 1 : ((objB.id > objA.id) ? -1 : 0));

            setMandatoryGroup(props.equipments.filter((equipment: any) => equipment.group === 1));
            setAdditionalSalvageGroup(props.equipments.filter((equipment: any) => equipment.group === 2));
            setAccessoriesGroup(props.equipments.filter((equipment: any) => equipment.group === 3));
            setOtherGroup(props.equipments.filter((equipment: any) => equipment.group === 4));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ props.equipments ]);

    useEffect(() => {
        initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function viewKeep() {
        var bookcaseName = '';
        var shelfName = '';
        var drawerName = '';
        var colorName = '';

        if (props.keep) {
            bookcaseName = props.keep.name;
            if (props.keep.shelves && (props.keep.shelves.length > 0)) {
                const shelf = props.keep.shelves[0];
                shelfName = shelf.name;
                if (shelf.drawers && (shelf.drawers.length > 0)) {
                    const drawer = shelf.drawers[0];
                    drawerName = drawer.name;
                    colorName = drawer.color;
                }
            }
        }

        return (
            <div className="card-drawer" style={{ backgroundColor: colorName }}>
                <div className="card-drawer-title">{bookcaseName}</div>
                <div className="card-drawer-group">{shelfName}</div>
                <div className="card-drawer-number">{drawerName}</div>
            </div>
        );
    }

    const handleSharedVessel = (e: any) => {
        if (props.shared) {
            props.setQuotasQuantity(0);
            props.setQuotasAvailable(0);
            props.setQuotasAmount('0');
        } else {
            if (props.responsibles.length > 0) {
                props.setQuotasQuantity(props.responsibles.length);
                props.setQuotasAvailable(props.responsibles.length);
                props.setQuotasAmount(floatToString(toFloat(props.vesselCost) / props.responsibles.length));
            } else {
                props.setQuotasQuantity(1);
                props.setQuotasAvailable(1);
                props.setQuotasAmount(props.vesselCost);
            }
        }
        props.setShared(!props.shared)
        /*
        if (e.target.checked) {
            alertContext.show(AlertMessageEnum.WARN, 'Atenção!', 'Obrigatório o preenchimento dos Equipamentos e Acessórios para embarcações compartilhadas.');
        }
        */
    }

    function renderSectionData(equipament: any) {
        
        return (
            <div className={`${styles.formGroup} d-flex`}>
                <FormControlLabel 
                    control={<Switch 
                        checked={equipament.checked}
                        onChange={(e) => updateEquipmentItem(equipament.id, {
                            checked: e.target.checked,
                            quantity: e.target.checked ? equipament.quantity : 1
                        }, e)}
                    />}
                    disabled={(toInt(props.typeVessel) === VESSEL_JETSKI && equipament.brand === "grill") ||
                    (toInt(props.typeVessel) === VESSEL_JETSKI && equipament.brand === 'table') || 
                    (toInt(props.typeVessel) === VESSEL_JETSKI && equipament.brand === 'ladder') || 
                    (toInt(props.typeVessel) === VESSEL_JETSKI && equipament.brand === "cushion") || 
                    (toInt(props.typeVessel) === VESSEL_JETSKI && equipament.brand === 'image') || 
                    (toInt(props.typeVessel) === VESSEL_JETSKI && equipament.brand === 'flag') || 
                    (toInt(props.typeVessel) === VESSEL_JETSKI && equipament.brand === 'floater') || 
                    (toInt(props.typeVessel) === VESSEL_JETSKI && equipament.brand === "additionalFloater")}
                    label={`${equipament.description}`} 
                />
                <TextField className={styles.input} id='outlined-basic' 
                    inputProps={{maxLength: 3}} 
                    color='secondary' variant='standard' size='small'
                    label='Quantidade'
                    disabled={!equipament.checked} 
                    value={equipament.checked ? equipament.quantity : ''}
                    onChange={(e) => updateEquipmentItem(equipament.id, {
                        checked: equipament.checked,
                        quantity: Number(e.target.value.replace(/\D/g, ''))
                    }, e)}
                />
            </div>
        );
    }

    function renderSection(children: any) {
        return (
            <div className={`${styles.acessoriesSection}`}>{children}</div>
        );
    }

    function renderGroup(equipaments: any[]) {
        let divOut: any;
        let divData = [] as any;
        let divSecion = [] as any;

        for (let ind = 0; ind < equipaments.length; ind++) {
            divData.push(renderSectionData( equipaments[ind] ));
                
            if (inArray(ind + 1, [3, 6, 9, 12, 15]) > -1) {
                divSecion.push(renderSection(divData));
                divData = [] as any;
            }
            if (ind === equipaments.length - 1 && divData.length > 0) {
                divSecion.push(renderSection(divData));
            }
        }
        divOut = divSecion;
        return ( divOut );
    }

    const typeOptions = {
        responsive: true,
        cutout: 140,
        gridLines: {
            display: false
        },
        plugins: {
            legend: {
                position: 'bottom' as const,
                labels: {
                    color: '#FFFFFF',
                    font: {
                        size: 12,
                        family: "'Montserrat', sans-serif"
                    }
                }
            },
            datalabels: {
                color: 'white',
                font: {
                    size: 16,
                    family: "'Montserrat', sans-serif"
                },
                formatter(value: any) {
                    return `${value}%`
                }
            }
        }
    }

    const typeOptions2 = {
        responsive: true,
        cutout: 100,
        gridLines: {
          display: false
        },
        plugins: {
          legend: {
            position: 'bottom' as const,
            labels: {
              color: '#FFFFFF',
              font: {
                size: 12,
                family: "'Montserrat', sans-serif"
              }
            }
          },
          datalabels: {
            color: 'white',
            font: {
              size: 16,
              family: "'Montserrat', sans-serif"
            },
            formatter(value: any) {
              return `${value}%`
            }
          }
        }
      }


      const typeOptionsAmount = {
        responsive: true,
        cutout: 100,
        gridLines: {
          display: false
        },
        plugins: {
          legend: {
            position: 'bottom' as const,
            labels: {
              color: '#FFFFFF',
              font: {
                size: 12,
                family: "'Montserrat', sans-serif"
              }
            }
          },
          datalabels: {
            color: 'white',
            font: {
              size: 16,
              family: "'Montserrat', sans-serif"
            },
            formatter(value: any) {
              return `${value} Litros`
            }
          }
        }
    }

    const operationsType = {
        labels: ['Limpeza', 'Higienização', 'Adoçamento', 'Polimento'],
        datasets: [
          {
            data: [
                Math.round(props.resumeMaintenance.total > 0 ? (props.resumeMaintenance?.cleanCount / props.resumeMaintenance.total) * 100 : 0), 
                Math.round(props.resumeMaintenance.total > 0 ? (props.resumeMaintenance?.sanitizeCount / props.resumeMaintenance.total) * 100 : 0), 
                Math.round(props.resumeMaintenance.total > 0 ? (props.resumeMaintenance?.sweetenCount / props.resumeMaintenance.total) * 100 : 0), 
                Math.round(props.resumeMaintenance.total > 0 ? (props.resumeMaintenance?.polishCount / props.resumeMaintenance.total) * 100 : 0)
            ],
            backgroundColor: ['gray', '#6783E6', '#16BE94', 'purple'],
            borderWidth: 0
          }
        ]
    }

    const fuelsType = {
        labels: ['Gasolina Comum', 'Gasolina Aditivada', 'Diesel'],
        datasets: [
          {
            data: [
                Math.round((props.resumeSupply?.fuelType?.commonCount / props.resumeSupply?.fuelType?.total) * 100), 
                Math.round((props.resumeSupply?.fuelType?.additiveCount / props.resumeSupply?.fuelType?.total) * 100), 
                Math.round((props.resumeSupply?.fuelType?.dieselCount / props.resumeSupply?.fuelType?.total) * 100), 
            ],
            backgroundColor: ['gray', '#6783E6', '#16BE94'],
            borderWidth: 0
          }
        ]
    }

    const amountType = {
        labels: ['Gasolina Comum', 'Gasolina Aditivada', 'Diesel'],
        datasets: [
          {
            data: [
                props.resumeSupply?.liter?.commonCount,
                props.resumeSupply?.liter?.additiveCount,
                props.resumeSupply?.liter?.dieselCount
            ],
            backgroundColor: ['gray', '#6783E6', '#16BE94'],
            borderWidth: 0
          }
        ]
    }

    const renderAdditionalInfo = (params: any) => {
        return (
            <TooltipMaterial title={params?.value}>
                <ContentPasteIcon/>
            </TooltipMaterial>
        )
    }

    const renderBoolean = (params: any) => {
        if (params?.value === 1) {
            return (
                <CheckIcon  color='success'/>
            )
        }
        return (
            <CloseIcon  color='error'/>
        )
    }

    const renderFloat = (params: any) => {
        return floatToString(params?.value)
    }

    const columnsMaintenance: GridColDef[] = [
        { field: 'dateOperation', headerName: 'Data', maxWidth: 90, align: 'center', headerAlign: 'center', 
            renderCell(params: any) {
                return dateToString(params?.value, 'dd/mm/yyyy');
            }, 
        },
        { field: 'clean', headerName: 'Limpeza', flex: 1, align: 'center', headerAlign: 'center', renderCell: renderBoolean },
        { field: 'sanitize', headerName: 'Higienização', flex: 1, align: 'center', headerAlign: 'center', renderCell: renderBoolean },
        { field: 'sweeten', headerName: 'Adoçamento', flex: 1, align: 'center', headerAlign: 'center', renderCell: renderBoolean },
        { field: 'polish', headerName: 'Polimento', flex: 1, align: 'center', headerAlign: 'center', renderCell: renderBoolean },
        { field: 'addtionalInfo', headerName: 'Observações', flex: 1, align: 'center', headerAlign: 'center', renderCell: renderAdditionalInfo },
        { field: 'credential', headerName: 'Operador', align: 'center', headerAlign: 'center' },
    ];

    const columnsSupplies: GridColDef[] = [
        { field: 'dateOperation', headerName: 'Data', maxWidth: 90, align: 'center', headerAlign: 'center', 
            renderCell(params: any) {
                return dateToString(params?.value, 'dd/mm/yyyy');
            }, 
        },
        { field: 'hours', headerName: 'Horas', maxWidth: 65, align: 'center', headerAlign: 'center',
            renderCell(params: any) {
                return toInt(params?.value);
            }, 
        },
        { field: 'fuelType', headerName: 'Combustível', flex: 1, align: 'center', headerAlign: 'center', 
            renderCell(params: any) {
                var labels = ['Gasolina Comum', 'Gasolina Aditivada', 'Diesel'];
                return labels[params?.value - 1];
            }, 
        },
        { field: 'amount', headerName: 'Valor', flex: 1, align: 'center', headerAlign: 'center', renderCell: renderFloat }, 
        { field: 'credential', headerName: 'Operador', align: 'center', headerAlign: 'center' },
    ];

    function viewVesselCadaster() {
        return (
            <Row>
                <Col sm="12">
                    <Row>
                        <Col sm="3">
                            <div className="d-flex flex-column align-items-center">
                                <ImageLightBox containerStyle={styles.imageWrapperVessel} src={props.image} alt="Embarcação"
                                    theftIndicator={props.indAlert} financialIndicator={props.indRestriction} blockedMaintenance={props.indMaintenance} />
                                {!props.viewOnly && <FormGroup as={Row} className={`mt-2 d-flex justify-content-start ${styles.imageControls}`}>
                                    <label htmlFor='input-file-image'>
                                        <TooltipMaterial className="isClickable" title="Fazer upload de nova foto">
                                            <CloudUploadIcon />
                                        </TooltipMaterial>
                                    </label>
                                    <label className="text-right" onClick={(e)=> onChange_Image(e)}>
                                        <TooltipMaterial className="isClickable ms-2" title="Remover foto atual">
                                            <HighlightOffIcon />
                                        </TooltipMaterial>
                                    </label>
                                    <Form.Control type='file' id='input-file-image' accept="image/*" onChange={(e)=> onChange_Image(e)} />
                                </FormGroup>}
                            </div>
                        </Col>
                        <Col sm="7">
                            <Container className="container-min">
                                <Row>
                                    <Col sm="3">
                                        <FormGroup>
                                            <Form.Label htmlFor="form-identify-key">{display.label.identify_key}</Form.Label>
                                            <FormControl id="form-identify-key" name="identifyKey" maxLength={10}
                                                placeholder={display.example.identify_key} required
                                                value={toString(props.identifyKey)} onChange={e=>
                                                props.setIdentifyKey(toUpperCase(e.target.value))}
                                                onBlur={(e) => onBlue_ValidIdentifyKey(e)}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="9">
                                        <FormGroup>
                                            <Form.Label htmlFor="form-name-vessel">{display.label.name_vessel}</Form.Label>
                                            <FormControl id="form-name-vessel" name="nameVessel"
                                                placeholder={display.example.name_vessel} value={toString(props.name)}
                                                onChange={e=> props.setName(e.target.value)}
                                                />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="3">
                                        <FormGroup>
                                            <Form.Label htmlFor="form-year">{display.label.year}</Form.Label>
                                            <FormControl id="form-year" name="year" placeholder={display.example.year}
                                                value={toString(props.year)} onChange={e=> props.setYear(e.target.value)}
                                                />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="8">
                                        <FormGroup>
                                            <Form.Label htmlFor="form-type-vessel">{display.label.type_vessel}</Form.Label>
                                            <Form.Select id="form-type-vessel" name="typeVessel" required
                                                value={toString(props.typeVessel)} onChange={(e: any)=>
                                                props.setTypeVessel(e.target.value)}
                                            >
                                                { isEmpty(props.typeVessel) ? (
                                                    <option value="">...</option>
                                                ) : null }
                                                { VesselType.map((iterator: any, idx: any) => (
                                                    <option key={idx} value={iterator.id}>{iterator.description}</option>)
                                                ).sort((a: any, b: any) => { 
                                                    return a.props.children > b.props.children ? 1 : -1; 
                                                }) }
                                            </Form.Select>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="1">
                                        <FormGroup>
                                            <Form.Label htmlFor="form-color">{display.label.color}</Form.Label>
                                            <Dropdown className="dropdown-color">
                                                <Dropdown.Toggle className={`${styles.dropdownToogle} dropdown-color-toggle`} variant="none">
                                                    <div className={styles.selectedColor} style={{backgroundColor: props.color}}></div>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className={`${styles.dropdownMenu} dropdown-color-menu`}>
                                                    { ColorDefaultType.map((iterator: any, idx: any) => (
                                                    <Dropdown.Item key={idx} className={`${styles.dropdownItem}
                                                        dropdown-color-item`} onClick={e=> onClick_Color(e, iterator.id)}>
                                                        <span className="dropdown-color-item-block" style={{backgroundColor: `${iterator.id}`}}></span>
                                                    </Dropdown.Item>
                                                    )) }
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="2">
                                        <FormGroup>
                                            <Form.Label htmlFor="form-has-trailer">{display.label.trailer}</Form.Label>
                                            <Form.Select id="form-has-trailer" name="hasTrailer"
                                                value={toString(props.hasTrailer)} onChange={(e: any)=>
                                                props.setHasTrailer(e.target.value)}
                                            >
                                                <option value={0}>Não</option>
                                                <option value={1}>Sim</option>
                                            </Form.Select>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Form.Label htmlFor="form-type-trailer">{display.label.type_trailer}</Form.Label>
                                            <Form.Select id="form-type-trailer" name="typeTrailer"
                                                value={toString(props.typeTrailer)} onChange={(e: any)=>
                                                props.setTypeTrailer(e.target.value)}
                                            >
                                                { isEmpty(props.typeTrailer) ? (
                                                <option value="">...</option>
                                                ) : null }
                                                { TrailerType.map((iterator: any, idx: any) => (<option key={idx}
                                                    value={iterator.id}>{display.label[toLowerCase(iterator.name)]}</option>)).sort((a : any, b :
                                                any) => { return a.props.children > b.props.children ? 1 : -1; }) }
                                            </Form.Select>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="4">
                                        <FormGroup>
                                            <Form.Label htmlFor="form-plate-trailer">{display.label.plate}</Form.Label>
                                            <FormControl id="form-plate-trailer" name="plateTrailer"
                                                placeholder={display.example.plate_trailer} value={toString(props.plateTrailer)}
                                                onChange={e=> props.setPlateTrailer(toUpperCase(e.target.value))}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                {!props.viewOnly && <>
                                    <Row className='mt-3 mb-1'>
                                        <Col className='p-0'>
                                            <Form.Check type="switch" label="Alerta de Roubo" id="form-ind-alert" name="indAlert"
                                                checked={props.indAlert} className={styles.switch} onChange={(e)=>
                                                props.setIndAlert(!props.indAlert)}
                                            />
                                        </Col>
                                        <Col className='p-0'>
                                            <Form.Check type="switch" label="Restrição Financeira" id="form-ind-restriction"
                                                name="indRestriction" checked={props.indRestriction} className={styles.switch}
                                                onChange={(e)=> props.setIndRestriction(!props.indRestriction)}
                                            />
                                        </Col>
                                        </Row>
                                        <Row>
                                        <Col className='p-0'>
                                            <Form.Check type="switch" label="Bloqueada Manutenção" id="form-ind-maintenance"
                                                name="indMaintenance" checked={props.indMaintenance} className={styles.switch}
                                                onChange={(e)=> props.setIndMaintenance(!props.indMaintenance)}
                                            />
                                        </Col>
                                        <Col className='p-0'>
                                            <Form.Check type="switch" label="Embarcação Compartilhada" id="form-is-shared"
                                                name="shared" checked={props.shared} className={styles.switch}
                                                onChange={(e)=> handleSharedVessel(e)}
                                            />
                                        </Col>
                                    </Row>
                                    </>}
                            </Container>
                        </Col>
                        <Col sm="2">
                            <Row>
                                <Col sm="6">
                                    {!props.viewOnly && <div className="box-code">
                                        <QRCode bgColor={'#303A4A'} fgColor={'#FFFFFF'} value={windowQrcode} size={80} onClick={ (e)=> onClick_QrCode(e) }/>
                                    </div>}
                                </Col>
                                <Col sm="6">
                                    {viewKeep()}
                                </Col>
                            </Row>
                            <Row>
                                <Col><hr /></Col>
                            </Row>
                            {props.shared && (
                                <>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Form.Label htmlFor="form-quotas-quantity">Cotas</Form.Label>
                                                <FormControl type='number'
                                                    min={0} max={100}
                                                    value={toString(props.quotasQuantity)} 
                                                    // onChange={e => props.setQuotasQuantity(e.target.value)}
                                                    onChange={e => props.changeQuotas(e)}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Form.Label htmlFor="form-quotas-available">Disponível</Form.Label>
                                                <FormControl type='number'
                                                    min={0} max={props.quotasQuantity}
                                                    value={toString(props.quotasAvailable)} 
                                                    onChange={e => props.setQuotasAvailable(e.target.value)}
                                                    readOnly
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <FormGroup>
                                            <Form.Label htmlFor="form-average">Valor</Form.Label>
                                            <FormControl type="text" id="form-average" name="average" placeholder={display.example.average} required
                                                className="text-right"
                                                value={props.quotasAmount}
                                                onChange={e => props.setQuotasAmount(floatMask(e.target.value))}
                                                readOnly
                                            />
                                        </FormGroup>
                                    </Row>
                                </>
                            )}
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col sm="12">
                            <Container fluid className="container-min">
                                <Tabs defaultActiveKey="ship-registration" id="tabs-ship-registration" className="mb-3">
                                    <Tab eventKey="ship-registration" title={display.subtitle.vessel_information}>
                                        <Container fluid className="container-min">
                                            <fieldset>
                                                <legend>{display.legend.general_data}</legend>
                                                <Row>
                                                    <Col sm="2">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-enrollment">{display.label.enrollment}</Form.Label>
                                                            <FormControl id="form-enrollment" name="enrollment"
                                                                placeholder={display.example.enrollment}
                                                                value={toString(props.enrollment)} onChange={e=>
                                                                props.setEnrollment(toUpperCase(e.target.value))}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-harbor-state">Estado</Form.Label>
                                                            <Form.Select id="form-harbor-state" name="harborstate" value={toString(props.harborState)} onChange={(e: any)=> onChange_State(e.target.value)} >
                                                                { isEmpty(props.harborState) ? (
                                                                    <option value="">...</option>
                                                                ) : null }
                                                                { listStates.map((iterator: any, idx: number) => (
                                                                    <option key={idx} value={iterator.sigla}>{iterator.nome}</option>
                                                                )).sort((a: any, b: any) => { 
                                                                    return a.props.children > b.props.children ? 1 : -1;
                                                                })}
                                                            </Form.Select>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="6">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-harbor">DPC</Form.Label>
                                                            <Form.Select id="form-harbor" name="harbor" 
                                                                value={toString(props.harbor)} onChange={(e: any)=> props.setHarbor(e.target.value)} >
                                                                { isEmpty(props.harbor) ? (
                                                                    <option value="">...</option>
                                                                ) : null }
                                                                { listHarbor.map((iterator: any, idx: number) => (
                                                                    <option key={idx} value={iterator.id}>{iterator.om} - {iterator.initials}</option>
                                                                )).sort((a : any, b : any) => {
                                                                    return a.props.children > b.props.children ? 1 : -1; 
                                                                })}
                                                            </Form.Select>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm="3">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-manufacturer">{display.label.maker} </Form.Label>
                                                            <Form.Select id="form-manufacturer" name="manufacturer" required 
                                                                value={toString(props.manufacturer)} 
                                                                onChange={e => onChange_Manufacturer(e)}
                                                            >
                                                                <option value="">...</option>
                                                                { ManufacturerTypes.map((iterator: any, idx: number) => (
                                                                    <option key={idx} value={iterator.id}> {iterator.description}</option>)) 
                                                                }
                                                            </Form.Select>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="2">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-brand">{display.label.brand}</Form.Label>
                                                            <FormControl id="form-brand" name="brand"
                                                                placeholder={props.manufacturer === '16' ? 'Digite a marca' : ''}
                                                                onChange={e => props.setBrand(e.target.value)}
                                                                value={toString(props.brand)} 
                                                                disabled={props.manufacturer !== '16'}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="2">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-model">{display.label.model}</Form.Label>
                                                            <FormControl id="form-model" name="model"
                                                                placeholder={display.example.model}
                                                                value={toString(props.model)} 
                                                                onChange={e => props.setModel(e.target.value)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="3">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-classification">Classificação</Form.Label>
                                                            <Form.Select id="form-classification" name="classification" required
                                                                value={toString(props.classification)} onChange={e => props.setClassification(e.target.value)}
                                                            >
                                                                <option value="">...</option>
                                                                { ClassificationTypes.map((iterator: any, idx: number) => (
                                                                <option key={idx} value={iterator.id}>{iterator.description}
                                                                </option>)).sort((a : any, b : any) => { return a.props.children
                                                                > b.props.children ? 1 : -1; }) }
                                                            </Form.Select>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="2">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-hull-material">{display.label.hull}</Form.Label>
                                                            <Form.Select id="form-hull-material" name="hullMaterial" required 
                                                                value={toString(props.hullMaterial)} onChange={(e: any)=> props.setHullMaterial(e.target.value)}>
                                                                <option value="">...</option>
                                                                { HullTypes.map((iterator: any, idx: number) => (<option
                                                                    key={idx} value={iterator.id}>{iterator.description}</option>
                                                                )).sort((a : any, b : any) => { 
                                                                    return a.props.children > b.props.children ? 1 : -1; 
                                                                })}
                                                            </Form.Select>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm="2">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-quantity-people">{display.label.capacity}</Form.Label>
                                                            {toInt(props.typeVessel) === VESSEL_JETSKI ? (
                                                                <Form.Select id="form-quantity-people" name="quantityPeople" required 
                                                                    value={toString(props.quantityPeople)}
                                                                    onChange={(e: any)=> props.setQuantityPeople(e.target.value)}>
                                                                    <option value="">...</option>
                                                                    { JetCapacity.map((iterator: any, idx: number) => (<option
                                                                        key={idx} value={iterator.id}>{iterator.description}</option>
                                                                    )).sort((a : any, b : any) => { 
                                                                        return a.props.children > b.props.children ? 1 : -1; 
                                                                    })}
                                                                </Form.Select>
                                                            ):(
                                                                <FormControl type="number" id="form-quantity-people"
                                                                    name="quantityPeople" placeholder={display.example.capacity}
                                                                    className="text-right" value={toString(props.quantityPeople)}
                                                                    onChange={e=> props.setQuantityPeople(toInt(e.target.value))}
                                                                />
                                                            )}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="2">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-vessel-size">{display.label.vessel_size} (Pés)</Form.Label>
                                                            <FormControl id="form-vessel-size" name="vesselSize"
                                                                maxLength={4} 
                                                                placeholder={display.example.vessel_size}
                                                                className="text-right" 
                                                                value={toString(props.vesselSize)} 
                                                                onChange={e=> props.setVesselSize(sizeMask(e.target.value))}
                                                                onBlur={e=> props.setVesselSize(sizeMask(e.target.value))}
                                                                disabled={toInt(props.typeVessel) === VESSEL_JETSKI}
                                                            />

                                                            {/*
                                                            <FormControl type="number" id="form-vessel-size" name="vesselSize"
                                                                placeholder={display.example.vessel_size} 
                                                                className="text-right"
                                                                maxLength={2}
                                                                value={toString(props.vesselSize)} 
                                                                onChange={e=> props.setVesselSize(toInt(e.target.value))}
                                                                disabled={toInt(props.typeVessel) === VESSEL_JETSKI}
                                                            />
                                                            */}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="2">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-vessel-size">{display.label.potency} (HP)
                                                            </Form.Label>
                                                            <FormControl type="number" id="form-vessel-size" name="potency"
                                                                placeholder={display.example.potency} className="text-right"
                                                                maxLength={2} value={toString(props.potency)} onChange={e=>
                                                                props.setPotency(toInt(e.target.value))}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="2">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-number-tie">{display.label.number_tie}</Form.Label>
                                                            <FormControl id="form-number-tie" name="numberTie"
                                                                placeholder={display.example.number_tie}
                                                                value={toString(props.numberTie)} 
                                                                onChange={e=> props.setNumberTie(e.target.value)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="2">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-validity-tie">{display.label.validity_tie}</Form.Label>
                                                            <FormControl id="form-validity-tie" name="validityTie"
                                                                maxLength={10} 
                                                                placeholder={display.example.validity_tie}
                                                                value={toString(props.validityTie)} 
                                                                onChange={e=> props.setValidityTie(dataMask(e.target.value))}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="2">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-validity-tr">{display.label.validity_tr}</Form.Label>
                                                            <FormControl id="form-validity-tr" name="validityTr" 
                                                                maxLength={10}
                                                                placeholder={display.example.validity_tr}
                                                                value={toString(props.validityTr)} 
                                                                onChange={e=> props.setValidityTr(dataMask(e.target.value))}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </fieldset>
                                            <fieldset>
                                                 <legend>{display.legend.engines}
                                                    {!props.viewOnly && <div className="card-actions float-right">
                                                        <ButtonMaterial variant="contained" color='primary'
                                                            sx={{minWidth: 106,  maxWidth: 106}} size='small'
                                                            onClick={onClick_AddEngine}>
                                                            <AddIcon />
                                                            Motor
                                                        </ButtonMaterial>
                                                    </div>}
                                                </legend>
                                                {props.engines && props.engines.map((engine: any, index: number) => (
                                                <Row key={`engine-${index}`}>
                                                    <Col sm="9">
                                                        <Row>
                                                            <Col sm="3">
                                                                <FormGroup>
                                                                    <Form.Label htmlFor="form-validity-tr">{display.label.maker}</Form.Label>
                                                                    <Form.Select id="form-maker" name="maker"
                                                                        value={toString(engine.maker)} onChange={(e: any)=>
                                                                        onChange_UpdateEngineItem(e, index, 'maker',
                                                                        e.target.value)}
                                                                    >
                                                                        <option value="">...</option>
                                                                        { MakerTypes.map((iterator: any, idx: number) => (
                                                                            <option key={idx} value={iterator.id}> {iterator.description}</option>
                                                                        )).sort((a: any, b: any) => { 
                                                                            return a.props.children > b.props.children ? 1 : -1;
                                                                        }) }
                                                                    </Form.Select>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col sm="3">
                                                                <FormGroup>
                                                                    <Form.Label htmlFor="form-validity-tr">Modelo</Form.Label>
                                                                    <FormControl id="form-model" name="model"
                                                                        placeholder={display.example.type_and_model}
                                                                        value={toString(engine.model)} onChange={(e)=>
                                                                        onChange_UpdateEngineItem(e, index, 'model',
                                                                        e.target.value)}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col sm="3">
                                                                <FormGroup>
                                                                    <Form.Label htmlFor="form-validity-tr">{display.label.type_and_model}</Form.Label>
                                                                    <Form.Select id="form-norm" name="norm"
                                                                        value={toString(engine.norm)} onChange={(e: any)=>
                                                                        onChange_UpdateEngineItem(e, index, 'norm',
                                                                        e.target.value)}
                                                                    >
                                                                        <option value="">...</option>
                                                                        { EngineTypes.map((iterator: any, idx: number) => (
                                                                            <option key={idx} value={iterator.id}>{iterator.description}</option>
                                                                        )).sort((a: any, b: any) => { 
                                                                            return a.props.children > b.props.children ? 1 : -1;
                                                                        }) }
                                                                    </Form.Select>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col sm="3">
                                                                <FormGroup>
                                                                    <Form.Label htmlFor="form-validity-tr">Ano</Form.Label>
                                                                    <FormControl id="form-year" name="year"
                                                                        value={toString(engine.year)} onChange={(e)=>
                                                                        onChange_UpdateEngineItem(e, index, 'year',
                                                                        e.target.value)}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm="3">
                                                                <FormGroup>
                                                                    <Form.Label htmlFor="form-validity-tr">Número de Série</Form.Label>
                                                                    <FormControl id="form-serial-number" name="serialNumber"
                                                                        placeholder={display.example.serial_number}
                                                                        value={toString(engine.serialNumber)} onChange={(e)=>
                                                                        onChange_UpdateEngineItem(e, index, 'serialNumber',
                                                                        e.target.value)}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col sm="3">
                                                                <FormGroup>
                                                                    <Form.Label htmlFor="form-validity-tr">Potência (HP)</Form.Label>
                                                                    <FormControl id="form-potency" name="potency"
                                                                        value={toString(engine.potency)} onChange={(e)=>
                                                                        onChange_UpdateEngineItem(e, index, 'potency',
                                                                        e.target.value)}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col sm="3">
                                                                <FormGroup>
                                                                    <Form.Label htmlFor="form-validity-tr">Combustível</Form.Label>
                                                                    <Form.Select id="form-fuel" name="fuel"
                                                                        value={toString(engine.fuel)} onChange={(e: any)=>
                                                                        onChange_UpdateEngineItem(e, index, 'fuel',
                                                                        e.target.value)}
                                                                    >
                                                                        <option value="">...</option>
                                                                        { FuelTypes.map((iterator: any, idx: number) => (
                                                                            <option key={idx} value={iterator.id}>{iterator.description}</option>
                                                                        )).sort((a: any, b: any) => { 
                                                                            return a.props.children > b.props.children ? 1 : -1;
                                                                        })}
                                                                    </Form.Select>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col sm="3">
                                                                <FormGroup>
                                                                    <Form.Label htmlFor="form-origin">Procedência</Form.Label>
                                                                    <Form.Select id="form-origin" name="fuel"
                                                                        value={toString(engine.origin)} onChange={(e: any)=>
                                                                        onChange_UpdateEngineItem(e, index, 'origin',
                                                                        e.target.value)}
                                                                    >
                                                                        <option value="">...</option>
                                                                        { OriginType.map((iterator: any, idx: number) => (
                                                                        <option key={idx} value={iterator.id}>{iterator.description}</option>
                                                                        )).sort((a: any, b: any) => { 
                                                                            return a.props.children > b.props.children ? 1 : -1;
                                                                        })}
                                                                    </Form.Select>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col sm="2">
                                                        <div className="d-flex justify-content-between">
                                                            {!isEmpty(engine.image) ? (
                                                            <div className='d-flex flex-column justify-content-center align-items-center'>
                                                                <ImageLightBox containerStyle={`${styles.imageWrapper} ${styles.vesselImage}`} src={engine.image}
                                                                    alt={'Motor'}
                                                                    noBackground />
                                                                {!props.viewOnly && <FormGroup as={Row} className={`mt-3 d-flex justify-content-start ${styles.imageControls}`}>
                                                                    <label htmlFor={`input-file-image-engine-${index}`}>
                                                                        <TooltipMaterial className="isClickable" title="Fazer upload de nova foto">
                                                                            <CloudUploadIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <label className="text-right" onClick={(e)=> onChange_ImageEngineItem(e, index)}>
                                                                        <TooltipMaterial className="isClickable ms-2" title="Remover foto atual">
                                                                            <HighlightOffIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <Form.Control type='file' id={`input-file-image-engine-${index}`} accept="image/*" onChange={(e)=> onChange_ImageEngineItem(e, index)} />
                                                                </FormGroup>}
                                                            </div>
                                                            ) : (
                                                            <div className={`${styles.imageWrapper} ${styles.vesselImagePlaceholder}`}>
                                                                <CameraAltOutlined color='secondary' className={`isClickable mb-3 ${styles.svg}`} sx={{fontSize: '73px'}} />
                                                                {!props.viewOnly && <FormGroup as={Row} className={` d-flex justify-content-start ${styles.imageControls}`}>
                                                                    <label htmlFor={`input-file-image-engine-${index}`}>
                                                                        <TooltipMaterial className="isClickable" title="Fazer upload de nova foto">
                                                                            <CloudUploadIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <label className="text-right">
                                                                        <TooltipMaterial className="isClickable ms-2" title="Remover foto atual">
                                                                            <HighlightOffIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <Form.Control type='file' id={`input-file-image-engine-${index}`} accept="image/*" onChange={(e)=> onChange_ImageEngineItem(e, index)} />
                                                                </FormGroup>}
                                                            </div>
                                                            )}
                                                        </div>
                                                    </Col>
                                                    <Col sm="1" style={{maxWidth:30}}>
                                                        <OverlayTrigger overlay={<ToolTipBootstrap id="tooltip">{display.tooltips.delete}</ToolTipBootstrap>}>
                                                            <a href="/#" onClick={(e)=> onClick_RemoveEngineItem(e, index)}>
                                                                <FaMinusSquare className='mt-4' size={18} />
                                                            </a>
                                                        </OverlayTrigger>
                                                    </Col>
                                                </Row>
                                                ))}
                                            </fieldset>
                                            <fieldset>
                                                <legend>Imagens</legend>
                                                <div className="d-flex justify-content-center mt-3">
                                                    <div key={0} className='d-flex flex-column align-items-center justify-content-center'>
                                                        <small style={{marginBottom: '-20px'}}>{display.label[toLowerCase(props.prowImage.norm)]}</small>
                                                        {!isEmpty(props.prowImage.document) ? (
                                                            <>
                                                                <ImageLightBox containerStyle={`${styles.imageWrapper} ${styles.vesselImage}`} src={props.prowImage.document} alt={display.label[toLowerCase(props.prowImage.norm)]} noBackground />
                                                                {!props.viewOnly && <FormGroup as={Row} className={`mt-2 d-flex justify-content-start ${styles.imageControls}`}>
                                                                    <label htmlFor={`input-file-image-prow-image`}>
                                                                        <TooltipMaterial className="isClickable" title="Fazer upload de nova foto">
                                                                            <CloudUploadIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <label className="text-right" onClick={(e)=> onChange_ImageVesselItem(e, props.prowImage.norm, 0)}>
                                                                        <TooltipMaterial className="isClickable ms-2" title="Remover foto atual">
                                                                            <HighlightOffIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <Form.Control type='file' id={`input-file-image-prow-image`} accept="image/*" onChange={(e)=> onChange_ImageVesselItem(e, props.prowImage.norm, 0)} />
                                                                </FormGroup>}
                                                            </>
                                                        ) : (
                                                            <div className={`${styles.imageWrapper} ${styles.vesselImagePlaceholder}`}>
                                                                <CameraAltOutlined color='secondary' className={`isClickable mb-1 ${styles.svg}`} sx={{fontSize: '73px'}} />
                                                                {!props.viewOnly && <FormGroup as={Row} className={`mt-2 d-flex justify-content-start ${styles.imageControls}`}>
                                                                    <label htmlFor={`input-file-image-prow-image`}>
                                                                        <TooltipMaterial className="isClickable" title="Fazer upload de nova foto">
                                                                            <CloudUploadIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <label className="text-right" onClick={(e)=> onChange_ImageVesselItem(e, props.prowImage.norm, 0)}>
                                                                        <TooltipMaterial className="isClickable ms-2" title="Remover foto atual">
                                                                            <HighlightOffIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <Form.Control type='file' id={`input-file-image-prow-image`} accept="image/*" onChange={(e)=> onChange_ImageVesselItem(e, props.prowImage.norm, 0)} />
                                                                </FormGroup>}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div key={1} className='d-flex flex-column align-items-center justify-content-center'>
                                                        <small style={{marginBottom: '-20px'}}>{display.label[toLowerCase(props.sternImage.norm)]}</small>
                                                        {!isEmpty(props.sternImage.document) ? (
                                                            <>
                                                                <ImageLightBox containerStyle={`${styles.imageWrapper} ${styles.vesselImage}`} src={props.sternImage.document} alt={display.label[toLowerCase(props.sternImage.norm)]} noBackground />
                                                                {!props.viewOnly && <FormGroup as={Row} className={`mt-2 d-flex justify-content-start ${styles.imageControls}`}>
                                                                    <label htmlFor={`input-file-image-stern-image`}>
                                                                        <TooltipMaterial className="isClickable" title="Fazer upload de nova foto">
                                                                            <CloudUploadIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <label className="text-right" onClick={(e)=> onChange_ImageVesselItem(e, props.sternImage.norm, 1)}>
                                                                        <TooltipMaterial className="isClickable ms-2" title="Remover foto atual">
                                                                            <HighlightOffIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <Form.Control type='file' id={`input-file-image-stern-image`} accept="image/*" onChange={(e)=> onChange_ImageVesselItem(e, props.sternImage.norm, 1)} />
                                                                </FormGroup>}
                                                            </>
                                                        ) : (
                                                            <div className={`${styles.imageWrapper} ${styles.vesselImagePlaceholder}`}>
                                                                <CameraAltOutlined color='secondary' className={`isClickable mb-1 ${styles.svg}`} sx={{fontSize: '73px'}} />
                                                                {!props.viewOnly && <FormGroup as={Row} className={`mt-2 d-flex justify-content-start ${styles.imageControls}`}>
                                                                    <label htmlFor={`input-file-image-stern-image`}>
                                                                        <TooltipMaterial className="isClickable" title="Fazer upload de nova foto">
                                                                            <CloudUploadIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <label className="text-right" onClick={(e)=> onChange_ImageVesselItem(e, props.sternImage.norm, 1)}>
                                                                        <TooltipMaterial className="isClickable ms-2" title="Remover foto atual">
                                                                            <HighlightOffIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <Form.Control type='file' id={`input-file-image-stern-image`} accept="image/*" onChange={(e)=> onChange_ImageVesselItem(e, props.sternImage.norm, 1)} />
                                                                </FormGroup>}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div key={2} className='d-flex flex-column align-items-center justify-content-center'>
                                                        <small style={{marginBottom: '-20px'}}>{display.label[toLowerCase(props.portboardImage.norm)]}</small>
                                                        {!isEmpty(props.portboardImage.document) ? (
                                                            <>
                                                                <ImageLightBox containerStyle={`${styles.imageWrapper} ${styles.vesselImage}`} src={props.portboardImage.document} alt={display.label[toLowerCase(props.portboardImage.norm)]} noBackground />
                                                                {!props.viewOnly && <FormGroup as={Row} className={`mt-2 d-flex justify-content-start ${styles.imageControls}`}>
                                                                    <label htmlFor={`input-file-image-portboard-image`}>
                                                                        <TooltipMaterial className="isClickable" title="Fazer upload de nova foto">
                                                                            <CloudUploadIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <label className="text-right" onClick={(e)=> onChange_ImageVesselItem(e, props.portboardImage.norm, 2)}>
                                                                        <TooltipMaterial className="isClickable ms-2" title="Remover foto atual">
                                                                            <HighlightOffIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <Form.Control type='file' id={`input-file-image-portboard-image`} accept="image/*" onChange={(e)=> onChange_ImageVesselItem(e, props.portboardImage.norm, 2)} />
                                                                </FormGroup>}
                                                            </>
                                                        ) : (
                                                            <div className={`${styles.imageWrapper} ${styles.vesselImagePlaceholder}`}>
                                                                <CameraAltOutlined color='secondary' className={`isClickable mb-1 ${styles.svg}`} sx={{fontSize: '73px'}} />
                                                                {!props.viewOnly && <FormGroup as={Row} className={`mt-2 d-flex justify-content-start ${styles.imageControls}`}>
                                                                    <label htmlFor={`input-file-image-portboard-image`}>
                                                                        <TooltipMaterial className="isClickable" title="Fazer upload de nova foto">
                                                                            <CloudUploadIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <label className="text-right" onClick={(e)=> onChange_ImageVesselItem(e, props.portboardImage.norm, 2)}>
                                                                        <TooltipMaterial className="isClickable ms-2" title="Remover foto atual">
                                                                            <HighlightOffIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <Form.Control type='file' id={`input-file-image-portboard-image`} accept="image/*" onChange={(e)=> onChange_ImageVesselItem(e, props.portboardImage.norm, 2)} />
                                                                </FormGroup>}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div key={3} className='d-flex flex-column align-items-center justify-content-center'>
                                                        <small style={{marginBottom: '-20px'}}>{display.label[toLowerCase(props.starboardImage.norm)]}</small>
                                                        {!isEmpty(props.starboardImage.document) ? (
                                                            <>
                                                                <ImageLightBox containerStyle={`${styles.imageWrapper} ${styles.vesselImage}`} src={props.starboardImage.document} alt={display.label[toLowerCase(props.starboardImage.norm)]} noBackground />
                                                                {!props.viewOnly && <FormGroup as={Row} className={`mt-2 d-flex justify-content-start ${styles.imageControls}`}>
                                                                    <label htmlFor={`input-file-image-starboard-image`}>
                                                                        <TooltipMaterial className="isClickable" title="Fazer upload de nova foto">
                                                                            <CloudUploadIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <label className="text-right" onClick={(e)=> onChange_ImageVesselItem(e, props.starboardImage.norm, 3)}>
                                                                        <TooltipMaterial className="isClickable ms-2" title="Remover foto atual">
                                                                            <HighlightOffIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <Form.Control type='file' id={`input-file-image-starboard-image`} accept="image/*" onChange={(e)=> onChange_ImageVesselItem(e, props.starboardImage.norm, 3)} />
                                                                </FormGroup>}
                                                            </>
                                                        ) : (
                                                            <div className={`${styles.imageWrapper} ${styles.vesselImagePlaceholder}`}>
                                                                <CameraAltOutlined color='secondary' className={`isClickable mb-1 ${styles.svg}`} sx={{fontSize: '73px'}} />
                                                                {!props.viewOnly && <FormGroup as={Row} className={`mt-2 d-flex justify-content-start ${styles.imageControls}`}>
                                                                    <label htmlFor={`input-file-image-starboard-image`}>
                                                                        <TooltipMaterial className="isClickable" title="Fazer upload de nova foto">
                                                                            <CloudUploadIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <label className="text-right" onClick={(e)=> onChange_ImageVesselItem(e, props.starboardImage.norm, 3)}>
                                                                        <TooltipMaterial className="isClickable ms-2" title="Remover foto atual">
                                                                            <HighlightOffIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <Form.Control type='file' id={`input-file-image-starboard-image`} accept="image/*" onChange={(e)=> onChange_ImageVesselItem(e, props.starboardImage.norm, 3)} />
                                                                </FormGroup>}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div key={4} className='d-flex flex-column align-items-center justify-content-center'>
                                                        <small style={{marginBottom: '-20px'}}>{display.label[toLowerCase(props.panelImage.norm)]}</small>
                                                        {!isEmpty(props.panelImage.document) ? (
                                                            <>
                                                                <ImageLightBox containerStyle={`${styles.imageWrapper} ${styles.vesselImage}`} src={props.panelImage.document} alt={display.label[toLowerCase(props.panelImage.norm)]} noBackground />
                                                                {!props.viewOnly && <FormGroup as={Row} className={`mt-2 d-flex justify-content-start ${styles.imageControls}`}>
                                                                    <label htmlFor={`input-file-image-panel-image`}>
                                                                        <TooltipMaterial className="isClickable" title="Fazer upload de nova foto">
                                                                            <CloudUploadIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <label className="text-right" onClick={(e)=> onChange_ImageVesselItem(e, props.panelImage.norm, 4)}>
                                                                        <TooltipMaterial className="isClickable ms-2" title="Remover foto atual">
                                                                            <HighlightOffIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <Form.Control type='file' id={`input-file-image-panel-image`} accept="image/*" onChange={(e)=> onChange_ImageVesselItem(e, props.panelImage.norm, 4)} />
                                                                </FormGroup>}
                                                            </>
                                                        ) : (
                                                            <div className={`${styles.imageWrapper} ${styles.vesselImagePlaceholder}`}>
                                                                <CameraAltOutlined color='secondary' className={`isClickable mb-1 ${styles.svg}`} sx={{fontSize: '73px'}} />
                                                                {!props.viewOnly && <FormGroup as={Row} className={`mt-2 d-flex justify-content-start ${styles.imageControls}`}>
                                                                    <label htmlFor={`input-file-image-panel-image`}>
                                                                        <TooltipMaterial className="isClickable" title="Fazer upload de nova foto">
                                                                            <CloudUploadIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <label className="text-right" onClick={(e)=> onChange_ImageVesselItem(e, props.panelImage.norm, 4)}>
                                                                        <TooltipMaterial className="isClickable ms-2" title="Remover foto atual">
                                                                            <HighlightOffIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <Form.Control type='file' id={`input-file-image-panel-image`} accept="image/*" onChange={(e)=> onChange_ImageVesselItem(e, props.panelImage.norm, 4)} />
                                                                </FormGroup>}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div key={5} className='d-flex flex-column align-items-center justify-content-center'>
                                                        <small style={{marginBottom: '-20px'}}>{display.label[toLowerCase(props.interiorImage.norm)]}</small>
                                                        {!isEmpty(props.interiorImage.document) ? (
                                                            <>
                                                                <ImageLightBox containerStyle={`${styles.imageWrapper} ${styles.vesselImage}`} src={props.interiorImage.document} alt={display.label[toLowerCase(props.interiorImage.norm)]} noBackground />
                                                                {!props.viewOnly && <FormGroup as={Row} className={`mt-2 d-flex justify-content-start ${styles.imageControls}`}>
                                                                    <label htmlFor={`input-file-image-interior-image`}>
                                                                        <TooltipMaterial className="isClickable" title="Fazer upload de nova foto">
                                                                            <CloudUploadIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <label className="text-right" onClick={(e)=> onChange_ImageVesselItem(e, props.interiorImage.norm, 5)}>
                                                                        <TooltipMaterial className="isClickable ms-2" title="Remover foto atual">
                                                                            <HighlightOffIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <Form.Control type='file' id={`input-file-image-interior-image`} accept="image/*" onChange={(e)=> onChange_ImageVesselItem(e, props.interiorImage.norm, 5)} />
                                                                </FormGroup>}
                                                            </>
                                                        ) : (
                                                            <div className={`${styles.imageWrapper} ${styles.vesselImagePlaceholder}`}>
                                                                <CameraAltOutlined color='secondary' className={`isClickable mb-1 ${styles.svg}`} sx={{fontSize: '73px'}} />
                                                                {!props.viewOnly && <FormGroup as={Row} className={`mt-2 d-flex justify-content-start ${styles.imageControls}`}>
                                                                    <label htmlFor={`input-file-image-interior-image`}>
                                                                        <TooltipMaterial className="isClickable" title="Fazer upload de nova foto">
                                                                            <CloudUploadIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <label className="text-right" onClick={(e)=> onChange_ImageVesselItem(e, props.interiorImage.norm, 5)}>
                                                                        <TooltipMaterial className="isClickable ms-2" title="Remover foto atual">
                                                                            <HighlightOffIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <Form.Control type='file' id={`input-file-image-interior-image`} accept="image/*" onChange={(e)=> onChange_ImageVesselItem(e, props.interiorImage.norm, 5)} />
                                                                </FormGroup>}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div key={6} className='d-flex flex-column align-items-center justify-content-center'>
                                                        <small style={{marginBottom: '-20px'}}>{display.label[toLowerCase(props.cabinImage.norm)]}</small>
                                                        {!isEmpty(props.cabinImage.document) ? (
                                                        <>
                                                            <ImageLightBox containerStyle={`${styles.imageWrapper} ${styles.vesselImage}`} src={props.cabinImage.document} alt={display.label[toLowerCase(props.cabinImage.norm)]} noBackground />
                                                            {!props.viewOnly && <FormGroup as={Row} className={`mt-2 d-flex justify-content-start ${styles.imageControls}`}>
                                                                <label htmlFor={`input-file-image-cabin-image`}>
                                                                    <TooltipMaterial className="isClickable" title="Fazer upload de nova foto">
                                                                        <CloudUploadIcon />
                                                                    </TooltipMaterial>
                                                                </label>
                                                                <label className="text-right" onClick={(e)=> onChange_ImageVesselItem(e, props.cabinImage.norm, 6)}>
                                                                    <TooltipMaterial className="isClickable ms-2" title="Remover foto atual">
                                                                        <HighlightOffIcon />
                                                                    </TooltipMaterial>
                                                                </label>
                                                                <Form.Control type='file' id={`input-file-image-cabin-image`} accept="image/*" onChange={(e)=> onChange_ImageVesselItem(e, props.cabinImage.norm, 6)} />
                                                            </FormGroup>}
                                                        </>
                                                        ) : (
                                                        <div className={`${styles.imageWrapper} ${styles.vesselImagePlaceholder}`}>
                                                            <CameraAltOutlined color='secondary' className={`isClickable mb-1 ${styles.svg}`} sx={{fontSize: '73px'}} />
                                                            {!props.viewOnly && <FormGroup as={Row} className={`mt-2 d-flex justify-content-start ${styles.imageControls}`}>
                                                                <label htmlFor={`input-file-image-cabin-image`}>
                                                                    <TooltipMaterial className="isClickable" title="Fazer upload de nova foto">
                                                                        <CloudUploadIcon />
                                                                    </TooltipMaterial>
                                                                </label>
                                                                <label className="text-right" onClick={(e)=> onChange_ImageVesselItem(e, props.cabinImage.norm, 6)}>
                                                                    <TooltipMaterial className="isClickable ms-2" title="Remover foto atual">
                                                                        <HighlightOffIcon />
                                                                    </TooltipMaterial>
                                                                </label>
                                                                <Form.Control type='file' id={`input-file-image-cabin-image`} accept="image/*" onChange={(e)=> onChange_ImageVesselItem(e, props.cabinImage.norm, 6)} />
                                                            </FormGroup>}
                                                        </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </fieldset>
                                            <Row>
                                                <Col sm="12" className="ps-0 pe-0">
                                                    <fieldset>
                                                        <legend>{display.legend.associates}
                                                            {!props.viewOnly && <div className="card-actions float-right">
                                                                <ButtonMaterial sx={{minWidth: 106,  maxWidth: 106}}
                                                                    variant="contained" color='primary' size='small'
                                                                    onClick={onClick_AddResponsible}>
                                                                    <AddIcon />
                                                                    Proprietário
                                                                </ButtonMaterial>
                                                            </div>}
                                                        </legend>
                                                        {props.responsibles.length > 0 && (
                                                            <TableContainer columns={props.columnsResponsible} data={isEmpty(props.responsibles) ? [] : props.responsibles} />
                                                        )}
                                                    </fieldset>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm="12" className="ps-0 pe-0">
                                                    <fieldset>
                                                        <legend>{display.legend.pre_authorized_persons}
                                                            {!props.viewOnly && <div className="card-actions float-right">
                                                                <ButtonMaterial variant="contained" color='primary' size='small'
                                                                    sx={{minWidth: 106,  maxWidth: 106}}
                                                                    onClick={onClick_AddConductor}>
                                                                    <AddIcon />
                                                                    Condutor
                                                                </ButtonMaterial>
                                                            </div>}
                                                        </legend>
                                                        {props.conductors.length > 0 && (
                                                            <TableContainer columns={props.columnsConductor} data={isEmpty(props.conductors) ? [] : props.conductors} />
                                                        )}
                                                    </fieldset>
                                                </Col>
                                            </Row>
                                            <fieldset>
                                                <legend>{display.legend.documentation_annex}</legend>
                                                <Table size="sm">
                                                    <tbody>
                                                        <tr>
                                                            <td ></td>
                                                            <td style={{display:  'flex', justifyContent: 'flex-end'}}>
                                                                <span className='me-5 mt-2' style={{verticalAlign: 'initial'}}>

                                                                    {display.label.vessel_document}
                                                                </span>
                                                                <InputGroup style={{width: '420px'}}>
                                                                    <FormControl className="input-file-text"
                                                                        id="form-file-vessel-document" name="vesselDocument"
                                                                        value={toString(props.vesselDocument.filename)}
                                                                        readOnly
                                                                        style={{marginBottom: '2px'}}
                                                                    />
                                                                    {!props.viewOnly && <><Form.Control type='file' id='input-file-vessel-document' 
                                                                        onChange={ (e)=> {onChange_VesselDocument(e) }} />
                                                                    <OverlayTrigger overlay={<ToolTipBootstrap id="tooltip">
                                                                        {display.tooltips.upload_documents}</ToolTipBootstrap>}>
                                                                        <Form.Label className="input-file-button"
                                                                            htmlFor='input-file-vessel-document'>
                                                                            <UploadFileIcon />
                                                                        </Form.Label>
                                                                    </OverlayTrigger></>}
                                                                    {!isEmpty(props.vesselDocument.document) ? (
                                                                        <>
                                                                        <Form.Label
                                                                            className="open-file-button input-file-button"
                                                                            htmlFor='input-file-vessel-document'
                                                                            onClick={ (e)=> { onClick_ViewDocument(e, display.label.vessel_document, props.vesselDocument.document) }}
                                                                        >
                                                                            {display.buttom.visualize}
                                                                        </Form.Label>
                                                                        {!props.viewOnly && <OverlayTrigger overlay={<ToolTipBootstrap id="tooltip">
                                                                            {display.tooltips.remove_documents}</ToolTipBootstrap>}>
                                                                            <Form.Label className="input-file-button"
                                                                                onClick={(e)=> {onChange_VesselDocument(e)}}
                                                                            >
                                                                                <FaTrashAlt size={18} />
                                                                            </Form.Label>
                                                                        </OverlayTrigger>}
                                                                        </>
                                                                    ) : null}
                                                                </InputGroup>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </fieldset>
                                        </Container>
                                    </Tab>
                                    <Tab eventKey="ship-protection" title={display.subtitle.protection}>
                                        <Container fluid className="container-min">
                                            <fieldset>
                                                <legend>{display.legend.insurance_data}</legend>
                                                <Row>
                                                    <Col sm="3" className="ms-0 me-0">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-insurance-registry">CNPJ</Form.Label>
                                                            <FormControl id="form-insurance-registry" name="insuranceRegistry"
                                                                maxLength={14}
                                                                placeholder={display.example.insurance_name}
                                                                value={toString(props.insuranceRegistry)} 
                                                                onChange={e=> props.setInsuranceRegistry(cnpjMask(e.target.value))}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="3" className="ms-0 me-0">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-insurance-name">{display.label.insurance_name}</Form.Label>
                                                            <FormControl id="form-insurance-name" name="insuranceName"
                                                                placeholder={display.example.insurance_name}
                                                                value={toString(props.insuranceName)} 
                                                                onChange={e=> props.setInsuranceName(e.target.value)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="2">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-insurance-number">{display.label.insurance_number}</Form.Label>
                                                            <FormControl id="form-insurance-number" name="insuranceNumber"
                                                                placeholder={display.example.insurance_number}
                                                                value={toString(props.insuranceNumber)} 
                                                                onChange={e=> props.setInsuranceNumber(toUpperCase(e.target.value))}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="2">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-insurance-validity">{display.label.insurance_validity}</Form.Label>
                                                            <FormControl id="form-insurance-validity" name="insuranceValidity"
                                                                maxLength={10} 
                                                                placeholder={display.example.insurance_validity}
                                                                value={toString(props.insuranceValidity)} 
                                                                onChange={e=> props.setInsuranceValidity(dataMask(e.target.value))}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="2">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-insurance-cost">{display.label.insurance_cost}</Form.Label>
                                                            <FormControl id="form-insurance-cost" name="insuranceCost"
                                                                readOnly
                                                                placeholder={display.example.insurance_cost}
                                                                className="text-right" 
                                                                value={toString(props.insuranceCost)} 
                                                                onChange={e=> props.setInsuranceCost(e.target.value)}
                                                                onBlur={e=> props.setInsuranceCost(floatMask(e.target.value))}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm="4">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-insurance-contact">{display.label.insurance_contact}</Form.Label>
                                                            <FormControl id="form-insurance-contact" name="insuranceContact"
                                                                placeholder={display.example.insurance_contact}
                                                                value={toString(props.insuranceContact)} 
                                                                onChange={e=> props.setInsuranceContact(e.target.value)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="2">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-insurance-phone1">{display.label.insurance_phone1}</Form.Label>
                                                            <FormControl id="form-insurance-phone1" name="insurancePhone1"
                                                                placeholder={display.example.insurance_phone}
                                                                value={toString(props.insurancePhone1)}
                                                                onChange={e=> props.setInsurancePhone1(phoneMask(e.target.value))}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="2">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-insurance-phone2">{display.label.insurance_phone2}</Form.Label>
                                                            <FormControl id="form-insurance-phone2" name="insurancePhone2"
                                                                placeholder={display.example.insurance_phone} 
                                                                value={toString(props.insurancePhone2)}
                                                                onChange={e=> props.setInsurancePhone2(phoneMask(e.target.value))}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-insurance-email">{display.label.insurance_email}</Form.Label>
                                                            <FormControl id="form-insurance-email" name="insuranceEmail"
                                                                placeholder={display.example.insurance_email}
                                                                value={toString(props.insuranceEmail)}
                                                                onChange={e=> props.setInsuranceEmail(e.target.value)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </fieldset>
                                            <Row>
                                                <Col sm="8" className="ps-0 pe-0">
                                                    <fieldset>
                                                        <legend>{display.legend.vessel_values}</legend>
                                                        <Row>
                                                            <Col sm="12">
                                                            <Table size="sm">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <Form.Label htmlFor="form-hull-cost">
                                                                                {display.label.hull}</Form.Label>
                                                                        </td>
                                                                        <td>
                                                                            <InputGroup>
                                                                                <InputGroup.Text>
                                                                                    <BsCashCoin />
                                                                                </InputGroup.Text>
                                                                                <FormControl id="form-hull-cost" name="hullCost"
                                                                                    maxLength={10}
                                                                                    placeholder={display.example.hull_cost}
                                                                                    className="text-right"
                                                                                    value={toString(props.hullCost)}
                                                                                    onChange={e=>
                                                                                    props.setHullCost(floatMask(e.target.value))}
                                                                                    onBlur={e => onBlur_SumVesselCost(e)}
                                                                                    />
                                                                            </InputGroup>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <Form.Label htmlFor="form-engine-cost">
                                                                                Motor(es)/Turbina(s)</Form.Label>
                                                                        </td>
                                                                        <td>
                                                                            <InputGroup>
                                                                                <InputGroup.Text>
                                                                                    <BsCashCoin />
                                                                                </InputGroup.Text>
                                                                                <FormControl id="form-engine-cost"
                                                                                    name="engineCost" maxLength={10}
                                                                                    placeholder={display.example.engine_cost}
                                                                                    className="text-right"
                                                                                    value={toString(props.engineCost)}
                                                                                    onChange={e=>
                                                                                    props.setEngineCost(floatMask(e.target.value))}
                                                                                    onBlur={e => onBlur_SumVesselCost(e)}
                                                                                    />
                                                                            </InputGroup>
                                                                        </td>
                                                                    </tr>
                                                                    {toInt(props.typeVessel) !== VESSEL_JETSKI && (

                                                                        <tr>
                                                                            <td>
                                                                                <Form.Label htmlFor="form-generators-cost">
                                                                                    {display.label.generators}</Form.Label>
                                                                            </td>
                                                                            <td>
                                                                                <InputGroup>
                                                                                    <InputGroup.Text>
                                                                                        <BsCashCoin />
                                                                                    </InputGroup.Text>
                                                                                    <FormControl id="form-generators-cost"
                                                                                        name="generatorsCost" maxLength={10}
                                                                                        placeholder={display.example.generators_cost}
                                                                                        className="text-right"
                                                                                        value={toString(props.generatorsCost)}
                                                                                        onChange={e=>
                                                                                        props.setGeneratorsCost(floatMask(e.target.value))}
                                                                                        onBlur={e => onBlur_SumVesselCost(e)}
                                                                                        />
                                                                                </InputGroup>
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                                    <tr>
                                                                        <td>
                                                                            <Form.Label htmlFor="form-soundsystem-cost">
                                                                                {display.label.soundsystem}</Form.Label>
                                                                        </td>
                                                                        <td>
                                                                            <InputGroup>
                                                                                <InputGroup.Text>
                                                                                    <BsCashCoin />
                                                                                </InputGroup.Text>
                                                                                <FormControl id="form-soundsystem-cost"
                                                                                    name="soundsystemCost" maxLength={10}
                                                                                    placeholder={display.example.soundsystem_cost}
                                                                                    className="text-right"
                                                                                    value={toString(props.soundsystemCost)}
                                                                                    onChange={e=>
                                                                                    props.setSoundsystemCost(floatMask(e.target.value))}
                                                                                    onBlur={e => onBlur_SumVesselCost(e)}
                                                                                    />
                                                                            </InputGroup>
                                                                        </td>
                                                                    </tr>
                                                                    {toInt(props.typeVessel) !== VESSEL_JETSKI && (

                                                                        <tr>
                                                                            <td>
                                                                                <Form.Label htmlFor="form-sails-and-masts-cost">
                                                                                    {display.label.sails_and_masts}</Form.Label>
                                                                            </td>
                                                                            <td>
                                                                                <InputGroup>
                                                                                    <InputGroup.Text>
                                                                                        <BsCashCoin />
                                                                                    </InputGroup.Text>
                                                                                    <FormControl id="form-sails-and-masts-cost"
                                                                                        name="sailsAndMastsCost" maxLength={10}
                                                                                        placeholder={display.example.sails_and_masts_cost}
                                                                                        className="text-right"
                                                                                        value={toString(props.sailsAndMastsCost)}
                                                                                        onChange={e=>
                                                                                        props.setSailsAndMastsCost(floatMask(e.target.value))}
                                                                                        onBlur={e => onBlur_SumVesselCost(e)}
                                                                                        />
                                                                                </InputGroup>
                                                                            </td>
                                                                        </tr>

                                                                    )}

                                                                    <tr>
                                                                        <td>
                                                                            <Form.Label htmlFor="form-accessories-cost">
                                                                                {display.label.accessories}</Form.Label>
                                                                        </td>
                                                                        <td>
                                                                            <InputGroup>
                                                                                <InputGroup.Text>
                                                                                    <BsCashCoin />
                                                                                </InputGroup.Text>
                                                                                <FormControl id="form-accessories-cost"
                                                                                    name="accessoriesCost" maxLength={10}
                                                                                    placeholder={display.example.accessories_cost}
                                                                                    className="text-right"
                                                                                    value={toString(props.accessoriesCost)}
                                                                                    onChange={e=>
                                                                                    props.setAccessoriesCost(floatMask(e.target.value))}
                                                                                    onBlur={e => onBlur_SumVesselCost(e)}
                                                                                    />
                                                                            </InputGroup>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <Form.Label htmlFor="form-others-cost">
                                                                                {display.label.other}</Form.Label>
                                                                        </td>
                                                                        <td>
                                                                            <InputGroup>
                                                                                <InputGroup.Text>
                                                                                    <BsCashCoin />
                                                                                </InputGroup.Text>
                                                                                <FormControl id="form-others-cost"
                                                                                    name="othersCost" maxLength={10}
                                                                                    placeholder={display.example.others_cost}
                                                                                    className="text-right"
                                                                                    value={toString(props.othersCost)}
                                                                                    onChange={e=>
                                                                                    props.setOthersCost(floatMask(e.target.value))}
                                                                                    onBlur={e => onBlur_SumVesselCost(e)}
                                                                                    />
                                                                            </InputGroup>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <Form.Label htmlFor="form-vessel-cost">
                                                                                {display.label.amount}</Form.Label>
                                                                        </td>
                                                                        <td>
                                                                            <InputGroup>
                                                                                <InputGroup.Text>
                                                                                    <BsCashCoin />
                                                                                </InputGroup.Text>
                                                                                <FormControl id="form-vessel-cost"
                                                                                    name="vesselCost" maxLength={10}
                                                                                    placeholder={display.example.vessel_cost}
                                                                                    readOnly className="text-right"
                                                                                    value={toString(props.vesselCost)}
                                                                                    onChange={e=>
                                                                                    props.setVesselCost(floatMask(e.target.value))}
                                                                                    />
                                                                            </InputGroup>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>
                                                            </Col>
                                                        </Row>
                                                    </fieldset>
                                                </Col>
                                                <Col sm="4" className="ps-3 pe-0">
                                                    <fieldset>
                                                        <legend>
                                                            {display.legend.tracker}
                                                            <div className="card-actions float-right">
                                                                <a href="/#" onClick={(e)=> onClick_Tracker(e)}>
                                                                    <FaWifi size={22} />
                                                                </a>
                                                            </div>
                                                        </legend>
                                                        <Row>
                                                            <Col sm="5">
                                                                <Form.Label htmlFor="form-tracker-brand">{display.label.brand}</Form.Label>
                                                            </Col>
                                                            <Col sm="7">
                                                                <FormControl id="form-tracker-brand" name="trackerBrand"
                                                                    placeholder={display.example.brand}
                                                                    value={toString(props.trackerBrand)} 
                                                                    onChange={e => props.setTrackerBrand(e.target.value)}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="form-row-divider">
                                                            <Col sm="5">
                                                                <Form.Label htmlFor="form-tracker-model">{display.label.model}</Form.Label>
                                                            </Col>
                                                            <Col sm="7">
                                                                <FormControl id="form-tracker-model" name="trackerModel"
                                                                    placeholder={display.example.model}
                                                                    value={toString(props.trackerModel)}
                                                                    onChange={e => props.setTrackerModel(e.target.value)}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="form-row-divider">
                                                            <Col sm="5">
                                                                <Form.Label htmlFor="form-tracker-serial">{display.label.serial_number}</Form.Label>
                                                            </Col>
                                                            <Col sm="7">
                                                                <FormControl id="form-tracker-serial" name="trackerSerial"
                                                                    placeholder={display.example.serial_number}
                                                                    value={toString(props.trackerSerial)}
                                                                    onChange={e => props.setTrackerSerial(e.target.value)}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="form-row-divider">
                                                            <Col sm="5">
                                                                <Form.Label htmlFor="form-tracker-installer-name">{display.label.installer_name}</Form.Label>
                                                            </Col>
                                                            <Col sm="7">
                                                                <FormControl id="form-tracker-installer-name" name="trackerInstallerName"
                                                                    placeholder={display.example.installer_name}
                                                                    value={toString(props.trackerInstallerName)} 
                                                                    onChange={e => props.setTrackerInstallerName(e.target.value)}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="form-row-divider">
                                                            <Col sm="5">
                                                                <Form.Label htmlFor="form-tracker-installer-registry">{display.label.installer_registry}</Form.Label>
                                                            </Col>
                                                            <Col sm="7">
                                                                <FormControl id="form-tracker-installer-registry" name="trackerInstallerRegistry"
                                                                    placeholder={display.example.installer_registry}
                                                                    value={toString(props.trackerInstallerRegistry)} 
                                                                    onChange={e => props.setTrackerInstallerRegistry(cpfCnpjMask(e.target.value))}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="form-row-divider">
                                                            <Col sm="5">
                                                                <Form.Label htmlFor="form-tracker-installer-date">{display.label.installer_date}</Form.Label>
                                                            </Col>
                                                            <Col sm="7">
                                                                <FormControl id="form-tracker-installer-date" name="trackerInstallerDate"
                                                                    maxLength={10}
                                                                    placeholder={display.example.installer_date}
                                                                    value={toString(props.trackerInstallerDate)} 
                                                                    onChange={e => props.setTrackerInstallerDate(dataMask(e.target.value))}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="form-row-divider">
                                                            <Col sm="5">
                                                                <Form.Label htmlFor="form-tracker-responsible">{display.label.responsible}</Form.Label>
                                                            </Col>
                                                            <Col sm="7">
                                                                <FormControl id="form-tracker-responsible" name="trackerResponsible"
                                                                    placeholder={display.example.responsible}
                                                                    value={toString(props.trackerResponsible)} 
                                                                    onChange={e => props.setTrackerResponsible(e.target.value)}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="form-row-divider">
                                                            <Col sm="5">
                                                                <Form.Label htmlFor="form-tracker-situation">{display.label.situation}</Form.Label>
                                                            </Col>
                                                            <Col sm="7">
                                                                <Form.Select id="form-tracker-situation" name="trackerSituation"
                                                                    value={toString(props.trackerSituation)} 
                                                                    onChange={e => props.setTrackerSituation(e.target.value)}
                                                                    >
                                                                    { isEmpty(props.trackerSituation) ? (
                                                                        <option value="">...</option>
                                                                    ) : null }
                                                                    { TrackerSituationType.map((iterator: any, idx: any) => (
                                                                        <option key={idx} value={iterator.id}>{iterator.description}</option>
                                                                    ))}
                                                                </Form.Select>
                                                            </Col>
                                                        </Row>
                                                    </fieldset>
                                                </Col>
                                            </Row>
                                            <fieldset>
                                                <legend>{display.legend.documentation_annex}</legend>
                                                <Table size="sm">
                                                    <tbody>
                                                        <tr>
                                                        <td className='text-right' style={{verticalAlign: 'initial'}}>
                                                            </td>
                                                        <td style={{display:  'flex', justifyContent: 'flex-end'}}>
                                                            <span className='me-5 mt-2' style={{verticalAlign: 'initial'}}>

                                                                    {display.label.insurance_policy_document}
                                                            </span>
                                                                <InputGroup style={{width: '420px'}}>
                                                                    <FormControl className="input-file-text"
                                                                        id="form-file-insurance-policy-document" name="insurancePolicyDocument"
                                                                        value={toString(props.insurancePolicyDocument.filename)}
                                                                        readOnly
                                                                        style={{marginBottom: '2px'}}
                                                                    />
                                                                    <Form.Control type='file' id='input-file-insurance-policy-document' onChange={(e)=> { onChange_InsurancePolicyDocument(e) }} />
                                                                        {!props.viewOnly && <OverlayTrigger overlay={<ToolTipBootstrap id="tooltip">
                                                                            {display.tooltips.upload_documents}</ToolTipBootstrap>}>
                                                                            <Form.Label className="input-file-button"
                                                                                htmlFor='input-file-insurance-policy-document'>
                                                                                <UploadFileIcon />
                                                                            </Form.Label>
                                                                        </OverlayTrigger>}
                                                                        {!isEmpty(props.insurancePolicyDocument.document) ? (
                                                                            <>
                                                                            <Form.Label
                                                                                className="open-file-button input-file-button"
                                                                                htmlFor='file-insurance-policy-document'
                                                                                onClick={ (e)=> { onClick_ViewDocument(e, display.label.insurance_policy_document, props.insurancePolicyDocument.document)}}
                                                                            >
                                                                                {display.buttom.visualize}
                                                                            </Form.Label>
                                                                            {!props.viewOnly && <OverlayTrigger overlay={<ToolTipBootstrap id="tooltip">
                                                                                {display.tooltips.remove_documents}</ToolTipBootstrap>}>
                                                                                <Form.Label className="input-file-button"
                                                                                    onClick={(e)=> {onChange_VesselDocument(e)}}
                                                                                >
                                                                                    <FaTrashAlt size={18} />
                                                                                </Form.Label>
                                                                            </OverlayTrigger>}
                                                                            </>
                                                                        ) : null}
                                                                </InputGroup>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td ></td>
                                                            <td style={{display:  'flex', justifyContent: 'flex-end'}}>
                                                                <span className='me-5 mt-2' style={{verticalAlign: 'initial'}}>

                                                                {display.label.tracker_policy_document}
                                                                </span>
                                                                <InputGroup style={{width: '420px'}}>
                                                                    <FormControl className="input-file-text"
                                                                        id="form-file-drivers-license-document" name="driversLicenseDocument"
                                                                        value={toString(props.driversLicenseDocument.filename)}
                                                                        readOnly
                                                                        style={{marginBottom: '2px'}}
                                                                    />
                                                                    <Form.Control type='file' id='input-file-drivers-license-document' onChange={(e)=> { onChange_DriversLicenseDocument(e) }} />
                                                                        {!props.viewOnly && <OverlayTrigger overlay={<ToolTipBootstrap id="tooltip">
                                                                            {display.tooltips.upload_documents}</ToolTipBootstrap>}>
                                                                            <Form.Label className="input-file-button"
                                                                                htmlFor='input-file-drivers-license-document'>
                                                                                <UploadFileIcon />
                                                                            </Form.Label>
                                                                        </OverlayTrigger>}
                                                                        {!isEmpty(props.driversLicenseDocument.document) ? (
                                                                            <>
                                                                            <Form.Label
                                                                                className="open-file-button input-file-button"
                                                                                htmlFor='file-drivers-license-document'
                                                                                onClick={ (e)=> { onClick_ViewDocument(e, 
                                                                                    display.label.tracker_policy_document, 
                                                                                    props.driversLicenseDocument.document
                                                                                )
                                                                            }}>{display.buttom.visualize}</Form.Label>
                                                                            {!props.viewOnly && <OverlayTrigger overlay={<ToolTipBootstrap id="tooltip">
                                                                                {display.tooltips.remove_documents}</ToolTipBootstrap>}>
                                                                                <Form.Label className="input-file-button"
                                                                                    onClick={(e)=> {onChange_VesselDocument(e)}}
                                                                                >
                                                                                    <FaTrashAlt size={18} />
                                                                                </Form.Label>
                                                                            </OverlayTrigger>}
                                                                            </>
                                                                        ) : null}
                                                                </InputGroup>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </fieldset>
                                        </Container>
                                    </Tab>
                                    <Tab eventKey="ship-maintenance" title={display.subtitle.maintenance}>
                                        <Container fluid className="container-min">
                                            <Row>
                                                <Col sm="7">
                                                    <fieldset>
                                                        <legend>Agendamento</legend>
                                                        <Row>
                                                            <Col sm="4">
                                                            <FormGroup>
                                                                <Form.Label htmlFor="form-schedule-prohibited">
                                                                    {display.label.prohibited}</Form.Label>
                                                                <DatePicker locale={ptBR}
                                                                    selected={toDate(props.scheduleProhibited, 'dd/mm/yyyy HH:MM:ss'
                                                                    )} onChange={(date: any, e: any)=> onChange_Prohibited(e,
                                                                    date)}
                                                                    dateFormat="dd/MM/yyyy HH:mm"
                                                                    customInput={
                                                                    <PickerInput />}
                                                                    // minDate={new Date()}
                                                                    showTimeSelect
                                                                    timeFormat="HH:mm"
                                                                    timeIntervals={20}
                                                                    timeCaption="Hora"

                                                                    popperClassName="some-custom-class"
                                                                    popperPlacement="top-end"
                                                                    popperModifiers={[{
                                                                    name : "offset",
                                                                    options : {
                                                                    offset : [5, 10]
                                                                    }
                                                                    },{
                                                                    name : "preventOverflow",
                                                                    options : {
                                                                    rootBoundary : "viewport",
                                                                    tether : false,
                                                                    altAxis : true
                                                                    }
                                                                    }]}
                                                                />
                                                            </FormGroup>
                                                            </Col>
                                                            <Col sm="8">
                                                            <FormGroup>
                                                                <Form.Label htmlFor="form-schedule-title">{display.label.title}
                                                                </Form.Label>
                                                                <Form.Select id="form-schedule-title" name="scheduleTitle"
                                                                    value={toString(props.scheduleTitle)} onChange={(e: any)=>
                                                                    props.setScheduleTitle(e.target.value)}
                                                                    >
                                                                    { isEmpty(props.scheduleTitle) ? (
                                                                    <option value="">...</option>
                                                                    ) : null }
                                                                    { ScheduleTitleType.map((iterator: any, idx: any) => (
                                                                    <option key={idx} value={iterator.id}>
                                                                        {display.label[toLowerCase(iterator.name)]}</option>))}
                                                                </Form.Select>
                                                            </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm="12">
                                                            <FormGroup>
                                                                <Form.Label htmlFor="form-schedule-comments">
                                                                    {display.label.comments}</Form.Label>
                                                                <Form.Control as="textarea" rows={3} type=""
                                                                    id="form-schedule-comments" name="scheduleComments"
                                                                    placeholder={display.example.comments}
                                                                    value={toString(props.scheduleComments)} onChange={e=>
                                                                    props.setScheduleComments(e.target.value)}
                                                                    />
                                                            </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm="12" className="other-footer text-right">
                                                            <Button onClick={(e)=>
                                                                onClick_Schedule(e)}>{display.buttom.schedule}</Button>
                                                            </Col>
                                                        </Row>
                                                    </fieldset>
                                                </Col>
                                                <Col sm="5">
                                                    <fieldset>
                                                        <legend>Histórico de Serviços</legend>
                                                        <div className={styles.vesselSchedules}>
                                                            {props.schedules && props.schedules.map((schedule: any, idx: any) => (
                                                                <div key={idx} className={styles.vesselSchedule}>
                                                                    <div className={styles.vesselScheduleHeader}>
                                                                        <div>{schedule.title}</div>
                                                                        <div className={styles.vesselDate}>
                                                                            {dateToString(schedule.prohibited, 'dd/mm/yyyy')}
                                                                        </div>
                                                                    </div>
                                                                    <div>{schedule.comments}</div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </fieldset>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Tab>
                                    <Tab eventKey="equipments" title="Equipamentos e Acessórios" style={{ height: 500}}>
                                        <Container fluid className={`container-min p-0 ${styles.container}`}>
                                            <TabsMaterial
                                                className={styles.tabs}
                                                value={activeTab}
                                                onChange={handleChange}
                                                indicatorColor="primary"
                                                textColor="primary"
                                                variant="fullWidth"
                                            >
                                                {equipmentGroups.map((group: any, index: number) => (
                                                    <TabMaterial
                                                        label={group.name}
                                                        {...aProps(index)}
                                                        key={uuid()}
                                                    />
                                                ))}
                                            </TabsMaterial>
                                            <ThemeProvider theme={theme}>
                                                {equipmentGroups.map((group: any, indexGroup: number) => (
                                                    <TabPanel value={activeTab} index={indexGroup} dir={theme.direction}>
                                                        <div className='d-flex justify-content-between gap-4'>
                                                            {(group.id === 1) ? (
                                                                renderGroup(mandatoryGroup)
                                                            ) : (group.id === 2) ? (
                                                                renderGroup(additionalSalvageGroup)
                                                            ) : (group.id === 3) ? (
                                                                renderGroup(accessoriesGroup)
                                                            ) : (
                                                                <>
                                                                <div className='d-flex flex-column justify-content-between'>
                                                                    <div className='d-flex justify-content-between align-items-baseline gap-4'>
                                                                        <TextField autoFocus className={styles.input} id="standard-basic" color='secondary' label="Descrição" variant="standard" size='small' value={otherItem.description} onChange={(e) => setOtherItem({...otherItem, description: e.target.value})}/>
                                                                        <TextField sx={{maxWidth: 100}} inputProps={{maxLength: 3}} className={styles.input} id="standard-basic" color='secondary' label="Quantidade" variant="standard" size='small' value={otherItem.quantity} onChange={(e) => setOtherItem({...otherItem, quantity: Number(e.target.value)})}/>
                                                                        <TextField className={styles.input} id="standard-basic" color='secondary' label="Marca" variant="standard" size='small' value={otherItem.brand} onChange={(e) => setOtherItem({...otherItem, brand: e.target.value})}/>
                                                                        <TextField className={styles.input} id="standard-basic" color='secondary' label="Modelo" variant="standard" size='small' value={otherItem.model} onChange={(e) => setOtherItem({...otherItem, model: e.target.value})}/>
                                                                        <FormControlMaterial
                                                                            variant="outlined"
                                                                            sx={{ minWidth: 200, maxWidth: 200 }}
                                                                            fullWidth
                                                                        >
                                                                            <InputLabel style={{color: '#FFFFFF'}} id="origem">Origem *</InputLabel>
                                                                            <Select
                                                                                className={styles.input}
                                                                                labelId="Origem"
                                                                                id="Origem-select"
                                                                                variant='standard'
                                                                                color='secondary'
                                                                                value={otherItem.origin?.toLowerCase() || ''}
                                                                                onChange={e =>
                                                                                    setOtherItem({
                                                                                        ...otherItem,
                                                                                        origin: e.target.value
                                                                                    })
                                                                                }
                                                                                label="Origem *"
                                                                                MenuProps={MenuProps}
                                                                            >
                                                                                {originsOptions?.map(
                                                                                (
                                                                                    option: any,
                                                                                    index: {
                                                                                        toString: () => React.Key | null | undefined
                                                                                    }
                                                                                ) => (
                                                                                    <MenuItem
                                                                                    selected={
                                                                                        otherItem?.origin?.toLowerCase() ===
                                                                                        option.value
                                                                                    }
                                                                                    key={index.toString()}
                                                                                    value={option.value}
                                                                                    >
                                                                                    {option.label}
                                                                                    </MenuItem>
                                                                                )
                                                                                )}
                                                                            </Select>
                                                                        </FormControlMaterial>
                                                                        <TextField sx={{maxWidth: 120}} 
                                                                            InputProps={{
                                                                                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                                                                            }} 
                                                                            className={styles.input} id="standard-basic" color='secondary' label="Valor Aprox." variant="standard" size='small' 
                                                                            value={otherItem.amount} 
                                                                            onChange={(e) => setOtherItem({...otherItem, amount: e.target.value })}
                                                                        />
                                                                        {(!editMode || otherGroup.length === 0) && (
                                                                            <div className='d-flex'>
                                                                                <TooltipMaterial title="Limpar dados" >
                                                                                    <span>
                                                                                        <IconButton
                                                                                            aria-label="clear"
                                                                                            onClick={() => setOtherItem({
                                                                                                id: uuid(),
                                                                                                group : 4,
                                                                                                name : 'Outros',
                                                                                                category : '',
                                                                                                description : '',
                                                                                                brand : '',
                                                                                                model : '',
                                                                                                origin : '',
                                                                                                checked: false,
                                                                                                unity : '',
                                                                                                quantity : 1,
                                                                                                amount : '',
                                                                                                order : 0
                                                                                            })}
                                                                                        >
                                                                                            <ClearIcon sx={{fontSize: 30}} color="error" />
                                                                                        </IconButton>
                                                                                    </span>
                                                                                </TooltipMaterial>
                                                                                <TooltipMaterial
                                                                                    title={
                                                                                        !otherItem.amount || 
                                                                                        !otherItem.brand || 
                                                                                        !otherItem.model || 
                                                                                        !otherItem.origin || 
                                                                                        !otherItem.quantity || 
                                                                                        otherItem.amount === '' || 
                                                                                        otherItem.brand === '' || 
                                                                                        otherItem.model === '' || 
                                                                                        otherItem.origin === '' || 
                                                                                        otherItem.quantity === 0 
                                                                                    ? 'Preencha todos os dados para adicionar um novo item' : 'Adicionar item'}
                                                                                >
                                                                                    <span>
                                                                                        <IconButton
                                                                                        onClick={handleOtherCheckListItems}
                                                                                        aria-label="add"
                                                                                        disabled={
                                                                                            !otherItem.amount || 
                                                                                            !otherItem.brand || 
                                                                                            !otherItem.model || 
                                                                                            !otherItem.origin || 
                                                                                            !otherItem.quantity || 
                                                                                            otherItem.amount === '' || 
                                                                                            otherItem.brand === '' || 
                                                                                            otherItem.model === '' || 
                                                                                            otherItem.origin === '' || 
                                                                                            otherItem.quantity === 0
                                                                                        }>
                                                                                            <AddIcon sx={{fontSize: 30}} color="primary" />
                                                                                        </IconButton>
                                                                                    </span>
                                                                                </TooltipMaterial>
                                                                            </div>
                                                                        )}
                                                                        {editMode && otherGroup.length > 0 && (
                                                                            <div className='d-flex'>
                                                                                <TooltipMaterial title="Descartar alterações" >
                                                                                    <span>
                                                                                        <IconButton
                                                                                            aria-label="clear"
                                                                                            onClick={undo}
                                                                                        >
                                                                                            <ClearIcon sx={{fontSize: 30}} color="error" />
                                                                                        </IconButton>
                                                                                    </span>
                                                                                </TooltipMaterial>
                                                                                <TooltipMaterial
                                                                                    title={!otherItem.description || !otherItem.amount || !otherItem.brand || !otherItem.model || !otherItem.origin || !otherItem.quantity || otherItem.amount === '' || otherItem.brand === '' || otherItem.model === '' || otherItem.origin === '' || otherItem.quantity === 0 || otherItem.description === '' ? 'Preencha todos os dados para adicionar um novo item' : 'Atualizar item'}
                                                                                >
                                                                                    <span>
                                                                                        <IconButton
                                                                                            onClick={updateOtherItem}
                                                                                            aria-label="add"
                                                                                            disabled={!otherItem.description || !otherItem.amount || !otherItem.brand || !otherItem.model || !otherItem.origin || !otherItem.quantity || otherItem.amount === '' || otherItem.brand === '' || otherItem.model === '' || otherItem.origin === '' || otherItem.quantity === 0 || otherItem.description === ''}
                                                                                        >
                                                                                            <Check sx={{fontSize: 30}} color="success" />
                                                                                        </IconButton>
                                                                                    </span>
                                                                                </TooltipMaterial>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    {(otherGroup.length > 0) && (
                                                                        <div style={{ height: 300, minHeight: 300, width: '100%', marginTop: 5, paddingTop: 15, borderTop: 'solid 1px #FFFFFF', overflowY: 'scroll' }}>
                                                                            <DataGrid
                                                                                rows={otherGroup}
                                                                                rowCount={otherGroup.length}
                                                                                getRowId={row => row.id}
                                                                                columns={columns}
                                                                                pageSize={pageSize}
                                                                                onPageSizeChange={newPage => setPageSize(newPage)}
                                                                                rowsPerPageOptions={[10, 25, 50, 75, 100]}
                                                                                disableSelectionOnClick
                                                                                onSelectionModelChange={(newSelectionModel: any) => {
                                                                                    setSelectionModel(newSelectionModel)
                                                                                }}
                                                                                components={{
                                                                                    LoadingOverlay: LinearProgress
                                                                                }}
                                                                                selectionModel={selectionModel}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                </>
                                                            )}
                                                        </div>
                                                    </TabPanel>
                                                ))}
                                            </ThemeProvider>
                                        </Container>
                                    </Tab>
                                    <Tab eventKey="cleaning" title="Limpeza e Higienização" style={{ height: 500}}>
                                        <Container fluid className={`container-min p-0 ${styles.cleaning}`}>
                                            <Row>
                                                <Col sm="8">
                                                    <section className='d-flex justify-content-between align-items-baseline' style={{marginTop: '-9px'}}>
                                                        <div className='d-flex flex-column mb-2'>
                                                            <h6>Limpeza</h6>
                                                            <h5>{props.resumeMaintenance.cleanCount}</h5>
                                                        </div>
                                                        <div className='d-flex flex-column'>
                                                            <h6>Higienização</h6>
                                                            <h5>{props.resumeMaintenance.sanitizeCount}</h5>
                                                        </div>
                                                        <div className='d-flex flex-column'>
                                                            <h6>Adoçamento</h6>
                                                            <h5>{props.resumeMaintenance.sweetenCount}</h5>
                                                        </div>
                                                        <div className='d-flex flex-column'>
                                                            <h6>Polimento</h6>
                                                            <h5>{props.resumeMaintenance.polishCount}</h5>
                                                        </div>
                                                        <LocalizationProvider adapterLocale={ptBR} dateAdapter={AdapterDateFns}>
                                                            <DesktopDatePicker label="Data da Operação" sx={{ width: 250, fontSize: 42 }} slotProps={{
                                                                    textField: {
                                                                    variant: 'standard',
                                                                    helperText: 'Exibindo 30 dias',
                                                                    }
                                                                }}
                                                                value={new Date()}
                                                                className="mt-2 me-3"
                                                            />
                                                        </LocalizationProvider>
                                                    </section>
                                                    <ThemeProvider theme={theme}>
                                                        <div style={{ height: 402, width: '100%', marginTop: 0 }}>
                                                            <DataGrid
                                                                rows={props.maintenances}
                                                                rowCount={props.maintenances.length}
                                                                getRowId={row => row.id}
                                                                // loading={isLoading}
                                                                columns={columnsMaintenance}
                                                                pageSize={pageSize}
                                                                onPageSizeChange={newPage => setPageSize(newPage)}
                                                                rowsPerPageOptions={[10, 25, 50, 75, 100]}
                                                                disableSelectionOnClick
                                                                // onCellClick={(e) => console.log(e)}
                                                                onSelectionModelChange={(newSelectionModel: any) => {
                                                                    setSelectionModel(newSelectionModel)
                                                                }}
                                                                components={{
                                                                    Toolbar: GridToolbar,
                                                                    LoadingOverlay: LinearProgress
                                                                }}
                                                                selectionModel={selectionModel}
                                                            />
                                                        </div>
                                                    </ThemeProvider>
                                                </Col>
                                                <Col sm="4">
                                                    <div className="d-flex flex-column align-items-center" style={{ maxHeight: '450px'}}>
                                                        <h4 className='mb-3'>Operações</h4>
                                                        <Doughnut data={operationsType} options={typeOptions} />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Tab>
                                    <Tab eventKey="fueling" title="Abastecimentos" style={{ height: 500}}>
                                        <Container fluid className={`container-min p-0 ${styles.cleaning}`}>
                                            <Row>
                                                <Col sm="6">
                                                    <section className='d-flex justify-content-between align-items-baseline' style={{marginTop: '-9px'}}>
                                                        <div className='d-flex flex-column mb-2'>
                                                            <h6>Quantidade</h6>
                                                            <h5>{props.resumeSupply.hoursCount}</h5>
                                                        </div>
                                                        <div className='d-flex flex-column'>
                                                            <h6>Litros</h6>
                                                            <h5>{props.resumeSupply.litersCount}</h5>
                                                        </div>
                                                        <div className='d-flex flex-column'>
                                                            <h6>Valor</h6>
                                                            <h5>R$ {props.resumeSupply.amountCount}</h5>
                                                        </div>
                                                        <LocalizationProvider adapterLocale={ptBR} dateAdapter={AdapterDateFns}>
                                                            <DesktopDatePicker label="Data da Operação" sx={{ width: 250, fontSize: 42 }} slotProps={{
                                                                    textField: {
                                                                    variant: 'standard',
                                                                    helperText: 'Exibindo 30 dias',
                                                                    }
                                                                }}
                                                                value={new Date()}
                                                                className="mt-2 me-3"
                                                            />
                                                        </LocalizationProvider>
                                                    </section>
                                                    <ThemeProvider theme={theme}>
                                                        <div style={{ height: 402, width: '100%', marginTop: 0 }}>
                                                            <DataGrid
                                                                autoHeight
                                                                rows={props.supplies}
                                                                rowCount={props.supplies.length}
                                                                getRowId={row => row.id}
                                                                // loading={isLoading}
                                                                columns={columnsSupplies}
                                                                pageSize={pageSize}
                                                                onPageSizeChange={newPage => setPageSize(newPage)}
                                                                rowsPerPageOptions={[10, 25, 50, 75, 100]}
                                                                disableSelectionOnClick
                                                                // onCellClick={(e) => console.log(e)}
                                                                onSelectionModelChange={(newSelectionModel: any) => {
                                                                    setSelectionModel(newSelectionModel)
                                                                }}
                                                                components={{
                                                                    Toolbar: GridToolbar,
                                                                    LoadingOverlay: LinearProgress
                                                                }}
                                                                selectionModel={selectionModel}
                                                            />
                                                        </div>
                                                    </ThemeProvider>
                                                </Col>
                                                <Col sm="6" className='mt-5'>
                                                    <Row className='mt-5'>
                                                        <Col md='6'>
                                                            <div className="d-flex flex-column align-items-center">
                                                                <h4 className='mb-3'>Tipo</h4>
                                                                <Doughnut data={fuelsType} options={typeOptions2} />
                                                            </div>
                                                        </Col>
                                                        <Col md='6'>
                                                            <div className="d-flex flex-column align-items-center">
                                                                <h4 className='mb-3'>Quantidade (Litros)</h4>
                                                                <Doughnut data={amountType} options={typeOptionsAmount} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Tab>
                                </Tabs>
                            </Container>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }

    return (
        <>
            {viewVesselCadaster()}
        </>
    );

}

export default VesselRecord;