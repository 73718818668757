import React from "react";
import styles from './StripBox.module.scss';
import { Image } from "react-bootstrap";

import classNames from 'classnames'

export type DataType = {
    image: string, 
    value: string
}

export interface IStripCardProps {
    label: string, 
    datas?: DataType[],
    image?: string,
    value?: string,
    rotating?: boolean
}

export const StripCard: React.FC<IStripCardProps & React.HTMLProps<HTMLDivElement>> = ({...props}) => {
    
    let manyClass = classNames([styles.stripCard, 
        props.className ? 
            props.className === 'ship' ? styles.ship : 
            props.className === 'total' ? styles.total : 
            props.className === 'received' ? styles.received : 
            props.className === 'delay' ? styles.delay : 
            props.className === 'opened' ? styles.opened : props.className
        : null
    ]);

    return <div className={manyClass}>
        <label className={styles.stripLabel}>{props.label}</label>
        <div className={styles.stripContent}>
            {props.datas ? (
                props.datas.map((dataType: DataType, index: number) => (
                    <div key={`strip-group-${index}`} className={styles.stripGroup}>
                        <Image src={dataType.image} className={styles.stripImage} />
                        <div className={styles.stripValue}>{dataType.value}</div>
                    </div>
                ))
            ) : (
                <>
                    {props.image && 
                    <div className={`${props.rotating ? 'rotating': ''}`} >
                        <Image src={props.image} className={styles.stripImage} />
                    </div>}
                    <div className={styles.stripValue}>{props.value}</div>
                </>   
            )}
        </div>
    </div>;
}

export interface IStripBoxProps {}
export const StripBox: React.FC<IStripBoxProps & React.HTMLProps<HTMLDivElement>> = ({children, ...props}) => {

    return <div className={styles.stripBox} {...props}>
        {children}
    </div>;
} 