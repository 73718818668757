import React, { Component, useContext } from 'react';

import languageEN from '../assets/languages/en.json';
import languagePtBr from '../assets/languages/pt-br.json';

// Create new context
export const TranslateContext = React.createContext({
    translator: {} as any,
    display: {} as any,
    changeLanguage: {} as any
});

export const useTranslateContext = () => {
    return useContext(TranslateContext);
};

// Then create a Provider Component
export class TranslateProvider extends Component<any, any> {
    
    state = {
        language: 'pt-br',
        display: languagePtBr
    }

    constructor(props: any) {
        super(props);

        let locaDisplay = {} as any;
        let localLanguage = localStorage.getItem('language');
        if (!localLanguage)
            localLanguage = 'pt-br';

        if (localLanguage === 'pt-br') {
            locaDisplay = languagePtBr;
        } else {
            locaDisplay = languageEN;
        }

        this.state = {
            language : localLanguage,
            display : locaDisplay
        }
    }

    changeLanguage = (event: any) => {
        let selected = event.target.value;
        let data = {};

        if (selected === 'en') {
            data = languageEN;
        } else {
            data = languagePtBr;
        }

        localStorage.setItem("language", selected)
        this.setState({
            language: selected,
            display: data
        })
    }

    render() {
        return (
            <TranslateContext.Provider value={{
                translator: this.state,
                display: this.state.display,
                changeLanguage: this.changeLanguage
            }}>
                {this.props.children}
            </TranslateContext.Provider>
        )
    }
}
