import WarehouseIcon from '@mui/icons-material/Warehouse';
import { useEffect, useState } from "react";
import { ButtonGroup, Card, Col, Container, Image, Row, Spinner } from "react-bootstrap";
import { FaEdit, FaEye, FaTrash } from "react-icons/fa";
import Lancha from '../../../assets/Lancha.svg';
import { useNavigate, useSearchParams } from "react-router-dom";
import Jet from '../../../assets/Jetski.svg';
import { VesselOwner } from "../../../components/sub-render.component";
import { useAlertMessageContext } from "../../../contexts/alert-message.context";
import { useDeleteMessageContext } from "../../../contexts/delete-message.context";
import authService from "../../../services/auth.service";
import lclService from "../../../services/lcl.service";
import { PermissionEnum, VESSEL_LANCHA } from "../../../utilities/constants";
import { AlertMessageEnum } from "../../../utilities/types";
import VesselImporterModal from "../../modals/vessel-importer.modal";
import { Button, FormControl, FormHelperText, Input, InputAdornment, InputLabel, LinearProgress } from '@mui/material';
import { DataGrid, GridColDef, GridSelectionModel, GridToolbar } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import styles from './vessel.cadastre.list.module.scss';
import { Tooltip as TooltipMaterial } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import Tooltip from 'react-bootstrap/Tooltip';
import theme from "../../../theme";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { toInt } from '../../../utilities/auxiliary-functions';
import { TPagination } from '../../../types/PaginationType';
import { useTranslateContext } from '../../../contexts/translate.context';
import { isEmpty } from '../../../utilities/validators';
import { usePartnerContext } from '../../../contexts/partner.context';

export interface IVesselCadastreListProps {
    title: string,
    setRecord: any,
    viewOnly?: boolean
}

export const VesselCadastreList: React.FC<IVesselCadastreListProps> = (props: IVesselCadastreListProps) => {
    
    const navigator = useNavigate();

    const alertContext = useAlertMessageContext();
    const deleteContext = useDeleteMessageContext();
    const { display } = useTranslateContext();
    const { partner } = usePartnerContext();

    let reloadPage = false;
    const [ isLoading, setLoading ] = useState(false);
    const [ textSearch, setTextSearch ] = useState('');
    const [ showFilter, setShowFilter ]  = useState(false);
    const [ sending, setSending ] = useState(false);
    const [ selectionModel, setSelectionModel ] = useState<GridSelectionModel>([]);

    let noPaged = true;
    const [ searchParams, setSearchParams ] = useSearchParams();

    const [ pageSize, setPageSize ] = useState(10);
    const [ page, setPage ] = useState(0);
    const [ pageFilled, setPageFilled ] = useState<number[]>([]);

    const [ recordCount, setRecordCount ] = useState(0);
    const [ records, setRecords ] = useState([]);
    const [ originalRecords, setOriginalRecords ] = useState([]);

    const [ showImported, setShowImported ]  = useState(false);

    const [ sumShared, setSumShared ]  = useState(0);
    const [ sumQuantity, setSumQuantity ]  = useState(0);
    const [ sumQuotas, setSumQuotas ]  = useState(0);

    const columns: GridColDef[] = [
        { field: 'identifyKey', headerName: 'Nome | ID', flex: 0.5, align: 'center', headerAlign: 'center', renderCell: renderNameAndId },
        { field: 'color', headerName: 'TIPO | COR', flex: 0.5, align: 'center', headerAlign: 'center', renderCell: renderNormAndColor },
        { field: 'enrollment', headerName: display.label.enrollment, flex: 0.5, align: 'center', headerAlign: 'center' },
        { field: 'bookcase', headerName: display.label.drawer, flex: 0.5, align: 'center', headerAlign: 'center', renderCell: renderBookcase },
        { field: 'manufacturer', headerName: 'MAR | MOD', flex: 0.5, align: 'center', headerAlign: 'center', renderCell: renderBrandAndModel },
        { field: 'responsibles', headerName: 'Propriedade', flex: 0.5, align: 'center', headerAlign: 'center', renderCell: renderOwner },
        { field: 'shared', headerName: 'Compartilhado', flex: 0.5, align: 'center', headerAlign: 'center', renderCell: renderShared },
        { field: 'quotas', headerName: 'Cotas Disp.', flex: 0.5, align: 'center', headerAlign: 'center', renderCell: renderQuotas },
        {
            field: 'action',
            headerName: 'Ações',
            headerAlign: 'center',
            renderCell: renderButtons,
            disableExport: true,
        }
    ];

    function renderButtons(param: any) {
        const vesselRow = param.row;
        return (
            <div className="d-flex w-100 justify-content-center">
                {props.viewOnly ? (
                    <>{authService.hasPermission(PermissionEnum.CADASTRE_VESSEL_VIEW) && 
                        <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.toView}</Tooltip>}>
                            <span onClick={(e) => onClick_ViewRecord(e, vesselRow)}>
                                <FaEye size={18} />
                            </span>
                        </OverlayTrigger>
                    }</>
                ) : (
                    <>
                    {authService.hasPermission(PermissionEnum.CADASTRE_VESSEL_EDIT) && 
                        <TooltipMaterial className="isClickable" title="Editar Embarcação">
                            <span className="me-3"  onClick={(e) => onClick_EditRecord(e, vesselRow)}>
                                <FaEdit size={18} />
                            </span>
                        </TooltipMaterial>
                    }
                    {authService.hasPermission(PermissionEnum.CADASTRE_VESSEL_REMOVE) && 
                        <TooltipMaterial className="isClickable" title="Excluir Embarcação">
                            <span onClick={(e) => onClick_DeleteRecord(e, records, vesselRow)}>
                                <FaTrash size={18} />
                            </span>
                        </TooltipMaterial>
                    }
                    </>
                )}
            </div>
        );
    }

    function renderNameAndId(param: any) {
        const vessel = param.row;
        return (
            <div className="d-flex flex-column align-items-center justify-content-center">
                <div className="white-space mt-2">{(vessel.name) ? vessel.name : '-'}</div>
                <div className="white-space mt-1">{vessel.identifyKey}</div>
            </div>
        );
    }

    function renderNormAndColor(param: any) {
        const vessel = param.row;
        return (
            toInt(vessel.typeVessel) === VESSEL_LANCHA ? (
                <div className="d-flex flex-column align-items-center justify-content-center mb-2">
                    <div className="mt-2 d-flex justify-content-center mb-2">
                        <Image src={Lancha} className="jet" style={{height: 32}}/>
                        <small className="ms-2">{`${(vessel.vesselSize) ? vessel.vesselSize : '-'}'`}</small>
                    </div>
                    <span className={styles.vesselColor} style={{backgroundColor: `${vessel.color}`}}></span>
                </div>
            ):(
                <div className="d-flex flex-column align-items-center justify-content-center mb-2">
                    <div className="d-flex justify-content-center mb-2">
                        <Image src={Jet} className="jet mt-3" style={{height: 32}}/>
                        <small className="ms-1 mt-3">{`10'`}</small>
                    </div>
                    <span className={styles.vesselColor} style={{backgroundColor: `${vessel.color}`}}></span>
                </div>
            )
        );
    }

    function renderBookcase(param: any) {
        const vessel = param.row;

        const bookcaseName = vessel.bookcase.name;
        const shelfName = vessel.bookcase.shelves ? vessel.bookcase?.shelves[0]?.name : '';
        const drawerName = vessel.bookcase.shelves ? vessel.bookcase.shelves[0]?.drawers[0]?.name : '';

        return (
            <TooltipMaterial className="isClickable d-flex text-center justify-content-center w-100" title={`${bookcaseName} | ${shelfName} | ${drawerName}`}>
                <WarehouseIcon color="inherit" className="mt-1" />
            </TooltipMaterial>
        );
    }

    function renderBrandAndModel(param: any) {
        const vessel = param.row;
        return (
            <div className="d-flex flex-column align-items-center justify-content-center">
                <div className="white-space mt-2">{vessel.brand}</div>
                <div className="white-space mt-1">{`${(vessel.model) ? vessel.model : '-'}`}</div>
            </div>
        );
    }

    function renderOwner(param: any) {
        const vessel = param.row;
        return <VesselOwner values={vessel} />
    }

    function renderShared(param: any) {
        const vessel = param.row;
        return <div className="white-space mt-1">{`${(vessel.shared) ? 'Sim': 'Não'}`}</div>
    }

    function renderQuotas(param: any) {
        const vessel = param.row;
        return <div className="white-space mt-1">{`${(vessel.shared) ? vessel.quotasAvailable : '-'}`}</div>
    }
    
    function onClick_Filter(event: any) {
        event.preventDefault();
        
        setSending(false);
        setShowFilter(false);
        searchFilter();
    } // onClick_Filter

    function onClick_AddRecord(event: any) {
        event.preventDefault();

        setShowFilter(false);
        setSending(true);
        props.setRecord(event, 'new');
    } // onClick_AddRecord


    function onClick_ViewRecord(event: any, record: any) {
        event.preventDefault();
        props.setRecord(event, record.id);
    } // onClick_ViewRecord

    function onClick_EditRecord(event: any, record: any) {
        event.preventDefault();
        props.setRecord(event, record.id);
    } // onClick_EditRecord

    async function onClick_DeleteRecord(event: any, records: any, record: any) {
        event.preventDefault();
        const isConfirmed = await deleteContext.show(props.title, display.message.delete_record, true);
        if (isConfirmed && isConfirmed.result) {
            try {
                await lclService.vesselDeleteById(record.id, isConfirmed.message);
                searchFilter();
            } catch(error: any) {
                await alertContext.show(AlertMessageEnum.FAIL, props.title, error);
            }
        }
    } // onClick_DeleteRecord

    async function searchFilter() {
        setLoading(true);

        /* Sempre incluir o partner na pesquisa */
        if (!isEmpty(partner)) {
            try {
                let localPageSize = pageSize;
                let localPage = page;

                if (noPaged) {
                    if (searchParams.has('limit')) {
                        let paramPageSize = searchParams.get('limit');
                        if (paramPageSize) {
                            // eslint-disable-next-line react-hooks/exhaustive-deps
                            localPageSize = Number.parseInt(paramPageSize);
                            setPageSize(localPageSize);
                        }
                    }
                    if (searchParams.has('offset')) {
                        let paramPage = searchParams.get('offset');
                        if (paramPage) {
                            // eslint-disable-next-line react-hooks/exhaustive-deps
                            localPage = Number.parseInt(paramPage);
                            setPage(localPage);
                        }
                    }
                }

                if (pageFilled.indexOf(localPage) === -1) {
                    const pagination = {
                        limit: localPageSize, 
                        offset: localPage * localPageSize
                    } as TPagination;
                    
                    const vesselList = await lclService.vesselList(partner.id, pagination);
                    setRecordCount(vesselList.count);
                    const array = [] as any;
                    records.forEach((record: any) => array.push(record));
                    vesselList.rows.forEach((record: any) => array.push(record));
                    setRecords(array);
                    setOriginalRecords(array);
                    setSending(false);


                    let sumShared = array.reduce((accumulator: number, vessel: any) => {
                        return accumulator + (vessel.shared ? 1 : 0);
                    }, 0);
                    setSumShared(sumShared);

                    let sumQuantity = array.reduce((accumulator: number, vessel: any) => {
                        return accumulator + (vessel.shared ? vessel.quotasQuantity : 0);
                    }, 0);
                    setSumQuantity(sumQuantity);

                    let sumQuotas = array.reduce((accumulator: number, vessel: any) => {
                        return accumulator + (vessel.shared ? vessel.quotasAvailable : 0);
                    }, 0);
                    setSumQuotas(sumQuotas);



                    if (array.length > 0)
                        pageFilled.push(localPage);
                }
            } catch(error: any) {
                await alertContext.show(AlertMessageEnum.FAIL, props.title, error);
            } finally {
                setLoading(false);
                setShowFilter(false);
            }
        } else {
            await alertContext.show(AlertMessageEnum.FAIL, props.title, 'Não foi definido uma Marina para exibir o cadastro !');
            setLoading(false);
            navigator(`/portal`);
        }
    } // searchfilter
    
    const onChangePageSize = (newPageSize: number) => {
        setSearchParams({...searchParams, 'limit': `${newPageSize}`, 'offset': `${0}`});

        setRecords([]);
        setOriginalRecords([]);
        setRecordCount(0);
        setPageFilled([]);
        setPageSize(newPageSize);
        setPage(0);
    }
    
    const onChangePage = (newPage: number) => {
        setSearchParams({...searchParams, 'offset': `${newPage}`});

        setPage(newPage);
    }

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        noPaged = false;
        let paramPage = searchParams.get('offset');
        if (paramPage) {
            searchFilter();
        }
    }, [pageSize, page]); // useEffect

    useEffect(() => {
        if (!isLoading && !reloadPage) {
            noPaged = true;
            searchFilter();
        }

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            reloadPage = !reloadPage;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // useEffect

    const filtered = (key?: string, value?: string) => {
        const filtered: any = []
        records.filter((record: any) => {
        if (
            record.identifyKey
                .toLocaleLowerCase()
                .includes(textSearch.toLocaleLowerCase()) ||
            record.name
                .toLocaleLowerCase()
                .includes(textSearch.toLocaleLowerCase()) ||
            record.enrollment
                .toLocaleLowerCase()
                .includes(textSearch.toLocaleLowerCase())
        )
            filtered.push(record);
            return record;
        });
        setRecords(filtered);
        return '';
    }

    useEffect(() => {
        if (textSearch.length >= 3) {
            filtered();
        } else if (textSearch.length < 3) {
            setRecords(originalRecords);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [textSearch]);

    function viewCadastreList() {
        return (
            <Container fluid className="page-body vessel">
                <Row>
                    <Col md={12} className="page-sweet">
                        <Card className={styles.vesselList}>
                            <Card.Header>
                                <div className="card-actions float-right">
                                    {!props.viewOnly && <ButtonGroup>
                                        {authService.hasPermission(PermissionEnum.CADASTRE_VESSEL_ADD) && 
                                            <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.register} {display.tooltips.member}</Tooltip>}>
                                                <Button size="small" onClick={ onClick_AddRecord } disabled={sending} variant="contained" color="primary">
                                                    { sending ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <AddIcon /> }
                                                    Embarcação
                                                </Button>
                                            </OverlayTrigger>
                                        }
                                    </ButtonGroup>}
                                </div>
                                <Card.Title>{display.legend.listing}</Card.Title>
                            </Card.Header>
                            <Card.Body style={{ minHeight : 500 }}>
                                <div className={`card-filter ${showFilter ? "active" : ""}`} >
                                    <div className="card-filter-header">{display.legend.filter}</div>
                                    <div className="card-filter-body">
                                        <Container>
                                            <Row>
                                                <Col>{display.legend.filter_scheme}</Col>
                                            </Row>
                                        </Container>
                                    </div>
                                    <div className="card-filter-footer">
                                        <Button onClick={ onClick_Filter } disabled={sending} variant="contained" color="primary">
                                            { sending ? <Spinner className="me-2" as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null }
                                            {display.buttom.filter}
                                        </Button>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <FormControl fullWidth sx={{width: 300, marginBottom: 2 }} variant="standard">
                                        <InputLabel className={styles.label} htmlFor="standard-adornment-amount">Pesquisar</InputLabel>
                                        <Input id="standard-adornment-amount"
                                            value={textSearch}
                                            onChange={(e) => setTextSearch(e.target.value)}
                                            endAdornment = {
                                                <InputAdornment position="end">
                                                    <SearchIcon className='isClickable' />
                                                </InputAdornment>
                                            }
                                        />
                                        <FormHelperText id="component-helper-text">
                                            ID, Nome ou Registro da Embarcação
                                        </FormHelperText>
                                    </FormControl>
                                    <div className='d-flex mt-2' style={{color: '#FFFFFF'}}>
                                        <div className="d-flex flex-column align-items-center me-5">
                                            <h5>COMPARTILHADAS</h5>
                                            <h5>{sumShared}</h5>
                                        </div>
                                        <div className="d-flex flex-column align-items-center ms-3 me-5">
                                            <h5>TOTAL COTAS</h5>
                                            <h5>{sumQuantity}</h5>
                                        </div>
                                        <div className="d-flex flex-column align-items-center ms-3">
                                            <h5>COTAS DISPONÍVEIS</h5>
                                            <h5>{sumQuotas}</h5>
                                        </div>
                                    </div>
                                </div>
                                <ThemeProvider theme={theme}>
                                    <div style={{ height: 402, width: '100%', marginTop: 0 }}>
                                        <DataGrid
                                            rows={records}
                                            rowCount={recordCount}
                                            getRowId={row => row.id}
                                            loading={isLoading}
                                            columns={columns}
                                            pageSize={pageSize}
                                            onPageSizeChange={newPageSize => onChangePageSize(newPageSize)}
                                            page={page}
                                            onPageChange={newPage => onChangePage(newPage) }
                                            
                                            rowsPerPageOptions={[10, 25, 50, 75, 100]}
                                            disableSelectionOnClick
                                            // onCellClick={(e) => console.log(e)}
                                            onSelectionModelChange={(newSelectionModel: any) => {
                                                setSelectionModel(newSelectionModel)
                                            }}
                                            components={{
                                                Toolbar: GridToolbar,
                                                LoadingOverlay: LinearProgress
                                            }}
                                            selectionModel={selectionModel}
                                        />
                                    </div>
                                </ThemeProvider>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    } // viewCadastreList

    return (
        <>
            { viewCadastreList() }
            <VesselImporterModal 
                show={showImported}
                onSubmitModal={(event: any, record: any) => {
                    event.preventDefault();
                    setShowImported(false);
                }}
                onCancelModal={(event: any) => {
                    event.preventDefault();
                    setShowImported(false);
                }}
            />

        </>
    );

}

export default VesselCadastreList;
