import { useEffect, useState } from "react";

import { useMediaContext } from "../../../contexts/media.context";
import { Box } from "@mui/material";
import moment from "moment";

import styles from './ClockAndClimate.module.scss';
import { notNullToDate, toInt } from "../../../utilities/auxiliary-functions";
import { FaTint } from "react-icons/fa";
import authService from "../../../services/auth.service";
import cduService from "../../../services/cdu.service";
import climateService from "../../../services/climate.service";
import { useTranslateContext } from "../../../contexts/translate.context";
import { isEmpty } from "../../../utilities/validators";
import { usePartnerContext } from "../../../contexts/partner.context";

export interface IClockAndClimateProps {
    
}

export const ClockAndClimate: React.FC<IClockAndClimateProps> = (props: IClockAndClimateProps) => {

    const { isPortrait, isTv, isMonitor, isTablet, isSmart } = useMediaContext();
    const { display } = useTranslateContext();
    const { partner, showDefinedPartner } = usePartnerContext();

	const [ time, setTime ] = useState(new Date());
    const [ currentDate, setCurrentDate ] = useState(new Date());
	const [ currentHour, setCurrentHour ] = useState(0);
	
	const [ climateDay, setClimateDay ] = useState({} as any);
	const [ climateHour, setClimeHour ] = useState([] as any);
	const [ climateLocation, setClimateLocation ] = useState([] as any);
	const [ climateDescription, setClimateDescription ] = useState("");

    async function handleClimate() {
        const user = authService.currentUser();
        let userAddress = await cduService.addressByUser(user?.id);
        let city = `${userAddress?.address?.city}`
        city = city.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
        if (city) {
            try {
                let responseClimate = await climateService.history(city, notNullToDate(currentDate, "yyyy-mm-dd"));

                const location = responseClimate;
                const climate = responseClimate.forecast.forecastday[0];
                const ClimateForecast = climate.hour;

                let hourCount;
                if (currentDate.getHours() <= 18) {
                    hourCount = currentDate.getHours() + 1;
                } else {
                    hourCount = 19;
                }

                const climehour = ClimateForecast.slice(hourCount, (hourCount + 5));

                let resultaClimateFilter = display.climate.filter((el: any) => el.code === climate.hour[currentDate.getHours()].condition.code);
                let climateDescription;
                if (currentDate.getHours() <= 18) {
                    climateDescription = resultaClimateFilter[0].day;
                } else {
                    climateDescription = resultaClimateFilter[0].night;
                }

                setClimateDescription(climateDescription);

                setClimeHour(climehour);
                setClimateDay(climate);
                setClimateLocation(location);
            } catch (error: any) {}
        }
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            const date = new Date();
            setTime(date);
            setCurrentHour(date.getHours());
        }, 1000);
        
        handleClimate();
        return () => clearInterval(intervalId);
    }, []);

    return (
        <Box className={`d-flex justify-content-between align-items-center gap-4 ${styles.clockBar}`}>
            <Box>
                {partner?.company?.image ? (
                    <img src={partner?.company?.image} alt="Logo Marina" className={styles.clockPartnerLogo} />
                ):(
                    <div className={styles.clockPartnerLogoPlaceholder}>
                        <p>Logo Marina</p>
                    </div>
                )}
            </Box>
            <Box className={`d-flex flex-column ${styles.clockDateTime}`}>
                {(isTv || isMonitor) ? <>
                    <Box><h2 className={`${styles.clockDate}`}>{moment(time).format('DD \\d\\e MMMM \\d\\e YYYY')}</h2></Box>
                    <Box><h1 className={`${styles.clockTime}`}>{time.toLocaleTimeString()}</h1></Box>
                </> : <Box><h1 className={`${styles.clockTime}`}>{time.toLocaleTimeString()}</h1></Box>}
            </Box>
            <Box className="flex-grow-1"></Box>
            <Box>
                <div className={`climate-partials d-flex ${(isTv || isMonitor) ? 'justify-content-between' : 'justify-content-end'} gap-5 ${isPortrait ? 'flex-column': 'flex-row'}`}>
                    <div className="climate-current">
                        {!isEmpty(climateDay) && <div className="climate-current-main">
                            <div className="current-main me-5">
                                <img className="climate-current-icon" src={climateDay.hour[currentHour].condition.icon} alt={climateDay.hour[currentHour].condition.text} />
                                <div>
                                    <div className="climate-current-temperature">
                                        {toInt(climateDay.hour[currentHour].temp_c)}
                                        <span>ºC</span>
                                    </div>
                                    <div className="climate-current-label">
                                        {climateDescription}
                                    </div>
                                </div>
                            </div>
                            <div className="climate-current-information">
                                <span className="climate-current-temperature d-flex flex-column align-items-center me-3">
                                    <strong>Chuva</strong>
                                    <span>
                                        {climateDay.hour[currentHour].chance_of_rain}%
                                    </span>
                                </span>
                                <span className="climate-current-temperature d-flex flex-column align-items-center me-3">
                                    <strong>Umidade</strong>
                                    <span>
                                        {climateDay.hour[currentHour].humidity}%
                                    </span>
                                </span>
                                <span className="climate-current-temperature d-flex flex-column align-items-center">
                                    <strong>Vento</strong>
                                    <span>
                                        {climateDay.hour[currentHour].wind_kph} km/h
                                    </span>
                                </span>
                            </div>
                            <div className="climate-location">
                                <span>
                                    {`${climateLocation.location.name}, ${climateLocation.location.region}`}
                                </span>
                            </div>
                        </div>}
                    </div>
                    {(isTv || isMonitor || (isTablet && !isPortrait)) && <>
                    <div className="climate-hour">
                        {climateHour.map((climate: any, index: number) => (
                            <div key={`climate-hour-${index}`} className="card-hour">
                                <span className="card-hour-label">
                                    {climate.time.substring(11)}
                                </span>
                                <img className="card-hour-icon" src={climate.condition.icon} alt={climate.condition.text} />
                                <div className="card-hour-data">
                                    <div className="card-hour-temperature">
                                        {toInt(climate.temp_c)}
                                        <span>ºC</span>
                                    </div>
                                    <div className="card-hour-rain">
                                        <span><FaTint size={10} /></span>
                                        {climate.chance_of_rain}%
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    </>}
                </div>
            </Box>
        </Box>
    );
}
