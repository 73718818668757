import { useEffect } from "react";
import { Col, Form, FormControl, FormGroup, InputGroup, Row } from "react-bootstrap";
import { BsSliders } from "react-icons/bs";
import ReactDatePicker from "react-datepicker";

import { useTranslateContext } from "../../contexts/translate.context";

import { dateToString, floatToString, toString, toDate, toFloat, toLowerCase } from "../../utilities/auxiliary-functions";
import { CategoryEnum } from "../../utilities/constants";
import { floatMask } from "../../utilities/masks";
import { CategoryType, ExchangeProductType, OriginProductorType, TypeProductType, UnitType, WarrantyProductType } from "../../utilities/types";
import { isEmpty } from "../../utilities/validators";
import { PickerInput } from "../../components/sub-render.component";
import ptBR from 'date-fns/locale/pt-BR';
import ImageLightBox from "../../components/image-lightbox/ImageLightbox";
import { Tooltip as TooltipMaterial } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { CameraAltOutlined } from '@mui/icons-material';
import styles from './product.record.module.scss';

export interface IProductRecordProps {
    title: any,
    isEdit: boolean,
    viewOnly?: boolean,

    code?: any,
    mode?: any,
    barcode?: any,
    unit?: any,
    description?: any,
    usability?: any,
    reference?: any,
    codeNcm?: any,
    grossWeight?: any,
    netWeight?: any,
    category?: any,
    control?: any,
    notify?: any,
    sellZero?: any,
    minimum?: any,
    actual?: any,
    movement?: any,
    purchase?: any,
    margin?: any,
    sale?: any,
    comments?: any,
    manufacturer?: any,
    origin?: any,
    warranty?: any,
    norm?: any,
    exchange?: any,
    image1?: any,
    image2?: any,

    setCode?: any,
    setMode?: any,
    setBarcode?: any,
    setUnit?: any,
    setDescription?: any,
    setUsability?: any,
    setReference?: any,
    setCodeNcm?: any,
    setGrossWeight?: any,
    setNetWeight?: any,
    setCategory?: any,
    setControl?: any,
    setNotify?: any,
    setSellZero?: any,
    setMinimum?: any,
    setActual?: any,
    setMovement?: any,
    setPurchase?: any,
    setMargin?: any,
    setSale?: any,
    setComments?: any,
    setManufacturer?: any,
    setOrigin?: any,
    setWarranty?: any,
    setNorm?: any,
    setExchange?: any,
    setImage1?: any,
    setImage2?: any,

    filterCategory?: any
    changeImage1?: any
    changeImage2?: any
}

export const ProductRecord: React.FC<IProductRecordProps> = (props: IProductRecordProps) => {

    const { display } = useTranslateContext();

    async function initialize() {
    }

    useEffect(() => {
        initialize();
    }, []);
    
    async function onChange_Mode(value: any) {
        props.setMode(value);
        props.setCategory({});
    }

    function onClick_FilterCategory(event: any) {
        event.preventDefault();
        props.filterCategory(event);
    }

    function onBlur_CalculatedSale(event: any) {
        const sale = (toFloat(props.purchase) * toFloat(props.margin)) / 100 + toFloat(props.purchase);
        props.setPurchase(floatToString(props.purchase));
        props.setMargin(floatToString(props.margin));
        props.setSale(floatToString(sale));
    }

    function onChange_Movement(event: any, date: any) {
        event.preventDefault();
        props.setMovement(dateToString(date, 'dd/mm/yyyy'));
    }

    function viewProductCadaster() {
        return (
            <>
            <Row>
                <Col sm="12">
                    <Row>
                        <Col sm="12">
                            <fieldset>
                                <legend>Dados do Produtos</legend>
                                <Row>
                                    <Col sm="2">
                                        <FormGroup>
                                            <Form.Label htmlFor="form-mode">{display.label.group}</Form.Label>
                                            <Form.Select id="form-mode" name="mode" 
                                                value={toString(props.mode)}
                                                onChange={(e) => onChange_Mode(e.target.value)}
                                            >
                                                { CategoryType.map((iterator: any, idx: number) => (
                                                    (iterator.id !== CategoryEnum.VESSEL) ? (
                                                        <option key={idx} value={iterator.id}>{display.label[toLowerCase(iterator.name)]}</option>
                                                    ) : null
                                                )) }
                                            </Form.Select>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="3">
                                        <FormGroup>
                                            <Form.Label htmlFor="form-origin">{display.label.origin}</Form.Label>
                                            <Form.Select id="form-origin" name="origin" 
                                                value={toString(props.origin)}
                                                onChange={(e) => props.setOrigin(e.target.value)}
                                            >
                                                {isEmpty(props.origin) && <option value={''}>...</option>}
                                                { OriginProductorType.map((iterator: any, idx: number) => (<option key={idx} value={iterator.id}>{display.label[toLowerCase(iterator.name)]}</option>)) }
                                            </Form.Select>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="5">
                                        <FormGroup>
                                            <Form.Label htmlFor="form-category">{display.label.category}</Form.Label>
                                            <InputGroup>
                                                <FormControl type="text" id="form-category" name="category" placeholder="Selecione a categoria" required
                                                    value={toString(!isEmpty(props.category) ? (props.category.group +' \\ '+ (isEmpty(props.category.subgroup) ? props.category.name : props.category.subgroup +' \\ '+ props.category.name)) : '')}
                                                />
                                                <InputGroup.Text style={{backgroundColor: '#3366FF', color: 'white', border: 0}}>
                                                    <a href="/#" onClick={(e) => { onClick_FilterCategory(e) }}>
                                                        <BsSliders />
                                                    </a>
                                                </InputGroup.Text>
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="2">
                                        <FormGroup>
                                            <Form.Label htmlFor="form-barcode">{display.label.barcode} (EAN)</Form.Label>
                                            <FormControl type="text" id="form-barcode" name="barcode" placeholder={display.example.barcode} readOnly
                                                value={toString(props.barcode)}
                                                onChange={e => props.setBarcode(e.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="2">
                                        <FormGroup>
                                            <Form.Label htmlFor="form-code">{display.label.code} (Máx. 20 caracteres)</Form.Label>
                                            <FormControl type="text" id="form-code" name="code" placeholder={display.example.code} required
                                                value={toString(props.code)}
                                                maxLength={20}
                                                onChange={e => props.setCode(e.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="3">
                                        <FormGroup>
                                            <Form.Label htmlFor="form-reference">Código Fabricante ({display.label.reference})</Form.Label>
                                            <FormControl type="text" id="form-reference" name="reference" placeholder={display.example.reference}
                                                value={toString(props.reference)}
                                                maxLength={20}
                                                onChange={e => props.setReference(e.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="3">
                                        <FormGroup>
                                            <Form.Label htmlFor="form-code-ncm">NCM</Form.Label>
                                            <FormControl type="text" id="form-code-ncm" name="codeNcm" placeholder={display.example.codeNcm}
                                                value={toString(props.codeNcm)} 
                                                onChange={e => props.setCodeNcm(e.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="1">
                                        <FormGroup>
                                            <Form.Label htmlFor="form-gross-weight">Peso Bruto</Form.Label>
                                            <FormControl type="text" id="form-gross-weight" name="grossWeight" placeholder={display.example.grossWeight}
                                                className="text-right"
                                                value={toString(props.grossWeight)}
                                                onChange={e => props.setGrossWeight(e.target.value)}
                                                onBlur={e=> props.setGrossWeight(floatMask(e.target.value))}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="1">
                                        <FormGroup>
                                            <Form.Label htmlFor="form-net-weight">Peso Líquido</Form.Label>
                                            <FormControl type="text" id="form-net-weight" name="netWeight" placeholder={display.example.netWeight}
                                                className="text-right"
                                                value={toString(props.netWeight)}
                                                onChange={e => props.setNetWeight(e.target.value)}
                                                onBlur={e => props.setNetWeight(floatMask(e.target.value))}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="2">
                                        <FormGroup>
                                            <Form.Label htmlFor="form-unit">{display.label.unit}</Form.Label>
                                            <Form.Select id="form-unit" name="unit" 
                                                value={toString(props.unit)}
                                                onChange={(e) => props.setUnit(e.target.value)}
                                                required
                                            >
                                                {isEmpty(props.unit) && <option value={0}>...</option>}
                                                { UnitType.map((iterator: any, idx: number) => (<option key={idx} value={iterator.id}>{iterator.name} - {iterator.description}</option>)) }
                                            </Form.Select>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>

                                    <Col sm="2">
                                        <FormGroup>
                                            <Form.Label htmlFor="form-manufacturer">{display.label.manufacturer}</Form.Label>
                                            <FormControl type="text" id="form-manufacturer" name="manufacturer" placeholder={display.example.manufacturer}
                                                value={toString(props.manufacturer)}
                                                onChange={e => props.setManufacturer(e.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="3">
                                        <FormGroup>
                                            <Form.Label htmlFor="form-description">{display.label.description} (Máx. 40 caracteres)</Form.Label>
                                            <FormControl type="text" id="form-description" name="description" placeholder={display.example.description}
                                                value={toString(props.description)}
                                                maxLength={40}
                                                onChange={e => props.setDescription(e.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="3">
                                        <FormGroup>
                                            <Form.Label htmlFor="form-type">Tipo</Form.Label>
                                            <Form.Select id="form-type" name="type" 
                                                value={toString(props.norm)}
                                                disabled={props.category.material === 5}
                                                onChange={(e) => props.setNorm(e.target.value)}
                                            >
                                                {isEmpty(props.norm) && <option value={''}>...</option>}
                                                { TypeProductType.map((iterator: any, idx: number) => (<option key={idx} value={iterator.id}>{iterator.description}</option>)) }
                                            </Form.Select>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="2">
                                        <FormGroup>
                                            <Form.Label htmlFor="form-warranty">Garantia</Form.Label>
                                            <Form.Select id="form-warranty" name="warranty" 
                                                disabled={props.category.material === 5}
                                                value={toString(props.warranty)}
                                                onChange={(e) => props.setWarranty(e.target.value)}
                                            >
                                                {isEmpty(props.warranty) && <option value={''}>...</option>}
                                                { WarrantyProductType.map((iterator: any, idx: number) => (<option key={idx} value={iterator.id}>{iterator.name}</option>)) }
                                            </Form.Select>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="2">
                                        <FormGroup>
                                            <Form.Label htmlFor="form-exchange">Troca</Form.Label>
                                            <Form.Select id="form-exchange" name="exchange" 
                                                value={toString(props.exchange)}
                                                onChange={(e) => props.setExchange(e.target.value)}
                                            >
                                                {isEmpty(props.exchange) && <option value={''}>...</option>}
                                                { ExchangeProductType.map((iterator: any, idx: number) => (<option key={idx} value={iterator.id}>{iterator.name}</option>)) }
                                            </Form.Select>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </fieldset>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="6">
                            <fieldset style={{ height: '133px'}}>
                                <legend>Avisos e Alertas</legend>
                                <Row>
                                    <Col>
                                        <div className="react-switch">
                                            <FormControl type="checkbox" className="react-switch-checkbox" id="form-control" name="control" 
                                                checked={props.control}
                                                onChange={(e) => props.setControl(!props.control)}
                                            />
                                            <Form.Label className="react-switch-label" htmlFor="form-control">
                                                <span className={`react-switch-button`} /> 
                                            </Form.Label>
                                            <span className="react-switch-description white-space">{display.label.control_stock}</span>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="react-switch">
                                            <FormControl type="checkbox" className="react-switch-checkbox" id="form-notify" name="notify" 
                                                checked={props.notify}
                                                onChange={(e) => props.setNotify(!props.notify)}
                                            />
                                            <Form.Label className="react-switch-label" htmlFor="form-notify">
                                                <span className={`react-switch-button`} /> 
                                            </Form.Label>
                                            <span className="react-switch-description white-space">{display.label.notify_stock}</span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="react-switch">
                                            <FormControl type="checkbox" className="react-switch-checkbox" id="form-sellZero" name="sellZero" 
                                                checked={props.sellZero}
                                                onChange={(e) => props.setSellZero(!props.sellZero)}
                                            />
                                            <Form.Label className="react-switch-label" htmlFor="form-sellZero">
                                                <span className={`react-switch-button`} /> 
                                            </Form.Label>
                                            <span className="react-switch-description white-space">{display.label.sellZero_stock}</span>
                                        </div>
                                    </Col>
                                </Row>
                            </fieldset>
                        </Col>
                        <Col sm="6">
                            <fieldset>
                                <legend>Estoque</legend>
                                <FormGroup as={Row} className="form-row-divider">
                                    <Form.Label column htmlFor="form-minimum">{display.label.minimum_stock}</Form.Label>
                                    <Col>
                                        <FormControl type="number" min={0} id="form-minimum" name="minimum" placeholder={display.example.minimum_stock}
                                            className="text-right"
                                            value={toString(props.minimum)}
                                            onChange={e => props.setMinimum(e.target.value)}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup as={Row} className="form-row-divider">
                                    <Form.Label column htmlFor="form-actual">{display.label.actual_stock}</Form.Label>
                                    <Col>
                                        <FormControl type="number" min={0} id="form-actual" name="actual" placeholder={display.example.actual_stock}
                                            className="text-right"
                                            value={toString(props.actual)}
                                            onChange={e => props.setActual(e.target.value)}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup as={Row} className="form-row-divider mb-1">
                                    <Form.Label column htmlFor="form-movement">{display.label.update_stock}</Form.Label>
                                    <Col>
                                        <ReactDatePicker
                                            locale={ptBR}
                                            selected={toDate(props.movement, 'dd/mm/yyyy')}
                                            onChange={(date: any, e: any) => onChange_Movement(e, date)}
                                            dateFormat="dd/MM/yyyy"
                                            customInput={<PickerInput />}
                                            // minDate={new Date()}
                                            popperClassName="some-custom-class"
                                            popperPlacement="top-end"
                                            popperModifiers={[{
                                                    name : "offset",
                                                    options : {
                                                        offset : [5, 10]
                                                    }
                                                },{
                                                    name : "preventOverflow",
                                                    options : {
                                                        rootBoundary : "viewport",
                                                        tether : false,
                                                        altAxis : true
                                                    }
                                            }]}
                                            readOnly={props.isEdit}
                                        />
                                    </Col>
                                </FormGroup>
                            </fieldset>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="6">
                        <fieldset>
                                <legend>Aplicação (Modelos Compatíveis)</legend>
                                <FormGroup className="mb-4">
                                    <Form.Control as="textarea" rows={5} type="" id="form-usability" name="usability" placeholder={display.example.usability}
                                        value={toString(props.usability)}
                                        onChange={e => props.setUsability(e.target.value)}
                                    />
                                </FormGroup>
                            </fieldset>
                        </Col>
                        <Col sm="6">
                            <fieldset style={{paddingBottom: '44px'}}> 
                                <legend>Valores</legend>
                                <FormGroup as={Row} className="form-row-divider">
                                    <Form.Label column htmlFor="form-purchase">{display.label.purchase}</Form.Label>
                                    <Col>
                                        <FormControl type="text" id="form-purchase" name="purchase" placeholder={display.example.purchase}
                                            className="text-right"
                                            value={toString(props.purchase)}
                                            onChange={e => props.setPurchase(floatMask(e.target.value))}
                                            onBlur={e => onBlur_CalculatedSale(e.target.value)}
                                            required
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup as={Row} className="form-row-divider">
                                    <Form.Label column htmlFor="form-margin">Margem (%)</Form.Label>
                                    <Col>
                                        <FormControl type="text" id="form-margin" name="margin" placeholder={display.example.margin}
                                            className="text-right"
                                            value={toString(props.margin)}
                                            onChange={e => props.setMargin(floatMask(e.target.value))}
                                            onBlur={e => onBlur_CalculatedSale(e.target.value)}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup as={Row} className="mb-2">
                                    <Form.Label column htmlFor="form-sale">{display.label.sale}</Form.Label>
                                    <Col>
                                        <FormControl type="text" id="form-sale" name="sale" placeholder={display.example.sale} required
                                            readOnly
                                            className="text-right"
                                            value={toString(props.sale)}
                                            onChange={e => props.setSale(floatMask(e.target.value))}
                                        />
                                    </Col>
                                </FormGroup>
                            </fieldset>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="6">
                            <fieldset>
                                <legend>Informações Complementares</legend>
                                <FormGroup className="mb-4">
                                    {/* <Form.Label htmlFor="form-comments">Descrição e Informações</Form.Label> */}
                                    <Form.Control as="textarea" rows={5} type="" id="form-comments" name="comments" placeholder={display.example.description}
                                        value={toString(props.comments)}
                                        onChange={e => props.setComments(e.target.value)}
                                    />
                                </FormGroup>
                            </fieldset>
                        </Col>
                        <Col sm="6">

                        <div className="d-flex justify-content-between align-items-center" style={{marginTop: '-25px'}}>
                            <div className="bolder">
                                <p>IMPORTANTE:</p>
                                <p>Imagens para simples referência.<br/> Pode não representar exatamente o produto ou material.<br/> Verifique com seu fornecedor.</p>

                            </div>

                        {!isEmpty(props.image1) ? (
                            <div className='d-flex flex-column justify-content-center align-items-center'>
                                <ImageLightBox containerStyle={`${styles.imageWrapper} ${styles.vesselImage}`} src={props.image1}
                                    alt={'Imagem 1 Produto'}
                                    noBackground />
                                {!props.viewOnly && <FormGroup as={Row} className={`mt-3 d-flex justify-content-start ${styles.imageControls}`}>
                                    <label htmlFor={`input-file-image-engine-image1`}>
                                        <TooltipMaterial className="isClickable" title="Fazer upload de nova foto">
                                            <CloudUploadIcon />
                                        </TooltipMaterial>
                                    </label>
                                    <label className="text-right" 
                                    onClick={(e)=> props.setImage1(e)}
                                    >
                                        <TooltipMaterial className="isClickable ms-2" title="Remover foto atual">
                                            <HighlightOffIcon onClick={(e)=> props.changeImage1(e)}/>
                                        </TooltipMaterial>
                                    </label>
                                    <Form.Control type='file' id={`input-file-image-engine-image1`} accept="image/*" 
                                    onChange={(e)=> props.changeImage1(e)} 
                                    />
                                </FormGroup>}
                            </div>
                        ) : (
                            <div className={`${styles.imageWrapper} ${styles.vesselImagePlaceholder}`}>
                                <CameraAltOutlined color='secondary' className={`isClickable mb-3 ${styles.svg}`} sx={{fontSize: '73px'}} />
                                {!props.viewOnly && <FormGroup as={Row} className={` d-flex justify-content-start ${styles.imageControls}`}>
                                    <label htmlFor={`input-file-image-engine-image1`}>
                                        <TooltipMaterial className="isClickable" title="Fazer upload de nova foto">
                                            <CloudUploadIcon />
                                        </TooltipMaterial>
                                    </label>
                                    <label className="text-right">
                                        <TooltipMaterial className="isClickable ms-2" title="Remover foto atual">
                                            <HighlightOffIcon onClick={(e)=> props.changeImage1(e)}/>
                                        </TooltipMaterial>
                                    </label>
                                    <Form.Control type='file' id={`input-file-image-engine-image1`} accept="image/*" 
                                        onChange={(e)=> props.changeImage1(e)}
                                    />
                                </FormGroup>}
                            </div>
                        )}
                        {!isEmpty(props.image2) ? (
                            <div className='d-flex flex-column justify-content-center align-items-center'>
                                <ImageLightBox containerStyle={`${styles.imageWrapper} ${styles.vesselImage}`} src={props.image2}
                                    alt={'Imagem 2 Produto'}
                                    noBackground />
                                {!props.viewOnly && <FormGroup as={Row} className={`mt-3 d-flex justify-content-start ${styles.imageControls}`}>
                                    <label htmlFor={`input-file-image-engine-image2`}>
                                        <TooltipMaterial className="isClickable" title="Fazer upload de nova foto">
                                            <CloudUploadIcon />
                                        </TooltipMaterial>
                                    </label>
                                    <label className="text-right">
                                        <TooltipMaterial className="isClickable ms-2" title="Remover foto atual">
                                            <HighlightOffIcon onClick={(e)=> props.changeImage2(e)}/>
                                        </TooltipMaterial>
                                    </label>
                                    <Form.Control type='file' id={`input-file-image-engine-image2`} accept="image/*" 
                                        onChange={(e)=> props.changeImage2(e)} 
                                    />
                                </FormGroup>}
                            </div>
                        ) : (
                            <div className={`${styles.imageWrapper} ${styles.vesselImagePlaceholder}`}>
                                <CameraAltOutlined color='secondary' className={`isClickable mb-3 ${styles.svg}`} sx={{fontSize: '73px'}} />
                                {!props.viewOnly && <FormGroup as={Row} className={` d-flex justify-content-start ${styles.imageControls}`}>
                                    <label htmlFor={`input-file-image-engine-image2`}>
                                        <TooltipMaterial className="isClickable" title="Fazer upload de nova foto">
                                            <CloudUploadIcon />
                                        </TooltipMaterial>
                                    </label>
                                    <label className="text-right">
                                        <TooltipMaterial className="isClickable ms-2" title="Remover foto atual">
                                            <HighlightOffIcon onClick={(e)=> props.changeImage2(e)}/>
                                        </TooltipMaterial>
                                    </label>
                                    <Form.Control type='file' id={`input-file-image-engine-image2`} accept="image/*" 
                                        onChange={(e)=> props.changeImage2(e)} 
                                    />
                                </FormGroup>}
                            </div>
                        )}
                            </div>

                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
        );
    }

    return (
        <>
            {viewProductCadaster()}
        </>
    );

}

export default ProductRecord;