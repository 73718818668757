import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles'
import env from "react-dotenv";

import './styles/main.scss';
import "react-datepicker/dist/react-datepicker.css";

import MaintenancePage from './pages/maintenance.page';
import AccessPage from './pages/access.page';
import PortalPage from './pages/portal.page';
import ErrorPage from './pages/error.page';
import CardPage from './pages/card.page';
import PartnerPage from './pages/others/partner.page';

import authService from './services/auth.service';

import { SITUATION_APP_ACTIVE } from './utilities/constants';
import { TranslateProvider } from './contexts/translate.context';
import ConfirmMessageContextProvider from './contexts/confirm-message.context';
import AlertMessageContextProvider from './contexts/alert-message.context';
import DeleteMessageContextProvider from './contexts/delete-message.context';
import theme from './theme';
import { SnackbarProvider } from 'notistack'
import CheckIn from './pages/check-in/CheckIn';
import { MediaProvider } from './contexts/media.context';
import { PartnerProvider } from './contexts/partner.context';

function App() {

	const [ isLogged, setLogged ] = useState(false);

	async function initialize() {
	    let userAuth = {} as any;
		try {
   		    userAuth = authService.currentUser();
            setLogged(userAuth && (userAuth.id > 0));
        } catch(error: any) {
		    return error;
		}
	}

	useEffect(() => {
        initialize();
	}, []);

    return (
        <TranslateProvider>
            <ThemeProvider theme={theme}>
            <SnackbarProvider
                maxSnack={1}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
                }}
            >
            <MediaProvider>
            <PartnerProvider>
                <AlertMessageContextProvider>
                <ConfirmMessageContextProvider>
                <DeleteMessageContextProvider>
                <BrowserRouter>
                    <Routes>
                        {(env.SITUATION === SITUATION_APP_ACTIVE) ? (
                            isLogged ? (
                                <>
                                <Route index element={<Navigate replace to="/portal" />} />
                                <Route path="/">
                                    <Route path="access" element={<AccessPage />} />
                                    <Route path="portal/*" element={<PortalPage />} >
                                        <Route path="*" element={<PortalPage />} />
                                    </Route>
                                    <Route path="partner" element={<PartnerPage />} />
                                    <Route path="*" element={<ErrorPage type={' UrL não Cadastrada '} />} />
                                </Route>
                                <Route path="*" element={<ErrorPage type={' QQue deu de errado '} />} />
                                </>
                            ) : (
                                <Route path="*" element={<AccessPage />} />
                            )
                        ) : (
                            <Route index element={<MaintenancePage />} />
                        )}
                        <Route path="/card/vessel" element={<CardPage />} />
                        <Route path="/card/vessel/:enrollment" element={<CardPage />} />
                        <Route path="/card/checkin/:reservation" element={<CheckIn />} />
                        <Route path="*" element={<AccessPage />} />
                    </Routes>
                </BrowserRouter>
                </DeleteMessageContextProvider>
                </ConfirmMessageContextProvider>
                </AlertMessageContextProvider>
            </PartnerProvider>
            </MediaProvider>
            </SnackbarProvider>
            </ThemeProvider>
        </TranslateProvider>
	);
}

export default App;
