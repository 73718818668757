import { useNavigate, useSearchParams } from "react-router-dom";
import ProvidedHeader from "../headers/provided.header";
import { useAlertMessageContext } from "../../contexts/alert-message.context";
import { usePartnerContext } from "../../contexts/partner.context";
import { Button, Card, Col, Container, Image, Row, Spinner } from "react-bootstrap";
import { useTranslateContext } from "../../contexts/translate.context";
import styles from './CleaningProvided.module.scss';

import SearchIcon from '@mui/icons-material/Search';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import TodayIcon from '@mui/icons-material/Today';
import TimerIcon from '@mui/icons-material/Timer';
import WashIcon from '@mui/icons-material/Wash';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import { useEffect, useState } from "react";
import { FormControl, FormHelperText, Input, InputAdornment, InputLabel, LinearProgress } from "@mui/material";

import { ThemeProvider } from '@mui/material/styles';
import theme from "../../theme";
import { DataGrid, GridColDef, GridRowParams, GridSelectionModel, GridToolbar } from "@mui/x-data-grid";
import ptBR from 'date-fns/locale/pt-BR';
import moment from "moment";
import { dateToString, toDate, toInt } from "../../utilities/auxiliary-functions";
import { isEmpty } from "../../utilities/validators";
import { AlertMessageEnum, getNamesStatusClear } from "../../utilities/types";
import cduService from "../../services/cdu.service";
import DatePicker from "react-datepicker";
import { PickerButtom } from "../../components/sub-render.component";
import { FaSpinner } from "react-icons/fa";
import authService from "../../services/auth.service";
import { removeFormatDate } from "../../utilities/masks";
import { TFilter } from "../../types/FilterType";
import lclService from "../../services/lcl.service";
import { STATUS_CLEAR_AWAITING, STATUS_CLEAR_FINISHED, STATUS_CLEAR_FUELLED, STATUS_CLEAR_PENDING, STATUS_CLEAR_WORKING, VESSEL_LANCHA } from "../../utilities/constants";

import Jet from '../../assets/Jetski.svg';
import Lancha from '../../assets/Lancha.svg';
import CleaningModal from "../modals/personal-modal/CleaningModal";

export interface ICleaningProvidedProps {
}

export const CleaningProvided: React.FC<ICleaningProvidedProps> = (props: ICleaningProvidedProps) => {
    
    const navigator = useNavigate();

    const alertContext = useAlertMessageContext();
    const { display } = useTranslateContext();
    const { partner } = usePartnerContext();

    const title = "Serviços - Limpeza e Higienização";
    const description = "";

    const [ dateTimeStart, setDateTimeStart ] = useState(dateToString(new Date(), 'dd/mm/yyyy') + ' 00:00:00');
    const [ dateTimeEnd, setDateTimeEnd ] = useState(dateToString(new Date(), 'dd/mm/yyyy') + ' 23:59:59');

    let reloadPage = false;
    const [ isLoading, setLoading ] = useState(false);
    const [ textSearch, setTextSearch ] = useState('');
    const [ showFilter, setShowFilter]  = useState(false);
    const [ sending, setSending ] = useState(false);
    const [ selectionModel, setSelectionModel ] = useState<GridSelectionModel>([]);

    let noPaged = true;
    const [ searchParams, setSearchParams ] = useSearchParams();

    const [ pageSize, setPageSize ] = useState(10);
    const [ page, setPage ] = useState(0);
    const [ pageFilled, setPageFilled ] = useState<number[]>([]);

    const [ recordCount, setRecordCount ] = useState(0);
    const [ records, setRecords ] = useState([]);
    const [ originalRecords, setOriginalRecords ] = useState([]);

    const [ selectedFilter, setSelectedFilter] = useState('all')
    const [ pendingOrders, setPendingOrders ] = useState([] as any);
    const [ awaitingOrders, setAwaitingOrders ] = useState([] as any);
    const [ workingOrders, setWorkingOrders ] = useState([] as any);
    const [ finishedOrders, setFinishedOrders ] = useState([] as any);
    const [ fuelledOrders, setFuelledOrders ] = useState([] as any);

    const [ selectedOrder, setSelectedOrder ] = useState();
    const [ openConfirmationModal, setOpenConfirmationModal ] = useState(false);
    const [ modalType, setModalType ] = useState('')

    let forceRefresh = false;

    const columns: GridColDef[] = [
        { field: 'dateOperation', headerName: 'Data | Hora', width: 110, align: 'center', headerAlign: 'center', renderCell: renderDateTime },
        { field: 'operation', headerName: 'Operação', flex: 1, align: 'center', headerAlign: 'center' },
        { field: 'identifyKey', headerName: 'Nome | ID', flex: 1, align: 'center', headerAlign: 'center', renderCell: renderNameAndId },
        { field: 'vessel.enrollment', headerName: display.label.enrollment, flex:1, align: 'center', headerAlign: 'center', valueGetter: getVesselEnrollment },
        { field: 'vessel.typeVessel', headerName: 'Tipo | Cor',flex:1,  align: 'center', headerAlign: 'center', renderCell: renderTypeAndColor },
        { field: 'vessel.brand', headerName: 'Mar | Mod',flex:1,  align: 'center', headerAlign: 'center', renderCell: renderBrandAndModel },
        { field: 'locator', headerName: 'Localizador',flex:1,  align: 'center', headerAlign: 'center', renderCell: renderMovement },
        { field: 'status', headerName: 'Status',flex:1,  align: 'center', headerAlign: 'center', renderCell: renderStatus },
        { field: 'credential', headerName: 'Operador',flex:1,  align: 'center', headerAlign: 'center', valueGetter: getCredential },
    ];
    
    function renderDateTime(param: any) {
        const record = param.row;

        return (
            <div className="d-flex flex-column align-items-center">
                <div className={`mt-2 ${styles.info}`}>{new Date(record.dateOperation).toLocaleDateString('pt-BR')}</div>
                <div className={`mt-1 ${styles.info}`}>{new Date(record.dateOperation).toLocaleTimeString('pt-BR')}</div>
            </div>
        )
    }

    function renderNameAndId(param: any) {
        const {vessel} = param.row;

        return (
            <div className="d-flex flex-column align-items-center justify-content-center">
                <div className="white-space mt-2">{(vessel.name) ? vessel.name : '-'}</div>
                <div className="white-space mt-1">{vessel.identifyKey}</div>
            </div>
        );
    }

    function getVesselEnrollment(param: any) {
        const record = param.row;

        return record.vessel?.enrollment || '-';
    }

    function renderTypeAndColor(param: any) {
        const {vessel} = param.row;

        return toInt(vessel?.typeVessel) === VESSEL_LANCHA ? (
            <div className="d-flex flex-column align-items-center justify-content-center mb-2">
                <div className="mt-3 d-flex justify-content-center mb-2">
                    <Image src={Lancha} className="jet" style={{height: 32}}/>
                    <small className="ms-2">{`${vessel?.vesselSize}'`}</small>
                </div>
                <span className={styles.vesselColor} style={{backgroundColor: `${vessel?.color}`}}></span>
            </div>
        ):(
            <div className="d-flex flex-column align-items-center justify-content-center mb-2">
                <div className="d-flex justify-content-center mb-2">
                    <Image src={Jet} className="jet mt-3" style={{height: 32}}/>
                    <small className="ms-1 mt-3">{`10'`}</small>
                </div>
                <span className={styles.vesselColor} style={{backgroundColor: `${vessel?.color}`}}></span>
            </div>
        );
    }

    function renderBrandAndModel(param: any) {
        const {vessel} = param.row;

        return (
            <div className="d-flex flex-column justify-content-center mb-2">
                <div className="mt-3">{vessel?.brand || '-'}</div>
                <div className="mt-1">{vessel?.model || '-'}</div>
            </div>
        );
    }

    function renderMovement(param: any) {
        const record = param.row;

        return (
            <div className="d-flex flex-column align-items-center">
                <div className={`mt-2 ${styles.info}`}>{new Date(record.movementVessel?.dateMovement).toLocaleDateString('pt-BR')}</div>
                <div className={`mt-1 ${styles.info}`}>{record.movementVessel?.locator || '-'}</div>
            </div>
        )
    }

    function renderStatus(param: any) {
        const record = param.row;

        return  (
            <div className="d-flex flex-column align-items-center">
                <span className={`${styles.orderStatus} 
                    ${toInt(record.status) === STATUS_CLEAR_AWAITING ? styles.awaiting : ''}
                    ${toInt(record.status) === STATUS_CLEAR_WORKING ? styles.working : ''}
                    ${toInt(record.status) === STATUS_CLEAR_FINISHED ? styles.finished : ''}
                    ${toInt(record.status) === STATUS_CLEAR_PENDING ? styles.pending : ''}`}
                    onClick={e => viewOrder(record) }
                >
                    {getNamesStatusClear(toInt(record.status))}
                </span>
            </div>
        )
    }

    function getCredential(param: any) {
        const record = param.row;

        return record.credential || '-';
    }

    const onChange_DateTimeStart = async (date: any) => {
        const dateStop = toDate(dateTimeEnd, 'dd/mm/yyyy HH:MM:ss');
        if (date > dateStop) {
            setDateTimeStart(dateToString(dateStop, 'dd/mm/yyyy') + ' 00:00:00');
        } else {
            setDateTimeStart(dateToString(date, 'dd/mm/yyyy HH:MM:ss'));

            let parameterMaximumDaysInConsultations = await cduService.parameterByCode(partner.companyId, '10');
            let fim = moment(date).day(toInt(parameterMaximumDaysInConsultations.content) + 1);
            setDateTimeEnd(fim.format('DD/MM/YYYY 23:59:59'));
        }

        setRecords([]);
        setOriginalRecords([]);
        setRecordCount(0);
        setPageFilled([]);
    }

    const onChange_DateTimeStop = (date: any) => {
        const dateStart = toDate(dateTimeStart, 'dd/mm/yyyy HH:MM:ss');
        if (date < dateStart) {
            setDateTimeEnd(dateToString(dateStart, 'dd/mm/yyyy') + ' 23:59:59');
        } else {
            setDateTimeEnd(dateToString(date, 'dd/mm/yyyy HH:MM:ss'));
        }

        setRecords([]);
        setOriginalRecords([]);
        setRecordCount(0);
        setPageFilled([]);
    }

    function onClick_Refresh(event: any) {
        event.preventDefault();
        
        setSending(true);
        searchFilter(dateTimeStart, dateTimeEnd);
    } // onClick_RefreshMovement
    
    const filterOrders = (filter: string) => {
        setSelectedFilter(filter)
        if (filter === 'all') {
            setRecords(originalRecords)
        }
        if (filter === 'awaiting') {
            const filteredOrders = originalRecords.filter((record: any) => toInt(record.status) === STATUS_CLEAR_AWAITING)
            setRecords(filteredOrders)
        }
        if (filter === 'working') {
            const filteredOrders = originalRecords.filter((record: any) => toInt(record.status) === STATUS_CLEAR_WORKING)
            setRecords(filteredOrders)
        }
        if (filter === 'finished') {
            const filteredOrders = originalRecords.filter((record: any) => toInt(record.status) === STATUS_CLEAR_FINISHED)
            setRecords(filteredOrders)
        }
        if (filter === 'pending') {
            const filteredOrders = originalRecords.filter((record: any) => toInt(record.status) === STATUS_CLEAR_PENDING)
            setRecords(filteredOrders)
        }
        if (filter === 'fuelled') {
            const filteredOrders = originalRecords.filter((record: any) => toInt(record.status) === STATUS_CLEAR_FINISHED && toInt(record.fuelType) > 0)
            setRecords(filteredOrders)
        }
    }

    async function searchFilter(dateTimeStart: string, dateTimeEnd: string) {
        setLoading(true);

        /* Sempre incluir o partner na pesquisa */
        if (!isEmpty(partner)) {
            try {
                setDateTimeStart(dateTimeStart);
                setDateTimeEnd(dateTimeEnd);

                let localPageSize = pageSize;
                let localPage = page;

                if (noPaged) {
                    if (searchParams.has('limit')) {
                        let paramPageSize = searchParams.get('limit');
                        if (paramPageSize) {
                            // eslint-disable-next-line react-hooks/exhaustive-deps
                            localPageSize = Number.parseInt(paramPageSize);
                            setPageSize(localPageSize);
                        }
                    }
                    if (searchParams.has('offset')) {
                        let paramPage = searchParams.get('offset');
                        if (paramPage) {
                            // eslint-disable-next-line react-hooks/exhaustive-deps
                            localPage = Number.parseInt(paramPage);
                            setPage(localPage);
                        }
                    }
                }

                if ((pageFilled.indexOf(localPage) === -1) || forceRefresh) {
                    forceRefresh = false;

                    const filterMaintenance = {
                        attributes: [
                            'id', 'dateOperation', 'operation', 'clean', 'sanitize', 'sweeten', 'polish', 'status', 'credential', 'additionalInfo',
                            'partnerId',
                            'movementVessel.id', 'movementVessel.dateMovement', 'movementVessel.locator',
                            'vessel.id', 'vessel.identifyKey', 'vessel.name', 'vessel.enrollment', 'vessel.typeVessel', 'vessel.vesselSize', 'vessel.color', 'vessel.quantityPeople', 'vessel.brand', 'vessel.model',
                        ],
                        where: {
                            partnerId: partner.id,
                            dateOperation: { start: removeFormatDate(dateTimeStart), end: removeFormatDate(dateTimeEnd) }
                        },
                        order: [ [ 'dateOperation', 'ASC' ] ],
                        limit: localPageSize, 
                        offset: localPage * localPageSize
                    } as TFilter;
                    const vesselMaintenanceFilter = await lclService.vesselMaintenanceFilter(filterMaintenance);
                    const maintenanceIds = vesselMaintenanceFilter.rows.map((record: any) => record.id )
                    const filterSupply = {
                        attributes: [
                            'maintenanceId', 'fuelType', 'fuelInfo', 'hours', 'liters', 'amount'
                        ],
                        where: {
                            partnerId: partner.id,
                            maintenanceId: { in: maintenanceIds }
                        },
                        order: [ [ 'dateOperation', 'ASC' ] ]
                    } as TFilter;
                    const vesselSupplyFilter = await lclService.vesselSupplyFilter(filterSupply);

                    const orderFilter = [] as any;
                    vesselMaintenanceFilter.rows.forEach((maintenance: any) => {
                        let order = {
                            id : maintenance.id,
                            dateOperation : maintenance.dateOperation,
                            operation : maintenance.operation,
                            partnerId: partner.id,
                            movementVesselId: maintenance.movementVessel.id,
                            vesselId: maintenance.vessel.id,
                            movementVessel : maintenance.movementVessel,
                            vessel : maintenance.vessel,
                            clean : maintenance.clean,
                            sanitize : maintenance.sanitize,
                            sweeten : maintenance.sweeten,
                            polish : maintenance.polish,
                            status : maintenance.status, 
                            credential : maintenance.credential,
                            additionalInfo : maintenance.additionalInfo
                        } as any;

                        let supply = vesselSupplyFilter.rows.filter((supply: any) => supply.maintenanceId === maintenance.id)[0];
                        order = {
                            ...order,
                            fuelType : supply?.fuelType || 0,
                            fuelInfo : supply?.fuelInfo || 0,
                            hours : supply?.hours || 0,
                            liters : supply?.liters || 0,
                            amount : supply?.amount || 0
                        }

                        orderFilter.push(order);
                    });

                    setRecordCount(orderFilter.length);
                    const array = [] as any;
                    records.forEach((record: any) => array.push(record));

                    orderFilter.forEach((record: any) => {
                        // array.push(record);
                        let idx = 0;
                        let exist = false;
                        for (; idx < array.length; idx++) {
                            if (array[idx].id === record.id) {
                                exist = true;
                                break;
                            }
                        }
                        if (exist) 
                            array[idx] = record
                        else
                            array.push(record);
                    });

                    setRecords(array);
                    setOriginalRecords(array);
                    setSending(false);

                    const awaiting = array.filter((record: any) => toInt(record.status) === STATUS_CLEAR_AWAITING)
                    setAwaitingOrders(awaiting)
                    const working = array.filter((record: any) => toInt(record.status) === STATUS_CLEAR_WORKING)
                    setWorkingOrders(working)
                    const pending = array.filter((record: any) => toInt(record.status) === STATUS_CLEAR_PENDING)
                    setPendingOrders(pending)
                    const finished = array.filter((record: any) => toInt(record.status) === STATUS_CLEAR_FINISHED)
                    setFinishedOrders(finished)
                    const fuelled = array.filter((record: any) => toInt(record.status) === STATUS_CLEAR_FINISHED && toInt(record.fuelType) > 0)
                    setFuelledOrders(fuelled)

                    if (array.length > 0)
                        pageFilled.push(localPage);
                }
            } catch(error: any) {
                await alertContext.show(AlertMessageEnum.FAIL, title, error);
            } finally {
                setSending(false);
                setLoading(false);
                setShowFilter(false);
            }
        } else {
            await alertContext.show(AlertMessageEnum.FAIL, title, 'Não foi definido uma Marina para exibir o cadastro !');
            setLoading(false);
            navigator(`/portal`);
        }
    } // searchFilter

    const onChangePageSize = (newPageSize: number) => {
        setSearchParams({...searchParams, 'limit': `${newPageSize}`, 'offset': `${0}`});

        setRecords([]);
        setOriginalRecords([]);
        setRecordCount(0);
        setPageFilled([]);
        setPageSize(newPageSize);
        setPage(0);
    }

    const onChangePage = (newPage: number) => {
        setSearchParams({...searchParams, 'offset': `${newPage}`});

        setPage(newPage);
    }

    let effectForPage = false;

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        noPaged = false;
        let paramPage = searchParams.get('offset');
        if (paramPage) {
            searchFilter(dateTimeStart, dateTimeEnd);
            effectForPage = true;
        }
    }, [pageSize, page]); // useEffect

    useEffect(() => {
        if (!isLoading && !reloadPage) {
            noPaged = true;
            if (!effectForPage) {
                let inicio = moment().day(0); // domingo desta semana
                let fim = moment().day(6); // sábado desta semana

                let startDate = inicio.format('DD/MM/YYYY 00:00:00');
                let endDate = fim.format('DD/MM/YYYY 23:59:59');
                
                searchFilter(startDate, endDate);
                effectForPage = false;
            }
        }

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            reloadPage = !reloadPage;
        }
    }, []); // useEffect

    const filtered = (key?: string, value?: string) => {
        const filtered: any = []
        records.filter((record: any) => {
            if (
                record.vesselEnrollment
                    .toLocaleLowerCase()
                    .includes(textSearch.toLocaleLowerCase()) ||
                record.vesselName
                    .toLocaleLowerCase()
                    .includes(textSearch.toLocaleLowerCase())
            )
            filtered.push(record)
            return record
        })
        setRecords(filtered)
        return ''
    }

    useEffect(() => {
        if (textSearch.length >= 3) {
            filtered()
        } else if (textSearch.length < 3) {
          setRecords(originalRecords)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [textSearch]);

    const viewOrder = (order: any) => {
        setSelectedOrder(order)
        setOpenConfirmationModal(true)
    }

    const closeModal = (event: any, reason: any) => {
        if (reason && reason === 'backdropClick') return
        setOpenConfirmationModal(false)
        setModalType('')
    }

    const handleOrder = (order: any) => {
        setOpenConfirmationModal(false)
        setModalType('')
        forceRefresh = true;
        searchFilter(dateTimeStart, dateTimeEnd);
    }

    function viewPage() {

        return (
            <Container fluid className="page-body provideds">
                <Row>
                    <Col md={12} className="page-sweet">
                        <Card 
                            className={styles.provided}
                            style={{ minHeight : 500 }}
                        >
                            <Card.Header>
                                <div className="card-actions d-flex justify-content-between">
                                    <div className="d-flex">
                                        <div className="card-actions-time d-flex flex-row">
                                            <DatePicker
                                                locale={ptBR}
                                                selected={toDate(dateTimeStart, 'dd/mm/yyyy HH:MM:ss')}
                                                onChange={(date: any) => onChange_DateTimeStart(date) }
                                                dateFormat="dd/MM/yyyy"
                                                customInput={<PickerButtom />}
                                                selectsStart
                                                startDate={toDate(dateTimeStart, 'dd/mm/yyyy HH:MM:ss')}
                                                endDate={toDate(dateTimeEnd, 'dd/mm/yyyy HH:MM:ss')}

                                                popperClassName="some-custom-class"
                                                popperPlacement="top-end"
                                                popperModifiers={[
                                                    {
                                                        name : "offset",
                                                        options : {
                                                            offset : [5, 10]
                                                        }
                                                    },{
                                                        name : "preventOverflow",
                                                        options : {
                                                            rootBoundary : "viewport",
                                                            tether : false,
                                                            altAxis : true
                                                        }
                                                    }
                                                ]}
                                            /> 
                                            <DatePicker
                                                locale={ptBR}
                                                selected={toDate(dateTimeEnd, 'dd/mm/yyyy HH:MM:ss')}
                                                onChange={(date: any) => onChange_DateTimeStop(date) }
                                                dateFormat="dd/MM/yyyy"
                                                customInput={<PickerButtom />}
                                                selectsEnd
                                                startDate={toDate(dateTimeStart, 'dd/mm/yyyy HH:MM:ss')}
                                                endDate={toDate(dateTimeEnd, 'dd/mm/yyyy HH:MM:ss')}
                                                minDate={toDate(dateTimeStart, 'dd/mm/yyyy HH:MM:ss')}

                                                popperClassName="some-custom-class"
                                                popperPlacement="top-end"
                                                popperModifiers={[
                                                    {
                                                        name : "offset",
                                                        options : {
                                                            offset : [5, 10]
                                                        }
                                                    },{
                                                        name : "preventOverflow",
                                                        options : {
                                                            rootBoundary : "viewport",
                                                            tether : false,
                                                            altAxis : true
                                                        }
                                                    }
                                                ]}
                                            />
                                        </div>
                                        <Button variant="secondary" className='ms-2' onClick={ (e) => onClick_Refresh(e) } disabled={sending}>
                                            { sending ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <FaSpinner size={22} /> } {' '}
                                            {display.buttom.refresh}
                                        </Button>
                                    </div>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <div className="d-flex justify-content-between">
                                    <FormControl fullWidth sx={{width: 300, marginBottom: 2 }} variant="standard">
                                        <InputLabel className={styles.label} htmlFor="standard-adornment-amount">Pesquisar</InputLabel>
                                        <Input id="standard-adornment-amount" value={textSearch} onChange={(e)=>
                                        setTextSearch(e.target.value)}
                                        endAdornment = {
                                        <InputAdornment position="end">
                                            <SearchIcon className='isClickable' />
                                        </InputAdornment>
                                        }
                                        />
                                        <FormHelperText id="component-helper-text">
                                            ID, Nome ou Registro da Embarcação
                                        </FormHelperText>
                                    </FormControl>
                                    <div className={styles.statusButtonsWrapper}>
                                        <div className={`${styles.statusButton} ${selectedFilter === 'all' ? styles.active : ''}`} 
                                            role="presentation" 
                                            onClick={() => filterOrders('all')}
                                        >
                                            <TodayIcon sx={{ fontSize: 32 }} />
                                            <span className="ms-2">{originalRecords?.length || 0}</span>
                                        </div>
                                        <div className={`${styles.statusButton} ${selectedFilter === 'awaiting' ? styles.active : ''}`} 
                                            role="presentation" 
                                            onClick={() => filterOrders('awaiting')}
                                        >
                                            <TimerIcon color="warning" sx={{ fontSize: 32 }} />
                                            <span className="ms-2">{awaitingOrders?.length || 0}</span>
                                        </div>
                                        <div className={`${styles.statusButton} ${selectedFilter === 'working' ? styles.active : ''}`} 
                                            role="presentation" 
                                            onClick={() => filterOrders('working')}
                                        >
                                            <WashIcon color="info"  sx={{ fontSize: 32 }} />
                                            <span className="ms-2">{workingOrders?.length || 0}</span>
                                        </div>
                                        <div className={`${styles.statusButton} ${selectedFilter === 'pending' ? styles.active : ''}`} 
                                            role="presentation" 
                                            onClick={() => filterOrders('pending')}
                                        >
                                            <PendingActionsIcon color="error"  sx={{ fontSize: 32 }} />
                                            <span className="ms-2">{pendingOrders?.length || 0}</span>
                                        </div>
                                        <div className={`${styles.statusButton} ${selectedFilter === 'fuelled' ? styles.active : ''}`} 
                                            role="presentation" 
                                            onClick={() => filterOrders('fuelled')}
                                        >
                                            <LocalGasStationIcon sx={{ fontSize: 32 }} />
                                            <span className="ms-2">{fuelledOrders?.length || 0}</span>
                                        </div>
                                        <div className={`${styles.statusButton} ${ selectedFilter==='finished' ? styles.active : '' }`} 
                                            role="presentation" 
                                            onClick={() => filterOrders('finished')}
                                        >
                                            <SportsScoreIcon sx={{ fontSize: 32 }} />
                                            <span className="ms-2">{finishedOrders?.length || 0}</span>
                                        </div>
                                    </div>
                                </div>
                                <ThemeProvider theme={theme}>
                                    <div style={{ height: 665, width: '100%', marginTop: 0 }}>
                                        <DataGrid
                                            rows={records}
                                            rowCount={recordCount}
                                            getRowId={row => row.id}
                                            loading={isLoading}
                                            columns={columns}
                                            pageSize={pageSize}
                                            onPageSizeChange={newPageSize => onChangePageSize(newPageSize)}
                                            page={page}
                                            onPageChange={newPage => onChangePage(newPage) }
                                            
                                            rowsPerPageOptions={[10, 25, 50, 75, 100]}
                                            disableSelectionOnClick
                                            rowHeight={70}
                                            // onCellClick={(e) => console.log(e)}
                                            onSelectionModelChange={(newSelectionModel: any) => {
                                                setSelectionModel(newSelectionModel)
                                            }}
                                            /*
                                            onRowClick={(params: GridRowParams) => {
                                                viewOrder(params.row);
                                            }}
                                            */
                                            components={{
                                                Toolbar: GridToolbar,
                                                LoadingOverlay: LinearProgress
                                            }}
                                            selectionModel={selectionModel}
                                        />
                                    </div>
                                </ThemeProvider>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    } // viewPage

    return (
        <div className="page">
            <ProvidedHeader title={title} description={description} />
            { viewPage() }
            <CleaningModal
                open={openConfirmationModal}
                handleClose={closeModal}
                height={"fit-content"}
                width={470}
                order={selectedOrder}
                type={modalType}
                onConfirm={(order: any) => handleOrder(order)}
                onCancel={closeModal}
            />
        </div>
    );
}

export default CleaningProvided;