import { useEffect, useState, } from "react";
import { Form, Modal, Spinner } from "react-bootstrap";
import { FaRegIdCard, FaTimes } from "react-icons/fa";
import { useAlertMessageContext } from "../../contexts/alert-message.context";
import { useConfirmMessageContext } from "../../contexts/confirm-message.context";
import srvService from "../../services/srv.service";
import { dateAdd, dateToString } from "../../utilities/auxiliary-functions";
import { removeFormatDate } from "../../utilities/masks";
import { AlertMessageEnum } from "../../utilities/types";
import ScheduleRecord from "../records/schedule.record";
import { Button } from '@mui/material';
import { useTranslateContext } from "../../contexts/translate.context";
import { usePartnerContext } from "../../contexts/partner.context";

export interface IScheduleCadastreModalProps {
    isEdit: boolean,
    show: boolean,
    scheduleId: string,
    record: any;
    onSubmitModal: any,
    onCancelModal: any
}

export const ScheduleCadastreModal: React.FC<IScheduleCadastreModalProps> = (props: IScheduleCadastreModalProps) => {
    
    const alertContext = useAlertMessageContext();
    const confirmContext = useConfirmMessageContext();
    const { display } = useTranslateContext();
    const { partner } = usePartnerContext();

    const title : string = display.title.schedule_cadastre;

    const stay = 2;
    const [ invalidatedCadastreForm, setInvalidatedCadastreForm ] = useState(false);

    const [ sending, setSending ] = useState(false);

    // Schedule
    const [ scheduleId, setScheduleId ] = useState(0);
    const [ partnerId, setPartnerId ] = useState(0);
    const [ prohibited, setProhibited ] = useState(dateToString(new Date(), 'dd/mm/yyyy HH:MM:ss'));
    const [ departure, setDeparture ] = useState(dateToString(dateAdd('H', new Date(), stay), 'dd/mm/yyyy HH:MM:ss'));
    const [ titleTask, setTitleTask ] = useState('');
    const [ color, setColor ] = useState('#FFCC66');
    const [ sourceLink, setSourceLink ] = useState('');
    const [ numberLink, setNumberLink ] = useState('');
    const [ comments, setComments ] = useState('');
    const [ created, setCreated ] = useState(dateToString(new Date(), 'dd/mm/yyyy HH:MM:ss'));
    const [ updated, setUpdated ] = useState(dateToString(new Date(), 'dd/mm/yyyy HH:MM:ss'));

    async function isValidForm() {
        let result = true;
        let emptyRequired = false;

        if (emptyRequired) {
            await alertContext.show(AlertMessageEnum.FAIL, title, display.message.invalid.required);
            result = false;
        }

        setInvalidatedCadastreForm(!result);
        return result;
    }

    async function onClick_Save(event: any) {
        event.preventDefault();
        
        const isValid = await isValidForm();
        if (isValid) {
            try {
                const isConfirmed = await confirmContext.show(title, display.message.confirm_record);
                if (isConfirmed) {
                    setSending(true);

                    const schedule = fillSchedule();
                    let returned = null;

                    if (props.isEdit)
                        returned = await srvService.saveSchedule(schedule.id, schedule);
                    else
                        returned = await srvService.createSchedule(schedule);
                    schedule.id = returned.id;
                    setSending(false);
                    await alertContext.show(AlertMessageEnum.SUCCESS, title, display.message.the_record_has_been_saved_successfully);
                    props.onSubmitModal(event, schedule);
                }
            } catch (error: any) {
                setSending(false);
                await alertContext.show(AlertMessageEnum.FAIL, title, error);
            }
        }
    }

    async function onClick_Cancel(event : any) {
        event.preventDefault();

        setInvalidatedCadastreForm(false);
        props.onCancelModal(event);
    }

    async function clearSchedule() {
        // Schedule
        setScheduleId(0);
        setPartnerId(0);
        setProhibited(dateToString(new Date(), 'dd/mm/yyyy HH:MM:ss'));
        setDeparture(dateToString(dateAdd('H', new Date(), stay), 'dd/mm/yyyy HH:MM:ss'));
        setTitleTask('');
        setColor('#FFCC66');
        setSourceLink('Task');
        setNumberLink('');
        setComments('');

        setCreated('');
        setUpdated('');
    }

    function fillRecord(schedule: any) {

        if (schedule != null) {
            setScheduleId(schedule.id);

            setPartnerId(schedule.partnerId);
            setProhibited(dateToString(schedule.prohibited, 'dd/mm/yyyy HH:MM:ss'));
            setDeparture(dateToString(schedule.departure, 'dd/mm/yyyy HH:MM:ss'));
            setTitleTask(schedule.title);
            setColor(schedule.color);
            setSourceLink(schedule.sourceLink);
            setNumberLink(schedule.numberLink);
            setComments(schedule.comments);
            setCreated(dateToString(schedule.created, 'dd/mm/yyyy HH:MM:ss'));
            setUpdated(dateToString(schedule.updated, 'dd/mm/yyyy HH:MM:ss'));
        }
    } // fillRecord

    function fillSchedule() {

        const dataSchedule = {
            id: scheduleId,
            partnerId : partner.id,
            prohibited : removeFormatDate(prohibited),
            departure : removeFormatDate(departure),
            title : titleTask,
            color,
            sourceLink,
            numberLink,
            comments,
            created : removeFormatDate(created),
            updated : removeFormatDate(updated)
        };
        return dataSchedule;
    }

    async function initialize() {
        clearSchedule();

        if (props.isEdit) {
            try {
                const schedule = await srvService.scheduleById(Number(props.scheduleId));
                fillRecord(schedule);
            } catch(error: any) {
                await alertContext.show(AlertMessageEnum.FAIL, title, error);
            }
        }
    }

    useEffect(() => {
        if (props.show)
            initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show]);

    /* FUNCION EXCLUVISE OF FORM - END */

    function viewCadastreForm() {
        return (
            <>
                <Modal show={props.show}
                    style={{ top: "calc((100vh / 2) - 250px)", left: "calc((100vw / 2) - 250px)", width : "500px" }}
                    aria-labelledby="modal-cadastre-schedule-form"
                >
                    <Modal.Header>
                        <Modal.Title id="modal-cadastre-schedule-form"><FaRegIdCard />{title}</Modal.Title>
                        <div className="d-flex align-items-center">
                            <FaTimes className="isClickable" onClick={ onClick_Cancel } size={22}/>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <Form className="form" id="form-cadastre-schedule" validated={invalidatedCadastreForm}>
                            <ScheduleRecord
                                title={title}
                                isEditMode={props.isEdit}

                                partnerId={partnerId}
                                prohibited={prohibited}
                                departure={departure}
                                titleTask={titleTask}
                                color={color}
                                sourceLink={sourceLink}
                                numberLink={numberLink}
                                comments={comments}
                                created={created}
                                updated={updated}
                            
                                setProhibited={(value: any) => { setProhibited(value); }}
                                setDeparture={(value: any) => { setDeparture(value); }}
                                setTitleTask={(value: any) => { setTitleTask(value); }}
                                setColor={(value: any) => { setColor(value); }}
                                setSourceLink={(value: any) => { setSourceLink(value); }}
                                setNumberLink={(value: any) => { setNumberLink(value); }}
                                setComments={(value: any) => { setComments(value); }}
                                setCreated={(value: any) => { setCreated(value); }}
                                setUpdated={(value: any) => { setUpdated(value); }}
                            />
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={ onClick_Cancel } variant="contained" color="secondary">{display.buttom.cancel}</Button>
                        <Button variant="contained" color="primary" onClick={(e) => onClick_Save(e)} disabled={sending}>
                            { sending ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null }{'  '}
                            { display.buttom.save }
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

    return (
        <>
            { viewCadastreForm() }
        </>
    )
}

export default ScheduleCadastreModal
