import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { BsSliders } from "react-icons/bs";

import { useTranslateContext } from "../../contexts/translate.context";

import AddressRecord from './address.record';
import PersonalDataRecord from './personal-data.record';
import PersonalDocumentRecord from './personal-document.record';
import MaritimeLicenseRecord from "./maritime-license.record";
import { Button } from '@mui/material'
import styles from './vessel-conductor.record.module.scss'

export interface IVesselConductorRecordProps {
    title: any,
    isEdit: boolean,

    created?: any,
    updated?: any,
    tratament?: any,
    fullname?: any,
    gender?: any,
    birth?: any,
    norm?: any,
    operator?: any,
    connection?: any,
    sms?: any,
    zap?: any,
    major?: any,
    nationality?: any,
    naturalness?: any,
    registrationPf?: any,
    registrationIdentity?: any,
    issuerIdentity?: any,
    stateIdentity?: any,
    expeditionIdentity?: any,
    driversLicense?: any,
    categoryDriversLicense?: any,
    validityDriversLicense?: any,
    categoryNaval?: any,
    enrollmentNaval?: any,
    validityNaval?: any,
    limitsNaval?: any,
    commentsNaval?: any,
    locationNaval?: any,
    expeditionNaval?: any,
    zipcode?: any,
    state?: any,
    city?: any,
    district?: any,
    place?: any,
    habitation?: any,
    complement?: any,
    setCreated?: any,
    setUpdated?: any,
    setTratament?: any,
    setFullname?: any,
    setGender?: any,
    setBirth?: any,
    setNorm?: any,
    setOperator?: any,
    setConnection?: any,
    setSms?: any,
    setZap?: any,
    setMajor?: any,
    setNationality?: any,
    setNaturalness?: any,
    setRegistrationPf?: any,
    setRegistrationIdentity?: any,
    setIssuerIdentity?: any,
    setStateIdentity?: any,
    setExpeditionIdentity?: any,
    setDriversLicense?: any,
    setCategoryDriversLicense?: any,
    setValidityDriversLicense?: any
    setCategoryNaval?: any,
    setEnrollmentNaval?: any,
    setValidityNaval?: any,
    setLimitsNaval?: any,
    setCommentsNaval?: any,
    setLocationNaval?: any,
    setExpeditionNaval?: any,
    document?: any,
    setZipcode?: any,
    setState?: any,
    setCity?: any,
    setDistrict?: any,
    setPlace?: any,
    setHabitation?: any,
    setComplement?: any,
    changeDocument?: any,
    showFilterPeopleModal?: any,

    driversLicenseDocument?: any,
    proofOfResidenceDocument: any,
    changeDriversLicenseDocument?: any,
    changeProofOfResidenceDocument: any,
    clickViewDocument: any
}

export const VesselConductorRecord: React.FC<IVesselConductorRecordProps> = (props: IVesselConductorRecordProps) => {

    const { display } = useTranslateContext();

    const handleFilterPeopleModal = (e: any) => {
        props.showFilterPeopleModal(e)
    }
    
    return (
        <>
            <Row>
                <Col sm="12">
                    <fieldset>
                        <div className="d-flex align-items-center justify-content-start mt-2">
                            <legend className={styles.titleLegend}>{display.legend.general_data}</legend>
                            {(!props.isEdit) && (
                                <Button className="ms-2 mb-2" size="small" variant="contained" color="secondary" onClick={(e: any) => handleFilterPeopleModal(e) }>
                                    {display.legend.search_person} <BsSliders className="ms-3" />
                                </Button>
                            )}
                        </div>
                        <PersonalDataRecord
                            title={props.title}
                            isEdit={props.isEdit}

                            tratament={props.tratament}
                            fullname={props.fullname}
                            gender={props.gender}
                            birth={props.birth}
                            norm={props.norm}
                            operator={props.operator}
                            connection={props.connection}
                            sms={props.sms}
                            zap={props.zap}
                            major={props.major}
                            nationality={props.nationality}
                            naturalness={props.naturalness}
                            setTratament={(value: any) => { props.setTratament(value); }}  
                            setFullname={(value: any) => { props.setFullname(value); }}  
                            setGender={(value: any) => { props.setGender(value); }}  
                            setBirth={(value: any) => { props.setBirth(value); }}  
                            setNorm={(value: any) => { props.setNorm(value); }}
                            setOperator={(value: any) => { props.setOperator(value); }}
                            setConnection={(value: any) => { props.setConnection(value); }}
                            setSms={(value: any) => { props.setSms(value); }}
                            setZap={(value: any) => { props.setZap(value); }}
                            setMajor={(value: any) => { props.setMajor(value); }}
                            setNationality={(value: any) => { props.setNationality(value); }} 
                            setNaturalness={(value: any) => { props.setNaturalness(value); }} 
                            
                        />
                    </fieldset>
                    <Tabs defaultActiveKey="member-documentation" id="tabs-member" className="mb-3">
                        <Tab eventKey="member-documentation" title={display.subtitle.documentation}>
                            <Container>
                                <fieldset style={{minHeight : 250}}>
                                    <legend>{display.legend.documentation}</legend>
                                    <PersonalDocumentRecord
                                        title={props.title}
                                        isEdit={props.isEdit}

                                        registrationPf={props.registrationPf}
                                        registrationIdentity={props.registrationIdentity}
                                        issuerIdentity={props.issuerIdentity}
                                        stateIdentity={props.stateIdentity}
                                        expeditionIdentity={props.expeditionIdentity}
                                        driversLicense={props.driversLicense}
                                        categoryDriversLicense={props.categoryDriversLicense}
                                        validityDriversLicense={props.validityDriversLicense}
                                        setRegistrationPf={(value: any) => { props.setRegistrationPf(value); }}  
                                        setRegistrationIdentity={(value: any) => { props.setRegistrationIdentity(value); }}  
                                        setIssuerIdentity={(value: any) => { props.setIssuerIdentity(value); }}  
                                        setStateIdentity={(value: any) => { props.setStateIdentity(value); }}  
                                        setExpeditionIdentity={(value: any) => { props.setExpeditionIdentity(value); }}  
                                        setDriversLicense={(value: any) => { props.setDriversLicense(value); }}  
                                        setCategoryDriversLicense={(value: any) => { props.setCategoryDriversLicense(value); }} 
                                        setValidityDriversLicense={(value: any) => { props.setValidityDriversLicense(value); }} 
                                    />
                                </fieldset>
                            </Container>
                        </Tab>
                        <Tab eventKey="member-maritime-license" title={display.subtitle.maritime_license}>
                            <Container>
                                <fieldset style={{minHeight : 250}}>
                                    <legend>{display.legend.maritime_license}</legend>
                                    <MaritimeLicenseRecord
                                        title={props.title}
                                        isEdit={props.isEdit}
                                        isRequired={true}

                                        categoryNaval={props.categoryNaval}
                                        enrollmentNaval={props.enrollmentNaval}
                                        validityNaval={props.validityNaval}
                                        limitsNaval={props.limitsNaval}
                                        commentsNaval={props.commentsNaval}
                                        locationNaval={props.locationNaval}
                                        expeditionNaval={props.expeditionNaval}
                                        setCategoryNaval={(value: any) => { props.setCategoryNaval(value); }} 
                                        setEnrollmentNaval={(value: any) => { props.setEnrollmentNaval(value); }} 
                                        setValidityNaval={(value: any) => { props.setValidityNaval(value); }} 
                                        setLimitsNaval={(value: any) => { props.setLimitsNaval(value); }} 
                                        setCommentsNaval={(value: any) => { props.setCommentsNaval(value); }} 
                                        setLocationNaval={(value: any) => { props.setLocationNaval(value); }} 
                                        setExpeditionNaval={(value: any) => { props.setExpeditionNaval(value); }} 

                                        driversLicenseDocument={props.driversLicenseDocument}
                                        changeDriversLicenseDocument={props.changeDriversLicenseDocument}
                                        clickViewDocument={props.clickViewDocument}
                                    />
                                </fieldset>
                            </Container>
                        </Tab>
                        <Tab eventKey="member-personal-address" title={display.subtitle.personal_address}>
                            <Container>
                                <fieldset style={{minHeight : 250}}>
                                    <legend>{display.legend.personal_address}</legend>
                                    <AddressRecord 
                                        title={props.title}
                                        isEdit={props.isEdit}

                                        zipcode={props.zipcode}
                                        state={props.state}
                                        city={props.city}
                                        district={props.district}
                                        place={props.place}
                                        habitation={props.habitation}
                                        complement={props.complement}
                                        setZipcode={(value: any) => { props.setZipcode(value); }} 
                                        setState={(value: any) => { props.setState(value); }} 
                                        setCity={(value: any) => { props.setCity(value); }} 
                                        setDistrict={(value: any) => { props.setDistrict(value); }} 
                                        setPlace={(value: any) => { props.setPlace(value); }} 
                                        setHabitation={(value: any) => { props.setHabitation(value); }} 
                                        setComplement={(value: any) => { props.setComplement(value); }}

                                        proofOfResidenceDocument={props.proofOfResidenceDocument}
                                        changeProofOfResidenceDocument={props.changeProofOfResidenceDocument}
                                        clickViewDocument={props.clickViewDocument}
                                    />
                                </fieldset>
                            </Container>
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
        </>
    );

}

export default VesselConductorRecord;
