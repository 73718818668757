import env from "react-dotenv";
import { ServiceCommon } from "./service.common";
import { isEmpty } from "../utilities/validators";
import { TFilter } from "../types/FilterType";
import { TPagination } from "../types/PaginationType";

class CduService extends ServiceCommon {
    
    API_CDU = env.API_CDU;

    constructor() {
        super('CduService');
    }

    async roleFilter(attributes: [], where: {}, order: []) {
        const url = `${this.API_CDU}/role/filter`;

        try {
            return await this.postReturnObject(url, {attributes, where, order});
        } catch (error: any) {
            throw new Error(error);
        }
    } // roleFilter

    async saveRole(roleId: number, role: any) {
        const url = `${this.API_CDU}/role/${roleId}`;

        try {
            return await this.putReturnObject(url, role);
        } catch (error: any) {
            throw new Error(error);
        }
    }


    async solicitationOfAccess(
        fullname: string, gender: string, registrationPf: string, email: string, connection: string, 
        zipcode: string, state: string, city: string, district: string, place: string, habitation: string, complement: string
    ) {
        const url = `${this.API_CDU}/user/solicitation`;

        try {
            return await this.postReturnObject(url, {
                fullname, gender, registrationPf, email, connection, 
                zipcode, state, city, district, place, habitation, complement
            });
        } catch (error: any) {
            throw new Error(error);
        }
    }

    async userGenerateToken(email: string) {
        const url = `${this.API_CDU}/user/generate_token/${email}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    }

    async interestInPartner(
        socialReason: string, fantasy: string, registrationPj: string, email: string, site: string, telephone: string, 
        zipcode: string, state: string, city: string, district: string, place: string, habitation: string, complement: string, 
        responsibleName: string, responsibleRegistrationPf: string, responsibleGender: string, responsibleEmail: string, responsibleTelephone: string
    ) {
        const url = `${this.API_CDU}/company/interest`;

        try {
            return await this.postReturnObject(url, {
                socialReason, fantasy, registrationPj, email, site, telephone, 
                zipcode, state, city, district, place, habitation, complement, 
                responsibleName, responsibleRegistrationPf, responsibleGender, responsibleEmail, responsibleTelephone
            });
        } catch (error: any) {
            throw new Error(error);
        }
    }

    
    async peopleAccess() {
        const url = `${this.API_CDU}/people/access`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // peopleAccess

    async userById(userId: number) {
        const url = `${this.API_CDU}/user/${userId}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // userById

    async userFilter(attributes: [], where: {}, order: []) {
        const url = `${this.API_CDU}/user/filter`;

        try {
            return await this.postReturnObject(url, {attributes, where, order});
        } catch (error: any) {
            throw new Error(error);
        }
    } // userFilter

    saveUser(typeInfo: string, userId: number, data: any) {
        var result: any;
        switch(typeInfo) {
            case 'PublicInfo':
                result = this.putReturnObject(`${this.API_CDU}/user/${userId}/public_info`, data);
                break;
            case 'PrivateInfo':
                result = this.putReturnObject(`${this.API_CDU}/user/${userId}`, data);
                break;
            case 'AccessInfo':
                result = this.putReturnObject(`${this.API_CDU}/user/${userId}/access_info`, data);
                break;
        default:
            throw new Error(`Type ${typeInfo} not implemented.`);
        }
        return result;
    } // userSave

    async createUser(typeInfo: string, user: any) {
        const url = `${this.API_CDU}/user/create/public_info`;

        try {
            return await this.postReturnObject(url, user);
        } catch (error: any) {
            throw new Error(error);
        }
    } // createUser

    async userRolesFilter(userId: number, attributes: [], where: {}, order: []) {
        const url = `${this.API_CDU}/user/${userId}/roles/filter`;

        try {
            return await this.postReturnObject(url, {attributes, where, order});
        } catch (error: any) {
            throw new Error(error);
        }
    } // userRoleFilter

    async saveUserRoles(userId: number, userRoles: any) {
        const url = `${this.API_CDU}/user/${userId}/roles`;

        try {
            return await this.putReturnObject(url, userRoles);
        } catch (error: any) {
            throw new Error(error);
        }
    } // saveUserRoles

    async peopleById(peopleId: number) {
        const url = `${this.API_CDU}/people/${peopleId}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // peopleById

    async peopleByUser(userId: number) {
        const url = `${this.API_CDU}/people/user/${userId}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // peopleById

    async peopleByRegistration(registry: string) {
        const url = `${this.API_CDU}/people/registration/${registry}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // peopleByRegistration

    async peopleFilter(filter?: TFilter) {
        const url = `${this.API_CDU}/people/filter`;

        console.group('Payload - People Filter');
        try {
            console.log({ url, filter });
            const resultObject = await this.postReturnObject(url, { filter });
            console.log(resultObject);
            console.groupEnd();
            return resultObject;
        } catch (error: any) {
            console.log(error);
            console.groupEnd();
            throw new Error(error);
        }
    } // peopleFilter

    async createPeople(people: any) {
        const url = `${this.API_CDU}/people/create`;

        try {
            return await this.postReturnObject(url, people);
        } catch (error: any) {
            throw new Error(error);
        }
    } // createPeople

    async savePeople(peopleId: number, people: any) {
        const url = `${this.API_CDU}/people/save/${peopleId}`;

        try {
            return await this.putReturnObject(url, people);
        } catch (error: any) {
            throw new Error(error);
        }
    } // savePeople

    async peopleInPartner(partnerId: number) {
        const url = `${this.API_CDU}/people/partner/${partnerId}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // peopleInPartner

    async companyById(companyId: number) {
        const url = `${this.API_CDU}/company/${companyId}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // companyById

    async companyByRegistration(registry: string) {
        const url = `${this.API_CDU}/company/registration/${registry}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // companyByRegistration

    async companyFilter(attributes: [], where: {}, order: []) {
        const url = `${this.API_CDU}/company/filter`;

        try {
            return await this.postReturnObject(url, {attributes, where, order});
        } catch (error: any) {
            throw new Error(error);
        }
    } // companyFilter

    async societyById(societyId: number) {
        const url = `${this.API_CDU}/society/${societyId}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // societyById

    async societyByCompany(companyId: number) {
        const url = `${this.API_CDU}/society/company/${companyId}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // societyByCompany

    async societyByCompanyAndPeople(companyId: number, peopleId: number) {
        const url = `${this.API_CDU}/society/company/${companyId}/people/${peopleId}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // societyByCompanyAndPeople

    async societyList(attributes: [], where: {}, order: []) {
        const url = `${this.API_CDU}/society/list`;

        try {
            return await this.postReturnObject(url, {attributes, where, order});
        } catch (error: any) {
            throw new Error(error);
        }
    } // societyList

    async societyFilter(attributes: [], where: {}, order: []) {
        const url = `${this.API_CDU}/society/filter`;

        try {
            return await this.postReturnObject(url, {attributes, where, order});
        } catch (error: any) {
            throw new Error(error);
        }
    } // societyFilter

    async saveSociety(societyId: number, society: any) {
        const url = `${this.API_CDU}/society/save/${societyId}`;

        try {
            return await this.putReturnObject(url, society);
        } catch (error: any) {
            throw new Error(error);
        }
    } // saveSociety

    async createSociety(society: any) {
        const url = `${this.API_CDU}/society/create`;

        try {
            return await this.postReturnObject(url, society);
        } catch (error: any) {
            throw new Error(error);
        }
    } // createSociety

    async societyDeleteById(societyId: number, comments: any) {
        let url = '';
        if (isEmpty(comments))
            url = `${this.API_CDU}/society/delete/${societyId}`;
        else
            url = `${this.API_CDU}/society/delete/${societyId}/comments/${comments}`;

        try {
            return await this.deleteReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    }
    
    async employeeById(employeeId: number) {
        const url = `${this.API_CDU}/employee/${employeeId}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // employeeById

    async employeeByCompany(companyId: number) {
        const url = `${this.API_CDU}/employee/company/${companyId}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // employeeByCompany

    async employeeByCompanyAndPeople(companyId: number, peopleId: number) {
        const url = `${this.API_CDU}/employee/company/${companyId}/people/${peopleId}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // employeeByCompanyAndPeople

    async employeeByCredential(credential: string) {
        const url = `${this.API_CDU}/employee/credential/${credential}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // employeeByCompanyAndPeople

    async employeeList(companyId: number, pagination?: TPagination) {
        const url = `${this.API_CDU}/employee/list`;

        console.group('Payload - Employee List');
        try {
            console.log({ url, companyId, limit: pagination?.limit, offset: pagination?.offset });
            const resultObject = await this.postReturnObject(url, { companyId, pagination });
            console.log(resultObject);
            console.groupEnd();
            return resultObject;
        } catch (error: any) {
            console.log(error);
            console.groupEnd();
            throw new Error(error);
        }
    } // employeeList

    async employeeFilter(filter?: TFilter) {
        const url = `${this.API_CDU}/employee/filter`;

        console.group('Payload - Employee Filter');
        try {
            console.log({ url, filter });
            const resultObject = await this.postReturnObject(url, { filter });
            console.log(resultObject);
            console.groupEnd();
            return resultObject;
        } catch (error: any) {
            console.log(error);
            console.groupEnd();
            throw new Error(error);
        }
    } // employeeFilter

    async saveEmployee(employeeId: number, employee: any) {
        const url = `${this.API_CDU}/employee/save/${employeeId}`;

        try {
            return await this.putReturnObject(url, employee);
        } catch (error: any) {
            throw new Error(error);
        }
    } // saveEmployee

    async createEmployee(employee: any) {
        const url = `${this.API_CDU}/employee/create`;

        try {
            return await this.postReturnObject(url, employee);
        } catch (error: any) {
            throw new Error(error);
        }
    } // createEmployee

    async employeeDeleteById(employeeId: number, comments: any) {
        let url = '';
        if (isEmpty(comments))
            url = `${this.API_CDU}/employee/delete/${employeeId}`;
        else
            url = `${this.API_CDU}/employee/delete/${employeeId}/comments/${comments}`;

        try {
            return await this.deleteReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    }

    /* PARAMETER */

    async parameterById(parameterId: number) {
        const url = `${this.API_CDU}/parameter/${parameterId}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // parameterById

    async parameterByCode(companyId: number, code: string) {
        const url = `${this.API_CDU}/parameter/company/${companyId}/code/${code}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // parameterById

    async parameterList(attributes: [], where: {}, order: []) {
        const url = `${this.API_CDU}/parameter/list`;

        try {
            return await this.postReturnObject(url, {attributes, where, order});
        } catch (error: any) {
            throw new Error(error);
        }
    } // parameterList

    async parameterFilter(attributes: [], where: {}, order: []) {
        const url = `${this.API_CDU}/parameter/filter`;

        try {
            return await this.postReturnObject(url, {attributes, where, order});
        } catch (error: any) {
            throw new Error(error);
        }
    } // parameterFilter

    async saveParameter(parameterId: number, parameter: any) {
        const url = `${this.API_CDU}/parameter/save/${parameterId}`;

        try {
            return await this.putReturnObject(url, parameter);
        } catch (error: any) {
            throw new Error(error);
        }
    } // saveParameter

    async createParameter(parameter: any) {
        const url = `${this.API_CDU}/parameter/create`;

        try {
            return await this.postReturnObject(url, parameter);
        } catch (error: any) {
            throw new Error(error);
        }
    } // createParameter

    async parameterDeleteById(parameterId: number, comments: any) {
        let url = '';
        if (isEmpty(comments))
            url = `${this.API_CDU}/parameter/delete/${parameterId}`;
        else
            url = `${this.API_CDU}/parameter/delete/${parameterId}/comments/${comments}`;

        try {
            return await this.deleteReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    }

    
    async addressById(addressId: number) {
        const url = `${this.API_CDU}/address/${addressId}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // addressById

    async addressByUser(userId: number) {
        const url = `${this.API_CDU}/address/user/${userId}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // addressByUser

    /* CATEGORY */
    
    async categoryById(categoryId: number) {
        const url = `${this.API_CDU}/category/${categoryId}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // categoryById

    async categoryList(pagination?: TPagination) {
        const url = `${this.API_CDU}/category/list`;

        console.group('Payload - Category List');
        try {
            console.log({ url, limit: pagination?.limit, offset: pagination?.offset });
            const resultObject = await this.postReturnObject(url, { pagination });
            console.log(resultObject);
            console.groupEnd();
            return resultObject;
        } catch (error: any) {
            console.log(error);
            console.groupEnd();
            throw new Error(error);
        }
    } // categoryList

    async categoryFilter(filter?: TFilter) {
        const url = `${this.API_CDU}/category/filter`;

        console.group('Payload - Category Filter');
        try {
            console.log({ url, filter });
            const resultObject = await this.postReturnObject(url, { filter });
            console.log(resultObject);
            console.groupEnd();
            return resultObject;
        } catch (error: any) {
            console.log(error);
            console.groupEnd();
            throw new Error(error);
        }
    } // categoryFilter

    async saveCategory(categoryId: number, category: any) {
        const url = `${this.API_CDU}/category/save/${categoryId}`;

        try {
            return await this.putReturnObject(url, category);
        } catch (error: any) {
            throw new Error(error);
        }
    } // saveCategory

    async createCategory(category: any) {
        const url = `${this.API_CDU}/category/create`;

        try {
            return await this.postReturnObject(url, category);
        } catch (error: any) {
            throw new Error(error);
        }
    } // createCategory

    async categoryDeleteById(categoryId: number, comments: any) {
        let url = '';
        if (isEmpty(comments))
            url = `${this.API_CDU}/category/delete/${categoryId}`;
        else
            url = `${this.API_CDU}/category/delete/${categoryId}/comments/${comments}`;

        try {
            return await this.deleteReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // categoryDeleteById

    /* ARTIFACT */
    
    async artifactById(artifactId: number) {
        const url = `${this.API_CDU}/artifact/${artifactId}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // artifactById
    
    async artifactBySource(source: string) {
        const url = `${this.API_CDU}/artifact/source/${source}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // artifactBySource
    
    async artifactByIdenty(source: string, identy: string) {
        const url = `${this.API_CDU}/artifact/source/${source}/identy/${identy}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // artifactByIdenty
    
    async artifactByNorm(source: string, identy: string, norm: string) {
        const url = `${this.API_CDU}/artifact/source/${source}/identy/${identy}/norm/${norm}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // artifactByNorm

    async artifactList(attributes: [], where: {}, order: []) {
        const url = `${this.API_CDU}/artifact/list`;

        try {
            return await this.postReturnObject(url, {attributes, where, order});
        } catch (error: any) {
            throw new Error(error);
        }
    } // artifactList

    async artifactFilter(attributes: [], where: {}, order: []) {
        const url = `${this.API_CDU}/artifact/filter`;

        try {
            return await this.postReturnObject(url, {attributes, where, order});
        } catch (error: any) {
            throw new Error(error);
        }
    } // artifactFilter

    async saveArtifact(artifactId: number, artifact: any) {
        const url = `${this.API_CDU}/artifact/save/${artifactId}`;

        try {
            return await this.putReturnObject(url, artifact);
        } catch (error: any) {
            throw new Error(error);
        }
    } // saveArtifact

    async createArtifact(artifact: any) {
        const url = `${this.API_CDU}/artifact/create`;

        try {
            return await this.postReturnObject(url, artifact);
        } catch (error: any) {
            throw new Error(error);
        }
    } // createArtifact

    async artifactDeleteById(artifactId: number, comments?: any) {
        let url = '';
        if (isEmpty(comments))
            url = `${this.API_CDU}/artifact/delete/${artifactId}`;
        else
            url = `${this.API_CDU}/artifact/delete/${artifactId}/comments/${comments}`;

        try {
            return await this.deleteReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // artifactDeleteById

}

// eslint-disable-next-line import/no-anonymous-default-export
export default new CduService();