import React, { useState } from 'react'

import { Button, TextField } from '@mui/material'

import { createTheme, ThemeProvider } from '@mui/material/styles'

import NTSModal from '../../../components/modal/Modal'
import styles from './DeliveryOrderModal.module.scss'

type ModalProps = {
  open: boolean
  handleClose: any
  height: any
  onCancel?: any
  onConfirm?: any
  width: any
  type?: string
  order: any
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#3366FF'
    },
    secondary: {
      main: '#4E5F78'
    },
    mode: 'light'
  },

  typography: {
    fontFamily: ['Montserrat', 'sans-serif'].join(',')
  }
})

const DeliveryOrderModal: React.FC<ModalProps> = ({
  open,
  handleClose,
  height,
  onCancel,
  onConfirm,
  width,
  type,
  order,
}: ModalProps) => {
  const [success, setSuccess] = useState(false)
  const [confirming, setConfirming] = useState(false)
  const [departureRequested, setDepartureRequested] = useState(false)
  const [credential, setCredential] = useState('')

  const cancel = () => {
    onCancel()
    setCredential('')
  }


  const handleConfirmation = () => {
    switch (type) {
      case 'confirm':
        confirm()
        break
      case 'finish':
        confirm()
        break
      default:
        break
    }
  }



  const confirm = async () => {
    if (type === 'cancel') {
      onConfirm()
    }
    
  }

  const handleKeypress = (e: any) => {
    if (e.charCode === 13) {
      handleConfirmation()
    }
  }


  return (
    <ThemeProvider theme={theme}>
      <NTSModal
        open={open}
        name={`Pedido ${order?.orderData.number} - ${order?.orderData.status}`}
        handleClose={handleClose}
        noClose
        width={success ? 500 : width}
        onSuccess={success}
        height={height}
      >
        {success ? (
          <div className="d-flex align-items-center flex-column">
            <h5 className="mt-2 gray-text">{order.orderData.number}</h5>
            <h5 className="gray-text">{order.responsibles[0]}</h5>
          
            {type === 'cancel' && (
              <Button
                color="inherit"
                variant="outlined"
                onClick={() => onConfirm()}
                className={`${styles.submit} mb-2 mt-1`}
                size="large"
              >
                FECHAR
              </Button>
            )}
            {type !== 'cancel' && !departureRequested && (
              <>
                <h6 className="gray-text mt-3 bolder">Data | Hora</h6>
                <h5>
                  {new Date().toLocaleDateString()} -{' '}
                  {new Date().toLocaleTimeString()}
                </h5>
              </>
            )}
          </div>
        ) : (
          <div className={`${styles.content} d-flex align-items-center flex-column`}>
            <div className={styles.order}>
              <h4>Pedido {order?.orderData.number}</h4>
              <h4>{new Date(order?.date).toLocaleDateString()} {new Date(order?.date).toLocaleTimeString()}</h4>
              <div className={styles.orderInfo}>
                <h6>Solicitante:</h6>
                <h6>{order?.responsibles[0]?.ownerName}</h6>
              </div>
              <h5 className={styles.itemsHeader}>Items</h5>
              <div className={styles.items}>
                <div className='d-flex justify-content-between mt-2'>
                  <div className='d-flex flex-column'>
                    <h6>1x Coca-Cola 2L</h6>
                    <p>(Sem gelo)</p>
                  </div>
                  <h6>R$ 9,00</h6>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div className='d-flex flex-column'>
                    <h6>1x X-Burguer</h6>
                    <p>(Carne mal passada)</p>
                  </div>
                  <h6>R$ 15,00</h6>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div className='d-flex flex-column'>
                    <h6>2x Heineken LT</h6>
                    <p>(Sem observação)</p>
                  </div>
                  <h6>R$ 13,80</h6>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  <div className='d-flex flex-column'>
                    <h6>2x Heineken LT</h6>
                    <p>(Sem observação)</p>
                  </div>
                  <h6>R$ 13,80</h6>
                </div>
              </div>
              <h5 className={styles.itemsFooter}>Total: R$ 37,80</h5>
            </div>
            {order?.orderData.status === 'Pendente' && (
              <>
              <h6 className='mt-2'>Digite ou aproxime sua credencial</h6>
              <TextField
                  margin="normal"
                  name="credential"
                  type="text"
                  id="credential"
                  autoFocus
                  variant="standard"
                  autoComplete="off"
                  onChange={e => setCredential(e.target.value)}
                  value={credential}
                  onKeyPress={handleKeypress}
                  label="Credencial"
                  disabled={confirming}
                  color="primary"
                  className="mb-0 mt-0"
                  sx={{ minWidth: 275, maxWidth: 275 }}
                />
              </>

            )}
            <div className="mt-4 justify-content-center d-flex mb-2">
              <Button
                variant="outlined"
                onClick={() => cancel()}
                color="secondary"
                className={`${styles.submit} me-4`}
                disabled={confirming}
                size="large"
              >
                Fechar
              </Button>
              {order?.operator === '-' && order?.orderData.status !== 'Cancelado' && (
                <>
                
                  <Button
                    variant="contained"
                    onClick={() => cancel()}
                    color="error"
                    className={`${styles.submit} me-4`}
                    disabled={
                      credential.length < 5
                    }
                    size="large"
                  >
                    Rejeitar
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => cancel()}
                    color="success"
                    className={`${styles.submit} me-4`}
                    size="large"
                    disabled={
                      credential.length < 5
                    }
                  >
                    Aceitar
                  </Button>
                </>
              )}
              {order?.operator !== '-' && order?.orderData.status !== 'Finalizado' && order?.orderData.status !== 'Cancelado' && (
              <Button
                variant="contained"
                onClick={() => cancel()}
                color="success"
                className={`${styles.submit} me-4`}
                size="large"
                disabled={
                  credential.length < 5
                }
              >
                Finalizar
              </Button>
              )}
            </div>
          </div>
        )}
      </NTSModal>
    </ThemeProvider>
  )
}

DeliveryOrderModal.defaultProps = {
  onCancel: false,
  onConfirm: false,
  type: ''
}

export default DeliveryOrderModal
