import { Tooltip } from "@mui/material";
import { forwardRef } from "react";
import { Button, FormControl } from "react-bootstrap";
import { toLowerCase } from "../utilities/auxiliary-functions";
import { PersonEnum } from "../utilities/constants";
import { phoneMask } from "../utilities/masks";
import { getCategoriesNaval, getGender } from "../utilities/types";
import { isEmpty } from "../utilities/validators";
import PersonIcon from '@mui/icons-material/Person';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import PhoneIcon from '@mui/icons-material/Phone';
import { useTranslateContext } from "../contexts/translate.context";

export const PickerButtom = forwardRef((props: any, ref: any) => (
    <Button className="picker-button" onClick={props.onClick} ref={ref}>
        {props.value}
    </Button>
));

export const PickerInput = forwardRef((props: any, ref: any) => (
    <FormControl className={`picker-input${props.enabled ? "-enabled" : ""}`} type="text" onClick={props.onClick} ref={ref} size={"sm"}
        value={ props.value }
        readOnly
    />
));

export interface IPhonesProps {
    values: any
}

export const Phones: React.FC<IPhonesProps> = (props: IPhonesProps) => {

    const phones: any = [];
    const cellPhones: any = [];

    if (Array.isArray(props.values)) {
        const receivedPhones = props.values.filter((phone: any) => phone?.connection?.length === 10);
        const receivedCellPhones = props.values.filter((phone: any) => phone?.connection?.length > 10);
        receivedPhones?.forEach((phone: any) => {
            phones.push(phoneMask(phone.connection))
        });
        receivedCellPhones?.forEach((phone: any) => {
            cellPhones.push(phoneMask(phone.connection))
        });
    } else {
        if (props.values?.connection?.length === 10)
            phones.push(phoneMask(props.values.connection))
        if (props.values?.connection?.length > 10)
            cellPhones.push(phoneMask(props.values.connection))
    }

    return (
        <div className="d-flex justify-content-center">

            {phones?.length > 0 && (
                <Tooltip title={phones?.join(', ')}>
                    <PhoneIcon />
                </Tooltip>
            )}
            {cellPhones?.length > 0 && (
                <Tooltip title={cellPhones?.join(', ')}>
                    <PhoneIphoneIcon className={phones?.length > 0 ? 'ms-2' : ''} />
                </Tooltip>
            )}
        </div>

    );
}; // Phones

export interface IAddressesProps {
    values: any
}

export const Addresses: React.FC<IPhonesProps> = (props: IPhonesProps) => {

    const { display } = useTranslateContext();
    const addresses = props.values;

    return (
        addresses && addresses?.map((peopleAdress: any, idx: number) => {
            return (
                <span key={idx} className="table-item">
                    {display.label[peopleAdress.norm]} {' - '} {peopleAdress.address.city} {' / '} {peopleAdress.address.state}
                </span>
            )
        })
    );
}; // Addresses

export interface IVesselsProps {
    values: any
}

export const Vessels: React.FC<IVesselsProps> = (props: IVesselsProps) => {

    const vessels = props.values;

    return (
        vessels && vessels?.map((vessel: any, idx: number) => {
            return (
                <span key={idx} className="table-item">
                    {vessel.name}
                </span>
            )
        })
    );
}; // Vessels

export interface IVesselOwnerProps {
    values: any,
    viewname?: any
}

export const VesselOwner: React.FC<IVesselOwnerProps> = (props: IVesselOwnerProps) => {

    const element = props.values?.row?.original || props.values;
    let owners = [] as any;

    const getOwner = (responsible: any): any => {
        let ownerName = '';

        if (responsible.ownerType === PersonEnum.PESSOA_FISICA) {
            if (responsible.owner) {
                if (responsible.owner.name)
                    ownerName = responsible.owner.name;
                else
                    ownerName = responsible.owner.fullname;
            } else if (responsible.ownerName) {
                ownerName = responsible.ownerName;
            } else if (responsible.people)
                ownerName = responsible.people.fullname;
        } else if (responsible.ownerType === PersonEnum.PESSOA_JURIDICA) {
            if (responsible.owner) {
                if (responsible.owner.name)
                    ownerName = responsible.owner.name;
                else
                    ownerName = responsible.owner.socialRason;
            } else if (responsible.ownerName) {
                ownerName = responsible.ownerName;
            } else if (responsible.company)
                ownerName = responsible.company.socialRason;
        }

        return ownerName;
    }

    if (element) {
        let responsibles = null;
        if (element.responsibles) {
            responsibles = element.responsibles;

            for (var v1 = 0; v1 < responsibles.length; v1++) {
                const responsible = responsibles[v1];
                const owner = getOwner(responsible);
                owners[owners.length] = owner;
            }
        } else if (element.owner) {
            owners[owners.length] = element.owner.fullname;
        } else {
            owners[owners.length] = element.ownerName;
        }
    }

    return props.viewname && owners.length === 1 ? (
            <div className="d-flex align-items-center justify-content-center mt-1">
                <span>{owners[0]}</span>
            </div>
        ) : (
            <Tooltip className="isClickable" title={owners?.join(', ')}>
                <div className="d-flex align-items-center justify-content-center mt-1">
                    <PersonIcon className={owners.length > 1 ? "me-2" : ''} />
                    {owners.length > 1 && (
                        <span>{owners.length}</span>
                    )}
                </div>
            </Tooltip>
        );
} // VesselOwner

export interface IVesselOwnerGenderProps {
    values: any
}

export const VesselOwnerGender: React.FC<IVesselOwnerGenderProps> = (props: IVesselOwnerGenderProps) => {

    const { display } = useTranslateContext();
    const element = props.values.row.original;

    let owners = [] as any;

    const getOwner = (responsible: any): any => {
        let owner = '';

        if (responsible.ownerType === PersonEnum.PESSOA_FISICA) {
            if (responsible.owner)
                owner = responsible.owner.gender;
            else if (responsible.people)
                owner = responsible.people.gender;
        } else if (responsible.ownerType === PersonEnum.PESSOA_JURIDICA) {
            if (responsible.owner)
                owner = '';
            else  if (responsible.company)
                owner = '';
        }
        return owner;
    }

    let responsibles = null;
    if (element.responsibles) {
        responsibles = element.responsibles;

        for (var v1 = 0; v1 < responsibles.length; v1++) {
            const responsible = responsibles[v1];
            const owner = getOwner(responsible);
            owners[owners.length] = owner;
        }
    } else {
        const owner = getOwner(element);
        owners[owners.length] = owner;
    }

    return (
        <>
            {owners?.map((owner: any, idx: number) => (
                <div key={idx} className="white-space">{display.label[toLowerCase(getGender(owner).name)]}</div>
            ))}
        </>
    );
} // VesselOwnerGender

export interface IVesselOwnerPhonesProps {
    values: any
}

export const VesselOwnerPhones: React.FC<IVesselOwnerPhonesProps> = (props: IVesselOwnerPhonesProps) => {

    const { display } = useTranslateContext();
    const element = props.values.row.original;

    let owners = [] as any;

    const getOwner = (responsible: any): any => {
        let owner = '';

        if (responsible.ownerType === PersonEnum.PESSOA_FISICA) {
            let phones = null;
            if (responsible.owner)
                phones = responsible.owner.phones;
            else if (responsible.people)
                phones = responsible.people.phones;
                
            owner = phones && phones?.map((phone: any, idx: number) => {
                return (
                    <div key={idx}>
                        {isEmpty(phone.operator) ? phoneMask(phone.connection) : <> {display.label[phone.norm]} {' - '} {phone.operator} {' '} {phoneMask(phone.connection)} </> }
                    </div>
                )
            })
        } else if (responsible.ownerType === PersonEnum.PESSOA_JURIDICA) {
            if (responsible.owner)
                owner = 'I';
            else if (responsible.company)
                owner = ' --- ';
        }
        return owner;
    }

    let responsibles = null;
    if (element.responsibles) {
        responsibles = element.responsibles;

        for (var v1 = 0; v1 < responsibles.length; v1++) {
            const responsible = responsibles[v1];
            const owner = getOwner(responsible);
            owners[owners.length] = owner;
        }
    } else {
        const owner = getOwner(element);
        owners[owners.length] = owner;
    }

    return (
        <>
            {owners?.map((owner: any, idx: number) => (
                <div key={idx}>{owner}</div>
            ))}
        </>
    );
} // VesselOwnerPhones

export interface IDocumentsNauticalCategoryProps {
    values: any
}

export const DocumentsNauticalCategory: React.FC<IDocumentsNauticalCategoryProps> = (props: IDocumentsNauticalCategoryProps) => {

    const { display } = useTranslateContext();
    const element = props.values.row.original;
    const documentsNautical = element.people.documentsNautical;
                
    const viewCategories = (category: string) => {
        const categories = category.split(';');

        return (
            <>
                {categories?.map((category: any, idx: number) => (
                    <div key={`cat-${idx}`} className="white-space">{display.label[toLowerCase(getCategoriesNaval(category).name)]}</div>
                ))}
            </>
        );
    }

    return (
        <>
        {documentsNautical?.map((document: any, idx: number) => (
            <div key={`doc-${idx}`}>{viewCategories(document.category)}</div>
        ))}
        </>
    );
} // DocumentsNauticalCategory

export interface IDocumentsNauticalEnrollmentProps {
    values: any
}

export const DocumentsNauticalEnrollment: React.FC<IDocumentsNauticalEnrollmentProps> = (props: IDocumentsNauticalEnrollmentProps) => {

    const element = props.values.row.original;
    const documentsNautical = element.people.documentsNautical;

    return (
        <>
        {documentsNautical?.map((document: any, idx: number) => (
            <div key={`doc-${idx}`}>{document.enrollment}</div>
        ))}
        </>
    );

} // DocumentsNauticalCategory

export interface IContractOwnerProps {
    values: any
}

export const ContractOwner: React.FC<IContractOwnerProps> = (props: IContractOwnerProps) => {

    const element = props.values.row.original;

    let owners = [] as any;

    // É um pagamento avulso
    if ((element.sourceDocument) && (element.sourceDocument === 'LPF')) {
        if (element.ownerType === "PF") {
            if (element.owner)
                owners[owners.length] = element.owner.fullname;
            else
                owners[owners.length] = ' --- ';
        } else if (element.ownerType === "PJ") {
            if (element.owner)
                owners[owners.length] = element.owner.socialRason;
            else
                owners[owners.length] = ' --- ';
        }
    } else {
        // É um pagamento por contrato
        if (element.ownerType === "PF") {
            if (element.owner)
                owners[owners.length] = element.owner.fullname;
            else
                owners[owners.length] = ' --- ';
        } else if (element.ownerType === "PJ") {
            if (element.owner)
                owners[owners.length] = element.owner.socialRason;
            else
                owners[owners.length] = ' --- ';
        } else {
            let responsibles = null;
            if (element.responsibles) {
                responsibles = element.responsibles;

                for (var v1 = 0; v1 < responsibles.length; v1++) {
                        owners[owners.length] = 'Teste 1';
                }
            } else if (element.vessels) {
                const vessels = element.vessels;
                for (var v2 = 0; v2 < vessels.length; v2++) {
                    const vessel = vessels[v2];

                    responsibles = vessel.responsibles;
                    if (responsibles) {
                        for (var r = 0; r < responsibles.length; r++) {
                            const responsible = responsibles[r];
                            if (responsible) {
                                console.log(responsible);
                                owners[owners.length] = 'Teste 2';
                            }
                        }
                    }
                }
            } else {
                owners[owners.length] = " --- ";
            }
        }        
    }

    return (
        <>
            {owners?.map((owner: any, idx: number) => (
                <div key={idx} className="white-space">{owner}</div>
            ))}
        </>
    );
}
