import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CadastreHeader from "../headers/cadastre.header";
import ProductCadastreForm from "./forms/product.cadastre.form";
import ProductCadastreList from "./lists/product.cadastre.list";
import { useTranslateContext } from "../../contexts/translate.context";

export interface IProductCadastreProps {
    viewOnly?: boolean
}

export const ProductCadastre: React.FC<IProductCadastreProps> = (props: IProductCadastreProps) => {

    const { productId } = useParams();
    const { display } = useTranslateContext();

    const title = display.title.product;
    const description =  '';

    let reloadPage = false;
    const [ isLoading, setLoading ] = useState(false);
    const [ recordSelected, setRecordSelected ] = useState({ param : productId || '', selected : ((productId === 'new') || (productId === '0') || (!Number.isNaN(Number.parseInt(productId ||'')))) });

    function setSelected(isRecord: boolean, recordId: string) {
        setRecordSelected({ param : recordId, selected : isRecord });
    }

    async function initialize() {
    }
    
    useEffect(() => {
        if (!isLoading && !reloadPage) {
            initialize();
            setLoading(true);
        }

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            reloadPage = !reloadPage;
        }
    }, []);
    
    return (
        <div className="page">
            <CadastreHeader title={title} description={description} />
            {(recordSelected.selected) ? (
                <ProductCadastreForm 
                    title={title}
                    productId={recordSelected.param}
                    setRecord={(event: any) => { 
                        event.preventDefault();
        
                        setSelected(false, '0'); 
                    }}
                    viewOnly={props.viewOnly}
                />
            ) : (
                <ProductCadastreList
                    title={title}
                    setRecord={(event: any, recordId: string) => { 
                        event.preventDefault();
        
                        setSelected(true, recordId); 
                    }}
                    viewOnly={props.viewOnly}
                />
            )}
        </div>
    )
}

export default ProductCadastre;
