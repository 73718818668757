import { PropsWithChildren } from "react";
import { Box } from "@mui/material";

import styles from './ResumeBox.module.scss';
import { Image } from "react-bootstrap";

import { useMediaContext } from "../../../contexts/media.context";

export type ResumeDataType = {
    image: string, 
    value: string
}

export interface IResumeBoxCardProps {
    label: string, 
    datas?: ResumeDataType[],
    image?: string,
    value?: string,
    rotating?: boolean
}

export const ResumeBoxCard: React.FC<IResumeBoxCardProps> = (props: IResumeBoxCardProps) => {

    return (
        <Box className={styles.resumeCard} >
            <Box className={styles.resumeCardLabel}>{props.label}</Box>
            <Box className={styles.resumeCardContent}>
                {props.datas ? (
                    props.datas.map((resumeData: ResumeDataType, index: number) => (
                        <Box key={`resume-box-card-group-${index}`} className={styles.resumeCardGroup}>
                            <Image src={resumeData.image} className={styles.resumeCardImage} />
                            <Box className={styles.resumeCardData}>{resumeData.value}</Box>
                        </Box>
                    ))
                ) : (
                    <>
                        {props.image && 
                        <div className={`${props.rotating ? 'rotating': ''}`} >
                            <Image src={props.image} className={styles.resumeCardImage} />
                        </div>}
                        <Box className={styles.resumeCardData}>{props.value}</Box>
                    </>   
                )}
            </Box>
        </Box>
    );
}

export interface IResumeBoxProps {
    
}

export const ResumeBox: React.FC<PropsWithChildren<IResumeBoxProps>> = ({ children }) => {

    const { isPortrait, isTablet } = useMediaContext();

    return (
        <Box className={`${styles.resumeBox} ${isTablet && isPortrait ? 'flex-wrap' : 'flex-row'}`}>{children}</Box>
    );
}
