import env from "react-dotenv";
import { ServiceCommon } from "./service.common";
import { isEmpty } from "../utilities/validators";
import { TFilter } from "../types/FilterType";
import { TPagination } from "../types/PaginationType";

class LclService extends ServiceCommon {
    
    API_LCL = env.API_LCL;

    constructor() {
        super('LclService');
    }

    /* PARTNER */

    async partnerById(partnerId: number) {
        const url = `${this.API_LCL}/partner/${partnerId}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // partnerById

    async partnerList(pagination?: TPagination) {
        const url = `${this.API_LCL}/partner/list`;

        console.group('Payload - Partner List');
        try {
            console.log({ url, limit: pagination?.limit, offset: pagination?.offset });
            const resultObject = await this.postReturnObject(url, { pagination });
            console.log(resultObject);
            console.groupEnd();
            return resultObject;
        } catch (error: any) {
            console.log(error);
            console.groupEnd();
            throw new Error(error);
        }
    } // partnerList

    async partnerFilter(filter?: TFilter) {
        const url = `${this.API_LCL}/partner/filter`;

        console.group('Payload - Partner Filter');
        try {
            console.log({ url, filter });
            const resultObject = await this.postReturnObject(url, { filter });
            console.log(resultObject);
            console.groupEnd();
            return resultObject;
        } catch (error: any) {
            console.log(error);
            console.groupEnd();
            throw new Error(error);
        }
    } // partnerFilter

    async savePartner(partnerId: number, partner: any) {
        const url = `${this.API_LCL}/partner/save/${partnerId}`;

        try {
            return await this.putReturnObject(url, partner);
        } catch (error: any) {
            throw new Error(error);
        }
    } // savePartner

    async createPartner(partner: any) {
        const url = `${this.API_LCL}/partner/create`;

        try {
            return await this.postReturnObject(url, partner);
        } catch (error: any) {
            throw new Error(error);
        }
    }

    async partnerDeleteById(partnerId: number, comments: any) {
        let url = '';
        if (isEmpty(comments))
            url = `${this.API_LCL}/partner/delete/${partnerId}`;
        else
            url = `${this.API_LCL}/partner/delete/${partnerId}/comments/${comments}`;

        try {
            return await this.deleteReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // partnerDeleteById

    async partnersUser(userId: number) {
        const url = `${this.API_LCL}/partner/user/${userId}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // partnersUser

    async savePartnersUser(userId: number, userPartners: any) {
        const url = `${this.API_LCL}/partner/user/${userId}/save`;

        try {
            return await this.putReturnObject(url, userPartners);
        } catch (error: any) {
            throw new Error(error);
        }
    } // savePartnersUser

    /* PARTNER CONTRACTS */

    async partnerContractById(contractId: number) {
        const url = `${this.API_LCL}/partner/contract/${contractId}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // partnerContractById

    async partnerContractFilter(attributes: [], where: {}, order: []) {
        const url = `${this.API_LCL}/partner/contract/filter`;

        try {
            return await this.postReturnObject(url, {attributes, where, order});
        } catch (error: any) {
            throw new Error(error);
        }
    } // partnerContractFilter

    async savePartnerContract(type: string, contractId: number, contract: any) {
        const url = `${this.API_LCL}/partner/contract/${type}/save/${contractId}`;

        try {
            return await this.putReturnObject(url, contract);
        } catch (error: any) {
            throw new Error(error);
        }
    } // savePartnerContract

    async createPartnerContract(type: string, contract: any) {
        const url = `${this.API_LCL}/partner/contract/${type}/create`;

        try {
            return await this.postReturnObject(url, contract);
        } catch (error: any) {
            throw new Error(error);
        }
    } // createPartnerContract

    async partnerContractDeleteById(movementVesselId: number, comments: any) {
        let url = '';
        if (isEmpty(comments))
            url = `${this.API_LCL}/partner/contract/delete/${movementVesselId}`;
        else
            url = `${this.API_LCL}/partner/contract/delete/${movementVesselId}/comments/${comments}`;

        try {
            return await this.deleteReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // partnerContractDeleteById

    /* MEMBER */
    
    async memberById(memberId: number) {
        const url = `${this.API_LCL}/member/${memberId}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // memberById

    async memberList(partnerId: number, pagination?: TPagination) {
        const url = `${this.API_LCL}/member/list`;

        console.group('Payload - Member List');
        try {
            console.log({ url, partnerId, limit: pagination?.limit, offset: pagination?.offset });
            const resultObject = await this.postReturnObject(url, { partnerId, pagination });
            console.log(resultObject);
            console.groupEnd();
            return resultObject;
        } catch (error: any) {
            console.log(error);
            console.groupEnd();
            throw new Error(error);
        }
    } // memberList

    async memberFilter(filter?: TFilter) {
        const url = `${this.API_LCL}/member/filter`;

        console.group('Payload - Member Filter');
        try {
            console.log({ url, filter });
            const resultObject = await this.postReturnObject(url, { filter });
            console.log(resultObject);
            console.groupEnd();
            return resultObject;
        } catch (error: any) {
            console.log(error);
            console.groupEnd();
            throw new Error(error);
        }
    } // memberFilter

    async saveMember(memberId: number, member: any) {
        const url = `${this.API_LCL}/member/save/${memberId}`;

        try {
            return await this.putReturnObject(url, member);
        } catch (error: any) {
            throw new Error(error);
        }
    } // saveMember

    async createMember(member: any) {
        const url = `${this.API_LCL}/member/create`;

        try {
            return await this.postReturnObject(url, member);
        } catch (error: any) {
            throw new Error(error);
        }
    } // createMember

    async memberDeleteById(memberId: number, comments: any) {
        let url = '';
        if (isEmpty(comments))
            url = `${this.API_LCL}/member/delete/${memberId}`;
        else
            url = `${this.API_LCL}/member/delete/${memberId}/comments/${comments}`;

        try {
            return await this.deleteReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    }
    
    /* VESSEL */

    async vesselImageById(vesselId: number) {
        const url = `${this.API_LCL}/vessel/image/${vesselId}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // vesselById

    async vesselById(vesselId: number) {
        const url = `${this.API_LCL}/vessel/${vesselId}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // vesselById

    async vesselList(partnerId: number, pagination?: TPagination) {
        const url = `${this.API_LCL}/vessel/list`;

        console.group('Payload - Vessel List');
        try {
            console.log({ url, partnerId, limit: pagination?.limit, offset: pagination?.offset });
            const resultObject = await this.postReturnObject(url, { partnerId, pagination });
            console.log(resultObject);
            console.groupEnd();
            return resultObject;
        } catch (error: any) {
            console.log(error);
            console.groupEnd();
            throw new Error(error);
        }
    } // vesselList

    async vesselFilter(filter?: TFilter) {
        const url = `${this.API_LCL}/vessel/filter`;

        console.group('Payload - Vessel Filter');
        try {
            console.log({ url, filter });
            const resultObject = await this.postReturnObject(url, { filter });
            console.log(resultObject);
            console.groupEnd();
            return resultObject;
        } catch (error: any) {
            console.log(error);
            console.groupEnd();
            throw new Error(error);
        }
    } // vesselFilter

    async findByEnrollment(enrollment: string){
        const url = `${this.API_LCL}/vessel/enrollment/${enrollment}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    }

    async vesselInCard(enrollment: string, isComplete: boolean) {
        let url = '';
        if (isComplete)
            url = `${this.API_LCL}/vessel/card/${enrollment}/complete/true`;
        else
            url = `${this.API_LCL}/vessel/card/${enrollment}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    }

    async saveVessel(vesselId: number, vessel: any) {
        const url = `${this.API_LCL}/vessel/save/${vesselId}`;

        try {
            return await this.putReturnObject(url, vessel);
        } catch (error: any) {
            throw new Error(error);
        }
    } // saveVessel

    async createVessel(vessel: any) {
        const url = `${this.API_LCL}/vessel/create`;

        try {
            return await this.postReturnObject(url, vessel);
        } catch (error: any) {
            throw new Error(error);
        }
    } // createVessel

    async vesselDeleteById(vesselId: number, comments: any) {
        let url = '';
        if (isEmpty(comments))
            url = `${this.API_LCL}/vessel/delete/${vesselId}`;
        else
            url = `${this.API_LCL}/vessel/delete/${vesselId}/comments/${comments}`;

        try {
            return await this.deleteReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // vesselDeleteById

    async saveVesselImage(imageId: number, image: any) {
        const url = `${this.API_LCL}/vessel_image/save/${imageId}`;

        try {
            return await this.putReturnObject(url, image);
        } catch (error: any) {
            throw new Error(error);
        }
    }

    async deleteVesselImage(imageId: number) {
        const url = `${this.API_LCL}/vessel_image/delete/${imageId}`;

        try {
            return await this.deleteReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    }

    async createVesselImage(image: any) {
        const url = `${this.API_LCL}/vessel_image/create`;

        try {
            return await this.postReturnObject(url, image);
        } catch (error: any) {
            throw new Error(error);
        }
    }

    /* VESSEL-RESPONSIBLE */

    async vesselResponsibleById(vesselResponsibleId: number) {
        const url = `${this.API_LCL}/vessel_responsible/${vesselResponsibleId}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // vesselResponsibleById

    async vesselResponsibleFilter(attributes: [], where: {}, order: []) {
        const url = `${this.API_LCL}/vessel_responsible/filter`;

        try {
            return await this.postReturnObject(url, {attributes, where, order});
        } catch (error: any) {
            throw new Error(error);
        }
    } // vesselResponsibleFilter

    async saveVesselResponsible(vesselResponsibleId: number, responsible: any) {
        const url = `${this.API_LCL}/vessel_responsible/save/${vesselResponsibleId}`;

        try {
            return await this.putReturnObject(url, responsible);
        } catch (error: any) {
            throw new Error(error);
        }
    } // saveVesselResponsible

    async createVesselResponsible(responsible: any) {
        const url = `${this.API_LCL}/vessel_responsible/create`;

        try {
            return await this.postReturnObject(url, responsible);
        } catch (error: any) {
            throw new Error(error);
        }
    } // createVesselResponsible

    async vesselResponsibleDeleteById(vesselResponsibleId: number, comments: any) {
        let url = '';
        if (isEmpty(comments))
            url = `${this.API_LCL}/vessel_responsible/delete/${vesselResponsibleId}`;
        else
            url = `${this.API_LCL}/vessel_responsible/delete/${vesselResponsibleId}/comments/${comments}`;

        try {
            return await this.deleteReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // vesselResponsibleDeleteById

    /* VESSEL-CONDUCTOR */

    async vesselConductorById(vesselConductorId: number) {
        const url = `${this.API_LCL}/vessel_conductor/${vesselConductorId}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // vesselConductorById

    async vesselConductorFilter(attributes: [], where: {}, order: []) {
        const url = `${this.API_LCL}/vessel_conductor/filter`;

        try {
            return await this.postReturnObject(url, {attributes, where, order});
        } catch (error: any) {
            throw new Error(error);
        }
    } // vesselConductorFilter

    async saveVesselConductor(vesselConductorId: number, conductor: any) {
        const url = `${this.API_LCL}/vessel_conductor/save/${vesselConductorId}`;

        try {
            return await this.putReturnObject(url, conductor);
        } catch (error: any) {
            throw new Error(error);
        }
    } // saveVesselConductor

    async createVesselConductor(conductor: any) {
        const url = `${this.API_LCL}/vessel_conductor/create`;

        try {
            return await this.postReturnObject(url, conductor);
        } catch (error: any) {
            throw new Error(error);
        }
    } // createVesselConductor

    async vesselConductorDeleteById(vesselConductorId: number, comments: any) {
        let url = '';
        if (isEmpty(comments))
            url = `${this.API_LCL}/vessel_conductor/delete/${vesselConductorId}`;
        else
            url = `${this.API_LCL}/vessel_conductor/delete/${vesselConductorId}/comments/${comments}`;

        try {
            return await this.deleteReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // vesselConductorDeleteById

    /* VESSEL-TRACKER */

    async createTracker(tracker: any) {
        const url = `${this.API_LCL}/vessel_tracker/create`;

        try {
            return await this.postReturnObject(url, tracker);
        } catch (error: any) {
            throw new Error(error);
        }
    } // createTracker

    async saveTracker(trackerId: number, tracker: any) {
        const url = `${this.API_LCL}/vessel_tracker/save/${trackerId}`;
        try {
            return await this.putReturnObject(url, tracker);
        } catch (error: any) {
            throw new Error(error);
        }
    } // saveTracker

    async deleteTracker(trackerId: number, comments: any) {
        let url = '';
        if (isEmpty(comments))
            url = `${this.API_LCL}/vessel_tracker/delete/${trackerId}`;
        else
            url = `${this.API_LCL}/vessel_tracker/delete/${trackerId}/comments/${comments}`;

        try {
            return await this.deleteReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // deleteTracker

    
    /* VESSEL-ARTIFACT */

    async vesselArtifactById(vesselArtifactId: number) {
        const url = `${this.API_LCL}/vessel_artifact/${vesselArtifactId}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // vesselArtifactById

    async vesselArtifactByVessel(vesselArtifactId: number) {
        const url = `${this.API_LCL}/vessel_artifact/vessel/${vesselArtifactId}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // vesselArtifactByVessel

    async vesselArtifactBySource(source: string, vesselArtifactId: number) {
        const url = `${this.API_LCL}/vessel_artifact/source/${source}/vessel/${vesselArtifactId}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // vesselArtifactBySource
    
    async vesselArtifactFilter(attributes: [], where: {}, order: []) {
        const url = `${this.API_LCL}/vessel_artifact/filter`;

        try {
            return await this.postReturnObject(url, {attributes, where, order});
        } catch (error: any) {
            throw new Error(error);
        }
    } // vesselArtifactFilter

    async saveVesselArtifact(vesselArtifactId: number, artifact: any) {
        const url = `${this.API_LCL}/vessel_artifact/save/${vesselArtifactId}`;

        try {
            return await this.putReturnObject(url, artifact);
        } catch (error: any) {
            throw new Error(error);
        }
    } // saveVesselArtifact

    async createVesselArtifact(artifact: any) {
        const url = `${this.API_LCL}/vessel_artifact/create`;

        try {
            return await this.postReturnObject(url, artifact);
        } catch (error: any) {
            throw new Error(error);
        }
    } // createVesselArtifact

    async vesselArtifactDeleteById(vesselArtifactId: number) {
        const url = `${this.API_LCL}/vessel_artifact/delete/${vesselArtifactId}`;

        try {
            return await this.deleteReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // vesselArtifactDeleteById

    async downloadArtifactVessel(fileId: string) {
        return `${this.API_LCL}/vessel_artifact/download/${fileId}`;
    }

    /* VESSEL-MAINTENANCE */

    async vesselMaintenanceById(vesselMaintenanceId: number) {
        const url = `${this.API_LCL}/vessel_maintenance/${vesselMaintenanceId}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // vesselMaintenanceById

    async vesselMaintenanceByVessel(vesselId: number) {
        const url = `${this.API_LCL}/vessel_maintenance/vessel/${vesselId}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // vesselMaintenanceByVessel

    async vesselMaintenanceByMovementVessel(movementVesselId: number) {
        const url = `${this.API_LCL}/vessel_maintenance/movement_vessel/${movementVesselId}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // vesselMaintenanceByMovementVessel

    async vesselMaintenanceFilter(filter?: TFilter) {
        const url = `${this.API_LCL}/vessel_maintenance/filter`;

        console.group('Payload - Maintenance Filter');
        try {
            console.log({ url, filter });
            const resultObject = await this.postReturnObject(url, { filter });
            console.log(resultObject);
            console.groupEnd();
            return resultObject;
        } catch (error: any) {
            console.log(error);
            console.groupEnd();
            throw new Error(error);
        }
    } // vesselMaintenanceFilter

    async saveVesselMaintenance(vesselMaintenanceId: number, maintenance: any) {
        const url = `${this.API_LCL}/vessel_maintenance/save/${vesselMaintenanceId}`;

        try {
            return await this.putReturnObject(url, maintenance);
        } catch (error: any) {
            throw new Error(error);
        }
    } // saveVesselMaintenance

    async createVesselMaintenance(maintenance: any) {
        const url = `${this.API_LCL}/vessel_maintenance/create`;

        try {
            return await this.postReturnObject(url, maintenance);
        } catch (error: any) {
            throw new Error(error);
        }
    } // createVesselMaintenance

    async vesselMaintenanceDeleteById(vesselMaintenanceId: number, comments: any) {
        let url = '';
        if (isEmpty(comments))
            url = `${this.API_LCL}/vessel_maintenance/delete/${vesselMaintenanceId}`;
        else
            url = `${this.API_LCL}/vessel_maintenance/delete/${vesselMaintenanceId}/comments/${comments}`;

        try {
            return await this.deleteReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // vesselMaintenanceDeleteById

    async vesselMaintenanceStatus(vesselMaintenanceId: number, credential: string, status: string, order?: any) {
        const url = `${this.API_LCL}/vessel_maintenance/status/${vesselMaintenanceId}`;

        try {
            return await this.putReturnObject(url, { credential, status, order });
        } catch (error: any) {
            throw new Error(error);
        }
    } // vesselMaintenanceForAwaiting

    /* VESSEL-SUPPLY */

    async vesselSupplyById(vesselSupplyId: number) {
        const url = `${this.API_LCL}/vessel_supply/${vesselSupplyId}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // vesselSupplyById

    async vesselSupplyByVessel(vesselId: number) {
        const url = `${this.API_LCL}/vessel_supply/vessel/${vesselId}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // vesselSupplyByVessel

    async vesselSupplyFilter(filter?: TFilter) {
        const url = `${this.API_LCL}/vessel_supply/filter`;

        console.group('Payload - Supply Filter');
        try {
            console.log({ url, filter });
            const resultObject = await this.postReturnObject(url, { filter });
            console.log(resultObject);
            console.groupEnd();
            return resultObject;
        } catch (error: any) {
            console.log(error);
            console.groupEnd();
            throw new Error(error);
        }
    } // vesselSupplyFilter
 
    async saveVesselSupply(vesselSupplyId: number, supply: any) {
        const url = `${this.API_LCL}/vessel_supply/save/${vesselSupplyId}`;

        try {
            return await this.putReturnObject(url, supply);
        } catch (error: any) {
            throw new Error(error);
        }
    } // saveVesselSupply

    async createVesselSupply(supply: any) {
        const url = `${this.API_LCL}/vessel_supply/create`;

        try {
            return await this.postReturnObject(url, supply);
        } catch (error: any) {
            throw new Error(error);
        }
    } // createVesselSupply

    async vesselSupplyDeleteById(vesselSupplyId: number, comments: any) {
        let url = '';
        if (isEmpty(comments))
            url = `${this.API_LCL}/vessel_supply/delete/${vesselSupplyId}`;
        else
            url = `${this.API_LCL}/vessel_supply/delete/${vesselSupplyId}/comments/${comments}`;

        try {
            return await this.deleteReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // vesselSupplyDeleteById

    /* MOVEMENT-VESSEL */
    async movementVesselById(movementVesselId: number) {
        const url = `${this.API_LCL}/movement_vessel/${movementVesselId}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // movementVesselById

    async movementVesselList(partnerId: number, pagination?: TPagination) {
        const url = `${this.API_LCL}/movement_vessel/list`;

        console.group('Payload - Movement List');
        try {
            console.log({ url, partnerId, limit: pagination?.limit, offset: pagination?.offset });
            const resultObject = await this.postReturnObject(url, { partnerId, pagination });
            console.log(resultObject);
            console.groupEnd();
            return resultObject;
        } catch (error: any) {
            console.log(error);
            console.groupEnd();
            throw new Error(error);
        }
    } // movementVesselList

    async movementVesselFilter(filter?: TFilter) {
        const url = `${this.API_LCL}/movement_vessel/filter`;

        console.group('Payload - Movement Filter');
        try {
            console.log({ url, filter });
            const resultObject = await this.postReturnObject(url, { filter });
            console.log(resultObject);
            console.groupEnd();
            return resultObject;
        } catch (error: any) {
            console.log(error);
            console.groupEnd();
            throw new Error(error);
        }
    } // movementVesselFilter

    async saveMovementVessel(movementVesselId: number, movementVessel: any) {
        const url = `${this.API_LCL}/movement_vessel/save/${movementVesselId}`;

        try {
            return await this.putReturnObject(url, movementVessel);
        } catch (error: any) {
            throw new Error(error);
        }
    } // saveMovementVessel

    async createMovementVessel(movementVessel: any) {
        const url = `${this.API_LCL}/movement_vessel/create`;

        try {
            return await this.postReturnObject(url, movementVessel);
        } catch (error: any) {
            throw new Error(error);
        }
    } // createMovementVessel

    async movementVesselDeleteById(movementVesselId: number, comments: any) {
        let url = '';
        if (isEmpty(comments))
            url = `${this.API_LCL}/movement_vessel/delete/${movementVesselId}`;
        else
            url = `${this.API_LCL}/movement_vessel/delete/${movementVesselId}/comments/${comments}`;

        try {
            return await this.deleteReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // movementVesselDeleteById

    async movementMove(movementVesselId: number, data: any) {
        const url = `${this.API_LCL}/movement_vessel/move/${movementVesselId}`;

        try {
            return await this.putReturnObject(url, data);
        } catch (error: any) {
            throw new Error(error);
        }
    } // movementMove
    
    async movementUpdateCheckInByLocator(locator: string, checkIn: string, registration: string) {
        const url = `${this.API_LCL}/movement_vessel/checkin/${locator}`;

        try {
            return await this.putReturnObject(url, { checkIn, registration });
        } catch (error: any) {
            throw new Error(error);
        }
    }

    async movementUpdateMoveByLocator(locator: string, credential: string, move: number, status: number) {
        const url = `${this.API_LCL}/movement_vessel/move/locator/${locator}`;

        try {
            return await this.putReturnObject(url, { credential, move, status });
        } catch (error: any) {
            throw new Error(error);
        }
    }

    /* FINANCIAL CONTRACTS */

    async contractById(contractId: number) {
        const url = `${this.API_LCL}/contract/${contractId}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // contractById

    async contractFilter(attributes: [], where: {}, order: []) {
        const url = `${this.API_LCL}/contract/filter`;

        try {
            return await this.postReturnObject(url, {attributes, where, order});
        } catch (error: any) {
            throw new Error(error);
        }
    } // contractFilter

    async saveContract(contractId: number, contract: any) {
        const url = `${this.API_LCL}/contract/save/${contractId}`;

        try {
            return await this.putReturnObject(url, contract);
        } catch (error: any) {
            throw new Error(error);
        }
    } // saveContract

    async createContract(contract: any) {
        const url = `${this.API_LCL}/contract/create`;

        try {
            return await this.postReturnObject(url, contract);
        } catch (error: any) {
            throw new Error(error);
        }
    } // createContract

    async contractDeleteById(movementVesselId: number, comments: any) {
        let url = '';
        if (isEmpty(comments))
            url = `${this.API_LCL}/contract/delete/${movementVesselId}`;
        else
            url = `${this.API_LCL}/contract/delete/${movementVesselId}/comments/${comments}`;

        try {
            return await this.deleteReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // contractDeleteById

    async contractResponsibleDeleteById(contractId: number, ownerType: string, ownerId: number) {
        const url = `${this.API_LCL}/contract/delete/${contractId}/ownerType/${ownerType}/ownerId/${ownerId}`;

        try {
            return await this.deleteReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // contractResponsibleDeleteById

    async listContracts(partnerId: number, ownerType?: string, ownerId?: number) {
        const url = `${this.API_LCL}/contract/list`;
        
        console.group('Payload - Contract List');
        try {
            console.log({ url, partnerId });
            const resultObject = await this.postReturnObject(url, {partnerId, ownerType, ownerId});
            console.log(resultObject);
            console.groupEnd();
            return resultObject;
        } catch (error: any) {
            console.log(error);
            console.groupEnd();
            throw new Error(error);
        }
    } // vesselWithActiveContract

    async vesselWithoutActiveContract(partnerId: number, ownerType?: string, ownerId?: number) {
        const url = `${this.API_LCL}/contract/vessel_without_active_contract`;

        try {
            return await this.postReturnObject(url, { partnerId, ownerType, ownerId });
        } catch (error: any) {
            throw new Error(error);
        }
    } // vesselWithActiveContract

    async vesselWithContract(partnerId: number) {
        const url = `${this.API_LCL}/contract/vessel_with_contract`;

        try {
            return await this.postReturnObject(url, { partnerId });
        } catch (error: any) {
            throw new Error(error);
        }
    } // vesselsWithoutContracts

    async vesselsWithoutContracts(partnerId: number, ownerType?: string, ownerId?: number) {
        const url = `${this.API_LCL}/contract/vessels_without_contracts`;

        try {
            return await this.postReturnObject(url, { partnerId, ownerType, ownerId });
        } catch (error: any) {
            throw new Error(error);
        }
    } // vesselsWithoutContracts

    async contractsInProgress(partnerId: number, ownerType?: string, ownerId?: number) {
        const url = `${this.API_LCL}/contract/contracts_in_progress`;

        try {
            return await this.postReturnObject(url, { partnerId, ownerType, ownerId });
        } catch (error: any) {
            throw new Error(error);
        }
    } // contractsInProgress

    async contractsActive(partnerId: number, ownerType?: string, ownerId?: number) {
        const url = `${this.API_LCL}/contract/contracts_active`;

        try {
            return await this.postReturnObject(url, { partnerId, ownerType, ownerId });
        } catch (error: any) {
            throw new Error(error);
        }
    } // contractsActive

    async contractsManager(partnerId: number) {
        const url = `${this.API_LCL}/contract/contracts_manager`;

        console.group('Payload - Contracts Manager');
        try {
            console.log({ url, partnerId });
            const resultObject = await this.postReturnObject(url, { partnerId });
            console.log(resultObject);
            console.groupEnd();
            return resultObject;
        } catch (error: any) {
            console.log(error);
            console.groupEnd();
            throw new Error(error);
        }
    } // contractsManager

    async contractsCustomer(partnerId: number) {
        const url = `${this.API_LCL}/contract/contracts_customer`;

        console.group('Payload - Contracts Customer');
        try {
            console.log({ url, partnerId });
            const resultObject = await this.postReturnObject(url, { partnerId });
            console.log(resultObject);
            console.groupEnd();
            return resultObject;
        } catch (error: any) {
            console.log(error);
            console.groupEnd();
            throw new Error(error);
        }
    } // contractsCustomer
    
    /* FINANCIAL LAUNCHER */

    async launcherById(launcherId: number) {
        const url = `${this.API_LCL}/launcher/${launcherId}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // launcherById

    async launcherList(partnerId: number, pagination?: TPagination) {
        const url = `${this.API_LCL}/launcher/list`;

        console.group('Payload - Launcher List');
        try {
            console.log({ url, partnerId, limit: pagination?.limit, offset: pagination?.offset });
            const resultObject = await this.postReturnObject(url, { partnerId, pagination });
            console.log(resultObject);
            console.groupEnd();
            return resultObject;
        } catch (error: any) {
            console.log(error);
            console.groupEnd();
            throw new Error(error);
        }
    } // launcherList

    async launcherFilter(filter?: TFilter) {
        const url = `${this.API_LCL}/launcher/filter`;

        console.group('Payload - Launcher Filter');
        try {
            console.log({ url, filter });
            const resultObject = await this.postReturnObject(url, { filter });
            console.log(resultObject);
            console.groupEnd();
            return resultObject;
        } catch (error: any) {
            console.log(error);
            console.groupEnd();
            throw new Error(error);
        }
    } // launcherFilter

    async saveLauncher(launcherId: number, launcher: any) {
        const url = `${this.API_LCL}/launcher/save/${launcherId}`;

        try {
            return await this.putReturnObject(url, launcher);
        } catch (error: any) {
            throw new Error(error);
        }
    } // saveLauncher

    async createLauncher(launcher: any) {
        const url = `${this.API_LCL}/launcher/create`;

        try {
            return await this.postReturnObject(url, launcher);
        } catch (error: any) {
            throw new Error(error);
        }
    } // createLauncher

    async launcherDeleteById(launcherId: number, comments: any) {
        let url = '';
        if (isEmpty(comments))
            url = `${this.API_LCL}/launcher/delete/${launcherId}`;
        else
            url = `${this.API_LCL}/launcher/delete/${launcherId}/comments/${comments}`;

        try {
            return await this.deleteReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // launcherDeleteById

    /* BILLET 
    async createBillet(launcherId: number) {
        const url = `${this.API_LCL}/launcher/billet/create/${launcherId}`;

        try {
            return await this.postReturnObject(url, []);
        } catch (error: any) {
            throw new Error(error);
        }
    } // createLauncher

    async detailBillet(chargeId: number) {
        const url = `${this.API_LCL}/launcher/billet/detail/${chargeId}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // createLauncher
    */
    /* VESSEL */

    async vesselByEnrollment(enrollment: string) {
        const url = `${this.API_LCL}/vessel/${enrollment}/card`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    }

    async monthlyPayment(partnerId: number, dateStart: string, dateEnd: string) {
        const url = `${this.API_LCL}/view/${partnerId}/monthly_payment`;

        try {
            return await this.postReturnObject(url, {dateStart, dateEnd});
        } catch (error: any) {
            throw new Error(error);
        }
    }

    async monthlyPaymentPerPerson(partnerId: number) {
        const url = `${this.API_LCL}/view/${partnerId}/monthly_payment_per_person`;

        try {
            return await this.postReturnObject(url, []);
        } catch (error: any) {
            throw new Error(error);
        }
    }

    async monthlyPaymentPerForm(partnerId: number, dateStart: string, dateEnd: string) {
        const url = `${this.API_LCL}/view/${partnerId}/monthly_payment_per_form`;

        try {
            return await this.postReturnObject(url, {dateStart, dateEnd});
        } catch (error: any) {
            throw new Error(error);
        }
    }

    async monthlyPaymentPerAccompany(partnerId: number, dateStart: string, dateEnd: string) {
        const url = `${this.API_LCL}/view/${partnerId}/monthly_payment_per_accompany`;

        try {
            return await this.postReturnObject(url, {dateStart, dateEnd});
        } catch (error: any) {
            throw new Error(error);
        }
    }

    async hourlyPrediction(partnerId: number, dateStart: string, days: number) {
        const url = `${this.API_LCL}/view/${partnerId}/hourly_prediction`;

        try {
            return await this.postReturnObject(url, {dateStart, days});
        } catch (error: any) {
            throw new Error(error);
        }
    }

    async serviceCenter(partnerId: number, dateStart: string, days: number) {
        const url = `${this.API_LCL}/view/${partnerId}/service_center`;

        try {
            return await this.postReturnObject(url, {dateStart, days});
        } catch (error: any) {
            throw new Error(error);
        }
    }

    async stock(partnerId: number, product: string, date: string) {
        const url = `${this.API_LCL}/view/${partnerId}/stock`;

        try {
            return await this.postReturnObject(url, { product, date });
        } catch (error: any) {
            throw new Error(error);
        }
    }

    /* BOOKCASE */

    async bookcaseById(bookcaseId: number) {
        const url = `${this.API_LCL}/bookcase/${bookcaseId}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // bookcaseById
    
    async bookcaseByVessel(vesselId: number) {
        const url = `${this.API_LCL}/bookcase/vessel/${vesselId}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // bookcaseById

    async bookcaseList(pagination?: TPagination) {
        const url = `${this.API_LCL}/bookcase/list`;

        console.group('Payload - Bookcase List');
        try {
            console.log({ url, limit: pagination?.limit, offset: pagination?.offset });
            const resultObject = await this.postReturnObject(url, { pagination });
            console.log(resultObject);
            console.groupEnd();
            return resultObject;
        } catch (error: any) {
            console.log(error);
            console.groupEnd();
            throw new Error(error);
        }
    } // bookcaseFilter

    async bookcaseFilter(filter: TFilter) {
        const url = `${this.API_LCL}/bookcase/filter`;

        console.group('Payload - Bookcase Filter');
        try {
            console.log({ url, filter });
            const resultObject = await this.postReturnObject(url, { filter });
            console.log(resultObject);
            console.groupEnd();
            return resultObject;
        } catch (error: any) {
            console.log(error);
            console.groupEnd();
            throw new Error(error);
        }
    } // bookcaseFilter

    async saveBookcase(bookcaseId: number, bookcase: any) {
        const url = `${this.API_LCL}/bookcase/save/${bookcaseId}`;

        try {
            return await this.putReturnObject(url, bookcase);
        } catch (error: any) {
            throw new Error(error);
        }
    } // saveBookcase

    async createBookcase(bookcase: any) {
        const url = `${this.API_LCL}/bookcase/create`;

        try {
            return await this.postReturnObject(url, bookcase);
        } catch (error: any) {
            throw new Error(error);
        }
    } // createBookcase

    async bookcaseDeleteById(bookcaseId: number, comments: any) {
        let url = '';
        if (isEmpty(comments))
            url = `${this.API_LCL}/bookcase/delete/${bookcaseId}`;
        else
            url = `${this.API_LCL}/bookcase/delete/${bookcaseId}/comments/${comments}`;

        try {
            return await this.deleteReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // bookcaseDeleteById

    async drawerByVessel(vesselId: number) {
        const url = `${this.API_LCL}/drawer/vessel/${vesselId}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // drawerByVessel
    
    async drawerUnused(partnerId: number) {
        const url = `${this.API_LCL}/drawer/unused/${partnerId}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // drawerUnused

    async saveDrawer(drawerId: number, drawer: any) {
        const url = `${this.API_LCL}/drawer/save/${drawerId}`;

        try {
            return await this.putReturnObject(url, drawer);
        } catch (error: any) {
            throw new Error(error);
        }
    } // saveDrawer

    async getAppointmentByDate(date: string, partnerId: number) {
        const url = `${this.API_LCL}/movement_vessel/appointment/${date}/partner/${partnerId}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // getAppointmentByDate

    async navigation (partnerId: number, dateMovement: string) {
          const url = `${this.API_LCL}/movement_vessel/schedule`
        try {
            return await this.postReturnObject(url, {partnerId, dateMovement});

        } catch (error: any) {
            throw new Error(error);
        }
    } // navigation

    async getByLocator (locator: string) {
          const url = `${this.API_LCL}/movement_vessel/locator/${locator}`
        try {
            return await this.getReturnObject(url);

        } catch (error: any) {
            throw new Error(error);
        }
    } // getByLocator

    async updateCheckIn(locator: string, checkIn: string, registration: string) {
        const url = `${this.API_LCL}/movement_vessel/checkin/${locator}`;

        try {
            return await this.putReturnObject(url, { checkIn, registration });
        } catch (error: any) {
            throw new Error(error);
        }
    } // updateCheckIn

    async updateMove(credential: string,
        locator: string,
        move: number,
        status: number) {
        const url = `${this.API_LCL}/movement_vessel/move/${locator}`;

        try {
            return await this.putReturnObject(url, { credential, move, status });
        } catch (error: any) {
            throw new Error(error);
        }
    } // updateMove

}

// eslint-disable-next-line import/no-anonymous-default-export
export default new LclService();