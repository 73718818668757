import { useEffect, useState } from "react";
import { ButtonGroup, Card, Col, Container, Row, Spinner } from "react-bootstrap";
import { FaEdit, FaEye, FaTrash } from "react-icons/fa";
import { useNavigate, useSearchParams } from "react-router-dom";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useDeleteMessageContext } from "../../../contexts/delete-message.context";
import authService from "../../../services/auth.service";
import { PermissionEnum } from "../../../utilities/constants";
import { toLowerCase } from "../../../utilities/auxiliary-functions";
import { AlertMessageEnum, getCategory } from "../../../utilities/types";
import cduService from "../../../services/cdu.service";
import { useAlertMessageContext } from "../../../contexts/alert-message.context";
import { Button, FormControl, FormHelperText, Input, InputAdornment, InputLabel, LinearProgress } from '@mui/material';
import { DataGrid, GridColDef, GridSelectionModel, GridToolbar } from '@mui/x-data-grid';
import { ThemeProvider } from '@mui/material/styles';
import theme from "../../../theme";
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import styles from './product.cadastre.list.module.scss';
import { Tooltip as TooltipMaterial } from '@mui/material';
import { TPagination } from "../../../types/PaginationType";
import { useTranslateContext } from "../../../contexts/translate.context";
import { usePartnerContext } from "../../../contexts/partner.context";

export interface ICategoryCadastreListProps {
    title: string,
    setRecord: any,
    viewOnly?: boolean
}

export const CategoryCadastreList: React.FC<ICategoryCadastreListProps> = (props: ICategoryCadastreListProps) => {
    
    const navigator = useNavigate();

    const alertContext = useAlertMessageContext();
    const deleteContext = useDeleteMessageContext();    
    const { display } = useTranslateContext();
    const { partner } = usePartnerContext();

    let reloadPage = false;
    const [ isLoading, setLoading ] = useState(false);
    const [ textSearch, setTextSearch ] = useState('');
    const [ showFilter, setShowFilter]  = useState(false);
    const [ sending, setSending ] = useState(false);
    const [ selectionModel, setSelectionModel ] = useState<GridSelectionModel>([]);

    let noPaged = true;
    const [ searchParams, setSearchParams ] = useSearchParams();

    const [ pageSize, setPageSize ] = useState(10);
    const [ page, setPage ] = useState(0);
    const [ pageFilled, setPageFilled ] = useState<number[]>([]);

    const [ recordCount, setRecordCount ] = useState(0);
    const [ records, setRecords ] = useState([]);
    const [ originalRecords, setOriginalRecords ] = useState([]);

    const columns: GridColDef[] = [
        { field: 'team', headerName: display.label.group, flex: 0.2, minWidth: 110, align: 'center', headerAlign: 'center', valueGetter: getTeam },
        { field: 'group', headerName: display.label.category, flex: 0.3, minWidth: 110, align: 'center', headerAlign: 'center' },
        { field: 'subgroup', headerName: display.label.subcategory, flex: 0.5, minWidth: 170, align: 'center', headerAlign: 'center' },
        { field: 'name', headerName: display.label.description, flex: 1, align: 'center', headerAlign: 'center' },
        {
            field: 'action',
            headerName: 'Ações',
            headerAlign: 'center',
            renderCell: renderButtons,
            disableExport: true,
        }
    ];

    function getTeam(param: any) {
        const category = param.row;
        return display.label[toLowerCase(getCategory(category.team).name)];
    }

    function renderButtons(param: any) {
        const categoryRow = param.row;
        return (
            <div className="d-flex w-100 justify-content-center">
                {props.viewOnly ? (
                    <>{authService.hasPermission(PermissionEnum.CADASTRE_CATEGORY_VIEW) && 
                        <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.toView}</Tooltip>}>
                            <span onClick={(e) => onClick_ViewRecord(e, categoryRow)}>
                                <FaEye size={18} />
                            </span>
                        </OverlayTrigger>
                    }</>
                ) : (
                    <>
                    {authService.hasPermission(PermissionEnum.CADASTRE_CATEGORY_EDIT) && 
                        <TooltipMaterial className="isClickable" title="Editar Categoria">
                            <span className="me-3"  onClick={(e) => onClick_EditRecord(e, categoryRow)}>
                                <FaEdit size={18} />
                            </span>
                        </TooltipMaterial>
                    }
                    {authService.hasPermission(PermissionEnum.CADASTRE_CATEGORY_REMOVE) && 
                        <TooltipMaterial className="isClickable" title="Excluir Categoria">
                            <span onClick={(e) => onClick_DeleteRecord(e, records, categoryRow)}>
                                <FaTrash size={18} />
                            </span>
                        </TooltipMaterial>
                    }
                    </>
                )}
            </div>
        );
    }

    function onClick_Filter(event: any) {
        event.preventDefault();
        
        setSending(false);
        setShowFilter(false);
        searchFilter();
    } // onClick_Filter

    function onClick_AddRecord(event: any) {
        event.preventDefault();

        setShowFilter(false);
        setSending(true);
        props.setRecord(event, 'new');
    } // onClick_AddRecord

    function onClick_EditRecord(event: any, record: any) {
        event.preventDefault();
        props.setRecord(event, record.id);
    } // onClick_EditRecord

    function onClick_ViewRecord(event: any, record: any) {
        event.preventDefault();
        props.setRecord(event, record.id);
        navigator(`${record.id}`);
    } // onClick_ViewRecord

    async function onClick_DeleteRecord(event: any, records: any, record: any) {
        event.preventDefault();
        const isConfirmed = await deleteContext.show(props.title, display.message.delete_record, true);
        if (isConfirmed && isConfirmed.result) {
            try {
                await cduService.categoryDeleteById(record.id, isConfirmed.message);
                searchFilter();
            } catch(error: any) {
                await alertContext.show(AlertMessageEnum.FAIL, props.title, error);
            }
        }
    } // onClick_DeleteRecord

    async function searchFilter() {
        try {
            let localPageSize = pageSize;
            let localPage = page;

            if (noPaged) {
                if (searchParams.has('limit')) {
                    let paramPageSize = searchParams.get('limit');
                    if (paramPageSize) {
                        // eslint-disable-next-line react-hooks/exhaustive-deps
                        localPageSize = Number.parseInt(paramPageSize);
                        setPageSize(localPageSize);
                    }
                }
                if (searchParams.has('offset')) {
                    let paramPage = searchParams.get('offset');
                    if (paramPage) {
                        // eslint-disable-next-line react-hooks/exhaustive-deps
                        localPage = Number.parseInt(paramPage);
                        setPage(localPage);
                    }
                }
            }

            if (pageFilled.indexOf(localPage) === -1) {
                const pagination = {
                    limit: localPageSize, 
                    offset: localPage * localPageSize
                } as TPagination;
                const categoryList = await cduService.categoryList(pagination);
                setRecordCount(categoryList.count);
                const array = [] as any;
                records.forEach((record: any) => array.push(record));
                categoryList.rows.forEach((record: any) => array.push(record));

                setRecords(array);
                setOriginalRecords(array);
                setSending(false);

                if (array.length > 0)
                    pageFilled.push(localPage);
            }
        } catch(error: any) {
            await alertContext.show(AlertMessageEnum.FAIL, props.title, error);
        } finally {
            setLoading(false);
            setShowFilter(false);
        }
    } // searchfilter

    const onChangePageSize = (newPageSize: number) => {
        setSearchParams({...searchParams, 'limit': `${newPageSize}`, 'offset': `${0}`});

        setRecords([]);
        setOriginalRecords([]);
        setRecordCount(0);
        setPageFilled([]);
        setPageSize(newPageSize);
        setPage(0);
    }
    
    const onChangePage = (newPage: number) => {
        setSearchParams({...searchParams, 'offset': `${newPage}`});

        setPage(newPage);
    }

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        noPaged = false;
        let paramPage = searchParams.get('offset');
        if (paramPage) {
            searchFilter();
        }
    }, [pageSize, page]); // useEffect

    useEffect(() => {
        if (!isLoading && !reloadPage) {
            noPaged = true;
            searchFilter();
        }

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            reloadPage = !reloadPage;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // useEffect
 
    const filtered = (key?: string, value?: string) => {
        const filtered: any = [];
        records.filter((record: any) => {
            if (
                display.label[toLowerCase(getCategory(record.team).name)]
                    .toLocaleLowerCase()
                    .includes(textSearch.toLocaleLowerCase()) ||
                record.group
                    .toLocaleLowerCase()
                    .includes(textSearch.toLocaleLowerCase()) ||
                record.subgroup
                    .toLocaleLowerCase()
                    .includes(textSearch.toLocaleLowerCase()) ||
                record.name
                    .toLocaleLowerCase()
                    .includes(textSearch.toLocaleLowerCase())
            )
            filtered.push(record);
            return record;
        })
        setRecords(filtered);
        return '';
    }

    useEffect(() => {
        if (textSearch.length >= 3) {
            filtered();
        } else if (textSearch.length < 3) {
            setRecords(originalRecords);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [textSearch]);

    function viewCadastreList() {
        return (
            <Container fluid className="page-body category">
                <Row>
                    <Col md={12} className="page-sweet">
                        <Card>
                            <Card.Header>
                                <div className="card-actions float-right">
                                    <ButtonGroup>
                                        {authService.hasPermission(PermissionEnum.CADASTRE_CATEGORY_ADD) && 
                                            <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.add} {display.tooltips.category}</Tooltip>}>
                                                <Button size="small" onClick={ onClick_AddRecord } disabled={sending} variant="contained" color="primary">
                                                    { sending ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <AddIcon /> }
                                                    Categoria
                                                </Button>
                                            </OverlayTrigger>
                                        }
                                    </ButtonGroup>
                                </div>
                                <Card.Title>{display.legend.listing}</Card.Title>
                            </Card.Header>
                            <Card.Body style={{ minHeight : 500 }}>
                                <div className={`card-filter ${showFilter ? "active" : ""}`} >
                                    <div className="card-filter-header">{display.legend.filter}</div>
                                    <div className="card-filter-body">
                                        <Container>
                                            <Row>
                                                <Col>{display.legend.filter_scheme}</Col>
                                            </Row>
                                        </Container>
                                    </div>
                                    <div className="card-filter-footer">
                                        <Button onClick={ onClick_Filter } disabled={sending} variant="contained" color="primary">
                                            { sending ? <Spinner className="me-2" as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null } {' '}
                                            {display.buttom.filter}
                                        </Button>
                                    </div>
                                </div>
                                <FormControl fullWidth sx={{width: 300, marginBottom: 2 }} variant="standard">
                                    <InputLabel className={styles.label} htmlFor="standard-adornment-amount">Pesquisar</InputLabel>
                                    <Input id="standard-adornment-amount"
                                        value={textSearch}
                                        onChange={(e) => setTextSearch(e.target.value)}
                                        endAdornment = {
                                            <InputAdornment position="end">
                                                <SearchIcon className='isClickable' />
                                            </InputAdornment>
                                        }
                                    />
                                    <FormHelperText id="component-helper-text">
                                        Grupo, Categoria, Subcategoria ou Descrição
                                    </FormHelperText>
                                </FormControl>
                                <ThemeProvider theme={theme}>
                                    <div style={{ height: 402, width: '100%', marginTop: 0 }}>
                                        <DataGrid
                                            rows={records}
                                            rowCount={recordCount}
                                            getRowId={row => row.id}
                                            loading={isLoading}
                                            columns={columns}
                                            pageSize={pageSize}
                                            onPageSizeChange={newPageSize => onChangePageSize(newPageSize)}
                                            page={page}
                                            onPageChange={newPage => onChangePage(newPage) }
                                            
                                            rowsPerPageOptions={[10, 25, 50, 75, 100]}
                                            disableSelectionOnClick
                                            // onCellClick={(e) => console.log(e)}
                                            onSelectionModelChange={(newSelectionModel: any) => {
                                                setSelectionModel(newSelectionModel)
                                            }}
                                            components={{
                                                Toolbar: GridToolbar,
                                                LoadingOverlay: LinearProgress
                                            }}
                                            selectionModel={selectionModel}
                                        />
                                    </div>
                                </ThemeProvider>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    } // viewCadastreList

    return (
        <>
            { viewCadastreList() }
        </>
    );

}

export default CategoryCadastreList;
