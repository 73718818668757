import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { NavMenu } from "../components/nav-menu.component";
import { SideMenu } from "../components/side-menu.component";
import authService from "../services/auth.service";
import MemberCadastre from "./cadastres/member.cadastre";
import PartnerCadastre from "./cadastres/partner.cadastre";
import ShipCadastre from "./cadastres/vessel.cadastre";
import DashboardPage from "./dashboards/dashboard";
import MaritimeDashboard from "./dashboards/maritime.dashboard";
import MonitoringDashboard from "./dashboards/monitoring.dashboard";
import MovementDashboard from "./dashboards/movement.dashboard";
import FinancialDashboard from "./dashboards/financial.dashboard";
import ErrorPage from "./error.page";
import ConciliationsFinancial from "./financial/conciliations.financial";
import ContractLauchersFinancial from "./financial/launchers.financial";
import ContratctsFinancial from "./financial/contratcts.financial";
import MovementsOperation from "./operations/movements.operation";
import ProfilePage from "./others/profile.page";
import SettingsPage from "./others/settings.page";
import KeepBoatsOperation from "./operations/keep-boats.operation";
import CategoryCadastre from "./cadastres/category.cadastre";
import PurveyorCadastre from "./cadastres/purveyor.cadastre";
import MechanicCadastre from "./cadastres/mechanic.cadastre";
import ProductCadastre from "./cadastres/product.cadastre";
import BudgetOffice from "./offices/budget.office";
import ScheduleOffice from "./offices/schedule.office";
import MenuAccessPage from "./others/menu-access.page";
import UserAccessPage from "./others/user-access.page";
import { Badge, Col, Form, ListGroup, Modal, Row } from "react-bootstrap";
import { cnpjMask } from "../utilities/masks";
import { useAlertMessageContext } from "../contexts/alert-message.context";
import lclService from "../services/lcl.service";
import { AlertMessageEnum } from "../utilities/types";
import ContractManagementFinancial from "./financial/ContractManagementFinancial";
import MovementsHistoryOperation from "./operations/movement-history.operation";
import { FaTimes } from "react-icons/fa";
import { Button } from '@mui/material';
import AssessmentsOperation from "./operations/assessments.operation";
import ContractCustomerFinancial from "./financial/contract-customer.financial";
import EmployeeCadastre from "./cadastres/employee.cadastre";
import ServiceCenterDashboard from "./dashboards/ServiceCenterDashboard";
import HourlyOccupation from "./dashboards/HourlyOccupation";
import HourlyPrediction from "./dashboards/HourlyPrediction";
import StockDashboard from "./dashboards/StockDashboard";
import StockSearch from "./stock/StockSearch";
import OperationManagement from "./dashboards/OperationManagement";
import Reservation from "./dashboards/Reservation";
import SharingManagementDashboard from "./dashboards/SharingManagementDashboard";
import DeliveryManagementDashboard from "./dashboards/DeliveryManagementDashboard";
import DeliveryOperation from "./dashboards/DeliveryOperation";
import CustomizeDashboard from "./dashboards/CustomizeDashboard";
import { useMediaContext } from "../contexts/media.context";
import { usePartnerContext } from "../contexts/partner.context";
import { isEmpty } from "../utilities/validators";
import MaritimeDashboardV2 from "./dashboards/MaritimeDashboardV2";
import FinancialDashboardV2 from "./dashboards/FinancialDashboardV2";
import CleaningProvided from "./provideds/CleaningProvided";
import CleaningDashboardV2 from "./dashboards/CleaningDashboardV2";

export interface IPortalPageProps {
}

export const PortalPage: React.FC<IPortalPageProps> = (props: IPortalPageProps) => {
   
    const { changeMedia } = useMediaContext();
    const { partner, setPartner } =  usePartnerContext();

    const isDashboard = (window.location.pathname.indexOf('dashboard') > -1);
    const isSetting = (window.location.pathname.indexOf('settings') > -1);
    const [ inactive, setInactive ] = useState(isDashboard || isSetting);

    function onClickBrand_Click(event: any) {
        setInactive(!inactive);
    }

    function onClick_Logout(event: any) {
        event.preventDefault();

        authService.logout();
        window.location.href = '/';
    }

    function onMouseDown(event: any) {
        authService.validToken(true);
    }

    function onResize(event: any) {
        changeMedia();
    }

    useEffect(() => {
        if (isEmpty(partner)) {
            setPartner(authService.currentPartner());
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [partner]);

    useEffect(() => {
        window.addEventListener("resize", onResize);
    });

    return (
        <>
            <div className="portal" onMouseDown={ e => onMouseDown(e) }>
                {!authService.isAssociateOperator() && (
                    <SideMenu
                        /* 
                        smooth={smooth}
                        name={userData.name} 
                        profile={userData.profile} 
                        email={userData.email} 
                        telephone={userData.telephone}
                        partner={userData.partner}
                        onChangeProfile={(value: any) => {
                            setViewProfile(value);
                        }}
                        */
                        onCollapse={inactive}
                        onCloseSideMenu={(value: any) => {
                            setInactive(value);
                        }}
                        onSignout={onClick_Logout}
                    />
                )}
                <div className="main">
                    <NavMenu onBrandClick={ (e: any) => onClickBrand_Click(e) } onClickSair={ (e: any) => onClick_Logout(e) } onCloseSideMenu={(value: any) => {
                        setInactive(value);
                    }} />
                    <Routes>
                        <Route index element={<DashboardPage />} />

                        <Route path='/profile' element={<ProfilePage />} />
                        <Route path='/settings' element={<SettingsPage />} />
                        <Route path='/menu_access' element={<MenuAccessPage />} />
                        <Route path='/user_access' element={<UserAccessPage />} />
                        
                        {authService.isAssociateOperator() ? (
                            <>
                                <Route path='/dashboard/operation-management' element={<OperationManagement />} />
                                <Route path='/dashboard/operation-management/:reservation' element={<Reservation />} />
                            </>
                        ) : (
                            <>
                                <Route path='/dashboard/customize' element={<CustomizeDashboard />} />

                                <Route path='/dashboard/maritime_v2' element={<MaritimeDashboardV2 />} />
                                <Route path='/dashboard/financial_v2' element={<FinancialDashboardV2 />} />
                                <Route path='/dashboard/cleaning_v2' element={<CleaningDashboardV2 />} />
                                <Route path='/dashboard/stock_v2' element={<CustomizeDashboard />} />
                                <Route path='/dashboard/sharing_v2' element={<CustomizeDashboard />} />
                                <Route path='/dashboard/service-center_v2' element={<CustomizeDashboard />} />
                                <Route path='/dashboard/delivery_v2' element={<CustomizeDashboard />} />
                                <Route path='/dashboard/hourly-occupation_v2' element={<CustomizeDashboard />} />
                                <Route path='/dashboard/hourly-prediction_v2' element={<CustomizeDashboard />} />

                                <Route path='/dashboard/maritime' element={<MaritimeDashboard />} />
                                <Route path='/dashboard/financial' element={<FinancialDashboard />} />
                                <Route path='/dashboard/stock' element={<StockDashboard />} />
                                <Route path='/dashboard/sharing' element={<SharingManagementDashboard />} />
                                <Route path='/dashboard/service-center' element={<ServiceCenterDashboard />} />
                                <Route path='/dashboard/delivery' element={<DeliveryManagementDashboard />} />
                                <Route path='/dashboard/hourly-occupation' element={<HourlyOccupation />} />
                                <Route path='/dashboard/hourly-prediction' element={<HourlyPrediction />} />
                                                                
                                <Route path='/dashboard/movement' element={<MovementDashboard />} />
                                <Route path='/dashboard/monitoring' element={<MonitoringDashboard />} />
                                <Route path='/dashboard/operation-management' element={<OperationManagement />} />
                                <Route path='/dashboard/operation-management/:reservation' element={<Reservation />} />
                                
                                <Route path='/delivery' element={<DeliveryOperation />} />
                                <Route path='/provideds/cleaning' element={<CleaningProvided />} />
                                
                                <Route path='/cadastre/category' element={<CategoryCadastre />}>
                                    <Route path='/cadastre/category/:categoryId' element={<CategoryCadastre />} />
                                </Route>
                                <Route path='/cadastre/member' element={<MemberCadastre />}>
                                    <Route path='/cadastre/member/:memberId' element={<MemberCadastre />} />
                                </Route>
                                <Route path='/cadastre/partner' element={<PartnerCadastre />}>
                                    <Route path='/cadastre/partner/:partnerId' element={<PartnerCadastre />} />
                                </Route>
                                <Route path='/cadastre/vessel' element={<ShipCadastre />}>
                                    <Route path='/cadastre/vessel/:vesselId' element={<ShipCadastre />} />
                                </Route>
                                <Route path='/cadastre/purveyor' element={<PurveyorCadastre />}>
                                    <Route path='/cadastre/purveyor/:purveyorId' element={<PurveyorCadastre />} />
                                </Route>
                                <Route path='/cadastre/employee' element={<EmployeeCadastre />}>
                                    <Route path='/cadastre/employee/:employeeId' element={<EmployeeCadastre />} />
                                </Route>
                                <Route path='/cadastre/mechanic' element={<MechanicCadastre />}>
                                    <Route path='/cadastre/mechanic/:mechanicId' element={<MechanicCadastre />} />
                                </Route>
                                <Route path='/cadastre/product' element={<ProductCadastre />}>
                                    <Route path='/cadastre/product/:productId' element={<ProductCadastre />} />
                                </Route>

                                {/* INICIO Menus apenas de visualizações */}
                                <Route path='/member' element={<MemberCadastre viewOnly={true} />}>
                                    <Route path='/member/:memberId' element={<MemberCadastre viewOnly={true} />} />
                                </Route>
                                <Route path='/vessel' element={<ShipCadastre viewOnly={true} />}>
                                    <Route path='/vessel/:vesselId' element={<ShipCadastre viewOnly={true} />} />
                                </Route>
                                <Route path='/employee' element={<EmployeeCadastre viewOnly={true} />}>
                                    <Route path='/employee/:employeeId' element={<EmployeeCadastre viewOnly={true} />} />
                                </Route>
                                <Route path='/mechanic' element={<MechanicCadastre viewOnly={true} />}>
                                    <Route path='/mechanic/:mechanicId' element={<MechanicCadastre viewOnly={true} />} />
                                </Route>
                                <Route path='/product' element={<ProductCadastre viewOnly={true} />}>
                                    <Route path='/product/:productId' element={<ProductCadastre viewOnly={true} />} />
                                </Route>
                                {/* FIM */}

                                <Route path='/stock/search' element={<StockSearch />} />

                                <Route path='/operation/movement' element={<MovementsOperation />}>
                                    <Route path='/operation/movement/:movementId' element={<MovementsOperation />} />
                                </Route>
                                <Route path='/operation/movement-history' element={<MovementsHistoryOperation />}>
                                    <Route path='/operation/movement-history/:movementHistoryId' element={<MovementsHistoryOperation />} />
                                </Route>
                                <Route path='/operation/keep_boat' element={<KeepBoatsOperation />}>
                                    <Route path='/operation/keep_boat/:bookcaseId' element={<KeepBoatsOperation />} />
                                </Route>
                                <Route path='/operation/assessment' element={<AssessmentsOperation />}>
                                    <Route path='/operation/assessment/:assessmentId' element={<AssessmentsOperation />} />
                                </Route>
                                
                                <Route path='/office/schedule' element={<ScheduleOffice />}>
                                    <Route path='/office/schedule/:scheduleId' element={<ScheduleOffice />} />
                                </Route>
                                <Route path='/office/budget' element={<BudgetOffice />}>
                                    <Route path='/office/budget/:budgetId' element={<BudgetOffice />} />
                                </Route>

                                <Route path='/financial/contract' element={<ContratctsFinancial />}>
                                    <Route path='/financial/contract/:contractId' element={<ContratctsFinancial />} />
                                </Route>
                                <Route path='/financial/launcher' element={<ContractLauchersFinancial />}>
                                    <Route path='/financial/launcher/:launcherId' element={<ContractLauchersFinancial />} />
                                </Route>
                                <Route path='/financial/contract-management' element={<ContractManagementFinancial />}>
                                    <Route path='/financial/contract-management/:contractId' element={<ContractManagementFinancial />} />
                                </Route>
                                <Route path='/financial/contract-customer' element={<ContractCustomerFinancial />}>
                                    <Route path='/financial/contract-customer/:contractId' element={<ContractCustomerFinancial />} />
                                </Route>
                                <Route path='/financial/conciliation' element={<ConciliationsFinancial />}>
                                    <Route path='/financial/conciliation/:conciliationId' element={<ConciliationsFinancial />} />
                                </Route>
                            </>
                        )}
                        <Route path="*" element={<ErrorPage type={'Não mapeado em Portal'} />} />
                    </Routes>
                </div>
            </div>
        </>
    )
}

export default PortalPage;
