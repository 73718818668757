import { useEffect, useState } from "react";
import { Form, Modal, Spinner } from "react-bootstrap";
import { notNullToDate } from "../../utilities/auxiliary-functions";
import { cpfMask, numberMask, phoneMask, removeFormatDate, removeMask, zipcodeMask } from "../../utilities/masks";
import { isCpf, isEmpty } from "../../utilities/validators";
import EmployeeRecord from "../records/employee.record";
import { FaTimes } from "react-icons/fa";
import { useAlertMessageContext } from "../../contexts/alert-message.context";
import { AlertMessageEnum } from "../../utilities/types";
import { Button } from '@mui/material';
import { openDocument } from "../../utilities/view-document";
import cduService from "../../services/cdu.service";
import { UserSituationEnum } from "../../utilities/constants";
import { useTranslateContext } from "../../contexts/translate.context";
import { usePartnerContext } from "../../contexts/partner.context";

export interface IEmployeeCadastreModalProps {
    isEdit: boolean,
    show: boolean,
    record: any,
    onSubmitModal: any,
    onCancelModal: any
}

export const EmployeeCadastreModal: React.FC<IEmployeeCadastreModalProps> = (props: IEmployeeCadastreModalProps) => {
    
    const alertContext = useAlertMessageContext();
    const { display } = useTranslateContext();
    const { partner } = usePartnerContext();

    const title : string = display.title.employee_cadastre;

    const [ invalidatedCadastreForm, setInvalidatedCadastreForm ] = useState(false);
    const [ sending, setSending ] = useState(false);

    // Employee
    const [ id, setId ] = useState(0);
    const [ companyId, setCompanyId ] = useState(0);
    const [ employeeType, setEmployeeType ] = useState('');
    const [ created, setCreated ] = useState('');
    const [ updated, setUpdated ] = useState('');

    // PersonalData
    const [ peopleId, setPeopleId ] = useState(0); 
    const [ tratament, setTratament ] = useState(''); 
    const [ fullname, setFullname ] = useState(''); 
    const [ gender, setGender ] = useState('');
    const [ birth, setBirth ] = useState('');

    // Phone
    const [ phoneId, setPhoneId ] = useState(0);
    const [ norm, setNorm ] = useState('');
    const [ operator, setOperator ] = useState('');
    const [ connection, setConnection ] = useState('');
    const [ sms, setSms ] = useState(0);
    const [ zap, setZap ] = useState(0);
    const [ major, setMajor ] = useState(0);

    const [ nationality, setNationality ] = useState('');
    const [ naturalness, setNaturalness ] = useState('');

    // PersonalDocument
    const [ registrationPf, setRegistrationPf ] = useState('');
    const [ registrationIdentity, setRegistrationIdentity ] = useState('');
    const [ issuerIdentity, setIssuerIdentity ] = useState('');
    const [ stateIdentity, setStateIdentity ] = useState('');
    const [ expeditionIdentity, setExpeditionIdentity ] = useState('');
    const [ driversLicense, setDriversLicense ] = useState('');
    const [ categoryDriversLicense, setCategoryDriversLicense ] = useState('');
    const [ validityDriversLicense, setValidityDriversLicense ] = useState('');

    // WorkData
    const [ sector, setSector ] = useState('');
    const [ occupation, setOccupation ] = useState('');
    const [ credential, setCredential ] = useState('');
    const [ remuneration, setRemuneration ] = useState('');
    const [ admission, setAdmission ] = useState('');
    const [ dismissal, setDismissal ] = useState('');
    const [ situation, setSituation ] = useState('');
    const [ workdayFirst, setWorkdayFirst ] = useState('');
    const [ workdayStop, setWorkdayStop ] = useState('');
    const [ workdayStart, setWorkdayStart ] = useState('');
    const [ workdayLast, setWorkdayLast ] = useState('');

    // Address
    const [ addressId, setAddressId ] = useState(0);
    const [ zipcode, setZipcode ] = useState('');
    const [ state, setState ] = useState('');
    const [ city, setCity ] = useState('');
    const [ district, setDistrict ] = useState('');
    const [ place, setPlace ] = useState('');
    const [ habitation, setHabitation ] = useState('');
    const [ complement, setComplement ] = useState('');

    type ArtifactType = { id : number, norm : string, source : string, identy : string, document : string, filename : string };
    const DEFAULT_ARTIFACT: ArtifactType = { id : 0, norm : '', source : '', identy : '', document : '', filename : '' };
    
    const [ proofOfResidenceDocument, setProofOfResidenceDocument ] = useState(DEFAULT_ARTIFACT);

    const [ image, setImage ] = useState(''); 
    const [ email, setEmail ] = useState(''); 
    const [ userId, setUserId ] = useState(0); 

    async function isValidForm() {
        let result = true;
        let emptyRequired = false;

        if (isEmpty(fullname))
            emptyRequired = true;

        if (isEmpty(sector))
            emptyRequired = true;
        if (isEmpty(occupation))
            emptyRequired = true;

        if (isEmpty(registrationPf))
            emptyRequired = true;
        if ((!isEmpty(registrationPf)) && (!isCpf(registrationPf))) {
            await alertContext.show(AlertMessageEnum.FAIL, title, display.message.invalid.registration_pf);
            result = false;
        }
    
        if (emptyRequired) {
            await alertContext.show(AlertMessageEnum.FAIL, title, display.message.invalid.required);
            result = false;
        }

        setInvalidatedCadastreForm(!result);
        return result;
    }

    async function onClick_Confirm(event : any) {
        event.preventDefault();

        const isValid = await isValidForm();
        if (isValid) {
            try {
                setSending(false);
                const employee = fillEmployee();
                if (proofOfResidenceDocument) {
                    proofOfResidenceDocument.source = 'PEOPLE';
                    proofOfResidenceDocument.identy = String(employee.peopleId);

                    if ((proofOfResidenceDocument.id > 0) && !isEmpty(proofOfResidenceDocument.document)) {
                        await cduService.saveArtifact(proofOfResidenceDocument.id, proofOfResidenceDocument);
                    } else if ((proofOfResidenceDocument.id > 0) && isEmpty(proofOfResidenceDocument.document)) {
                        await cduService.artifactDeleteById(proofOfResidenceDocument.id);
                    } else if ((proofOfResidenceDocument.id === 0) && !isEmpty(proofOfResidenceDocument.document)) {
                        await cduService.createArtifact(proofOfResidenceDocument);
                    }   
                }
                props.onSubmitModal(event, employee);
            } catch (error: any) {
                setSending(false);
                await alertContext.show(AlertMessageEnum.FAIL, title, error);
            }
        }
    }

    async function onClick_Cancel(event : any) {
        event.preventDefault();

        setInvalidatedCadastreForm(false);
        props.onCancelModal(event);
    }

    function clearEmployee() {

        setId(0);
        setCompanyId(0);
        setEmployeeType('');
        setCreated('');
        setUpdated('');

        setPeopleId(0);
        setTratament('');
        setFullname('');
        setGender('');
        setBirth('');
        setEmail('');
        setPhoneId(0);
        setNorm('');
        setOperator('');
        setConnection('');
        setSms(0);
        setZap(0);
        setMajor(0);
            
        setNationality('');
        setNaturalness('');
        setImage('');
        setUserId(0);
        setRegistrationPf('');
        setRegistrationIdentity('');
        setIssuerIdentity('');
        setStateIdentity('');
        setExpeditionIdentity('');
        setDriversLicense('');
        setCategoryDriversLicense('');
        setValidityDriversLicense('');
        
        setSector('');
        setOccupation('');
        setCredential('');
        setRemuneration('');
        setAdmission('');
        setDismissal('');
        setSituation(String(UserSituationEnum.REGISTERED));
        setWorkdayFirst('');
        setWorkdayStop('');
        setWorkdayStart('');
        setWorkdayLast('');

        setAddressId(0);
        setZipcode('');
        setState('');
        setCity('');
        setDistrict('');
        setPlace('');
        setHabitation('');
        setComplement('');
    }

    function fillDataForm(employee: any) {

        if (employee != null) {
            setId(employee.id);
            setCompanyId(employee.companyId);
            setEmployeeType(employee.employeeType);
            setCreated(notNullToDate(employee.created, 'dd/mm/yyyy HH:MM:ss'));  
            setUpdated(notNullToDate(employee.updated, 'dd/mm/yyyy HH:MM:ss'));  
            setPeopleId(employee.peopleId);

            setSector(employee.sector);
            setOccupation(employee.occupation);
            setCredential(employee.credential);
            setRemuneration(employee.remuneration);
            setAdmission(notNullToDate(employee.admission, 'dd/mm/yyyy'));
            setDismissal(notNullToDate(employee.dismissal, 'dd/mm/yyyy'));
            setSituation(employee.situation);
            setWorkdayFirst(employee.workdayFirst);
            setWorkdayStop(employee.workdayStop);
            setWorkdayStart(employee.workdayStart);
            setWorkdayLast(employee.workdayLast);

            setPeopleId(employee.peopleId)
            fillRecordPeople(employee.people);
        }
    }

    function fillRecordPeople(people: any) {

        if (!isEmpty(people)) {
            setPeopleId(people.id);
            setTratament(people.tratament);
            setFullname(people.fullname);
            setGender(people.gender);
            setBirth(notNullToDate(people.birth, 'dd/mm/yyyy'));
            setEmail(people.email);
            setImage(people.image);
            setUserId(people.userId);

            if (!isEmpty(people.phones)) {
                const phone = people.phones[0];

                setPhoneId(phone.id);
                setNorm(phone.norm);
                setOperator(phone.operator);
                setConnection(phoneMask(phone.connection));
                setSms(phone.sms);
                setZap(phone.zap);
                setMajor(phone.major);
            }
            setNationality(people.nationality);
            setNaturalness(people.naturalness);

            setRegistrationPf(cpfMask(people.registrationPf));
            setRegistrationIdentity(numberMask(people.registrationIdentity));
            setIssuerIdentity(people.issuerIdentity);
            setStateIdentity(people.stateIdentity);
            setExpeditionIdentity(notNullToDate(people.expeditionIdentity, 'dd/mm/yyyy'));
            setDriversLicense(numberMask(people.driversLicense));
            setCategoryDriversLicense(people.categoryDriversLicense);
            setValidityDriversLicense(notNullToDate(people.validityDriversLicense, 'dd/mm/yyyy'));
            
            if (!isEmpty(people.adresses)) {
                const addressPeople = people.adresses[0];

                setAddressId(addressPeople.addressId);
                setZipcode(zipcodeMask(addressPeople.address.zipcode));
                setState(addressPeople.address.state);
                setCity(addressPeople.address.city);
                setDistrict(addressPeople.address.district);
                setPlace(addressPeople.address.place);

                setHabitation(addressPeople.habitation);
                setComplement(addressPeople.complement);
            }
        }
    } // fillRecordPeople

    function fillEmployee() {

        const address = [];
        address[0] = {
            peopleId : peopleId,
            addressId : addressId,
            address : {
                id : addressId,
                zipcode : removeMask(zipcode),
                state : state,
                city : city,
                district : district,
                place : place
            },
            correspondence : 1,
            norm : 'residential',
            habitation,
            complement
        };

        const phones = [];
        phones[0] = {
            id : phoneId,
            peopleId,
            norm, 
            operator,
            connection : removeMask(connection),
            sms,
            zap,
            major
        };

        const dataEmployee = {
            id,
            companyId : partner.companyId,
            employeeType: removeMask(employeeType, '0'), 
            created: removeFormatDate(created),
            updated: removeFormatDate(updated),
            sector,
            occupation,
            credential,
            remuneration: removeMask(remuneration, '0'),
            admission: removeFormatDate(admission),
            dismissal: removeFormatDate(dismissal),
            situation: removeMask(situation, '0'),
            workdayFirst,
            workdayStop,
            workdayStart,
            workdayLast,
            peopleId,
            people : {
                id : peopleId,
                tratament,
                fullname, 
                gender, 
                birth : removeFormatDate(birth), 
                email,
                phones, 
                nationality, 
                naturalness, 
                registrationPf : removeMask(registrationPf),
                registrationIdentity : removeMask(registrationIdentity),
                issuerIdentity,
                stateIdentity,
                expeditionIdentity: removeFormatDate(expeditionIdentity),
                driversLicense : removeMask(driversLicense),
                categoryDriversLicense,
                validityDriversLicense: removeFormatDate(validityDriversLicense),
                address,
                image,
                userId
            }
        };

        return dataEmployee;
    }

    async function initialize() {
        if (props.isEdit) {
            fillDataForm(props.record);

            cduService.artifactByNorm('PEOPLE', props.record?.people.id, 'FILE_RESIDENCE').then((artifact: any) => {
                if (artifact)
                    setProofOfResidenceDocument(artifact);
            });
        } else {
            clearEmployee();
        }
    }

    useEffect(() => {
        if (props.show)
            initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show]);

    function changeProofOfResidenceDocument(event: any) {
        if (event.target.files && event.target.files.length > 0) {
            let reader = new FileReader();
            reader.onload = (e: any) => {
                let artfact: ArtifactType = {
                    id : proofOfResidenceDocument.id,
                    norm : 'FILE_RESIDENCE',
                    source : 'PEOPLE',
                    identy : String(peopleId),
                    document : e.target.result,
                    filename : event.target.files[0].name
                }
                setProofOfResidenceDocument(artfact);
            };
            reader.readAsDataURL(event.target.files[0]);
        } else {
            let artifact: ArtifactType = { id : proofOfResidenceDocument.id, norm : 'FILE_RESIDENCE', source : 'PEOPLE', identy : String(peopleId), document : '', filename : '' };
            setProofOfResidenceDocument(artifact);
        }
    }

    async function onClick_SearchPeople(event: any) {
        event.preventDefault();
        const search = registrationPf;
        clearEmployee();
        setRegistrationPf(search);
        if (!isEmpty(search)) {
            const registration = removeMask(search);
            if (isCpf(registration)) {
                const people = await cduService.peopleByRegistration(registration);
                fillRecordPeople(people);
                if (!isEmpty(people)) {
                    const employee = await cduService.employeeByCompanyAndPeople(partner.companyId, people.id);
                    if (employee) {
                        await alertContext.show(AlertMessageEnum.SUCCESS, title, 'CPF já cadastrado.');
                        props.onCancelModal(event);
                    }
                } else 
                    await alertContext.show(AlertMessageEnum.SUCCESS, title, 'CPF não localizado. Efetue o cadastro completo.');
            } else {
                await alertContext.show(AlertMessageEnum.FAIL, title, 'CPF Inválido !');
            }
        } else {
            await alertContext.show(AlertMessageEnum.FAIL, title, 'Insira um CPF válido !');
        }
    }

    function clickViewDocument(event: any, title: string, url: any) {
        event.preventDefault();
        openDocument(title, url);
    }

    function changeImage(event: any) {
        if (event.target.files && event.target.files.length > 0) {
            let reader = new FileReader();
            reader.onload = (e: any) => {
                setImage(e.target.result);
            };
            reader.readAsDataURL(event.target.files[0]);
        } else {
            setImage('');
        }
    }

    function viewCadastreForm() {
        return (
            <>
                <Modal show={props.show}
                    size="lg"
                    aria-labelledby="modal-cadastre-employee-form"
                    centered
                >
                    <Modal.Header>
                        <Modal.Title id="modal-cadastre-employee-form">{title}</Modal.Title>
                        <FaTimes className="isClickable" onClick={ onClick_Cancel } size={26}/>
                    </Modal.Header>
                    <Modal.Body>
                        <Form className="form" id="form-cadastre-employee" validated={invalidatedCadastreForm}>
                            <EmployeeRecord
                                title={title}
                                isEdit={props.isEdit}

                                employeeType={employeeType}
                                created={created}
                                updated={updated}
                                tratament={tratament}
                                fullname={fullname}
                                gender={gender}
                                birth={birth}
                                norm={norm}
                                operator={operator}
                                connection={connection}
                                sms={sms}
                                zap={zap}
                                major={major}
                                nationality={nationality}
                                naturalness={naturalness}
                                registrationPf={registrationPf}
                                registrationIdentity={registrationIdentity}
                                issuerIdentity={issuerIdentity}
                                stateIdentity={stateIdentity}
                                expeditionIdentity={expeditionIdentity}
                                driversLicense={driversLicense}
                                categoryDriversLicense={categoryDriversLicense}
                                validityDriversLicense={validityDriversLicense}
                                sector={sector}
                                occupation={occupation}
                                credential={credential}
                                remuneration={remuneration}
                                admission={admission}
                                dismissal={dismissal}
                                situation={situation}
                                workdayFirst={workdayFirst}
                                workdayStop={workdayStop}
                                workdayStart={workdayStart}
                                workdayLast={workdayLast}
                                zipcode={zipcode}
                                state={state}
                                city={city}
                                district={district}
                                place={place}
                                habitation={habitation}
                                complement={complement}
                                email={email}
                                setEmployeeType={(value: any) => { setEmployeeType(value); }}
                                setCreated={(value: any) => { setCreated(value); }}
                                setUpdated={(value: any) => { setUpdated(value); }}
                                setTratament={(value: any) => { setTratament(value); }}
                                setFullname={(value: any) => { setFullname(value); }}
                                setGender={(value: any) => { setGender(value); }}
                                setBirth={(value: any) => { setBirth(value); }}
                                setNorm={(value: any) => { setNorm(value); }}
                                setOperator={(value: any) => { setOperator(value); }}
                                setConnection={(value: any) => { setConnection(value); }}
                                setSms={(value: any) => { setSms(value); }}
                                setZap={(value: any) => { setZap(value); }}
                                setMajor={(value: any) => { setMajor(value); }}
                                setNationality={(value: any) => { setNationality(value); }}
                                setNaturalness={(value: any) => { setNaturalness(value); }}
                                setRegistrationPf={(value: any) => { setRegistrationPf(value); }}  
                                setRegistrationIdentity={(value: any) => { setRegistrationIdentity(value); }}  
                                setIssuerIdentity={(value: any) => { setIssuerIdentity(value); }}  
                                setStateIdentity={(value: any) => { setStateIdentity(value); }}  
                                setExpeditionIdentity={(value: any) => { setExpeditionIdentity(value); }}  
                                setDriversLicense={(value: any) => { setDriversLicense(value); }}  
                                setCategoryDriversLicense={(value: any) => { setCategoryDriversLicense(value); }} 
                                setValidityDriversLicense={(value: any) => { setValidityDriversLicense(value); }} 
                                setSector={(value: any) => { setSector(value); }}
                                setOccupation={(value: any) => { setOccupation(value); }}
                                setCredential={(value: any) => { setCredential(value); }}
                                setRemuneration={(value: any) => { setRemuneration(value); }}
                                setAdmission={(value: any) => { setAdmission(value); }}
                                setDismissal={(value: any) => { setDismissal(value); }}
                                setSituation={(value: any) => { setSituation(value); }}
                                setWorkdayFirst={(value: any) => { setWorkdayFirst(value); }}
                                setWorkdayStop={(value: any) => { setWorkdayStop(value); }}
                                setWorkdayStart={(value: any) => { setWorkdayStart(value); }}
                                setWorkdayLast={(value: any) => { setWorkdayLast(value); }}
                                setZipcode={(value: any) => { setZipcode(value); }}
                                setState={(value: any) => { setState(value); }}
                                setCity={(value: any) => { setCity(value); }}
                                setDistrict={(value: any) => { setDistrict(value); }}
                                setPlace={(value: any) => { setPlace(value); }}
                                setHabitation={(value: any) => { setHabitation(value); }}
                                setComplement={(value: any) => { setComplement(value); }}
                                setEmail={(value: any) => { setEmail(value); }}

                                onClick_SearchPeople={(value: any) => { onClick_SearchPeople(value); }}

                                proofOfResidenceDocument={proofOfResidenceDocument}
                                changeProofOfResidenceDocument={changeProofOfResidenceDocument}
                                clickViewDocument={clickViewDocument}

                                changeImage={(event: any) => { changeImage(event); }} 
                            />
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={ onClick_Cancel } variant="contained" color="secondary">{display.buttom.cancel}</Button>
                        <Button onClick={ onClick_Confirm } variant="contained" color="primary" disabled={sending}>
                            { sending ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null }{'  '}
                            { display.buttom.confirm }
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

    return (
        <>
        { viewCadastreForm() }
        </>
    )
}

export default EmployeeCadastreModal
