import { useEffect, useState } from "react";
import { Button, ButtonGroup, Card, Col, Container, Form, Row, Spinner, Table } from "react-bootstrap";
import { FaSpinner } from "react-icons/fa";
import { useAlertMessageContext } from "../../contexts/alert-message.context";
import cduService from "../../services/cdu.service";
import { binaryToHex, leftPad, rightPad } from "../../utilities/auxiliary-functions";
import { AlertMessageEnum, PermissionType } from "../../utilities/types";
import SettingHeader from "../headers/settings.header";
import { useTranslateContext } from "../../contexts/translate.context";

export interface IMenuAccessPageProps {
}

export const MenuAccessPage: React.FC<IMenuAccessPageProps> = (props: IMenuAccessPageProps) => {

    const alertContext = useAlertMessageContext();
    const { display } = useTranslateContext();

    const title = display.title.menu_access;
    const description = display.description.menu_access;

    let reloadPage = false;
    const [ isLoading, setLoading ] = useState(false);
    const [ sending, setSending ] = useState(false);

    const [ roles, setRoles ] = useState([] as any);
    const [ matriz, setMatriz ] = useState([] as any);

    async function initialize() {
        const attributes = [] as any;
        const where = {} as any;
        const order = [] as any;

        const roles = await cduService.roleFilter(attributes, where, order);
        setRoles(roles);

        const rows = [] as any;
        const permissions = Object.keys(PermissionType);

        for ( var i_col = 0; i_col < roles.length; i_col++) {
            const role = roles[i_col];
            let binary = leftPad('0', permissions.length, '0');
            
            if (role.permission) {
                let hexa = role.permission;
                let binaries = '';
                while (hexa.length > 0) {
                    let trecho = hexa.substring(0, 1);
                    let bbin = (parseInt(trecho, 16).toString(2)).padStart(4, '0');
                    binaries += bbin;
                    hexa = hexa.substring(1, hexa.length);
                }
                binary = leftPad(binaries, 128, '0').substring(128 - permissions.length, 128);
            }

            const cols = [];
            for ( var j_row = 0; j_row < permissions.length; j_row++) {
                cols[j_row] = binary.substring(j_row, j_row + 1);
            }
            rows[i_col] = cols;
        }
        setMatriz(rows);
    }

    useEffect(() => {
        if (!isLoading && !reloadPage) {
            initialize();
            setLoading(true);
        }

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            reloadPage = !reloadPage;
        }
    }, []);

    function getChildrenPermission(father: any) {
        const permissions = PermissionType;
        const tamFather = String(father.id).length;
        const childrens = [] as any;

        for (var p = 0; p < permissions.length; p++) {
            const permission = permissions[p];
            if (String(permission.id).length > tamFather) {
                if (String(permission.id).substring(0, tamFather) === String(father.id))
                    childrens[childrens.length] = {
                        position : p,
                        permission
                    }
            }
        }
        return childrens;
    }

    function getParentPermission(sam: any) {
        const permissions = PermissionType;
        const tamSam = String(sam.id).length;

        const parents = [] as any;
        
        for (var p = 0; p < permissions.length; p++) {
            const permission = permissions[p];
            if (String(permission.id).length < tamSam) {
                if (String(sam.id).substring(0, String(permission.id).length) === String(permission.id)) {
                    parents[parents.length] = {
                        position : p,
                        permission
                    }
                }
            }
        }
        return parents;
    }

    function onChange_Switch(event: any, col: number, row: number) {
        const rows = new Array(matriz.length);

        for (var c = 0; c < matriz.length; c++) {
            rows[c] = new Array(matriz[c].length);

            for (var r = 0; r < matriz[c].length; r++) {
                if ((c === col) && (r === row)) {
                    rows[col][row] = matriz[col][row] === '1' ? '0' : '1';
                } else {
                    rows[c][r] = matriz[c][r];
                }
            }
        }

        const whoAmI = PermissionType[row]; 
        // Ligado
        if (rows[col][row] === '1') {
            // Ligar o PAI
            const parents = getParentPermission(whoAmI);
            for (var i = 0; i < parents.length; i++) {
                rows[col][parents[i].position] = '1';
            }
        } else {
            // Desligar FILHOS
            const childrens = getChildrenPermission(whoAmI);
            // eslint-disable-next-line @typescript-eslint/no-redeclare
            for (var i = 0; i < childrens.length; i++) {
                rows[col][childrens[i].position] = '0';
            }
        }
        setMatriz(rows);
    }

    function onClick_RefreshSettings(event : any) {
        initialize();
    }

    async function onClick_Refresh(event : any) {
        event.preventDefault();

        const permissions = Object.keys(PermissionType);

        for (var c = 0; c < matriz.length; c++) {
            let binary = ''
            for (var r = 0; r < matriz[c].length; r++) {
                binary += matriz[c][r];
            }
            binary = rightPad(binary, permissions.length, '0');

            let hex = '';
            while (binary.length > 0) {
                let trecho = binary.substring(binary.length - 4, binary.length);
                let bhex = binaryToHex(trecho);
                hex = (bhex === '0x' ? '0' : bhex.substring(2, 3)) + hex;
                binary = binary.substring(0, binary.length - 4);
            }
            roles[c].permission = hex;
        }

        setSending(true);
        const message = [];
        for (var j = 0; j < roles.length; j++) {
            const role = roles[j];

            try {
                await cduService.saveRole(role.id, role);
            } catch (error: any) {
                message[message.length] = error.message;
            }
        }
        setSending(false);
        if (message.join(", ")) {
            await alertContext.show(AlertMessageEnum.FAIL, title, message.join(", "));
        }
    }

    function viewPage() {

        return (
            <Container fluid className="page-body settings">
                <Row>
                    <Col>
                        <Card>
                            <Card.Header>
                                <div className="card-actions float-right d-flex justify-content-end">
                                    <ButtonGroup>
                                        <Button variant="secondary" onClick={ (e) => onClick_RefreshSettings(e) } disabled={sending}>
                                            { sending ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <FaSpinner size={22} /> } {' '}
                                            {display.buttom.refresh}
                                        </Button>
                                    </ButtonGroup>
                                </div>
                                <Card.Title>Permissões</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <div className="table-header">
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th style={{width : 280}}></th>
                                                {roles.map((role: any, col: number) => (
                                                    <th className="table-label-vertical" key={col}>{role.name}</th>
                                                ))}
                                            </tr>
                                        </thead>
                                    </Table>
                                </div>
                                <div className="table-content">
                                    <Table>
                                        <tbody>
                                            {PermissionType.map((item: any, row: number) => (
                                                <tr key={row}>
                                                    <td className="table-label" style={{width : 280}}>{item.name}</td>
                                                    {roles.map((role: any, col: number) => (
                                                        <td key={col} className="white-space">
                                                            <Form.Check type="switch" id={`switch-${col}-${row}`} 
                                                                name={`switch-${col}-${row}`} 
                                                                checked={matriz[col][row] === '1'} 
                                                                //value={matriz[col][row]} 
                                                                onChange={(e) => onChange_Switch(e, col, row)}
                                                            />
                                                        </td>
                                                    ))}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </Card.Body>
                            <Card.Footer>
                                <Button onClick={ onClick_Refresh } disabled={sending}>
                                    { sending ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null }{'  '}
                                    { display.buttom.refresh }
                                </Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );    
    } // viewPage

    return (
        <div className="page">
            <SettingHeader title={title} description={description} />
            { viewPage() }
        </div>
    )
}

export default MenuAccessPage;
