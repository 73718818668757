import { useEffect, useState } from 'react'

import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { ptBR } from 'date-fns/locale'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { Bar, Doughnut } from 'react-chartjs-2'
import Jet from '../../assets/Jetski.svg'
import Lancha from '../../assets/Lancha.svg'
import Loader from '../../components/loader/loader'
import OperationHeader from '../headers/operation.header'
import styles from './HourlyPrediction.module.scss'
import lclService from '../../services/lcl.service'
import authService from '../../services/auth.service'
import { dateAdd, dateToString, toDate, toInt } from '../../utilities/auxiliary-functions'
import { removeFormatDate } from '../../utilities/masks'
import { isEmpty } from '../../utilities/validators'
import { VESSEL_JETSKI } from '../../utilities/constants'

interface IHourlyPredictionProps {
    
}

export const HourlyPrediction: React.FC<IHourlyPredictionProps> = () => {

  const [ loading, setLoading ] = useState(false);
  const [ dateFilter, setDateFilter ] = useState(new Date());

  const [ rushDate, setRushDate ] = useState('');
  const [ rushHour, setRushHour ] = useState('');
  const [ expectedPeople, setExpectedPeople ] = useState('');
  const [ dearPeople, setDearPeople ] = useState('');
  const [ vessels, setVessels ] = useState([]);
  const [ checkIns, setCheckIns ] = useState([]);
  const [ forecasts, setForecasts ] = useState([]);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ChartDataLabels,
    Title,
    Tooltip,
    Legend,
    ArcElement
  )

  const barOptions = {
    responsive: true,
    gridLines: {
      display: false
    },
    plugins: {
      legend: {
        position: 'bottom' as const,
        labels: {
          color: '#FFFFFF',
          font: {
            size: 12,
            family: "'Montserrat', sans-serif"
          }
        }
      },
      datalabels: {
        color: 'white',
        font: {
          size: 12,
          family: "'Montserrat', sans-serif"
        }
      }
    },
    maintainAspectRatio: true,
    scales: {
      x: {
        ticks: {
          color: 'white',
          font: {
            size: 12,
            family: "'Montserrat', sans-serif"
          }
        },
        grid: {
          display: true,
          tickColor: 'white',
          drawOnChartArea: false,
          color: 'white'
        },
        border: {
          color: 'white'
        }
      },
      y: {
        ticks: {
          color: 'white',
          font: {
            size: 12,
            family: "'Montserrat', sans-serif"
          }
        },
        grid: {
          display: true,
          tickColor: 'white',
          drawOnChartArea: false,
          color: 'white'
        },
        border: {
          color: 'white'
        }
      }
    }
  }

  const predictedDailyData = {
    labels: checkIns.map((checkIn: any) => {
      return dateToString(checkIn.dateMovement, 'dd/mm/yyyy')
    }),
    datasets: [
      {
        label: 'Pendentes',
        data: checkIns.map((checkIn: any) => {
          return checkIn.pending
        }),
        backgroundColor: '#3232D6'
      },
      {
        label: 'Confirmados',
        data: checkIns.map((checkIn: any) => {
          return checkIn.confirmed
        }),
        backgroundColor: '#87B972',
        datalabels: {
          display: false
        }
      },
      {
        label: 'Finalizados',
        data: checkIns.map((checkIn: any) => {
          return checkIn.finished
        }),
        backgroundColor: '#FFB034',
        datalabels: {
          display: false
        }
      },
      {
        label: 'Cancelados',
        data: checkIns.map((checkIn: any) => {
          return checkIn.canceled
        }),
        backgroundColor: '#CF4147',
        datalabels: {
          display: false
        }
      }
    ]
  }

  const predictedHourlyData = {
    labels: forecasts.map((forecast: any) => forecast.departure),
    datasets: [
      {
        label: 'Previstos',
        data: forecasts.map((forecast: any) => toInt(forecast.planned)),
        backgroundColor: '#3232D6',
        barThickness: 35
      },
      {
        label: 'Realizados',
        data: forecasts.map((forecast: any) => toInt(forecast.performed)),
        backgroundColor: '#87B972',
        datalabels: {
          display: false
        },
        barThickness: 35
      }
    ]
  }

  const dataType = {
    labels: vessels.map((vessel: any) => toInt(vessel.typeVessel) === VESSEL_JETSKI ? 'Jet-Ski' : 'Lancha'),
    datasets: [
      {
        data: vessels.map((vessel: any) => {
          var some = 0;
          vessels.forEach((v: any) => {
            some += v.qty
          });

          return some > 0 ? Math.round((vessel.qty / some) * 100) : 0
        }),
        backgroundColor: ['#00007A', '#91A9AF'],
        borderWidth: 0
      }
    ]
  }

  const typeOptions = {
    responsive: true,
    cutout: 120,
    gridLines: {
      display: false
    },
    plugins: {
      legend: {
        position: 'bottom' as const,
        labels: {
          color: '#FFFFFF',
          font: {
            size: 14,
            family: "'Montserrat', sans-serif"
          }
        }
      },
      datalabels: {
        color: 'white',
        font: {
          size: 14,
          family: "'Montserrat', sans-serif"
        },
        formatter(value: any) {
          return `${value}%`
        }
      }
    }
  }
  
  async function handleClickRefresh(dateSearch: Date | null) {
    if (dateSearch === null)
      dateSearch = new Date();

      setDateFilter(dateSearch)
      const partner = authService.currentPartner();
      const view = await lclService.hourlyPrediction(partner.id, removeFormatDate(dateToString(dateSearch, 'dd/mm/yyyy')), 7);

      setRushDate(view.rushDate);
      setRushHour(view.rushHour);
      setExpectedPeople(view.expectedPeople);
      setDearPeople(view.dearPeople);
      setVessels(view.vessels);

      const localCheckIn = [] as any;
      for (var day = 0; day < 7; day++) {
        const dateNow = new Date(dateSearch);
        const dateS = removeFormatDate(dateToString(dateAdd('d', dateNow, day), 'dd/mm/yyyy'));
        const filter = view.checkIns.filter((checkIn: any) => checkIn.dateMovement === dateS);
        if (filter.length > 0)
          localCheckIn[localCheckIn.length] = filter[0]
        else 
          localCheckIn[localCheckIn.length] = {
            dateMovement: dateS,
            canceled: '0',
            confirmed: '0',
            finished: '0',
            pending: '0',
            qty: 0,
          }
      }
      setCheckIns(localCheckIn);
      
      const localForecast = [] as any;
      const hourStart = dateAdd('H', new Date(dateSearch), 8);
      for (var hour = 0; hour < 11; hour++) {
        const hourNow = dateToString(dateAdd('H', new Date(hourStart), hour), 'HH:00')
        const filter = view.forecasts.filter((forecast: any) => forecast.departure === hourNow);
        if (filter.length > 0)
          localForecast[localForecast.length] = filter[0]
        else 
          localForecast[localForecast.length] = {
            departure: hourNow,
            performed: '0',
            planned: '0',
            qty: 0,
          }
      }
      setForecasts(localForecast);
  }

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      handleClickRefresh(new Date())
      setLoading(false)
    }, 1000)
  }, [])

    function viewPage() {
        return (
            <Container fluid className={`page-body movements`}>
            {loading ? (
        <Loader />
      ) : (
        <div className={styles.hourlyPrediction}>
          <Row>
            <Col md={12} className='mb-4 d-flex justify-content-between'>
            <div className='me-2'></div>
            <div className='ms-3 d-flex'>
              <div className='d-flex flex-column align-items-center me-5' style={{width: 250}}>
                <h5>Data Pico</h5>
                <h2 className='lighter'>{isEmpty(rushDate) ? '': dateToString(toDate(rushDate, 'yyyy-mm-ddTHH:MM:ss.sssZ'), 'dd/mm/yyyy')}</h2>
              </div>
              <div className='d-flex flex-column align-items-center me-5' style={{width: 250}}>
                <h5>Horário Pico</h5>
                <h2 className='lighter'>{rushHour}</h2>
              </div>
              <div className='d-flex flex-column align-items-center me-5' style={{width: 250}}>
                <h5>Pessoas Previstas</h5>
                <h2 className='lighter'>{expectedPeople}</h2>
              </div>
              <div className='d-flex flex-column align-items-center' style={{width: 250}}>
                <h5>Pessoas Estimadas</h5>
                <h2 className='lighter'>{dearPeople}</h2>
              </div>
            </div>
            <LocalizationProvider adapterLocale={ptBR} dateAdapter={AdapterDateFns}>
              <DesktopDatePicker label="Previsão Seguinte" sx={{ width: 130, fontSize: 42 }} slotProps={{
                    textField: {
                      variant: 'standard',
                      helperText: 'Exibindo 7 dias',
                    }
                  }} 
                  value={dateFilter} 
                  onChange={(date) => handleClickRefresh(date)}
                className="mt-2 me-3"
                // minDate={new Date()}
              />
            </LocalizationProvider>
            </Col>
          </Row>
          <Row>
            <Col md={3} className="page-sweet">
            <Card>
              <Card.Header>
                <div className="card-actions float-right d-flex justify-content-end">
                  <div className="card-actions-time" />
                </div>
                <Card.Title>Embarcações</Card.Title>
              </Card.Header>
              <Card.Body>
                <Doughnut data={dataType} options={typeOptions} className="mt-3" />
                <div className="d-flex mt-1 ms-5 justify-content-start w-100">
                  {vessels.map((vessel: any, index: number) => (
                    <span key={`vessel-${index}`} className="d-flex ms-4 me-4 align-items-center">
                      <img src={toInt(vessel.typeVessel) === VESSEL_JETSKI ? Jet : Lancha} 
                        alt={toInt(vessel.typeVessel) === VESSEL_JETSKI ? 'Jet' : 'Lancha'} 
                        className="jet me-2" style={{ height: 44, width: 44 }} />
                      <span className="bolder mt-1">{vessel.qty}</span>
                    </span>
                  ))}
                </div>
              </Card.Body>
            </Card>
            </Col>
            <Col md={9} className='page-sweet'>
            <Card>
              <Card.Header>
                <div className="card-actions float-right d-flex justify-content-end">
                  <div className="card-actions-time" />
                </div>
                <Card.Title>Checkin-in</Card.Title>
              </Card.Header>
              <Card.Body>
                <Bar className="mt-5" options={barOptions} data={predictedDailyData} height={96} />
              </Card.Body>
            </Card>

            </Col>
          </Row>
          <Row>
            <Col md={12} className="page-sweet">
            <Card>
              <Card.Header>
                <div className="card-actions float-right d-flex justify-content-end">
                  <div className="card-actions-time" />
                </div>
                <Card.Title>Previsão Horária</Card.Title>
              </Card.Header>
              <Card.Body>
                <Bar className="mt-4" options={barOptions} data={predictedHourlyData} height={48} />
              </Card.Body>
            </Card>
            </Col>
          </Row>

        </div>
      )}
            </Container>
        )
    }

    return (
        <div className="page">
            <OperationHeader title="Previsão" description="" />
            {viewPage()}
        </div>
    )
}

export default HourlyPrediction
