import React from 'react'

import Navegando from '../../assets/Navegando.svg'
import styles from './loader.module.scss'

const Loader: React.FC = () => {
  return (
    <div className={`loadingQueueMessage mt-5 mb-2 ${styles.loader}`}>
      <div className={styles.rotating}>
        <img
          alt="Navegando"
          src={Navegando}
          className="jet mb-2"
          style={{ height: 46, width: 46 }}
        />
      </div>
      <h2 className="mb-5">Carregando dados, aguarde...</h2>
    </div>
  )
}

export default Loader
