import React, { useState } from 'react'

import { Button, TextField } from '@mui/material'

import { createTheme, ThemeProvider } from '@mui/material/styles'

import { useSnackbar } from 'notistack'
import styles from './CheckInReservationModal.module.scss'
import NTSModal from '../../../../components/modal/Modal'
import lclService from '../../../../services/lcl.service'
import { isEmpty } from '../../../../utilities/validators'
import { useNavigate } from 'react-router-dom'

const theme = createTheme({
    palette: {
        primary: {
            main: '#3366FF'
        },
        secondary: {
            main: '#4E5F78'
        },
        mode: 'light'
    },

    typography: {
        fontFamily: ['Montserrat', 'sans-serif'].join(',')
    }
})

export interface ICheckInReservationModalProps {
    open: boolean,
    handleClose: any,
    height: any,
    onCancel?: any,
    width: any
}

const CheckInReservationModal: React.FC<ICheckInReservationModalProps> = (props: ICheckInReservationModalProps) => {

    const [ reservation, setReservation ] = useState<string>('')
    const [ searching, setSearching ] = useState(false)
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()

    const cancel = () => {
        props.onCancel()
    }

    const handleKeypress = (e: any) => {
        if (e.charCode === 13) {
            search(e)
        }
    }

    const search = async (e: { preventDefault: () => void }) => {
        e.preventDefault()
        setSearching(true)
        try {
            const movement = await lclService.getByLocator(reservation)
            if (!isEmpty(movement)) {
                navigate(`/card/checkin/${reservation}`)
            } else {
                enqueueSnackbar('Localizador não encontrado', { variant: 'error' })
            }
        } catch (error: any) {
            enqueueSnackbar(
                (error as { message: string }).message ||
                    'Ocorreu um problema, tente novamente',
                {
                    variant: 'error'
                }
            )
        } finally {
            setSearching(false)
        }
    }

    return (
    <ThemeProvider theme={theme}>
        <NTSModal
            open={props.open}
            name="Check-in"
            handleClose={props.handleClose}
            noClose
            width={props.width}
            height={props.height}
        >
            <div className="d-flex align-items-center flex-column">
                <h5 className="mt-2 gray-text">Digite seu localizador</h5>
                <TextField
                    variant="standard"
                    margin="normal"
                    fullWidth
                    id="reservation"
                    label="Localizador"
                    name="reservation"
                    type="text"
                    onKeyPress={handleKeypress}
                    autoFocus
                    onChange={e => setReservation(e.target.value.toUpperCase())}
                    disabled={searching}
                    color="primary"
                    autoComplete='off'
                    inputProps={{
                        maxLength: 6,
                        style: { textTransform: 'uppercase' }
                    }}
                    className="submit"
                />
                <div className="mt-4 justify-content-center d-flex">
                    <Button
                        variant="outlined"
                        onClick={() => cancel()}
                        color="secondary"
                        className={`${styles.submit} me-4`}
                        disabled={searching}
                        size="large"
                    >
                        Cancelar
                    </Button>
                    <Button
                        disabled={searching || reservation === ''}
                        variant="outlined"
                        color="primary"
                        size="large"
                        className={`${styles.submit}`}
                        onClick={search}
                    >
                        {!searching ? 'BUSCAR' : 'AGUARDE...'}
                    </Button>
                </div>
            </div>
        </NTSModal>
    </ThemeProvider>
    )
}

CheckInReservationModal.defaultProps = {
  onCancel: false,
}

export default CheckInReservationModal
