import AddIcon from '@mui/icons-material/Add';
import { Button as ButtonMaterial, FormControl, FormHelperText, Input, InputAdornment, InputLabel, LinearProgress } from '@mui/material';
import ptBR from 'date-fns/locale/pt-BR';
import { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row, Spinner } from "react-bootstrap";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import DatePicker from "react-datepicker";
import { BsReceipt } from "react-icons/bs";
import { FaBarcode, FaMoneyBillAlt, FaSpinner } from "react-icons/fa";
import { MdLaunch } from "react-icons/md";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PickerButtom } from "../../components/sub-render.component";
import { useAlertMessageContext } from "../../contexts/alert-message.context";
import authService from "../../services/auth.service";
import lclService from "../../services/lcl.service";
import { dateToString, floatToString, leftPad, toDate, toInt, toLowerCase } from "../../utilities/auxiliary-functions";
import { BILLET_MODE_EDIT, BILLET_MODE_NEW, BILLET_MODE_NONE, BILLET_MODE_VIEW, ContractSituationEnum, FILTER_LAUNCHER_BY_EXPIRATION, PermissionEnum } from "../../utilities/constants";
import { cnpjMask, phoneMask, removeFormatDate, zipcodeMask } from "../../utilities/masks";
import { AlertMessageEnum } from "../../utilities/types";
import { isEmpty } from "../../utilities/validators";
import FinancialHeader from "../headers/financial.header";
import LauncherCadastreModal from "../modals/launcher.cadastre.modal";
import PaymentCadastreModal from "../modals/payment-cadastre.modal";
import DetachedReceiptReport, { TDetachedReceiptReport } from "../reports/detached-receipt.report";
import ContractReceiptReport, { TContractReceiptReport } from "../reports/contract-receipt.report";
import moment from 'moment';
import SearchIcon from '@mui/icons-material/Search';
import styles from './launchers.financial.module.scss';
import { DataGrid, GridColDef, GridSelectionModel, GridToolbar } from '@mui/x-data-grid';
import { ThemeProvider } from '@mui/material/styles';
import theme from "../../theme";
import { TFilter } from '../../types/FilterType';
import cduService from '../../services/cdu.service';
import { useTranslateContext } from '../../contexts/translate.context';
import { usePartnerContext } from '../../contexts/partner.context';

export interface IContractLaunchersFinancialProps {
}

export const ContractLaunchersFinancial: React.FC<IContractLaunchersFinancialProps> = (props: IContractLaunchersFinancialProps) => {

    const navigator = useNavigate();
    
    const alertContext = useAlertMessageContext();
    const { display } = useTranslateContext();
    const { partner } = usePartnerContext();

    const title = display.title.contract_launchers;
    const description = '';

    const [ dateTimeStart, setDateTimeStart ] = useState(dateToString(new Date(), 'dd/mm/yyyy') + ' 00:00:00');
    const [ dateTimeEnd, setDateTimeEnd ] = useState(dateToString(new Date(), 'dd/mm/yyyy') + ' 23:59:59');

    let reloadPage = false;
    const [ isLoading, setLoading ] = useState(false);
    const [ textSearch, setTextSearch ] = useState('');
    const [ showFilter, setShowFilter]  = useState(false);
    const [ sending, setSending ] = useState(false);
    const [ selectionModel, setSelectionModel ] = useState<GridSelectionModel>([]);

    let noPaged = true;
    const [ searchParams, setSearchParams ] = useSearchParams();

    const [ pageSize, setPageSize ] = useState(10);
    const [ page, setPage ] = useState(0);
    const [ pageFilled, setPageFilled ] = useState<number[]>([]);

    const [ recordCount, setRecordCount ] = useState(0);
    const [ records, setRecords ] = useState([] as any);
    const [ originalRecords, setOriginalRecords ] = useState([]);

    const [ itemsToast, setItemsToast ] = useState([] as any);

    const onChange_DateTimeStart = async (date: any) => {
        const dateStop = toDate(dateTimeEnd, 'dd/mm/yyyy HH:MM:ss');
        if (date > dateStop) {
            setDateTimeStart(dateToString(dateStop, 'dd/mm/yyyy') + ' 00:00:00');
        } else {
            setDateTimeStart(dateToString(date, 'dd/mm/yyyy HH:MM:ss'));

            let parameterMaximumDaysInConsultations = await cduService.parameterByCode(partner.companyId, '10');
            let fim = moment(date).day(toInt(parameterMaximumDaysInConsultations.content) + 1);
            setDateTimeEnd(fim.format('DD/MM/YYYY 23:59:59'));
        }

        setRecords([]);
        setOriginalRecords([]);
        setRecordCount(0);
        setPageFilled([]);
    }

    const onChange_DateTimeStop = (date: any) => {
        const dateStart = toDate(dateTimeStart, 'dd/mm/yyyy HH:MM:ss');
        if (date < dateStart) {
            setDateTimeEnd(dateToString(dateStart, 'dd/mm/yyyy') + ' 23:59:59');
        } else {
            setDateTimeEnd(dateToString(date, 'dd/mm/yyyy HH:MM:ss'));
        }

        setRecords([]);
        setOriginalRecords([]);
        setRecordCount(0);
        setPageFilled([]);
    }

    const columns: GridColDef[] = [
        { field: 'ownerName', headerName: 'Pagador', flex: 1, align: 'center', headerAlign: 'center', renderCell: renderOwner },
        { field: 'sourceName', headerName: display.label.vessel, flex: 1, align: 'center', headerAlign: 'center', renderCell: renderSource },
        { field: 'numParcel', headerName: display.label.num_parcel, align: 'center', headerAlign: 'center', renderCell: renderParcels },
        { field: 'amountParcel', headerName: display.label.amount_parcel, align: 'center', headerAlign: 'center', renderCell: renderAmountParcel },
        { field: 'expiry', headerName: display.label.expiry_parcel, align: 'center', headerAlign: 'center', renderCell: renderExpiry },
        { field: 'payment', headerName: display.label.payment, align: 'center', headerAlign: 'center', renderCell: renderPayment },
        { field: 'delayed', headerName: display.label.delayed, align: 'center', headerAlign: 'center', renderCell: renderDelayed },
        { field: 'amount', headerName: display.label.amount_payment, align: 'center', headerAlign: 'center', renderCell: renderAmountPayment },
        { field: 'paymentForm', headerName: display.label.form, align: 'center', headerAlign: 'center', renderCell: renderPaymentForm },
        {
            field: 'action',
            headerName: 'Ações',
            headerAlign: 'center',
            renderCell: renderButtons,
            disableExport: true,
        }
    ];

    function renderOwner(param: any) {
        const launcher = param.row;

        return <div>{launcher.owner.name}</div>
    }

    function renderSource(param: any) {
        const launcher = param.row;

        if (launcher.sourceDocument === "CTT") {
            return renderNameAndIdForContract({ row: launcher });
        } else
            return '';
    }

    function renderNameAndIdForContract(param: any) {
        const launcher = param.row;
        
        const renders = [] as any;
        
        for (let ind = 0; ind < launcher.vessels.length; ind++) {
            const contractVessel = launcher.vessels[ind];
            const param = { row: contractVessel.vessel };
            renders.push(renderNameAndId(param, ind));
        }
        
        return renders;
    }

    function renderNameAndId(param: any, index: number = 0) {
        const vessel = param.row;
        return (
            <div key={`name-and-id-${index}`} className="d-flex flex-column align-items-center justify-content-center">
                <div className="white-space mt-2">{(vessel.name) ? vessel.name : '-'}</div>
                <div className="white-space mt-1">{vessel.identifyKey}</div>
            </div>
        );
    }

    function renderParcels(param: any) {
        const { numParcel, qtyParcels } = param.row;

        return <div>{ numParcel } / { qtyParcels }</div>
    }

    function renderAmountParcel(param: any) {
        const { amountParcel } = param.row;

        return <div className="white-space">{ floatToString(amountParcel) }</div>
    }

    function renderExpiry(param: any) {
        const { expiry } = param.row;

        return <div className="white-space">{ dateToString(expiry, 'dd/mm/yyyy') }</div>
    }

    function renderPayment(param: any) {
        const { payment } = param.row;

        return <div className="white-space">{ dateToString(payment, 'dd/mm/yyyy') }</div>
    }

    function renderDelayed(param: any) {
        const { expiry } = param.row;

        const dateExpiry = toDate(dateToString(expiry, 'dd/mm/yyyy'), 'dd/mm/yyyy');
        const dateNow = new Date();

        let numDays = 0;
        if (dateExpiry < dateNow) {
            numDays = Math.trunc(((dateNow.getTime() - dateExpiry.getTime()) / (1000 * 60 * 60 * 24)));
        }
        
        return <div className="white-space text-center">{ numDays }</div>
    }

    function renderAmountPayment(param: any) {
        const { amount } = param.row;

        return <div className="white-space">{ floatToString(amount) }</div>
    }

    function renderPaymentForm(param: any) {
        const { paymentForm } = param.row;

        return <div className="white-space">{ display.label[toLowerCase(paymentForm)] }</div>
    }
    
    function renderButtons(param: any) {
        const launcherRow = param.row;

        return (
            (toInt(launcherRow.situation) === ContractSituationEnum.ACTIVED) &&
            <Row className="white-space table-actions">
                { isEmpty(launcherRow.payment) ? ( 
                    <Col sm="12">
                        { !isEmpty(launcherRow.paymentCode) ? (
                            <>
                                { authService.hasPermission(PermissionEnum.FINANCIAL_LAUNCHER_MAKE_PAYMENT) &&
                                    <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.make_payment}</Tooltip>}>
                                        <span onClick={(e) => onClick_PaymentLauncher(e, launcherRow)}>
                                            <FaMoneyBillAlt size={22} />
                                        </span>
                                    </OverlayTrigger>
                                }     
                                { authService.hasPermission(PermissionEnum.FINANCIAL_LAUNCHER_TRACK_STATUS) &&
                                    <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.track_status}</Tooltip>}>
                                        <span onClick={(e) => onClick_BilletLauncher(e, launcherRow, BILLET_MODE_VIEW)}>
                                            <FaBarcode size={22} />
                                        </span>
                                    </OverlayTrigger>
                                }
                            </>
                        ) : (
                            <>
                                { authService.hasPermission(PermissionEnum.FINANCIAL_LAUNCHER_MAKE_PAYMENT) &&
                                    <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.make_payment}</Tooltip>}>
                                        <span onClick={(e) => onClick_PaymentLauncher(e, launcherRow)}>
                                            <FaMoneyBillAlt size={22} />
                                        </span>
                                    </OverlayTrigger>
                                }     
                                { authService.hasPermission(PermissionEnum.FINANCIAL_LAUNCHER_GENERATE_TICKET) && 
                                    <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.generate_ticket}</Tooltip>}>
                                        <span onClick={(e) => onClick_BilletLauncher(e, launcherRow, BILLET_MODE_EDIT)}>
                                            <MdLaunch size={22} />
                                        </span>
                                    </OverlayTrigger>
                                }
                            </>
                        )}
                    </Col>
                ) : (
                    <Col sm="12">
                        { authService.hasPermission(PermissionEnum.FINANCIAL_LAUNCHER_VIEW_RECEIPT) ? ( 
                            <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.view_receipt}</Tooltip>}>
                                <span onClick={(e) => onClick_ReceiptLauncher(e, launcherRow)}>
                                    <BsReceipt size={22} />
                                </span>
                            </OverlayTrigger>
                        ) : null }
                    </Col>
                ) }
            </Row>
        );
    }

    const filterLauncher = FILTER_LAUNCHER_BY_EXPIRATION;
    const [ launcherId, setLauncherId ] = useState('');
    const [ launcher, setLauncher ] = useState({} as any);
    const [ isEditLauncher, setEditLauncher ] = useState(0);
    const [ showLauncher, setShowLauncher ] = useState(false);
    const [ showPayment, setShowPayment ] = useState(false);

    function onClick_RefreshLauncher(event: any) {
        event.preventDefault();

        setSending(true);
        searchFilter(dateTimeStart, dateTimeEnd);
    } // onClick_RefreshLauncher
    
    function onClick_AddRecord(event: any) {
        event.preventDefault();

        setEditLauncher(BILLET_MODE_NEW);
        setLauncherId('new');
        setLauncher({} as any);
        setShowLauncher(true);
    } // onClick_AddRecord

    function onClick_PaymentLauncher(event: any, launcher: any) {
        event.preventDefault();

        setLauncherId(launcher.id);
        setLauncher(launcher);
        setShowPayment(true);
    }

    function onClick_BilletLauncher(event: any, launcher: any, modo: number) {
        event.preventDefault();
        
        setEditLauncher(modo);
        setLauncherId(launcher.id);
        setLauncher(launcher);
        setShowLauncher(true);
    } // onClick_EditRecord

    async function onClick_ReceiptLauncher(event: any, launcherRow: any) {
        event.preventDefault();

        let launcher = await lclService.launcherById(launcherRow.id);
        const address = await cduService.addressById(partner.company.addressId);
        if (launcher.sourceDocument === "CTT") {
            const contract = await lclService.contractById(launcher.numberDocument);

            let receipt: TContractReceiptReport = {
                company: {
                    image: partner.company.image,
                    socialReason: partner.company.socialReason,
                    fantasy: partner.company.fantasy,
                    registrationPj: cnpjMask(partner.company.registrationPj),
                    telephone: phoneMask(partner.company.telephone),
                    email: partner.company.email,
                    address: `${address.place} - ${address.district} - ${address.city} - ${address.state} - ${zipcodeMask(address.zipcode)}`
                },
                paying: launcher.ownerName,
                amount: floatToString(launcher.amount),
                numParcel: launcher.numParcel,
                qtyParcels: launcher.qtyParcels,
                payday: dateToString(new Date(), 'dd/mm/yyyy'),
                numberDocument: launcher.sourceDocument + leftPad(launcher.numberDocument, 5, '0'),
                form: display.label[toLowerCase(launcher.paymentForm)],
                vessels: contract?.vessels.map((vesselContract: any) => {
                    return {
                        enrollment: vesselContract.vessel.enrollment,
                        name: vesselContract.vessel.name
                    }
                })
            };
            ContractReceiptReport(receipt);
        } else {
            let receipt: TDetachedReceiptReport = {
                company: {
                    image: partner.company.image,
                    socialReason: partner.company.socialReason,
                    fantasy: partner.company.fantasy,
                    registrationPj: cnpjMask(partner.company.registrationPj),
                    telephone: phoneMask(partner.company.telephone),
                    email: partner.company.email,
                    address: `${address.place} - ${address.district} - ${address.city} - ${address.state} - ${zipcodeMask(address.zipcode)}`
                },
                paying: launcher.ownerName,
                amount: floatToString(launcher.amount),
                payday: dateToString(new Date(), 'dd/mm/yyyy'),
                numberDocument: launcher.sourceDocument + leftPad(launcher.numberDocument, 5, '0'),
                descriptive : launcher?.descriptive
            };
            DetachedReceiptReport(receipt);
        }
    }

    async function searchFilter(dateTimeStart: string, dateTimeEnd: string) {
        setLoading(true);

        /* Sempre incluir o partner na pesquisa */
        if (!isEmpty(partner)) {
            try {
                setDateTimeStart(dateTimeStart);
                setDateTimeEnd(dateTimeEnd);

                let localPageSize = pageSize;
                let localPage = page;

                if (noPaged) {
                    if (searchParams.has('limit')) {
                        let paramPageSize = searchParams.get('limit');
                        if (paramPageSize) {
                            // eslint-disable-next-line react-hooks/exhaustive-deps
                            localPageSize = Number.parseInt(paramPageSize);
                            setPageSize(localPageSize);
                        }
                    }
                    if (searchParams.has('offset')) {
                        let paramPage = searchParams.get('offset');
                        if (paramPage) {
                            // eslint-disable-next-line react-hooks/exhaustive-deps
                            localPage = Number.parseInt(paramPage);
                            setPage(localPage);
                        }
                    }
                }

                if (pageFilled.indexOf(localPage) === -1) {
                    const filter = {
                        where: {
                            partnerId: partner.id
                        },
                        limit: localPageSize, 
                        offset: localPage * localPageSize
                    } as TFilter;

                    if (filterLauncher === FILTER_LAUNCHER_BY_EXPIRATION) {
                        filter.where['expiry'] = { start : removeFormatDate(dateTimeStart), end : removeFormatDate(dateTimeEnd) };
                        filter.order = [ [ 'expiry', 'ASC' ] ];
                    } else {
                        filter.where['payment'] = { start : removeFormatDate(dateTimeStart), end : removeFormatDate(dateTimeEnd) };
                        filter.order = [ [ 'payment', 'ASC' ] ];
                    }
                    if (authService.isProprietor()) {
                        const user = authService.currentUser();
                        const people = await cduService.peopleByUser(user.id);
                        filter.where['ownerType'] = 'PF';
                        filter.where['ownerId'] = people.id;
                    }

                    const launcherFilter = await lclService.launcherFilter(filter);
                    setRecordCount(launcherFilter.count);
                    const array = [] as any;
                    records.forEach((record: any) => array.push(record));
                    launcherFilter.rows.forEach((record: any) => array.push(record));

                    setRecords(array);
                    setOriginalRecords(array);
                    setSending(false);

                    if (array.length > 0)
                        pageFilled.push(localPage);
                }
            } catch(error: any) {
                await alertContext.show(AlertMessageEnum.FAIL, title, error);
            } finally {
                setSending(false);
                setLoading(false);
                setShowFilter(false);
            }
        } else {
            await alertContext.show(AlertMessageEnum.FAIL, title, 'Não foi definido uma Marina para exibir o cadastro !');
            setLoading(false);
            navigator(`/portal`);
        }
    } // searchFilter

    const onChangePageSize = (newPageSize: number) => {
        setSearchParams({...searchParams, 'limit': `${newPageSize}`, 'offset': `${0}`});

        setRecords([]);
        setOriginalRecords([]);
        setRecordCount(0);
        setPageFilled([]);
        setPageSize(newPageSize);
        setPage(0);
    }
    
    const onChangePage = (newPage: number) => {
        setSearchParams({...searchParams, 'offset': `${newPage}`});

        setPage(newPage);
    }

    let effectForPage = false;

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        noPaged = false;
        let paramPage = searchParams.get('offset');
        if (paramPage) {
            searchFilter(dateTimeStart, dateTimeEnd);
            effectForPage = true;
        }
    }, [pageSize, page]); // useEffect

    useEffect(() => {
        if (!isLoading && !reloadPage) {            
            noPaged = true;
            if (!effectForPage) {
                let inicio = moment().day(0); // domingo desta semana
                let fim = moment().day(6); // sábado desta semana

                let startDate = inicio.format('DD/MM/YYYY 00:00:00');
                let endDate = fim.format('DD/MM/YYYY 23:59:59');
                
                searchFilter(startDate, endDate);
                effectForPage = false;
            }
        }

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            reloadPage = !reloadPage;
        }
    }, []); // useEffect

    const filtered = (key?: string, value?: string) => {
        const filtered: any = []
        records.filter((record: any) => {
            /*
            if (
                record.vesselEnrollment
                .toLocaleLowerCase()
                .includes(textSearch.toLocaleLowerCase()) ||
                record.vesselName
                .toLocaleLowerCase()
                .includes(textSearch.toLocaleLowerCase()) ||
                toString(record.locator)
                .toLocaleLowerCase()
                .includes(textSearch.toLocaleLowerCase())
            )
            filtered.push(record)
            */
            return record
        })
        setRecords(filtered)
        return ''
    }

    useEffect(() => {
        if (textSearch.length >= 3) {
            filtered()
        } else if (textSearch.length < 3) {
            setRecords(originalRecords)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [textSearch]);

    function viewPage() {

        return (
            <Container fluid className="page-body lauchers">
                <Row>
                    <Col md={12} className="page-sweet">
                        <Card style={{ minHeight : 500 }}>
                            <Card.Header>
                                <div className="card-actions d-flex justify-content-between">
                                    <div className="d-flex">
                                        <div className="card-actions-time d-flex flex-row">
                                            <DatePicker
                                                locale={ptBR}
                                                selected={toDate(dateTimeStart, 'dd/mm/yyyy HH:MM:ss')}
                                                onChange={(date: any) => onChange_DateTimeStart(date) }
                                                dateFormat="dd/MM/yyyy"
                                                customInput={<PickerButtom />}
                                                selectsStart
                                                startDate={toDate(dateTimeStart, 'dd/mm/yyyy HH:MM:ss')}
                                                endDate={toDate(dateTimeEnd, 'dd/mm/yyyy HH:MM:ss')}

                                                popperClassName="some-custom-class"
                                                popperPlacement="top-end"
                                                popperModifiers={[
                                                    {
                                                        name : "offset",
                                                        options : {
                                                            offset : [5, 10]
                                                        }
                                                    },{
                                                        name : "preventOverflow",
                                                        options : {
                                                            rootBoundary : "viewport",
                                                            tether : false,
                                                            altAxis : true
                                                        }
                                                    }
                                                ]}
                                            />
                                            <DatePicker
                                                locale={ptBR}
                                                selected={toDate(dateTimeEnd, 'dd/mm/yyyy HH:MM:ss')}
                                                onChange={(date: any) => onChange_DateTimeStop(date) }
                                                dateFormat="dd/MM/yyyy"
                                                customInput={<PickerButtom />}
                                                selectsEnd
                                                startDate={toDate(dateTimeStart, 'dd/mm/yyyy HH:MM:ss')}
                                                endDate={toDate(dateTimeEnd, 'dd/mm/yyyy HH:MM:ss')}
                                                minDate={toDate(dateTimeStart, 'dd/mm/yyyy HH:MM:ss')}

                                                popperClassName="some-custom-class"
                                                popperPlacement="top-end"
                                                popperModifiers={[
                                                    {
                                                        name : "offset",
                                                        options : {
                                                            offset : [5, 10]
                                                        }
                                                    },{
                                                        name : "preventOverflow",
                                                        options : {
                                                            rootBoundary : "viewport",
                                                            altAxis : true
                                                        }
                                                    }
                                                ]}
                                            />
                                        </div>
                                        <Button variant="secondary" className='ms-2' onClick={ (e) => onClick_RefreshLauncher(e) } disabled={sending}>
                                            { sending ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <FaSpinner size={22} /> } {' '}
                                            {display.buttom.refresh}
                                        </Button>
                                    </div>
                                    {authService.hasPermission(PermissionEnum.FINANCIAL_LAUNCHER_ADD) && 
                                        <ButtonMaterial size='small' variant='contained' onClick={(e) => onClick_AddRecord(e) } ><AddIcon />Cobrança</ButtonMaterial>
                                    }
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <FormControl fullWidth sx={{width: 300, marginBottom: 2 }} variant="standard">
                                    <InputLabel className={styles.label} htmlFor="standard-adornment-amount">Pesquisar</InputLabel>
                                    <Input id="standard-adornment-amount"
                                        value={textSearch}
                                        onChange={(e) => setTextSearch(e.target.value)}
                                        endAdornment = {
                                            <InputAdornment position="end">
                                                <SearchIcon className='isClickable' />
                                            </InputAdornment>
                                        }
                                    />
                                    <FormHelperText id="component-helper-text">
                                        Regitro, Embarcação ou Localizador
                                    </FormHelperText>
                                </FormControl>
                                <ThemeProvider theme={theme}>
                                    <div style={{ height: 402, width: '100%', marginTop: 0 }}>
                                        <DataGrid
                                            rows={records}
                                            rowCount={recordCount}
                                            getRowId={row => row.id}
                                            loading={isLoading}
                                            columns={columns}
                                            pageSize={pageSize}
                                            onPageSizeChange={newPageSize => onChangePageSize(newPageSize)}
                                            page={page}
                                            onPageChange={newPage => onChangePage(newPage) }
                                            
                                            rowsPerPageOptions={[10, 25, 50, 75, 100]}
                                            disableSelectionOnClick
                                            // onCellClick={(e) => console.log(e)}
                                            onSelectionModelChange={(newSelectionModel: any) => {
                                                setSelectionModel(newSelectionModel)
                                            }}
                                            components={{
                                                Toolbar: GridToolbar,
                                                LoadingOverlay: LinearProgress
                                            }}
                                            selectionModel={selectionModel}
                                        />
                                    </div>
                                </ThemeProvider>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    } // viewCadastreList

    return (
        <div className="page financial">
            <FinancialHeader title={title} description={description} itemsToast={itemsToast}
                setItemsToast={(items: any) => {
                    setItemsToast(items);
                }} 
            />
            { viewPage() }
            <LauncherCadastreModal 
                itemsToast={itemsToast}
                setItemsToast={(items: any) => {
                    setItemsToast(items);
                }}
                isEditMode={isEditLauncher}

                show={showLauncher}
                launcherId={launcherId}
                record={launcher}
                onSubmitModal={async (event: any, record: any) => {
                    event.preventDefault();

                    setLauncher(record);
                    if (isEditLauncher) {
                        const updatedLaunchers = Array.from(records) as any;
                        for ( var idx_Edit = 0; idx_Edit < updatedLaunchers.length; idx_Edit++) {
                            if (updatedLaunchers[idx_Edit].id === record.id) {
                                updatedLaunchers[idx_Edit] = record;
                            }
                        }
                        setRecords(updatedLaunchers);
                    } else {
                        let existLauncher = false;

                        for ( var idx_New = 0; idx_New < records.length; idx_New++) {
                            const launcher = records[idx_New];
                            if (launcher.id === record.id) {
                                existLauncher = true;
                                await alertContext.show(AlertMessageEnum.FAIL, title, display.message.the_informed_record_is_already_linked);
                                break;
                            }
                        }
                        if (!existLauncher)
                            setRecords([...records, record]);
                    }

                    setShowLauncher(false);
                    setEditLauncher(BILLET_MODE_NONE);
                    searchFilter(dateTimeStart, dateTimeEnd);
                }}
                onCancelModal={(event: any) => {
                    // event.preventDefault();

                    setEditLauncher(BILLET_MODE_NONE);
                    setShowLauncher(false);
                }}
            />
            <PaymentCadastreModal 
                show={showPayment}
                launcherId={launcherId}
                record={launcher}
                onSubmitModal={(event: any, record: any) => {
                    event.preventDefault();

                    setShowPayment(false);
                    searchFilter(dateTimeStart, dateTimeEnd);
                }}
                onCancelModal={(event: any) => {
                    // event.preventDefault();

                    setShowPayment(false);
                }}
            />
        </div>
    );

}

export default ContractLaunchersFinancial;
