import { useEffect, useState } from "react";

import authService from "../../services/auth.service";
import MaritimeDashboard from "./maritime.dashboard";
import { Image } from "react-bootstrap";
import LogoRegistry from '../../assets/logo_registry.png';
import OperationManagement from "./OperationManagement";

interface IDashboardProps {
}

export const DashboardPage: React.FC<IDashboardProps> = () => {

    let reloadPage = false;
    const [ isLoading, setLoading ] = useState(false);

    async function initialize() {
    }

    useEffect(() => {
        if (!isLoading && !reloadPage) {
            initialize();
            setLoading(true);
        }

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            reloadPage = !reloadPage;
        }
    }, []);

    return (
        <>
            {authService.isGroupAssociate() ? (
                authService.isAssociateOperator() ? (
                    <OperationManagement />
                ) : (
                    <MaritimeDashboard />
                )
            ) : <div style={{ height: '100%', display: 'flex', justifyContent : 'center', alignItems: 'center' }}>
                    <Image src={LogoRegistry} style={{ height: '200px', filter: 'invert(80%) sepia(100%) saturate(2%) hue-rotate(234deg) brightness(200%) contrast(11%)'}} />
                </div>}
        </>
    )
}

export default DashboardPage;
