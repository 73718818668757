import { Col, Dropdown, Form, FormControl, FormGroup, InputGroup, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { BsSliders } from "react-icons/bs";
import { FaRegCopy, FaSearch, FaWhatsapp } from "react-icons/fa";

import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import ptBR from 'date-fns/locale/pt-BR';
import { PickerInput } from "../../components/sub-render.component";
import { dateToString, toDate, toLowerCase, toString } from '../../utilities/auxiliary-functions';
import { BILLET_MODE_VIEW } from "../../utilities/constants";
import { cpfCnpjMask, floatMask } from "../../utilities/masks";
import { DescriptionLauncherType, getDescriptionLauncher, PersonType } from "../../utilities/types";

import { useTranslateContext } from '../../contexts/translate.context';

export interface ILauncherRecordProps {
    title: any,
    isEditMode: number,

    created?: any,
    updated?: any,

    numParcel?: any,
    qtyParcels?: any,
    partnerId?: any,
    sourceDocument?: any,
    numberDocument?: any,
    situation?: any,
    confection?: any,
    amountParcel?: any,
    expiry?: any,
    description?: any,
    discount?: any,
    discountPerc?: any,
    addition?: any,
    additionPerc?: any,
    amount?: any,

    identifier?: any,
    status?: any,
    url?: any,
    barcode?: any,
    digitableLine?: any,
    pix?: any,
    qrcode?: any,

    ownerType?: any,
    ownerId?: any,
    ownerName?: any,
    ownerRegistry?: any,

    ownerPhone?: any,

    setSourceDocument?: any,
    setNumberDocument?: any,
    setPartnerId?: any,
    setNumParcel?: any,
    setQtyParcels?: any,
    setSituation?: any,
    setConfection?: any,
    setAmountParcel?: any,
    setExpiry?: any,
    setDescription?: any,
    setDiscount?: any,
    setDiscountPerc?: any,
    setAddition?: any,
    setAdditionPerc?: any,
    setAmount?: any,
    setPaymentMethod?: any,
    setChargeId?: any,
    setStatus?: any,
    setBankingBarcode?: any,
    setBankingPix?: any,
    setBankingQrcode?: any,
    setBilletLink?: any,
    setBilletPdf?: any,

    setOwnerType: any,
    setOwnerId: any,
    setOwnerName: any,
    setOwnerRegistry: any,

    setOwnerPhone : any,
    searchOwner: any,

    filterPerson: any,
    addPerson: any,

    copyPix?: any,
    copyBarcode?: any,
}

export const LauncherRecord: React.FC<ILauncherRecordProps> = (props: ILauncherRecordProps) => {

    const { display } = useTranslateContext();

    function onChange_Confection(event: any, date: any) {
        event.preventDefault();
        props.setConfection(dateToString(date, 'dd/mm/yyyy'));
    }

    function onChange_Expiry(event: any, date: any) {
        event.preventDefault();
        props.setExpiry(dateToString(date, 'dd/mm/yyyy'));
    }

    function onClick_CopyPix(event: any) {
        event.preventDefault();
        props.copyPix(event);
    }
    
    function onClick_CopyBarcode(event: any) {
        event.preventDefault();
        props.copyBarcode(event);
    }

    function openWhatsApp(event: any) {
        /*
        let url = `https://wa.me/55${props.ownerPhone}?text=Ol%C3%A1%2C%20${props.ownerName}!%0AAqui%20esta%20sua%20fatura%20com%20vencimento%20em%20${props.expiry}%2C%20no%20valor%20de%3A%20R%24%20${props.amountParcel}.%0A%0APix%3A%20${props.bankingPix}%0ABoleto%3A%20${props.bankingPix}`
        window.open(url, '_blank');
        */
    }


    function onSelect_Description(eventKey: any) {
        const descriptionLauncher = getDescriptionLauncher(eventKey);
        const value = display.label[toLowerCase(descriptionLauncher.name)];
        props.setDescription(value);
    }

    function onChange_Description(event: any) {
        const value = event.target.value;
        props.setDescription(value);
    }

    /*                                                          */ 
    function onClick_SearchOwner(event: any) {
        event.preventDefault();
        props.searchOwner(event);
    }

    function onClick_FilterPerson(event: any) {
        event.preventDefault();
        props.filterPerson(event);
    }

    function onChange_OwnerType(event: any) {
        props.setOwnerType(event.target.value);
        props.setOwnerId(0);
        props.setOwnerName('');
        props.setOwnerRegistry('');
    }

    function onClick_Person(event: any) {
        event.preventDefault();
        props.addPerson(event);
    }

    return (
        <>
            <Row>
                <Col sm="2">
                    <FormGroup>
                        <Form.Label htmlFor="form-confection">{display.label.confection}</Form.Label>
                        <DatePicker
                            locale={ptBR}
                            selected={toDate(props.confection, 'dd/mm/yyyy')}
                            onChange={(date: any, e: any) => onChange_Confection(e, date)}
                            dateFormat="dd/MM/yyyy"
                            customInput={<PickerInput />}
                            // minDate={new Date()}

                            popperClassName="some-custom-class"
                            popperPlacement="top-end"
                            popperModifiers={[{
                                    name : "offset",
                                    options : {
                                        offset : [5, 10]
                                    }
                                },{
                                    name : "preventOverflow",
                                    options : {
                                        rootBoundary : "viewport",
                                        tether : false,
                                        altAxis : true
                                    }
                            }]}
                            readOnly
                        />
                    </FormGroup>
                </Col>
                <Col sm="2">
                    <FormGroup>
                        <Form.Label htmlFor="form-expiry">{display.label.expiry_parcel}</Form.Label>
                        <DatePicker
                            locale={ptBR}
                            selected={toDate(props.expiry, 'dd/mm/yyyy')}
                            onChange={(date: any, e: any) => onChange_Expiry(e, date)}
                            dateFormat="dd/MM/yyyy"
                            customInput={<PickerInput enabled={props.isEditMode !== BILLET_MODE_VIEW} />}
                            // minDate={new Date()}

                            popperClassName="some-custom-class"
                            popperPlacement="top-end"
                            popperModifiers={[{
                                    name : "offset",
                                    options : {
                                        offset : [5, 10]
                                    }
                                },{
                                    name : "preventOverflow",
                                    options : {
                                        rootBoundary : "viewport",
                                        tether : false,
                                        altAxis : true
                                    }
                            }]}
                            disabled={props.isEditMode === BILLET_MODE_VIEW} 
                        />
                    </FormGroup>
                </Col>
                <Col sm="5"></Col>
                <Col sm="3">
                    <FormGroup>
                        <Form.Label htmlFor="form-amount_parcel">{display.label.amount_parcel}</Form.Label>
                        <FormControl type="text" id="form-amount_parcel" name="amountParcel" placeholder={display.example.amount_parcel} required
                            className="text-right"
                            value={props.amountParcel}
                            onChange={e => props.setAmountParcel(floatMask(e.target.value))}
                            disabled={props.isEditMode === BILLET_MODE_VIEW}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm="12">
                    <FormGroup>
                        <Form.Label htmlFor="form-description">{display.label.description}</Form.Label>
                        <Dropdown as={InputGroup} align={{ lg: 'end' }}  onSelect={(e) => onSelect_Description(e)}>
                            <Form.Control value={props.description} onChange={e => onChange_Description(e)} />
                            <Dropdown.Toggle split variant="primary" id="dropdown-split-basic" />
                            <Dropdown.Menu>
                                <Dropdown.Header>Selecione uma das opções abaixo</Dropdown.Header>
                                { DescriptionLauncherType.map(((iterator: any, idx: number) => (
                                    <Dropdown.Item key={idx} eventKey={iterator.id}
                                    >{display.label[toLowerCase(iterator.name)]}</Dropdown.Item>
                                ))) }
                            </Dropdown.Menu>
                        </Dropdown>
                    </FormGroup>
                </Col>
            </Row>
            <Row className="form-row-divider"><Col>{' '}</Col></Row>
            <Row className="form-row-divider">
                <Col sm="12">
                    <fieldset>
                        <legend>Tipo de Pessoa
                            <div className="card-actions float-right">
                                <Button size="small" variant="contained" onClick={(e) => onClick_Person(e)}>
                                    <AddIcon />
                                    Pessoa
                                </Button>
                            </div>
                        </legend>
                        <Row>
                            <Col sm="3">
                                <Form.Label htmlFor="form-owner-type">{display.label.owner_type} (PF / PJ)</Form.Label>
                            </Col>
                            <Col sm="9">
                                <Form.Label htmlFor="form-owner-registry">{display.label.owner_registry} (CPF / CNPJ)</Form.Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="3">
                                <Form.Select id="form-owner-type" name="ownerType" required
                                    value={props.ownerType}
                                    onChange={(e: any) => onChange_OwnerType(e)}
                                >
                                    { PersonType.map((iterator: any, idx: number) => (<option key={idx} value={iterator.id}>{iterator.name}</option>)).sort((a : any, b : any) => { return a.props.children > b.props.children ? 1 : -1; }) }
                                </Form.Select>
                            </Col>
                            <Col sm="4">
                                <InputGroup className="mb-2">
                                    <FormControl type="hidden" id="form-owner-id" name="ownerId"
                                        value={props.ownerId}
                                        onChange={e => props.setOwnerId(e.target.value)}
                                    />
                                    <FormControl id="form-owner-registry"  name="ownerRegistry" placeholder={display.example.owner_registry} required
                                        value={toString(props.ownerRegistry)}
                                        onChange={e => props.setOwnerRegistry(cpfCnpjMask(e.target.value))}
                                    />
                                    <InputGroup.Text>
                                        <a href="/#" onClick={onClick_SearchOwner}>
                                            <FaSearch />
                                        </a>
                                    </InputGroup.Text>
                                    <InputGroup.Text>
                                        <a href="/#" onClick={onClick_FilterPerson}>
                                            <BsSliders />
                                        </a>
                                    </InputGroup.Text>
                                </InputGroup>
                            </Col>
                            <Col sm="5">
                                <FormControl id="form-owner-data" name="ownerName" placeholder={display.example.owner_name} readOnly
                                    value={toString(props.ownerName)}
                                    onChange={e => props.setOwnerName(e.target.value)}
                                />
                            </Col>
                        </Row>
                    </fieldset>
                </Col>
            </Row>
            {(props.isEditMode === BILLET_MODE_VIEW) && (
            <>
                <Row>
                    <Col sm="12"><hr/></Col>
                </Row>
                <Row className="form-row-divider">
                    <Col sm="12">
                        <Row>
                            <Col sm="3">
                                <Row>
                                    <Col sm="12">
                                        <div key={1}><strong>Documento: </strong>{props.sourceDocument} - {props.numberDocument}</div>
                                        <div key={2}><strong>Parcelas: </strong>{props.numParcel} / {props.qtyParcels}</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12" className="form-row-divider"></Col>
                                </Row>
                                <Row>
                                    <Col sm="12">
                                        <FormGroup>
                                            <Form.Label htmlFor="form-discount">{display.label.discount}</Form.Label>
                                            <div className="w-box">
                                                <FormControl type="text" id="form-discount" name="discount" placeholder={display.example.discount} 
                                                    className="w-70 text-right"
                                                    value={props.discount}
                                                    onChange={e => props.setDiscount(floatMask(e.target.value))}
                                                    readOnly
                                                />
                                                <FormControl type="text" id="form-discount-perc" name="discountPerc" placeholder={display.example.discount_perc}
                                                    value={props.discountPerc}
                                                    onChange={e => props.setDiscountPerc(floatMask(e.target.value))}
                                                    className="w-30"
                                                    readOnly
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12">
                                        <FormGroup>
                                            <Form.Label htmlFor="form-addition">{display.label.addition}</Form.Label>
                                            <div className="w-box">
                                                <FormControl type="text" id="form-addition" name="addition" placeholder={display.example.addition}
                                                    className="w-70 text-right"
                                                    value={props.addition}
                                                    onChange={e => props.setAddition(floatMask(e.target.value))}
                                                    readOnly
                                                />
                                                <FormControl type="text" id="form-addition-perc" name="additionPerc" placeholder={display.example.addition_perc}
                                                    value={props.additionPerc}
                                                    onChange={e => props.setAdditionPerc(floatMask(e.target.value))}
                                                    className="w-30"
                                                    readOnly
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12">
                                        <FormGroup>
                                            <Form.Label htmlFor="form-amount">{display.label.amount_payment}</Form.Label>
                                            <FormControl type="text" id="form-amount" name="amount" placeholder={display.example.amount_payment} readOnly
                                                className="text-right"
                                                value={props.amount}
                                                onChange={e => props.setAmount(floatMask(e.target.value))}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm="9">
                                <div className="billet-payment">
                                    <Row>
                                        <Col sm="9">
                                            <h4>Pagamento</h4> 
                                            <div key={1}><strong>Identificador: </strong>{props.identifier}</div>
                                            {/*<div key={2}><strong>Forma: </strong>{props.paymentMethod}</div>*/}
                                            <div key={3}><strong>Status: </strong>{props.status}</div>
                                            { props.ownerPhone !== ''  && (
                                                <div className="whatsapp" key={4} onClick={(e) => openWhatsApp(e)}>
                                                        <FaWhatsapp size={18}/>
                                                        <small>Compartilhar</small>
                                                </div>
                                            )}
                                        </Col>
                                        <Col sm={2}><img src={props.qrcode} alt="" width={100} /></Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12"><hr/></Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12">
                                            <FormGroup>
                                                <Form.Label htmlFor="form-banking_pix">{display.label.banking_pix}</Form.Label>
                                                <InputGroup>
                                                    <FormControl type="text" id="form-banking_pix" name="bankingPix" placeholder={display.example.banking_pix} readOnly
                                                        value={props.pix}
                                                        onChange={e => props.setBankingPix(e.target.value)}
                                                    />
                                                    <InputGroup.Text>
                                                        <a href="/#" onClick={(e) => onClick_CopyPix(e)}>
                                                            <FaRegCopy />
                                                        </a>
                                                    </InputGroup.Text>
                                                </InputGroup>
                                            </FormGroup>                                
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12">
                                            <FormGroup>
                                                <Form.Label htmlFor="form-banking_barcode">{display.label.banking_barcode}</Form.Label>
                                                <InputGroup>
                                                    <FormControl type="text" id="form-banking_barcode" name="bankingBarcode" placeholder={display.example.banking_barcode} readOnly
                                                        value={props.barcode}
                                                        onChange={e => props.setBankingBarcode(e.target.value)}
                                                    />
                                                    <InputGroup.Text>
                                                        <a href="/#" onClick={(e) => onClick_CopyBarcode(e)}>
                                                            <FaRegCopy />
                                                        </a>
                                                    </InputGroup.Text>
                                                </InputGroup>
                                            </FormGroup>                                
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
            )}
        </>
    );

}

export default LauncherRecord;
