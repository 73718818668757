import { useNavigate } from "react-router-dom";

import { useTranslateContext } from "../../contexts/translate.context";
import { usePartnerContext } from "../../contexts/partner.context";
import { useAlertMessageContext } from "../../contexts/alert-message.context";

import DashboardHeader from "../headers/dashboard.header";
import { Container, Image } from "react-bootstrap";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import styles from './FinancialDashboardV2.module.scss';

import Jet from '../../assets/Jetski.svg';
import Lancha from '../../assets/Lancha.svg';
import Navegando from '../../assets/Navegando.svg';
import Abastecimento from '../../assets/Abastecimento.svg';
import Manutencao_externa from '../../assets/Manutencao_externa.svg';
import Movimento_entrada from '../../assets/Movimento_entrada.svg';
import Movimento_saida from '../../assets/Movimento_saida.svg';
import Higiene from '../../assets/Limpeza.svg';
import Warehouse from '../../assets/Warehouse.svg';
import Users from '../../assets/Users.svg';
import CashCoin from '../../assets/CashCoin.svg';
import CartCheck from '../../assets/CartCheck.svg';
import Wallet2 from '../../assets/Wallet2.svg';
import BarChartIcon from '../../assets/BarChart.svg';
import ClockHistory from '../../assets/ClockHistory.svg';
import SportsScore from '../../assets/SportsScore.svg';
import Tools from '../../assets/Tools.svg';
import Selo from '../../assets/selo.png';

import { Box } from "@mui/material";
import { ClockAndClimate } from "./components/ClockAndClimate";
import { ResumeBox, ResumeBoxCard } from "./components/ResumeBox";
import { GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import Loader from "../../components/loader/loader";
import { TFilter } from "../../types/FilterType";
import { floatMask, removeFormatDate } from "../../utilities/masks";
import lclService from "../../services/lcl.service";
import { arrivalForecast, browsingDelay, dateToString, monthName, notNullToDate, toDate, toFloat, toInt, toLowerCase, toStringFloat } from "../../utilities/auxiliary-functions";
import { isEmpty } from "../../utilities/validators";
import { AlertMessageEnum, getVessel } from "../../utilities/types";
import { VESSEL_JETSKI, VESSEL_LANCHA } from "../../utilities/constants";
import { CardDataGrid } from "./components/CardDataGrid";
import { useMediaContext } from "../../contexts/media.context";
import moment from "moment";
import { StripCard, StripBox } from "./components/StripBox";

import { Bar, BarChart, ResponsiveContainer, CartesianGrid, Cell, LabelList, Legend, Pie, PieChart, Tooltip, XAxis, YAxis } from 'recharts';

interface IFinancialDashboardProps {
}

export const FinancialDashboardV2: React.FC<IFinancialDashboardProps> = () => {

    const { isTv } = useMediaContext();

    const { display } = useTranslateContext();
    const { partner } = usePartnerContext();

    const alertContext = useAlertMessageContext();
    const navigator = useNavigate();

	const title: string = "Gestão Financeira";
	const description: string = "";

    let reloadPage: boolean = false;
    const [ loading, setLoading ] = useState(false);
    
    const [ countLancha, setCountLancha ]  = useState(0);
    const [ countJetski, setCountJetski ]  = useState(0);

    const [ sumPaid, setSumPaid ] = useState('0.00');
    const [ sumWaiting, setSumWaiting ] = useState('0.00');
    const [ sumDelay, setSumDelay ] = useState('0.00');
    const [ sumParcel, setSumParcel ] = useState('0.00');

    const [ selectedYear, setSelectedYear ] = useState('2024');

    const [ paymentPerForm, setPaymentPerForm ] = useState({} as any);
    const [ paymentPerAccompany, setPaymentPerAccompany ] = useState({} as any);

    /* constantes de objetos */
    const DropdownProps = {
        PaperProps: {
            style: {
                width: 160,
                maxHeight: 500
            }
        }
    } 

    const yearOptions = [
        {
            label: '2024',
            value: '2024'
        },{
            label: '2023',
            value: '2023'
        }
    ]

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
    const RADIAN = Math.PI / 180;
    
    const renderFormLabel = (props: any) => {

        const {
            cx,
            cy,
            midAngle,
            outerRadius,
            fill,
            payload,
            value,
        } = props;
        
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 30;
        const ey = my;
        const textAnchor = cos >= 0 ? "start" : "end";
        
        return (
            <g>
                <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                <text style={{ fontWeight: "bold" }} x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill={fill} >
                    {display.label[toLowerCase(payload.name)]}
                </text>
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999" >
                    {toStringFloat(value)}
                </text>
            </g>
        );
    };

    const CustomTooltip = (props: any) => {

        const { active, payload } = props;

        if (active && payload && payload.length) {
            return (
                <div className="financial-accompany-tooltip">
                    <span style={{color : payload[0].color }}>{`${payload[0].name} : ${toStringFloat(payload[0].value)}`}</span>
                    <span style={{color : payload[1].color }}>{`${payload[1].name} : ${toStringFloat(payload[1].value)}`}</span>
                </div>
            );
        }
        return null;
    };

    async function searchFilter() {
        let currentDateStart = moment([selectedYear, 0, 1]);
        let currentDateEnd = moment([selectedYear, 11, 31]);

        let dateStart = removeFormatDate(currentDateStart.format('DD/MM/YYYY hh:mm:ss'));
        let dateEnd = removeFormatDate(currentDateEnd.format('DD/MM/YYYY hh:mm:ss'));

        if (!isEmpty(partner)) {
            try {
                const filter = {
                    where: { 
                        'partners.partnerId': partner.id
                    }
                } as TFilter;
                let vesselFilter = await lclService.vesselFilter(filter); // {count: 0, rows: []} as any; 
                const vessels = vesselFilter.rows;

                let countJetski = 0;
                let countLancha = 0;
                vessels.forEach((vessel: any) => {
                    if (toInt(vessel.typeVessel) === VESSEL_JETSKI)
                        countJetski++
                    else
                        countLancha++
                });
                setCountJetski(countJetski);
                setCountLancha(countLancha);

                const resultMonthlyPayments = await lclService.monthlyPayment(partner.id, dateStart, dateEnd);
                if (resultMonthlyPayments.length > 0) {
                    const resultMonthlyPayment = resultMonthlyPayments[0];
                    
                    setSumPaid(floatMask(resultMonthlyPayment.sumPaid));
                    setSumWaiting(floatMask(resultMonthlyPayment.sumWaiting));
                    setSumParcel(floatMask(resultMonthlyPayment.sumParcel));
                    setSumDelay(floatMask(resultMonthlyPayment.sumDelay));
                }
                
                const resultMonthlyPaymentPerForms = await lclService.monthlyPaymentPerForm(partner.id, dateStart, dateEnd);
                const localPaymentPerForm = [] as any;
                for ( var mf = 0; mf < resultMonthlyPaymentPerForms.length; mf++ ) {
                    localPaymentPerForm[localPaymentPerForm.length] = {
                        name: resultMonthlyPaymentPerForms[mf].paymentForm,
                        value: toFloat(resultMonthlyPaymentPerForms[mf].sumParcel)
                    }
                }
                setPaymentPerForm(localPaymentPerForm);

                const resultMonthlyPaymentPerAccompanies = await lclService.monthlyPaymentPerAccompany(partner.id, dateStart, dateEnd);
                const localPaymentPerAccompany = [] as any;
                for ( var ma = 0; ma < resultMonthlyPaymentPerAccompanies.length; ma++ ) {
                    localPaymentPerAccompany[localPaymentPerAccompany.length] = {
                        name: monthName((resultMonthlyPaymentPerAccompanies[ma].monthy).toString()),
                        provided: toFloat(resultMonthlyPaymentPerAccompanies[ma].sumProvided),
                        realized: toFloat(resultMonthlyPaymentPerAccompanies[ma].sumRealized)
                    }
                }
                setPaymentPerAccompany(localPaymentPerAccompany);
            } catch(error: any) {
                await alertContext.show(AlertMessageEnum.FAIL, title, error);
            }
        }
    }

    useEffect(() => {
        if (!reloadPage) {
            reloadPage = true;
            setLoading(true);
            searchFilter().finally(() => {
                console.log('finally')
                setLoading(false);
            });
        }
    }, []);

    useEffect(() => {
        setLoading(true);
        searchFilter().finally(() => {
            setLoading(false);
        });
    }, [selectedYear]);
    
    function viewDashboard() {
        return (
            <Container fluid className="page-body dashboards">
                <StripBox>
                    <StripCard className={"ship"} label="Embarcações" datas={[{ image: Lancha, value: String(countJetski || 0) }, { image: Jet, value: String(countLancha || 0) }]} />
                    <StripCard className={"total"} label="Total Previsto" image={CashCoin} value={`R$ ${sumParcel}`} />
                    <StripCard className={"received"} label="Recebido" image={CartCheck} value={`R$ ${sumPaid}`} />
                    <StripCard className={"delay"} label="Em Atraso" image={Wallet2} value={`R$ ${sumDelay}`} />
                    <StripCard className={"opened"} label="A Vencer" image={BarChartIcon} value={`R$ ${sumWaiting}`} />
                </StripBox>
                <Box sx={{display: 'flex', justifyContent: 'flex-end', padding: '10px', gap: '10px'}}>
                    <InputLabel id="selected-year">Ano</InputLabel>
                    <FormControl variant="standard" sx={{ minWidth: 160, maxWidth: 160, fontSize: 26, marginRight: 2 }}>
                        <Select
                            sx={{fontSize: 30}}
                            labelId="selected-year"
                            id="selected-year-select"
                            value={selectedYear}
                            onChange={e =>
                                setSelectedYear(e.target.value)
                            }
                            label="Ano"
                            MenuProps={DropdownProps}
                        >
                            {yearOptions?.map(
                            (
                                option: any,
                                index: {
                                    toString: () => React.Key | null | undefined
                                }
                            ) => (
                                <MenuItem
                                    selected={selectedYear === option.value }
                                    key={index.toString()}
                                    value={option.value}
                                >
                                    {option.label}
                                </MenuItem>
                            )
                            )}
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{display: 'flex', justifyContent: 'space-around', padding: '10px', gap: 5}}>
                    <Box sx={{flexGrow: '1', display: 'flex', flexDirection: 'column'}}  >
                        <h4 className="title text-center mb-3">Previsto X Realizado</h4>
                        <ResponsiveContainer width="100%" height={400}>
                            <BarChart
                                width={500}
                                height={300}
                                data={paymentPerAccompany}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" tick={{ fill: '#FFFFFF' }} />
                                <YAxis tick={{ fill: '#FFFFFF' }}/>
                                <Tooltip content={<CustomTooltip />} />
                                <Legend />
                                <Bar barSize={100} dataKey="provided" name={display.label.provided} fill="#00C49F"> 
                                </Bar>
                                <Bar barSize={100} dataKey="realized" name={display.label.realized} fill="#FF8042">
                                </Bar>
                            </BarChart>
                        </ResponsiveContainer>
                    </Box>
                    <Box sx={{minWidth: '150px'}}>
                        <h4 className="title text-center mb-3">Tipo de Pagamento</h4>
                        {paymentPerForm && (
                            <PieChart width={400} height={400}>
                                <Pie
                                    data={paymentPerForm}
                                    //cx={120}
                                    //cy={200}
                                    innerRadius={50}
                                    outerRadius={80}
                                    fill="#8884d8"
                                    paddingAngle={5}
                                    dataKey="value"
                                    label={ renderFormLabel }
                                >
                                    {Object.keys(paymentPerForm).length > 0 && paymentPerForm.map((form: any, index: number) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}> 
                                            {`R$ ${form}`}
                                        </Cell>
                                    ))}
                                </Pie>
                                <PieChart width={400} height={400}></PieChart>
                            </PieChart>
                        )}
                    </Box>
                </Box>
                <Image src={Selo} className={`${styles.selo}`} style={{filter: 'invert(80%) sepia(100%) saturate(2%) hue-rotate(234deg) brightness(200%) contrast(101%)'}} />
            </Container>
        );
    }
    
    return (
        <div className="dashboard">
            <DashboardHeader title={title} description={description} />
            {loading ? <Loader /> : viewDashboard()}
        </div>
    );
}

export default FinancialDashboardV2;
