import { useEffect, useState } from "react";
import { Card, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { FaSave } from "react-icons/fa";
import { dateToString } from "../../../utilities/auxiliary-functions";
import { isEmpty } from "../../../utilities/validators";
import CategoryRecord from "../../records/category.record";
import { useConfirmMessageContext } from "../../../contexts/confirm-message.context";
import { removeFormatDate } from "../../../utilities/masks";
import cduService from "../../../services/cdu.service";
import { useAlertMessageContext } from "../../../contexts/alert-message.context";
import { Button } from '@mui/material'
import { AlertMessageEnum } from "../../../utilities/types";
import { useTranslateContext } from "../../../contexts/translate.context";
import { usePartnerContext } from "../../../contexts/partner.context";

export interface ICategoryCadastreFormProps {
    title: string,
    categoryId: string,
    setRecord: any
}

export const CategoryCadastreForm: React.FC<ICategoryCadastreFormProps> = (props: ICategoryCadastreFormProps) => {
    
    const alertContext = useAlertMessageContext();
    const confirmContext = useConfirmMessageContext();
    const { display } = useTranslateContext();
    const { partner } = usePartnerContext();

    const [ invalidatedForm, setInvalidatedForm ] = useState(false);
    const [ isEditForm, setEditForm ] = useState(true);

    let reloadPage = false;
    const [ isLoading, setLoading ] = useState(false);
    const [ originRecord, setOriginRecord ] = useState({} as any);
    const [ sending, setSending ] = useState(false);

    /* TRATAMENTO PARTICULAR DO OBJETO QUE ESTA SENDO MANTIDO */
    // Member
    const [ categoryId, setCategoryId ] = useState(0);
    const [ created, setCreated ] = useState(dateToString(new Date(), 'dd/mm/yyyy HH:MM:ss'));
    const [ updated, setUpdated ] = useState(dateToString(new Date(), 'dd/mm/yyyy HH:MM:ss'));

    const [ material, setMaterial ] = useState('4');
    const [ team, setTeam ] = useState('2');
    const [ group, setGroup ] = useState('');
    const [ subgroup, setSubgroup ] = useState('');
    const [ name, setName ] = useState('');

    async function fillRecord(category: any) {

        if (!isEmpty(category)) {
            setCategoryId(category.id);
            setMaterial(category.material);
            setTeam(category.team);
            setGroup(category.group);
            setSubgroup(category.subgroup);
            setName(category.name);
            setCreated(category.created);
            setUpdated(category.updated);
        }
    } // fillRecord

    async function isValidForm() {
        let result = true;
        let emptyRequired = false;

        if (isEmpty(group))
            emptyRequired = true;
        //if (isEmpty(name))
        //    emptyRequired = true;

        if (emptyRequired) {
            await alertContext.show(AlertMessageEnum.FAIL, props.title, display.message.invalid.required);
            result = false;
        }

        setInvalidatedForm(!result);
        return result;
    } // isValidForm

    async function fillCategory() {

        let dataCategory = {
            id : categoryId,
            material,
            team,
            group,
            subgroup,
            name,
            created : removeFormatDate(created), 
            updated : removeFormatDate(updated) 
        }

        return dataCategory;
    } // fillCategory

    async function searchParam() {
        try {
            if (!Number.isNaN(Number.parseInt(props.categoryId ||''))) {
                setEditForm(true);

                const category = await cduService.categoryById(Number(props.categoryId));
                setOriginRecord(category);
                fillRecord(category);
            } else {
                setEditForm(false);
            }
        } catch(error: any) {
            alertContext.show(AlertMessageEnum.FAIL, props.title, error);
        }
    } // searchParam

    useEffect(() => {
        if (!isLoading && !reloadPage) {
            searchParam();
            setLoading(true);
        }

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            reloadPage = !reloadPage;
        }
    }, []); // useEffect

    /* FUNCTION EXCLUSIVE OF FORM - START */

    function onClick_CancelCategory(event: any) {
        event.preventDefault();
        props.setRecord(event, 0)
    } // onClick_CancelCategory

    async function onClick_SaveCategory(event: any) {
        event.preventDefault();
        
        const isValid = await isValidForm();
        if (isValid) {
            try {
                const isConfirmed = await confirmContext.show(props.title, display.message.confirm_record);
                if (isConfirmed) {
                    setSending(true);

                    const category = await fillCategory();

                    if (isEditForm)
                        await cduService.saveCategory(category.id, category);
                    else
                        await cduService.createCategory(category);

                    setSending(false);
                    await alertContext.show(AlertMessageEnum.SUCCESS, props.title, display.message.the_record_has_been_saved_successfully);
                    props.setRecord(event, 0);
                }
            } catch (error: any) {
                setSending(false);
                await alertContext.show(AlertMessageEnum.FAIL, props.title, error);
            }
        }
    } // onClick_SaveCategory

    /* FUNCION EXCLUVISE OF FORM - END */

    function viewCadastreForm() {
        return (
            <Container fluid className="page-body category">
                <Row>
                    <Col md={12} className="page-sweet">
                        <Card>
                            <Card.Header>
                                {(originRecord.created || originRecord.updated) && (
                                    <div className="form-identy-registry float-right">
                                        <span className="white-space"><b>Criação: </b> {dateToString(originRecord.created, 'dd/mm/yyyy HH:MM:ss')}</span>
                                        <span className="white-space"><b>Atualização: </b> {dateToString(originRecord.updated, 'dd/mm/yyyy HH:MM:ss')}</span>
                                    </div>
                                )}
                                <Card.Title>{display.legend.record}</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Form className="form" id="form-cadastre-category" validated={invalidatedForm}>
                                    <CategoryRecord
                                        title={props.title}
                                        isEdit={isEditForm}
                                        
                                        material={material}
                                        team={team}
                                        group={group}
                                        subgroup={subgroup}
                                        name={name}

                                        setMaterial={(value: any) => { setMaterial(value); }}
                                        setTeam={(value: any) => { setTeam(value); }}
                                        setGroup={(value: any) => { setGroup(value); }}
                                        setSubgroup={(value: any) => { setSubgroup(value); }}
                                        setName={(value: any) => { setName(value); }}
                                    />
                                </Form>
                            </Card.Body>
                            <Card.Footer className="text-right">
                                <Button className="me-2" onClick={ (e) => onClick_CancelCategory(e) } variant="contained" color="secondary">{display.buttom.cancel}</Button>
                                <Button variant="contained" color="primary" onClick={ (e) => onClick_SaveCategory(e) } disabled={sending}>
                                    { sending ? <Spinner className="me-2" as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <FaSave className="me-2" size={22} /> } {' '}
                                    {display.buttom.save}
                                </Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }

    return (
        <>
            { viewCadastreForm() }
        </>
    );
    
}

export default CategoryCadastreForm;
