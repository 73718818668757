import { Card, Col, Container, Row } from "react-bootstrap";

import DashboardHeader from "../headers/dashboard.header";

interface IMonitoringDashboardProps {
    
}

export const MonitoringDashboard: React.FC<IMonitoringDashboardProps> = () => {

    const title = "Monitoramento Cadastral";
    const description = "Acompanhamento do Volume Cadastrais em Geral";
  
    function viewDashboard() {
        return (
            <Container>
                <Row>
                    <Col sm={3}>
                        <Card>
                            <Card.Header>Usuarios</Card.Header>
                            <Card.Body></Card.Body>
                            <Card.Footer></Card.Footer>
                        </Card>
                    </Col>
                    <Col sm={3}>
                        <Card>
                            <Card.Header>Parceiros</Card.Header>
                            <Card.Body></Card.Body>
                            <Card.Footer></Card.Footer>
                        </Card>
                    </Col>
                    <Col sm={6}>
                        <Card>
                            <Card.Header>Mensagens</Card.Header>
                            <Card.Body></Card.Body>
                            <Card.Footer></Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }

    return (
        <div className="dashboard">
            <DashboardHeader title={title} description={description} />
            {viewDashboard()}
        </div>
    );
}

export default MonitoringDashboard;
