import env from 'react-dotenv'
import React from 'react'

import QRCode from 'react-qr-code'

import styles from './CheckInCard.module.scss'
import Jet from '../../../../assets/Jetski.svg'
import Lancha from '../../../../assets/Lancha.svg'
import Logo from '../../../../assets/N.png'
import { toInt } from '../../../../utilities/auxiliary-functions'

export interface ICheckInCardProps {
    navegation: string,
    departure: string,
    forecast: string,
    locator: string,
    checkIn: string,
    conductor: string,
    vesselName: string,
    vesselType: string,
    vesselModel: string,
    vesselYear: string,
    vesselEnrollment: string,
    vesselSize: string,
    vesselColor: string,
    keepBookcaseName: string,
    keepShelfName: string,
    keepDrawerName: string
}

const CheckInCard: React.FC<ICheckInCardProps> = (props: ICheckInCardProps) => {

    const url = env.URL_CARD
    const windowQrcode = `${url}${props.vesselEnrollment}`

    const openQRCode = (event: any) => {
        event.preventDefault()

        const win = window.open(
            '',
            '',
            'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=yes,width=500,height=830,top=100,left=200'
        )
        if (win) {
            win.document.write(`<html>`)
            win.document.write(`<head>`)
            win.document.write(`<title>Cartão da Embarcação</title>`)
            win.document.write(
                `<meta http-equiv="Content-Type" content="text/html; charset=iso-8859-1" />`
            )
            win.document.write(
                `<style style rel="stylesheet" type="text/css">body { font-size: 1em; color: #fff; background: #303A4A; width: vw; height: vh; margin: 0; padding: 0; }</style>`
            )
            win.document.write(`</head>`)
            win.document.write(`<body>`)
            win.document.write(
                `<object type="application/pdf" width="100%" height="100%" data="${windowQrcode}">The “object” tag is not supported by your browser.</object>`
            )
            win.document.write(`</body>`)
            win.document.write(`</html>`)
        }
    }

    return (
        <div className={styles.checkInCard}>
        <div className={styles.header}>
            <img src={Logo} alt="Avatar" className={styles.logo} />
            <div className="me-4" />
            <h5 className="text-center ms-5 mt-3">{props.navegation}</h5>
            <div>
            <small>LOCALIZADOR</small>
            <h4>{props.locator}</h4>
            </div>
        </div>
        <h4 className="text-center mt-2">{props.conductor}</h4>
        <div
            className={`d-flex justify-content-between align-items-center ${styles.reservationInfo}`}
        >
            <div
            className="d-flex flex-column align-items-start"
            style={{ minWidth: '80px' }}
            >
            <p>SAÍDA</p>
            <h4>{props.departure}</h4>
            </div>
            <div className={styles.separator} />
            <div
            className={`d-flex flex-column align-items-center justify-content-center mb-2 ${styles.vesselWrapper}`}
            >
            <div className="d-flex justify-content-center mb-2">
                <img
                alt="JetSki"
                src={toInt(props.vesselType) === 33 ? Jet : Lancha}
                className="jet mt-3"
                style={{
                    height: 36,
                    filter: 'invert()'
                }}
                />
                <small className="ms-1 mt-3">{props.vesselSize}</small>
            </div>
            <span
                className={styles.vesselColor}
                style={{ backgroundColor: props.vesselColor }}
            />
            </div>
            <div className="d-flex flex-column align-items-center">
            <p>PREVISÃO</p>
            <h4>{props.forecast}</h4>
            </div>
        </div>
        <div
            className={`d-flex justify-content-between w-100 ${styles.ticketCut}`}
        >
            <span />
            <span />
        </div>
        <section className={styles.section}>
            <h4 className="text-center">{props.vesselName}</h4>
            <div className={`d-flex justify-content-between ${styles.vesselInfo}`}>
            <div className="d-flex flex-column">
                <h6>Modelo: {props.vesselModel}</h6>
                <h6>Ano: {props.vesselYear}</h6>
                <h6>Registro: {props.vesselEnrollment}</h6>
            </div>
            <QRCode
                bgColor="#FFFFFF"
                fgColor="#000000"
                value={windowQrcode}
                size={70}
                onClick={openQRCode}
            />
            </div>
        </section>
        <div
            className={`d-flex justify-content-between w-100 ${styles.ticketCut}`}
        >
            <span />
            <span />
        </div>
        <section
            className={`d-flex flex-column justify-content-center align-items-center ${styles.section}`}
        >
            <div>
            <h6 className="text-center">POSIÇÃO DE GUARDA</h6>
            <div className="d-flex flex-column align-items-center">
                <div className={styles.vesselGarage}>
                <div>
                    <p>{props.keepBookcaseName || 'Não Informada'}</p>
                    <p>{`${props.keepShelfName || 'Não Informada'} - ${
                    props.keepDrawerName || 'Não Informada'
                    }`}</p>
                </div>
                </div>
            </div>
            </div>
        </section>
        <div
            className={`${styles.checkinStatus} 
                ${props.checkIn === 'confirmed' ? styles.confirmed : ''}
                ${props.checkIn === 'pending' ? styles.pending : ''}
                ${props.checkIn === 'canceled' ? styles.canceled : ''}
                ${props.checkIn === 'finished' ? styles.finished : ''}
            `}
        >
            <h5 className="text-center">
            {props.checkIn === 'pending'
                ? 'CHECK-IN PENDENTE'
                : props.checkIn === 'confirmed'
                ? 'CHECK-IN REALIZADO'
                : props.checkIn === 'canceled'
                ? 'RESERVA CANCELADA'
                : 'RESERVA FINALIZADA'}
            </h5>
        </div>
        </div>
    )
}

export default CheckInCard
