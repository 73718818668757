import { useEffect, useState } from "react";
import { Button } from '@mui/material'
import { Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import cduService from "../../services/cdu.service";
import lclService from "../../services/lcl.service";
import { dateToString, floatToString, lastDayOdMonth, leftPad, notNullToDate, toDate, toFloat, toInt, toLowerCase, toString, toStringFloat } from "../../utilities/auxiliary-functions";
import { ContractProgressEnum, ContractSituationEnum, PersonEnum } from "../../utilities/constants";
import { cnpjMask, cpfMask, floatMask, phoneMask, removeFormatDate, removeMask, sizeMask, zipcodeMask } from "../../utilities/masks";
import ContractRecord from "../records/contract.record";
import VesselFilter from "../filters/vessel.filter";
import LauncherCadastreModal from "../modals/launcher.cadastre.modal";
import PaymentCadastreModal from "../modals/payment-cadastre.modal";
import { FaTimes } from "react-icons/fa";
import { BILLET_MODE_NONE } from "../../utilities/constants";
import { useAlertMessageContext } from "../../contexts/alert-message.context";
import { useConfirmMessageContext } from "../../contexts/confirm-message.context";
import { useDeleteMessageContext } from "../../contexts/delete-message.context";
import VesselResponsibleCadastreModal from "./vessel-responsible.cadastre.modal";
import { AlertMessageEnum, getColorDefault } from "../../utilities/types";
import ContractReport, { TContractReport } from "../reports/contract.report";
import { isEmpty } from "../../utilities/validators";
import { openDocument } from "../../utilities/view-document";
import { useTranslateContext } from "../../contexts/translate.context";
import { usePartnerContext } from "../../contexts/partner.context";

export const DEFAULT_VALIDITY_DAYS = 365;
export const DEFAULT_PRICE_PER_FEET = 50.00;
export const DEFAULT_QTY_PARCELS = 1
export const DEFAULT_START_BILLING_IN = 1
export const DEFAULT_EXPIRATION_DAY = 10

export interface IContractCadastreModalProps {
    isEdit: boolean,
    typeFilter?: any,
    subTitle?: any,
    show: boolean,
    contractId: string,
    record: any;
    onSubmitModal: any,
    onCancelModal: any,
    setShowContract?: any
}

export const ContractCadastreModal: React.FC<IContractCadastreModalProps> = (props: IContractCadastreModalProps) => {

    const STEP_ADVANCE = 'advance';
    const STEP_GO_BACK = 'go_back';

    const alertContext = useAlertMessageContext();
    const confirmContext = useConfirmMessageContext();
    const deleteContext = useDeleteMessageContext();
    const { display } = useTranslateContext();
    const { partner } = usePartnerContext();

    const title : string = display.title.contract_cadastre;

    const [ invalidatedCadastreForm, setInvalidatedCadastreForm ] = useState(false);

    const [ originRecord, setOriginRecord ] = useState({} as any);
    const [ sending, setSending ] = useState(false);
    const [ showFilterVessel, setShowFilterVessel ] = useState(false);

    // Contract
    const [ contractId, setContractId ] = useState(0);
    const [ contractKey, setContractKey ] = useState('');
    const [ created, setCreated ] = useState(dateToString(new Date(), 'dd/mm/yyyy'));
    const [ updated, setUpdated ] = useState(dateToString(new Date(), 'dd/mm/yyyy'));

    const [ situation, setSituation ] = useState(ContractSituationEnum.INACTIVE);
    const [ confection, setConfection ] = useState(dateToString(new Date(), 'dd/mm/yyyy'));
    const [ progress, setProgress ] = useState(ContractProgressEnum.ELABORATION);

    let [ vessels, setVessels ] = useState([] as any);
    const [ responsibles, setResponsibles ] = useState([] as any);

    const [ indCalculated, setIndCalculated ] = useState(false);
    const [ pricePerFeet, setPricePerFeet ] = useState(`${DEFAULT_PRICE_PER_FEET}`);
    const [ totalVessels, setTotalVessels ] = useState('');
    const [ totalOfFeet, setTotalOfFeet ] = useState('');
    const [ qtyParcels, setQtyParcels ] = useState(`${DEFAULT_QTY_PARCELS}`);
    const [ expected, setExpected ] = useState('');
    const [ discount, setDiscount ] = useState('');
    const [ discountPerc, setDiscountPerc ] = useState('');
    const [ addition, setAddition ] = useState('');
    const [ additionPerc, setAdditionPerc ] = useState('');
    const [ amount, setAmount ] = useState('');
    const [ validity, setValidity ] = useState(dateToString(new Date(), 'dd/mm/yyyy'));
    const [ startBillingIn, setStartBillingIn ] = useState(`${DEFAULT_START_BILLING_IN}`);
    const [ expirationDay, setExpirationDay ] = useState(`${DEFAULT_EXPIRATION_DAY}`);
    const [ generatedPlots, setGeneratedPlots ] = useState(false);
    const [ accept, setAccept ] = useState('');
    const [ signedBy, setSignedBy ] = useState([]);
    const [ indAverage, setIndAverage ] = useState(false);

    const [ monthlies, setMonthlies ] = useState([] as any);
    const [ totalAmountParcel, setTotalAmountParcel ] = useState('');
    const [ contractualDifference, setContractualDifference ] = useState('');

    const [ itemsToast, setItemsToast ] = useState([] as any);
    
    const [ launcherId, setLauncherId ] = useState('');
    const [ launcher, setLauncher ] = useState({} as any);
    const [ isEditLauncher, setEditLauncher ] = useState(0);
    const [ showLauncher, setShowLauncher ] = useState(false);
    const [ showPayment, setShowPayment ] = useState(false);
    const [ responsibleId, setResponsibleId ] = useState('');
    const [ responsible, setResponsible ] = useState({} as any);
    const [ isEditResponsible, setEditResponsible ] = useState(false);
    const [ showResponsible, setShowResponsible ] = useState(false);

    type ArtifactType = { id : number, norm : string, source : string, identy : string, document : string, filename : string };
    const DEFAULT_ARTIFACT: ArtifactType = { id : 0, norm : '', source : '', identy : '', document : '', filename : '' };

    const [ signatureDocuments, setSignatureDocuments ] = useState([ DEFAULT_ARTIFACT ] as any);

    async function isValidForm() {
        let result = true;
        let emptyRequired = false;

        if (toInt(qtyParcels) === 0)
            result = false;
        if (toFloat(totalAmountParcel) < toFloat(amount))
            result = false;
        if (toFloat(contractualDifference) > toFloat(totalAmountParcel))
            result = false;

        if (vessels.length === 0) {
            await alertContext.show(AlertMessageEnum.FAIL, title, 'Não foi informado nenhuma embarcação para o contrato !!!');
            result = false;
        }
        if (responsibles.length === 0) {
            await alertContext.show(AlertMessageEnum.FAIL, title, 'Não foi informado nenhuma proprietário para o contrato !!!');
            result = false;
        }
        if (monthlies.length === 0) {
            await alertContext.show(AlertMessageEnum.FAIL, title, 'Não foi gerado nenhuma mensalidade para este contrato !!!');
            result = false;
        } else {
            if (!indAverage) {
                let isAverage = false;
                for (var r = 0; r < responsibles.length; r++ ) {
                    const responsible = responsibles[r];
                    if (responsible.officialPayer)
                        isAverage = true;
                }

                if (!isAverage) {
                    await alertContext.show(AlertMessageEnum.FAIL, title, 'Contrato sem cota, deve ser informado ao menos um pagador !!!');
                    result = false;
                }
            }
        }

        if (emptyRequired) {
            await alertContext.show(AlertMessageEnum.FAIL, title, display.message.invalid.required);
            result = false;
        }

        setInvalidatedCadastreForm(!result);
        return result;
    }

    async function onClick_Confirm(event: any, step: string) {
        event.preventDefault();

        if (toInt(progress) < ContractProgressEnum.FINISHED) {
            const isValid = await isValidForm();
            if (isValid) {
                try {
                    const isConfirmed = await confirmContext.show(title, display.message.confirm_record);
                    if (isConfirmed) {
                        setSending(true);

                        const contract = fillContract(step);
                        let existContract = contract.id > 0;

                        let returned = null;
                        if (contract.id > 0) {
                            returned = await lclService.saveContract(contract.id, contract);
                        } else {
                            returned = await lclService.createContract(contract);
                            contract.id = returned.id
                        }

                        if (existContract) {
                            for ( let d = 0; d < signatureDocuments.length; d++ ) {
                                const signatureDocument = signatureDocuments[d];

                                if (signatureDocument) {
                
                                    if ((signatureDocument.id > 0) && !isEmpty(signatureDocument.document)) {
                                        await cduService.saveArtifact(signatureDocument.id, signatureDocument);
                                    } else if ((signatureDocument.id > 0) && isEmpty(signatureDocument.document)) {
                                        await cduService.artifactDeleteById(signatureDocument.id);
                                    } else if ((signatureDocument.id === 0) && !isEmpty(signatureDocument.document)) {
                                        await cduService.createArtifact(signatureDocument);
                                    }   
                                }
                            }
                        }

                        setSending(false);
                        await alertContext.show(AlertMessageEnum.SUCCESS, title, display.message.the_record_has_been_saved_successfully);
                        props.onSubmitModal(event, contract);
                    }
                } catch (error: any) {
                    setSending(false);
                    await alertContext.show(AlertMessageEnum.FAIL, title, error);
                }
            }
        } else {
            props.onSubmitModal(event, originRecord);
        }
    }

    async function onClick_Cancel(event : any) {
        event.preventDefault();

        setInvalidatedCadastreForm(false);
        props.onCancelModal(event);
    }

    async function onClick_Print(event : any) {
        event.preventDefault();

        const attributes = [] as any;
        const where = {
            partnerId: partner.id,
            situation: 5,
            type: 'CAG'
        } as any;
        const order = [] as any;

        const contracts = await lclService.partnerContractFilter(attributes, where, order);
        if (contracts) {
            const contract = contracts[0];
            let template = contract.content;

            if (!isEmpty(template)) {
                const tagStartLoopOwner = "%inicio_laco_proprietario%";
                const tagEndLoopOwner = "%fim_laco_proprietario%";

                let startLoopOwner = 0
                let endLoopOwner = 0
                let trechoOwner = '';

                if (template.indexOf(tagStartLoopOwner) > -1) {
                    startLoopOwner = template.indexOf(tagStartLoopOwner);
                    endLoopOwner = template.indexOf(tagEndLoopOwner) + tagEndLoopOwner.length;
                    trechoOwner = template.substring(startLoopOwner, endLoopOwner);

                    let trechoNowithLoop = template.substring(startLoopOwner + tagStartLoopOwner.length, template.indexOf(tagEndLoopOwner));
                    let replacedTrechoOwner = '';
                    for (var r = 0; r < responsibles.length; r++) {
                        const responsible = responsibles[r];

                        let trecho = trechoNowithLoop;
                        trecho = trecho.replace('%proprietario.nome_completo%', toString(responsible.owner.fullname));
                        trecho = trecho.replace('%proprietario.nacionalidade%', toString(responsible.owner.nationality));
                        trecho = trecho.replace('%proprietario.identidade%', toString(responsible.owner.registrationIdentity));
                        trecho = trecho.replace('%proprietario.cpf%', cpfMask(responsible.owner.registrationPf));
                        trecho = trecho.replace('%endereco.logradouro%', toString(responsible.owner.adresses[0]?.address?.place));
                        trecho = trecho.replace('%endereco.numero%', toString(responsible.owner.adresses[0]?.habitation));
                        trecho = trecho.replace('%endereco.bairro%', toString(responsible.owner.adresses[0]?.address?.district));
                        trecho = trecho.replace('%endereco.cep%', toString(responsible.owner.adresses[0]?.address?.zipcode));
                        trecho = trecho.replace('%endereco.cidade%', toString(responsible.owner.adresses[0]?.address?.city));
                        trecho = trecho.replace('%endereco.estado%', toString(responsible.owner.adresses[0]?.address?.state));

                        replacedTrechoOwner += trecho;
                    }
                    template = template.replace(trechoOwner, replacedTrechoOwner);
                } else {
                    const responsible = responsibles[0];
                    template = template.replace('%proprietario.nome_completo%', toString(responsible.owner.fullname));
                    template = template.replace('%proprietario.nacionalidade%', toString(responsible.owner.nationality));
                    template = template.replace('%proprietario.identidade%', toString(responsible.owner.registrationIdentity));
                    template = template.replace('%proprietario.cpf%', cpfMask(responsible.owner.registrationPf));
                    template = template.replace('%endereco.logradouro%', toString(responsible.owner.adresses[0]?.address?.place));
                    template = template.replace('%endereco.numero%', toString(responsible.owner.adresses[0]?.habitation));
                    template = template.replace('%endereco.bairro%', toString(responsible.owner.adresses[0]?.address?.district));
                    template = template.replace('%endereco.cep%', toString(responsible.owner.adresses[0]?.address?.zipcode));
                    template = template.replace('%endereco.cidade%', toString(responsible.owner.adresses[0]?.address?.city));
                    template = template.replace('%endereco.estado%', toString(responsible.owner.adresses[0]?.address?.state));
                }

                const tagStartLoopVessel = "%inicio_laco_embarcacao%";
                const tagEndLoopVessel = "%fim_laco_embarcacao%";

                let startLoopVessel = 0
                let endLoopVessel = 0
                let trechoVessel = '';

                if (template.indexOf(tagStartLoopVessel) > -1) {
                    startLoopVessel = template.indexOf(tagStartLoopVessel);
                    endLoopVessel = template.indexOf(tagEndLoopVessel) + tagEndLoopVessel.length;
                    trechoVessel = template.substring(startLoopVessel, endLoopVessel);

                    let trechoNowithLoop = template.substring(startLoopVessel + tagStartLoopVessel.length, template.indexOf(tagEndLoopVessel));
                    let replacedTrechoVessel = '';
                    for (var v = 0; v < vessels.length; v++) {
                        const vessel = vessels[v];

                        let trecho = trechoNowithLoop;
                        trecho = trecho.replace('%embarcacao.chave_id%', toString(vessel.identifyKey));
                        trecho = trecho.replace('%embarcacao.nome%', toString(vessel.name));
                        trecho = trecho.replace('%embarcacao.tipo%', toString(vessel.typeVessel));
                        trecho = trecho.replace('%embarcacao.cor%', display.label[toLowerCase(getColorDefault(vessel.color).name)]);
                        trecho = trecho.replace('%embarcacao.registro%', toString(vessel.enrollment));
                        trecho = trecho.replace('%embarcacao.fabricante%', toString(vessel.manufacturer));
                        trecho = trecho.replace('%embarcacao.marca%', toString(vessel.brand));
                        trecho = trecho.replace('%embarcacao.modelo%', toString(vessel.model));
                        replacedTrechoVessel += trecho;
                    }
                    template = template.replace(trechoVessel, replacedTrechoVessel);
                } else {
                    const vessel = vessels[0];
                    template = template.replace('%embarcacao.chave_id%', toString(vessel.identifyKey));
                    template = template.replace('%embarcacao.nome%', toString(vessel.name));
                    template = template.replace('%embarcacao.tipo%', toString(vessel.typeVessel));
                    template = template.replace('%embarcacao.cor%', display.label[toLowerCase(getColorDefault(vessel.color).name)]);
                    template = template.replace('%embarcacao.registro%', toString(vessel.enrollment));
                    template = template.replace('%embarcacao.fabricante%', toString(vessel.manufacturer));
                    template = template.replace('%embarcacao.marca%', toString(vessel.brand));
                    template = template.replace('%embarcacao.modelo%', toString(vessel.model));
                }
                const valor = toFloat(totalAmountParcel) / toInt(qtyParcels);

                template = template.replace('%contrato.valor_parcela%', floatMask(String(valor)));
                template = template.replace('%contrato.qtd_parcelas%', toString(qtyParcels));

                const address = await cduService.addressById(partner.company.addressId);
                let contractReceipt: TContractReport = {
                    content : template,
                    company: {
                        image: partner.company.image,
                        socialReason: partner.company.socialReason,
                        fantasy: partner.company.fantasy,
                        registrationPj: cnpjMask(partner.company.registrationPj),
                        telephone: phoneMask(partner.company.telephone),
                        email: partner.company.email,
                        address: `${address.place} - ${address.district} - ${address.city} - ${address.state} - ${zipcodeMask(address.zipcode)}`
                    }
                }
                ContractReport(contractReceipt);
            } else {
                await alertContext.show(AlertMessageEnum.WARN, title, 'O template de contrato está vazio !!!');
            }
        } else {
            await alertContext.show(AlertMessageEnum.WARN, title, 'Não foi cadastrado um template para a geração do contrato !!!');
        }
    }

    function clearContract() {
        var now = new Date();
        setContractId(0);
        setContractKey(dateToString(now, 'yyyymm') + leftPad('0', 4, '0'));
        setCreated('');
        setUpdated('');

        setSituation(ContractSituationEnum.INACTIVE);
        setConfection(dateToString(now, 'dd/mm/yyyy'));
        setProgress(ContractProgressEnum.ELABORATION);
    
        setVessels([] as any);
        setResponsibles([] as any);

        setIndCalculated(false);
        setPricePerFeet(floatToString(DEFAULT_PRICE_PER_FEET));
        setTotalVessels('');
        setTotalOfFeet('');
        setQtyParcels(`${DEFAULT_QTY_PARCELS}`);
        setExpected('');

        setDiscount('');
        setDiscountPerc('');
        setAddition('');
        setAdditionPerc('');
        setAmount('');

        now.setDate(now.getDate() + DEFAULT_VALIDITY_DAYS);
        setValidity(dateToString(now, 'dd/mm/yyyy'));
        setStartBillingIn(`${DEFAULT_START_BILLING_IN}`);
        setExpirationDay(`${DEFAULT_EXPIRATION_DAY}`);
        setIndAverage(false);

        setGeneratedPlots(false);
        setMonthlies([] as any);
        setTotalAmountParcel('');
        setContractualDifference('');
    }

    function fillRecord(contract: any) {

        if (contract != null) {
            setContractId(contract.id);
            setContractKey(dateToString(contract.confection, 'yyyymm') + leftPad(contract.id, 4, '0'));
            setCreated(contract.created);
            setUpdated(contract.updated);

            setSituation(contract.situation);
            setConfection(dateToString(contract.confection, 'dd/mm/yyyy'));
            setProgress(contract.progress);

            const localVessels = [] as any;
            for ( var fv = 0; fv < contract.vessels.length; fv++) {
                let contractVessel = contract.vessels[fv];
                if (contractVessel.vessel)
                    localVessels[fv] = contractVessel.vessel;
            }
            setVessels(localVessels);
            setResponsibles(contract.responsibles || [] as any);

            setIndCalculated(Boolean(contract.indCalculated));
            setPricePerFeet(floatMask(contract.pricePerFeet));
            setTotalVessels(contract.totalVessels);
            setTotalOfFeet(sizeMask(contract.totalOfFeet));
            setQtyParcels(contract.qtyParcels);
            setExpected(floatMask(contract.expected));
            setDiscount(floatMask(contract.discount));
            let localPercDiscount = 100 - (((contract.expected - contract.discount) / contract.expected) * 100);
                localPercDiscount = toFloat(localPercDiscount.toFixed(2))
            setDiscountPerc(floatMask(String(localPercDiscount)));

            setAddition(floatMask(contract.addition));
            let localPercAddition = 100 - (((contract.expected - contract.addition) / contract.expected) * 100);
                localPercAddition = toFloat(localPercAddition.toFixed(2));
            setAdditionPerc(floatMask(String(localPercAddition)));
            setAmount(floatMask(contract.amount));

            setValidity(dateToString(contract.validity, 'dd/mm/yyyy'));
            setStartBillingIn(contract.startBillingIn);
            setExpirationDay(contract.expirationDay);
            setIndAverage(contract.indAverage ? contract.indAverage : false);

            setGeneratedPlots(contract.generatedPlots);
            
            let totalAmountParcel = 0;
            const localMonthly = Array<any>();
            if (contract.monthlies) {
                for (var idx = 0; idx < contract.monthlies.length; idx++) {
                    const monthly = contract.monthlies[idx];
                    totalAmountParcel += toFloat(toStringFloat(monthly.amountParcel));

                    let status = monthly.paymentData ? monthly.paymentData.situation : 'none';
                    if ((monthly.paymentData) && (status === 'waiting')) {
                        if (Date < monthly.expiry)
                            status = 'pay'
                    }

                    const itemMonthly = {
                        addition : monthly.addition,
                        amount : monthly.amount,
                        amountParcel : floatMask(monthly.amountParcel),
                        confection : dateToString(monthly.confection, 'dd/mm/yyyy HH:MM:ss'),
                        created : dateToString(monthly.created, 'dd/mm/yyyy HH:MM:ss'),
                        creditAccount : monthly.creditAccount,
                        expiry : dateToString(monthly.expiry, 'dd/mm/yyyy'),
                        flag : monthly.flag,
                        group : monthly.group,
                        id : monthly.id,
                        owner : {
                           type: contract.ownerType,
                           id : contract.ownerId,
                           name : contract.ownerName
                        },
                        numParcel : monthly.numParcel,
                        numberDocument : monthly.numberDocument,
                        partnerId : monthly.partnerId,
                        payment : monthly.payment,
                        paymentCode : monthly.paymentCode,
                        paymentData : monthly.paymentData,
                        paymentForm : monthly.paymentForm,
                        qtyParcels : monthly.qtyParcels,
                        situation,
                        sourceDocument : monthly.sourceDocument,
                        updated : dateToString(monthly.updated, 'dd/mm/yyyy HH:MM:ss'),

                        status : status,
                    }

                    localMonthly[idx] = itemMonthly;
                }
            }
            setMonthlies(localMonthly || [] as any);

            setTotalAmountParcel(floatToString(totalAmountParcel));
            setContractualDifference(floatMask(floatToString(totalAmountParcel - contract.amount)));
/*
            setSignatures([
                { id : 0, group : 'Segurança', category : '', name : 'Extintor', unity : '', quantity : '', amount : '' }
            ]);
*/
        }
    }

    function fillContract(step: string) {
        let checkProgress = !props.isEdit ? 1 : step === STEP_ADVANCE ? toInt(progress) + 1 : toInt(progress) - 1;
        let checkSituation = (checkProgress === ContractProgressEnum.FINISHED) ? ContractSituationEnum.ACTIVED : situation;

        let dataContract = {} as any;

        if (checkProgress < ContractProgressEnum.SIGNATURE) {
            const dataMonthlies = [] as any;
            monthlies.forEach((item: any, index: number) => {

                dataMonthlies[dataMonthlies.length] = {
                    id : 0,
                    partnerId : partner.id,
                    situation : checkSituation,
                    confection : removeFormatDate(dateToString(new Date(), 'dd/mm/yyyy HH:MM:ss')),
                    numberDocument : contractId, 
                    numParcel : item.numParcel, //index + 1,
                    qtyParcels : qtyParcels,
                    expiry : removeFormatDate(item.expiry),
                    amountParcel : removeMask(item.amountParcel, '0'),
                    ownerType : item.ownerType,
                    ownerId : item.ownerId, 
                    owner : item.owner,
                    description : item.description
                }
            });

            const dataVessels = [] as any;
            for (var v = 0; v < vessels.length; v++) {
                const vessel = vessels[v];
                dataVessels[dataVessels.length] = {
                    contractId : contractId,
                    vesselId : vessel.id,
                    vessel : vessel
                }
            }

            const dataResponsabiles = [] as any;
            for (var r = 0; r < responsibles.length; r++) {
                const responsible = responsibles[r];
                dataResponsabiles[dataResponsabiles.length] = {
                    id : responsible.id,
                    contractId : contractId,
                    ownerType : responsible.ownerType,
                    ownerId : responsible.ownerId, 
                    owner : responsible.owner,
                    officialPayer : responsible.officialPayer,
                    average : responsible.average
                }
            }

            dataContract = {
                id: contractId,
                created : removeFormatDate(created),
                updated : removeFormatDate(updated),
                partnerId : partner.id,
                situation : checkSituation,
                confection : removeFormatDate(confection),
                progress : checkProgress,
                vessels : dataVessels,
                responsibles : dataResponsabiles,
                indCalculated,
                pricePerFeet : removeMask(pricePerFeet, '0'),
                totalVessels : removeMask(totalVessels, '0'),
                totalOfFeet : removeMask(totalOfFeet, '0'),
                qtyParcels : removeMask(qtyParcels, '1'),
                expected : removeMask(expected),
                discount : removeMask(discount, '0'),
                addition : removeMask(addition, '0'),
                amount : removeMask(amount, '0'),
                validity : removeFormatDate(validity),
                expirationDay : removeMask(expirationDay, '1'),
                startBillingIn : removeMask(startBillingIn, '10'),
                indAverage,
                generatedPlots,
                accept : removeFormatDate(accept),
                signedBy : signedBy,

                monthlies : dataMonthlies
            };
        } else {
            dataContract = {
                id: contractId,
                created : removeFormatDate(created),
                updated : removeFormatDate(updated),
                partnerId : partner.id,
                situation : checkSituation,
                confection : removeFormatDate(confection),
                progress : checkProgress,
                accept : removeFormatDate(accept),
                signedBy : signedBy
            };
        }

        return dataContract;
    }

    async function initialize() {
        clearContract();

        if (props.isEdit) {
            try {
                const contract = await lclService.contractById(Number(props.contractId));
                setOriginRecord(contract);

                const localResponsibles = [] as any;
                if (contract.responsibles) {
                    for (let r = 0; r < contract.responsibles.length; r++) {
                        const localResp = contract.responsibles[r];

                        if (localResp.ownerType === PersonEnum.PESSOA_FISICA) {
                            localResp.owner = await cduService.peopleById(localResp.ownerId);
                        } else if (localResp.ownerType === PersonEnum.PESSOA_JURIDICA) {
                            localResp.owner = await cduService.companyById(localResp.ownerId);
                        }
                        localResponsibles[localResponsibles.length] = localResp;
                    }
                    setResponsibles(localResponsibles);
                }

                const localDocuments = [] as any;
                for ( let r = 0; r < localResponsibles.length; r++) {
                    if (!contract.indAverage) {
                        if (localResponsibles[r].officialPayer) {
                            let artifact = await cduService.artifactByNorm('CONTRACT', contract.id, 'FILE_SIGNATURE');
                            if (artifact)
                                artifact.filename = `Atualizado em ${notNullToDate(artifact.updated, 'dd/mm/yyyy HH:MM:ss')}`;
                            else 
                                artifact = { 
                                    id : 0, 
                                    norm : 'FILE_SIGNATURE', 
                                    source : 'CONTRACT', 
                                    identy : String(contract.id),
                                    fullname : localResponsibles[r].owner.fullname, 
                                    document : '', 
                                    filename : '' 
                                };
                            localDocuments[localDocuments.length] = artifact;
                        }
                    } else {
                        let artifact = await cduService.artifactByNorm('CONTRACT', `${contract.id}-${localResponsibles[r].ownerId}`, 'FILE_SIGNATURE');
                        if (artifact)
                            artifact.filename = `Atualizado em ${notNullToDate(artifact.updated, 'dd/mm/yyyy HH:MM:ss')}`;
                        else 
                            artifact = { 
                                id : 0, 
                                norm : 'FILE_SIGNATURE', 
                                source : 'CONTRACT', 
                                identy : `${contract.id}-${localResponsibles[r].ownerId}`, 
                                fullname : localResponsibles[r].owner.fullname, 
                                document : '', 
                                filename : '' 
                            };
                        localDocuments[localDocuments.length] = artifact;
                    }
                }
                setSignatureDocuments(localDocuments);
                fillRecord(contract);
            } catch(error: any) {
                await alertContext.show(AlertMessageEnum.FAIL, title, error);
            }
        } else if (props.contractId === 'new' || props.contractId === '0') {
            if (props.record.vessels) {
                try {
                    let localVessels = props.record.vessels;
                    let localResponsables = [] as any;

                    for (var v = 0; v < localVessels.length; v++) {
                        const localVessel = localVessels[v];

                        if (localVessel.responsibles) {
                            let selected = false;
                            for (var i = 0; i < localVessel.responsibles.length; i++) {
                                const localResp = localVessel.responsibles[i];

                                if (localResp.principal) {
                                    if (!selected) {
                                        localResp.officialPayer = selected = true;
                                    } else {
                                        localResp.officialPayer = false;
                                    }

                                    if (!localResp.people) {
                                        if (localResp.ownerType === PersonEnum.PESSOA_FISICA) {
                                            localResp.owner = await cduService.peopleById(localResp.ownerId);
                                        } else if (localResp.ownerType === PersonEnum.PESSOA_JURIDICA) {
                                            localResp.owner = await cduService.companyById(localResp.ownerId);
                                        }
                                    }
                                    localResponsables[localResponsables.length] = localResp;
                                }
                            }
                        }
                    }

                    setVessels(localVessels);
                    setResponsibles(localResponsables);
                    updatedAmount(localVessels);
                    defineSignature();
                } catch(error: any) {
                    await alertContext.show(AlertMessageEnum.FAIL, title, error);
                }
            }
        }
    }

    useEffect(() => {
        if (props.show)
            initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show]);

    useEffect(() => {
        generateMonthly();
        defineSignature();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [indAverage]);

//    signatures={signatures}
//    changeSignatures={(event: any, index: number) => changeSignatures(event, index)}
//    clickViewDocument={clickViewDocument}

    function defineSignature(paramResponsibles?: any) {
        let localDocuments = [] as any;
        let localResponsibles = [] as any;

        if (paramResponsibles)
            localResponsibles = paramResponsibles;
        else
            localResponsibles = responsibles;

        if (indAverage) {
            const numPeople = localResponsibles.length;
            for (var p = 0; p < numPeople; p++)  {
                localDocuments[localDocuments.length] = { 
                    id : 0, 
                    norm : 'FILE_SIGNATURE', 
                    source : 'CONTRACT', 
                    identy : `${contractId}-${localResponsibles[p].ownerId}`, 
                    fullname : localResponsibles[p].owner.fullname, 
                    document : '', 
                    filename : '' 
                };
            }
        } else {
            for ( var r = 0; r < localResponsibles.length; r++) {
                if (localResponsibles[r].officialPayer) {
                    localDocuments[localDocuments.length] = {
                        id : 0, 
                        norm : 'FILE_SIGNATURE', 
                        source : 'CONTRACT', 
                        identy : String(contractId), 
                        fullname : localResponsibles[r].owner.fullname, 
                        document : '', 
                        filename : '' 
                    };
                    break;
                }
            }
        }

        setSignatureDocuments(localDocuments);
    }

    function changeSignatureDocuments(event: any, identy: string) {

        if (event.target.files && event.target.files.length > 0) {
            let reader = new FileReader();
            reader.onload = (e: any) => {
                let localDocuments = [] as any;
                for (let s = 0; s < signatureDocuments.length; s++) {
                    localDocuments[localDocuments.length] = { 
                        id : signatureDocuments[s].id, 
                        norm : signatureDocuments[s].norm, 
                        source : signatureDocuments[s].source, 
                        identy : signatureDocuments[s].identy, 
                        fullname : signatureDocuments[s].fullname, 
                        document : (signatureDocuments[s].identy === identy) ? e.target.result : signatureDocuments[s].document, 
                        filename : (signatureDocuments[s].identy === identy) ? event.target.files[0].name : signatureDocuments[s].filename
                    };
                }
                setSignatureDocuments(localDocuments);
            };
            reader.readAsDataURL(event.target.files[0]);
        } else {
            let localDocuments = [] as any;
            for (let s = 0; s < signatureDocuments.length; s++) {
                localDocuments[localDocuments.length] = { 
                    id : signatureDocuments[s].id, 
                    norm : signatureDocuments[s].norm, 
                    source : signatureDocuments[s].source, 
                    identy : signatureDocuments[s].identy, 
                    fullname : signatureDocuments[s].fullname, 
                    document : (signatureDocuments[s].identy === identy) ? '' : signatureDocuments[s].document, 
                    filename : (signatureDocuments[s].identy === identy) ? '' : signatureDocuments[s].filename
                };
            }
            setSignatureDocuments(localDocuments);
        }
    }

    function clickViewDocument(event: any, title: string, url: any) {
        event.preventDefault();
        openDocument(title, url);
    }

    /* START */

    function calculateValidity(event: any) {
        event.preventDefault();

        let dateContract = toDate(confection, 'dd/mm/yyyy');
        dateContract.setDate(dateContract.getDate() + DEFAULT_VALIDITY_DAYS);
        setValidity(dateToString(dateContract, 'dd/mm/yyyy'));
    }
    
    async function openFilterVessel(event: any) {
        setShowFilterVessel(true);
    }

    function checkCalculated(action: boolean) {
        if (action) {
            let localTotalOfFeet = 0;
            for(var i = 0; i < vessels.length; i++) {
                const item = vessels[i];
                localTotalOfFeet += toFloat(item.vesselSize);
            }
            let localPricePerFeet = toFloat(pricePerFeet);

            let localExpected = (localPricePerFeet * localTotalOfFeet) * vessels.length;
            setExpected(floatMask(localExpected.toFixed(2)));
        }
        setIndCalculated(!indCalculated);
        updatedAmount(vessels, action);
    }

    async function generateInstallments() {
        let generate = true;

        if (!indAverage) {
            let isAverage = false;
            for (var r = 0; r < responsibles.length; r++ ) {
                const responsible = responsibles[r];
                if (responsible.officialPayer)
                    isAverage = true;
            }

            if (!isAverage) {
                await alertContext.show(AlertMessageEnum.FAIL, title, 'Informe um pagador para gerar as parcelas !!!');
                generate = false;
            }
        }

        if (generate)
            generateMonthly();
    }

    function updatedAmount(listVessel?: any, isCalculated?: boolean) {
        let arrayVessel = [] as any;
        if (listVessel)
            arrayVessel = listVessel;
        else
            arrayVessel = vessels;

        setTotalVessels(arrayVessel.length);
        let localTotalOfFeet = 0;
        for(var i = 0; i < arrayVessel.length; i++) {
            const item = arrayVessel[i];
            localTotalOfFeet += toFloat(item.vesselSize);
        }
        let total = floatMask(String(localTotalOfFeet));
        setTotalOfFeet(total);

        let localPricePerFeet = toFloat(pricePerFeet);
        let localExpected = 0;

        let calculated = false;
        if (isCalculated !== undefined) {
            calculated = isCalculated;
        } else {
            calculated = indCalculated;
        }

        if (!calculated) {
            localExpected = toFloat(expected);
            if (localExpected === 0)
                localExpected = (localPricePerFeet * localTotalOfFeet) * arrayVessel.length;
        } else {
            localExpected = (localPricePerFeet * localTotalOfFeet) * arrayVessel.length;
        }

        setExpected(floatMask(localExpected.toFixed(2)));

        let localDiscount = toFloat(discount) || 0.00;
        let localAddition = toFloat(addition) || 0.00;
        let localAmount = (localExpected - localDiscount) + localAddition;
            localAmount = localAmount * toInt(qtyParcels);

        let localPercDiscount = 100 - (((localExpected - localDiscount) / localExpected) * 100);
            localPercDiscount = toFloat(localPercDiscount.toFixed(2))
        setDiscountPerc(floatMask(String(localPercDiscount)));
        let localPercAddition = 100 - (((localExpected - localAddition) / localExpected) * 100);
            localPercAddition = toFloat(localPercAddition.toFixed(2));
        setAdditionPerc(floatMask(String(localPercAddition)));
        setAmount(floatMask(localAmount.toFixed(2)));

        setMonthlies([] as any);
    }
    
    function generateMonthly(numParcels?: any, cost?: any) {
        let localAmount = 0;
        if (cost)
            localAmount = cost;
        else
            localAmount = toFloat(amount);

        let localQtyParcels = 0;
        if (numParcels)
            localQtyParcels = numParcels;
        else
            localQtyParcels = toInt(qtyParcels);

        const localConfection = toDate(confection, 'dd/mm/yyyy');
        const localStartBillingIn = toInt(startBillingIn);
        const localExpirationDay = toInt(expirationDay);

        const arrayMonthly = [] as any;

        let parcel = toFloat(localAmount / localQtyParcels);
        let balance = localAmount - (parcel * localQtyParcels);

        let due = localConfection;
        due.setDate(due.getDate() + localStartBillingIn);
        due = lastDayOdMonth(due);
        due.setDate(due.getDate() + localExpirationDay);

        for (var idx = 0; idx < localQtyParcels; idx++) {
            let date = due; 
            // 0 é Domingo e 6 é Sábado
            if (date.getDay() === 0)
                date.setDate(date.getDate() + 1);
            if (date.getDay() === 6)
                date.setDate(date.getDate() + 2);
            
            date = new Date(date.getFullYear(), date.getMonth(), localExpirationDay);

            let amountParcel = 0;
            if (idx === 0)
                amountParcel = parcel + balance;
            else
                amountParcel = parcel;

            if (indAverage) {
                const numPeople = responsibles.length;
                for (var p = 0; p < numPeople; p++)  {
                    const amount = amountParcel / numPeople;

                    arrayMonthly[arrayMonthly.length] = {
                        id : 0,
                        numParcel : idx + 1,
                        amountParcel : floatToString(amount),
                        expiry : dateToString(date, 'dd/mm/yyyy'),
                        ownerType : responsibles[p].ownerType,
                        ownerId : responsibles[p].ownerId, 
                        owner : responsibles[p].owner,
                        description : 'Contrato de Prestação de Serviço'
                    }
                }
            } else {
                let localResp = {} as any;
                for ( var r = 0; r < responsibles.length; r++) {
                    if (responsibles[r].officialPayer) {
                        localResp = responsibles[r];
                        break;
                    }
                }

                arrayMonthly[arrayMonthly.length] = {
                    id : 0,
                    numParcel : idx + 1,
                    amountParcel : floatToString(amountParcel),
                    expiry : dateToString(date, 'dd/mm/yyyy'),
                    ownerType : localResp.ownerType,
                    ownerId : localResp.ownerId, 
                    owner : localResp.owner,
                    description : 'Contrato de Prestação de Serviço'
                }
            }

            due.setMonth(due.getMonth() + 1);
        }

        let localAmountParcel = 0;
        arrayMonthly.forEach((parcel: any) => {
            localAmountParcel += toFloat(parcel.amountParcel);
        });

        let localDifference = Math.abs(localAmount - localAmountParcel);

        setTotalAmountParcel(floatToString(localAmountParcel));
        setContractualDifference(floatToString(localDifference));
        setMonthlies(arrayMonthly);
    }

    function changeAverage(event: any) {
        event.preventDefault();
        setIndAverage(!indAverage);
    }

    function changeResponsibleSelected(event: any, responsibles: any , responsibleId: any) {
        event.preventDefault();

        const localResp = [] as any;
        for ( var r = 0; r < responsibles.length; r++) {
            localResp[r] = responsibles[r];
            if (responsibles[r].id === responsibleId) {
                localResp[r].officialPayer = true;
            } else {
                localResp[r].officialPayer = false;
            }
        }
        setResponsibles(localResp);
        setMonthlies([] as any);
    }

    async function onClick_ConfirmFilterVessel(event: any, vessel: any) {
        event.preventDefault();
        if (vessel) {
            const arrayVessel = vessels;

            let existVessel = false;
            for(var i = 0; i < arrayVessel.length; i++) {
                const item = arrayVessel[i];
                if (item.id === vessel.id) {
                    existVessel = true;
                    break;
                }
            }
            if (!existVessel)
                arrayVessel.push(vessel);

            setVessels(arrayVessel);
        }
        updatedAmount();
    }

    async function addResponsible(event: any) {
        event.preventDefault();

        const idsVessel = [] as any;
        for (var v = 0; v < vessels.length; v++) {
            idsVessel.push(vessels[v].id);
        }

        const attributes = [] as any;
        const where = { vesselId : { in : idsVessel }} as any;
        const order = [] as any;
        const resultResponsibles = await lclService.vesselResponsibleFilter(attributes, where, order);

        const localResponsibles = [] as any;
        let selected = false;
        for ( var r = 0; r < resultResponsibles.length; r++) {
            const localResponsible = resultResponsibles[r];
            if (localResponsible.principal) {
                if (!selected) {
                    localResponsible.officialPayer = selected = true;
                } else {
                    localResponsible.officialPayer = false;
                }
            }
            localResponsibles[localResponsibles.length] = localResponsible;
        }

        setResponsibles(resultResponsibles);
    }

    function editResponsible(event: any, responsible: any) {
        event.preventDefault();

        setEditResponsible(true);
        setResponsibleId(responsible.id);
        setResponsible(responsible);
        setShowResponsible(true);
    }

    async function deleteResponsible(event: any, responsibles: any, responsible: any) {
        event.preventDefault();

        const isConfirmed = await deleteContext.show(title, display.message.delete_record, false);
        if (isConfirmed && isConfirmed.result) {

            if (contractId > 0) {
                try {
                    const returned = await lclService.contractResponsibleDeleteById(contractId, responsible.ownerType, responsible.ownerId);
                    if (!returned)
                        await alertContext.show(AlertMessageEnum.FAIL, title, 'Não foi possivel remover o responsável.');
                } catch(error: any) {
                    await alertContext.show(AlertMessageEnum.FAIL, title, error);
                }
            }

            const localResponsibles = [] as any;
            let selected = false;
            for ( var r = 0; r < responsibles.length; r++) {
                const localResponsible = responsibles[r];
                if (localResponsible.id !== responsible.id) {

                    if (localResponsible.principal) {
                        if (!selected) {
                            localResponsible.officialPayer = selected = true;
                        } else {
                            localResponsible.officialPayer = false;
                        }
                    }
                    localResponsibles[localResponsibles.length] = localResponsible;
                }
            }
            setResponsibles(localResponsibles);
        }
    }

    /* END */
    
    function viewCadastreForm() {

        return (
            <>
                <Modal show={((props.show && !showFilterVessel) && (props.show && !showResponsible))}
                    size="lg"
                    aria-labelledby="modal-cadastre-contract-form"
                    centered
                >
                    <Modal.Header>
                        <Modal.Title id="modal-cadastre-contract-form">{title}</Modal.Title>
                        <FaTimes className="isClickable" onClick={ onClick_Cancel } size={26}/>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                <h6>{props.subTitle}</h6>
                            </Col>
                        </Row>
                        <Form className="form" id="form-cadastre-contract" validated={invalidatedCadastreForm}>
                            <ContractRecord
                                title={title}
                                isEdit={props.isEdit}
                                isEditLaucher={false}
                                hasBlocked={(progress >= ContractProgressEnum.FINANCIAL)}

                                contractKey={contractKey}
                                situation={situation}
                                confection={confection}
                                progress={progress}
                                vessels={vessels}
                                responsibles={responsibles}
                                indCalculated={indCalculated}
                                pricePerFeet={pricePerFeet}
                                totalVessels={totalVessels}
                                totalOfFeet={totalOfFeet}
                                qtyParcels={qtyParcels}
                                expected={expected}
                                discount={discount}
                                discountPerc={discountPerc}
                                addition={addition}
                                additionPerc={additionPerc}
                                amount={amount}
                                validity={validity}
                                startBillingIn={startBillingIn}
                                expirationDay={expirationDay}
                                generatedPlots={generatedPlots}
                                accept={accept}
                                signedBy={signedBy}
                                monthlies={monthlies}
                                totalAmountParcel={totalAmountParcel}
                                contractualDifference={contractualDifference}
                                indAverage={indAverage}
                                setSituation={(value: any) => { setSituation(value); }}
                                setConfection={(value: any) => { setConfection(value); }}
                                setProgress={(value: any) => { setProgress(value); }}
                                setVessels={(value: any) => { setVessels(value); }}
                                setResponsibles={(value: any) => { setResponsibles(value); }}
                                setIndCalculated={(value: any) => { setIndCalculated(value); }}
                                setPricePerFeet={(value: any) => { setPricePerFeet(value); }}
                                setTotalVessels={(value: any) => { setTotalVessels(value); }}
                                setTotalOfFeet={(value: any) => { setTotalOfFeet(value); }}
                                setQtyParcels={(value: any) => { setQtyParcels(value); }}
                                setExpected={(value: any) => { setExpected(value); }}
                                setDiscount={(value: any) => { setDiscount(value); }}
                                setDiscountPerc={(value: any) => { setDiscountPerc(value); }}
                                setAddition={(value: any) => { setAddition(value); }}
                                setAdditionPerc={(value: any) => { setAdditionPerc(value); }}
                                setAmount={(value: any) => { setAmount(value); }}
                                setValidity={(value: any) => { setValidity(value); }}
                                setStartBillingIn={(value: any) => { setStartBillingIn(value); }}
                                setExpirationDay={(value: any) => { setExpirationDay(value); }}
                                setGeneratedPlots={(value: any) => { setGeneratedPlots(value); }}
                                setAccept={(value: any) => { setAccept(value); }}
                                setSignedBy={(value: any) => { setSignedBy(value); }}
                                setMonthlies={(value: any) => { setMonthlies(value); }}
                                setTotalAmountParcel={(value: any) => { setTotalAmountParcel(value); }}
                                setContractualDifference={(value: any) => { setContractualDifference(value); }}
                                setIndAverage={(value: any) => { setIndAverage(value); }}

                                addResponsible={(value: any) => { addResponsible(value); }}
                                editResponsible={(value: any, object: any) => { editResponsible(value, object); }}
                                deleteResponsible={(value: any, objects: any, object: any) => { deleteResponsible(value, objects, object); }}

                                calculateValidity={(value: any) => { calculateValidity(value); }}
                                openFilterVessel={(value: any) => { openFilterVessel(value); }}
                                updatedAmount={(value: any) => { updatedAmount(); }}
                                generateMonthly={(value: any) => { generateMonthly(); }}
                                changeAverage={(value: any) => { changeAverage(value); }}

                                setLauncherId={(value: any) => { setLauncherId(value); }}
                                setLauncher={(value: any) => { setLauncher(value); }}
                                setEditLauncher={(value: any) => { setEditLauncher(value); }}
                                setShowLauncher={(value: any) => { setShowLauncher(value); }}
                                setShowPayment={(value: any) => { setShowPayment(value); }}
                                setShowContract={(value: any) => { props.setShowContract(value); }}

                                changeResponsibleSelected={(value: any, objects: any, object: any) => { changeResponsibleSelected(value, objects, object); }}
                                checkCalculated={(value: any) => { checkCalculated(value); }}
                                generateInstallments={() => { generateInstallments(); }}

                                signatureDocuments={signatureDocuments}
                                changeSignatureDocuments={(event: any, identy: string) => changeSignatureDocuments(event, identy)}
                                clickViewDocument={clickViewDocument}
                            />
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        { toInt(progress) !== ContractProgressEnum.FINISHED && <Button className="me-2" variant="contained"  color="secondary" onClick={e => onClick_Cancel(e) }>{display.buttom.cancel}</Button>}
                        { toInt(progress) === ContractProgressEnum.FINANCIAL && <Button className="me-2" variant="contained" color="error" onClick={e => onClick_Confirm(e, STEP_GO_BACK) } >Voltar Etapa</Button> }
                        { toInt(progress) === ContractProgressEnum.SIGNATURE && <Button className="me-2" variant="contained" color="info" onClick={e => onClick_Print(e) } >Imprimir Contrato</Button> }
                        <Button className="me-2" variant="contained" onClick={e => onClick_Confirm(e, STEP_ADVANCE) } disabled={sending}>
                            { sending ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null }{'  '}
                            { (toInt(progress) === ContractProgressEnum.ELABORATION) ? display.buttom.save : (toInt(progress) === ContractProgressEnum.FINISHED) ? display.buttom.close : display.buttom.advance_step }
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

    return (
        <>
            { viewCadastreForm() }
            <VesselFilter
                show={showFilterVessel}
                typeFilter={props.typeFilter}
                subTitle={props.subTitle}
                owner={{} as any}

                onClick_Close={(event: any) => { 
                    setShowFilterVessel(false);
                }}
                onClick_Confirm={(event: any, value: any) => { 
                    setShowFilterVessel(false);
                    onClick_ConfirmFilterVessel(event, value); 
                }}
            />
            <LauncherCadastreModal 
                itemsToast={itemsToast}
                setItemsToast={(items: any) => {
                    setItemsToast(items);
                }}

                isEditMode={isEditLauncher}
                show={showLauncher}
                launcherId={launcherId}
                record={launcher}
                onSubmitModal={async (event: any, record: any) => {
                    event.preventDefault();

                    setLauncher(record);
                    if (isEditLauncher) {
                        const updatedLaunchers = Array.from(monthlies) as any;
                        for ( var idx_Edit = 0; idx_Edit < updatedLaunchers.length; idx_Edit++) {
                            if (updatedLaunchers[idx_Edit].id === record.id) {
                                updatedLaunchers[idx_Edit] = record;
                            }
                        }
                    } else {

                        for ( var idx_New = 0; idx_New < monthlies.length; idx_New++) {
                            const launcher = monthlies[idx_New];
                            if (launcher.id === record.id) {
                                await alertContext.show(AlertMessageEnum.FAIL, title, display.message.the_informed_record_is_already_linked);
                                break;
                            }
                        }
                        
                    }

                    setShowLauncher(false);
                    setEditLauncher(BILLET_MODE_NONE);
                    props.setShowContract(true);
                }}
                onCancelModal={(event: any) => {
                    setEditLauncher(BILLET_MODE_NONE);
                    setShowLauncher(false);
                    props.setShowContract(true);
                }}
            />
            <PaymentCadastreModal 
                show={showPayment}
                launcherId={launcherId}
                record={launcher}
                onSubmitModal={(event: any, record: any) => {
                    event.preventDefault();

                    setShowPayment(false);
                    props.setShowContract(true);

                }}
                onCancelModal={(event: any) => {
                    setShowPayment(false);
                    props.setShowContract(true);
                }}
            />
            <VesselResponsibleCadastreModal 
                isEdit={isEditResponsible}

                show={showResponsible}
                vessel={originRecord}
                responsibleId={responsibleId}
                record={responsible}
                onSubmitModal={async (event: any, record: any) => {
                    event.preventDefault();

                    setResponsible(record);
                    if (isEditResponsible) {
                        const updatedResponsibles = Array.from(responsibles) as any;
                        for ( var idx_Edit = 0; idx_Edit < updatedResponsibles.length; idx_Edit++) {
                            if (updatedResponsibles[idx_Edit].id === record.id) {
                                updatedResponsibles[idx_Edit] = record;
                            }
                        }
                        setResponsibles(updatedResponsibles);
                    } else {
                        let existResponsible = false;

                        for ( var idx_New = 0; idx_New < responsibles.length; idx_New++) {
                            const responsible = responsibles[idx_New];
                            if ((responsible.ownerType === record.ownerType) && (responsible.ownerId === record.ownerId)) {
                                existResponsible = true;
                                await alertContext.show(AlertMessageEnum.FAIL, title, display.message.the_informed_record_is_already_linked);
                                break;
                            }
                        }
                        if (!existResponsible)
                            setResponsibles([...responsibles, record]);
                    }
                    setShowResponsible(false);
                    setEditResponsible(false);
                }}
                onCancelModal={(event: any) => {
                    event.preventDefault();

                    setEditResponsible(false);
                    setShowResponsible(false);
                }}
            />
        </>
    )
}

export default ContractCadastreModal
