import { useEffect, useRef, useState } from 'react'

import useSound from 'use-sound'

import { useNavigate } from 'react-router-dom'
import Abastecimento from '../../assets/Abastecimento.svg'
import Jet from '../../assets/Jetski.svg'
import Lancha from '../../assets/Lancha.svg'
import Logo from '../../assets/N.png'
import Navegando from '../../assets/Navegando.svg'
import styles from './OperationManagement.module.scss'

import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp'
import SportsScoreIcon from '@mui/icons-material/SportsScore'
import TodayIcon from '@mui/icons-material/Today'
import descidaSolicitada from '../../assets/sounds/descida_solicitada.mp3'
import subidaSolicitada from '../../assets/sounds/subida_solicitada.mp3'
import bip from '../../assets/sounds/bip.mp3'
import Loader from '../../components/loader/loader'
import authService from '../../services/auth.service'
import lclService from '../../services/lcl.service'
import { dateToString, toInt } from '../../utilities/auxiliary-functions'
import { removeFormatDate } from '../../utilities/masks'
import { isEmpty } from '../../utilities/validators'
import { IconButton, Menu, MenuItem } from '@mui/material'
import SpatialAudioOffIcon from '@mui/icons-material/SpatialAudioOff'
import MusicNoteIcon from '@mui/icons-material/MusicNote'
import VolumeOffIcon from '@mui/icons-material/VolumeOff'

interface IOperationManagementProps {
}

export const OperationManagement: React.FC<IOperationManagementProps> = () => {

  const [ selectedFilter, setSelectedFilter ] = useState('All')
  const [ filteringMove, setFilteringMove ] = useState(false)
  const [ loading, setLoading ] = useState(true)
  const [ qtdMovement, setQtdMovement ] = useState(0)
  const [ qtdDown, setQtdDown ] = useState(0)
  const [ qtdNavigate, setQtdNavigate ] = useState(0)
  const [ qtdRise, setQtdRise ] = useState(0)
  const [ qtfFueling, setQtdFueling ] = useState(0)
  const [ qtdFinished, setQtdFinished ] = useState(0)
  const [ reservations, setReservations ] = useState([] as any)
  const [ originalReservations, setOriginalReservations ] = useState([] as any)
  const [ selectedSound, setSelectedSound ] = useState(localStorage.getItem('sound') || 'voice')
	const [ time, setTime ] = useState(new Date());
  const marina = authService.currentPartner()?.company?.fantasy || ''
  const navigate = useNavigate()

  const [ playDescidaSolicitada ] = useSound(descidaSolicitada);
  const [ playSubidaSolicitada ] = useSound(subidaSolicitada);
  const [ playBip ] = useSound(bip)
  const inputRef: any = useRef(null)

  const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  useEffect(() => {
    const intervalId = setInterval(() => {
        setTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const filterReservations = (key?: string, value?: string) => {
    setFilteringMove(false)
    if (key && value) {
      setSelectedFilter(value)
      if (value === 'All') {
        return setReservations(
          originalReservations.filter(
            (movement: any) => movement.move !== 6 && movement.status !== '5'
          )
        )
      }
      let filteredReservations: any

      if (key === 'status') {
        filteredReservations = originalReservations.filter(
          (reservation: any) => reservation.status === value
        )
      } else {
        filteredReservations = originalReservations.filter(
          (reservation: any) => reservation.move === Number(value)
        )
        setFilteringMove(true)
      }
      setReservations(filteredReservations)
    } else {
      const filteredReservations: any = []
      setReservations(filteredReservations)
    }
    return ''
  }
  
  const getDepartureAndId = (params: any) => {
    return (
      <div className="d-flex flex-column align-items-center justify-content-center">
        <span>
          {!isEmpty(params.departure)
            ? dateToString(params.departure, 'HH:MM')
            : ''}
        </span>
        <span>{params.vesselIdentifyKey}</span>
      </div>
    )
  }

  const getModelAndOwner = (params: any) => {
    return (
      <div className="d-flex flex-column align-items-center justify-content-center">
        <span>{params.vesselModel}</span>
        <span>{params.vesselOwner.name.split(' ')[0]}</span>
      </div>
    )
  }

  const getVessel = (params: any) => {
    return (
      <div
        className={`d-flex flex-column align-items-center justify-content-center w-100 mb-2 ${styles.vesselWrapper}`}
      >
        <div className="d-flex justify-content-center mb-1">
          <img
            alt="JetSki"
            src={toInt(params.vesselType) === 33 ? Jet : Lancha}
            className="jet mt-3"
            style={{
              height: 36
            }}
          />
          <small className="ms-1 mt-3">{`${
            toInt(params.vesselType) === 33 ? '10' : params.vesselSize
          }'`}</small>
        </div>
        <span
          className={styles.vesselColor}
          style={{ backgroundColor: params.vesselColor }}
        />
      </div>
    )
  }

  const getLocator = (params: any) => {
    return (
      <span className="d-flex flex-column align-items-center">
        <span>{params.locator}</span>
        <span>{getCheckInStatus(params.checkIn)}</span>
      </span>
    )
  }

  const getCheckInStatus = (status: string) => {
    switch (status) {
      case 'confirmed':
        return 'Efetuado'
      case 'pending':
        return 'Pendente'
      case 'canceled':
        return 'Cancelado'
      case 'finished':
        return 'Finalizado'
      default:
        return ''
    }
  }

  const getMove = (params: any) => {
    switch (params.move) {
      case 1:
        return <p className={styles.departureRequested}>Descida Solicitada</p>
      case 2:
        return <p className={styles.arrivesRequested}>Subida Solicitada</p>
      case 3:
        return <p className={styles.departureRequested}>Descida Confirmada</p>
      case 4:
        return <p className={styles.departureRequested}>Subida Confirmada</p>
      case 5:
        return <p className={styles.departureRequested}>Descida Finalizada</p>
      case 6:
        return <p className={styles.departureRequested}>Subida Finalizada</p>
      case 0:
        return <h2 className='mt-2'>-</h2>
      case undefined:
        return <h2 className='mt-2'>-</h2>
      case null:
        return <h2 className='mt-2'>-</h2>
      default:
        return <h2 className='mt-2'>-</h2>
    }
  }

  const viewReservation = (reservation: any) => {
    if (reservation.checkIn === 'confirmed')
      navigate(`/portal/dashboard/operation-management/${reservation.locator}`)
  }

  useEffect(() => {
    getReservations()
    const interval = setInterval(() => {
      getReservations()
    }, 30000);
    return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getReservations = async () => {
    setLoading(true)
    inputRef.current?.click()

    const partnerAuth = await authService.currentPartner()
    // Consulta fake de data
    const dateNavigation = removeFormatDate(new Date().toLocaleDateString())
    const schedules = await lclService.navigation(partnerAuth.id, dateNavigation)

    if (schedules) {
      setReservations(
        schedules.filter(
          (movement: any) => movement.move !== 6 && movement.status !== '5'
        )
      )
      setOriginalReservations(schedules)

      setQtdMovement(
        schedules.filter(
          (movement: any) => movement.move !== 6 && movement.status !== '5'
        ).length
      )
      setQtdDown(
        schedules.filter((movement: any) => movement.move === 1).length
      )

      setQtdNavigate(
        schedules.filter((movement: any) => movement.status === '5').length
      )
      setQtdRise(
        schedules.filter((movement: any) => movement.move === 2).length
      )
      setQtdFueling(
        schedules.filter((movement: any) => movement.status === '2').length
      )
      setQtdFinished(
        schedules.filter((movement: any) => movement.move === 6).length
      )
      
    }
    setLoading(false)
    setSelectedFilter('All')
    inputRef.current?.click()
  }

  const playSounds = () => {
    const down = reservations?.filter(
      (movement: any) => movement.move === 1
    ).length
    const rise = reservations?.filter(
      (movement: any) => movement.move === 2
    ).length

    if (selectedSound === 'bip' && (down > 0 || rise > 0)) {
      playBip()
    }

    if (selectedSound === 'voice') {
      if (down > 0) {
        playDescidaSolicitada()
      }
      if (rise > 0) {
        if (down > 0) {
          setTimeout(() => {
            playSubidaSolicitada()
          }, 2000)
        } else {
          playSubidaSolicitada()
        }
      }
    }

    if (selectedSound === 'muted') {
      return ''
    }
    return ''
  }

  const filterAndPlay = () => {
    filterReservations('none', 'All')
    playSounds()
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  
  const selectSound = (sound: string) => {
    localStorage.setItem('sound', sound)
    setSelectedSound(sound)
    handleClose()
  }

  const getQueuePosition = (params: any) => {
    if (params.queuePosition) {
      return (
        <span className="d-flex flex-column align-items-center justify-content-center">
          <h2 className='mb-0 mt-2'>{params.queuePosition}</h2>
        </span>
      )
    }

    return (
      <span className="d-flex flex-column align-items-center justify-content-center">
        <h2 className='mt-2'>-</h2>
      </span>
    )
  }

  function viewPage() {
    return (
        <div className={styles.operationManagement}>
          <div className="d-flex justify-content-between">
          <img src={Logo} alt="logo Nautisystem" className={styles.logo} />
          <div style={{width: '60px'}}></div>
          <div className='d-flex flex-column align-items-center'>
            <h2>{marina}</h2>
            <h5>{new Date().toLocaleDateString()}</h5>
            <h5 className={`${styles.clockTitle}`}>{time.toLocaleTimeString()}</h5>
          </div>
            <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            {selectedSound === 'voice' && (
              <SpatialAudioOffIcon sx={{ width: 32, height: 32 }} />
            )}
            {selectedSound === 'bip' && (
              <MusicNoteIcon sx={{ width: 32, height: 32 }} />
            )}
            {selectedSound === 'muted' && (
              <VolumeOffIcon sx={{ width: 32, height: 32 }} />
            )}
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0
                }
              }
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem
              disabled={selectedSound === 'voice'}
              onClick={() => selectSound('voice')}
            >
              <SpatialAudioOffIcon className="me-3" /> Voz
            </MenuItem>
            <MenuItem
              disabled={selectedSound === 'bip'}
              onClick={() => selectSound('bip')}
            >
              <MusicNoteIcon className="me-3" /> Bip
            </MenuItem>
            <MenuItem
              disabled={selectedSound === 'muted'}
              onClick={() => selectSound('muted')}
            >
              <VolumeOffIcon className="me-3" /> Sem som
            </MenuItem>
          </Menu>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <>
          <div className={styles.statusButtonsWrapper}>
            <div
              className={`${styles.statusButton} ${
                selectedFilter === 'All' ? styles.active : ''
              }`}
              role="presentation"
              onClick={() => filterAndPlay()}
              ref={inputRef}
            >
              <TodayIcon sx={{ fontSize: 32 }} />
              <span className="ms-2">{qtdMovement}</span>
            </div>
            <div
              className={`${styles.statusButton} ${
                selectedFilter === '2' && !filteringMove ? styles.active : ''
              }`}
              role="presentation"
              onClick={() => filterReservations('status', '2')}
            >
              <img
                alt="Abastecimento"
                src={Abastecimento}
                className="jet me-2"
                style={{ height: 30, width: 30 }}
              />
              <span className="me-0">{qtfFueling}</span>
            </div>
            <div
             className={`${styles.statusButton} ${
              selectedFilter === '5' && filteringMove ? styles.active : ''
            }`}
              role="presentation"
              onClick={() => filterReservations('move', '6')}
            >
              <SportsScoreIcon sx={{ fontSize: 32 }} />
              <span>{qtdFinished}</span>
            </div>
          </div>
          <div className={styles.grid}>
            <>
            {reservations.length === 0 ?(
              <h3 className='d-flex flex-column align-items-center justify-content-center' style={{marginTop: '200px'}}>Nenhuma Reserva</h3>
            ):(

            <>  
            {reservations.map((reservation: any, index: { toString: () => React.Key | null | undefined }) => (
              <div className={styles.reservation} key={index.toString()} onClick={() => viewReservation(reservation)}>
                <div className='d-flex justify-content-between mb-1'>
                  <div className='d-flex flex-column align-items-center'>
                    <h5>HORA | ID</h5>
                    {getDepartureAndId(reservation)}
                  </div>
                  <div className='d-flex flex-column align-items-center'>
                    <h5>TIPO | COR</h5>
                    {getVessel(reservation)}
                  </div>
                  <div className='d-flex flex-column align-items-center'>
                    <h5>MOD | RESP</h5>
                    {getModelAndOwner(reservation)}
                  </div>
                </div>
                <div className='d-flex justify-content-between'>
                  <div className='d-flex flex-column align-items-center'>
                    <h5>CHECK-IN</h5>
                    {getLocator(reservation)}
                  </div>
                  <div className='d-flex flex-column align-items-center'>
                    <h5>POS. FILA</h5>
                    {getQueuePosition(reservation)}
                  </div>
                  <div className='d-flex flex-column align-items-center'>
                    <h5>CHAMADA</h5>
                      {getMove(reservation)}
                  </div>
                </div>
              </div>
            ))}
              
            </>
            )}
            </>

          </div>
          <div className={styles.statusButtonsWrapper}>
            <div
              className={`${styles.statusButton} ${
                selectedFilter === '1' && filteringMove ? styles.active : ''
              }`}
              role="presentation"
              onClick={() => filterReservations('move', '1')}
            >
              <KeyboardDoubleArrowDownIcon
                color="success"
                sx={{ fontSize: 52 }}
              />
              <span className="me-3">{qtdDown}</span>
            </div>
            <div
              className={`${styles.statusButton} ${
                selectedFilter === '5' ? styles.active : ''
              }`}
              role="presentation"
              onClick={() => filterReservations('status', '5')}
            >
              <div
                className={`d-flex flex-column align-items-center justify-content-center rotating`}
              >
                <img
                  alt="Navegando"
                  src={Navegando}
                  className="jet me-3"
                  style={{ height: 32, width: 32 }}
                />
              </div>
              <span>{qtdNavigate}</span>
            </div>
            <div
              className={`${styles.statusButton} ${
                selectedFilter === '2' && filteringMove ? styles.active : ''
              }`}
              role="presentation"
              onClick={() => filterReservations('move', '2')}
            >
              <KeyboardDoubleArrowUpIcon color="error" sx={{ fontSize: 52 }} />
              <span className="me-3">{qtdRise}</span>
            </div>
          </div>
          </>
          )}
        </div>
    )
  }

  return (
      <div className="page">
          {/* <OperationHeader
            title=""
            description=""
            onClick_ShowDefinedPartner={props.onClick_ShowDefinedPartner}
          /> */}
          {viewPage()}
      </div>
  )
}

export default OperationManagement
