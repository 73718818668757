import { useEffect, useState } from "react";
import { Button, Col, Form, FormControl, FormGroup, InputGroup, Modal, Row, Spinner } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import cduService from "../../services/cdu.service";
import { dateToString, notNullToDate, toString } from "../../utilities/auxiliary-functions";
import { cnpjMask, numberMask, phoneMask, removeFormatDate, removeMask, zipcodeMask } from "../../utilities/masks";
import { isCnpj, isEmpty } from "../../utilities/validators";
import { FaTimes } from "react-icons/fa";
import { useAlertMessageContext } from "../../contexts/alert-message.context";
import { useConfirmMessageContext } from "../../contexts/confirm-message.context";
import CorporateDataRecord from "../records/corporate-data.record";
import AddressRecord from "../records/address.record";
import srvService from "../../services/srv.service";
import { AlertMessageEnum } from "../../utilities/types";
import { openDocument } from "../../utilities/view-document";
import { TFilter } from "../../types/FilterType";
import { useTranslateContext } from "../../contexts/translate.context";

export interface ICompanyModalProps {
    isEdit: boolean,
    show: boolean,
    companyId: any,
    record: any,

    registration: any,

    onSubmitModal: any,
    onCancelModal: any
}

export const CompanyModal: React.FC<ICompanyModalProps> = (props: ICompanyModalProps) => {

    const alertContext = useAlertMessageContext();
    const confirmContext = useConfirmMessageContext();
    const { display } = useTranslateContext();

    const title : string = 'Dados da Pessoa Jurídica';

    const [ invalidatedForm, setInvalidatedForm ] = useState(false);
    const [ sending, setSending ] = useState(false);

    // Company
    const [ companyId, setCompanyId ] = useState(0);
    const [ created, setCreated ] = useState(dateToString(new Date(), 'dd/mm/yyyy HH:MM:ss'));
    const [ updated, setUpdated ] = useState(dateToString(new Date(), 'dd/mm/yyyy HH:MM:ss'));

    const [ situation, setSituation ] = useState(0);
    const [ socialReason, setSocialReason ] = useState('');
    const [ fantasy, setFantasy ] = useState('');
    const [ sector, setSector ] = useState('');
    const [ registrationPj, setRegistrationPj ] = useState('');
    const [ registrationState, setRegistrationState ] = useState('');
    const [ foundation, setFoundation ] = useState('');
    const [ site, setSite ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ telephone, setTelephone ] = useState('');
    const [ url, setUrl ] = useState('');
    const [ latitude, setLatitude ] = useState('');
    const [ longitude, setLongitude ] = useState('');

    // Address
    const [ addressId, setAddressId ] = useState(0);
    const [ zipcode, setZipcode ] = useState('');
    const [ state, setState ] = useState('');
    const [ city, setCity ] = useState('');
    const [ district, setDistrict ] = useState('');
    const [ place, setPlace ] = useState('');
    const [ habitation, setHabitation ] = useState('');
    const [ complement, setComplement ] = useState('');

    type ArtifactType = { id : number, norm : string, source : string, identy : string, document : string, filename : string };
    const DEFAULT_ARTIFACT: ArtifactType = { id : 0, norm : '', source : '', identy : '', document : '', filename : '' };
    
    const [ proofOfResidenceDocument, setProofOfResidenceDocument ] = useState(DEFAULT_ARTIFACT);
    
    const [ societies, setSocieties ] = useState([] as any);
    const [ employees, setEmployees ] = useState([] as any);

    function fillRecord(company: any) {

        if (!isEmpty(company)) {
            setCompanyId(company.id);
            setCreated(notNullToDate(company.created, 'dd/mm/yyyy HH:MM:ss'));  
            setUpdated(notNullToDate(company.updated, 'dd/mm/yyyy HH:MM:ss'));  

            setSituation(company.situation);
            setSocialReason(company.socialReason);
            setFantasy(company.fantasy);
            setSector(company.sector);
            setRegistrationPj(cnpjMask(company.registrationPj));
            setRegistrationState(numberMask(company.registrationState));
            setFoundation(notNullToDate(company.foundation, 'dd/mm/yyyy'));
            setSite(company.site);
            setEmail(company.email);
            setTelephone(phoneMask(company.telephone));
            setUrl(company.url);
            setLatitude(company.latitude);
            setLongitude(company.longitude);
        
            setSocieties(company.societies);
            setEmployees(company.employees);

            // Address
            setAddressId(company.addressId);
            setZipcode(zipcodeMask(company.address.zipcode));
            setState(company.address.state);
            setCity(company.address.city);
            setDistrict(company.address.district);
            setPlace(company.address.place);
            setHabitation(company.habitation);
            setComplement(company.complement);
        }
    } // fillRecord

    async function isValidForm() {
        let result = true;
        let emptyRequired = false;

        if (isEmpty(socialReason))
            emptyRequired = true;
        if (isEmpty(fantasy))
            emptyRequired = true;
        if (isEmpty(registrationPj))
            emptyRequired = true;
        if ((!isEmpty(registrationPj)) && (!isCnpj(registrationPj))) {
            await alertContext.show(AlertMessageEnum.FAIL, title, display.message.invalid.registration_pj);
            result = false;
        }

        if (emptyRequired) {
            await alertContext.show(AlertMessageEnum.FAIL, title, display.message.invalid.required);
            result = false;
        }

        setInvalidatedForm(!result);
        return result;
    } // isValidForm

    async function onClick_Confirm(event : any) {
        event.preventDefault();
        
        const isValid = await isValidForm();
        if (isValid) {
            try {
                const isConfirmed = await confirmContext.show(title, display.message.confirm_the_data_entered);
                if (isConfirmed) {
                    setSending(true);
                
                    const company = fillCompany();

                    if (proofOfResidenceDocument) {
                        proofOfResidenceDocument.source = 'COMPANY';
                        proofOfResidenceDocument.identy = String(company.id);
    
                        if ((proofOfResidenceDocument.id > 0) && !isEmpty(proofOfResidenceDocument.document)) {
                            await cduService.saveArtifact(proofOfResidenceDocument.id, proofOfResidenceDocument);
                        } else if ((proofOfResidenceDocument.id > 0) && isEmpty(proofOfResidenceDocument.document)) {
                            await cduService.artifactDeleteById(proofOfResidenceDocument.id);
                        } else if ((proofOfResidenceDocument.id === 0) && !isEmpty(proofOfResidenceDocument.document)) {
                            await cduService.createArtifact(proofOfResidenceDocument);
                        }   
                    }
                    setSending(false);
                    props.onSubmitModal(event, company);
                }
            } catch (error: any) {
                setSending(false);
                await alertContext.show(AlertMessageEnum.FAIL, title, error);
            }
        }
    }

    async function onClick_Cancel(event : any) {
        event.preventDefault();

        setInvalidatedForm(false);
        props.onCancelModal(event);
    }

    function clearForm() {
        setCompanyId(0);
        setCreated(notNullToDate(new Date(), 'dd/mm/yyyy HH:MM:ss'));  
        setUpdated(notNullToDate(new Date(), 'dd/mm/yyyy HH:MM:ss'));  

        setSituation(0);
        setSocialReason('');
        setFantasy('');
        setSector('');
        setRegistrationPj(props.registration);
        setRegistrationState('');
        setFoundation('');
        setSite('');
        setEmail('');
        setTelephone('');
        setUrl('');
        setLatitude('');
        setLongitude('');
    
        setSocieties([] as any);
        setEmployees([] as any);

        // Address
        setAddressId(0);
        setZipcode('');
        setState('');
        setCity('');
        setDistrict('');
        setPlace('');
        setHabitation('');
        setComplement('');
    }

    function fillCompany() {

        for (var i = 0; i < societies.length; i++) {
            const society = societies[i];

            let addressSociety = [];
            if (!isEmpty(society.people.address)) {
                for ( var j = 0; j < society.people.address.length; j++) {
                    const addressPeople = society.people.address[j];
                    addressSociety[j] = {
                        peopleId : addressPeople.peopleId,
                        addressId : addressPeople.addressId,
                        address : {
                            id : addressPeople.address.id,
                            zipcode : addressPeople.address.zipcode,
                            state : addressPeople.address.state,
                            city : addressPeople.address.city,
                            district : addressPeople.address.district,
                            place : addressPeople.address.place
                        },
                        correspondence : addressPeople.correspondence,
                        norm : addressPeople.norm,
                        habitation : addressPeople.habitation,
                        complement : addressPeople.complement
                    }
                }
            }

            let phonesSociety = [];
            if (!isEmpty(society.people.phones)) {
                for ( var k = 0; k < society.people.phones.length; k++) {
                    const phone = society.people.phones[k];
                    phonesSociety[k] = {
                        id : phone.id, 
                        norm : phone.norm, 
                        operator : phone.operator, 
                        connection : removeMask(phone.connection), 
                        sms : phone.sms, 
                        zap : phone.zap, 
                        major : phone.major
                    }
                }
            }

            const dataSociety = {
                id : society.id, 
                companyId, 
                societyType : society.societyType, 
                created : removeFormatDate(society.created), 
                updated : removeFormatDate(society.updated),
                peopleId : society.peopleId,
                people : {
                    id : society.people.id,
                    tratament : '', // society.people.tratament,
                    fullname : society.people.fullname, 
                    gender : society.people.gender, 
                    birth : removeFormatDate(society.people.birth), 
                    phones : phonesSociety, 
                    nationality : society.people.nationality, 
                    naturalness : society.people.naturalness, 
                    registrationPf : removeMask(society.people.registrationPf),
                    registrationIdentity : removeMask(society.people.registrationIdentity),
                    issuerIdentity : society.people.issuerIdentity,
                    stateIdentity : society.people.stateIdentity,
                    expedition : removeFormatDate(society.people.expedition),
                    driversLicense : society.people.driversLicense,
                    categoryDriversLicense : society.people.categoryDriversLicense,
                    validityDriversLicense : removeFormatDate(society.people.validityDriversLicense),
                    adresses: addressSociety
                }
            }

            societies[i] = dataSociety;
        }

        for (var l = 0; l < employees.length; l++) {
            const employee = employees[l];

            let addressEmployee = [];
            if (!isEmpty(employee.people.address)) {
                for ( var m = 0; m < employee.people.address.length; m++) {
                    const addressPeople = employee.people.address[m];
                    addressEmployee[m] = {
                        peopleId : addressPeople.peopleId,
                        addressId : addressPeople.addressId,
                        address : {
                            id : addressPeople.address.id,
                            zipcode : addressPeople.address.zipcode,
                            state : addressPeople.address.state,
                            city : addressPeople.address.city,
                            district : addressPeople.address.district,
                            place : addressPeople.address.place
                        },
                        correspondence : addressPeople.correspondence,
                        norm : addressPeople.norm,
                        habitation : addressPeople.habitation,
                        complement : addressPeople.complement
                    }
                }
            }

            let phonesEmployee = [];
            if (!isEmpty(employee.people.phones)) {
                for ( var n = 0; n < employee.people.phones.length; n++) {
                    const phone = employee.people.phones[n];
                    phonesEmployee[n] = {
                        id : phone.id, 
                        norm : phone.norm, 
                        operator : phone.operator, 
                        connection : removeMask(phone.connection), 
                        sms : phone.sms, 
                        zap : phone.zap, 
                        major : phone.major
                    }
                }
            }

            const dataEmployee = {
                id : employee.id,
                companyId, 
                employeeType : employee.employeeType, 
                created : removeFormatDate(employee.created),
                updated : removeFormatDate(employee.updated),
                sector : employee.sector,
                office : employee.office,
                remuneration : removeMask(employee.remuneration),
                workdayFirst : employee.workdayFirst,
                workdayStop : employee.workdayStop,
                workdayStart : employee.workdayStart,
                workdayLast : employee.workdayLast,
                peopleId : employee.peopleId,
                people : {
                    id : employee.people.id,
                    tratament : employee.people.tratament,
                    fullname : employee.people.fullname, 
                    gender : employee.people.gender, 
                    birth : removeFormatDate(employee.people.birth), 
                    phones : phonesEmployee, 
                    nationality : employee.people.nationality, 
                    naturalness : employee.people.naturalness,
                    registrationPf : removeMask(employee.people.registrationPf),
                    registrationIdentity : removeMask(employee.people.registrationIdentity),
                    issuerIdentity : employee.people.issuerIdentity,
                    stateIdentity : employee.people.stateIdentity,
                    expedition : removeFormatDate(employee.people.expedition),
                    driversLicense : employee.people.driversLicense,
                    categoryDriversLicense : employee.people.categoryDriversLicense,
                    validityDriversLicense : removeFormatDate(employee.people.validityDriversLicense),
                    adresses: addressEmployee
                }
            };

            employees[l] = dataEmployee;
        }

        const dataCompany = {
            id : companyId,
            situation, 
            created : removeFormatDate(created), 
            updated : removeFormatDate(updated),

            socialReason, 
            fantasy, 
            sector, 
            registrationPj : removeMask(registrationPj), 
            registrationState, 
            foundation : removeFormatDate(foundation),
            site, 
            email, 
            telephone : removeMask(telephone), 
            url, 
            latitude, 
            longitude, 
            societies, 
            employees, 
            addressId,
            address : {
                id : addressId,
                zipcode : removeMask(zipcode), 
                state, 
                city, 
                district, 
                place 
            },
            habitation, 
            complement
        };

        return dataCompany;
    }

    async function onClick_SearchCompany(event: any) {
        event.preventDefault();

        if (!isEmpty(registrationPj)) {
            const registration = removeMask(registrationPj);
            if (isCnpj(registration)) {
                const company = await cduService.companyByRegistration(registration);
                fillRecord(company);

                if (!isEmpty(company)) {
                    const filter = {
                        where: { ownerType : 'PJ', ownerId : company.id }
                    } as TFilter;
                    let partnerFilter = await srvService.purveyorFilter(filter); // {count: 0, rows: []} as any; 
                    if (partnerFilter.rows.length > 0) {
                        await alertContext.show(AlertMessageEnum.SUCCESS, title, 'CNPJ já cadastrado.');
                        setInvalidatedForm(false);
                        props.onCancelModal(event);
                    }
                } else 
                    await alertContext.show(AlertMessageEnum.SUCCESS, title, 'CNPJ não localizado. Efetue o cadastro completo.');
                } else {
                await alertContext.show(AlertMessageEnum.FAIL, title, 'CNPJ Inválido !');
            }
        } else {
            await alertContext.show(AlertMessageEnum.FAIL, title, 'Insira um CNPJ válido !');
        }
    }

    async function searchFilter() {
        clearForm();
        fillRecord(props.record);
        
        cduService.artifactByNorm('COMPANY', props.record?.id, 'FILE_RESIDENCE').then((artifact: any) => {
            if (artifact)
                setProofOfResidenceDocument(artifact);
        });
    }

    useEffect(() => {
        if (props.show)
            searchFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show]);

    /* */

    function changeProofOfResidenceDocument(event: any) {
        if (event.target.files && event.target.files.length > 0) {
            let reader = new FileReader();
            reader.onload = (e: any) => {
                let artfact: ArtifactType = {
                    id : proofOfResidenceDocument.id,
                    norm : 'FILE_RESIDENCE',
                    source : 'COMPANY',
                    identy : String(companyId),
                    document : e.target.result,
                    filename : event.target.files[0].name
                }
                setProofOfResidenceDocument(artfact);
            };
            reader.readAsDataURL(event.target.files[0]);
        } else {
            let artifact: ArtifactType = { id : proofOfResidenceDocument.id, norm : 'FILE_RESIDENCE', source : 'COMPANY', identy : String(companyId), document : '', filename : '' };
            setProofOfResidenceDocument(artifact);
        }
    }

    function clickViewDocument(event: any, title: string, url: any) {
        event.preventDefault();
        openDocument(title, url);
    }

    function viewForm() {
        return (
            <>
                <Modal id="modal-cadastre-launcher-form" show={props.show}
                    size="lg"
                    aria-labelledby="modal-cadastre-launcher-form"
                    centered
                >
                    <Modal.Header>
                        <Modal.Title >{title}</Modal.Title>
                        <div className="float-right d-flex justify-content-end">
                            <FaTimes className="isClickable" onClick={ onClick_Cancel } size={26}/>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <Form className="form" id="form-cadastre-company" validated={invalidatedForm} style={{ minHeight : 300 }}>
                            { !props.isEdit && <>
                                <Row>
                                    <Col sm="5">
                                        <FormGroup>
                                            <Form.Label htmlFor="form-registration_pj">{display.label.registration_pj}</Form.Label>
                                            <InputGroup className="mb-2">
                                                <FormControl type="text" id="form-registration_pj" name="registration_pj" placeholder={'Digite o CNPJ'} required readOnly={props.isEdit}
                                                    value={toString(registrationPj)}
                                                    onChange={e => setRegistrationPj(cnpjMask(e.target.value))}
                                                />
                                                <InputGroup.Text>
                                                    <a href="/#" onClick={onClick_SearchCompany}>
                                                        <FaSearch />
                                                    </a>
                                                </InputGroup.Text>
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                </Row> 
                                <Row><Col><hr /></Col></Row>
                            </>}
                            <Row>
                                <Col sm="12">
                                    <fieldset>
                                        <legend>{display.legend.general_data}</legend>
                                        <CorporateDataRecord 
                                            title={title}
                                            isEdit={false}

                                            socialReason={socialReason}
                                            fantasy={fantasy}
                                            sector={sector}
                                            registrationPj={registrationPj}
                                            registrationState={registrationState}
                                            foundation={foundation}
                                            site={site}
                                            email={email}
                                            telephone={telephone}
                                            situation={situation}
                                            setSocialReason={(value: any) => { setSocialReason(value); }}  
                                            setFantasy={(value: any) => { setFantasy(value); }}  
                                            setSector={(value: any) => { setSector(value); }}  
                                            setRegistrationPj={(value: any) => { setRegistrationPj(value); }}  
                                            setRegistrationState={(value: any) => { setRegistrationState(value); }} 
                                            setFoundation={(value: any) => { setFoundation(value); }} 
                                            setSite={(value: any) => { setSite(value); }} 
                                            setEmail={(value: any) => { setEmail(value); }} 
                                            setTelephone={(value: any) => { setTelephone(value); }} 
                                            setSituation={(value: any) => { setSituation(value); }} 
                                        />
                                    </fieldset>
                                    <fieldset>
                                        <legend>{display.legend.address}</legend>
                                        <AddressRecord 
                                            title={title}
                                            isEdit={false}

                                            zipcode={zipcode}
                                            state={state}
                                            city={city}
                                            district={district}
                                            place={place}
                                            habitation={habitation}
                                            complement={complement}
                                            setZipcode={(value: any) => { setZipcode(value); }} 
                                            setState={(value: any) => { setState(value); }} 
                                            setCity={(value: any) => { setCity(value); }} 
                                            setDistrict={(value: any) => { setDistrict(value); }} 
                                            setPlace={(value: any) => { setPlace(value); }} 
                                            setHabitation={(value: any) => { setHabitation(value); }} 
                                            setComplement={(value: any) => { setComplement(value); }}

                                            proofOfResidenceDocument={proofOfResidenceDocument}
                                            changeProofOfResidenceDocument={changeProofOfResidenceDocument}
                                            clickViewDocument={clickViewDocument}
                                        />
                                    </fieldset>
                                </Col>
                            </Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={ onClick_Cancel } variant="secondary">{display.buttom.cancel}</Button>
                        <Button onClick={ onClick_Confirm } disabled={sending}>
                            { sending ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null }{'  '}
                            { display.buttom.save }
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

    return (
        <>
        { viewForm() }
        </>
    )
}

export default CompanyModal
