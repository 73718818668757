import { Col, Form, FormControl, InputGroup, Row } from "react-bootstrap";
import { BsSliders } from "react-icons/bs";
import { FaSearch } from "react-icons/fa";

import { useTranslateContext } from '../../contexts/translate.context';

import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import TableContainer from "../../components/table-container.component";
import { toString } from "../../utilities/auxiliary-functions";
import { cpfCnpjMask } from "../../utilities/masks";
import { PersonType } from "../../utilities/types";
import { isEmpty } from "../../utilities/validators";

export interface IPurveyorRecordProps {
    // escencials
    title: any,
    isEdit: boolean,
    created?: any,
    updated?: any,

    // attibutes
    ownerType?: any,
    ownerId?: any,
    ownerName?: any,
    ownerRegistry?: any,

    setOwnerType?: any,
    setOwnerId?: any,
    setOwnerName?: any,
    setOwnerRegistry?: any,
    catalogs?: any,

    columnsCatalog?: any,
    // functions
    searchOwner?: any,
    filterPerson?: any,
    addPerson?: any,
    addItemRecord?: any,
}

export const PurveyorRecord: React.FC<IPurveyorRecordProps> = (props: IPurveyorRecordProps) => {

    const { display } = useTranslateContext();

    function onClick_SearchOwner(event: any) {
        event.preventDefault();
        props.searchOwner(event);
    }

    function onClick_FilterPerson(event: any) {
        event.preventDefault();
        props.filterPerson(event);
    }

    function onChange_OwnerType(event: any) {
        props.setOwnerType(event.target.value);
        props.setOwnerId(0);
        props.setOwnerName('');
        props.setOwnerRegistry('');
    }

    function onClick_Person(event: any) {
        event.preventDefault();
        props.addPerson(event);
    }

    function onClick_AddItemRecord(event: any) {
        event.preventDefault();
        props.addItemRecord(event);
    }

    return (
        <>
            <Row>
                <Col sm="12">
                    <fieldset>
                        <legend>Tipo de Pessoa
                            <div className="card-actions float-right">
                                <Button sx={{minWidth: 104, maxWidth: 104}} variant="contained" size="small" onClick={(e) => onClick_Person(e)}>
                                    <AddIcon />
                                    Fornecedor
                                </Button>
                            </div>
                        </legend>
                        <Row>
                            <Col sm="2">
                                <Form.Label htmlFor="form-owner-type">{display.label.owner_type} (PF / PJ)</Form.Label>
                            </Col>
                            <Col sm="10">
                                <Form.Label htmlFor="form-owner-registry">{display.label.owner_registry} (CPF / CNPJ)</Form.Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="3">
                                <Form.Select id="form-owner-type" name="ownerType" required
                                    value={props.ownerType}
                                    onChange={(e: any) => onChange_OwnerType(e)}
                                >
                                    { PersonType.map((iterator: any, idx: number) => (<option key={idx} value={iterator.id}>{iterator.name}</option>)).sort((a : any, b : any) => { return a.props.children > b.props.children ? 1 : -1; }) }
                                </Form.Select>
                            </Col>
                            <Col sm="4">
                                <InputGroup className="mb-2">
                                    <FormControl type="hidden" id="form-owner-id" name="ownerId"
                                        value={props.ownerId}
                                        onChange={e => props.setOwnerId(e.target.value)}
                                    />
                                    <FormControl id="form-owner-registry"  name="ownerRegistry" placeholder={display.example.owner_registry} required
                                        value={toString(props.ownerRegistry)}
                                        onChange={e => props.setOwnerRegistry(cpfCnpjMask(e.target.value))}
                                    />
                                    <InputGroup.Text>
                                        <a href="/#" onClick={onClick_SearchOwner}>
                                            <FaSearch />
                                        </a>
                                    </InputGroup.Text>
                                    <InputGroup.Text>
                                        <a href="/#" onClick={onClick_FilterPerson}>
                                            <BsSliders />
                                        </a>
                                    </InputGroup.Text>
                                </InputGroup>
                            </Col>
                            <Col sm="5">
                                <FormControl id="form-owner-data" name="ownerName" placeholder={display.example.owner_name} readOnly
                                    value={toString(props.ownerName)}
                                    onChange={e => props.setOwnerName(e.target.value)}
                                />
                            </Col>
                        </Row>
                    </fieldset>
                </Col>
            </Row>
            <Row>
                <Col sm="12">
                    <fieldset>
                        <legend>{display.legend.catalogs}
                            <div className="card-actions float-right">

                            <Button variant="contained" sx={{minWidth: 104, maxWidth: 104}} size="small" onClick={(e) => onClick_AddItemRecord(e)}>
                                    <AddIcon />
                                    Catálogo
                            </Button>
                            </div>
                        </legend>
                        <TableContainer columns={props.columnsCatalog} data={isEmpty(props.catalogs) ? [] : props.catalogs} />
                    </fieldset>
                </Col>
            </Row>
        </>
    );

}

export default PurveyorRecord;
