import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import React, { useState } from 'react';
import { Modal } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";

import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import styles from './SelectServiceModal.module.scss';

const theme = createTheme({
    palette: {
        primary: {
            main: '#3366FF'
        },
            secondary: {
            main: '#4E5F78'
        },
        mode: 'dark'
    },

    typography: {
        fontFamily: ['Montserrat', 'sans-serif'].join(',')
    }
})

export interface ISelectServiceModalProps {
    show: any,
    owner?: any,
    vesselNotInList?: any,
    typeFilter?: any,
    subTitle?: any,
    onClick_Close: any,
}

export const SelectServiceModal: React.FC<ISelectServiceModalProps> = (props: ISelectServiceModalProps) => {

    const [ selectedService, setSelecteService ] = useState('');

    const cancel = () => {
        props.onClick_Close()
        setSelecteService('')
    }

    const servicesOptions = [
        {
            label: 'Manutenção',
            value: 'MAINTENANCE'
        },
        {
            label: 'Limpeza e Higienização',
            value: 'CLEANING'
        },
        {
            label: 'Delivery',
            value: 'DELIVERY'
        }
    ]


    const MenuProps = {
        PaperProps: {
            style: {
            width: 225,
            maxHeight: 500
            }
        }
    }

    return (
        <Modal size="sm" show={props.show} centered>
          <Modal.Header>
            <Modal.Title>
              <MiscellaneousServicesIcon />Solicitar Serviço</Modal.Title>
            <div className="card-actions float-right">
              <FaTimes className="isClickable" size={22} onClick={props.onClick_Close} />
            </div>
          </Modal.Header>
          <Modal.Body style={{ minHeight : 200, paddingLeft: 0, paddingRight: 0 }}>
            <ThemeProvider theme={theme}>
              <div className="d-flex flex-column align-items-center">
                <p>Selecione o serviço desejado:</p>

                <FormControl variant="standard" className="mt-0" sx={{ width: 180 }} fullWidth>
                  <InputLabel id="businessType">Serviço</InputLabel>
                  <Select labelId="businessType" id="businessType-select" value={ selectedService?.toUpperCase() || '' }
                    onChange={e=>
                    setSelecteService(e.target.value)
                    }
                    label="Serviço"
                    MenuProps={MenuProps}
                    >
                    {servicesOptions?.map(
                    (
                    option: any,
                    index: {
                    toString: () => React.Key | null | undefined
                    }
                    ) => (
                    <MenuItem selected={ selectedService?.toUpperCase()===option.value } key={index.toString()}
                      value={option.value}>
                    {option.label}
                    </MenuItem>
                    )
                    )}
                  </Select>
                </FormControl>
              </div>
              <div className={`justify-content-center d-flex mt-5`}>
                <Button variant="outlined" onClick={()=> cancel()}
                  color="inherit"
                  className={`${styles.submit} me-4`}
                  >
                  Cancelar
                </Button>

                <Button disabled={ selectedService==='' } variant="contained" color="primary"
                  className={`${styles.submit}`} onClick={()=> cancel()}
                  >
                  Prosseguir
                </Button>
              </div>


            </ThemeProvider>
          </Modal.Body>
        </Modal>
    )
}

export default SelectServiceModal;
