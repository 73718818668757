import React, { useState, useEffect } from 'react'
import { Modal } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";

import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import BlockIcon from '@mui/icons-material/Block'
import Box from '@mui/material/Box'
import { Button } from '@mui/material'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { ptBR } from 'date-fns/locale'
import { v4 as uuid } from 'uuid'
import { useSnackbar } from 'notistack'
import Typography from '@mui/material/Typography'
import { createTheme, ThemeProvider } from '@mui/material/styles'

import styles from './select-reservation-filter.module.scss'
import lclService from "../../services/lcl.service";
import { removeFormatDate } from "../../utilities/masks";
import { dateToString, toDate, toInt } from "../../utilities/auxiliary-functions";
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import authService from "../../services/auth.service";
import cduService from "../../services/cdu.service";

interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
}

const theme = createTheme({
    palette: {
        primary: {
            main: '#3366FF'
        },
        secondary: {
            main: '#4E5F78'
        },
        mode: 'dark'
    },
    typography: {
        fontFamily: ['Montserrat', 'sans-serif'].join(',')
    }
})

export interface ISelectReservationFilterProps {
    show: any,
    onClick_Close: any,
    onClick_Confirm: any,

    owner?: any,
    vesselNotInList?: any,
    typeFilter?: any,
    subTitle?: any
}

export const SelectReservationFilter: React.FC<ISelectReservationFilterProps> = (props: ISelectReservationFilterProps) => {
    
    const [ value, setValue ] = useState(0)
    const [ selectedReservation, setSelectedReservation ] = useState({
        date: new Date(),
        departure: '',
        arrives: ''
    })
    const [ selectedDepartureHour, setSelectedDepartureHour ] = useState('')
    const [ selectedArriveHour, setSelectedArriveHour ] = useState('')
    const [ disabledHours, setDisabledHours ] = useState([] as any)
    const [ records, setRecords ] = useState([] as any);
    const [ intervalOperation, setIntervalOperation ] = useState(5);

    const [ morning, setMorning ] = useState(0);
    const [ afternoon, setAfternoon ] = useState(0);
    const [ night, setNight ] = useState(0);

    const { enqueueSnackbar } = useSnackbar()

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }

    const cancel = () => {
        props.onClick_Close()
        setSelectedDepartureHour('')
        setValue(0)
        setSelectedArriveHour('')
        setSelectedReservation({
            date: new Date(),
            departure: '',
            arrives: ''
        })
    }

    const handleConfirmation = () => {
        props.onClick_Confirm(selectedReservation)
    }

    const parseHour = (hour: string): string => {
        if (hour.length === 1) {
            return `0${hour}`
        }
        return hour
    }

    const selectDepartureHour = (hour: any) => {
        if (disabledHours.includes(hour)) {
            enqueueSnackbar(`Não disponível. Por favor escolha outro horário.`, {
                variant: 'error'
            })
        } else {
            setSelectedDepartureHour(hour)
            setSelectedReservation({ ...selectedReservation, departure: hour })
        }
    }

    const selectArriveHour = (hour: any) => {
        setSelectedArriveHour(hour)
        setSelectedReservation({ ...selectedReservation, arrives: hour })
    }

    const handleDate = (date: Date) => {
        if (value === 3) {
            setValue(0)
        }
        setSelectedDepartureHour('')
        setSelectedArriveHour('')
        setSelectedReservation({
            ...selectedReservation,
            date,
            departure: '',
            arrives: ''
        })
    }

    useEffect(() => {
        if (props.show) {
        const dateDefault = removeFormatDate(dateToString(selectedReservation.date, 'dd/mm/yyyy'));
        const partner = authService.currentPartner();
        
        const attributes_parameter = [] as any;
        const where_parameter = {
            companyId: partner.company.id,
            code: '101'
        } as any;
        const order_parameter = [['group', 'ASC'], ['code', 'ASC']] as any;
        cduService
            .parameterList(attributes_parameter, where_parameter, order_parameter)
            .then((result: any) => {
                if (result && result.length > 0) {
                    const parameter = result[0];
                    setIntervalOperation(toInt(parameter.content));
                }
            });

        lclService
            .getAppointmentByDate(dateDefault, partner.id)
            .then((result: any) => {
                setRecords(result);
                if (result) {
                    const localMorning = result.filter((movement: any) => {
                        const nDate = toDate(movement.departure, 'yyyy-mm-ddTHH:MM:ss.sssZ');
                        return nDate.getHours() < 12;
                    });
                    setMorning(Math.round((localMorning.length / ((60 / intervalOperation) * 5)) * 100));

                    const localAfternoon = result.filter((movement: any) => {
                        const nDate = toDate(movement.departure, 'yyyy-mm-ddTHH:MM:ss.sssZ');
                        return nDate.getHours() >= 12 && nDate.getHours() < 18;
                    });
                    setAfternoon(Math.round((localAfternoon.length / ((60 / intervalOperation) * 5)) * 100));

                    const localNight = result.filter((movement: any) => {
                        const nDate = toDate(movement.departure, 'yyyy-mm-ddTHH:MM:ss.sssZ');
                        return nDate.getHours() >= 18;
                    });
                    setNight(Math.round((localNight.length / ((60 / intervalOperation) * 5)) * 100));

                    const local = result.map((movement: any) => dateToString(movement.departure, 'HH:MM'));
                    setDisabledHours(local);
                }
            });
        }
    }, [props.show]);

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`
        }
    }

    function TabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props

        return (
            <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            >
            {value === index && (
                <Box sx={{ p: 3 }}>
                <Typography>{children}</Typography>
                </Box>
            )}
            </div>
        )
    }

    return (
        <Modal
            size="lg"
            show={props.show}
            centered
        >
            <Modal.Header>
                <Modal.Title><QueryBuilderIcon />Horários Disponíveis</Modal.Title>
                <div className="card-actions float-right">
                    <FaTimes className="isClickable" size={22} onClick={props.onClick_Close} />
                </div>
            </Modal.Header>
            <Modal.Body style={{ minHeight : 400, paddingLeft: 0, paddingRight: 0 }}>
    <ThemeProvider theme={theme}> 

        <div className="d-flex justify-content-start align-items-center ps-4 pe-4">
          <LocalizationProvider
            adapterLocale={ptBR}
            dateAdapter={AdapterDateFns}
          >
            <DesktopDatePicker
              label="Data do agendamento"
              sx={{ width: 180}}
              value={selectedReservation.date}
              onChange={(newValue: any) => {
                handleDate(newValue)
              }}
              className="mt-2"
              minDate={new Date()}
              slotProps={{
                textField: {
                  variant: 'standard'
                }
              }}
              
            />
          </LocalizationProvider>
          <div
            className={`d-flex flex-column align-items-start ${
              selectedReservation.departure === '' ? 'ms-2' : 'ms-2'
            }`}
          >
            {selectedReservation.departure === '' && (
              <h6 className="mt-2 ms-5">
                Selecione a data desejada para visualizar a disponibilidade
                horária
              </h6>
            )}

            {selectedReservation.departure !== '' && (
              <div
                className={`mt-2 d-flex flex-column align-items-center ${styles.selectedReservation}`}
              >
                <h5>RESERVA SELECIONADA</h5>
                <p>
                  {`Saída: ${new Date(
                    selectedReservation.date
                  ).toLocaleDateString()} - ${selectedReservation.departure}`}
                </p>
                {selectedReservation.arrives !== '' && (
                  <p>
                    {`Retorno: ${new Date(
                      selectedReservation.date
                    ).toLocaleDateString()} - ${selectedReservation.arrives}`}
                  </p>
                )}
                {selectedReservation.arrives === '' && (
                  <p>SELECIONE A PREVISÃO DE RETORNO</p>
                )}
              </div>
            )}
          </div>
        </div>
        <h5
          className="text-center mt-3 mb-0"
          style={{ textTransform: 'uppercase' }}
        >
          Ocupação
        </h5>
        <Box
          sx={{ width: '100%' }}
          className={`${value === 1 ? 'mb-2' : 'mb-3'}`}
        >
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              variant="fullWidth"
            >
              <Tab label={`Manhã (${morning}%)`} {...a11yProps(0)} />
              <Tab label={`Tarde (${afternoon}%)`} {...a11yProps(1)} />
              <Tab label={`Noite (${night}%)`} {...a11yProps(2)} />
              <Tab
                {...a11yProps(3)}
                label="Previsão de Retorno"
                disabled={selectedReservation.departure === ''}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <span className={styles.hourWrapper}>
              <span className="d-flex w-100">
                <span className="d-flex justify-content-between w-100">
                  {Array(60 / intervalOperation)
                    .fill(0)
                    ?.map(
                      (
                        option: any,
                        index: {
                          toString: () => React.Key | null | undefined
                        }
                      ) => (
                        <span
                          role="presentation"
                          onClick={() =>
                            selectDepartureHour(
                              `08:${parseHour(
                                (intervalOperation * Number(index)).toString()
                              )}`
                            )
                          }
                          key={uuid()}
                          className={`${
                            disabledHours.includes(
                              `08:${parseHour(
                                (intervalOperation * Number(index)).toString()
                              )}`
                            )
                              ? styles.unavailable
                              : ''
                          } ${styles.hour} ${
                            selectedDepartureHour ===
                            `08:${parseHour(
                              (intervalOperation * Number(index)).toString()
                            )}`
                              ? styles.selected
                              : ''
                          }`}
                        >
                          {disabledHours.includes(
                            `08:${parseHour(
                              (intervalOperation * Number(index)).toString()
                            )}`
                          ) ? (
                            <span>
                              <BlockIcon />
                            </span>
                          ) : (
                            `08:${parseHour(
                              (intervalOperation * Number(index)).toString()
                            )}`
                          )}
                        </span>
                      )
                    )}
                </span>
              </span>
              <span className={`d-flex w-100 ${styles.grayLine}`}>
                <span className="d-flex justify-content-between w-100">
                  {Array(60 / intervalOperation)
                    .fill(0)
                    ?.map(
                      (
                        option: any,
                        index: {
                          toString: () => React.Key | null | undefined
                        }
                      ) => (
                        <span
                          role="presentation"
                          onClick={() =>
                            selectDepartureHour(
                              `09:${parseHour(
                                (intervalOperation * Number(index)).toString()
                              )}`
                            )
                          }
                          key={uuid()}
                          className={`${styles.grayLine} ${
                            selectedDepartureHour ===
                            `09:${parseHour(
                              (intervalOperation * Number(index)).toString()
                            )}`
                              ? styles.selected
                              : ''
                          } ${
                            disabledHours.includes(
                              `09:${parseHour(
                                (intervalOperation * Number(index)).toString()
                              )}`
                            )
                              ? styles.unavailable
                              : ''
                          } ${styles.hour}`}
                        >
                          {disabledHours.includes(
                            `09:${parseHour(
                              (intervalOperation * Number(index)).toString()
                            )}`
                          ) ? (
                            <span>
                              <BlockIcon />
                            </span>
                          ) : (
                            `09:${parseHour(
                              (intervalOperation * Number(index)).toString()
                            )}`
                          )}
                        </span>
                      )
                    )}
                </span>
              </span>
              <span className="d-flex w-100">
                <span className="d-flex justify-content-between w-100">
                  {Array(60 / intervalOperation)
                    .fill(0)
                    ?.map(
                      (
                        option: any,
                        index: {
                          toString: () => React.Key | null | undefined
                        }
                      ) => (
                        <span
                          role="presentation"
                          onClick={() =>
                            selectDepartureHour(
                              `10:${parseHour(
                                (intervalOperation * Number(index)).toString()
                              )}`
                            )
                          }
                          key={uuid()}
                          className={`${
                            disabledHours.includes(
                              `10:${parseHour(
                                (intervalOperation * Number(index)).toString()
                              )}`
                            )
                              ? styles.unavailable
                              : ''
                          } ${styles.hour} ${
                            selectedDepartureHour ===
                            `10:${parseHour(
                              (intervalOperation * Number(index)).toString()
                            )}`
                              ? styles.selected
                              : ''
                          }`}
                        >
                          {disabledHours.includes(
                            `10:${parseHour(
                              (intervalOperation * Number(index)).toString()
                            )}`
                          ) ? (
                            <span>
                              <BlockIcon />
                            </span>
                          ) : (
                            `10:${parseHour(
                              (intervalOperation * Number(index)).toString()
                            )}`
                          )}
                        </span>
                      )
                    )}
                </span>
              </span>
              <span className={`d-flex w-100 ${styles.grayLine}`}>
                <span className="d-flex justify-content-between w-100">
                  {Array(60 / intervalOperation)
                    .fill(0)
                    ?.map(
                      (
                        option: any,
                        index: {
                          toString: () => React.Key | null | undefined
                        }
                      ) => (
                        <span
                          role="presentation"
                          onClick={() =>
                            selectDepartureHour(
                              `11:${parseHour(
                                (intervalOperation * Number(index)).toString()
                              )}`
                            )
                          }
                          key={uuid()}
                          className={`${styles.grayLine} ${
                            disabledHours.includes(
                              `11:${parseHour(
                                (intervalOperation * Number(index)).toString()
                              )}`
                            )
                              ? styles.unavailable
                              : ''
                          } ${styles.hour} ${
                            selectedDepartureHour ===
                            `11:${parseHour(
                              (intervalOperation * Number(index)).toString()
                            )}`
                              ? styles.selected
                              : ''
                          }`}
                        >
                          {disabledHours.includes(
                            `11:${parseHour(
                              (intervalOperation * Number(index)).toString()
                            )}`
                          ) ? (
                            <span>
                              <BlockIcon />
                            </span>
                          ) : (
                            `11:${parseHour(
                              (intervalOperation * Number(index)).toString()
                            )}`
                          )}
                        </span>
                      )
                    )}
                </span>
              </span>
              <span className="d-flex w-100">
                <span className="d-flex justify-content-between w-100">
                  {Array(60 / intervalOperation)
                    .fill(0)
                    ?.map(
                      (
                        option: any,
                        index: {
                          toString: () => React.Key | null | undefined
                        }
                      ) => (
                        <span
                          role="presentation"
                          onClick={() =>
                            selectDepartureHour(
                              `12:${parseHour(
                                (intervalOperation * Number(index)).toString()
                              )}`
                            )
                          }
                          key={uuid()}
                          className={`${
                            disabledHours.includes(
                              `12:${parseHour(
                                (intervalOperation * Number(index)).toString()
                              )}`
                            )
                              ? styles.unavailable
                              : ''
                          } ${styles.hour} ${
                            selectedDepartureHour ===
                            `12:${parseHour(
                              (intervalOperation * Number(index)).toString()
                            )}`
                              ? styles.selected
                              : ''
                          }`}
                        >
                          {disabledHours.includes(
                            `12:${parseHour(
                              (intervalOperation * Number(index)).toString()
                            )}`
                          ) ? (
                            <span>
                              <BlockIcon />
                            </span>
                          ) : (
                            `12:${parseHour(
                              (intervalOperation * Number(index)).toString()
                            )}`
                          )}
                        </span>
                      )
                    )}
                </span>
              </span>
            </span>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <span className={styles.hourWrapper}>
              <span className="d-flex w-100">
                <span className="d-flex justify-content-between w-100">
                  {Array(60 / intervalOperation)
                    .fill(0)
                    ?.map(
                      (
                        option: any,
                        index: {
                          toString: () => React.Key | null | undefined
                        }
                      ) => (
                        <span
                          role="presentation"
                          onClick={() =>
                            selectDepartureHour(
                              `13:${parseHour(
                                (intervalOperation * Number(index)).toString()
                              )}`
                            )
                          }
                          key={uuid()}
                          className={`${
                            disabledHours.includes(
                              `13:${parseHour(
                                (intervalOperation * Number(index)).toString()
                              )}`
                            )
                              ? styles.unavailable
                              : ''
                          } ${styles.hour} ${
                            selectedDepartureHour ===
                            `13:${parseHour(
                              (intervalOperation * Number(index)).toString()
                            )}`
                              ? styles.selected
                              : ''
                          }`}
                        >
                          {disabledHours.includes(
                            `13:${parseHour(
                              (intervalOperation * Number(index)).toString()
                            )}`
                          ) ? (
                            <span>
                              <BlockIcon />
                            </span>
                          ) : (
                            `13:${parseHour(
                              (intervalOperation * Number(index)).toString()
                            )}`
                          )}
                        </span>
                      )
                    )}
                </span>
              </span>
              <span className={`d-flex w-100 ${styles.grayLine}`}>
                <span className="d-flex justify-content-between w-100">
                  {Array(60 / intervalOperation)
                    .fill(0)
                    ?.map(
                      (
                        option: any,
                        index: {
                          toString: () => React.Key | null | undefined
                        }
                      ) => (
                        <span
                          role="presentation"
                          onClick={() =>
                            selectDepartureHour(
                              `14:${parseHour(
                                (intervalOperation * Number(index)).toString()
                              )}`
                            )
                          }
                          key={uuid()}
                          className={`${styles.grayLine} ${
                            selectedDepartureHour ===
                            `14:${parseHour(
                              (intervalOperation * Number(index)).toString()
                            )}`
                              ? styles.selected
                              : ''
                          } ${
                            disabledHours.includes(
                              `14:${parseHour(
                                (intervalOperation * Number(index)).toString()
                              )}`
                            )
                              ? styles.unavailable
                              : ''
                          } ${styles.hour}`}
                        >
                          {disabledHours.includes(
                            `14:${parseHour(
                              (intervalOperation * Number(index)).toString()
                            )}`
                          ) ? (
                            <span>
                              <BlockIcon />
                            </span>
                          ) : (
                            `14:${parseHour(
                              (intervalOperation * Number(index)).toString()
                            )}`
                          )}
                        </span>
                      )
                    )}
                </span>
              </span>
              <span className="d-flex w-100">
                <span className="d-flex justify-content-between w-100">
                  {Array(60 / intervalOperation)
                    .fill(0)
                    ?.map(
                      (
                        option: any,
                        index: {
                          toString: () => React.Key | null | undefined
                        }
                      ) => (
                        <span
                          role="presentation"
                          onClick={() =>
                            selectDepartureHour(
                              `15:${parseHour(
                                (intervalOperation * Number(index)).toString()
                              )}`
                            )
                          }
                          key={uuid()}
                          className={`${
                            disabledHours.includes(
                              `15:${parseHour(
                                (intervalOperation * Number(index)).toString()
                              )}`
                            )
                              ? styles.unavailable
                              : ''
                          } ${styles.hour} ${
                            selectedDepartureHour ===
                            `15:${parseHour(
                              (intervalOperation * Number(index)).toString()
                            )}`
                              ? styles.selected
                              : ''
                          }`}
                        >
                          {disabledHours.includes(
                            `15:${parseHour(
                              (intervalOperation * Number(index)).toString()
                            )}`
                          ) ? (
                            <span>
                              <BlockIcon />
                            </span>
                          ) : (
                            `15:${parseHour(
                              (intervalOperation * Number(index)).toString()
                            )}`
                          )}
                        </span>
                      )
                    )}
                </span>
              </span>
              <span className={`d-flex w-100 ${styles.grayLine}`}>
                <span className="d-flex justify-content-between w-100">
                  {Array(60 / intervalOperation)
                    .fill(0)
                    ?.map(
                      (
                        option: any,
                        index: {
                          toString: () => React.Key | null | undefined
                        }
                      ) => (
                        <span
                          role="presentation"
                          onClick={() =>
                            selectDepartureHour(
                              `16:${parseHour(
                                (intervalOperation * Number(index)).toString()
                              )}`
                            )
                          }
                          key={uuid()}
                          className={`${styles.grayLine} ${
                            disabledHours.includes(
                              `16:${parseHour(
                                (intervalOperation * Number(index)).toString()
                              )}`
                            )
                              ? styles.unavailable
                              : ''
                          } ${styles.hour} ${
                            selectedDepartureHour ===
                            `16:${parseHour(
                              (intervalOperation * Number(index)).toString()
                            )}`
                              ? styles.selected
                              : ''
                          }`}
                        >
                          {disabledHours.includes(
                            `16:${parseHour(
                              (intervalOperation * Number(index)).toString()
                            )}`
                          ) ? (
                            <span>
                              <BlockIcon />
                            </span>
                          ) : (
                            `16:${parseHour(
                              (intervalOperation * Number(index)).toString()
                            )}`
                          )}
                        </span>
                      )
                    )}
                </span>
              </span>
              <span className="d-flex w-100">
                <span className="d-flex justify-content-between w-100">
                  {Array(60 / intervalOperation)
                    .fill(0)
                    ?.map(
                      (
                        option: any,
                        index: {
                          toString: () => React.Key | null | undefined
                        }
                      ) => (
                        <span
                          role="presentation"
                          onClick={() =>
                            selectDepartureHour(
                              `17:${parseHour(
                                (intervalOperation * Number(index)).toString()
                              )}`
                            )
                          }
                          key={uuid()}
                          className={`${
                            disabledHours.includes(
                              `17:${parseHour(
                                (intervalOperation * Number(index)).toString()
                              )}`
                            )
                              ? styles.unavailable
                              : ''
                          } ${styles.hour} ${
                            selectedDepartureHour ===
                            `17:${parseHour(
                              (intervalOperation * Number(index)).toString()
                            )}`
                              ? styles.selected
                              : ''
                          }`}
                        >
                          {disabledHours.includes(
                            `17:${parseHour(
                              (intervalOperation * Number(index)).toString()
                            )}`
                          ) ? (
                            <span>
                              <BlockIcon />
                            </span>
                          ) : (
                            `17:${parseHour(
                              (intervalOperation * Number(index)).toString()
                            )}`
                          )}
                        </span>
                      )
                    )}
                </span>
              </span>
            </span>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <span className={styles.hourWrapper}>
              <span className="d-flex w-100">
                <span className="d-flex justify-content-between w-100">
                  {Array(60 / intervalOperation)
                    .fill(0)
                    ?.map(
                      (
                        option: any,
                        index: {
                          toString: () => React.Key | null | undefined
                        }
                      ) => (
                        <span
                          role="presentation"
                          onClick={() =>
                            selectDepartureHour(
                              `18:${parseHour(
                                (intervalOperation * Number(index)).toString()
                              )}`
                            )
                          }
                          key={uuid()}
                          className={`${
                            disabledHours.includes(
                              `18:${parseHour(
                                (intervalOperation * Number(index)).toString()
                              )}`
                            )
                              ? styles.unavailable
                              : ''
                          } ${styles.hour} ${
                            selectedDepartureHour ===
                            `18:${parseHour(
                              (intervalOperation * Number(index)).toString()
                            )}`
                              ? styles.selected
                              : ''
                          }`}
                        >
                          {disabledHours.includes(
                            `18:${parseHour(
                              (intervalOperation * Number(index)).toString()
                            )}`
                          ) ? (
                            <span>
                              <BlockIcon />
                            </span>
                          ) : (
                            `18:${parseHour(
                              (intervalOperation * Number(index)).toString()
                            )}`
                          )}
                        </span>
                      )
                    )}
                </span>
              </span>
              <span className="d-flex w-100">
                <span className="d-flex justify-content-between w-100">
                  {Array(60 / intervalOperation)
                    .fill(0)
                    ?.map(
                      (
                        option: any,
                        index: {
                          toString: () => React.Key | null | undefined
                        }
                      ) => (
                        <span
                          role="presentation"
                          onClick={() =>
                            selectDepartureHour(
                              `19:${parseHour(
                                (intervalOperation * Number(index)).toString()
                              )}`
                            )
                          }
                          key={uuid()}
                          className={`${
                            disabledHours.includes(
                              `19:${parseHour(
                                (intervalOperation * Number(index)).toString()
                              )}`
                            )
                              ? styles.unavailable
                              : ''
                          } ${styles.hour} ${
                            selectedDepartureHour ===
                            `19:${parseHour(
                              (intervalOperation * Number(index)).toString()
                            )}`
                              ? styles.selected
                              : ''
                          }`}
                        >
                          {disabledHours.includes(
                            `19:${parseHour(
                              (intervalOperation * Number(index)).toString()
                            )}`
                          ) ? (
                            <span>
                              <BlockIcon />
                            </span>
                          ) : (
                            `19:${parseHour(
                              (intervalOperation * Number(index)).toString()
                            )}`
                          )}
                        </span>
                      )
                    )}
                </span>
              </span>
              <span className={`d-flex w-100 ${styles.grayLine}`}>
                <span className="d-flex justify-content-between w-100">
                  {Array(60 / intervalOperation)
                    .fill(0)
                    ?.map(
                      (
                        option: any,
                        index: {
                          toString: () => React.Key | null | undefined
                        }
                      ) => (
                        <span
                          role="presentation"
                          onClick={() =>
                            selectDepartureHour(
                              `20:${parseHour(
                                (intervalOperation * Number(index)).toString()
                              )}`
                            )
                          }
                          key={uuid()}
                          className={`${styles.grayLine} ${
                            selectedDepartureHour ===
                            `20:${parseHour(
                              (intervalOperation * Number(index)).toString()
                            )}`
                              ? styles.selected
                              : ''
                          } ${
                            disabledHours.includes(
                              `20:${parseHour(
                                (intervalOperation * Number(index)).toString()
                              )}`
                            )
                              ? styles.unavailable
                              : ''
                          } ${styles.hour}`}
                        >
                          {disabledHours.includes(
                            `20:${parseHour(
                              (intervalOperation * Number(index)).toString()
                            )}`
                          ) ? (
                            <span>
                              <BlockIcon />
                            </span>
                          ) : (
                            `20:${parseHour(
                              (intervalOperation * Number(index)).toString()
                            )}`
                          )}
                        </span>
                      )
                    )}
                </span>
              </span>
              <span className="d-flex w-100">
                <span className="d-flex justify-content-between w-100">
                  {Array(60 / intervalOperation)
                    .fill(0)
                    ?.map(
                      (
                        option: any,
                        index: {
                          toString: () => React.Key | null | undefined
                        }
                      ) => (
                        <span
                          role="presentation"
                          onClick={() =>
                            selectDepartureHour(
                              `21:${parseHour(
                                (intervalOperation * Number(index)).toString()
                              )}`
                            )
                          }
                          key={uuid()}
                          className={`${
                            disabledHours.includes(
                              `21:${parseHour(
                                (intervalOperation * Number(index)).toString()
                              )}`
                            )
                              ? styles.unavailable
                              : ''
                          } ${styles.hour} ${
                            selectedDepartureHour ===
                            `21:${parseHour(
                              (intervalOperation * Number(index)).toString()
                            )}`
                              ? styles.selected
                              : ''
                          }`}
                        >
                          {disabledHours.includes(
                            `21:${parseHour(
                              (intervalOperation * Number(index)).toString()
                            )}`
                          ) ? (
                            <span>
                              <BlockIcon />
                            </span>
                          ) : (
                            `21:${parseHour(
                              (intervalOperation * Number(index)).toString()
                            )}`
                          )}
                        </span>
                      )
                    )}
                </span>
              </span>
              <span className={`d-flex w-100 ${styles.grayLine}`}>
                <span className="d-flex justify-content-between w-100">
                  {Array(60 / intervalOperation)
                    .fill(0)
                    ?.map(
                      (
                        option: any,
                        index: {
                          toString: () => React.Key | null | undefined
                        }
                      ) => (
                        <span
                          role="presentation"
                          onClick={() =>
                            selectDepartureHour(
                              `22:${parseHour(
                                (intervalOperation * Number(index)).toString()
                              )}`
                            )
                          }
                          key={uuid()}
                          className={`${styles.grayLine} ${
                            disabledHours.includes(
                              `22:${parseHour(
                                (intervalOperation * Number(index)).toString()
                              )}`
                            )
                              ? styles.unavailable
                              : ''
                          } ${styles.hour} ${
                            selectedDepartureHour ===
                            `22:${parseHour(
                              (intervalOperation * Number(index)).toString()
                            )}`
                              ? styles.selected
                              : ''
                          }`}
                        >
                          {disabledHours.includes(
                            `22:${parseHour(
                              (intervalOperation * Number(index)).toString()
                            )}`
                          ) ? (
                            <span>
                              <BlockIcon />
                            </span>
                          ) : (
                            `22:${parseHour(
                              (intervalOperation * Number(index)).toString()
                            )}`
                          )}
                        </span>
                      )
                    )}
                </span>
              </span>
            </span>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <span className={styles.hourWrapper}>
              <span className="d-flex w-100">
                <span className="d-flex justify-content-between w-100">
                  {Array(
                    23 - (Number(selectedDepartureHour.split(':')[0]) || 0)
                  )
                    .fill(0)
                    ?.map(
                      (
                        option: any,
                        index: {
                          toString: () => React.Key | null | undefined
                        }
                      ) => (
                        <span
                          role="presentation"
                          onClick={() =>
                            selectArriveHour(
                              `${
                                Number(selectedDepartureHour.split(':')[0]) +
                                  Number(index) +
                                  1 ===
                                9
                                  ? '0'
                                  : ''
                              }${
                                Number(selectedDepartureHour.split(':')[0]) +
                                Number(index) +
                                1
                              }:00`
                            )
                          }
                          key={uuid()}
                          className={` ${styles.hour} ${
                            selectedArriveHour ===
                            `${
                              Number(selectedDepartureHour.split(':')[0]) +
                                Number(index) +
                                1 ===
                              9
                                ? '0'
                                : ''
                            }${
                              Number(selectedDepartureHour.split(':')[0]) +
                              Number(index) +
                              1
                            }:00`
                              ? styles.selected
                              : ''
                          }`}
                        >
                          {`${
                            Number(selectedDepartureHour.split(':')[0]) +
                              Number(index) +
                              1 ===
                            9
                              ? '0'
                              : ''
                          }${
                            Number(selectedDepartureHour.split(':')[0]) +
                            Number(index) +
                            1
                          }:00`}
                        </span>
                      )
                    )}
                </span>
              </span>
              <span className="d-flex w-100">
                <span className="d-flex justify-content-between w-100">
                  {Array(
                    23 - (Number(selectedDepartureHour.split(':')[0]) || 0)
                  )
                    .fill(0)
                    ?.map(
                      (
                        option: any,
                        index: {
                          toString: () => React.Key | null | undefined
                        }
                      ) => (
                        <span
                          role="presentation"
                          onClick={() =>
                            selectArriveHour(
                              `${
                                Number(selectedDepartureHour.split(':')[0]) +
                                  Number(index) +
                                  1 ===
                                9
                                  ? '0'
                                  : ''
                              }${
                                Number(selectedDepartureHour.split(':')[0]) +
                                Number(index) +
                                1
                              }:30`
                            )
                          }
                          key={uuid()}
                          className={` ${styles.hour} ${
                            selectedArriveHour ===
                            `${
                              Number(selectedDepartureHour.split(':')[0]) +
                                Number(index) +
                                1 ===
                              9
                                ? '0'
                                : ''
                            }${
                              Number(selectedDepartureHour.split(':')[0]) +
                              Number(index) +
                              1
                            }:30`
                              ? styles.selected
                              : ''
                          }`}
                        >
                          {`${
                            Number(selectedDepartureHour.split(':')[0]) +
                              Number(index) +
                              1 ===
                            9
                              ? '0'
                              : ''
                          }${
                            Number(selectedDepartureHour.split(':')[0]) +
                            Number(index) +
                            1
                          }:30`}
                        </span>
                      )
                    )}
                </span>
              </span>
            </span>
          </TabPanel>
        </Box>
        <div
          className={`justify-content-center d-flex 
           ${value === 3 ? styles.selectArrive : styles.selectDeparture}`}
        >
          <Button
            variant="outlined"
            onClick={() => cancel()}
            color="inherit"
            className={`${styles.submit} me-4`}
            size="large"
          >
            Cancelar
          </Button>

          <Button
            disabled={
              selectedReservation.departure === '' ||
              selectedReservation.arrives === ''
            }
            variant="contained"
            color="primary"
            size="large"
            className={`${styles.submit}`}
            onClick={() => handleConfirmation()}
          >
            Confirmar
          </Button>
        </div>      
        </ThemeProvider> 
            </Modal.Body>
        </Modal>
    )
}

export default SelectReservationFilter;
