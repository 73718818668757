import env from "react-dotenv";
import { ServiceCommon } from "./service.common";

class MpgService extends ServiceCommon {
    
    API_MPG = env.API_MPG;

    constructor() {
        super('MpgService');
    }

    async boletAssignor(companyId: number) {
        const url = `${this.API_MPG}/bolet/assignor/register/${companyId}`;

        try {
            return await this.postReturnObject(url, []);
        } catch (error: any) {
            throw new Error(error);
        }
    } // boletAssignor

    async boletModernize(companyId: number) {
        const url = `${this.API_MPG}/bolet/assignor/modernize/${companyId}`;

        try {
            return await this.putReturnObject(url, []);
        } catch (error: any) {
            throw new Error(error);
        }
    } // boletModernize

    async boletAccount(companyId: number) {
        const url = `${this.API_MPG}/bolet/assignor/account/${companyId}`;

        try {
            return await this.postReturnObject(url, []);
        } catch (error: any) {
            throw new Error(error);
        }
    } // boletAccount

    async boletAgreement(companyId: number) {
        const url = `${this.API_MPG}/bolet/assignor/agreement/${companyId}`;

        try {
            return await this.postReturnObject(url, []);
        } catch (error: any) {
            throw new Error(error);
        }
    } // boletAgreement

    async boletTicket(companyId: number, billet: any) {
        const url = `${this.API_MPG}/bolet/ticket/${companyId}`;

        try {
            return await this.postReturnObject(url, billet);
        } catch (error: any) {
            throw new Error(error);
        }
    } // boletTicket

    async boletSearch(companyId: number, integration: string) {
        const url = `${this.API_MPG}/bolet/ticket/${companyId}/search/${integration}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // boletSearch

    async boletDiscard(companyId: number, integration: string) {
        const url = `${this.API_MPG}/bolet/ticket/${companyId}/discard/${integration}`;

        try {
            return await this.deleteReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // boletDiscard
    
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new MpgService();