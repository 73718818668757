import { useEffect } from "react";

import { Toast, ToastContainer } from 'react-bootstrap';

import { cnpjMask, phoneMask } from '../../utilities/masks';
import { Tooltip as TooltipMaterial } from '@mui/material';
import { usePartnerContext } from "../../contexts/partner.context";

export interface IFinancialHeaderProps {
    title: any,
    description: any,

    itemsToast?: any,
    setItemsToast?: any
}

export const FinancialHeader: React.FC<IFinancialHeaderProps> = (props: IFinancialHeaderProps) => {

    const { partner, showDefinedPartner } = usePartnerContext();

    let reloadPage = false;
    let timer: any;

    function onClose_Toast(item: any, idx: number) {
        timer = setInterval(() => {
            removeToast();
        }, 3000);
    }

    function removeToast() {
        if (props.itemsToast.length > 0) {
            const items = [] as any;

            props.itemsToast.shift();
            props.itemsToast.forEach((item: any) => {
                items[items.length] = item;
            });
            props.setItemsToast(items);
        } else {
            clearInterval(timer);
        }
    }

    useEffect(() => {
        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            reloadPage = !reloadPage;
        }
    }, []); // useEffect

    return (
        <div className="page-header">
            <div className='title'>
                <h4 className="page-title">{props.title}</h4>
                <div className="page-reserved ">{props.description}</div>
            </div>
            <TooltipMaterial title="Empresas do grupo">
                <div className="page-corporation isClickable" onClick={() => showDefinedPartner(true) } >
                    {partner && partner.company && <div>
                        <div><strong>{partner.company.fantasy}</strong>{' - '}{cnpjMask(partner.company.registrationPj)}{' - '}{phoneMask(partner.company.telephone)}</div>
                    </div>}  
                </div>
            </TooltipMaterial>
            <ToastContainer>
                {props.itemsToast && props.itemsToast.map((item: any, idx: number) => (
                    <Toast 
                        key={idx} 
                        onClose={(e) => onClose_Toast(item, idx)} 
                        show={item.show}
                        delay={3000} 
                        autohide
                        bg={'success'}    
                    >
                        <Toast.Header>
                        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                        <strong className="me-auto">Nautisystem</strong>
                        <small className="text-muted"></small>
                        </Toast.Header>
                        <Toast.Body>{item.message}</Toast.Body>
                    </Toast>
                ))}
            </ToastContainer>
        </div>
        
    )
}

export default FinancialHeader;
