import { useEffect, useState } from "react";
import { Form, Modal, Spinner } from "react-bootstrap";
import { FaRegIdCard,  FaTimes } from "react-icons/fa";
import { useAlertMessageContext } from "../../contexts/alert-message.context";
import { useConfirmMessageContext } from "../../contexts/confirm-message.context";
import lclService from "../../services/lcl.service";
import { notNullToDate } from "../../utilities/auxiliary-functions";
import { cpfMask, numberMask, phoneMask, removeFormatDate, removeMask, zipcodeMask } from "../../utilities/masks";
import { isCpf, isEmpty } from "../../utilities/validators";
import VesselConductorRecord from "../records/vessel-conductor.record";
import PeopleFilter from "../filters/people.filter";
import { Button } from '@mui/material'
import { AlertMessageEnum } from "../../utilities/types";
import { openDocument } from "../../utilities/view-document";
import cduService from "../../services/cdu.service";
import { useTranslateContext } from "../../contexts/translate.context";

export interface IVesselConductorCadastreModalProps {
    isEdit: boolean,
    show: boolean,
    vessel: any,
    conductorId: string,
    record: any;
    onSubmitModal: any,
    onCancelModal: any
}

export const VesselConductorCadastreModal: React.FC<IVesselConductorCadastreModalProps> = (props: IVesselConductorCadastreModalProps) => {

    const alertContext = useAlertMessageContext();
    const confirmContext = useConfirmMessageContext();
    const { display } = useTranslateContext();

    const title : string = display.title.conductor_cadastre;

    const [ invalidatedCadastreForm, setInvalidatedCadastreForm ] = useState(false);

    const [ sending, setSending ] = useState(false);
    const [ showFilterPeople, setShowFilterPeople ] = useState(false);

    // Conductor
    const [ id, setId ] = useState(0);
    const [ created, setCreated ] = useState('');
    const [ updated, setUpdated ] = useState('');

    // PersonalData
    const [ peopleId, setPeopleId ] = useState(0); 
    const [ tratament, setTratament ] = useState(''); 
    const [ fullname, setFullname ] = useState(''); 
    const [ gender, setGender ] = useState('');
    const [ birth, setBirth ] = useState('');
    // Phone
    const [ phoneId, setPhoneId ] = useState(0);
    const [ norm, setNorm ] = useState('');
    const [ operator, setOperator ] = useState('');
    const [ connection, setConnection ] = useState('');
    const [ sms, setSms ] = useState(0);
    const [ zap, setZap ] = useState(0);
    const [ major, setMajor ] = useState(0);
    const [ nationality, setNationality ] = useState('');
    const [ naturalness, setNaturalness ] = useState('');
    // PersonalDocument
    const [ registrationPf, setRegistrationPf ] = useState('');
    const [ registrationIdentity, setRegistrationIdentity ] = useState('');
    const [ issuerIdentity, setIssuerIdentity ] = useState('');
    const [ stateIdentity, setStateIdentity ] = useState('');
    const [ expedition, setExpedition ] = useState('');
    const [ driversLicense, setDriversLicense ] = useState('');
    const [ categoryDriversLicense, setCategoryDriversLicense ] = useState('');
    const [ validityDriversLicense, setValidityDriversLicense ] = useState('');
    const [ document, setDocument ] = useState('');
    // Maritime License
    const [ documentNauticalId, setDocumentNauticalId ] = useState(0);
    const [ categoryNaval, setCategoryNaval ] = useState('');
    const [ enrollmentNaval, setEnrollmentNaval ] = useState('');
    const [ validityNaval, setValidityNaval ] = useState('');
    const [ expeditionNaval, setExpeditionNaval ] = useState('');
    const [ limitsNaval, setLimitsNaval ] = useState('');
    const [ locationNaval, setLocationNaval ] = useState('');
    const [ commentsNaval, setCommentsNaval ] = useState('');
    // Address
    const [ addressId, setAddressId ] = useState(0);
    const [ zipcode, setZipcode ] = useState('');
    const [ state, setState ] = useState('');
    const [ city, setCity ] = useState('');
    const [ district, setDistrict ] = useState('');
    const [ place, setPlace ] = useState('');
    const [ habitation, setHabitation ] = useState('');
    const [ complement, setComplement ] = useState('');

    type ArtifactType = { id : number, norm : string, source : string, identy : string, document : string, filename : string };
    const DEFAULT_ARTIFACT: ArtifactType = { id : 0, norm : '', source : '', identy : '', document : '', filename : '' };
    
    const [ driversLicenseDocument, setDriversLicenseDocument ] = useState(DEFAULT_ARTIFACT);
    const [ proofOfResidenceDocument, setProofOfResidenceDocument ] = useState(DEFAULT_ARTIFACT);
    const [ isChangedProofOfResidenceDocument, setChangedProofOfResidenceDocument ] = useState(false);

    async function isValidForm() {
        let result = true;
        let emptyRequired = false;

        if (isEmpty(fullname))
            emptyRequired = true;

        if (isEmpty(registrationPf))
            emptyRequired = true;
        if ((!isEmpty(registrationPf)) && (!isCpf(registrationPf))) {
            await alertContext.show(AlertMessageEnum.FAIL, title, display.message.invalid.registration_pf);
            result = false;
        }

        if (isEmpty(categoryNaval))
            emptyRequired = true;
        if (isEmpty(enrollmentNaval))
            emptyRequired = true;
        if (isEmpty(validityNaval))
            emptyRequired = true;
        if (isEmpty(locationNaval))
            emptyRequired = true;
        if (isEmpty(expeditionNaval))
            emptyRequired = true;
            
        if (emptyRequired) {
            await alertContext.show(AlertMessageEnum.FAIL, title, display.message.invalid.required);
            result = false;
        }

        setInvalidatedCadastreForm(!result);
        return result;
    }

    function onClick_FilterPeople(event: any) {
        event.preventDefault();

        setShowFilterPeople(true);
    }

    async function onClick_ConfirmFilterPeople(event: any, record: any) {
        event.preventDefault();

        const attributes = [] as any;
        const where = { peopleId : record.id };
        const order = [] as any;
        const conductors = await lclService.vesselConductorFilter(attributes, where, order);
        if (conductors.length > 0) {
            const conductor = conductors[0];
            fillRecord(conductor);
        } else {
            fillRecordPeople(record);
        }
    }

    async function onClick_Confirm(event : any) {
        event.preventDefault();

        const isValid = await isValidForm();
        if (isValid) {
            try {
                const isConfirmed = await confirmContext.show(title, display.message.confirm_record);
                if (isConfirmed) {
                    setSending(true);

                    const conductor = fillConductor();
                    if (conductor.vesselId > 0) {
                        let returned = null;
                        if (conductor.id > 0)
                            returned = await lclService.saveVesselConductor(conductor.id, conductor);
                        else
                            returned = await lclService.createVesselConductor(conductor);

                        conductor.id = returned.id;

                        if (isChangedProofOfResidenceDocument && proofOfResidenceDocument) {
                            proofOfResidenceDocument.source = 'PEOPLE';
                            proofOfResidenceDocument.identy = String(conductor.peopleId);
        
                            if ((proofOfResidenceDocument.id > 0) && !isEmpty(proofOfResidenceDocument.document)) {
                                await cduService.saveArtifact(proofOfResidenceDocument.id, proofOfResidenceDocument);
                            } else if ((proofOfResidenceDocument.id > 0) && isEmpty(proofOfResidenceDocument.document)) {
                                await cduService.artifactDeleteById(proofOfResidenceDocument.id);
                            } else if ((proofOfResidenceDocument.id === 0) && !isEmpty(proofOfResidenceDocument.document)) {
                                await cduService.createArtifact(proofOfResidenceDocument);
                            }   
                        }
                        await alertContext.show(AlertMessageEnum.SUCCESS, title, display.message.the_record_has_been_saved_successfully);
                    }
                    props.onSubmitModal(event, conductor);
                    setSending(false);
                }
            } catch (error: any) {
                setSending(false);
                await alertContext.show(AlertMessageEnum.FAIL, title, error);
            }
        }
    }

    async function onClick_Cancel(event : any) {
        event.preventDefault();

        setInvalidatedCadastreForm(false);
        props.onCancelModal(event);
    }

    function clearConductor() {
        // Conductor
        setId(0);
        setCreated('');
        setUpdated('');
        // PersonalData
        setPeopleId(0); 
        setTratament(''); 
        setFullname(''); 
        setGender('');
        setBirth('');
        // Phone
        setPhoneId(0);
        setNorm('');
        setOperator('');
        setConnection('');
        setSms(0);
        setZap(0);
        setMajor(0);
        setNationality('');
        setNaturalness('');
        // PersonalDocument
        setRegistrationPf('');
        setRegistrationIdentity('');
        setIssuerIdentity('');
        setStateIdentity('');
        setExpedition('');
        setDriversLicense('');
        setCategoryDriversLicense('');
        setValidityDriversLicense('');
        setDocument('');
        // Maritime License
        setCategoryNaval('');
        setEnrollmentNaval('');
        setValidityNaval('');
        setLimitsNaval('');
        setCommentsNaval('');
        setLocationNaval('');
        setExpeditionNaval('');
        // Address
        setAddressId(0);
        setZipcode('');
        setState('');
        setCity('');
        setDistrict('');
        setPlace('');
        setHabitation('');
        setComplement('');
    }

    function fillRecordPeople(people: any) {

        if (people != null) {

            setPeopleId(people.id);
            setTratament(people.tratament);
            setFullname(people.fullname);
            setGender(people.gender);
            setBirth(notNullToDate(people.birth, 'dd/mm/yyyy'));
            if (!isEmpty(people.phones)) {
                const phone = people.phones[0];

                setPhoneId(phone.id);
                setNorm(phone.norm);
                setOperator(phone.operator);
                setConnection(phoneMask(phone.connection));
                setSms(phone.sms);
                setZap(phone.zap);
                setMajor(phone.major);
            }

            if (!isEmpty(people.documentsNautical)) {
                const documentNautical = people.documentsNautical[0];

                setDocumentNauticalId(documentNautical.id);
                setCategoryNaval(documentNautical.category);
                setEnrollmentNaval(documentNautical.enrollment);
                setValidityNaval(notNullToDate(documentNautical.validity, 'dd/mm/yyyy'));
                setLimitsNaval(documentNautical.limits);
                setCommentsNaval(documentNautical.comments);
                setLocationNaval(documentNautical.location);
                setExpeditionNaval(notNullToDate(documentNautical.expedition, 'dd/mm/yyyy'));
                setDocument(documentNautical.document);
            }

            setNationality(people.nationality);
            setNaturalness(people.naturalness);

            setRegistrationPf(cpfMask(people.registrationPf));
            setRegistrationIdentity(numberMask(people.registrationIdentity));
            setIssuerIdentity(people.issuerIdentity);
            setStateIdentity(people.stateIdentity);
            setExpedition(notNullToDate(people.expedition, 'dd/mm/yyyy'));
            setDriversLicense(numberMask(people.driversLicense));
            setCategoryDriversLicense(people.categoryDriversLicense);
            setValidityDriversLicense(notNullToDate(people.validityDriversLicense, 'dd/mm/yyyy'));

            if (!isEmpty(people.adresses)) {
                const addressPeople = people.adresses[0];

                setAddressId(addressPeople.addressId);
                setZipcode(zipcodeMask(addressPeople.address.zipcode));
                setState(addressPeople.address.state);
                setCity(addressPeople.address.city);
                setDistrict(addressPeople.address.district);
                setPlace(addressPeople.address.place);

                setHabitation(addressPeople.habitation);
                setComplement(addressPeople.complement);
            }
        
        }
    } // fillRecordPeople

    function fillRecord(conductor: any) {

        if (conductor != null) {
            setId(conductor.id);
            setCreated(conductor.created);
            setUpdated(conductor.updated);
            setPeopleId(conductor.peopleId);
            fillRecordPeople(conductor.people);
        }
    } // fillRecord

    function fillConductor() {

        const address = [];
        address[0] = {
            peopleId : peopleId,
            addressId : addressId,
            address : {
                id : addressId,
                zipcode : removeMask(zipcode),
                state : state,
                city : city,
                district : district,
                place : place
            },
            correspondence : 1,
            norm : 'residential',
            habitation,
            complement
        };

        const phones = [];
        phones[0] = {
            id : phoneId,
            norm, 
            operator,
            connection : removeMask(connection),
            sms,
            zap,
            major
        };

        const documentsNautical = [];
        documentsNautical[0] = {
            id : documentNauticalId,
            category : categoryNaval,
            enrollment : enrollmentNaval,
            validity : removeFormatDate(validityNaval),
            limits : limitsNaval,
            comments : commentsNaval,
            location : locationNaval,
            expedition : removeFormatDate(expeditionNaval),
            document
        };

        const dataConductor = {
            id,
            created, 
            updated,
            vesselId : props.vessel.id,
            peopleId,
            people : {
                id : peopleId,
                tratament,
                fullname, 
                gender, 
                birth : removeFormatDate(birth), 
                phones, 
                documentsNautical,
                nationality, 
                naturalness, 
                registrationPf : removeMask(registrationPf),
                registrationIdentity : removeMask(registrationIdentity),
                issuerIdentity,
                stateIdentity,
                expedition : removeFormatDate(expedition),
                driversLicense,
                categoryDriversLicense,
                validityDriversLicense : removeFormatDate(validityDriversLicense),
                address
            }
        };

        return dataConductor;
    }

    async function initialize() {
        if (props.isEdit) {
            try {
                const conductor = await lclService.vesselConductorById(Number(props.conductorId));
                fillRecord(conductor);
                
                cduService.artifactByNorm('PEOPLE', conductor.peopleId, 'FILE_DRIVER').then((artifact: any) => {
                    if (artifact) {
                        artifact.filename = `Atualizado em ${notNullToDate(artifact.updated, 'dd/mm/yyyy HH:MM:ss')}`
                        setDriversLicenseDocument(artifact);
                    }
                });
                cduService.artifactByNorm('PEOPLE', conductor.peopleId, 'FILE_RESIDENCE').then((artifact: any) => {
                    if (artifact) {
                        artifact.filename = `Atualizado em ${notNullToDate(artifact.updated, 'dd/mm/yyyy HH:MM:ss')}`
                        setProofOfResidenceDocument(artifact);
                    }
                });
            } catch(error: any) {
                await alertContext.show(AlertMessageEnum.FAIL, title, error);
            }
        } else {
            clearConductor();
        }
    }

    useEffect(() => {
        if (props.show)
            initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show]);

    function changeDocument(event: any) {
        if (event.target.files && event.target.files.length > 0) {
            let reader = new FileReader();
            reader.onload = (e: any) => {
                setDocument(e.target.result);
            };
            reader.readAsDataURL(event.target.files[0]);
        } else {
            setDocument('');
        }
    }

    function changeDriversLicenseDocument(event: any) {
        if (event.target.files && event.target.files.length > 0) {
            let reader = new FileReader();
            reader.onload = (e: any) => {
                let artfact: ArtifactType = {
                    id : driversLicenseDocument.id,
                    norm : 'FILE_DRIVER',
                    source : 'PEOPLE',
                    identy : String(peopleId),
                    document : e.target.result,
                    filename : event.target.files[0].name
                }
                setDriversLicenseDocument(artfact);
            };
            reader.readAsDataURL(event.target.files[0]);
        } else {
            let artifact: ArtifactType = { id : driversLicenseDocument.id, norm : 'FILE_DRIVER', source : 'PEOPLE', identy : String(peopleId), document : '', filename : '' };
            setDriversLicenseDocument(artifact);
        }
    }

    function changeProofOfResidenceDocument(event: any) {
        setChangedProofOfResidenceDocument(true);
        if (event.target.files && event.target.files.length > 0) {
            let reader = new FileReader();
            reader.onload = (e: any) => {
                let artfact: ArtifactType = {
                    id : proofOfResidenceDocument.id,
                    norm : 'FILE_RESIDENCE',
                    source : 'PEOPLE',
                    identy : String(peopleId),
                    document : e.target.result,
                    filename : event.target.files[0].name
                }
                setProofOfResidenceDocument(artfact);
            };
            reader.readAsDataURL(event.target.files[0]);
        } else {
            let artifact: ArtifactType = { id : proofOfResidenceDocument.id, norm : 'FILE_RESIDENCE', source : 'PEOPLE', identy : String(peopleId), document : '', filename : '' };
            setProofOfResidenceDocument(artifact);
        }
    }

    function clickViewDocument(event: any, title: string, url: any) {
        event.preventDefault();
        openDocument(title, url);
    }

    function viewCadastreForm() {
        return (
            <>
                <Modal show={props.show && !showFilterPeople}
                    size="lg"
                    aria-labelledby="modal-cadastre-conductor-form"
                    centered
                >
                    <Modal.Header>
                        <Modal.Title id="modal-cadastre-conductor-form"><FaRegIdCard />{title}</Modal.Title>
                        <FaTimes className="isClickable" onClick={ onClick_Cancel } size={26}/>
                    </Modal.Header>
                    <Modal.Body>
                        <Form className="form" id="form-cadastre-conductor" validated={invalidatedCadastreForm}>
                            <VesselConductorRecord
                                title={title}
                                isEdit={props.isEdit}

                                created={created}
                                updated={updated}
                                tratament={tratament}
                                fullname={fullname}
                                gender={gender}
                                birth={birth}
                                norm={norm}
                                operator={operator}
                                connection={connection}
                                sms={sms}
                                zap={zap}
                                major={major}
                                nationality={nationality}
                                naturalness={naturalness}
                                registrationPf={registrationPf}
                                registrationIdentity={registrationIdentity}
                                issuerIdentity={issuerIdentity}
                                stateIdentity={stateIdentity}
                                expeditionIdentity={expedition}
                                driversLicense={driversLicense}
                                categoryDriversLicense={categoryDriversLicense}
                                validityDriversLicense={validityDriversLicense}
                                document={document}
                                categoryNaval={categoryNaval}
                                enrollmentNaval={enrollmentNaval}
                                validityNaval={validityNaval}
                                limitsNaval={limitsNaval}
                                commentsNaval={commentsNaval}
                                locationNaval={locationNaval}
                                expeditionNaval={expeditionNaval}
                                zipcode={zipcode}
                                state={state}
                                city={city}
                                district={district}
                                place={place}
                                habitation={habitation}
                                complement={complement}
                                setCreated={(value: any) => { setCreated(value); }}
                                setUpdated={(value: any) => { setUpdated(value); }}
                                setTratament={(value: any) => { setTratament(value); }}
                                setFullname={(value: any) => { setFullname(value); }}
                                setGender={(value: any) => { setGender(value); }}
                                setBirth={(value: any) => { setBirth(value); }}
                                setNorm={(value: any) => { setNorm(value); }}
                                setOperator={(value: any) => { setOperator(value); }}
                                setConnection={(value: any) => { setConnection(value); }}
                                setSms={(value: any) => { setSms(value); }}
                                setZap={(value: any) => { setZap(value); }}
                                setMajor={(value: any) => { setMajor(value); }}
                                setNationality={(value: any) => { setNationality(value); }}
                                setNaturalness={(value: any) => { setNaturalness(value); }}
                                setRegistrationPf={(value: any) => { setRegistrationPf(value); }}  
                                setRegistrationIdentity={(value: any) => { setRegistrationIdentity(value); }}  
                                setIssuerIdentity={(value: any) => { setIssuerIdentity(value); }}  
                                setStateIdentity={(value: any) => { setStateIdentity(value); }}  
                                setExpeditionIdentity={(value: any) => { setExpedition(value); }}  
                                setDriversLicense={(value: any) => { setDriversLicense(value); }}  
                                setCategoryDriversLicense={(value: any) => { setCategoryDriversLicense(value); }} 
                                setValidityDriversLicense={(value: any) => { setValidityDriversLicense(value); }} 
                                setCategoryNaval={(value: any) => { setCategoryNaval(value); }} 
                                setEnrollmentNaval={(value: any) => { setEnrollmentNaval(value); }} 
                                setValidityNaval={(value: any) => { setValidityNaval(value); }} 
                                setLimitsNaval={(value: any) => { setLimitsNaval(value); }} 
                                setCommentsNaval={(value: any) => { setCommentsNaval(value); }} 
                                setLocationNaval={(value: any) => { setLocationNaval(value); }} 
                                setExpeditionNaval={(value: any) => { setExpeditionNaval(value); }} 
                                setZipcode={(value: any) => { setZipcode(value); }}
                                setState={(value: any) => { setState(value); }}
                                setCity={(value: any) => { setCity(value); }}
                                setDistrict={(value: any) => { setDistrict(value); }}
                                setPlace={(value: any) => { setPlace(value); }}
                                setHabitation={(value: any) => { setHabitation(value); }}
                                setComplement={(value: any) => { setComplement(value); }}
                                
                                changeDocument={(event: any) => { changeDocument(event); }}
                                showFilterPeopleModal={(e: any) => onClick_FilterPeople(e)}

                                driversLicenseDocument={driversLicenseDocument}
                                changeDriversLicenseDocument={changeDriversLicenseDocument}
                                proofOfResidenceDocument={proofOfResidenceDocument}
                                changeProofOfResidenceDocument={changeProofOfResidenceDocument}
                                clickViewDocument={clickViewDocument}
                            />
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={ onClick_Cancel } variant="contained" color="secondary">{display.buttom.cancel}</Button>
                        <Button onClick={ onClick_Confirm } variant="contained" color="primary" disabled={sending}>
                            { sending ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null }{'  '}
                            { display.buttom.save }
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

    return (
        <>
            { viewCadastreForm() }
            <PeopleFilter 
                show={showFilterPeople}

                onClick_Close={(event: any) => { 
                    setShowFilterPeople(false);
                }}
                onClick_Confirm={(event: any, value: any) => { 
                    setShowFilterPeople(false);
                    onClick_ConfirmFilterPeople(event, value); 
                }}
            />
        </>
    )
}

export default VesselConductorCadastreModal
