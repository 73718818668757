import { Button } from '@mui/material';
import { useEffect, useMemo, useState } from "react";
import { ButtonGroup, Form, Modal, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { FaRegIdCard, FaTimes, FaTrash } from "react-icons/fa";
import { useAlertMessageContext } from "../../contexts/alert-message.context";
import { useConfirmMessageContext } from "../../contexts/confirm-message.context";
import lclService from "../../services/lcl.service";
import srvService from "../../services/srv.service";
import { dateAdd, dateToString, floatToString, maxString, toFloat, toStringFloat } from "../../utilities/auxiliary-functions";
import { BudgetSituationEnum, CATEGORY_PRODUCT, PaymentConditionsEnum, PaymentEnum } from "../../utilities/constants";
import { floatMask, removeFormatDate, removeMask } from "../../utilities/masks";
import { AlertMessageEnum, getUnit } from "../../utilities/types";
import { isEmpty } from "../../utilities/validators";
import BudgetRecord from "../records/budget.record";
import VesselFilter from "../filters/vessel.filter";
import BudgetItemCadastreModal from "./budget-item.cadastre.modal";
import { useTranslateContext } from '../../contexts/translate.context';
import { usePartnerContext } from '../../contexts/partner.context';

export interface IBudgetCadastreModalProps {
    isEdit: boolean,
    show: boolean,
    budgetId: string,
    record: any;
    records: any;
    onSubmitModal: any,
    onCancelModal: any
}

export const BudgetCadastreModal: React.FC<IBudgetCadastreModalProps> = (props: IBudgetCadastreModalProps) => {
    
    const alertContext = useAlertMessageContext();
    const confirmContext = useConfirmMessageContext();
    const { display } = useTranslateContext();
    const { partner } = usePartnerContext();

    const title : string = display.title.budget_cadastre;

    const [ invalidatedCadastreForm, setInvalidatedCadastreForm ] = useState(false);

    // const [ cadastreFormShow, setCadastreFormShow ] = useState(true);
    const [ sending, setSending ] = useState(false);
    const sendingRevoke = false;
    const sendingMaintain = false
    const sendingDeliver = false

    const stay = 2
    const [ showFilterVessel, setShowFilterVessel ] = useState(false);
    const [ showFilterStockItem, setShowFilterStockItem ] = useState(false);
    const stockItemInList: any = []

    // Budget
    const [ budgetId, setBudgetId ] = useState(0);
    const [ created, setCreated ] = useState(dateToString(new Date(), 'dd/mm/yyyy HH:MM:ss'));
    const [ updated, setUpdated ] = useState(dateToString(new Date(), 'dd/mm/yyyy HH:MM:ss'));

    const [ searchVessel, setSearchVessel ] = useState('');

    const [ code, setCode ] = useState('0');
    const [ prohibited, setProhibited ] = useState(dateToString(new Date(), 'dd/mm/yyyy HH:MM:ss'));
    const [ departure, setDeparture ] = useState(dateToString(dateAdd('H', new Date(), stay), 'dd/mm/yyyy HH:MM:ss'));
    const [ vessel, setVessel ] = useState({} as any);
    const [ comments, setComments ] = useState('');
    const [ mechanicId, setMechanicId ] = useState(0);
    const [ mechanic, setMechanic ] = useState({} as any);
    const [ situation, setSituation ] = useState(0);
    const [ paymentForm, setPaymentForm ] = useState('');
    const [ paymentConditions, setPaymentConditions ] = useState('');
    const [ productCost, setProductCost ] = useState('');
    const [ officeCost, setOfficeCost ] = useState('');
    const [ discountCost, setDiscountCost ] = useState('');
    const [ orderCost, setOrderCost ] = useState('');

    const [ listMechanic, setListMechanic ] = useState([] as any);

    const [ budgetItems, setBudgetItems ] = useState([] as any);
    const columnsBudgetItems = useMemo(() => [{
        Header: display.label.reference,
        accessor: "stock.product.reference"
    }, {
        Header: display.label.code,
        accessor: "stock.product.code",
    },{
        Header: display.label.category,
        accessor: "stock.product.category",
        Cell: (row: any) => (<div className="white-space">{!isEmpty(row.value) ? row.value.group +' \\ '+ (isEmpty(row.value.subgroup) ? row.value.name : row.value.subgroup +' \\ '+ row.value.name) : ''}</div>)
    },{
        Header: display.label.description,
        accessor: "stock.product.description",
        Cell: (row: any) => (<div className="white-space">{maxString(row.value, 18)}</div>)
    },{
        Header: display.label.unit,
        accessor: "stock.product.unit",
        Cell: (row: any) => (<div className="white-space">{getUnit(row.value).name}</div>)
    },{
        Header: display.label.quantity,
        accessor: "quantity",
    },{
        Header: display.label.value,
        accessor: "stock.sale",
        Cell: (row: any) => (<div className="white-space">{floatMask(row.value)}</div>)
    },{
        Header: display.label.amount,
        accessor: "amount",
        Cell: (row: any) => (<div className="white-space">{floatMask(row.value)}</div>)
    },{
        Header: display.legend.actions,
        accessor: "actions",
        Cell: (props: any) => {
            let budgetItemRows = [] as any;
            const originals = props.rows;
            for (var o = 0; o < originals.length; o++) {
                budgetItemRows[budgetItemRows.length] = originals[o].original;
            }
            const budgetItemRow = props.row.original;

            return (
                <div className="white-space table-actions">
                    <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.delete}</Tooltip>}>
                        <span onClick={(e) => onClick_DeleteRecord(e, budgetItemRows, budgetItemRow)}>
                            <FaTrash size={18} />
                        </span>
                    </OverlayTrigger>
                </div>
            );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }], []); // columnsRecord

    async function onClick_DeleteRecord(event: any, budgetItems: any, budgetItem: any) {
        event.preventDefault();

        if (budgetItem.id > 0) {
            const isConfirmed = await confirmContext.show(title, display.message.delete_record);
            if (isConfirmed) {
                try {
                    await srvService.budgetItemDeleteById(budgetItem.id);
                } catch(error: any) {
                    await alertContext.show(AlertMessageEnum.FAIL, title, error);
                }
            }
        }

        const localBudgetItems = [] as any;
        for (var bi = 0; bi < budgetItems.length; bi++) {
            const objectItem = budgetItems[bi];
            if (objectItem.stock.productId !== budgetItem.stock.productId) {
                localBudgetItems[localBudgetItems.length] = objectItem;
            }
        }

        setBudgetItems(localBudgetItems);
        calculated(localBudgetItems);
    } // onClick_DeleteRecord

    async function isValidForm() {
        let result = true;
        let emptyRequired = false;

        if (emptyRequired) {
            await alertContext.show(AlertMessageEnum.FAIL, title, display.message.invalid.required);
            result = false;
        }

        setInvalidatedCadastreForm(!result);
        return result;
    }

    function onClick_Revoke(event: any) {
        onClick_Save(event, BudgetSituationEnum.CANCELED);
    }

    function onClick_Maintain(event: any) {
        onClick_Save(event, BudgetSituationEnum.MAINTENANCE);
    }

    function onClick_Deliver(event: any) {
        onClick_Save(event, BudgetSituationEnum.DELIVERED);
    }

    async function onClick_Save(event: any, state: number) {
        event.preventDefault();

        if (state !== -1) {
            const isValid = await isValidForm();
            if (isValid) {
                try {
                    const isConfirmed = await confirmContext.show(title, display.message.confirm_record);
                    if (isConfirmed) {
                        setSending(true);

                        const budget = fillBudget();
                        budget.situation = state;

                        if (props.isEdit)
                            await srvService.saveBudget(budget.id, budget);
                        else
                            await srvService.createBudget(budget);

                        setSending(false);
                        props.onSubmitModal(event, budget);
                    }
                } catch (error: any) {
                    setSending(false);
                    await alertContext.show(AlertMessageEnum.FAIL, title, error);
                }
            }
        }
    }

    async function onClick_Cancel(event : any) {
        event.preventDefault();

        //setCadastreFormShow(false); 
        setInvalidatedCadastreForm(false);
        props.onCancelModal(event);
    }

    async function clearBudget() {
        // Budget
        setBudgetId(0);
    
        setCreated('');
        setUpdated('');

        const budgetNewCode = await srvService.budgetNewCode();
        setCode(budgetNewCode);

        setProhibited(dateToString(new Date(), 'dd/mm/yyyy HH:MM:ss'));
        setDeparture(dateToString(dateAdd('H', new Date(), 2), 'dd/mm/yyyy HH:MM:ss'));
        setVessel({} as any);
        setSearchVessel('');
        setComments('');
        setBudgetItems([] as any);
        setMechanicId(0);
        setMechanic({} as any);
        setSituation(BudgetSituationEnum.ACTIVED);
        setPaymentForm(PaymentEnum.CASH);
        setPaymentConditions(PaymentConditionsEnum.IN_CASH);
        setProductCost(floatToString(0));
        setOfficeCost(floatToString(0));
        setDiscountCost(floatToString(0));
        setOrderCost(floatToString(0));
    }

    function fillRecord(budget: any) {

        if (budget != null) {
            setBudgetId(budget.id);

            setCode(budget.code);

            setProhibited(dateToString(budget.prohibited, 'dd/mm/yyyy HH:MM:ss'));
            setDeparture(dateToString(budget.departure, 'dd/mm/yyyy HH:MM:ss'));
            setVessel(budget.vessel);
            if (budget.vessel)
                setSearchVessel(budget.vessel.enrollment);
                
            setComments(budget.comments);

            const localBudgetItems = [];
            for (var i = 0; i < budget.items.length; i++) {
                const item = budget.items[i];
                localBudgetItems[i] = {
                    id : item.id,
                    budgetId : item.budgetId,
                    stockId : item.stock.id,
                    stock : item.stock,
                    quantity : item.quantity,
                    amount : floatToString(item.amount),
                    created : dateToString(item.created, 'dd/mm/yyyy HH:MM:ss'),
                    updated : dateToString(item.updated, 'dd/mm/yyyy HH:MM:ss')
                }
            }
    
            setBudgetItems(localBudgetItems);
            setMechanicId(budget.mechanicId);
            setMechanic(budget.mechanic);
            setSituation(budget.situation);
            setPaymentForm(budget.paymentForm);
            setPaymentConditions(budget.paymentConditions);
            setProductCost(floatToString(budget.productCost));
            setOfficeCost(floatToString(budget.officeCost));
            setDiscountCost(floatToString(budget.discountCost));
            setOrderCost(floatToString(budget.orderCost));
            setCreated(dateToString(budget.created, 'dd/mm/yyyy HH:MM:ss'));
            setUpdated(dateToString(budget.updated, 'dd/mm/yyyy HH:MM:ss'));
        }
    } // fillRecord

    function fillBudget() {

        const dataBudgetItems = [] as any;

        for (var i = 0; i < budgetItems.length; i++) {
            const budgetItem = budgetItems[i];
            dataBudgetItems[i] = {
                id : budgetItem.id || 0,
                budgetId : budgetId,
                stockId : budgetItem.stock.id,
                stock : budgetItem.stock,
                quantity : budgetItem.quantity,
                amount : removeMask(budgetItem.amount),
                created : removeFormatDate(budgetItem.created),
                updated : removeFormatDate(budgetItem.updated)
            }
        }

        const dataBudget = {
            id: budgetId,
            code,
            partnerId : partner.id,
            prohibited : removeFormatDate(prohibited),
            departure : removeFormatDate(departure),
            vesselId : vessel.id,
            comments,
            mechanicId,
            mechanic,
            situation,
            paymentForm,
            paymentConditions,
            productCost : removeMask(productCost),
            officeCost : removeMask(officeCost),
            discountCost : removeMask(discountCost),
            orderCost : removeMask(orderCost),
        
            items: dataBudgetItems,

            created : removeFormatDate(created),
            updated : removeFormatDate(updated)
        };
        return dataBudget;
    }

    async function initialize() {
        clearBudget();

        const listMechanics = await srvService.mechanicList(partner.id);
        setListMechanic(listMechanics.rows);
        if (props.isEdit) {
            try {
                const budget = await srvService.budgetById(Number(props.budgetId));
                if (budget.vesselId) {
                    const vessel = await lclService.vesselById(budget.vesselId);
                    budget.vessel = vessel;
                }
                if (budget.mechanicId) {
                    const mechanic = await srvService.mechanicById(budget.mechanicId);
                    budget.mechanic = mechanic;
                }
                fillRecord(budget);
            } catch(error: any) {
                await alertContext.show(AlertMessageEnum.FAIL, title, error);
            }
        }
    }

    useEffect(() => {
        if (props.show)
            initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show]);

    /* FUNCION EXCLUVISE OF FORM - END */

    async function filterVessel(event: any) {
        if (!isEmpty(searchVessel)) {
            try {
                const vessel = await lclService.findByEnrollment(searchVessel)
                setVessel(vessel);
            } catch (error: any) {
                await alertContext.show(AlertMessageEnum.FAIL, title, error);
            }
        } else {
            await alertContext.show(AlertMessageEnum.FAIL, title, 'Informe a matricula ou pesquise a embarcação !!!');
        }
    }

    async function onClick_ConfirmFilterStockItem(event: any, stockItem: any) {
        event.preventDefault();
        setBudgetItems([...budgetItems, stockItem]);
        calculated(budgetItems, stockItem);
    }

    function calculated(budgetItems?: any, stockItem?: any) {
        let sumSaleProduct = 0;
        let sumSaleOffice = 0;
        
        if (budgetItems) {
            budgetItems.forEach((budgetItem: any) => {
                if (Number(budgetItem.stock.product.category.team) === CATEGORY_PRODUCT)
                    sumSaleProduct += (toFloat(toStringFloat(budgetItem.stock.sale)) * Number(budgetItem.quantity));
                else
                    sumSaleOffice += (toFloat(toStringFloat(budgetItem.stock.sale)) * Number(budgetItem.quantity));
            });
        }

        if (stockItem) {
            if (Number(stockItem.stock.product.category.team) === CATEGORY_PRODUCT)
                sumSaleProduct += (toFloat(toStringFloat(stockItem.stock.sale)) * Number(stockItem.quantity));
            else
                sumSaleOffice += (toFloat(toStringFloat(stockItem.stock.sale)) * Number(stockItem.quantity));
        }

        setProductCost(floatToString(sumSaleProduct));
        setOfficeCost(floatToString(sumSaleOffice));

        const sumOrder = sumSaleProduct + sumSaleOffice;
        setOrderCost(floatToString(sumOrder));
    }

    function calculatedOrder(event: any) {
        event.preventDefault();

        const saleProduct = toFloat(productCost);
        const saleOffice = toFloat(officeCost);
        const saleDiscount = toFloat(discountCost);
        const sum = (saleProduct + saleOffice);
        let sumOrder = ((sum * saleDiscount) / 100) - sum;
        setOrderCost(floatToString(sumOrder));
    }

    function filterStock(event: any) {
        event.preventDefault();

        setShowFilterStockItem(true);
    }

    function onClick_FilterVessel(event: any) {
        event.preventDefault();
        setShowFilterVessel(true);
    }

    async function onClick_ConfirmFilterVessel(event: any, record: any) {
        event.preventDefault();

        try {
            const vessel = await lclService.vesselById(record.id);

            setVessel(vessel);
            setSearchVessel(vessel.enrollment);
        } catch (error: any) {
            await alertContext.show(AlertMessageEnum.FAIL, title, error);
        }
    }

    function canRevoke(): boolean {
        if (situation === BudgetSituationEnum.CANCELED)
            return true;
        if (situation === BudgetSituationEnum.DELIVERED) 
            return true;


        return !props.isEdit;
    }

    function canMaintain(): boolean {
        if (situation === BudgetSituationEnum.MAINTENANCE)
            return true;
        if (situation === BudgetSituationEnum.CANCELED)
            return true;
        if (situation === BudgetSituationEnum.DELIVERED) 
            return true;

        return !props.isEdit;
    }

    function canDeliver(): boolean {
        if (situation === BudgetSituationEnum.DELIVERED) 
            return true;
        if (situation === BudgetSituationEnum.ACTIVED)
            return true;
        if (situation === BudgetSituationEnum.CANCELED)
            return true;

        return !props.isEdit;
    }

    function canSave(): boolean {
        if (situation === BudgetSituationEnum.DELIVERED) 
            return true;
        if (situation === BudgetSituationEnum.CANCELED)
            return true;
        return false;
    }

    function viewCadastreForm() {
        return (
            <>
                <Modal show={props.show && !showFilterStockItem &&!showFilterVessel}
                    size="lg"
                    aria-labelledby="modal-cadastre-budget-form"
                    centered
                >
                    <Modal.Header>
                        <Modal.Title id="modal-cadastre-budget-form"><FaRegIdCard />{title}</Modal.Title>
                        <FaTimes className="isClickable" onClick={ onClick_Cancel } size={26}/>
                    </Modal.Header>
                    <Modal.Body style={{minHeight : 500}}>
                        <Form className="form" id="form-cadastre-budget" validated={invalidatedCadastreForm}>
                            <BudgetRecord
                                title={title}
                                isEditMode={props.isEdit}

                                budgetItems={budgetItems}
                                columnsBudgetItems={columnsBudgetItems}
                                
                                code={code}
                                prohibited={prohibited}
                                departure={departure}
                                vessel={vessel}
                                comments={comments}
                                mechanicId={mechanicId}
                                mechanic={mechanic}
                                situation={situation}
                                paymentForm={paymentForm}
                                paymentConditions={paymentConditions}
                                productCost={productCost}
                                officeCost={officeCost}
                                discountCost={discountCost}
                                orderCost={orderCost}
                            
                                created={created}
                                updated={updated}

                                setCode={(value: any) => { setCode(value); }}
                                setProhibited={(value: any) => { setProhibited(value); }}
                                setDeparture={(value: any) => { setDeparture(value); }}
                                setVessel={(value: any) => { setVessel(value); }}
                                setComments={(value: any) => { setComments(value); }}
                                setMechanicId={(value: any) => { setMechanicId(value); }}
                                setMechanic={(value: any) => { setMechanic(value); }}
                                setSituation={(value: any) => { setSituation(value); }}
                                setPaymentForm={(value: any) => { setPaymentForm(value); }}
                                setPaymentConditions={(value: any) => { setPaymentConditions(value); }}
                                setProductCost={(value: any) => { setProductCost(value); }}
                                setOfficeCost={(value: any) => { setOfficeCost(value); }}
                                setDiscountCost={(value: any) => { setDiscountCost(value); }}
                                setOrderCost={(value: any) => { setOrderCost(value); }}
                            
                                setCreated={(value: any) => { setCreated(value); }}
                                setUpdated={(value: any) => { setUpdated(value); }}

                                listMechanic={listMechanic}

                                searchVessel={searchVessel}
                                setSearchVessel={(value: any) => { setSearchVessel(value); }}
                                filterVessel={(value: any) => { filterVessel(value); }}

                                filterStock={(value: any) => { filterStock(value); }}
                                calculatedOrder={(value: any) => { calculatedOrder(value); }}
                                showFilterVesselModal={(value: any) => { onClick_FilterVessel(value); }}
                            />
                        </Form>
                        <ButtonGroup>
                            <Button onClick={ onClick_Maintain } className="ms-2 me-2" variant="contained" color="success" disabled={canMaintain() || sendingMaintain}>
                                { sending ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null }{'  '}
                                { display.buttom.maintain }
                            </Button>
                            <Button onClick={ onClick_Revoke } variant="contained" color="error" disabled={canRevoke() || sendingRevoke}>
                                { sending ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null }{'  '}
                                { display.buttom.revoke }
                            </Button>
                        </ButtonGroup>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={ onClick_Cancel } variant="contained" color="secondary">{display.buttom.cancel}</Button>
                        {/*<Button onClick={ onClick_Print } variant="info">{display.buttom.print}</Button>*/}
                        <Button onClick={ onClick_Deliver } variant="contained" color="success" disabled={canDeliver() || sendingDeliver}>
                            { sending ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null }{'  '}
                            { display.buttom.deliver }
                        </Button>
                        <Button variant="contained" color="primary" onClick={(e) => onClick_Save(e, BudgetSituationEnum.ACTIVED)} disabled={canSave() || sending}>
                            { sending ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null }{'  '}
                            { display.buttom.save }
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

    return (
        <>
            { viewCadastreForm() }
            <VesselFilter 
                show={showFilterVessel}

                onClick_Close={(event: any) => { 
                    setShowFilterVessel(false);
                }}
                onClick_Confirm={(event: any, value: any) => { 
                    setShowFilterVessel(false);
                    onClick_ConfirmFilterVessel(event, value); 
                }}
            />
            <BudgetItemCadastreModal
                stockItemNotInList={stockItemInList}
                show={showFilterStockItem}

                onClick_Close={(event: any) => { 
                    setShowFilterStockItem(false);
                }}
                onClick_Confirm={(event: any, value: any) => { 
                    setShowFilterStockItem(false);
                    onClick_ConfirmFilterStockItem(event, value); 
                }}
            />
        </>
    )
}

export default BudgetCadastreModal
