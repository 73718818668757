import { PropsWithChildren, createContext, useCallback, useContext, useState } from "react";
import { useTheme } from "@emotion/react";

interface IMediaContext {
    isPortrait: boolean;
    isTv: boolean;
    isMonitor: boolean;
    isTablet: boolean;
    isSmart: boolean;
    setPortrait: (newOrientation: boolean) => void,
    changeMedia: () => void;
}

const MediaContext = createContext({} as IMediaContext);

export const useMediaContext = () => {
    return useContext(MediaContext);
};

export const MediaProvider:React.FC<PropsWithChildren> = ({ children }) => {

    const [ isPortrait, setIsPortrait ] = useState(false);
    const [ isTv, setIsTv ] = useState(false);
    const [ isMonitor, setIsMonitor ] = useState(false);
    const [ isTablet, setIsTablet ] = useState(false);
    const [ isSmart, setIsSmart ] = useState(false);

    const handleChangeMedia = () => {
        //console.group('ChangeMedia');
        let doc = document;
        let docElem = doc.documentElement;
        let body = doc.getElementsByTagName('body')[0];

        let width = window.innerWidth || docElem.clientWidth || body.clientWidth;
        let height = window.innerHeight|| docElem.clientHeight|| body.clientHeight;
        const portrait = width < height;
        // console.log('xs: 0; sm: 600; md: 900; lg: 1200; xl: 1536');
        // console.log(`Width: ${width}`);
        // console.log(`Height: ${height}`);
        // console.log(`Portrait: ${portrait}`);

        let xs: number = 0;
        let sm: number = 600;
        let md: number = 900;
        let lg: number = 1200;
        let xl: number = 1536;

        const tv = (!portrait ? width : height) >= xl;
        // console.log(`Tv: ${tv}`);
        const monitor = (!portrait ? width : height) >= lg && (!portrait ? width : height) < xl;
        // console.log(`Monitor: ${monitor}`);
        const tablet = (!portrait ? width : height) >= md && (!portrait ? width : height) < lg;
        // console.log(`Tablet: ${tablet}`);
        const smart = (!portrait ? width : height) < md;
        // console.log(`Smart: ${smart}`);

        setIsPortrait(portrait);        
        setIsTv(tv);
        setIsMonitor(monitor);
        setIsTablet(tablet);
        setIsSmart(smart);
        // console.groupEnd();
    }

    const handleSetMedia = useCallback((newOrientation: boolean) => {
        setIsPortrait(newOrientation);
    }, []);

    return (
        <MediaContext.Provider value={{ 
            isPortrait, 
            isTv,
            isMonitor,
            isTablet,
            isSmart,
            setPortrait: handleSetMedia, 
            changeMedia: handleChangeMedia }}>
            { children }
        </MediaContext.Provider>
    );

}