import { useEffect, useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { Bar, Doughnut } from 'react-chartjs-2'
import { useTranslateContext } from '../../contexts/translate.context'
import { usePartnerContext } from '../../contexts/partner.context'

import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import OperationHeader from '../headers/operation.header'
import styles from './ServiceCenterDashboard.module.scss'
import Lancha from '../../assets/Lancha.svg'
import Jet from '../../assets/Jetski.svg'
import { faker } from '@faker-js/faker'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { ptBR } from 'date-fns/locale'
import Loader from '../../components/loader/loader'
import authService from '../../services/auth.service'
import lclService from '../../services/lcl.service'
import { floatMask, removeFormatDate } from '../../utilities/masks'
import { dateToString, toInt, toLowerCase } from '../../utilities/auxiliary-functions'
import { VESSEL_JETSKI } from '../../utilities/constants'

interface IServiceCenterDashboardProps {
    
}

export const ServiceCenterDashboard: React.FC<IServiceCenterDashboardProps> = () => {
  
	const { partner } = usePartnerContext();
	const { display } = useTranslateContext();

    const [ loading, setLoading ] = useState(false)

    const [ dateFilter, setDateFilter ] = useState(new Date());

    const [ budgetedQty, setBudgetedQty ] = useState('');
    const [ approvedQty, setApprovedQty ] = useState('');
    const [ guaranteeQty, setGuaranteeQty ] = useState('');
    const [ budgetedTotal, setBudgetedTotal ] = useState('');
    const [ approvedTotal, setApprovedTotal ] = useState('');
    const [ guaranteeTotal, setGuaranteeTotal ] = useState('');
    const [ vessels, setVessels ] = useState([]);
    const [ budgets, setBudgets ] = useState([]);
    const [ payments, setPayments ] = useState([]);
    const [ grossRevenues, setGrossRevenues ] = useState([]);

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        ChartDataLabels,
        Title,
        Tooltip,
        Legend,
        ArcElement
    )

    const dataType = {
        labels: vessels.map((vessel: any) => toInt(vessel.typeVessel) === VESSEL_JETSKI ? 'Jet-Ski' : 'Lancha'),
        datasets: [
        {
            data: vessels.map((vessel: any) => {
            var some = 0;
            vessels.forEach((v: any) => {
                some += v.qty
            });

            return some > 0 ? Math.round((vessel.qty / some) * 100) : 0
            }),
            backgroundColor: ['#00007A', '#91A9AF'],
            borderWidth: 0
        }
        ]
    }

    const dataTypeBudget = {
        labels: budgets.filter((budget: any) => budget.description !== 'MAINTENANCE')
        .map((budget: any) => {
            switch (budget.description) {
            case 'ACTIVED':
                return 'Orçado'            
            case 'CANCELED':
                return 'Cancelado'
            default:
                return 'Aprovado'
            } 
        }), // ['Orçado', 'Aprovado', 'Cancelado', 'Garantia'],
        datasets: [
        {
            data: budgets.map((budget: any) => {
            var some = 0;
            budgets.forEach((v: any) => {
                some += v.qty
            });

            return some > 0 ? Math.round((budget.qty / some) * 100) : 0
            }),
            backgroundColor: ['#4264E0', '#6CC94C', '#911116', '#6783E6'],
            borderWidth: 0
        }
        ]
    }

    const dataPayment = {
        labels: payments.map((payment: any) => display.label[toLowerCase(payment.form)]), // ['Pix', 'Dinheiro', 'Cartão', 'Boleto'],
        datasets: [
        {
            data: payments.map((payment: any) => payment.total),
            backgroundColor: ['#16BE94', '#E5C710', '#6783E6', '#FAA55C'],
            borderWidth: 0
        }
        ]
    }

    const typeOptions = {
        responsive: true,
        cutout: 120,
        gridLines: {
        display: false
        },
        plugins: {
        legend: {
            position: 'bottom' as const,
            labels: {
            color: '#FFFFFF',
            font: {
                size: 12,
                family: "'Montserrat', sans-serif"
            }
            }
        },
        datalabels: {
            color: 'white',
            font: {
            size: 12,
            family: "'Montserrat', sans-serif"
            },
            formatter(value: any) {
            return `${value}%`
            }
        }
        }
    }

    const paymentOptions = {
        ...typeOptions,
        plugins: {
        ...typeOptions.plugins,
        datalabels: {
            color: 'white',
            font: {
            size: 12,
            family: "'Montserrat', sans-serif"
            },
            formatter(value: any) {
            return `R$ ${floatMask(value)}`
            }
        }
        }
    }

    const budgetOptions = {
        responsive: true,
        gridLines: {
        display: false
        },
        plugins: {
        legend: {
            position: 'bottom' as const,
            labels: {
            display: false,
            color: '#FFFFFF',
            font: {
                size: 12,
                family: "'Montserrat', sans-serif"
            }
            }
        },
        datalabels: {
            color: 'white',
            font: {
            size: 12,
            family: "'Montserrat', sans-serif"
            },
            formatter(value: any) {
            return `R$ ${floatMask(value)}`
            }
        }
        },
        maintainAspectRatio: true,
        scales: {
        x: {
            ticks: {
            display: false,
            color: 'white',
            font: {
                size: 12,
                family: "'Montserrat', sans-serif"
            }
            },
            grid: {
            display: true,
            tickColor: 'white',
            drawOnChartArea: false,
            color: 'white'
            },
            border: {
            color: 'white'
            }
        },
        y: {
            ticks: {
            color: 'white',
            font: {
                size: 12,
                family: "'Montserrat', sans-serif"
            }
            },
            grid: {
            display: true,
            tickColor: 'white',
            drawOnChartArea: false,
            color: 'white'
            },
            border: {
            color: 'white'
            }
        }
        }
    }

    const profitOptions = {
        ...budgetOptions,
        plugins: {
        ...budgetOptions.plugins,
        datalabels: {
            color: 'white',
            font: {
            size: 12,
            family: "'Montserrat', sans-serif"
            },
            formatter(value: any) {
            return `R$ ${floatMask(value)}`
            }
        }
        }
    }

    const budgetsLabels = [
        'Legenda'
    ]

    const budgetData = {
        labels: budgetsLabels,
        datasets: [
        {
            label: 'Orçado',
            data: budgets.filter((budget: any) => budget.description === 'ACTIVED' ).map((budget: any) => budget.total || 0),
            backgroundColor: '#4264E0',
        },
        {
            label: 'Aprovado',
            data: budgets.filter((budget: any) => budget.description === 'DELIVERED' ).map((budget: any) => budget.total || 0),
            backgroundColor: '#6CC94C'
        },
        {
            label: 'Cancelado',
            data: budgets.filter((budget: any) => budget.description === 'CANCELED' ).map((budget: any) => budget.total || 0),
            backgroundColor: '#911116'
        }
        ]
    }

    const profitLabels = [
        'Legenda'
    ]

    const profitData = {
        labels: profitLabels,
        datasets: [
        {
            label: 'Peças',
            data: grossRevenues.map((grossRevenue: any) => grossRevenue.productTotal),
            backgroundColor: '#5B6E95',
            barPercentage: 0.4,
        },
        {
            label: 'Serviços',
            data: grossRevenues.map((grossRevenue: any) => grossRevenue.officeTotal),
            backgroundColor: '#819F8C',
            barPercentage: 0.4,
        },
        ]
    }

    async function handleClickRefresh(dateSearch: Date | null) {
        if (dateSearch === null)
        dateSearch = new Date();

        setDateFilter(dateSearch)
        const view = await lclService.serviceCenter(partner.id, removeFormatDate(dateToString(dateSearch, 'dd/mm/yyyy')), 7);

        setBudgetedQty(view.budgetedQty);
        setApprovedQty(view.approvedQty);
        setGuaranteeQty(view.guaranteeQty);
        setBudgetedTotal(view.budgetedTotal);
        setApprovedTotal(view.approvedTotal);
        setGuaranteeTotal(view.guaranteeTotal);
        setVessels(view.vessels);
        setBudgets(view.budgets);
        setPayments(view.payments);
        setGrossRevenues(view.grossRevenues);

        setLoading(false)
        }

    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
        handleClickRefresh(new Date())
        setLoading(false)
        }, 1000)
    }, [])
    
    function viewPage() {
        return (
            <Container fluid className={`page-body movements ${styles.serviceCenter}`}>
            {loading ? (
                <Loader />
              ) : (
                <>
              <Row>
                <Col md={12} className='mb-4 d-flex justify-content-between'>
                  <div className='me-5'></div>
                  <div className='ms-5 d-flex'>
                    <div className='d-flex flex-column align-items-center' style={{width: 280}}>
                      <h5>Total Orçado</h5>
                      <h2 className='bolder'>{budgetedQty}</h2>
                      <h3 className='lighter'>R$ {floatMask(budgetedTotal)}</h3>
                    </div>
                    <div className='d-flex flex-column align-items-center' style={{width: 280}}>
                      <h5>Total Aprovado</h5>
                      <h2 className='bolder'>{approvedQty}</h2>
                      <h3 className='lighter'>R$ {floatMask(approvedTotal)}</h3>
                    </div>
                    <div className='d-flex flex-column align-items-center' style={{width: 280}}>
                      <h5>Garantia</h5>
                      <h2 className='bolder'>{guaranteeQty}</h2>
                      <h3 className='lighter'>R$ {floatMask(guaranteeTotal)}</h3>
                    </div>
                  </div>
                  <LocalizationProvider
                    adapterLocale={ptBR}
                    dateAdapter={AdapterDateFns}
                  >
                    <DesktopDatePicker
                      label="Buscar período"
                      sx={{ width: 160, fontSize: 42 }}
                      slotProps={{
                        textField: {
                          variant: 'standard',
                          helperText: 'Exibindo 7 dias',
                        }
                      }}
                      value={dateFilter} 
                      onChange={(date) => handleClickRefresh(date)}
                      className="mt-2 me-3"
                      minDate={new Date()}
                    />
                  </LocalizationProvider>
                </Col>
              </Row>
              <Row>
                <Col md={3} className="page-sweet">
                  <Card >
                    <Card.Header>
                        <div className="card-actions float-right d-flex justify-content-end">
                            <div className="card-actions-time" />
                        </div>
                        <Card.Title>Embarcações</Card.Title>
                    </Card.Header>
                        <Card.Body>
                        <Doughnut data={dataType} options={typeOptions} className="mt-3" />
                        <div className="d-flex mt-1 ms-5 justify-content-start w-100">
                        {vessels.map((vessel: any, index: number) => (
                          <span key={`vessel-${index}`} className="d-flex ms-4 me-4 align-items-center">
                            <img src={toInt(vessel.typeVessel) === VESSEL_JETSKI ? Jet : Lancha} 
                              alt={toInt(vessel.typeVessel) === VESSEL_JETSKI ? 'Jet' : 'Lancha'} 
                              className="jet me-2" style={{ height: 44, width: 44 }} />
                            <span className="bolder mt-1">{vessel.qty}</span>
                          </span>
                        ))}
                        </div>
                        </Card.Body>
                  </Card>
                </Col>
                <Col md={6} className='page-sweet'>
                <Card >
                    <Card.Header>
                        <div className="card-actions float-right d-flex justify-content-end">
                            <div className="card-actions-time" />
                        </div>
                        <Card.Title>Orçamentos (R$)</Card.Title>
                    </Card.Header>
                        <Card.Body>
                    <Bar
                      className="mt-5"
                      options={budgetOptions}
                      data={budgetData}
                      height={148}
                    />
                        </Card.Body>
                  </Card>
                </Col>
                <Col md={3} className="page-sweet">
                  <Card>
                    <Card.Header>
                        <div className="card-actions float-right d-flex justify-content-end">
                            <div className="card-actions-time" />
                        </div>
                        <Card.Title>Orçamentos (%)</Card.Title>
                    </Card.Header>
                        <Card.Body>
                        <Doughnut data={dataTypeBudget} options={typeOptions} height={500} className="mt-3" />
                        <div className="me-5 ms-2 d-flex mt-1 justify-content-center w-100" style={{height: 44}}></div>
                        </Card.Body>
                  </Card>
                </Col>
                
              </Row>
              <Row className='mt-0'>
                <Col md={3} className="page-sweet">
                <Card>
                    <Card.Header>
                        <div className="card-actions float-right d-flex justify-content-end">
                            <div className="card-actions-time" />
                        </div>
                        <Card.Title>Formas de Pagamento</Card.Title>
                    </Card.Header>
                        <Card.Body>
                          <Doughnut data={dataPayment} options={paymentOptions} className="mt-3" />
                          <div className="me-5 ms-2 d-flex mt-1 justify-content-center w-100" style={{height: 58}}></div>
                        </Card.Body>
                  </Card>
                </Col>
                <Col md={9} className='page-sweet'>
                <Card>
                    <Card.Header>
                        <div className="card-actions float-right d-flex justify-content-end">
                            <div className="card-actions-time" />
                        </div>
                        <Card.Title>Receita Bruta</Card.Title>
                    </Card.Header>
                        <Card.Body>
                          <Bar
                            className="mt-5"
                            options={profitOptions}
                            data={profitData}
                            height={100}
                          />
                        </Card.Body>
                  </Card>
                </Col>
              </Row>
                </>
              )}
            </Container>
        )
    }

    return (
        <div className="page">
            <OperationHeader title="Gestão Oficina" description="" />
            {viewPage()}
        </div>
    )
}

export default ServiceCenterDashboard
