import { useEffect, useState } from 'react'

import { faker } from '@faker-js/faker'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { ptBR } from 'date-fns/locale'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { Bar, Doughnut } from 'react-chartjs-2'
import { useNavigate } from 'react-router-dom'
import Loader from '../../components/loader/loader'
import OperationHeader from '../headers/operation.header'
import styles from './StockDashboard.module.scss'
import { FormControl, FormHelperText, Input, InputAdornment, InputLabel } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { dateToString, toInt } from '../../utilities/auxiliary-functions'
import { removeFormatDate } from '../../utilities/masks'
import authService from '../../services/auth.service'
import lclService from '../../services/lcl.service'
import { MaterialType } from '../../utilities/types'

interface IStockDashboardProps {
    
}

export const StockDashboard: React.FC<IStockDashboardProps> = () => {

    const [ loading, setLoading ] = useState(false);
    const [ productFilter, setProductFilter ] = useState('');
    const [ dateFilter, setDateFilter ] = useState(new Date());

    const [ viewReport, setViewReport ] = useState({} as any);
    const [ totalProducts, setTotalProducts ] = useState(0);
    
    const navigate = useNavigate()

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        ChartDataLabels,
        Title,
        Tooltip,
        Legend,
        ArcElement
    )

    const barOptions = {
        responsive: true,
        gridLines: {
        display: false
        },
        plugins: {
        legend: {
            position: 'bottom' as const,
            labels: {
            color: '#FFFFFF',
            font: {
                size: 12,
                family: "'Montserrat', sans-serif"
            }
            }
        },
        datalabels: {
            color: 'white',
            font: {
            size: 12,
            family: "'Montserrat', sans-serif"
            }
        }
        },
        maintainAspectRatio: true,
        scales: {
        x: {
            ticks: {
            color: 'white',
            font: {
                size: 12,
                family: "'Montserrat', sans-serif"
            }
            },
            grid: {
            display: true,
            tickColor: 'white',
            drawOnChartArea: false,
            color: 'white'
            },
            border: {
            color: 'white'
            }
        },
        y: {
            ticks: {
            color: 'white',
            font: {
                size: 12,
                family: "'Montserrat', sans-serif"
            }
            },
            grid: {
            display: true,
            tickColor: 'white',
            drawOnChartArea: false,
            color: 'white'
            },
            border: {
            color: 'white'
            }
        }
        }
    }

    const horizontalBarOptions = { 
        ...barOptions,
        indexAxis: 'y' as const,
        plugins: {
        legend: {
            ...barOptions.plugins.legend,
            position: 'left' as const,
        },
        },
        scales: {
        ...barOptions.scales,
        y: {
            ...barOptions.scales.y,
            ticks: {
            ...barOptions.scales.y.ticks,
            display: false,
            }
        }
        }
    }

    const monthlyLabels = [
        'Jan',
        'Fev',
        'Mar',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez'
    ]

    const registeredLabels = [
        'Legenda'
    ]
    const monthlyData = {
        labels: monthlyLabels,
        datasets: [
        {
            label: 'Estoque Atual',
            data: monthlyLabels.map(() =>
            faker.number.int({ min: 0, max: 60 })
            ),
            backgroundColor: '#87B972',
            datalabels: {
            display: false
            }

        },
        {
            label: 'Entrada',
            data: monthlyLabels.map(() =>
            faker.number.int({ min: 0, max: 60 })
            ),
            backgroundColor: '#3232D6',
            datalabels: {
            display: false
            }
        },
        {
            label: 'Saída',
            data: monthlyLabels.map(() =>
            faker.number.int({ min: 0, max: 60 })
            ),
            backgroundColor: '#CF4147',
            datalabels: {
            display: false
            }
        }
        ]
    }
    const registeredData = {
        labels: registeredLabels,
        datasets: [
        {
            label: 'Bar e Restaurante',
            data: registeredLabels.map(() => {
            if (viewReport.categories) {
                const category = viewReport.categories.filter((category: any) => category.material === 5)[0];
                let total = 0;
                if (category)
                total = toInt(category.total_product);
                return total;
            } else 
                return 0;
            }),
            backgroundColor: 'gray',
            barPercentage: 0.7,
            datalabels: {
            display: false
            },
        },
        {
            label: 'Oficina',
            data: registeredLabels.map(() => {
            if (viewReport.categories) {
                const category = viewReport.categories.filter((category: any) => category.material === 4)[0];
                let total = 0;
                if (category)
                total = toInt(category.total_product);
                return total;
            } else 
                return 0;
            }),
            backgroundColor: '#6783E6',
            datalabels: {
            display: false
            },
            barPercentage: 0.7,

        },
        {
            label: 'Operação',
            data: registeredLabels.map(() => {
            if (viewReport.categories) {
                const category = viewReport.categories.filter((category: any) => category.material === 3)[0];
                let total = 0;
                if (category)
                total = toInt(category.total_product);
                return total;
            } else 
                return 0;
            }),
            backgroundColor: '#87B972',
            datalabels: {
            display: false
            },
            barPercentage: 0.7,
        },
        {
            label: 'Uso Consumo',
            data: registeredLabels.map(() => {
            if (viewReport.categories) {
                const category = viewReport.categories.filter((category: any) => category.material === 2)[0];
                let total = 0;
                if (category)
                total = toInt(category.total_product);
                return total;
            } else 
                return 0;
            }),
            backgroundColor: 'purple',
            datalabels: {
            display: false
            },
            barPercentage: 0.7,
        },
        {
            label: 'Administrativo',
            data: registeredLabels.map(() => {
            if (viewReport.categories) {
                const category = viewReport.categories.filter((category: any) => category.material === 1)[0];
                let total = 0;
                if (category)
                total = toInt(category.total_product);
                return total;
            } else 
                return 0;
            }),
            backgroundColor: '#E5C710',
            datalabels: {
            display: false
            },
            barPercentage: 0.7,
        }
        ]
    }

    const dataType = {
        labels: MaterialType.map((material: any) => material.name),
        datasets: [
        {
            data: MaterialType.map((material: any) => {
            if (viewReport.categories) {
                const category = viewReport.categories.filter((category: any) => category.material === material.id)[0];
                let total = 0;
                if (category)
                total = toInt(category.total_product);
                return totalProducts > 0 ? Math.round((total / totalProducts) * 100) : 0;
            } else 
                return 0;
            }),
            backgroundColor: [ '#E5C710', 'purple', '#16BE94', '#6783E6', 'gray'],
            borderWidth: 0
        }
        ]
    }

    const typeOptions = {
        responsive: true,
        cutout: 120,
        gridLines: {
        display: false
        },
        plugins: {
        legend: {
            display: false,
            labels: {
            color: '#FFFFFF',
            font: {
                size: 14,
                family: "'Montserrat', sans-serif"
            }
            }
        },
        datalabels: {
            color: 'white',
            font: {
            size: 14,
            family: "'Montserrat', sans-serif"
            },
            formatter(value: any) {
            return `${value}%`
            }
        }
        }
    }

    function onClick_Search(event: any) {
        event.preventDefault();
        handleClickRefresh(productFilter, dateFilter);
    }

    async function handleClickRefresh(productySearch: string, dateSearch: Date | null) {
        if (dateSearch === null)
        dateSearch = new Date();

        setProductFilter(productySearch)
        setDateFilter(dateSearch)
        const partner = authService.currentPartner();
        const view = await lclService.stock(partner.id, productySearch, removeFormatDate(dateToString(dateSearch, 'dd/mm/yyyy')));
        var some = 0;
        view.categories.forEach((v: any) => {
            some += toInt(v.total_product)
        });
        setTotalProducts(some);
        setViewReport(view);
    }

    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
        handleClickRefresh('', new Date())
        setLoading(false)
        }, 2500)
    }, [])

    function viewPage() {
        return (
            <Container fluid className={`page-body movements`}>
            {loading ? (
        <Loader />
      ) : (
        <div className={styles.StockDashboard}>
          <Row>
            <Col md={12} className='mb-4 d-flex justify-content-between'>
            <FormControl className='ms-3' fullWidth sx={{width: 350 }} variant="standard">
              <InputLabel htmlFor="standard-adornment-amount">Pesquisar</InputLabel>
              <Input id="standard-adornment-amount" value={productFilter} onChange={(e)=> handleClickRefresh(e.target.value, dateFilter)}
                endAdornment = {
                  <InputAdornment position="end">
                    <SearchIcon className='isClickable' onClick={(e)=> onClick_Search(e)} />
                  </InputAdornment>
                }
              />
              <FormHelperText id="component-helper-text">
                Código ou nome do Produto
              </FormHelperText>
            </FormControl>
            <LocalizationProvider adapterLocale={ptBR} dateAdapter={AdapterDateFns}>
              <DesktopDatePicker label="Data" sx={{ width: 130, fontSize: 42 }} slotProps={{
                    textField: {
                      variant: 'standard',
                    }
                  }}
                  value={dateFilter} 
                  onChange={(date) => handleClickRefresh(productFilter, date)}
                className="mt-2 me-3"
                minDate={new Date()}
                />
            </LocalizationProvider>
            </Col>
          </Row>
          {/*
          <Row>
            <Col md={12} className='page-sweet'>
            <Card>
              <Card.Header>
                <div className="card-actions float-right d-flex justify-content-end">
                  <div className="card-actions-time" />
                </div>
                <Card.Title>Mensal</Card.Title>
              </Card.Header>
              <Card.Body>
                <Bar className="mt-5" options={barOptions} data={monthlyData} height={80} />
              </Card.Body>
            </Card>

            </Col>
          </Row>
          */}
          <Row>
            <Col md={9} className="page-sweet">
            <Card>
              <Card.Header>
                <div className="card-actions float-right d-flex justify-content-end">
                  <div className="card-actions-time" />
                </div>
                <Card.Title>Cadastrados</Card.Title>
              </Card.Header>
              <Card.Body>
                <Bar className="mt-4" options={horizontalBarOptions} data={registeredData} height={90} />
              </Card.Body>
            </Card>
            </Col>
            <Col md={3} className="page-sweet">
            <Card>
              <Card.Header>
                <div className="card-actions float-right d-flex justify-content-end">
                  <div className="card-actions-time" />
                </div>
                <Card.Title>Total Cadastrado: {totalProducts}</Card.Title>
              </Card.Header>
              <Card.Body>
                <Doughnut data={dataType} options={typeOptions} className="mt-3" />
              </Card.Body>
            </Card>
            </Col>
          </Row>

        </div>
      )}
            </Container>
        )
    }

    return (
        <div className="page">
            <OperationHeader title="Gestão de Estoque" description="" />
            {viewPage()}
        </div>
    )
}

export default StockDashboard
