import { useState } from "react";
import { Col, Form, FormControl, FormGroup, InputGroup, Row } from "react-bootstrap";
import { FaKey } from "react-icons/fa";
import { GoShield } from "react-icons/go";

import { toString } from "../../utilities/auxiliary-functions";

export interface IAccessDataRecordProps {
    title: any,
    isEdit: boolean,

    token: any,
    password: any,
    checkPassword: any,
    setToken: any,
    setPassword: any,
    setCheckPassword: any
}

export const AccessDataRecord: React.FC<IAccessDataRecordProps> = (props: IAccessDataRecordProps) => {
    
    const [ isValidPass, setIsValidPass ] = useState(true);
    const [ isValidCheck, setIsValidCheck ] = useState(true);

    const [ passRuleMinChar, setPassRuleMinChar ] = useState(true);
    const [ passRuleMaxChar, setPassRuleMaxChar ] = useState(true);
    const [ passRuleMinCharMais, setPassRuleMinCharMais ] = useState(true);
    const [ passRuleMinCharMinus, setPassRuleMinCharMinus ] = useState(true);
    const [ passRuleMinDigit, setPassRuleMinDigit ] = useState(true);
    const [ passRuleMinCharEspetial, setPassRuleMinCharEspetial ] = useState(true);

    function onChange_Password(password: string): string {
        props.setPassword(password);
        props.setCheckPassword('');
        setIsValidCheck(false);
        setIsValidPass(false);

        setPassRuleMinChar(true);
        setPassRuleMaxChar(true);
        setPassRuleMinCharMais(true);
        setPassRuleMinCharMinus(true);
        setPassRuleMinDigit(true);
        setPassRuleMinCharEspetial(true);
        if (password.length < 8) {
            setPassRuleMinChar(false);
        }
        if (password.length > 10) {
            setPassRuleMaxChar(false);
        }
        
        if (password.search(/(?=.*[A-Z])/) < 0) {
            setPassRuleMinCharMais(false);
        }
        if (password.search(/(?=.*[a-z])/) < 0) {
            setPassRuleMinCharMinus(false);
        }
        if (password.search(/([0-9])/) < 0) {
            setPassRuleMinDigit(false);
        }
        if (password.search(/(?=.*[!@#$%&? "])/) < 0) {
            setPassRuleMinCharEspetial(false);
        }

        return password;
    }

    function onBlur_Password(password: string): string {
        setIsValidPass(passRuleMinChar && passRuleMaxChar && passRuleMinCharMais && passRuleMinCharMinus && passRuleMinDigit && passRuleMinCharEspetial);

        return password;
    }

    function onChange_CheckPassword(checkPassword: string): string {
        if (props.password === checkPassword)
            setIsValidCheck(true)
        else
            setIsValidCheck(false)
            props.setCheckPassword(checkPassword);

        return checkPassword;
    }
    
    let classNamePassRuleMinChar = (!passRuleMinChar ? ' rule-invalid' : '');
    let classNamePassRuleMaxChar = (!passRuleMaxChar ? ' rule-invalid' : '');
    let classNamePassRuleMinCharMais = (!passRuleMinCharMais ? ' rule-invalid' : '');
    let classNamePassRuleMinCharMinus = (!passRuleMinCharMinus ? ' rule-invalid' : '');
    let classNamePassRuleMinDigit = (!passRuleMinDigit ? ' rule-invalid' : '');
    let classNamePassRuleMinCharEspetial = (!passRuleMinCharEspetial ? ' rule-invalid' : '');

    return (
        <>
            <Row>
                <Col sm="6">
                    <FormGroup>
                        <Form.Label htmlFor="form-token">Token</Form.Label>
                        <InputGroup className="mb-2">
                            <InputGroup.Text><GoShield /></InputGroup.Text>
                            <FormControl id="form-token" name="token" placeholder="Token" readOnly
                                value={toString(props.token)}
                                onChange={e => props.setToken(e.target.value)}
                            />
                        </InputGroup>
                    </FormGroup>            
                </Col>
                <Col sm="3" className="ps-2 pe-2">
                    <FormGroup>
                        <Form.Label htmlFor="form-password">Senha</Form.Label>
                        <InputGroup className="mb-2">
                        <InputGroup.Text><FaKey /></InputGroup.Text>
                            <FormControl type="password" id="form-password" name="password" placeholder="Senha" required
                                value={toString(props.password)}
                                onChange={e => onChange_Password(e.target.value)}
                                onBlur={e => onBlur_Password(e.target.value)} disabled
                            />
                        </InputGroup>  
                    </FormGroup>            
                </Col>
                <Col sm="3">
                    <FormGroup>
                        <Form.Label htmlFor="form-checkpassword">Confirmação</Form.Label>
                        <InputGroup className="mb-2">
                        <InputGroup.Text><FaKey /></InputGroup.Text>
                            <FormControl type="password" id="form-checkpassword" name="checkPassword" placeholder="Senha" required
                                value={toString(props.checkPassword)}
                                onChange={e => onChange_CheckPassword(e.target.value)} disabled
                                />
                        </InputGroup>
                    </FormGroup>            
                </Col>
            </Row>
            <Row>
                <Col>
                    <div>Restrições para a senha: {isValidPass ? '[✔]' : '[　]' } Valido e {isValidCheck ? '[✔]' : '[　]' } Confirmado
                        <ol>
                            <li className={classNamePassRuleMinChar}>Mínimo de 8 caracteres</li>
                            <li className={classNamePassRuleMaxChar}>Máximo de 10 caracteres</li>
                            <li className={classNamePassRuleMinCharMais}>Pelo menos um caractere maiúsculo</li>
                            <li className={classNamePassRuleMinCharMinus}>Pelo menos um caractere minúsculo</li>
                            <li className={classNamePassRuleMinDigit}>Pelo menos um dígito</li>
                            <li className={classNamePassRuleMinCharEspetial}>Pelo menos um caractere especial</li>
                        </ol>
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default AccessDataRecord;