import { Box, Card, CardContent, CardHeader, Divider, Fade, Grid, IconButton, Menu, MenuItem, Slider, Typography } from "@mui/material";
import { DataGrid, GridCellParams, GridMoreVertIcon, GridRowParams } from "@mui/x-data-grid";
import { Refresh, Update, WebAsset, WebAssetOff } from "@mui/icons-material";

import styles from './CardDataGrid.module.scss';
import { useEffect, useState } from "react";
import { useMediaContext } from "../../../contexts/media.context";

export interface ICardDataGridProps {
    columns: any[],
    rows: any[],
    onClickRefresh?: () => void,
    onChangeReload?: (minute: number) => void
    onChangeMaximized?: (isMaximized: boolean) => void
}

export const CardDataGrid: React.FC<ICardDataGridProps> = (props: ICardDataGridProps) => {

    const { isPortrait, isTablet } = useMediaContext();

    const [ minuteRefresh, setMinuteRefresh ] = useState<number>(1);
    const [ isMaximized, setMaximized ] = useState<boolean>(false);
    const [ anchorMenu, setAnchorMenu ] = useState<null | HTMLElement>(null);
    const openMenu = Boolean(anchorMenu);
    
    const [ timeReload, setTimeReload ] = useState<number>(isTablet ? 2 : 5);
    let [ pageReload, setPageReload ] = useState(0);

    const heightAdapter = isTablet ? (!isPortrait ? (!isMaximized ? '240px' : '670px') : (!isMaximized ? '538px' : '1098px')) : (!isPortrait ? (!isMaximized ? '432px' : '903px') : (!isMaximized ? '1082px' : '1748px'));
    const pageAdapter = isTablet ? (!isPortrait ? (!isMaximized ? 2 : 8) : (!isMaximized ? 7 : 15)) : (!isPortrait ? (!isMaximized ? 5 : 12) : (!isMaximized ? 15 : 25));

    const onClick_MenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorMenu(event.currentTarget);
    };

    const onClick_MenuClose = () => {
        setAnchorMenu(null);
    };

    function onClick_Maximized(event: any) {
        setMaximized(!isMaximized);
        if (props.onChangeMaximized)
            props.onChangeMaximized(!isMaximized);
    }

    function onClick_TimeRefresh(minute: number, event: any) {
        onClick_MenuClose();
        setMinuteRefresh(minute);
        if (props.onChangeReload)
            props.onChangeReload(minute);
    }

    function changePage() {
        const maxPage = Math.round(props.rows.length / pageAdapter);
        let nextPage = pageReload + 1;
        if (nextPage >= maxPage)
            nextPage = 0;
        pageReload = nextPage;
        setPageReload(nextPage);
    }

    function changeTimeReload(event: Event, newValue: number | number[]) {
        setTimeReload(newValue as number);
    }

    useEffect(() => {
        if (props.rows.length > 0) {
            const interval = setInterval(() => {
                changePage();
            }, timeReload * 1000);
            return () => clearInterval(interval);
        }
    }, [props.rows, timeReload]);

    return (
        <Box className="flex-grow-1 d-flex">
            <Card className={`${isMaximized ? styles.cardGridMaximized : styles.cardGrid} flex-grow-1`}>
                <CardHeader className={styles.cardGridHeader}
                    avatar={
                        <Update />
                    }
                    title="Acompanhamento das Embarcações"
                    subheader={`Atualização a cada ${minuteRefresh} minuto(s)`} 
                    action={
                        <>
                            <IconButton aria-label="maximized" onClick={e => onClick_Maximized(e) }>
                                {!isMaximized ? <WebAsset /> : <WebAssetOff />}
                            </IconButton>
                            {!isMaximized && <IconButton aria-label="refresh" 
                                aria-controls={openMenu ? 'fade-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={openMenu ? 'true' : undefined}
                                onClick={e => onClick_MenuOpen(e) }
                            >
                                <GridMoreVertIcon />
                            </IconButton>}
                            <Menu
                                id="fade-menu"
                                MenuListProps={{
                                'aria-labelledby': 'fade-button',
                                }}
                                anchorEl={anchorMenu}
                                open={openMenu}
                                onClose={onClick_MenuClose}
                                TransitionComponent={Fade}
                            >
                                <MenuItem>
                                    <Box className="flex flex-grow-1">
                                        <Box>Velocidade</Box>
                                        <Slider className="flex-grow-1"
                                            size="small"
                                            defaultValue={5}
                                            value={timeReload}
                                            step={1}
                                            max={10}
                                            min={1}
                                            marks 
                                            aria-label="Velocidade"
                                            valueLabelDisplay="auto"
                                            onChange={changeTimeReload}
                                        />
                                    </Box>
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={props.onClickRefresh}>Atualizar agora</MenuItem>
                                <Divider />
                                <MenuItem onClick={e => onClick_TimeRefresh(1, e)} disabled={minuteRefresh === 1}>A cada 1 Minuto</MenuItem>
                                <MenuItem onClick={e => onClick_TimeRefresh(3, e)} disabled={minuteRefresh === 3}>A cada 3 Minuto</MenuItem>
                                <MenuItem onClick={e => onClick_TimeRefresh(5, e)} disabled={minuteRefresh === 5}>A cada 5 Minutos</MenuItem>
                                <MenuItem onClick={e => onClick_TimeRefresh(10, e)} disabled={minuteRefresh === 10}>A cada 10 Minutos</MenuItem>
                                <MenuItem onClick={e => onClick_TimeRefresh(30, e)} disabled={minuteRefresh === 30}>A cada 30 Minutos</MenuItem>
                                <MenuItem onClick={e => onClick_TimeRefresh(60, e)} disabled={minuteRefresh === 60}>A cada 1 Hora</MenuItem>
                            </Menu>
                        </>
                    }
                />
                <CardContent className={styles.cardGridContent} sx={{ height: heightAdapter, width: '100%' }}>
                    <DataGrid columns={props.columns} rows={props.rows}
                        headerHeight={30}
                        rowHeight={65}
                        pageSize={pageAdapter}
                        page={pageReload}
                        onPageChange={newPage => setPageReload(newPage) }
                        rowsPerPageOptions={[pageAdapter]}
                        disableSelectionOnClick
                        experimentalFeatures={{ newEditingApi: true }}
                    />
                </CardContent>
            </Card>
        </Box>
    );
}
