import { useEffect, useState } from "react";
import { Button, ButtonGroup, Card, Col, Container, Row, Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { FaPlusSquare, FaSpinner } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { PickerButtom } from "../../components/sub-render.component";
import { useAlertMessageContext } from "../../contexts/alert-message.context";
import { dateToString, toDate } from "../../utilities/auxiliary-functions";
import { AlertMessageEnum } from "../../utilities/types";
import { isDate, isEmpty } from "../../utilities/validators";
import FinancialHeader from "../headers/financial.header";
import { useTranslateContext } from "../../contexts/translate.context";
import { usePartnerContext } from "../../contexts/partner.context";

export interface IConciliationsFinancialProps {
}

export const ConciliationsFinancial: React.FC<IConciliationsFinancialProps> = (props: IConciliationsFinancialProps) => {
    
    const navigator = useNavigate();
    
    const alertContext = useAlertMessageContext();
    const { display } = useTranslateContext();
    const { partner } = usePartnerContext();

    const title = display.title.conciliation;
    const description = display.description.conciliation;
    
    let reloadPage = false;
    const [ isLoading, setLoading ] = useState(false);
    const [ sending, setSending ] = useState(false);

    const [ dateTimeStart, setDateTimeStart ] = useState(dateToString(new Date(), 'dd/mm/yyyy HH:MM:ss'));
    const [ dateTimeStop, setDateTimeStop ] = useState(dateToString(new Date(), 'dd/mm/yyyy HH:MM:ss'));

    const onChange_DateTimeStart = (date: any) => {
        const dateStop = toDate(dateTimeStop, 'dd/mm/yyyy HH:MM:ss');
        if (date > dateStop) {
            setDateTimeStart(dateToString(dateStop, 'dd/mm/yyyy') + ' 00:00:00');
        } else {
            setDateTimeStart(dateToString(date, 'dd/mm/yyyy HH:MM:ss'));
        }
    }

    const onChange_DateTimeStop = (date: any) => {
        const dateStart = toDate(dateTimeStart, 'dd/mm/yyyy HH:MM:ss');
        if (date < dateStart) {
            setDateTimeStop(dateToString(dateStart, 'dd/mm/yyyy') + ' 23:59:59');
        } else {
            setDateTimeStop(dateToString(date, 'dd/mm/yyyy HH:MM:ss'));
        }
    }


    function onClick_RefreshConciliation(event: any) {
        event.preventDefault();
        console.log('ConciliationOperation.onClick_RefreshConciliation');

        setSending(true);
        searchfilter();
    } // onClick_RefreshConciliation
    
    function onClick_AddRecord(event: any) {
        event.preventDefault();

    } // onClick_AddRecord

    async function searchfilter() {
        if (!isEmpty(partner)) {
            if (isDate(toDate(dateTimeStart, 'dd/mm/yyyy HH:MM:ss')) && isDate(toDate(dateTimeStop, 'dd/mm/yyyy HH:MM:ss'))) {
               
                try {
                    setSending(false);
                } catch(error: any) {
                    await alertContext.show(AlertMessageEnum.FAIL, title, error);
                }
                setSending(false);
            } else {
                setSending(false);
                await alertContext.show(AlertMessageEnum.FAIL, title, display.message.invalid.an_invalid_date_was_reported);
            }
        } else {
            await alertContext.show(AlertMessageEnum.FAIL, title, 'Não foi definido uma Marina para exibir as conciliações !');
            navigator(`/portal`);
        }
    } // searchfilter

    useEffect(() => {
        if (!isLoading && !reloadPage) {
            searchfilter();
            setLoading(true);
        }

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            reloadPage = !reloadPage;
        }
    }, []); // useEffect

    function viewPage() {

        return (
            <Container fluid className="page-body conciliations">
                <Row>
                    <Col md={12} className="page-sweet">
                        <Card style={{ minHeight : 500 }}>
                            <Card.Header>
                                <div className="card-actions float-right d-flex justify-content-end">
                                    <div className="card-actions-time">
                                        <DatePicker
                                            selected={toDate(dateTimeStart, 'dd/mm/yyyy HH:MM:ss')}
                                            onChange={(date: any) => onChange_DateTimeStart(date) }
                                            dateFormat="dd/MM/yyyy 00:00:00"
                                            customInput={<PickerButtom />}
                                            selectsStart
                                            startDate={toDate(dateTimeStart, 'dd/mm/yyyy HH:MM:ss')}
                                            endDate={toDate(dateTimeStop, 'dd/mm/yyyy HH:MM:ss')}

                                            popperClassName="some-custom-class"
                                            popperPlacement="top-end"
                                            popperModifiers={[
                                                {
                                                    name : "offset",
                                                    options : {
                                                        offset : [5, 10]
                                                    }
                                                },{
                                                    name : "preventOverflow",
                                                    options : {
                                                        rootBoundary : "viewport",
                                                        tether : false,
                                                        altAxis : true
                                                    }
                                                }
                                            ]}
                                        />
                                    </div>
                                    <div className="card-actions-time white-space">
                                        <DatePicker
                                            selected={toDate(dateTimeStop, 'dd/mm/yyyy HH:MM:ss')}
                                            onChange={(date: any) => onChange_DateTimeStop(date) }
                                            dateFormat="dd/MM/yyyy 23:59:59"
                                            customInput={<PickerButtom />}
                                            selectsEnd
                                            startDate={toDate(dateTimeStart, 'dd/mm/yyyy HH:MM:ss')}
                                            endDate={toDate(dateTimeStop, 'dd/mm/yyyy HH:MM:ss')}
                                            minDate={toDate(dateTimeStart, 'dd/mm/yyyy HH:MM:ss')}

                                            popperClassName="some-custom-class"
                                            popperPlacement="top-end"
                                            popperModifiers={[
                                                {
                                                    name : "offset",
                                                    options : {
                                                        offset : [5, 10]
                                                    }
                                                },{
                                                    name : "preventOverflow",
                                                    options : {
                                                        rootBoundary : "viewport",
                                                        tether : false,
                                                        altAxis : true
                                                    }
                                                }
                                            ]}
                                        />
                                    </div>
                                    <ButtonGroup>
                                        <Button variant="secondary" onClick={ (e) => onClick_RefreshConciliation(e) } disabled={sending}>
                                            { sending ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <FaSpinner size={22} /> } {' '}
                                            {display.buttom.refresh}
                                        </Button>
                                        <Button onClick={(e) => onClick_AddRecord(e) } ><FaPlusSquare /></Button>
                                    </ButtonGroup>
                                </div>
                                <Card.Title>{display.subtitle.conciliation}</Card.Title>
                            </Card.Header>
                            <Card.Body>
                            </Card.Body>
                            <Card.Footer>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    } // viewCadastreList

    return (
        <div className="page financial">
            <FinancialHeader title={title} description={description} />
            { viewPage() }
        </div>
    );

}

export default ConciliationsFinancial;
