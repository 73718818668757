import { Col, Form, FormControl, FormGroup, InputGroup, Row } from "react-bootstrap";

import { useTranslateContext } from "../../contexts/translate.context";

import { toString } from "../../utilities/auxiliary-functions";
import { cnpjMask, dataMask, phoneMask } from "../../utilities/masks";
import { PartnerSituationType } from "../../utilities/types";
import { isEmpty } from "../../utilities/validators";
import styles from './corporate-data.record.module.scss';
import ImageLightBox from "../../components/image-lightbox/ImageLightbox";
import { Tooltip as TooltipMaterial } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { CameraAltOutlined } from "@mui/icons-material";

export interface ICorporateDataRecordProps {
    title: any,
    isEdit: boolean,

    socialReason?: any,
    fantasy?: any,
    sector?: any,
    registrationPj?: any,
    registrationState?: any,
    foundation?: any,
    site?: any,
    email?: any,
    image?: any
    telephone?: any,
    whatsapp?: any,
    situation?: any,
    setSocialReason?: any,
    setFantasy?: any,
    setSector?: any,
    setRegistrationPj?: any,
    setRegistrationState?: any,
    setFoundation?: any,
    setSite?: any,
    setEmail?: any,
    setTelephone?: any,
    setWhatsapp?: any,
    setSituation?: any
    changeImage?: any
}

export const CorporateDataRecord: React.FC<ICorporateDataRecordProps> = (props: ICorporateDataRecordProps) => {

    const { display } = useTranslateContext();

    return (
        <div className="d-flex justify-content-between align-items-center mb-4">
            <Col sm="10">
                <Row>
                    <Col sm="6">
                        <Form.Label htmlFor="form-social-reason">{display.label.social_reason}</Form.Label>
                        <FormControl type="text" id="form-social-reason" name="social-reason" placeholder={display.example.social_reason} required
                            value={toString(props.socialReason)}
                            onChange={e => props.setSocialReason(e.target.value)}
                        />
                    </Col>
                    <Col sm="3">
                        <Form.Label htmlFor="form-fantasy">{display.label.fantasy}</Form.Label>
                        <FormControl type="text" id="form-fantasy" name="fantasy" placeholder={display.example.fantasy} required
                            value={toString(props.fantasy)}
                            onChange={e => props.setFantasy(e.target.value)}
                        />
                    </Col>
                    <Col sm="3">
                        <Form.Label htmlFor="form-situation">{display.label.situation}</Form.Label>
                        <Form.Select id="form-situation" name="situation" required
                                value={toString(props.situation)}
                                onChange={(e: any) => props.setSituation(e.target.value)}
                        >
                            { isEmpty(props.situation) ? (
                                <option value="">...</option>
                            ) : null }
                            { PartnerSituationType.map((iterator: any, idx: number) => (<option key={idx} value={iterator.id}>{display.label[iterator.name.toLowerCase()]}</option>)) }
                        </Form.Select>
                    </Col>
                </Row>
                <Row>
                    {props.isEdit && <Col sm="3">
                        <Form.Label htmlFor="form-registration-pj">{display.label.registration_pj}</Form.Label>
                        <FormControl type="text" id="form-registration-pj" name="registration-pj" placeholder={display.example.registration_pj} required readOnly={props.isEdit}
                            value={toString(props.registrationPj)}
                            onChange={e => props.setRegistrationPj(cnpjMask(e.target.value))}
                        />
                    </Col>}
                    <Col sm={props.isEdit ? '3' : '4'}>
                        <Form.Label htmlFor="form-registratio-state">{display.label.registration_state}</Form.Label>
                        <FormControl type="text" id="form-registration-state" name="registration-state" placeholder={display.example.registration_state}
                            value={toString(props.registrationState)}
                            onChange={e => props.setRegistrationState(e.target.value)}
                        />
                    </Col>
                    <Col sm={props.isEdit ? '3' : '4'}>
                        <Form.Label htmlFor="form-sector">{display.label.sector}</Form.Label>
                        <FormControl type="text" id="form-sector" name="sector" placeholder={display.example.sector} required
                            value={toString(props.sector)}
                            onChange={e => props.setSector(e.target.value)}
                        />
                    </Col>
                    <Col sm={props.isEdit ? '3' : '4'}>
                        <Form.Label htmlFor="form-foundation">{display.label.foundation}</Form.Label>
                        <FormControl type="text" id="form-foundation" name="foundation" placeholder={display.example.foundation} maxLength={10}
                            value={toString(props.foundation)}
                            onChange={e => props.setFoundation(dataMask(e.target.value))}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm="3">
                        <Form.Label htmlFor="form-site">{display.label.site}</Form.Label>
                        <FormControl type="text" id="form-site" name="site" placeholder={display.example.site}
                            value={toString(props.site)}
                            onChange={e => props.setSite(e.target.value)}
                        />
                    </Col>
                    <Col sm="3">
                        <Form.Label htmlFor="form-email">{display.label.email}</Form.Label>
                        <InputGroup className="mb-2">
                            <InputGroup.Text>@</InputGroup.Text>
                            <FormControl type="text" id="form-email" name="email" placeholder={display.example.email}
                                value={toString(props.email)}
                                onChange={e => props.setEmail(e.target.value)}
                            />
                        </InputGroup>
                    </Col>
                    <Col sm="3">
                        <Form.Label htmlFor="form-telephone">{display.label.telephone}</Form.Label>
                        <FormControl type="text" id="form-telephone" name="telephone" placeholder={display.example.telephone}
                            value={toString(props.telephone)}
                            onChange={e => props.setTelephone(phoneMask(e.target.value))}
                        />
                    </Col>
                    <Col sm="3">
                        <Form.Label htmlFor="form-whatsapp">{display.label.whatsapp}</Form.Label>
                        <FormControl type="text" id="form-whatsapp" name="whatsapp" placeholder={display.example.whatsapp}
                            value={toString(props.whatsapp)}
                            onChange={e => props.setWhatsapp(phoneMask(e.target.value))}
                        />
                    </Col>
                </Row>
            </Col>
            <Col sm="2" className="mt-4">
            {props.image ? (
                <div
                    className='d-flex flex-column justify-content-center'>
                    <ImageLightBox containerStyle={`${styles.imageWrapper}
                        ${styles.vesselImage} mb-3`} src={props.image}
                        alt={'Parceiro'}/>
                    <FormGroup as={Row} className={`d-flex
                        justify-content-center ${styles.imageControls}`}>
                        <label htmlFor={`partner-image`}>
                            <TooltipMaterial className="isClickable" 
                                title="Fazer upload de nova foto">
                                <CloudUploadIcon />
                            </TooltipMaterial>
                        </label>
                        <label className="text-right">
                            <TooltipMaterial className="isClickable ms-2"
                                onClick={(e)=> props.changeImage(e) } 
                                title="Remover foto atual">
                                <HighlightOffIcon />
                            </TooltipMaterial>
                        </label>
                        <Form.Control type='file'
                            id={`partner-image`}
                            accept="image/*"
                            onChange={(e)=> props.changeImage(e) } />
                    </FormGroup>
                </div>
            ) : (
                <div className={`${styles.imageWrapper}
                    ${styles.vesselImagePlaceholder}`}>
                    <CameraAltOutlined color='secondary' className={`isClickable
                        mb-3 ${styles.svg}`} sx={{fontSize: '73px'}} />
                    <FormGroup as={Row} className={` d-flex
                        justify-content-center ${styles.imageControls}`}>
                        <label htmlFor={`partner-image-placeholder`}>
                            <TooltipMaterial className="isClickable"
                                title="Fazer upload de nova foto">
                                <CloudUploadIcon />
                            </TooltipMaterial>
                        </label>
                        <label className="text-right">
                            <TooltipMaterial className="isClickable ms-2"
                                title="Remover foto atual">
                                <HighlightOffIcon />
                            </TooltipMaterial>
                        </label>
                        <Form.Control type='file'
                            id={`partner-image-placeholder`}
                            accept="image/*" onChange={(e)=> props.changeImage(e) } />
                    </FormGroup>
                </div>
            )}
            </Col>
        </div>
    );

}

export default CorporateDataRecord;