import '../styles/nav-menu.scss';

import { ContentPaste } from '@mui/icons-material';
import ListIcon from '@mui/icons-material/List';
import LogoutIcon from '@mui/icons-material/Logout';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { Badge, Box, Divider, IconButton, ListItemText, Menu, MenuItem, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Button, Navbar } from 'react-bootstrap';
import { BsMenuApp } from 'react-icons/bs';
import { FaCogs, FaSignOutAlt, FaUser, FaUserCircle } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import defaultAvatar from '../assets/avatar.png';
import authService from '../services/auth.service';
import { PermissionEnum } from '../utilities/constants';
import { useTranslateContext } from '../contexts/translate.context';

export interface INavMenuProps {
    onBrandClick: any,
    onClickSair: any,
    onCloseSideMenu: any
}

export const NavMenu: React.FC<INavMenuProps> = (props: INavMenuProps) => {

    const navigate = useNavigate();

    const { display } = useTranslateContext();

    const [ inactivedSetting, setInactivedSetting ] = useState(false);
    const [ user, setUser ] = useState({} as any);
    const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null);
    const [ anchorElSettings, setAnchorElSettings ] = React.useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);
    const openSettings = Boolean(anchorElSettings);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClickSettings = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElSettings(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setAnchorElSettings(null);
    };

    function onClick_Brand(event: any) {
        setInactivedSetting(false);
        props.onBrandClick();
    }

    function onClick_Profile(event: any) {
        event.preventDefault();
        handleClose()
        setInactivedSetting(false);
        navigate("/portal/profile");
    }

    function onClick_Settings(event: any) {
        event.preventDefault();
        handleClose()
        setInactivedSetting(false);
        navigate("/portal/settings");
    }
    
    function onClick_MenuAccess(event: any) {
        event.preventDefault();
        handleClose()
        setInactivedSetting(false);
        navigate("/portal/menu_access");
    }

    function onClick_UserAccess(event: any) {
        event.preventDefault();
        handleClose()
        setInactivedSetting(false);
        navigate("/portal/user_access");
    }

    useEffect(() => {
        const user = authService.currentUser();
        setUser(user);
    },[])

    return (
        <>
            <Navbar className='navbar-theme'>
                {!authService.isAssociateOperator() && (
                    <span className="sidebar-toggle d-flex mr-2" onClick={(e: any) => onClick_Brand(e)}>
                        <i className="hamburger align-self-center"/>
                    </span>
                )}
                <Navbar.Collapse className="collapse">
                    <ul className="ml-auto navbar-nav">
                        <li className={`ml-lg-1 dropdown ${inactivedSetting ? 'show ' : ''}nav-item`}>
                            <div className="d-flex navbar">
                            <div className='me-4'>
                                <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                                    <Tooltip title="Notificações">
                                    <IconButton
                                        onClick={handleClick}
                                        size="small"
                                        sx={{ ml: 2 }}
                                        aria-controls={open ? 'account-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                    >
                                    <Badge badgeContent={0} color="error">
                                        <NotificationsNoneIcon  fontSize="large" style={{ color: 'rgba(255, 255, 255, 0.75)'}} />
                                    </Badge>
                                    </IconButton>
                                    </Tooltip>
                                </Box>
                                <Menu
                                    anchorEl={anchorEl}
                                    id="account-menu"
                                    open={open}
                                    onClose={handleClose}
                                    onClick={handleClose}
                                    PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 1.5,
                                        '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                        },
                                        '&:before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                        },
                                    },
                                    }}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                >
                                    <MenuItem onClick={handleClose}>
                                        Sem notificações
                                    </MenuItem>
                                </Menu>
                                </div>

                                <Tooltip title={`${user.roles ? user.roles[0].name : ''}: ${user.username}`} style={{width: 64, height: 32}}>
                                    <IconButton>
                                        <img src={user.avatar ? user.avatar : defaultAvatar} alt="Avatar" className="avatar me-3" />
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title="Configurações" style={{height: 58}}>
                                    <IconButton
                                        onClick={handleClickSettings}
                                        sx={{fontSize: 22}}
                                        aria-controls={openSettings ? 'account-menu-settings' : undefined}
                                        aria-haspopup="true"
                                        className='rotating'
                                        aria-expanded={openSettings ? 'true' : undefined}
                                    >
                                        <SettingsIcon sx={{fontSize: 26}} style={{ color: 'rgba(255, 255, 255, 0.75)'}} />
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    anchorEl={anchorElSettings}
                                    id="account-menu-settings"
                                    open={openSettings}
                                    onClose={handleClose}
                                    onClick={handleClose}
                                    PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 1.5,
                                        '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                        },
                                        '&:before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                        },
                                    },
                                    }}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                >
                                    {authService.hasPermission(PermissionEnum.PROFILE) && 
                                         <MenuItem
                                         onClick={onClick_Profile}
                                       >
                                         <PersonIcon>
                                           <ContentPaste fontSize="medium" />
                                         </PersonIcon>
                                         <ListItemText className="ms-3">
                                         {display.label.my_profile}
                                         </ListItemText>
                                       </MenuItem>
                                    }
                                    {authService.hasPermission(PermissionEnum.CONFIGURATION) && (
                                        <MenuItem onClick={onClick_Settings}>
                                            <SettingsIcon>
                                            <ContentPaste fontSize="medium" />
                                            </SettingsIcon>
                                            <ListItemText className="ms-3">
                                            {display.label.settings}
                                            </ListItemText>
                                        </MenuItem>
                                    )}
                                    {(authService.hasPermission(PermissionEnum.CONFIGURATION) || (!authService.hasPermission(PermissionEnum.CONFIGURATION) && authService.isMaster())) && (

                                        <MenuItem onClick={onClick_MenuAccess}>

                                            <ListIcon>
                                            <ContentPaste fontSize="medium" />
                                            </ListIcon>
                                            <ListItemText className="ms-3">
                                            {display.label.menu_access}
                                            </ListItemText>
                                        </MenuItem>
                                    )}
                                    {authService.hasPermission(PermissionEnum.CONFIGURATION) && (

                                        <MenuItem onClick={onClick_UserAccess}>

                                            <VerifiedUserIcon>
                                            <ContentPaste fontSize="medium" />
                                            </VerifiedUserIcon>
                                            <ListItemText className="ms-3">
                                            {display.label.user_access}
                                            </ListItemText>
                                        </MenuItem>
                                        )}
                                    <Divider />
                                    <MenuItem onClick={props.onClickSair}>

                                        <LogoutIcon>
                                        <ContentPaste fontSize="medium" />
                                        </LogoutIcon>
                                        <ListItemText className="ms-3">
                                        {display.label.go_out}
                                        </ListItemText>
                                    </MenuItem>
                                </Menu>
                            </div>
                            <div role="menu" aria-hidden={!inactivedSetting} className={`dropdown-menu dropdown-menu-right ${inactivedSetting ? 'show' : ''}`}>
                                {authService.hasPermission(PermissionEnum.PROFILE) && 
                                    <Button className="dropdown-item" onClick={onClick_Profile}>
                                        <FaUser />{display.label.my_profile}
                                    </Button>
                                }
                                {authService.hasPermission(PermissionEnum.CONFIGURATION) && 
                                    <Button className="dropdown-item" onClick={onClick_Settings}>
                                        <FaCogs />{display.label.settings}
                                    </Button>
                                }
                                <Button className="dropdown-item" onClick={onClick_MenuAccess}>
                                    <BsMenuApp />{display.label.menu_access}
                                </Button>
                                <Button className="dropdown-item" onClick={onClick_UserAccess}>
                                    <FaUserCircle />{display.label.user_access}
                                </Button>
                                <div className="dropdown-divider"/>
                                <Button className="dropdown-item" onClick={props.onClickSair}>
                                    <FaSignOutAlt />{display.label.go_out}
                                </Button>
                            </div>
                        </li>
                    </ul>
                </Navbar.Collapse>
            </Navbar>
        </>
    );
}
