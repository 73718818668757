import AddIcon from '@mui/icons-material/Add';
import { useEffect, useState } from "react";
import { ButtonGroup, Card, Col, Container, Row, Spinner } from "react-bootstrap";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FaEdit, FaEye, FaTrash } from "react-icons/fa";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Phones } from "../../../components/sub-render.component";
import { useAlertMessageContext } from "../../../contexts/alert-message.context";
import { useDeleteMessageContext } from "../../../contexts/delete-message.context";
import authService from "../../../services/auth.service";
import lclService from "../../../services/lcl.service";
import { dateToString, toLowerCase } from "../../../utilities/auxiliary-functions";
import { PermissionEnum } from "../../../utilities/constants";
import { cpfMask } from "../../../utilities/masks";
import { AlertMessageEnum, getGender, getMemberSituation } from "../../../utilities/types";
import { Button, FormControl, FormHelperText, Input, InputAdornment, InputLabel, LinearProgress } from '@mui/material';
import { DataGrid, GridColDef, GridSelectionModel, GridToolbar } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import styles from './vessel.cadastre.list.module.scss'
import { ThemeProvider } from '@mui/material/styles';
import theme from "../../../theme";
import { Tooltip as TooltipMaterial } from '@mui/material';
import { TPagination } from '../../../types/PaginationType';
import { useTranslateContext } from '../../../contexts/translate.context';
import { usePartnerContext } from '../../../contexts/partner.context';
import { isEmpty } from '../../../utilities/validators';

export interface IMemberCadastreListProps {
    title: string,
    setRecord: any,
    viewOnly?: boolean
}

export const MemberCadastreList: React.FC<IMemberCadastreListProps> = (props: IMemberCadastreListProps) => {
    
    const navigator = useNavigate();

    const alertContext = useAlertMessageContext();
    const deleteContext = useDeleteMessageContext();
    const { display } = useTranslateContext();
    const { partner } = usePartnerContext();

    let reloadPage = false;
    const [ isLoading, setLoading ] = useState(false);
    const [ textSearch, setTextSearch ] = useState('');
    const [ showFilter, setShowFilter]  = useState(false);
    const [ sending, setSending ] = useState(false);
    const [ selectionModel, setSelectionModel ] = useState<GridSelectionModel>([]);

    let noPaged = true;
    const [ searchParams, setSearchParams ] = useSearchParams();

    const [ pageSize, setPageSize ] = useState(10);
    const [ page, setPage ] = useState(0);
    const [ pageFilled, setPageFilled ] = useState<number[]>([]);

    const [ recordCount, setRecordCount ] = useState(0);
    const [ records, setRecords ] = useState([]);
    const [ originalRecords, setOriginalRecords ] = useState([]);

    const columns: GridColDef[] = [
        { field: 'fullname', headerName: display.label.name, flex: 1, align: 'center', headerAlign: 'center', valueGetter: getFullname },
        { field: 'gender', headerName: display.label.gender, align: 'center', headerAlign: 'center', valueGetter: getPeopleGender },
        { field: 'registrationPf', headerName: display.label.registration_pf, minWidth: 150, align: 'center', headerAlign: 'center', valueGetter: getCpf },
        { field: 'situation', headerName: display.label.situation, align: 'center', headerAlign: 'center', valueGetter: getSituation },
        { field: 'phones', headerName: display.label.telephone, align: 'center', headerAlign: 'center', renderCell: getPhones },
        { field: 'created', headerName: display.label.created, align: 'center', headerAlign: 'center', valueGetter: getCreated },
        { field: 'updated', headerName: display.label.updated, align: 'center', headerAlign: 'center', valueGetter: getUpdated },
        {
            field: 'action',
            headerName: 'Ações',
            headerAlign: 'center',
            renderCell: renderButtons,
            disableExport: true,
        }
    ];

    function getFullname(param: any) {
        const member = param.row;
        return member.people.fullname;
    }

    function getPeopleGender(param: any) {
        const member = param.row;
        return display.label[toLowerCase(getGender(member.people.gender).name)];
    }

    function getCpf(param: any) {
        const member = param.row;
        return cpfMask(member.people.registrationPf);
    }

    function getSituation(param: any) {
        const member = param.row;
        return display.label[toLowerCase(getMemberSituation(member.situation).name)];
    }

    function getPhones(param: any) {
        const member = param.row;
        return <Phones values={member.people.phones} />
    }

    function getCreated(param: any) {
        const member = param.row;
        return dateToString(member.created, 'dd/mm/yyyy')
    }

    function getUpdated(param: any) {
        const member = param.row;
        return dateToString(member.updated, 'dd/mm/yyyy')
    }

    function renderButtons(param: any) {
        const memberRow = param.row;
        return (
            <div className="d-flex w-100 justify-content-center">
                {props.viewOnly ? (
                    <>{authService.hasPermission(PermissionEnum.CADASTRE_MEMBER_VIEW) && 
                        <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.toView}</Tooltip>}>
                            <span onClick={(e) => onClick_ViewRecord(e, memberRow)}>
                                <FaEye size={18} />
                            </span>
                        </OverlayTrigger>
                    }</>
                ) : (
                    <>
                    {authService.hasPermission(PermissionEnum.CADASTRE_MEMBER_EDIT) && 
                        <TooltipMaterial className="isClickable" title="Editar Cliente">
                            <span className="me-3"  onClick={(e) => onClick_EditRecord(e, memberRow)}>
                                <FaEdit size={18} />
                            </span>
                        </TooltipMaterial>
                    }
                    {authService.hasPermission(PermissionEnum.CADASTRE_MEMBER_REMOVE) && 
                        <TooltipMaterial className="isClickable" title="Excluir Cliente">
                            <span onClick={(e) => onClick_DeleteRecord(e, records, memberRow)}>
                                <FaTrash size={18} />
                            </span>
                        </TooltipMaterial>
                    }
                    </>
                )}
            </div>
        );
    }

    function onClick_Filter(event: any) {
        event.preventDefault();
        
        setSending(false);
        setShowFilter(false);
        searchFilter();
    } // onClick_Filter

    function onClick_AddRecord(event: any) {
        event.preventDefault();

        setShowFilter(false);
        setSending(true);
        props.setRecord(event, 'new');
    } // onClick_AddRecord

    function onClick_EditRecord(event: any, record: any) {
        event.preventDefault();
        props.setRecord(event, record.id);
    } // onClick_EditRecord

    function onClick_ViewRecord(event: any, record: any) {
        event.preventDefault();
        props.setRecord(event, record.id);
    } // onClick_ViewRecord

    async function onClick_DeleteRecord(event: any, records: any, record: any) {
        event.preventDefault();
        const isConfirmed = await deleteContext.show(props.title, display.message.delete_record, true);
        if (isConfirmed && isConfirmed.result) {
            try {
                await lclService.memberDeleteById(record.id, isConfirmed.message);
                searchFilter();
            } catch(error: any) {
                await alertContext.show(AlertMessageEnum.FAIL, props.title, error);
            }
        }
    } // onClick_DeleteRecord

    async function searchFilter() {
        setLoading(true);

        /* Sempre incluir o partner na pesquisa */
        if (!isEmpty(partner)) {
            try {
                let localPageSize = pageSize;
                let localPage = page;

                if (noPaged) {
                    if (searchParams.has('limit')) {
                        let paramPageSize = searchParams.get('limit');
                        if (paramPageSize) {
                            // eslint-disable-next-line react-hooks/exhaustive-deps
                            localPageSize = Number.parseInt(paramPageSize);
                            setPageSize(localPageSize);
                        }
                    }
                    if (searchParams.has('offset')) {
                        let paramPage = searchParams.get('offset');
                        if (paramPage) {
                            // eslint-disable-next-line react-hooks/exhaustive-deps
                            localPage = Number.parseInt(paramPage);
                            setPage(localPage);
                        }
                    }
                }

                if (pageFilled.indexOf(localPage) === -1) {
                    const pagination = {
                        limit: localPageSize, 
                        offset: localPage * localPageSize
                    } as TPagination;
                    const memberList = await lclService.memberList(partner.id, pagination);
                    setRecordCount(memberList.count);
                    const array = [] as any;
                    records.forEach((record: any) => array.push(record));
                    memberList.rows.forEach((record: any) => array.push(record));

                    setRecords(array);
                    setOriginalRecords(array);
                    setSending(false);

                    if (array.length > 0)
                        pageFilled.push(localPage);
                }
            } catch(error: any) {
                await alertContext.show(AlertMessageEnum.FAIL, props.title, error);
            } finally {
                setLoading(false);
                setShowFilter(false);
            }
        } else {
            await alertContext.show(AlertMessageEnum.FAIL, props.title, 'Não foi definido uma Marina para exibir o cadastro !');
            setLoading(false);
            navigator(`/portal`);
        }
    } // searchfilter
    
    const onChangePageSize = (newPageSize: number) => {
        setSearchParams({...searchParams, 'limit': `${newPageSize}`, 'offset': `${0}`});

        setRecords([]);
        setOriginalRecords([]);
        setRecordCount(0);
        setPageFilled([]);
        setPageSize(newPageSize);
        setPage(0);
    }
    
    const onChangePage = (newPage: number) => {
        setSearchParams({...searchParams, 'offset': `${newPage}`});

        setPage(newPage);
    }

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        noPaged = false;
        let paramPage = searchParams.get('offset');
        if (paramPage) {
            searchFilter();
        }
    }, [pageSize, page]); // useEffect

    useEffect(() => {
        if (!isLoading && !reloadPage) {
            noPaged = true;
            searchFilter();
        }

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            reloadPage = !reloadPage;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // useEffect

    const filtered = (key?: string, value?: string) => {
        const filtered: any = [];
        records.filter((record: any) => {
            if (
                record.people.fullname
                    .toLocaleLowerCase()
                    .includes(textSearch.toLocaleLowerCase()) ||
                record.people.registrationPf
                    .toLocaleLowerCase()
                    .includes(textSearch.toLocaleLowerCase())
            )
            filtered.push(record);
            return record;
        })
        setRecords(filtered);
        return '';
    }

    useEffect(() => {
        if (textSearch.length >= 3) {
            filtered();
        } else if (textSearch.length < 3) {
          setRecords(originalRecords);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [textSearch]);

    function viewCadastreList() {
        return (
            <Container fluid className="page-body member">
                <Row>
                    <Col md={12} className="page-sweet">
                        <Card className={styles.memberList}>
                            <Card.Header>
                                <div className="card-actions float-right">
                                    {!props.viewOnly && <ButtonGroup>
                                        {authService.hasPermission(PermissionEnum.CADASTRE_MEMBER_ADD) && 
                                            <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.register} {display.tooltips.member}</Tooltip>}>
                                                <Button size="small" onClick={ onClick_AddRecord } disabled={sending} variant="contained" color="primary">
                                                    { sending ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <AddIcon /> }
                                                    Cliente
                                                </Button>
                                            </OverlayTrigger>
                                        }
                                    </ButtonGroup>}
                                </div>
                                <Card.Title>Cadastros</Card.Title>
                            </Card.Header>
                            <Card.Body style={{ minHeight : 500 }}>
                                <div className={`card-filter ${showFilter ? "active" : ""}`} >
                                    <div className="card-filter-header">{display.legend.filter}</div>
                                    <div className="card-filter-body">
                                        <Container>
                                            <Row>
                                                <Col>{display.legend.filter_scheme}</Col>
                                            </Row>
                                        </Container>
                                    </div>
                                    <div className="card-filter-footer">
                                        <Button onClick={ onClick_Filter } disabled={sending} variant="contained" color="primary">
                                            { sending ? <Spinner className="me-2" as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null } {' '}
                                            {display.buttom.filter}
                                        </Button>
                                    </div>
                                </div>
                                <FormControl fullWidth sx={{width: 300, marginBottom: 2 }} variant="standard">
                                    <InputLabel className={styles.label} htmlFor="standard-adornment-amount">Pesquisar</InputLabel>
                                    <Input id="standard-adornment-amount"
                                        value={textSearch}
                                        onChange={(e) => setTextSearch(e.target.value)}
                                        endAdornment = {
                                            <InputAdornment position="end">
                                                <SearchIcon className='isClickable' />
                                            </InputAdornment>
                                        }
                                    />
                                    <FormHelperText id="component-helper-text">
                                        Nome ou CPF
                                    </FormHelperText>
                                </FormControl>
                                <ThemeProvider theme={theme}>
                                    <div style={{ height: 402, width: '100%', marginTop: 0 }}>
                                        <DataGrid
                                            rows={records}
                                            rowCount={recordCount}
                                            getRowId={row => row.id}
                                            loading={isLoading}
                                            columns={columns}
                                            pageSize={pageSize}
                                            onPageSizeChange={newPageSize => onChangePageSize(newPageSize)}
                                            page={page}
                                            onPageChange={newPage => onChangePage(newPage) }
                                            
                                            rowsPerPageOptions={[10, 25, 50, 75, 100]}
                                            disableSelectionOnClick
                                            // onCellClick={(e) => console.log(e)}
                                            onSelectionModelChange={(newSelectionModel: any) => {
                                                setSelectionModel(newSelectionModel)
                                            }}
                                            components={{
                                                Toolbar: GridToolbar,
                                                LoadingOverlay: LinearProgress
                                            }}
                                            selectionModel={selectionModel}
                                        />
                                    </div>
                                </ThemeProvider>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    } // viewCadastreList

    return (
        <>
            { viewCadastreList() }
        </>
    );

}

export default MemberCadastreList;
