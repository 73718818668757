import React from 'react';
import { useNavigate } from 'react-router-dom';
import authService from '../services/auth.service';
import { useTranslateContext } from '../contexts/translate.context';

export interface IMenuItemProps {
    name: any, 
    subMenus: any, 
    iconClassName: any, 
    onClick: any, 
    onCloseSideMenu: any, 
    to: any, 
    expanded: any
}

export const MenuItem: React.FC<IMenuItemProps> = (props: IMenuItemProps) => {

    const navigate = useNavigate();

    const { display } = useTranslateContext();

    function onClick_Item(event: any, link: string) {
        event.preventDefault();
        /*
        let force: boolean = false;
        if (typeof link === 'string') {
            if (link.indexOf('dashboard') > -1)
                force = true
            if (link.indexOf('schedule') > -1)
                force = true
            if (link.indexOf('settings') > -1)
                force = true
        }
        */
        props.onCloseSideMenu(false);
        navigate(link);
    }

    return (
        <>
            {props.subMenus.length === 0 ? (
                <li className="sidebar-item ">
                    <span className="sidebar-link" onClick={(e: any) => onClick_Item(e, props.to) }>
                        {props.iconClassName}
                        <span className="align-middle">{display.label[props.name]}</span>
                    </span>
                </li>
            ) : (
                <li className="sidebar-item " onClick={props.onClick}>
                    <span data-toggle="collapse" className={`sidebar-link ${props.expanded && !props.expanded.isOpened ? "collapsed" : ""}`} aria-expanded={props.expanded && props.expanded.isOpened}>
                        {props.iconClassName}
                        <span className="align-middle">{display.label[props.name]}</span>
                    </span>
                    <div className={`collapse ${props.expanded && props.expanded.isOpened ? "show" : ""}`}>
                        {props.subMenus && props.subMenus.length > 0 ? (
                        <ul id="item" className="sidebar-dropdown list-unstyled">
                            {props.subMenus.map((menu: any, index: any) => (
                                !menu.divider ? (
                                    authService.permissionIn(props.name +'_'+ menu.name) && <li className="sidebar-item" key={index}>
                                        <span className="sidebar-link" onClick={(e: any) => onClick_Item(e, menu.to) }>
                                            {menu.iconClassName}
                                            <span className="align-middle">{display.label[menu.name+'_menu']}</span>
                                        </span>
                                    </li>
                                ) : <li className="sidebar-item" key={index}><span className="sidebar-link"><span className="sidebar-divider"></span></span></li>
                            ))}
                        </ul>
                        ) : null}
                    </div>
                </li>
            )}
        </>
    );

};
