import { useEffect, useState } from "react";
import { Col, Form, FormControl, FormGroup, InputGroup, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";

import { useTranslateContext } from "../../contexts/translate.context";
import { useAlertMessageContext } from "../../contexts/alert-message.context";

import addressService from "../../services/address.service";
import { toInt, toLowerCase, toString } from "../../utilities/auxiliary-functions";
import { dataMask } from "../../utilities/masks";
import { AlertMessageEnum, CategoriesNavalType, getLimitsNavalDescription, HarborType } from "../../utilities/types";
import { isEmpty } from "../../utilities/validators";
import styles from './maritime-license.record.module.scss';
import UploadFileIcon from '@mui/icons-material/UploadFile';

export interface IMaritimeLicenseRecordProps {
    title: any,
    isEdit: boolean,
    viewOnly?: boolean,
    isRequired: boolean,

    categoryNaval?: any,
    enrollmentNaval?: any,
    validityNaval?: any,
    limitsNaval?: any,
    commentsNaval?: any,
    locationNaval?: any,
    expeditionNaval?: any,
    setCategoryNaval?: any,
    setEnrollmentNaval?: any,
    setValidityNaval?: any,
    setLimitsNaval?: any,
    setCommentsNaval?: any,
    setLocationNaval?: any,
    setExpeditionNaval?: any,
    
    driversLicenseDocument?: any,
    changeDriversLicenseDocument?: any,
    clickViewDocument: any
}

export const MaritimeLicenseRecord: React.FC<IMaritimeLicenseRecordProps> = (props: IMaritimeLicenseRecordProps) => {

    const alertContext = useAlertMessageContext();
    const { display } = useTranslateContext();

    const [ listStates, setListStates ] = useState([] as any);
    const [ listHarbor, setListHarbor ] = useState([] as any);
    let [ harborState, setHarborState ] = useState('');

    const [ categories, setCategories ] = useState([] as any);

    async function onChange_CategoryNaval(event: any) {
        event.preventDefault();

        const { checked, value } = event.target;

        let localIds = '';
        let countChecked = 0;

        for (var i = 0; i < categories.length; i++) {
            const category = categories[i];
            if (category.checked) {
                countChecked++;
            }
        }

        if ((countChecked < 2) || (!checked)) {
            const localCategories = [] as any;
            for (var j = 0; j < categories.length; j++) {
                const category = categories[j];
    
                if (toInt(category.id) === toInt(value))
                    category.checked = checked;

                if (category.checked)
                    localIds += ';'+ category.id;

                localCategories[localCategories.length] = {
                    id : category.id, 
                    name : category.name, 
                    description : category.description,
                    initial : category.initial,
                    limits : category.limits,
                    checked : countChecked < 2 ? category.checked : false
                };
            }
            setCategories(localCategories);
            treatLimits(localCategories);
            props.setCategoryNaval(localIds.substring(1));
        } else {
            await alertContext.show(AlertMessageEnum.FAIL, props.title, 'Apenas duas categorias são permitidas.');
            treatLimits(categories);
        }
    }

    function treatLimits(categories: any) {
        let localLimits = '';
        //let line = 1;
        for (var k = 0; k < categories.length; k++) {
            const category = categories[k];
            if (category.checked) {
                localLimits += display.label[toLowerCase(category.initial)] + ' - '+ display.label[toLowerCase(getLimitsNavalDescription(category.id).name)] +'\n';
                //line++;
            }
        }
        props.setLimitsNaval(localLimits);
    }

    async function fillStates() {
        try {
            const states = await addressService.listStates();
            setListStates(states);
        } catch (error: any) {
            await alertContext.show(AlertMessageEnum.FAIL, props.title, error);
        }
    }

    function treatHarbor(state: string): void {
        const listHarborLocal = new Array({} as any);
        HarborType.forEach((iterator: any) => {
            if (iterator.uf === state) {
                listHarborLocal.push(iterator);
            }
        });
        setListHarbor(listHarborLocal);
    }

    function onChange_State(state: string) {
        setHarborState(state);
        props.setLocationNaval(state);
        treatHarbor(state);
    }

    async function initialize() {
        await fillStates();
    }

    useEffect(() => {
        const localCategories = [] as any;
        for (var i = 0; i < CategoriesNavalType.length; i++) {
            const category = CategoriesNavalType.at(i);
            if (category != null) {
                localCategories[localCategories.length] = { 
                    id : category.id, 
                    name : category.name, 
                    description : category.description,
                    initial : category.initial,
                    limits : category.limits,
                    checked : props.categoryNaval && props.categoryNaval.indexOf(category.id) > -1
                }
            }
        }
        setCategories(localCategories);
        treatLimits(localCategories);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.categoryNaval]); // useEffect

    useEffect(() => {
        setHarborState(props.locationNaval);
        treatHarbor(props.locationNaval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.locationNaval]);

    useEffect(() => {
        initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Row>
                <Col sm="4">
                    <Form.Label htmlFor="form-category_naval">{display.label.category_naval}</Form.Label>
                    <FormGroup className={styles.groupCategoryNaval}>
                        { CategoriesNavalType.map((iterator: any, idx: number) => (
                            <Form.Check type="checkbox" key={idx} 
                                label={`${display.label[toLowerCase(iterator.initial)]} - ${display.label[toLowerCase(iterator.name)]}`}
                                checked={categories.length > 0 && categories[idx].checked}
                                name="categoryNaval"
                                value={toString(iterator.id)}
                                onChange={(e) => onChange_CategoryNaval(e)}
                                required={props.isRequired} disabled
                            />
                        ))}
                    </FormGroup>
                </Col>
                <Col sm="8">
                    <FormGroup>
                        <Form.Label htmlFor="form-limits_naval">{display.label.limits_naval}</Form.Label>
                        <FormControl as="textarea" rows={5} id="form-limits_naval" name="limitsNaval" placeholder={display.example.limits_naval}
                            value={toString(props.limitsNaval)}
                            style={{fontSize: 12, height: 128}}
                            onChange={e => props.setLimitsNaval(e.target.value)}
                            readOnly={true}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <hr/>
            <Row>
                <Col sm="5">
                    <FormGroup>
                        <Form.Label htmlFor="form-enrollment_naval">{display.label.enrollment}</Form.Label>
                        <FormControl id="form-enrollment_naval" name="enrollmentNaval" placeholder={display.example.enrollment_naval} 
                            required={props.isRequired}
                            value={toString(props.enrollmentNaval)} disabled
                            onChange={e => props.setEnrollmentNaval(e.target.value)}
                        />
                    </FormGroup>
                </Col>
                <Col sm="3" className="ps-2 pe-2">
                    <FormGroup>
                        <Form.Label htmlFor="form-expedition_naval">{display.label.expedition_naval}</Form.Label>
                        <FormControl id="form-expedition_naval" name="expeditionNaval" placeholder={display.example.expedition_naval} 
                            required ={props.isRequired}
                            maxLength={10}
                            value={toString(props.expeditionNaval)} disabled
                            onChange={e => props.setExpeditionNaval(dataMask(e.target.value))}
                        />
                    </FormGroup>
                </Col>
                <Col sm="4">
                    <FormGroup>
                        <Form.Label htmlFor="form-validity_naval">{display.label.validity_naval}</Form.Label>
                        <FormControl id="form-validity_naval" name="validityNaval" maxLength={10} placeholder={display.example.validity_naval} 
                            required={props.isRequired}
                            value={toString(props.validityNaval)} disabled
                            onChange={e => props.setValidityNaval(dataMask(e.target.value))}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm="4">
                    <FormGroup>
                        <Form.Label htmlFor="form-harbor-location">{display.label.state}</Form.Label>
                        <Form.Select id="form-harbor-location" name="harborstate" 
                            required={props.isRequired}
                            value={harborState} disabled
                            onChange={(e: any) => onChange_State(e.target.value)}
                        >
                            { isEmpty(harborState) ? (
                                <option value="">...</option>
                            ) : null }
                            { listStates.map((iterator: any, idx: number) => (<option key={idx} value={iterator.sigla}>{iterator.nome}</option>)).sort((a : any, b : any) => { return a.props.children > b.props.children ? 1 : -1; }) }
                        </Form.Select>
                    </FormGroup>
                </Col>
                <Col sm="8">
                    <FormGroup>
                        <Form.Label htmlFor="form-comments_naval">{display.label.location_naval}</Form.Label>
                        <Form.Select id="form-comments_naval" name="commentsNaval" placeholder={display.example.location_naval} 
                            required={props.isRequired}
                            value={toString(props.commentsNaval)}
                            onChange={e => props.setCommentsNaval(e.target.value)} disabled
                        >
                            { isEmpty(props.locationNaval) ? (
                                <option value="">...</option>
                            ) : null }
                            { listHarbor.map((iterator: any, idx: number) => (<option key={idx} value={iterator.id}>{iterator.om} - {iterator.initials}</option>)).sort((a : any, b : any) => { return a.props.children > b.props.children ? 1 : -1; }) }
                        </Form.Select>
                    </FormGroup>
                </Col>
            </Row>
            <Row className="mt-4">
                <Table size="sm">
                    <tbody>
                        <tr>
                        <td className={`${styles.tdFake} text-right`} style={{verticalAlign: 'initial'}}>
                            </td>
                        <td className={styles.td}>
                            <span className='me-5 mt-2' style={{verticalAlign: 'initial'}}>

                                    {display.label.drivers_license_document}
                            </span>
                                <InputGroup className={styles.inputGroup}>
                                    <FormControl className="input-file-text" disabled
                                        id="form-file-drivers-license-document" name="driversLicenseDocument"
                                        value={toString(props.driversLicenseDocument.filename)}
                                        readOnly
                                        style={{marginBottom: '2px'}}
                                    />
                                    <Form.Control type='file' id='input-file-drivers-license-document' onChange={(e) => { props.changeDriversLicenseDocument(e) }}  disabled />
                                        {!props.viewOnly && <OverlayTrigger overlay={<Tooltip id="tooltip">
                                            {display.tooltips.upload_documents}</Tooltip>}>
                                            <Form.Label className="input-file-button"
                                                htmlFor='input-file-drivers-license-document'>
                                                <UploadFileIcon />
                                            </Form.Label>
                                        </OverlayTrigger>}
                                        {!isEmpty(props.driversLicenseDocument.document) ? (
                                            <>
                                            <Form.Label
                                                className="open-file-button input-file-button"
                                                htmlFor='file-drivers-license-document'
                                                onClick={ (e)=> { props.clickViewDocument(e, 
                                                    display.label.tracker_policy_document, 
                                                    props.driversLicenseDocument.document
                                                )
                                            }}>{display.buttom.visualize}</Form.Label>
                                            {!props.viewOnly && <OverlayTrigger overlay={<Tooltip id="tooltip">
                                                {display.tooltips.remove_documents}</Tooltip>}>
                                                <Form.Label className="input-file-button"
                                                    onClick={(e) => { props.changeDriversLicenseDocument(e) }}
                                                >
                                                    <FaTrashAlt size={18} />
                                                </Form.Label>
                                            </OverlayTrigger>}
                                            </>
                                        ) : null}
                                </InputGroup>
                                <Form.Label
                                    className={`${styles.formLabelMobile} open-file-button input-file-button`}
                                    htmlFor='file-drivers-license-document'
                                    onClick={ (e)=> { props.clickViewDocument(e, 
                                        display.label.tracker_policy_document, 
                                        props.driversLicenseDocument.document
                                    )
                                }}>{display.buttom.visualize}</Form.Label>
                            </td>
                        </tr>
                        
                    </tbody>
                </Table>
            </Row>
        </>
    );

}

export default MaritimeLicenseRecord;
