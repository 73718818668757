import { useEffect, useState } from "react";
import { Col, Dropdown, Form, FormControl, FormGroup, InputGroup, Row } from "react-bootstrap";

import { useTranslateContext } from "../../contexts/translate.context";

import cduService from "../../services/cdu.service";
import { toString, toLowerCase, toInt } from "../../utilities/auxiliary-functions";
import { Categories, CategoryType, MaterialType, getSubCategory } from "../../utilities/types";
import { isEmpty } from "../../utilities/validators";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { TFilter } from "../../types/FilterType";

export interface ICategoryRecordProps {
    title: any,
    isEdit: boolean,

    material: any,
    team: any,
    group: any,
    subgroup: any,
    name: any,

    setMaterial: any,
    setTeam: any,
    setGroup: any,
    setSubgroup: any,
    setName: any,

    changeTeam?: any
}

export const CategoryRecord: React.FC<ICategoryRecordProps> = (props: ICategoryRecordProps) => {

    const { display } = useTranslateContext();

    const [ memoryGroup, setMemoryGroup ] = useState([] as any);
    const [ memorySubgroup, setMemorySubgroup ] = useState([] as any);
    const [ subCategories, setSubCategories ] = useState([] as any);

    async function initialize() {
        searchTeam(props.team);
    }

    useEffect(() => {
        initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    useEffect(() => {
        const subgroups = getSubCategory(props.group);
        setSubCategories(subgroups);
        if (props.subgroup === '' && subgroups.length > 0) {
            props.setSubgroup(subgroups[0].name)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.group]);

    function onChange_Material(event: any) {
        const material = event.target.value;
        props.setMaterial(material);
        // changeTeam(material);
    }

    function onChange_Team(event: any) {
        const team = event.target.value;
        props.setTeam(team);
        searchTeam(team);
    }

    async function searchTeam(team: string) {
        const filter = {
            where: { team }
        } as TFilter;
        let categoryFilter = await cduService.categoryFilter(filter); // {count: 0, rows: []} as any; 
        const memory = [] as any;
        categoryFilter.rows.forEach((category: any, index: number) => {
            if (memory.indexOf(category.group) === -1)
                memory[memory.length] = category.group;
        });
        setMemoryGroup(memory);
    }

    async function onSelect_Group(group: string) {
        props.setGroup(group);

        const filter = {
            where: { team: props.team, group }
        } as TFilter;
        let categoryFilter = await cduService.categoryFilter(filter); // {count: 0, rows: []} as any; 
        const memory = [] as any;
        categoryFilter.rows.forEach((category: any, index: number) => {
            if (memory.indexOf(category.subgroup) === -1)
                memory[memory.length] = category.subgroup;
        });
        setMemorySubgroup(memory);
    }

    function onSelect_Subgroup(eventKey: any) {
        props.setSubgroup(eventKey);
    }

    const onSelectCategory = (category: string) => {
        props.setGroup(category)
    }

    function viewCategoryCadaster() {
        return (
            <>
            <Row>
                <Col sm="12">
                    <fieldset>
                        <legend>{display.label.general_data}</legend>
                        <Row>
                            <Col sm="4">
                                <Row>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Form.Label htmlFor="form-material">Material</Form.Label>
                                            { MaterialType.map((iterator: any, idx: number) => (
                                                <Form.Check type="radio"  key={idx}
                                                    label={iterator.name}
                                                    checked={iterator.id === toInt(props.material)}
                                                    name="material"
                                                    value={iterator.id}
                                                    onChange={(e) => onChange_Material(e)}
                                                />
                                            )) }
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Form.Label htmlFor="form-team">Tipo</Form.Label>
                                            { CategoryType.map((iterator: any, idx: number) => (
                                                <Form.Check type="radio"  key={idx}
                                                    label={display.label[toLowerCase(iterator.name)]}
                                                    checked={iterator.id === toInt(props.team)}
                                                    name="team"
                                                    value={iterator.id}
                                                    onChange={(e) => onChange_Team(e)}
                                                />
                                            )) }
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm="8">
                                <Row>
                                    <Col sm="6">
                                        <FormGroup >
                                            {(toInt(props.material) === 4 && toInt(props.team) === 2) ? (
                                                <div>
                                                    <Form.Label htmlFor="form-state">{display.label.category}</Form.Label>
                                                    <Form.Select id="form-state" name="state"
                                                        value={toString(props.group)}
                                                        onChange={(e: any) => onSelectCategory(e.target.value)}
                                                    >
                                                        { isEmpty(props.group) ? (
                                                            <option value="">...</option>
                                                        ) : null }
                                                        { Categories.map((iterator: any, idx: number) => (<option key={idx} value={iterator.name}>{iterator.name}</option>)) }
                                                    </Form.Select>
                                                </div>
                                            ) : (
                                                <>
                                                    <Form.Label htmlFor="form-group">{display.label.category}</Form.Label>
                                                    <Dropdown>
                                                        <InputGroup className="mb-3">
                                                            <FormControl type="text" id="form-group" name="group"
                                                                    value={toString(props.group)}
                                                                    onChange={e => props.setGroup(e.target.value)}
                                                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                                                />
                                                            <Dropdown.Toggle id="form-group"/>
                                                        </InputGroup>
                                                        <Dropdown.Menu>
                                                        { memoryGroup.map((iterator: any, idx: number) => (<DropdownItem key={idx} onClick={e => props.setGroup(iterator)}>{iterator}</DropdownItem>)) }
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Form.Label htmlFor="form-state">{display.label.subcategory}</Form.Label>
                                            {(toInt(props.material) === 4 && (toInt(props.team) === 2 && subCategories.length))
                                                 ? (
                                                    <Form.Select id="form-state" name="state"
                                                        value={toString(props.subgroup)}
                                                        onChange={e => props.setSubgroup(e.target.value)}
                                                    >
                                                        { subCategories.map((iterator: any, idx: number) => (<option key={idx} value={iterator.name}>{iterator.name}</option>)) }
                                                    </Form.Select>
                                                ) : (
                                                    <Dropdown>
                                                        <InputGroup className="mb-3">
                                                            <FormControl type="text" id="form-subgroup" name="subgroup"
                                                                value={toString(props.subgroup)}
                                                                onChange={e => props.setSubgroup(e.target.value)}
                                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                                            />
                                                            <Dropdown.Toggle id="form-subgroup"/>
                                                        </InputGroup>
                                                        <Dropdown.Menu>
                                                            { memorySubgroup.map((iterator: any, idx: number) => (<DropdownItem key={idx} onClick={e => props.setSubgroup(iterator)}>{iterator}</DropdownItem>)) }
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                )
                                            }
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12">
                                        <FormGroup>
                                            <Form.Label htmlFor="form-name">{display.label.description}</Form.Label>
                                            <FormControl type="text" id="form-name" name="name" placeholder={display.example.name}
                                                value={toString(props.name)}
                                                onChange={e => props.setName(e.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </fieldset>
                </Col>
            </Row>
        </>
        );
    }

    return (
        <>
            {viewCategoryCadaster()}
        </>
    );

}

export default CategoryRecord;