import { useEffect, useState } from 'react'

import { useNavigate, useParams } from 'react-router-dom'
import Logo from '../../assets/N.png'
import nfcIcon from '../../assets/nfc_icon.png'
import styles from './Reservation.module.scss'

import Loader from '../../components/loader/loader'
import authService from '../../services/auth.service'
import lclService from '../../services/lcl.service'
import { Button, FormControlLabel, Switch, TextField } from '@mui/material'

import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp'
import WaterIcon from '@mui/icons-material/Water'
import BlockIcon from '@mui/icons-material/Block'
import CheckIcon from '@mui/icons-material/Check'
import { Image } from 'react-bootstrap'
import { dateToString, toInt } from '../../utilities/auxiliary-functions'
import { getFuel, getHarbor } from '../../utilities/types'
import GradingIcon from '@mui/icons-material/Grading';
import { isEmpty } from '../../utilities/validators'
import cduService from '../../services/cdu.service'

interface IReservationProps {
    
}

export const Reservation: React.FC<IReservationProps> = () => {

  const [typeConfirmation, setTypeConfirmation] = useState('');
  const [loading, setLoading] = useState(true)
  const [confirmingDeparture, setConfirmingDeparture] = useState(false)
  const [confirming, setConfirming] = useState(false)
  const [reservationData, setReservationData] = useState<any>()
	const [time, setTime] = useState(new Date())
  const [credential, setCredential] = useState('')
  const marina = authService.currentPartner()?.company?.fantasy || ''
  const navigate = useNavigate()
  const {reservation} = useParams()

  useEffect(() => {
    const intervalId = setInterval(() => {
        setTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    getReservation()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getReservation = async () => {
    setLoading(true)
    const reservationData = await lclService.getByLocator(reservation || '')
    if (reservationData) {
      setReservationData(reservationData)
    }
    setLoading(false)
  }

  const handleConfirmation = async (type: string) => {
    if (confirmingDeparture) {
      if (!isEmpty(credential)) {
        const employee = await cduService.employeeByCredential(credential)
        if (!isEmpty(employee)) {
          let locator = reservation || '';
          if (type === 'confirmDescent') {
              await lclService.movementUpdateMoveByLocator(
                locator,
                credential,
                3,
                8
              )
          } else if (type === 'confirmAscent') {
              await lclService.movementUpdateMoveByLocator(
                locator,
                credential,
                4,
                7
              )
          } else if (type === 'finishDescent') {
              await lclService.movementUpdateMoveByLocator(
                locator,
                credential,
                5,
                5
              )
          } else if (type === 'requestedAscent') {
              await lclService.movementUpdateMoveByLocator(
                locator,
                credential,
                2,
                5
              )
          } else if (type === 'requestedDescent') {
              await lclService.movementUpdateMoveByLocator(
                locator,
                credential,
                1,
                1
              )
          } else if (type === 'finishAscent') {
              await lclService.movementUpdateMoveByLocator(
                locator,
                credential,
                6,
                1
              )
              await lclService.movementUpdateCheckInByLocator(
                locator,
                'finished',
                reservationData.registratioCheckIn
              )
          } else {
              await lclService.movementUpdateMoveByLocator(
                locator,
                credential,
                1,
                6
              )
          }
          navigate('/portal/dashboard/operation-management')
        } else {
          setCredential('')
          setConfirmingDeparture(false)
        }
      } else {
        setCredential('')
        setConfirmingDeparture(false)
      }
    } else {
      setTypeConfirmation(type)
      setConfirmingDeparture(true)
    }
  }

  const handleCancel = () => {
    if (confirmingDeparture) {
      setConfirmingDeparture(false)
    } else {
      navigate('/portal/dashboard/operation-management')
    }
  }

  const handleKeypress = (e: any) => {
    if (e.charCode === 13) {
      handleConfirmation('')
    }
  }

  function viewPage() {
    return (
        <div className={styles.reservation}>
          <img src={Logo} alt="logo Nautisystem" className={styles.logo} />
          <div className='d-flex flex-column align-items-center'>
            <h2>{marina}</h2>
            <h5>{new Date().toLocaleDateString()}</h5>
            <h5 className={`${styles.clockTitle}`}>{time.toLocaleTimeString()}</h5>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <>
              {confirmingDeparture ? (
                <div className='d-flex flex-column w-100 align-items-center mt-3'>
                  <div style={{height: '242px'}} className='d-flex flex-column align-items-center'>
                    <img src={nfcIcon} alt="ícone nfc" className={styles.nfcIcon}/>
                    <h5>Digite ou aproxime sua credencial</h5>
                    <form action="">
                      <TextField
                        margin="normal"
                        name="credential"
                        type="text"
                        id="credential"
                        autoFocus
                        variant="standard"
                        autoComplete="off"
                        onChange={e => setCredential(e.target.value)}
                        value={credential}
                        onKeyPress={handleKeypress}
                        label="Credencial"
                        disabled={confirming}
                        color="primary"
                        className="mb-3 mt-2"
                        sx={{ minWidth: 275, maxWidth: 275 }}
                      />
                    </form>
                  </div>
                  {toInt(reservationData.move) === 4 && (
                    <FormControlLabel
                      control={<Switch />}
                      label="Limpar e Higienizar embarcação agora?"
                      className="mt-2"
                    />
                  )}
                </div>
              ) : (
                <div className="d-flex flex-column">
                  <div className={styles.vesselInformation}>
                    <Image src={reservationData.vesselImage} className={styles.vesselImage} />
                    <section className={styles.logoWrapper}>
                      <div className="w-100 d-flex justify-content-between align-items-center">
                          <div className={styles.vesselName}>
                              <h3 className="mt-3">{reservationData.vesselName}</h3>
                              <p className={`mb-0 ${styles.enrollment}`}>REG: {reservationData.vesselEnrollment}</p>
                              <p className={styles.enrollment}>ID: {reservationData.vesselIdentifyKey}</p>
                          </div>
                          <div className={styles.vesselAlert}>
                              <div className="d-flex justify-content-center flex-column">
                                  {reservationData.vesselIndAlert !== 0 && (
                                      <div className={`${styles.theftIndicator}`}>
                                          <p>Alerta de Roubo</p>
                                      </div>
                                  )}
                                  {reservationData.vesselIndRestriction !== 0 && (
                                      <div className={styles.financialIndicator}>
                                          <p>Restrição Financeira</p>
                                      </div>
                                  )}
                              </div>
                          </div>
                        </div>
                    </section>
                    <section className={styles.vesselInfo}>
                      <div className="d-flex justify-content-between align-items-center">
                          <h4 className='w-100 d-flex justify-content-between'>Dados da Embarcação
                          <GradingIcon className="isClickable"/>
                          </h4>
                      </div>
                      <section className="d-flex justify-content-between mt-2">
                          <div className="d-flex flex-column">
                              <div className="d-flex flex-column">
                                  <p>Marca</p>
                                  <h5>{reservationData.vesselBrand || ' -- '}</h5>
                              </div>
                              <div className="d-flex flex-column mt-2">
                                  <p>UF</p>
                                  <h5>{getHarbor(reservationData.vesselHarbor).uf}</h5>
                              </div>
                              <div className="d-flex flex-column mt-2">
                                  <p>Validade</p>
                                  <h5>{dateToString(reservationData.vesselValidityTie, 'dd/mm/yyyy')}</h5>
                              </div>
                          </div>
                          <div className="d-flex flex-column">
                              <div className="d-flex flex-column">
                                  <p>Modelo</p>
                                  <h5>{reservationData.vesselModel || ' -- '}</h5>
                              </div>
                              <div className="d-flex flex-column mt-2">
                                  <p>DPC</p>
                                  <h5>{getHarbor(reservationData.vesselHarbor).initials}</h5>
                              </div>
                              <div className="d-flex flex-column mt-2">
                                  <p>Potência (HP)</p>
                                  <h5>{reservationData.vesselPotency}</h5>
                              </div>
                          </div>
                          <div className="d-flex flex-column">
                              <div className="d-flex flex-column">
                                  <p>Ano</p>
                                  <h5>{reservationData.vesselYear}</h5>
                              </div>
                              <div className="d-flex flex-column mt-2">
                                  <p>TIE</p>
                                  <h5>{reservationData.vesselNumberTie}</h5>
                              </div>
                              <div className="d-flex flex-column mt-2">
                                  <p>Combustível</p>
                                  <h5>{getFuel(reservationData.vesselFuel).description}</h5>
                              </div>
                          </div>
                      </section>
                    </section>
                  </div>
                  <div className='d-flex w-100 flex-column justify-content-between align-items-center gap-3'>
                    {toInt(reservationData.move) === 1 && (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          className={`${styles.logoutButton} mt-4 me-0`}
                          onClick={() => handleConfirmation('confirmDescent')}
                        >
                          CONFIRMAR DESCIDA
                          <KeyboardDoubleArrowDownIcon className="ms-2" />
                        </Button>
                        <Button
                          variant="contained"
                          color="inherit"
                          sx={{
                            backgroundColor: 'yellow',
                            width: 226,
                            color: 'black'
                          }}
                          size="large"
                          className={`${styles.logoutButton} mt-4 me-5`}
                          onClick={() => handleConfirmation('reject')}
                        >
                          REJEITAR DESCIDA
                          <BlockIcon className="ms-2" />
                        </Button>
                      </>
                    )}
                    {toInt(reservationData.move) === 4 && (
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        className={`${styles.logoutButton} mt-4 me-0`}
                        onClick={() => handleConfirmation('finishAscent')}
                      >
                        FINALIZAR SUBIDA
                        <WaterIcon className="ms-2" />
                      </Button>
                    )}
                    {toInt(reservationData.move) === 3 && (
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        className={`${styles.logoutButton} mt-4 me-0`}
                        onClick={() => handleConfirmation('finishDescent')}
                      >
                        FINALIZAR DESCIDA
                        <WaterIcon className="ms-2" />
                      </Button>
                    )}
                    {toInt(reservationData.move) === 2 && (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          className={`${styles.logoutButton} mt-4 me-0`}
                          onClick={() => handleConfirmation('confirmAscent')} // acao no operador para subir a embarcacao
                        >
                          CONFIRMAR SUBIDA
                          <KeyboardDoubleArrowUpIcon className="ms-2" />
                        </Button>
                        <Button
                          variant="contained"
                          color="inherit"
                          sx={{
                            backgroundColor: 'yellow',
                            width: 226,
                            color: 'black'
                          }}
                          size="large"
                          className={`${styles.logoutButton} mt-4 me-5`}
                          onClick={() => handleConfirmation('rejectArrive')}
                        >
                          REJEITAR SUBIDA
                          <BlockIcon className="ms-2" />
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              )}
              <div className='d-flex w-100 justify-content-center mt-3'>
                {confirmingDeparture && (
                  <Button
                    variant="contained"
                    color="success"
                    sx={{
                      width: 226,
                    }}
                    size="large"
                    className={`${styles.logoutButton} mt-3 `}
                    onClick={() => handleConfirmation(typeConfirmation)}
                  >
                    CONFIRMAR
                    <CheckIcon className="ms-2" />
                  </Button>
                )}
              </div>
            </>
          )}
        </div>
    )
  }

  return (
      <div className="page">
          {/* <OperationHeader
            title=""
            description=""
            onClick_ShowDefinedPartner={props.onClick_ShowDefinedPartner}
          /> */}
          {viewPage()}
      </div>
  )
}

export default Reservation
