import React, { useState } from 'react'

import { Button, TextField } from '@mui/material'

import { createTheme, ThemeProvider } from '@mui/material/styles'

import { useSnackbar } from 'notistack'
import styles from './CheckInConfirmationModal.module.scss'
import NTSModal from '../../../../components/modal/Modal'
import lclService from '../../../../services/lcl.service'
import { isEmpty } from '../../../../utilities/validators'
import { cpf } from 'cpf-cnpj-validator'
import { NumberFormatDocument } from '../../../../utilities/NumberFormat'

const theme = createTheme({
    palette: {
        primary: {
            main: '#3366FF'
        },
        secondary: {
            main: '#4E5F78'
        },
        mode: 'light'
    },
    typography: {
        fontFamily: ['Montserrat', 'sans-serif'].join(',')
    }
})

export interface ICheckInConfirmationModalProps {
    open: boolean,
    handleClose: any,
    height: any,
    onLogout?: any,
    onCancelReservation?: any,
    onCancel?: any,
    onConfirm?: any,
    onCheckIn?: any,
    width: any,
    type?: string,
    reservation: string,
    owners: any[],
    conductor: string
}

const CheckInConfirmationModal: React.FC<ICheckInConfirmationModalProps> = (props: ICheckInConfirmationModalProps) => {
        
    const [ success, setSuccess ] = useState(false)
    const [ confirming, setConfirming ] = useState(false)
    const [ canceledReservation, setCanceledReservation ] = useState(false)
    const [ departureRequested, setDepartureRequested ] = useState(false)
    const [ ownerDocument, setOwnerDocument ] = useState('')
    const { enqueueSnackbar } = useSnackbar()

    const cancel = () => {
        props.onCancel()
        setOwnerDocument('')
    }

    const getTitle = () => {
        switch (props.type) {
        case 'confirm':
            return 'Confirmar Check-In'
        case 'requestDeparture':
            return 'Solicitar descida'
        case 'cancelCheckin':
            return 'Cancelar Check-in'
        case 'cancel':
            return 'Cancelar agendamento'
        case 'logout':
            return 'Sair'
        default:
            return ''
        }
    }

    const getConfirmation = () => {
        switch (props.type) {
            case 'confirm':
                return `Para confirmar o Check-in do localizador ${props.reservation} digite o CPF do responsável:`
            case 'requestDeparture':
                return `Para solicitar a descida do localizador ${props.reservation} digite o CPF do responsável:`
            case 'cancelCheckin':
                return `Para cancelar o Check-in do localizador ${props.reservation} digite o CPF do responsável:`
            case 'cancel':
                return `Para cancelar o agendamento do localizador ${props.reservation} digite o CPF do responsável:`
            case 'logout':
            return `${
                props.reservation === '4RE47A'
                    ? 'Deseja sair e cancelar o processo de solicitar descida?'
                    : 'Deseja sair e cancelar o processo de check-in?'
                }`
        default:
            return ''
        }
    }

    const getTextButton = () => {
        switch (props.type) {
            case 'confirm':
                return 'Confirmar Check-In'
            case 'requestDeparture':
                return 'Solicitar descida'
            case 'cancelCheckin':
                return 'Cancelar Check-in'
            case 'cancel':
                return 'Cancelar agendamento'
            case 'logout':
                return 'Sair'
        default:
            return ''
        }
    }

    const handleConfirmation = () => {
        switch (props.type) {
            case 'logout':
                props.onLogout()
                break
            case 'cancel':
                cancelReservation()
                break
            case 'confirm':
                confirm()
                break
            case 'requestDeparture':
                requestDeparture()
                break
            case 'cancelCheckin':
                cancelCheckin()
                break
        default:
            break
        }
    }

    const requestDeparture = async () => {
        setConfirming(true)
        const exists = props.owners.filter((item: any) => {
            return item.owner.registration === ownerDocument
        })
        if (exists.length > 0) {
            const movement = await lclService.getByLocator(props.reservation)
            if (!isEmpty(movement)) {
                await lclService.updateMove('', props.reservation, 1, 1)
            }
            setDepartureRequested(true)
            setSuccess(true)
            setConfirming(false)
        } else {
            enqueueSnackbar(
                `CPF não corresponde com o localizador ${props.reservation}. Confira os dados e tente novamente.`,
            {
                variant: 'error'
            }
            )
            setConfirming(false)
        }
    }

    const cancelCheckin = async () => {
        setConfirming(true)
        const exists = props.owners.filter((item: any) => {
            return item.owner.registration === ownerDocument
        })
        if (exists.length > 0) {
            const movement = await lclService.getByLocator(props.reservation)
            if (!isEmpty(movement)) {
            await lclService.updateMove('', props.reservation, 0, 1)
            await lclService.updateCheckIn(
                props.reservation,
                'canceled',
                ownerDocument
            )
            }
            // setDepartureRequested(true)
            setSuccess(true)
            setConfirming(false)
        } else {
            enqueueSnackbar(
            `CPF não corresponde com o localizador ${props.reservation}. Confira os dados e tente novamente.`,
            {
                variant: 'error'
            }
            )
            setConfirming(false)
        }
    }

    const confirm = async () => {
        if (props.type === 'cancel') {
            props.onConfirm()
        }
        setConfirming(true)

        const exists = props.owners.filter((item: any) => {
            return item.owner.registration === ownerDocument
        })
        if (exists.length > 0) {
            props.onCheckIn(ownerDocument)
            setSuccess(true)
            setConfirming(false)
        } else {
            enqueueSnackbar(
            `CPF não corresponde com o localizador ${props.reservation}. Confira os dados e tente novamente.`,
            {
                variant: 'error'
            }
            )
            setConfirming(false)
        }
    }

    const cancelReservation = async () => {
        setConfirming(true)

        const exists = props.owners.filter((item: any) => {
            return item.owner.registration === ownerDocument
        })
        if (exists.length > 0) {
            props.onCancelReservation(ownerDocument)
            setCanceledReservation(true)
            setSuccess(true)
            setConfirming(false)
        } else {
            enqueueSnackbar(
            `CPF não corresponde com o localizador ${props.reservation}. Confira os dados e tente novamente.`,
            {
                variant: 'error'
            }
            )
            setConfirming(false)
        }
    }

    const handleKeypress = (e: any) => {
        if (e.charCode === 13) {
        handleConfirmation()
        }
    }

    const handleClickDepartureRequested = (e: any) => {
        e.preventDefault()
        lclService.updateMove('', props.reservation, 1, 1).then(() => {
        setDepartureRequested(true)
        })
    }

    return (
        <ThemeProvider theme={theme}>
            <NTSModal
                open={props.open}
                name={
                    success && departureRequested
                    ? 'Descida solicitada'
                    : success && !canceledReservation
                    ? 'Check-in realizado'
                    : success && canceledReservation
                    ? 'Reserva cancelada'
                    : getTitle()
                }
                handleClose={props.handleClose}
                noClose
                width={success ? 500 : props.width}
                onSuccess={success}
                height={props.height}
            >
            {success ? (
            <div className="d-flex align-items-center flex-column">
                <h5 className="mt-2 gray-text">{props.reservation}</h5>
                <h5 className="gray-text">{props.conductor}</h5>
                <h6 className="text-center mt-3">
                {departureRequested && success
                    ? 'Descida solicitada com sucesso!'
                    : canceledReservation && success
                    ? `Reserva cancelada com sucesso. Se desejar fazer uma nova reserva, acesse o botão "Agendamento" na tela inicial.`
                    : 'Check-in realizado com sucesso! Gostaria de descer a embarcação agora?'}
                </h6>
                {departureRequested && success && (
                <h6>Acompanhe a fila de movimentação.</h6>
                )}
                {departureRequested && success && <h6>Navegue com Segurança!</h6>}

                <div className="justify-content-center mt-2 d-flex flex-column">
                {!departureRequested && !canceledReservation && (
                    <>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={e => handleClickDepartureRequested(e)}
                        className={`${styles.submit} mb-2`}
                        size="large"
                    >
                        Descer agora
                    </Button>
                    <Button
                        color="inherit"
                        variant="outlined"
                        onClick={() => props.onConfirm()}
                        className={`${styles.submit} mb-2 mt-3`}
                        size="large"
                    >
                        Descer depois
                    </Button>
                    <p className="text-center">
                        Para solicitar a descida posteriormente, acesse novamente
                        com seu localizador na tela inicial.
                    </p>
                    </>
                )}
                {props.type === 'cancel' && (
                    <div className="d-flex flex-column align-items-center mb-2">
                    <h6 className="gray-text bolder">Data | Hora</h6>
                    <h5>
                        {new Date().toLocaleDateString()} -{' '}
                        {new Date().toLocaleTimeString()}
                    </h5>
                    </div>
                )}
                </div>
                {props.type === 'cancel' && (
                <Button
                    color="inherit"
                    variant="outlined"
                    onClick={() => props.onConfirm()}
                    className={`${styles.submit} mb-2 mt-1`}
                    size="large"
                >
                    FECHAR
                </Button>
                )}
                {departureRequested && (
                <>
                    <h6 className="gray-text bolder">Data | Hora</h6>
                    <h5 className="mb-3">
                    {new Date().toLocaleDateString()} -{' '}
                    {new Date().toLocaleTimeString()}
                    </h5>
                    <Button
                    color="inherit"
                    variant="outlined"
                    onClick={() => props.onConfirm()}
                    className={`${styles.submit} mb-2 mt-1`}
                    size="large"
                    >
                    FECHAR
                    </Button>
                </>
                )}
                {props.type !== 'cancel' && !departureRequested && (
                <>
                    <h6 className="gray-text mt-3 bolder">Data | Hora</h6>
                    <h5>
                    {new Date().toLocaleDateString()} -{' '}
                    {new Date().toLocaleTimeString()}
                    </h5>
                </>
                )}
            </div>
            ) : (
            <div className="d-flex align-items-center flex-column">
                <h6 className="gray-text text-center">{getConfirmation()}</h6>
                {props.type !== 'logout' && (
                <TextField
                    margin="normal"
                    name="document"
                    type="text"
                    id="document"
                    autoFocus
                    variant="standard"
                    autoComplete="off"
                    onKeyPress={handleKeypress}
                    onChange={e => setOwnerDocument(e.target.value)}
                    value={ownerDocument}
                    error={
                    (ownerDocument.length === 11 &&
                        !cpf.isValid(ownerDocument)) ||
                    ownerDocument.length > 11
                    }
                    label="CPF"
                    disabled={confirming}
                    color="primary"
                    className="mt-3 mb-0"
                    sx={{ minWidth: 325, maxWidth: 325 }}
                    helperText={
                    ownerDocument.length === 11 && !cpf.isValid(ownerDocument)
                        ? 'Digite um CPF válido'
                        : ''
                    }
                    InputProps={{
                    inputComponent: NumberFormatDocument as any
                    }}
                />
                )}
                <div className="mt-4 justify-content-center d-flex">
                <Button
                    variant="outlined"
                    onClick={() => cancel()}
                    color="secondary"
                    className={`${styles.submit} me-4`}
                    disabled={confirming}
                    size="large"
                >
                    Cancelar
                </Button>
                <Button
                    disabled={
                        props.type !== 'logout' &&
                    (confirming ||
                        ownerDocument.length < 11 ||
                        ownerDocument.length > 11 ||
                        (ownerDocument.length === 11 &&
                        !cpf.isValid(ownerDocument)))
                    }
                    variant="outlined"
                    color="primary"
                    size="large"
                    className={`${styles.submit}`}
                    onClick={() => handleConfirmation()}
                >
                    {!confirming ? getTextButton() : 'Aguarde...'}
                </Button>
                </div>
            </div>
            )}
        </NTSModal>
        </ThemeProvider>
    )
}

CheckInConfirmationModal.defaultProps = {
    onCancel: false,
    onCancelReservation: false,
    onConfirm: false,
    type: ''
}

export default CheckInConfirmationModal
