import { useEffect, useState } from "react";

import { Container, Image } from "react-bootstrap";
import DashboardHeader from "../headers/dashboard.header";
import Loader from "../../components/loader/loader";

import { useMediaContext } from "../../contexts/media.context";
import styles from './CleaningDashboardV2.module.scss';
import { ClockAndClimate } from "./components/ClockAndClimate";
import { Avatar, Box, Card, CardContent, CardHeader, Icon, IconButton, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridMoreVertIcon, GridValueGetterParams } from '@mui/x-data-grid';
import { ResumeBox, ResumeBoxCard } from "./components/ResumeBox";

import Jet from '../../assets/Jetski.svg';
import Lancha from '../../assets/Lancha.svg';
import Navegando from '../../assets/Navegando.svg';
import Abastecimento from '../../assets/Abastecimento.svg';
import Manutenca0_externa from '../../assets/Manutenca0_externa.svg';
import Movimento_entrada from '../../assets/Movimento_entrada.svg';
import Movimento_saida from '../../assets/Movimento_saida.svg';
import Higiene from '../../assets/Limpeza.svg';
import selo from '../../assets/selo.png';

import Users from '../../assets/Users.svg';
import ClockHistory from '../../assets/ClockHistory.svg';
import Wash from '../../assets/Wash.svg';
import SportsScore from '../../assets/SportsScore.svg';
import { ArrowOutward, AspectRatio, GetApp, Launch, ResetTv, SettingsOverscan, Upload, WebAsset, WebAssetOff } from "@mui/icons-material";
import { useTranslateContext } from "../../contexts/translate.context";
import { usePartnerContext } from "../../contexts/partner.context";
import { useAlertMessageContext } from "../../contexts/alert-message.context";
import { useNavigate } from "react-router-dom";
import { notNullToDate, toInt } from "../../utilities/auxiliary-functions";
import { CardDataGrid } from "./components/CardDataGrid";
import { STATUS_CLEAR_AWAITING, STATUS_CLEAR_FINISHED, STATUS_CLEAR_PENDING, STATUS_CLEAR_WORKING, VESSEL_JETSKI, VESSEL_LANCHA } from "../../utilities/constants";
import { AlertMessageEnum, getNamesStatusClear } from "../../utilities/types";
import { TFilter } from "../../types/FilterType";
import { removeFormatDate } from "../../utilities/masks";
import { isEmpty } from "../../utilities/validators";
import lclService from "../../services/lcl.service";

export interface ICleaningDashboardV2Props {
    
}

const CleaningDashboardV2: React.FC<ICleaningDashboardV2Props> = (props: ICleaningDashboardV2Props) => {
    
    const { isTv } = useMediaContext();

    const { display } = useTranslateContext();
    const { partner } = usePartnerContext();

    const alertContext = useAlertMessageContext();
    const navigator = useNavigate();

	const title = 'Gestão Limpeza';
	const description = "";

    let reloadPage: boolean = false;
    const [ loading, setLoading ] = useState(false);
    const [ gridAsset, setGridAsset ] = useState(false);

    const [ working, setWorking ] = useState(0);
    const [ finished, setFinished ] = useState(0);
    const [ fuelled, setFuelled ] = useState(0);

	const [ vessels, setVessels ] = useState<{jets: number, lanchas: number}>();

    const [ isChangedReload, setChangedReload ] = useState(false);
    const [ isChangeMaximized, setChangeMaximized ] = useState(false);
    // const [ intervalTimeGrid, setIntervalTimeGrid ] = useState<NodeJS.Timer>();
    const [ timeGridReload, setTimeGridReload ] = useState(0);

    const orderColumns: GridColDef[] = [
        { field: 'dateOperation', headerName: 'Data | Hora', width: 110, align: 'center', headerAlign: 'center', renderCell: renderDateTime },
        { field: 'identifyKey', headerName: 'Nome | ID', flex: 1, align: 'center', headerAlign: 'center', renderCell: renderNameAndId },
        { field: 'vessel.enrollment', headerName: display.label.enrollment, flex:1, align: 'center', headerAlign: 'center', valueGetter: getVesselEnrollment },
        { field: 'vessel.typeVessel', headerName: 'Tipo | Cor',flex:1,  align: 'center', headerAlign: 'center', renderCell: renderTypeAndColor },
        { field: 'vessel.brand', headerName: 'Mar | Mod',flex:1,  align: 'center', headerAlign: 'center', renderCell: renderBrandAndModel },
        { field: 'status', headerName: 'Status',flex:1,  align: 'center', headerAlign: 'center', renderCell: renderStatus }
    ];
    const [ orders, setOrders ] = useState([] as any);
        
    function renderDateTime(param: any) {
        const record = param.row;

        return (
            <div className="d-flex flex-column align-items-center">
                <div className={`mt-2 ${styles.info}`}>{new Date(record.dateOperation).toLocaleDateString('pt-BR')}</div>
                <div className={`mt-1 ${styles.info}`}>{new Date(record.dateOperation).toLocaleTimeString('pt-BR')}</div>
            </div>
        )
    }

    function renderNameAndId(param: any) {
        const {vessel} = param.row;

        return (
            <div className="d-flex flex-column align-items-center justify-content-center">
                <div className="white-space mt-2">{(vessel.name) ? vessel.name : '-'}</div>
                <div className="white-space mt-1">{vessel.identifyKey}</div>
            </div>
        );
    }

    function getVesselEnrollment(param: any) {
        const record = param.row;

        return record.vessel?.enrollment || '-';
    }

    function renderTypeAndColor(param: any) {
        const {vessel} = param.row;

        return toInt(vessel?.typeVessel) === VESSEL_LANCHA ? (
            <div className="d-flex flex-column align-items-center justify-content-center mb-2">
                <div className="mt-3 d-flex justify-content-center mb-2">
                    <Image src={Lancha} className="jet" style={{height: 32}}/>
                    <small className="ms-2">{`${vessel?.vesselSize}'`}</small>
                </div>
                <span className={styles.vesselColor} style={{backgroundColor: `${vessel?.color}`}}></span>
            </div>
        ):(
            <div className="d-flex flex-column align-items-center justify-content-center mb-2">
                <div className="d-flex justify-content-center mb-2">
                    <Image src={Jet} className="jet mt-3" style={{height: 32}}/>
                    <small className="ms-1 mt-3">{`10'`}</small>
                </div>
                <span className={styles.vesselColor} style={{backgroundColor: `${vessel?.color}`}}></span>
            </div>
        );
    }

    function renderBrandAndModel(param: any) {
        const {vessel} = param.row;

        return (
            <div className="d-flex flex-column justify-content-center mb-2">
                <div className="mt-3">{vessel?.brand || '-'}</div>
                <div className="mt-1">{vessel?.model || '-'}</div>
            </div>
        );
    }

    function renderStatus(param: any) {
        const record = param.row;

        return  (
            <div className="d-flex flex-column align-items-center">
                <span className={`${styles.orderStatus} 
                    ${toInt(record.status) === STATUS_CLEAR_AWAITING ? styles.awaiting : ''}
                    ${toInt(record.status) === STATUS_CLEAR_WORKING ? styles.working : ''}
                    ${toInt(record.status) === STATUS_CLEAR_FINISHED ? styles.finished : ''}
                    ${toInt(record.status) === STATUS_CLEAR_PENDING ? styles.pending : ''}`}
                >
                    {getNamesStatusClear(toInt(record.status))}
                </span>
            </div>
        )
    }

    async function searchFilter() {
        let date = new Date();
        let baseDate = notNullToDate(date, "dd/mm/yyyy");

        /* Sempre incluir o partner na pesquisa */
        if (!isEmpty(partner)) {
            try {
                const filterMaintenance = {
                    attributes: [
                        'id', 'dateOperation', 'operation', 'clean', 'sanitize', 'sweeten', 'polish', 'status', 'credential', 'additionalInfo',
                        'partnerId',
                        'movementVessel.id', 'movementVessel.dateMovement', 'movementVessel.locator',
                        'vessel.id', 'vessel.identifyKey', 'vessel.name', 'vessel.enrollment', 'vessel.typeVessel', 'vessel.vesselSize', 'vessel.color', 'vessel.quantityPeople', 'vessel.brand', 'vessel.model',
                    ],
                    where: {
                        partnerId: partner.id,
                        dateOperation: { 
                            start: removeFormatDate(baseDate + " 00:00:00"),
                            end: removeFormatDate(baseDate + " 23:59:59")
                        }
                    },
                    order: [ [ 'dateOperation', 'ASC' ] ],
                } as TFilter;
                const vesselMaintenanceFilter = await lclService.vesselMaintenanceFilter(filterMaintenance);
                const maintenanceIds = vesselMaintenanceFilter.rows.map((record: any) => record.id )
                const filterSupply = {
                    attributes: [
                        'maintenanceId', 'fuelType', 'fuelInfo', 'hours', 'liters', 'amount'
                    ],
                    where: {
                        partnerId: partner.id,
                        maintenanceId: { in: maintenanceIds }
                    },
                    order: [ [ 'dateOperation', 'ASC' ] ]
                } as TFilter;
                const vesselSupplyFilter = await lclService.vesselSupplyFilter(filterSupply);

                const orderFilter = [] as any;
                vesselMaintenanceFilter.rows.forEach((maintenance: any) => {
                    let order = {
                        id : maintenance.id,
                        dateOperation : maintenance.dateOperation,
                        operation : maintenance.operation,
                        partnerId: partner.id,
                        movementVesselId: maintenance.movementVessel.id,
                        vesselId: maintenance.vessel.id,
                        movementVessel : maintenance.movementVessel,
                        vessel : maintenance.vessel,
                        clean : maintenance.clean,
                        sanitize : maintenance.sanitize,
                        sweeten : maintenance.sweeten,
                        polish : maintenance.polish,
                        status : maintenance.status, 
                        credential : maintenance.credential,
                        additionalInfo : maintenance.additionalInfo
                    } as any;

                    let supply = vesselSupplyFilter.rows.filter((supply: any) => supply.maintenanceId === maintenance.id)[0];
                    order = {
                        ...order,
                        fuelType : supply?.fuelType || 0,
                        fuelInfo : supply?.fuelInfo || 0,
                        hours : supply?.hours || 0,
                        liters : supply?.liters || 0,
                        amount : supply?.amount || 0
                    }

                    orderFilter.push(order);
                });

                setVessels(separateVessels(orderFilter))

                const working = orderFilter.filter((record: any) => toInt(record.status) === STATUS_CLEAR_WORKING)?.length || 0;
                setWorking(working)
                const finished = orderFilter.filter((record: any) => toInt(record.status) === STATUS_CLEAR_FINISHED)?.length || 0;
                setFinished(finished)
                const fuelled = orderFilter.filter((record: any) => toInt(record.status) === STATUS_CLEAR_FINISHED && toInt(record.fuelType) > 0)?.length || 0;
                setFuelled(fuelled)

                setOrders(orderFilter);
            } catch(error: any) {
                await alertContext.show(AlertMessageEnum.FAIL, title, error);
            } finally {
                setLoading(false);
            }
        } else {
            await alertContext.show(AlertMessageEnum.FAIL, title, 'Não foi definido uma Marina para exibir o cadastro !');
            setLoading(false);
            navigator(`/portal`);
        }

        if (timeGridReload === 0) {
            setChangedReload(true);
            setTimeGridReload(1);
        }
    } // searchFilter

    const separateVessels = (groups: Array<any>) => {
        const jets = groups?.filter(group => toInt(group.vessel?.typeVessel) === VESSEL_JETSKI)?.length || 0
        const lanchas = groups?.filter(group => toInt(group.vessel?.typeVessel) === VESSEL_LANCHA)?.length || 0

        return {
            jets, lanchas
        }
    }

    function onClick_GridRefresh() {
        searchFilter();
    }

    function onChange_GridReload(minute: number) {
        setChangedReload(true);
        setTimeGridReload(minute);
    }

    function onChange_Maximized(isMaximized: boolean) {
        setChangeMaximized(isMaximized);
    }

    function onClick_GridAsset(event: any) {
        setGridAsset(!gridAsset);
    }

    useEffect(() => {
        if (isChangedReload) {
            setChangedReload(false);

            const time = timeGridReload * (60 * 1000);
            const internal = setInterval(() => {
                searchFilter();
            }, time);
            return () => clearInterval(internal); 
        }
    }, [timeGridReload]);

    useEffect(() => {
        if (!reloadPage) {
            reloadPage = true;
            setLoading(true);
            searchFilter().finally(() => {
                setLoading(false);
            });
        }
    }, []);

    function viewDashboard() {
        return (
            <Container fluid className="page-body dashboards">
                <div className={styles.mainTitle}>
                    <h1>Limpeza e Higienização</h1>
                </div>
                <div className={styles.mainInfo}>
                    <ClockAndClimate />
                </div>
                <ResumeBox>
                    <ResumeBoxCard label="EMBARCAÇÕES" datas={[{ image: Lancha, value: String(vessels?.jets || 0) }, { image: Jet, value: String(vessels?.lanchas || 0) }]} />
                    <ResumeBoxCard label="ANDAMENTO" image={Wash} value={String(working)} />
                    <ResumeBoxCard label="FINALIZADOS" image={SportsScore} value={String(finished)}/>
                    <ResumeBoxCard label="ABASTECIDOS" image={Abastecimento} value={String(fuelled)} />
                </ResumeBox>
                <CardDataGrid
                    columns={orderColumns}
                    rows={orders}
                    onClickRefresh={onClick_GridRefresh}
                    onChangeReload={onChange_GridReload}
                    onChangeMaximized={onChange_Maximized}
                />
            </Container>
        );
    }

    return (
        <div className="dashboard">
            <DashboardHeader title={title} description={description} />
            {loading ? <Loader /> : viewDashboard()}
        </div>
    );
}

export default CleaningDashboardV2;
