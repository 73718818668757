import { ThemeProvider, createTheme } from "@mui/material/styles"
import { useEffect, useState } from "react"
import NTSModal from "../../../components/modal/Modal"
import { AlertMessageEnum, getNamesStatusClear } from "../../../utilities/types"
import styles from './CleaningModal.module.scss'

import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'
import { Button, FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, Switch, TextField, TextareaAutosize, Tooltip } from '@mui/material'
import { Image } from "react-bootstrap"
import Jet from '../../../assets/Jetski.svg'
import Lancha from '../../../assets/Lancha.svg'
import { useAlertMessageContext } from "../../../contexts/alert-message.context"
import cduService from "../../../services/cdu.service"
import lclService from "../../../services/lcl.service"
import { toInt } from "../../../utilities/auxiliary-functions"
import { STATUS_CLEAR_AWAITING, STATUS_CLEAR_FINISHED, STATUS_CLEAR_PENDING, STATUS_CLEAR_WORKING, VESSEL_JETSKI, VESSEL_LANCHA } from "../../../utilities/constants"
import { isEmpty } from "../../../utilities/validators"
import { TFilter } from "../../../types/FilterType"

type ModalProps = {
    open: boolean
    handleClose: any
    height: any
    onCancel?: any
    onConfirm?: any
    width: any
    type?: string
    order: any
}

const theme = createTheme({
    palette: {
      primary: {
        main: '#3366FF'
      },
      secondary: {
        main: '#4E5F78'
      },
      mode: 'light'
    },
  
    typography: {
      fontFamily: ['Montserrat', 'sans-serif'].join(',')
    },
    components: {
      MuiTooltip: {
          styleOverrides: {
              tooltip: {
                  backgroundColor: 'white',
                  color: 'rgba(0, 0, 0, 0.87)',
                  fontSize: 14,
                  textAlign: 'center'
              }
          }
      }
  },
})
  
const MenuProps = {
    PaperProps: {
        style: {
            width: 225,
            maxHeight: 500
        }
    }
}

const CleaningModal: React.FC<ModalProps> = ({
    open,
    handleClose,
    height,
    onCancel,
    onConfirm,
    width,
    type,
    order,
}: ModalProps) => {

    const alertContext = useAlertMessageContext();
    
    const jetFuelOptions = [{
            label: 'Gas. Comum - R$ 3,59',
            value: 'COMUM'
        },{
            label: 'Gas. Aditivada - R$ 3,99',
            value: 'ADITIVADA'
    }];

    const fuelOptions = [{
            label: 'Gas. Comum - R$ 3,59',
            value: 'COMUM'
        },{
            label: 'Gas. Aditivada - R$ 3,99',
            value: 'ADITIVADA'
        },{
            label: 'Diesel - R$ 3,20',
            value: 'DIESEL'
    }];

    const [ garagePosition, setGaragePosition ] = useState([] as any);

    const [ confirming, setConfirming ] = useState(false);
    const [ showFuelOptions, setShowFuelOptions ] = useState(false);
    const [ showAdditionalInfo, setShowAdditionalInfo ] = useState(false);
    const [ selectedFuel, setSelectedFuel ] = useState({
        fuel: '',
        amount: '1',
        hours: '0'
    });

    const [ orderNew, setOrderNew ] = useState({} as any);
    const [ credential, setCredential ] = useState('');
    const [ isClean, setIsClean ] = useState(true);
    const [ isSweeten, setIsSweeten ] = useState(true);
    const [ isSanitize, setIsSanitize ] = useState(true);
    const [ isPolish, setIsPolish ] = useState(false);
    
    const [ fuelType, setFuelType ] = useState(0);
    const [ fuelInfo, setFuelInfo ] = useState(0);
    const [ hours, setHours ] = useState(0);
    const [ liters, setLiters ] = useState(0);
    const [ amount, setAmount ] = useState(0);

    const [ selectedGaragePosition, setSelectedGaragePosition ] = useState('');
    const [ fuellingType, setFuellingType ] = useState('amount');
    const [ additionalInfo, setAdditionalInfo ] = useState('');
  
    const accept = async () => {
        if (!isEmpty(credential)) {
            const employee = await cduService.employeeByCredential(credential);
            if (!isEmpty(employee)) {
                if (toInt(order?.status) === STATUS_CLEAR_PENDING) {
                    await lclService.vesselMaintenanceStatus(order?.id, credential, String(STATUS_CLEAR_AWAITING));
                    confirmAccept();
                } else if (toInt(order?.status) === STATUS_CLEAR_AWAITING) {
                    await lclService.vesselMaintenanceStatus(order?.id, credential, String(STATUS_CLEAR_WORKING));
                    confirmAccept();
                } else if (toInt(order?.status) === STATUS_CLEAR_WORKING) {
                    const orderNew = { 
                        id: order.id,
                        dateOperation: order.dateOperation,
                        operation: order.operation,
                        partnerId: order.partnerId,
                        movementVesselId: order.movementVesselId,
                        vesselId: order.vesselId,
                        movementVessel: order.movementVessel,
                        vessel: order.vessel,
                        clean: isClean,
                        sanitize: isSanitize,
                        sweeten: isSweeten,
                        polish: isPolish,
                        status: String(STATUS_CLEAR_FINISHED),
                        credential,
                        additionalInfo,
                        fuelType,
                        fuelInfo,
                        hours,
                        liters,
                        amount
                    };

                    await lclService.vesselMaintenanceStatus(orderNew?.id, orderNew.credential, String(STATUS_CLEAR_FINISHED), orderNew);
                    confirmAccept();
                } else {
                    cancel();
                    await alertContext.show(AlertMessageEnum.FAIL, '', 'Nao definido ainda');
                }
            } else {
                cancel();
                await alertContext.show(AlertMessageEnum.FAIL, '', 'Nao encontrei uma credencial associada');
            }
        }
    }

    const confirmAccept = () => {
        setShowFuelOptions(false)
        setSelectedFuel({
            fuel: '',
            amount: '0',
            hours: '0'
        })
        setCredential('')
        setSelectedGaragePosition('')
        setFuellingType('amount')
        setConfirming(false)
        setShowAdditionalInfo(false)
        onConfirm(order)
    }

    const cancel = () => {
        setShowFuelOptions(false)
        setSelectedFuel({
            fuel: '',
            amount: '0',
            hours: '0'
        })
        setCredential('')
        setSelectedGaragePosition('')
        setFuellingType('amount')
        setConfirming(false)
        setShowAdditionalInfo(false)
        onCancel()
    }

    const confirm = async () => {
        if (type === 'cancel') {
            onConfirm()
        }
    }

    const onChange_FuelInfo = (event: React.ChangeEvent<HTMLInputElement>) => {
        const info = (event.target as HTMLInputElement).value;

        setFuelInfo(info === 'value' ? 1 : 2)
        setFuellingType(info);
    };

    const onChange_Hours = (event: any) => {
        const value = event.target.value?.replace(/[^\d.-]+/g, '');
        setSelectedFuel({...selectedFuel, hours: value});
        setHours(value);
    }

    const onChange_FuelType = (event: any) => {
        const value = event.target.value;
        setSelectedFuel({...selectedFuel, fuel: value});
        setFuelType(value);
    }

    const onChange_Liters = (event: any) => {
        const value = event.target.value?.replace(/[^\d.-]+/g, '');
        setSelectedFuel({...selectedFuel, amount: value});
        setLiters(value);
    }

    const onChange_Amount = (event: any) => {
        const value = event.target.value?.replace(/[^\d.-]+/g, '');
        setSelectedFuel({...selectedFuel, amount: value});
        setAmount(value);
    }

    const handleConfirmation = () => {
        switch (type) {
            case 'confirm':
                confirm()
                break
            case 'finish':
                confirm()
                break
            default:
            break
        }
    }

    const handleKeypress = (e: any) => {
        if (e.charCode === 13) {
            handleConfirmation()
        }
    }

    const getTotalFuelPrice = () => {
        switch (selectedFuel.fuel) {
            case '':
                return '0,00'
            case 'COMUM':
                return Number(3.59 * Number(selectedFuel.amount)).toFixed(2).toString().replace('.', ',')
            case 'ADITIVADA':
                return  Number(3.99 * Number(selectedFuel.amount)).toFixed(2).toString().replace('.', ',')
            case 'DIESEL':
                return Number(3.20 * Number(selectedFuel.amount)).toFixed(2).toString().replace('.', ',')
            default:
            break;
        }
    }

    const getTotalFuelAmount = () => {
        switch (selectedFuel.fuel) {
            case '':
                return '0,00'
            case 'COMUM':
                return Number(Number(selectedFuel.amount) / 3.59).toFixed(2).toString().replace('.', ',')
            case 'ADITIVADA':
                return  Number(Number(selectedFuel.amount) / 3.99).toFixed(2).toString().replace('.', ',')
            case 'DIESEL':
                return Number(Number(selectedFuel.amount) / 3.20).toFixed(2).toString().replace('.', ',')
            default:
            break;
        }
    }
    
    const viewVessel = (order: any) =>  {

        if (toInt(order?.vessel.typeVessel) === VESSEL_LANCHA) {
            return (
                <div className="d-flex">
                    <div className="d-flex flex-column align-items-center justify-content-center mb-2">
                        <div className="mt-2 d-flex justify-content-center mb-2">
                            <Image src={Lancha} className="jet" style={{height: 32, filter: 'invert(1)'}}/>
                            <small className="ms-2">{`${order?.vessel.vesselSize}'`}</small>
                        </div>
                        <span className={styles.vesselColor} style={{backgroundColor: `${order?.vessel.color}`}}></span>
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-center ms-5 mt-2">
                        <h6 className={`${styles.info} white-space mt-2`}>{order?.vessel.brand || 'Sem marca infomada'}</h6>
                        <h6 className="white-space mt-1">{order?.vessel.model || 'Sem modelo informado'}</h6>
                    </div>
                </div>
            )
        }

        return (
            <div className="d-flex">
                <div className="d-flex flex-column align-items-center justify-content-center mb-2">
                    <div className="d-flex justify-content-center mb-2">
                        <Image src={Jet} className="jet mt-3" style={{height: 32, filter: 'invert(1)'}}/>
                        <small className="ms-1 mt-3">{`10'`}</small>
                    </div>
                    <span className={styles.vesselColor} style={{backgroundColor: `${order?.vessel.color}`}}></span>
                </div>
                <div className="d-flex flex-column align-items-center justify-content-center ms-5 mt-2">
                    <h6 className={`${styles.info} white-space mt-2`}>{order?.vessel.brand || 'Sem marca infomada'}</h6>
                    <h6 className="white-space mt-1">{order?.vessel.model || 'Sem modelo informado'}</h6>
                </div>
            </div>
        )
    }
    
    useEffect(() => {
        if (open) {
            lclService.drawerUnused(order?.partnerId).then((book: any) => {
                setGaragePosition(book);
            });
        }
    }, [open]); // useEffect

    return (
        <ThemeProvider theme={theme}>
            <NTSModal 
                open={open} 
                name={`Operação${!isEmpty(order?.operation) ? ` ${order?.operation}` : ''} - ${getNamesStatusClear(toInt(order?.status))}`} 
                handleClose={handleClose}
                noClose 
                width={showAdditionalInfo ? width + 450 : width} 
                height={height}
            >
                <div className={`d-flex ${!showAdditionalInfo ? 'align-items-center flex-column' : 'justify-content-between'} `}>
                    <div className={`${styles.content} d-flex align-items-center flex-column`}>
                        <div className={styles.order}>
                            <h4>Operação {order?.operation}</h4>
                            <h4>{new Date(order?.dateOperation).toLocaleDateString()} {new Date(order?.dateOperation).toLocaleTimeString()}</h4>
                            <div className={styles.orderInfo}>
                                <h6>Solicitante:</h6>
                                <h6>order?.responsibles[0]?.ownerName</h6>
                                <Tooltip title="(51) 99999-9999">
                                    <PhoneIphoneIcon sx={{ fontSize: 24, marginLeft: '10px', marginTop: '-5px'}} />
                                </Tooltip>
                            </div>
                            {viewVessel(order)}
                            {toInt(order?.status) !== STATUS_CLEAR_PENDING && toInt(order?.status) !== STATUS_CLEAR_AWAITING && (
                                <>
                                    {toInt(order?.status) === STATUS_CLEAR_FINISHED && (
                                        <h5 className={styles.itemsHeader}>Serviços executados</h5>
                                    )}
                                    {toInt(order?.status) !== STATUS_CLEAR_FINISHED && (
                                        <h5 className={styles.itemsHeader}>Serviços</h5>
                                    )}

                                    {toInt(order?.status) === STATUS_CLEAR_FINISHED && (
                                        <>
                                            <div className={styles.items}>
                                                <div className='d-flex flex-column justify-content-between mt-2'>
                                                    <p>- Adoçamento</p>
                                                    <p>- Limpeza</p>
                                                    <p>- Polimento</p>
                                                </div>
                                            </div>
                                            <h5 className={styles.itemsFooter}>Posição de guarda: 001 | 123</h5>
                                            <p className='mb-0'>Concluído em: {new Date().toLocaleString()}</p>
                                        </>
                                    )}
                                    {toInt(order?.status) === STATUS_CLEAR_WORKING && (
                                        <div className='w-100 d-flex flex-column align-items-center'>
                                            <h6>Selecione os serviços executados</h6>
                                            <div className='d-flex justify-content-between w-100 mb-2' style={{borderBottom: 'solid 1px black'}}>
                                                <div className='d-flex flex-column'>
                                                    <FormControlLabel control={<Switch checked={isClean} onChange={()=> setIsClean(!isClean)} />} label="Limpeza" />
                                                    <FormControlLabel control={<Switch checked={isSweeten} onChange={()=> setIsSweeten(!isSweeten)} />} label="Adoçamento" />
                                                </div>
                                                <div className='d-flex flex-column'>
                                                    <FormControlLabel control={<Switch checked={isSanitize} onChange={()=> setIsSanitize(!isSanitize)} />} label="Higiene" />
                                                    <FormControlLabel control={<Switch checked={isPolish} onChange={()=> setIsPolish(!isPolish)} />} label="Polimento" />
                                                </div>
                                            </div>
                                            <FormControlLabel 
                                                control={<Switch onChange={()=> setShowFuelOptions(!showFuelOptions)} />}
                                                label="Abastecimento" 
                                            />
                                            <div className='w-100 d-flex flex-column align-items-center'>
                                                {showFuelOptions && (
                                                    <>
                                                        <div className='d-flex justify-content-between w-100 align-items-start'>
                                                            <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label"
                                                                name="row-radio-buttons-group" 
                                                                onChange={onChange_FuelInfo} 
                                                                value={fuellingType}
                                                            >
                                                                <FormControlLabel value="amount" control={<Radio />} label="Quantidade" />
                                                                <FormControlLabel value="value" control={<Radio />} label="Valor" />
                                                            </RadioGroup>
                                                            <TextField margin="normal" name="hours" type="text" id="hours" variant="standard"
                                                                autoComplete="off" 
                                                                onChange={e=> onChange_Hours(e)}
                                                                value={selectedFuel.hours}
                                                                label="Reg. Horas"
                                                                disabled={confirming}
                                                                color="primary"
                                                                inputProps={{
                                                                    maxLength: 7
                                                                }}
                                                                className="mb-0 mt-0"
                                                                sx={{ minWidth: 100, maxWidth: 100 }}
                                                            />
                                                        </div>
                                                        <div className='d-flex justify-content-between w-100 mt-0 mb-0'>
                                                            <>
                                                                <FormControl variant="standard" className="mb-2" sx={{ width: 195 }} fullWidth>
                                                                    <InputLabel id="businessType">Combustível</InputLabel>
                                                                    {toInt(order?.typeVessel) === VESSEL_JETSKI && (
                                                                        <Select labelId="businessType" id="businessType-select" 
                                                                            value={selectedFuel?.fuel?.toUpperCase() || '' } 
                                                                            onChange={e => onChange_FuelType(e)}
                                                                            label="Combustível"
                                                                            MenuProps={MenuProps}
                                                                            autoFocus
                                                                        >
                                                                            {jetFuelOptions?.map((
                                                                                option: any,
                                                                                index: {
                                                                                    toString: () => React.Key | null | undefined
                                                                                }
                                                                            ) => (
                                                                                <MenuItem 
                                                                                    selected={ selectedFuel?.fuel.toUpperCase()===option.value }
                                                                                    key={index.toString()} value={option.value}
                                                                                >
                                                                                    {option.label}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    )}
                                                                    {toInt(order?.typeVessel) === VESSEL_LANCHA && (
                                                                        <Select labelId="businessType" id="businessType-select" 
                                                                            value={selectedFuel?.fuel?.toUpperCase() || '' } 
                                                                            onChange={e => onChange_FuelType(e)}
                                                                            label="Serviço"
                                                                            MenuProps={MenuProps}
                                                                            autoFocus
                                                                        >
                                                                            {fuelOptions?.map((
                                                                                option: any,
                                                                                index: {
                                                                                    toString: () => React.Key | null | undefined
                                                                                }
                                                                            ) => (
                                                                                <MenuItem selected={ selectedFuel?.fuel?.toUpperCase()===option.value }
                                                                                    key={index.toString()} value={option.value}
                                                                                >
                                                                                    {option.label}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    )}
                                                                </FormControl>
                                                                {fuellingType === 'amount' && (
                                                                    <TextField margin="normal" name="amount" type="text" id="amount" variant="standard"
                                                                        autoComplete="off" 
                                                                        onChange={e => onChange_Amount(e)}
                                                                        value={selectedFuel.amount}
                                                                        label="Quantidade"
                                                                        disabled={confirming}
                                                                        color="primary"
                                                                        InputProps={{
                                                                                endAdornment: 'Litros',
                                                                        }}
                                                                        inputProps={{
                                                                            maxLength: 7
                                                                        }}
                                                                        className="mb-2 mt-0"
                                                                        sx={{ minWidth: 100, maxWidth: 100 }}
                                                                    />
                                                                )}
                                                                {fuellingType === 'value' && (
                                                                    <TextField margin="normal" name="value" type="text" id="value" variant="standard"
                                                                        autoComplete="off" 
                                                                        onChange={e => onChange_Liters(e)}
                                                                        value={selectedFuel.amount}
                                                                        label="Valor"
                                                                        disabled={confirming}
                                                                        color="primary"
                                                                        InputProps={{
                                                                            endAdornment: 'R$  ',
                                                                        }}
                                                                        inputProps={{
                                                                            maxLength: 7
                                                                        }}
                                                                        className="mb-2 mt-0"
                                                                        sx={{ minWidth: 100, maxWidth: 100 }}
                                                                    />
                                                                )}
                                                            </>
                                                        </div>
                                                        {fuellingType === 'amount' && (
                                                            <h6 className='mt-2 mb-0'>Valor: R$ {getTotalFuelPrice()}</h6>
                                                        )}
                                                        {fuellingType === 'value' && (
                                                            <h6 className='mt-2 mb-0'>Litros: {getTotalFuelAmount()}</h6>
                                                        )}

                                                    </>
                                                )}
                                            </div>

                                        </div>
                                    )}
                                    {(toInt(order?.status) === STATUS_CLEAR_FINISHED) && (
                                        <p className='mb-0'>Operador: {order?.operator}</p>
                                    )}
                                </>
                            )}
                        </div>
                        {(toInt(order?.status) === STATUS_CLEAR_AWAITING || 
                            toInt(order?.status) === STATUS_CLEAR_WORKING ||
                            toInt(order?.status) === STATUS_CLEAR_PENDING) && (
                            <>
                                <p className='mt-4 mb-3 text-center'>{toInt(order?.status) === STATUS_CLEAR_WORKING ? 'Digite ou aproxime sua credencial' : 'Informe sua credencial e a posição de guarda'}</p>
                                <div className={`d-flex w-100 ${toInt(order?.status) === STATUS_CLEAR_WORKING ? 'justify-content-between': 'justify-content-center'}`}>
                                    <TextField margin="normal" name="credential" type="text" id="credential" autoFocus variant="standard"
                                        autoComplete="off" onChange={e=> setCredential(e.target.value)}
                                        value={credential}
                                        onKeyPress={handleKeypress}
                                        label="Credencial"
                                        disabled={confirming}
                                        color="primary"
                                        className="mb-0 mt-0"
                                        sx={{ minWidth: toInt(order?.status) === STATUS_CLEAR_WORKING ? 200 : 180, maxWidth: toInt(order?.status) === STATUS_CLEAR_WORKING ? 200 : 180 }}
                                    />
                                    {toInt(order?.status) === STATUS_CLEAR_WORKING && (
                                        <FormControl variant="standard" className="mb-0" sx={{ width: 180, marginTop: '-1px' }} fullWidth>
                                            <InputLabel id="businessType">Posição de guarda</InputLabel>
                                            <Select labelId="businessType" id="businessType-select" 
                                                value={ selectedGaragePosition || ''} 
                                                onChange={e => setSelectedGaragePosition(e.target.value)}
                                                label="Posição de guarda"
                                                MenuProps={MenuProps}
                                            >
                                                {garagePosition?.map((
                                                    option: any,
                                                    index: {
                                                        toString: () => React.Key | null | undefined
                                                    }
                                                ) => (
                                                    <MenuItem selected={ selectedGaragePosition === option.id } key={index.toString()}
                                                        value={option.id}
                                                    >
                                                        {`${option.shelfName} | ${option.name}`}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                </div>
                            </>
                        )}
                        <div className={`mt-3 d-flex mb-2 w-100 ${toInt(order?.status) === STATUS_CLEAR_AWAITING || toInt(order?.status) === STATUS_CLEAR_PENDING || toInt(order?.status) === STATUS_CLEAR_FINISHED ? 'justify-content-center' : 'justify-content-between'}`}>
                            <Button variant="outlined" onClick={()=> cancel()}
                                color="secondary"
                                className={`${styles.submit}`}
                                disabled={confirming}
                            >
                                Fechar
                            </Button>
                            {(toInt(order?.status) === STATUS_CLEAR_AWAITING || 
                                toInt(order?.status) === STATUS_CLEAR_PENDING) && (
                                <Button variant="contained" onClick={()=> accept()}
                                    color="success"
                                    className={`${styles.submit} ms-3`}
                                    disabled={credential.length < 5 }
                                >
                                    Aceitar
                                </Button>
                            )}
                            {toInt(order?.status) === STATUS_CLEAR_WORKING && (
                                <>
                                    <Button variant="contained" onClick={()=> accept()}
                                        color="success"
                                        className={`${styles.submit} ms-3`}
                                        disabled={
                                            credential.length < 5 ||  
                                            selectedGaragePosition === '' || 
                                            // (showFuelOptions && selectedFuel.fuel === '' && selectedFuel.amount !== '') || 
                                            // (showFuelOptions && selectedFuel.fuel !== '' && selectedFuel.amount === '') || 
                                            // (showFuelOptions && selectedFuel.fuel === '' && selectedFuel.amount === '') || 
                                            (showAdditionalInfo && additionalInfo.length < 10) || 
                                            selectedFuel.amount === '0'
                                    }>
                                        Finalizar
                                    </Button>
                                    <FormControlLabel className='me-0 ms-2 mb-0' 
                                        control={<Switch onChange={() => setShowAdditionalInfo(!showAdditionalInfo)} />} 
                                        label="Incluir observações"
                                    />
                                </>
                            )}
                        </div>
                    </div>
                    {showAdditionalInfo && (
                        <div>
                            <h5 className="mb-1">Informações adicionais sobre esta operação:</h5>
                            <TextareaAutosize
                                minRows={3}
                                value={additionalInfo}
                                onChange={e => setAdditionalInfo(e.target.value)}
                                style={{
                                    minHeight: 450,
                                    minWidth: '100%',
                                    // maxWidth: 640,
                                    maxHeight: 450,
                                    borderRadius: 6,
                                    marginTop: 5
                                }}
                                placeholder="(Mínimo 10 caracteres) Descreva caso haja algum dano estético e/ou de peças.
                                Exemplo: Risco no casco de aproximadamente 15cm no lado esquerdo. Motor apresenta avarias"
                                onResize={undefined}
                                onResizeCapture={undefined}
                            />
                        </div>
                    )}
                </div>
            </NTSModal>
        </ThemeProvider>
    )    

}

CleaningModal.defaultProps = {
    onCancel: false,
    onConfirm: false,
    type: ''
}

export default CleaningModal
