import { useEffect, useState } from "react";
import { Card, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { FaSave } from "react-icons/fa";
import { useAlertMessageContext } from "../../../contexts/alert-message.context";
import { useConfirmMessageContext } from "../../../contexts/confirm-message.context";
import srvService from "../../../services/srv.service";
import { dateToString, notNullToDate } from "../../../utilities/auxiliary-functions";
import { CategoryEnum } from "../../../utilities/constants";
import { floatMask, removeFormatDate, removeMask } from "../../../utilities/masks";
import { isEmpty } from "../../../utilities/validators";
import CategoryFilter from "../../filters/category.filter";
import ProductRecord from "../../records/product.record";
import { Button } from '@mui/material'
import { AlertMessageEnum } from "../../../utilities/types";
import { useTranslateContext } from "../../../contexts/translate.context";
import { usePartnerContext } from "../../../contexts/partner.context";

export interface IProductCadastreFormProps {
    title: string,
    productId: string,
    setRecord: any,
    viewOnly?: boolean
}

export const ProductCadastreForm: React.FC<IProductCadastreFormProps> = (props: IProductCadastreFormProps) => {

    const alertContext = useAlertMessageContext();
    const confirmContext = useConfirmMessageContext();
    const { display } = useTranslateContext();
    const { partner } = usePartnerContext();

    const [ invalidatedForm, setInvalidatedForm ] = useState(false);
    const [ isEditForm, setEditForm ] = useState(true);

    let reloadPage = false;
    const [ isLoading, setLoading ] = useState(false);
    const [ originRecord, setOriginRecord ] = useState({} as any);
    const [ sending, setSending ] = useState(false);

    /* TRATAMENTO PARTICULAR DO OBJETO QUE ESTA SENDO MANTIDO */

    const [ showFilterCategory, setShowFilterCategory ] = useState(false);
    const [ categoryInList, setCategoryInList ] = useState([] as any);

    // Stock
    const [ stockId, setStockId ] = useState(0);
    const [ created, setCreated ] = useState(dateToString(new Date(), 'dd/mm/yyyy HH:MM:ss'));
    const [ updated, setUpdated ] = useState(dateToString(new Date(), 'dd/mm/yyyy HH:MM:ss'));

    const [ productId, setProductId ] = useState(0);
    
    //const [ product: Product = new Product();
    const [ code, setCode ] = useState('');
    const [ mode, setMode ] = useState(CategoryEnum.PRODUCT);
    const [ barcode, setBarcode ] = useState('');
    const [ unit, setUnit ] = useState('4');
    const [ manufacturer, setManufacturer ] = useState('');
    const [ origin, setOrigin ] = useState('');
    const [ warranty, setWarranty ] = useState('');
    const [ norm, setNorm ] = useState('');
    const [ exchange, setExchange ] = useState('');
    const [ description, setDescription ] = useState('');
    const [ usability, setUsability ] = useState('');
    const [ reference, setReference ] = useState('');
    const [ codeNcm, setCodeNcm ] = useState('');
    const [ grossWeight, setGrossWeight ] = useState('');
    const [ netWeight, setNetWeight ] = useState('');
    const [ category, setCategory ] = useState({} as any);
    const [ image1, setImage1 ] = useState('');
    const [ image2, setImage2 ] = useState('');

    const [ control, setControl ] = useState(0);
    const [ notify, setNotify ] = useState(0);
    const [ sellZero, setSellZero ] = useState(0);
    const [ minimum, setMinimum ] = useState(0);
    const [ actual, setActual ] = useState(0);
    const [ movement, setMovement ] = useState(dateToString(new Date(), 'dd/mm/yyyy HH:MM:ss'));
    const [ purchase, setPurchase ] = useState('');
    const [ margin, setMargin ] = useState('');
    const [ sale, setSale ] = useState('');
    const [ comments, setComments ] = useState('');
    
    function fillRecord(stock: any) {

        if (!isEmpty(stock)) {
            setStockId(stock.id);
            setProductId(stock.productId);

            const product = stock.product;
            setCode(product.code);
            // setMode(product.mode);
            setBarcode(product.barcode);
            setUnit(product.unit);
            setManufacturer(product.manufacturer);
            setOrigin(product.origin);
            setWarranty(product.warranty);
            setNorm(product.norm);
            setExchange(product.exchange);
            setDescription(product.description);
            setUsability(product.usability);
            setReference(product.reference);
            setCodeNcm(product.codeNcm);
            setGrossWeight(product.grossWeight);
            setNetWeight(product.netWeight);
            setCategory(product.category);
            setImage1(product.image1);
            setImage2(product.image2);

            setPurchase(floatMask(product.purchase));
            
            setControl(stock.control);
            setNotify(stock.notify);
            setSellZero(stock.sellZero);
            setMinimum(stock.minimum);
            setActual(stock.actual);
            setMovement(dateToString(isEmpty(stock.movement) ? new Date() : stock.movement, 'dd/mm/yyyy'));
            setMargin(floatMask(stock.margin));
            setSale(floatMask(stock.sale));
            setComments(stock.comments);

            setCreated(notNullToDate(stock.created, 'dd/mm/yyyy HH:MM:ss'));  
            setUpdated(notNullToDate(stock.updated, 'dd/mm/yyyy HH:MM:ss'));  
        }
    } // fillRecord

    async function isValidForm() {
        let result = true;
        let emptyRequired = false;

        if (isEmpty(code))
            emptyRequired = true;
        //if (isEmpty(description))
        //    emptyRequired = true;
        if (isEmpty(sale))
            emptyRequired = true;

        if (emptyRequired) {
            await alertContext.show(AlertMessageEnum.FAIL, props.title, display.message.invalid.required);
            result = false;
        }

        if (!emptyRequired && isEmpty(category)) {
            await alertContext.show(AlertMessageEnum.FAIL, props.title, 'Informe uma categoria para o produto');
            result = false;
        }

        setInvalidatedForm(!result);
        return result;
    } // isValidForm

    function onClick_CancelProduct(event: any) {
        event.preventDefault();
        props.setRecord(event, 0)
    } // onClick_CancelProduct

    function fillStock() {

        const dataProduct = {
            id : productId,
            code,
            unit,
            manufacturer,
            norm,
            warranty,
            exchange,
            origin,
            description,
            usability,
            reference,
            codeNcm,
            grossWeight : removeMask(grossWeight),
            netWeight : removeMask(netWeight),
            category,
            image1,
            image2,
            purchase : removeMask(purchase)
        }

        const dataStock = {
            id : stockId,
            partnerId : partner.id,
            productId,
            product : dataProduct,
            control: control,
            notify: notify,
            sellZero: sellZero,
            minimum: minimum,
            actual: actual,
            movement : removeFormatDate(movement),
            margin : removeMask(margin),
            sale : removeMask(sale),
            comments,
            created : removeFormatDate(created), 
            updated : removeFormatDate(updated)
        }

        return dataStock;
    } // fillProduct

    async function onClick_SaveProduct(event: any) {
        event.preventDefault();

        const isValid = await isValidForm();
        if (isValid) {
            try {
                const isConfirmed = await confirmContext.show(props.title, display.message.confirm_record);
                if (isConfirmed) {
                    setSending(true);

                    const stock = fillStock();
                    if (isEditForm)
                        await srvService.saveStock(stock.id, stock);
                    else
                        await srvService.createStock(stock);

                    setSending(false);
                    await alertContext.show(AlertMessageEnum.SUCCESS, props.title, display.message.the_record_has_been_saved_successfully);
                    props.setRecord(event, 0);
                }
            } catch (error: any) {
                setSending(false);
                await alertContext.show(AlertMessageEnum.FAIL, props.title, error);
            }
        }
    } // onClick_SaveProduct

    async function searchParam() {
        try {
            if (!Number.isNaN(Number.parseInt(props.productId ||''))) {
                setEditForm(true);

                const product = await srvService.stockById(Number(props.productId));
                setOriginRecord(product);
                fillRecord(product);
            } else {
                setEditForm(false);
            }
        } catch(error: any) {
            await alertContext.show(AlertMessageEnum.FAIL, props.title, error);
        }
    } // searchParam

    useEffect(() => {
        if (!isLoading && !reloadPage) {
            searchParam();
            setLoading(true);
        }

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            reloadPage = !reloadPage;
        }
    }, []); // useEffect
    
    async function onClick_ConfirmFilterCategory(event: any, category: any) {
        event.preventDefault();
        setCategory(category);
    }

    function filterCategory(event: any) {
        event.preventDefault();

        if (!isEmpty(category)) {
            const categoryList = Array<number>(category.id);
            setCategoryInList(categoryList);
        }
        setShowFilterCategory(true);
    }

    function changeImage1(event: any) {
        if (event.target.files && event.target.files.length > 0) {
            /*
            const file = event.target.files[0];
            const extension = String(file.name).substring(String(file.name).lastIndexOf('.') + 1, String(file.name).length);
            FileResizer.imageFileResizer(file, 300, 300, extension, 100, 0,
                (uri: any) => {
                    setImage1(uri);
                }, "base64"
            );
            */
            let reader = new FileReader();
            reader.onload = (e: any) => {
                setImage1(e.currentTarget.result);
            };
            reader.readAsDataURL(event.target.files[0]);
        } else {
            setImage1('');
        }
    }

    function changeImage2(event: any) {
        if (event.target.files && event.target.files.length > 0) {
            /*
            const file = event.target.files[0];
            const extension = String(file.name).substring(String(file.name).lastIndexOf('.') + 1, String(file.name).length);
            FileResizer.imageFileResizer(file, 300, 300, extension, 100, 0,
                (uri: any) => {
                    setImage1(uri);
                }, "base64"
            );
            */
            let reader = new FileReader();
            reader.onload = (e: any) => {
                setImage2(e.currentTarget.result);
            };
            reader.readAsDataURL(event.target.files[0]);
        } else {
            setImage2('');
        }
    }

    function viewCadastreForm() {

        return (
            <Container fluid className="page-body product">
                <Row>
                    <Col sm={12} className="page-sweet">
                        <Card>
                            <Card.Header>
                                {(originRecord.created || originRecord.updated) && (
                                    <div className="form-identy-registry float-right">
                                        <span className="white-space"><b>Criação: </b> {dateToString(originRecord.created, 'dd/mm/yyyy HH:MM:ss')}</span>
                                        <span className="white-space"><b>Atualização: </b> {dateToString(originRecord.updated, 'dd/mm/yyyy HH:MM:ss')}</span>
                                    </div>
                                )}
                                <Card.Title>{display.legend.record}</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Form className="form" id="form-cadastre" validated={invalidatedForm}>
                                    <ProductRecord
                                        title={props.title}
                                        isEdit={isEditForm}
                                        viewOnly={props.viewOnly}

                                        code={code}
                                        mode={mode}
                                        barcode={barcode}
                                        unit={unit}
                                        description={description}
                                        usability={usability}
                                        reference={reference}
                                        codeNcm={codeNcm}
                                        grossWeight={grossWeight}
                                        netWeight={netWeight}
                                        category={category}
                                        control={control}
                                        notify={notify}
                                        sellZero={sellZero}
                                        minimum={minimum}
                                        actual={actual}
                                        movement={movement}
                                        purchase={purchase}
                                        margin={margin}
                                        sale={sale}
                                        comments={comments}
                                        manufacturer={manufacturer}
                                        origin={origin}
                                        warranty={warranty}
                                        norm={norm}
                                        exchange={exchange}
                                        image1={image1}
                                        image2={image2}
                                    
                                        setCode={(value: any) => { setCode(value); }}
                                        setMode={(value: any) => { setMode(value); }}
                                        setBarcode={(value: any) => { setBarcode(value); }}
                                        setUnit={(value: any) => { setUnit(value); }}
                                        setDescription={(value: any) => { setDescription(value); }}
                                        setUsability={(value: any) => { setUsability(value); }}
                                        setReference={(value: any) => { setReference(value); }}
                                        setCodeNcm={(value: any) => { setCodeNcm(value); }}
                                        setGrossWeight={(value: any) => { setGrossWeight(value); }}
                                        setNetWeight={(value: any) => { setNetWeight(value); }}
                                        setCategory={(value: any) => { setCategory(value); }}
                                        setControl={(value: any) => { setControl(value); }}
                                        setNotify={(value: any) => { setNotify(value); }}
                                        setSellZero={(value: any) => { setSellZero(value); }}
                                        setMinimum={(value: any) => { setMinimum(value); }}
                                        setActual={(value: any) => { setActual(value); }}
                                        setMovement={(value: any) => { setMovement(value); }}
                                        setPurchase={(value: any) => { setPurchase(value); }}
                                        setMargin={(value: any) => { setMargin(value); }}
                                        setSale={(value: any) => { setSale(value); }}
                                        setComments={(value: any) => { setComments(value); }}
                                        setManufacturer={(value: any) => { setManufacturer(value); }}
                                        setOrigin={(value: any) => { setOrigin(value); }}
                                        setWarranty={(value: any) => { setWarranty(value); }}
                                        setNorm={(value: any) => { setNorm(value); }}
                                        setExchange={(value: any) => { setExchange(value); }}
                                        setImage1={(value: any) => { setImage1(value); }}
                                        setImage2={(value: any) => { setImage2(value); }}

                                        filterCategory={(value: any) => { filterCategory(value); }}
                                        changeImage1={(event: any) => { changeImage1(event); }} 
                                        changeImage2={(event: any) => { changeImage2(event); }} 
                                    />
                                </Form>
                            </Card.Body>
                            <Card.Footer className="text-right">
                                <Button className="me-2" onClick={ (e) => onClick_CancelProduct(e) } variant="contained" color="secondary">{display.buttom.cancel}</Button>
                                {!props.viewOnly && <Button onClick={ (e) => onClick_SaveProduct(e) } disabled={sending} variant="contained" color="primary">
                                    { sending ? <Spinner className="me-2" as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <FaSave className="me-2" size={22} /> } {' '}
                                    {display.buttom.save}
                                </Button>}
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    } // viewCadastreForm

    return (
        <>
            { viewCadastreForm() }
            <CategoryFilter 
                team={mode}
                categoryNotInList={categoryInList}
                show={showFilterCategory}

                onClick_Close={(event: any) => { 
                    setShowFilterCategory(false);
                }}
                onClick_Confirm={(event: any, value: any) => { 
                    setShowFilterCategory(false);
                    onClick_ConfirmFilterCategory(event, value); 
                }}
            />
        </>
    );

}

export default ProductCadastreForm;
