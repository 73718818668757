import { useEffect, useMemo, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import TableContainer from "../../components/table-container.component";
import { useAlertMessageContext } from "../../contexts/alert-message.context";
import authService from "../../services/auth.service";
import lclService from "../../services/lcl.service";
import { dateToString, toLowerCase } from "../../utilities/auxiliary-functions";
import { numberMask } from "../../utilities/masks";
import { AlertMessageEnum, getCategoriesNaval } from "../../utilities/types";
import { isEmpty } from "../../utilities/validators";
import VesselConductorCadastreModal from "../modals/vessel-conductor.cadastre.modal";
import { Button, FormControl, FormHelperText, Input, InputAdornment, InputLabel } from '@mui/material'
import { FaTimes } from "react-icons/fa";
import SearchIcon from '@mui/icons-material/Search';
import styles from './vessel-conductor.filter.module.scss';
import { useTranslateContext } from "../../contexts/translate.context";
import { usePartnerContext } from "../../contexts/partner.context";

export interface IVesselConductorFilterProps {
    show: any,
    onClick_Close: any,
    onClick_Confirm: any,

    vessel: any,
    crewNotInList: any
}

export const VesselConductorFilter: React.FC<IVesselConductorFilterProps> = (props: IVesselConductorFilterProps) => {
    
    const alertContext = useAlertMessageContext();
    const { display } = useTranslateContext();
    const { partner } = usePartnerContext();

    const title : string = display.title.crew_filter;

    const [ textSearch, setTextSearch ] = useState('');
    const [ record, setRecord ] = useState({} as any);
    const [ records, setRecords ] = useState([] as any);
    const [ originalRecords, setOriginalRecords ] = useState([]);

    const [ conductorId, setConductorId ] = useState('');
    const [ conductor, setConductor ] = useState({} as any);
    const [ showConductor, setShowConductor ] = useState(false);

    const columnsRecord = useMemo(() => [{
        Header: 'Nome',
        accessor: "people.fullname"
    },{
        
        Header: display.label.registration,
        Cell: (props: any) => {
            const conductorRow = props.row.original;
            let enrollment = '';
            if (!isEmpty(conductorRow.people)) {
                if (conductorRow.people.documentsNautical.length > 0)
                    enrollment = conductorRow.people.documentsNautical[0].enrollment;
            }

            return (
                <div className="white-space">{enrollment}</div>
            );
        }
    },{
        Header: display.label.category,
        Cell: (props: any) => {
            const conductorRow = props.row.original;
            let category = null;
            if (!isEmpty(conductorRow.people)) {
                if (conductorRow.people.documentsNautical.length > 0)
                    category = conductorRow.people.documentsNautical[0].category;
            }

            if (!isEmpty(category)) {
                const categories = category.split(';');
                return (
                    <>
                        {categories.map((category: any, idx: number) => (
                            <div key={idx} className="white-space">{display.label[toLowerCase(getCategoriesNaval(category).name)]}</div>
                        ))}
                    </>
                );
            }

            return null;
        }
    },{
        Header: display.label.validity,
        Cell: (props: any) => {
            const conductorRow = props.row.original;
            let validity = null;
            if (!isEmpty(conductorRow.people)) {
                if (conductorRow.people.documentsNautical.length > 0)
                    validity = conductorRow.people.documentsNautical[0].validity;
            }

            return (
                <div className="white-space">{dateToString(validity, 'dd/mm/yyyy')}</div>
            );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }], []
    ); // columnsRecord

    async function initialize() {
        try {
            if (props.show) {
                const attributes = [] as any;
                const where = {
                    vesselId : props.vessel.id,
                    id : { notIn : props.crewNotInList }
                } as any;
                const order = [] as any;
                /* Sempre incluir o partner na pesquisa */
                where['partnerId'] = partner.id;

                let conductors = await lclService.vesselConductorFilter(attributes, where, order);
                setRecords(conductors);
                setOriginalRecords(conductors);
                setRecord({} as any);
                if (conductors.length === 0) {
                    await alertContext.show(AlertMessageEnum.WARN, title, display.message.register_a_driver_on_the_vessel);
                }
            }
        } catch(error: any) {
            await alertContext.show(AlertMessageEnum.FAIL, title, error);
        }
    }

    useEffect(() => {
        initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show]); // useEffect
    
    function onClick_AddConductor(event: any) {
        event.preventDefault();

        setConductorId('new');
        setConductor({} as any);
        setShowConductor(true);
    }

    const filterRecords = (key?: string, value?: string) => {

        const filtered: any = []
        records.filter((record: any) => {
          if ( 
            record.registration
              .toLocaleLowerCase()
              .includes(textSearch.toLocaleLowerCase()) ||
            record.people.fullname
              .toLocaleLowerCase()
              .includes(textSearch.toLocaleLowerCase())
          )
          filtered.push(record)
          return record
        })
        setRecords(filtered)
      return ''
    }

    useEffect(() => {
        if (textSearch.length >= 3) {
            filterRecords()
        } else if (textSearch.length < 3) {
          setRecords(originalRecords)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [textSearch]);

    return (
        <>
            <Modal
                className={styles.filter}
                size="lg"
                show={props.show}
                centered
            >
                <Modal.Header>
                    <Modal.Title><FaSearch />{title}</Modal.Title>
                    <div className="card-actions float-right">
                        <FaTimes className="isClickable" size={22} onClick={props.onClick_Close} />
                    </div>
                </Modal.Header>
                <Modal.Body style={{ minHeight : 400 }}>
                    <Row>
                        <Col>
                            <Row>
                                <Col sm={"3"} className="filter-selectred"><strong>{display.label.enrollment}:</strong> <span>{props.vessel.enrollment?.toUpperCase()}</span></Col>
                            </Row>
                            <Row>
                                <Col sm={"3"} className="filter-selectred"><strong>{display.label.name_vessel}:</strong> <span>{props.vessel.name}</span></Col>
                            </Row>
                        </Col>
                        <Col sm={"3"}>
                            <Button variant="outlined" color="primary" onClick={(e) => onClick_AddConductor(e)}>{display.tooltips.add} {display.tooltips.conductor}</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormControl fullWidth sx={{width: 300, marginBottom: 2 }} variant="standard">
                                <InputLabel className={styles.label} htmlFor="standard-adornment-amount">Pesquisar</InputLabel>
                                <Input id="standard-adornment-amount"
                                    value={textSearch}
                                    onChange={(e) => setTextSearch(e.target.value)}
                                    endAdornment = {
                                        <InputAdornment position="end">
                                            <SearchIcon className='isClickable' />
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText id="component-helper-text">
                                    Matricula ou Nome
                                </FormHelperText>
                            </FormControl>
                            <TableContainer columns={ columnsRecord } data={ records } viewPagination={true} setSelectedRow={ 
                                (event: any, dataRow: any) => { 
                                    setRecord(dataRow); 
                                }}
                            />
                        </Col>
                    </Row>                    
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row>
                            <Col sm="8">
                                <div><strong>{display.label.selected_record}:</strong> <span className="filter-selectred">{  record.people && record.people.fullname }</span></div>
                            </Col>
                            <Col sm="4">
                                <Button variant="contained" color="secondary" className="ms-2" onClick={ (e) => props.onClick_Close(e) } >{display.buttom.cancel}</Button>{' '}
                                <Button variant="contained" color="primary" onClick={ (e) => props.onClick_Confirm(e, record) }  disabled={isEmpty(record)}>{display.buttom.confirm}</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>
            <VesselConductorCadastreModal 
                isEdit={false}
                show={showConductor}
                vessel={props.vessel}
                conductorId={conductorId}
                record={conductor}
                onSubmitModal={(event: any, conductor: any) => {
                    event.preventDefault();
                    setRecord(conductor);
                    setRecords([...records, conductor]);

                    setShowConductor(false);
                }}
                onCancelModal={(event: any) => {
                    event.preventDefault();
                    setShowConductor(false);
                }}
            />
        </>
    )
}

export default VesselConductorFilter;
