import AddIcon from '@mui/icons-material/Add';
import ptBR from 'date-fns/locale/pt-BR';
import moment from "moment";
import { useEffect, useState } from "react";
import { Button, Card, Col, Container, OverlayTrigger, Row, Spinner, Tooltip, Image } from "react-bootstrap";
import { Button as ButtonMaterial, FormControl, FormHelperText, Input, InputAdornment, InputLabel, LinearProgress } from '@mui/material';
import DatePicker from "react-datepicker";
import { FaEdit, FaSpinner } from "react-icons/fa";
import { IoNewspaperOutline } from "react-icons/io5";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PickerButtom } from "../../components/sub-render.component";
import { useAlertMessageContext } from "../../contexts/alert-message.context";
import authService from "../../services/auth.service";
import srvService from "../../services/srv.service";
import { dateToString, floatToString, toDate, toInt, toLowerCase } from "../../utilities/auxiliary-functions";
import { PERSON_PESSOA_JURIDICA, PermissionEnum } from "../../utilities/constants";
import { cnpjMask, phoneMask, removeFormatDate, zipcodeMask } from "../../utilities/masks";
import { AlertMessageEnum, getBudgetSituation, getColorDefault, getPayment, getPaymentConditions } from "../../utilities/types";
import { isEmpty } from "../../utilities/validators";
import OfficeHeader from "../headers/office.header";
import BudgetCadastreModal from "../modals/budget.cadastre.modal";
import BudgetReport, { TBudgetReport } from "../reports/budget.report";
import lclService from '../../services/lcl.service';
import cduService from '../../services/cdu.service';
import { VESSEL_LANCHA } from "../../utilities/constants";
import Jet from '../../assets/Jetski.svg';
import Lancha from '../../assets/Lancha.svg';
import SearchIcon from '@mui/icons-material/Search';
import styles from './budget.office.module.scss';
import { DataGrid, GridColDef, GridSelectionModel, GridToolbar } from '@mui/x-data-grid';
import { ThemeProvider } from '@mui/material/styles';
import theme from "../../theme";
import { TFilter } from '../../types/FilterType';
import { useTranslateContext } from '../../contexts/translate.context';
import { usePartnerContext } from '../../contexts/partner.context';

export interface IBudgetOfficeProps {
    viewOnly?: boolean
}

export const BudgetOffice: React.FC<IBudgetOfficeProps> = (props: IBudgetOfficeProps) => {
    
    const navigator = useNavigate();
    
    const alertContext = useAlertMessageContext();
    const { display } = useTranslateContext();
    const { partner } = usePartnerContext();

    const title = display.title.budget;
    const description = display.description.budget;

    const [ dateTimeStart, setDateTimeStart ] = useState(dateToString(new Date(), 'dd/mm/yyyy') + ' 00:00:00');
    const [ dateTimeEnd, setDateTimeEnd ] = useState(dateToString(new Date(), 'dd/mm/yyyy') + ' 23:59:59');

    let reloadPage = false;
    const [ isLoading, setLoading ] = useState(false);
    const [ textSearch, setTextSearch ] = useState('');
    const [ showFilter, setShowFilter]  = useState(false);
    const [ sending, setSending ] = useState(false);
    const [ selectionModel, setSelectionModel ] = useState<GridSelectionModel>([]);

    let noPaged = true;
    const [ searchParams, setSearchParams ] = useSearchParams();

    const [ pageSize, setPageSize ] = useState(10);
    const [ page, setPage ] = useState(0);
    const [ pageFilled, setPageFilled ] = useState<number[]>([]);

    const [ recordCount, setRecordCount ] = useState(0);
    const [ records, setRecords ] = useState([]);
    const [ originalRecords, setOriginalRecords ] = useState([]);

    const columns: GridColDef[] = [
        { field: 'code', headerName: display.label.os, width: 80, align: 'center', headerAlign: 'center' },
        { field: 'prohibited', headerName: display.label.prohibited, width: 110, align: 'center', headerAlign: 'center', valueGetter: getProhibited },
        { field: 'departure', headerName: display.label.departure, width: 110, align: 'center', headerAlign: 'center', valueGetter: getDeparture },
        { field: 'vessel.name', headerName: display.label.vessel, flex: 1, align: 'center', headerAlign: 'center', valueGetter: getVesselName },
        { field: 'vessel.identifyKey', headerName: display.label.identify_key, align: 'center', headerAlign: 'center', valueGetter: getVesselIdentifyKey },
        { field: 'vessel.enrollment', headerName: display.label.enrollment, align: 'center', headerAlign: 'center', valueGetter: getVesselEnrollment },
        { field: 'vessel.typeVessel', headerName: 'Tipo | Cor', align: 'center', headerAlign: 'center', renderCell: renderTypeAndColor },
        { field: 'vessel.brand', headerName: 'Mar | Mod', align: 'center', headerAlign: 'center', renderCell: renderBrandAndModel },
        { field: 'situation', headerName: display.label.situation, align: 'center', headerAlign: 'center', renderCell: renderSituation },
        { field: 'mechanic', headerName: display.label.mechanic, flex: 1, align: 'center', headerAlign: 'center', valueGetter: getMechanicName },
        {
            field: 'action',
            headerName: 'Ações',
            headerAlign: 'center',
            renderCell: renderButtons,
            disableExport: true,
        }
    ];

    function getProhibited(param: any) {
        const budget = param.row;
        return dateToString(budget.prohibited, 'dd/mm/yyyy');
    }

    function getDeparture(param: any) {
        const budget = param.row;
        return dateToString(budget.departure, 'dd/mm/yyyy');
    }

    function getVesselName(param: any) {
        const budget = param.row;
        return budget.vessel?.name || '-';
    }

    function getVesselIdentifyKey(param: any) {
        const budget = param.row;
        return budget.vessel?.identifyKey || '-';
    }

    function getVesselEnrollment(param: any) {
        const budget = param.row;
        return budget.vessel?.enrollment || '-';
    }

    function renderTypeAndColor(param: any) {
        const budget = param.row;

        return budget.vessel?.typeVessel === VESSEL_LANCHA ? (
            <div className="d-flex flex-column align-items-center justify-content-center mb-2">
                <div className="mt-3 d-flex justify-content-center mb-2">
                    <Image src={Lancha} className="jet" style={{height: 32}}/>
                    <small className="ms-2">{`${budget.vessel?.vesselSize}'`}</small>
                </div>
                <span className={styles.vesselColor} style={{backgroundColor: `${budget.vessel?.color}`}}></span>
            </div>
        ):(
            <div className="d-flex flex-column align-items-center justify-content-center mb-2">
                <div className="d-flex justify-content-center mb-2">
                    <Image src={Jet} className="jet mt-3" style={{height: 32}}/>
                    <small className="ms-1 mt-3">{`10'`}</small>
                </div>
                <span className={styles.vesselColor} style={{backgroundColor: `${budget.vessel?.color}`}}></span>
            </div>
        );
    }

    function renderBrandAndModel(param: any) {
        const budget = param.row;

        return (
            <div className="d-flex flex-column justify-content-center mb-2">
                <div className="mt-3">{budget.vessel?.brand || '-'}</div>
                <div className="mt-1">{budget.vessel?.model || '-'}</div>
            </div>
        );
    }

    function renderSituation(param: any) {
        const budget = param.row;

        return (
            <div className="white-space mt-2">
                <div className="table-column-color">
                    <span className="table-column-blockAutox30" style={{backgroundColor: `${getBudgetSituation(budget.situation).color}`}}>
                        {display.label[toLowerCase(getBudgetSituation(budget.situation).name)]}
                    </span>
                </div>
            </div>
        );
    }

    function getMechanicName(param: any) {
        const budget = param.row;
        return budget.mechanic?.people?.fullname || '-';
    }

    function renderButtons(param: any) {
        const budgetRow = param.row;
        return (
            <div className="d-flex w-100 justify-content-center">
                {authService.hasPermission(PermissionEnum.OFFICE_BUDGET_EDIT) && 
                    <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.toEdit}</Tooltip>}>
                        <span onClick={(e) => onClick_EditBudget(e, budgetRow)}>
                            <FaEdit size={18} />
                        </span>
                    </OverlayTrigger>
                }
                {authService.hasPermission(PermissionEnum.OFFICE_BUDGET_EDIT) && 
                    <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.view_report}</Tooltip>}>
                        <span onClick={(e) => onClick_ReportBudget(e, budgetRow)}>
                            <IoNewspaperOutline size={22} />
                        </span>
                    </OverlayTrigger>
                }
            </div>
        );
    }

    const [ budgetId, setBudgetId ] = useState('');
    const [ budget, setBudget ] = useState({} as any);
    const [ isEditBudget, setEditBudget ] = useState(false);
    const [ showBudget, setShowBudget ] = useState(false);

    const onChange_DateTimeStart = async (date: any) => {
        const dateStop = toDate(dateTimeEnd, 'dd/mm/yyyy HH:MM:ss');
        if (date > dateStop) {
            setDateTimeStart(dateToString(dateStop, 'dd/mm/yyyy') + ' 00:00:00');
        } else {
            setDateTimeStart(dateToString(date, 'dd/mm/yyyy HH:MM:ss'));

            let parameterMaximumDaysInConsultations = await cduService.parameterByCode(partner.companyId, '10');
            let fim = moment(date).day(toInt(parameterMaximumDaysInConsultations.content) + 1);
            setDateTimeEnd(fim.format('DD/MM/YYYY 23:59:59'));
        }

        setRecords([]);
        setOriginalRecords([]);
        setRecordCount(0);
        setPageFilled([]);
    }

    const onChange_DateTimeStop = (date: any) => {
        const dateStart = toDate(dateTimeStart, 'dd/mm/yyyy HH:MM:ss');
        if (date < dateStart) {
            setDateTimeEnd(dateToString(dateStart, 'dd/mm/yyyy') + ' 23:59:59');
        } else {
            setDateTimeEnd(dateToString(date, 'dd/mm/yyyy HH:MM:ss'));
        }

        setRecords([]);
        setOriginalRecords([]);
        setRecordCount(0);
        setPageFilled([]);
    }

    function onClick_RefreshBudget(event: any) {
        event.preventDefault();

        setSending(true);
        searchFilter(dateTimeStart, dateTimeEnd);
    } // onClick_RefreshBudget
    
    function onClick_AddRecord(event: any) {
        event.preventDefault();

        setEditBudget(false);
        setBudgetId('new');
        setBudget({} as any);
        setShowBudget(true);
    } // onClick_AddRecord

    function onClick_EditBudget(event: any, budget: any) {
        event.preventDefault();

        setEditBudget(true);
        setBudgetId(budget.id);
        setBudget(budget);
        setShowBudget(true);
    } // onClick_EditRecord

    function reportResponsableName(budget: any) {
        let name = '';

        const responsibles = budget.vessel.responsibles;
        if (responsibles && responsibles.length > 0) {
            name = responsibles[0].owner?.fullname;
        }
        if (isEmpty(name)) {
            const owner = budget.vessel.owner;
            if (owner) {
                name = (budget.vessel.ownerType === PERSON_PESSOA_JURIDICA) ? owner.socialReason : owner?.fullname;
            }
        }
        return name;
    }

    function reportResponsableEmail(budget: any) {
        let email = '';
        const responsibles = budget.vessel.responsibles;
        if (responsibles && responsibles.length > 0) {
            email = responsibles[0].owner?.email;
        }
        return email;
    }

    function reportResponsableTelephone(budget: any) {
        let telephone = '';

        const responsibles = budget.vessel.responsibles;
        if (responsibles && responsibles.length > 0) {
            const phones = responsibles[0].owner?.phones
            if (phones && phones.length > 0) {
                telephone = phones[0].connection;
            }
        }
        return telephone;
    }

    async function onClick_ReportBudget(event: any, record: any) {
        event.preventDefault();
        /*
        let report: IBudgetReport = {
            translate: props.translate,
            partner: props.partner,
            budgetId: record.id
        }
        */
        try {
            const budget = await srvService.budgetById(Number(record.id));
            if (budget.vesselId) {
                const vessel = await lclService.vesselById(budget.vesselId);
                budget.vessel = vessel;
            }
            if (budget.mechanicId) {
                const mechanic = await srvService.mechanicById(budget.mechanicId);
                budget.mechanic = mechanic;
            }

            const address = await cduService.addressById(partner.company.addressId);
            let report: TBudgetReport = {
                company: {
                    image: partner.company.image,
                    socialReason: partner.company.socialReason,
                    fantasy: partner.company.fantasy,
                    registrationPj: cnpjMask(partner.company.registrationPj),
                    telephone: phoneMask(partner.company.telephone),
                    email: partner.company.email,
                    address: `${address.place} - ${address.district} - ${address.city} - ${address.state} - ${zipcodeMask(address.zipcode)}`
                },
                budget: {
                    code: budget.code,
                    prohibited: dateToString(budget.prohibited, 'dd/mm/yyyy HH:MM'),
                    departure: dateToString(budget.departure, 'dd/mm/yyyy HH:MM')
                },
                responsable: {
                    name: reportResponsableName(budget),
                    email: reportResponsableEmail(budget),
                    telephone: reportResponsableTelephone(budget)
                },
                vessels: [{
                    identifyKey: budget.vessel.identifyKey,
                    enrollment: budget.vessel.enrollment,
                    name: budget.vessel.name,
                    color: display.label[toLowerCase(getColorDefault(budget.vessel.color).name)],
                    brand: budget.vessel.brand,
                    model: budget.vessel.model
                }],
                comments: budget.comments,
                productAndServices: budget.items.map((item: any) => {
                    return {
                        code: item.stock.product.code,
                        category: item.stock.product.category.name,
                        description: item.stock.product.description,
                        quantity: item.quantity,
                        sale: floatToString(item.stock.sale),
                        amount: floatToString(item.amount)
                    }
                }),
                mechanic: budget?.mechanic?.people?.fullname,
                situation: display.label[toLowerCase(getBudgetSituation(budget.situation).name)],
                paymentForm: display.label[toLowerCase(getPayment(budget.paymentForm).name)],
                paymentConditions: display.label[toLowerCase(getPaymentConditions(budget.paymentConditions).name)],
                productCost: floatToString(budget.productCost),
                officeCost: floatToString(budget.officeCost),
                discountCost: floatToString(budget.discountCost),
                orderCost: floatToString(budget.orderCost)
            };
            BudgetReport(report);
        } catch(error: any) {
            console.error(error);
        }
    } // onClick_ReportBudget
    
    async function searchFilter(dateTimeStart: string, dateTimeEnd: string) {
        setLoading(true);

        /* Sempre incluir o partner na pesquisa */
        if (!isEmpty(partner)) {
            try {
                setDateTimeStart(dateTimeStart);
                setDateTimeEnd(dateTimeEnd);

                let localPageSize = pageSize;
                let localPage = page;

                if (noPaged) {
                    if (searchParams.has('limit')) {
                        let paramPageSize = searchParams.get('limit');
                        if (paramPageSize) {
                            // eslint-disable-next-line react-hooks/exhaustive-deps
                            localPageSize = Number.parseInt(paramPageSize);
                            setPageSize(localPageSize);
                        }
                    }
                    if (searchParams.has('offset')) {
                        let paramPage = searchParams.get('offset');
                        if (paramPage) {
                            // eslint-disable-next-line react-hooks/exhaustive-deps
                            localPage = Number.parseInt(paramPage);
                            setPage(localPage);
                        }
                    }
                }

                if (pageFilled.indexOf(localPage) === -1) {
                    const filter = {
                        attributes: [
                            'id', 'code', 'prohibited', 'departure', 'vesselId', 'comments', 'mechanicId', 'situation', 
                            'paymentForm', 'paymentConditions', 'productCost', 'officeCost', 'discountCost', 'orderCost', 
                            'created', 'updated',
                            'mechanic.id', 'mechanic.situation',
                            'mechanic.people.fullname', 'mechanic.people.gender', 'mechanic.people.registrationPf',
                            'mechanic.people.phones.connection', 'mechanic.people.phones.norm', 'mechanic.people.phones.major', 'mechanic.people.phones.sms', 'mechanic.people.phones.zap'
                        ],
                        where: {
                            partnerId: partner.id,
                            prohibited : { start : removeFormatDate(dateTimeStart), end : removeFormatDate(dateTimeEnd) }
                        },
                        order: [ [ 'prohibited', 'ASC' ] ],
                        limit: localPageSize, 
                        offset: localPage * localPageSize
                    } as TFilter;
                    const budgetFilter = await srvService.budgetFilter(filter);
                    setRecordCount(budgetFilter.count);
                    const array = [] as any;
                    records.forEach((record: any) => array.push(record));
                    budgetFilter.rows.forEach((record: any) => array.push(record));

                    setRecords(array);
                    setOriginalRecords(array);
                    setSending(false);

                    if (array.length > 0)
                        pageFilled.push(localPage);
                }
            } catch(error: any) {
                await alertContext.show(AlertMessageEnum.FAIL, title, error);
            } finally {
                setSending(false);
                setLoading(false);
                setShowFilter(false);
            }
        } else {
            await alertContext.show(AlertMessageEnum.FAIL, title, 'Não foi definido uma Marina para exibir o cadastro !');
            setLoading(false);
            navigator(`/portal`);
        }
    } // searchFilter

    const onChangePageSize = (newPageSize: number) => {
        setSearchParams({...searchParams, 'limit': `${newPageSize}`, 'offset': `${0}`});

        setRecords([]);
        setOriginalRecords([]);
        setRecordCount(0);
        setPageFilled([]);
        setPageSize(newPageSize);
        setPage(0);
    }
    
    const onChangePage = (newPage: number) => {
        setSearchParams({...searchParams, 'offset': `${newPage}`});

        setPage(newPage);
    }

    let effectForPage = false;

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        noPaged = false;
        let paramPage = searchParams.get('offset');
        if (paramPage) {
            searchFilter(dateTimeStart, dateTimeEnd);
            effectForPage = true;
        }
    }, [pageSize, page]); // useEffect

    useEffect(() => {
        if (!isLoading && !reloadPage) {
            noPaged = true;
            if (!effectForPage) {
                let inicio = moment().day(0); // domingo desta semana
                let fim = moment().day(6); // sábado desta semana

                let startDate = inicio.format('DD/MM/YYYY 00:00:00');
                let endDate = fim.format('DD/MM/YYYY 23:59:59');
                
                searchFilter(startDate, endDate);
                effectForPage = false;
            }
        }

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            reloadPage = !reloadPage;
        }
    }, []); // useEffect

    const filtered = (key?: string, value?: string) => {
        const filtered: any = []
        records.filter((record: any) => {
            if (
                record.code
                .toLocaleLowerCase()
                .includes(textSearch.toLocaleLowerCase()) ||
                record.vessel.name
                .toLocaleLowerCase()
                .includes(textSearch.toLocaleLowerCase()) ||
                record.vessel.identifyKey
                .toLocaleLowerCase()
                .includes(textSearch.toLocaleLowerCase()) ||
                record.vessel.enrollment
                .toLocaleLowerCase()
                .includes(textSearch.toLocaleLowerCase())
            )
            filtered.push(record)
            return record
        })
        setRecords(filtered)
        return ''
    }

    useEffect(() => {
        if (textSearch.length >= 3) {
            filtered()
        } else if (textSearch.length < 3) {
          setRecords(originalRecords)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [textSearch]);

    function viewPage() {

        return (
            <Container fluid className="page-body budgets">
                <Row>
                    <Col md={12} className="page-sweet">
                        <Card style={{ minHeight : 500 }}>
                            <Card.Header>
                                <div className="card-actions d-flex justify-content-between">
                                    <div className="d-flex">
                                        <div className="card-actions-time">
                                            <DatePicker
                                                locale={ptBR}
                                                selected={toDate(dateTimeStart, 'dd/mm/yyyy')}
                                                onChange={(date: any) => onChange_DateTimeStart(date) }
                                                dateFormat="dd/MM/yyyy"
                                                customInput={<PickerButtom />}
                                                selectsStart
                                                startDate={toDate(dateTimeStart, 'dd/mm/yyyy')}
                                                endDate={toDate(dateTimeEnd, 'dd/mm/yyyy')}

                                                popperClassName="some-custom-class"
                                                popperPlacement="top-end"
                                                popperModifiers={[
                                                    {
                                                        name : "offset",
                                                        options : {
                                                            offset : [5, 10]
                                                        }
                                                    },{
                                                        name : "preventOverflow",
                                                        options : {
                                                            rootBoundary : "viewport",
                                                            tether : false,
                                                            altAxis : true
                                                        }
                                                    }
                                                ]}
                                            /> 
                                            <DatePicker
                                                locale={ptBR}
                                                selected={toDate(dateTimeEnd, 'dd/mm/yyyy')}
                                                onChange={(date: any) => onChange_DateTimeStop(date) }
                                                dateFormat="dd/MM/yyyy"
                                                customInput={<PickerButtom />}
                                                selectsEnd
                                                startDate={toDate(dateTimeStart, 'dd/mm/yyyy')}
                                                endDate={toDate(dateTimeEnd, 'dd/mm/yyyy')}
                                                minDate={toDate(dateTimeStart, 'dd/mm/yyyy')}

                                                popperClassName="some-custom-class"
                                                popperPlacement="top-end"
                                                popperModifiers={[
                                                    {
                                                        name : "offset",
                                                        options : {
                                                            offset : [5, 10]
                                                        }
                                                    },{
                                                        name : "preventOverflow",
                                                        options : {
                                                            rootBoundary : "viewport",
                                                            tether : false,
                                                            altAxis : true
                                                        }
                                                    }
                                                ]}
                                            />
                                        </div>
                                        <Button variant="secondary" className="ms-2" onClick={ (e) => onClick_RefreshBudget(e) } disabled={sending}>
                                            { sending ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <FaSpinner size={22} /> } {' '}
                                            {display.buttom.refresh}
                                        </Button>
                                     </div>
                                    {authService.hasPermission(PermissionEnum.OFFICE_BUDGET_ADD) && 
                                        <ButtonMaterial size="small" variant="contained" onClick={(e) => onClick_AddRecord(e) } ><AddIcon />Orçamento</ButtonMaterial>
                                    }
                                </div>
                                <Card.Title>{display.subtitle.budget}</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <FormControl fullWidth sx={{width: 300, marginBottom: 2 }} variant="standard">
                                    <InputLabel className={styles.label} htmlFor="standard-adornment-amount">Pesquisar</InputLabel>
                                    <Input id="standard-adornment-amount"
                                        value={textSearch}
                                        onChange={(e) => setTextSearch(e.target.value)}
                                        endAdornment = {
                                            <InputAdornment position="end">
                                                <SearchIcon className='isClickable' />
                                            </InputAdornment>
                                        }
                                    />
                                    <FormHelperText id="component-helper-text">
                                        Regitro, Chave ID ou Embarcação
                                    </FormHelperText>
                                </FormControl>
                                <ThemeProvider theme={theme}>
                                    <div style={{ height: 402, width: '100%', marginTop: 0 }}>
                                        <DataGrid
                                            rows={records}
                                            rowCount={recordCount}
                                            getRowId={row => row.id}
                                            loading={isLoading}
                                            columns={columns}
                                            pageSize={pageSize}
                                            onPageSizeChange={newPageSize => onChangePageSize(newPageSize)}
                                            page={page}
                                            onPageChange={newPage => onChangePage(newPage) }
                                            
                                            rowsPerPageOptions={[10, 25, 50, 75, 100]}
                                            disableSelectionOnClick
                                            // onCellClick={(e) => console.log(e)}
                                            onSelectionModelChange={(newSelectionModel: any) => {
                                                setSelectionModel(newSelectionModel)
                                            }}
                                            components={{
                                                Toolbar: GridToolbar,
                                                LoadingOverlay: LinearProgress
                                            }}
                                            selectionModel={selectionModel}
                                        />
                                    </div>
                                </ThemeProvider>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    } // viewPage

    return (
        <div className="page">
            <OfficeHeader title={title} description={description} />
            { viewPage() }
            <BudgetCadastreModal 
                isEdit={isEditBudget}

                show={showBudget}
                budgetId={budgetId}
                record={budget}
                records={records}
                onSubmitModal={(event: any, record: any) => {
                    event.preventDefault();

                    setBudget(record);
                    if (isEditBudget) {
                        const updatedBudgets = Array.from(records) as any;
                        for ( var idx_Edit = 0; idx_Edit < updatedBudgets.length; idx_Edit++) {
                            if (updatedBudgets[idx_Edit].id === record.id) {
                                updatedBudgets[idx_Edit] = record;
                            }
                        }
                        setRecords(updatedBudgets);
                    } else {
                        // setRecords([...records, record]);
                        console.log('Olhar este ponto')
                    }
                    setShowBudget(false);
                    setEditBudget(false);
                    searchFilter(dateTimeStart, dateTimeEnd);
                }}
                onCancelModal={(event: any) => {
                    setEditBudget(false);
                    setShowBudget(false);
                }}
            />
        </div>
    );

}

export default BudgetOffice;