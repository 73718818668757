import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CadastreHeader from "../headers/cadastre.header";
import MechanicCadastreForm from "./forms/mechanic.cadastre.form";
import MechanicCadastreList from "./lists/mechanic.cadastre.list";
import { useTranslateContext } from "../../contexts/translate.context";

export interface IMechanicCadastreProps {
    viewOnly?: boolean
}

export const MechanicCadastre: React.FC<IMechanicCadastreProps> = (props: IMechanicCadastreProps) => {

    const { mechanicId } = useParams();
    const { display } = useTranslateContext();

    const title = display.title.mechanic;
    const description = '';

    let reloadPage = false;
    const [ isLoading, setLoading ] = useState(false);
    const [ recordSelected, setRecordSelected ] = useState({ param : mechanicId || '', selected : ((mechanicId === 'new') || (mechanicId === '0') || (!Number.isNaN(Number.parseInt(mechanicId ||'')))) });

    function setSelected(isRecord: boolean, recordId: string) {
        setRecordSelected({ param : recordId, selected : isRecord });
    }

    async function initialize() {
    }
    
    useEffect(() => {
        if (!isLoading && !reloadPage) {
            initialize();
            setLoading(true);
        }

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            reloadPage = !reloadPage;
        }
    }, []);

    return (
        <div className="page">
            <CadastreHeader title="Mecânicos" description={description} />
            {(recordSelected.selected) ? (
                <MechanicCadastreForm 
                    title={title}
                    mechanicId={recordSelected.param}
                    setRecord={(event: any) => { 
                        event.preventDefault();
                        setSelected(false, '0'); 
                    }}
                    viewOnly={props.viewOnly}
                />
            ) : (
                <MechanicCadastreList
                    title={title}
                    setRecord={(event: any, recordId: string) => { 
                        event.preventDefault();
                        setSelected(true, recordId); 
                    }}    
                    viewOnly={props.viewOnly}
                />
            )}
        </div>
    )
}

export default MechanicCadastre;
