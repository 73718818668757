import { useEffect, useState } from "react";
import { Row, Col, Container, Modal, FormGroup, Form, FormControl } from "react-bootstrap";
import { BsSliders } from "react-icons/bs";
import { FaTimes } from "react-icons/fa";
import { useAlertMessageContext } from "../../contexts/alert-message.context";
import { useConfirmMessageContext } from "../../contexts/confirm-message.context";
import cduService from "../../services/cdu.service";
import srvService from "../../services/srv.service";
import { toString, toLowerCase, toStringFloat, dateToString, notNullToDate } from "../../utilities/auxiliary-functions";
import { CategoryEnum } from "../../utilities/constants";
import { floatMask, removeFormatDate, removeMask } from "../../utilities/masks";
import { AlertMessageEnum, CategoryType } from "../../utilities/types";
import { isEmpty } from "../../utilities/validators";
import StockFilter from "../filters/stock.filter";
import { Button } from '@mui/material'
import { TFilter } from "../../types/FilterType";
import { useTranslateContext } from "../../contexts/translate.context";

export interface ICatalogCadastreModalProps {
    show: any,
    purveyorId?: any, 
    productId?: any,
    record?: any,

    onClick_Close: any,
    onClick_Confirm: any,
}

export const CatalogCadastreModal: React.FC<ICatalogCadastreModalProps> = (props: ICatalogCadastreModalProps) => {
    
    const alertContext = useAlertMessageContext();
    const confirmContext = useConfirmMessageContext();
    const { display } = useTranslateContext();

    const title : string = display.title.catalog_cadastre;

    const [ invalidatedForm, setInvalidatedForm ] = useState(false);


    const showFilterCategory = false
    const [ showFilterProduct, setShowFilterProduct ] = useState(false);
    const stockInList: any = []

    const [ team, setTeam ] = useState(0);
    const [ category, setCategory ] = useState({} as any);
    const [ categories, setCategories ] = useState([] as any);
    const [ reference, setReference ] = useState('');
    const [ code, setCode ] = useState('');
    const [ description, setDescription ] = useState('');

    const [ productId, setProductId ] = useState(0);
    const [ product, setProduct ] = useState({} as any);
    const [ sale, setSale ] = useState('0,00');
    const [ created, setCreated ] = useState(dateToString(new Date(), 'dd/mm/yyyy HH:MM:ss'));
    const [ updated, setUpdated ] = useState(dateToString(new Date(), 'dd/mm/yyyy HH:MM:ss'));


    async function fillRecord(catalog: any) {

        if (!isEmpty(catalog)) {
            setProductId(catalog.productId);
            setProduct(catalog.product);

            setReference(catalog.product.reference);
            setCode(catalog.product.code);
            setDescription(catalog.product.description);

            setSale(toStringFloat(catalog.sale));
            setCreated(notNullToDate(catalog.created, 'dd/mm/yyyy HH:MM:ss'));  
            setUpdated(notNullToDate(catalog.updated, 'dd/mm/yyyy HH:MM:ss'));  
        }
    } // fillRecord

    async function isValidForm() {
        let result = true;
        let emptyRequired = false;

        if (isEmpty(product))
            emptyRequired = true;
        if (isEmpty(sale))
            emptyRequired = true;

        if (emptyRequired) {
            await alertContext.show(AlertMessageEnum.FAIL, title, display.message.invalid.required);
            result = false;
        }

        setInvalidatedForm(!result);
        return result;
    } // isValidForm

    function clearForm() {
        setTeam(CategoryEnum.PRODUCT);
        treatCategory(CategoryEnum.PRODUCT);
        setCategory({} as any);
        setCategories([] as any);
        setReference('');
        setCode('');
        setDescription('');

        setProductId(props.productId);
        setProduct({} as any);
        setSale('0,00');
    }

    function fillCatalog() {

        const dataCatalog = {
            purveyorId : props.purveyorId,
            productId,
            product, 
            sale: removeMask(sale), 
            created : removeFormatDate(created), 
            updated : removeFormatDate(updated),
        };

        return dataCatalog;
    }

    async function searchParam() {
        try {
            clearForm();
            if (!Number.isNaN(Number.parseInt(props.purveyorId ||'')) && !Number.isNaN(Number.parseInt(props.productId ||''))) {
                const catalog = await srvService.catalogById(Number(props.purveyorId), Number(props.productId));
                fillRecord(catalog);
            }
        } catch(error: any) {
            await alertContext.show(AlertMessageEnum.FAIL, title, error);
        }
    } // searchParam

    useEffect(() => {
        if (props.show) {
            searchParam();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show]); // useEffect

    async function onClick_Confirm(event: any) {
        event.preventDefault();

        const isValid = await isValidForm();
        if (isValid) {
            setInvalidatedForm(false);
            const isConfirmed = await confirmContext.show(title, display.message.confirm_record);
            if (isConfirmed) {
                const catalog = fillCatalog();
                props.onClick_Confirm(event, catalog);
            }
        }
    }

    function onClick_FilterProduct(event: any) {
        event.preventDefault();
        setShowFilterProduct(true);
    }

    async function onClick_ConfirmFilterProduct(event: any, stock: any) {
        event.preventDefault();
        setProductId(stock.product.id);
        setProduct(stock.product);
        setReference(stock.product.reference);
        setCode(stock.product.code);
        setDescription(stock.product.description);
    }

    function onChange_Team(event: any) {
        event.preventDefault();

        setTeam(event.target.value);
        treatCategory(event.target.value);
    }

    function onChange_Category(event: any) {
        event.preventDefault();

        let category = {} as any;
        for (var i = 0; i < categories.length; i++) {
            if (categories[i].id === Number(event.target.value)) {
                category = categories[i];
                break;
            }
        }
        setCategory(category);
    }

    async function treatCategory(team: number) {
        const filter = {
            where: { team }
        } as TFilter;
        let categoryFilter = await cduService.categoryFilter(filter); // {count: 0, rows: []} as any; 
        setCategories(categoryFilter.rows);
    }

    function viewCadastreForm() {
        return (
            <>
                <Modal
                    show={props.show && !(showFilterCategory || showFilterProduct)}
                    centered
                >
                    <Modal.Header>
                        <Modal.Title>{title}</Modal.Title>
                        <div className="card-actions float-right">
                            <FaTimes className="isClickable" size={26} onChange={props.onClick_Close} />
                        </div>
                    </Modal.Header>
                    <Modal.Body style={{ minHeight : 200 }}>
                        <Form className="form" id="form-cadastre-catalog" validated={invalidatedForm}>
                            <Row>
                                <Col sm="12">
                                    <FormGroup as={Row} className="form-row-divider">
                                        <Form.Label column sm="2" htmlFor="form-team">{display.label.group}</Form.Label>
                                        <Col>
                                            <Form.Select id="form-team" name="team" 
                                                value={team}
                                                onChange={(e) => onChange_Team(e)}
                                            >
                                                {isEmpty(team) && <option value="">...</option>}
                                                {CategoryType.map((iterator: any, idx: number) => (
                                                    (iterator.id !== CategoryEnum.VESSEL) ? (
                                                        <option key={idx} value={iterator.id}>{display.label[toLowerCase(iterator.name)]}</option>
                                                    ) : null
                                                ))}
                                            </Form.Select>
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12">
                                    <FormGroup as={Row} className="form-row-divider">
                                        <Form.Label column sm="2" htmlFor="form-category">{display.label.category}</Form.Label>
                                        <Col>
                                            <Form.Select id="form-category" name="category" 
                                                required
                                                value={toString(category.id)}
                                                onChange={(e) => onChange_Category(e)}
                                            >
                                                {isEmpty(category.group) && <option value="">...</option>}
                                                {categories.map((element: any, idx: number) => (
                                                    <option key={idx} value={element.id}>
                                                        {!isEmpty(element.group) ?
                                                            (element.group +' \\ '+ (isEmpty(element.subgroup) ? element.name : element.subgroup +' \\ '+ element.name))
                                                        : ''}
                                                    </option>
                                                ))}    
                                            </Form.Select>
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12">
                                    <hr/>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12">
                                    <fieldset>
                                        <Row>
                                            <Col sm="8">
                                                <FormGroup>
                                                    <Form.Label htmlFor="form-reference">{display.label.reference}</Form.Label>
                                                    <FormControl type="text" id="form-reference" name="reference" placeholder={display.example.reference}
                                                        value={toString(reference)}
                                                        onChange={e => setReference(e.target.value)}
                                                        readOnly
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col sm="4">
                                                <FormGroup>
                                                    <Form.Label htmlFor="form-code">{display.label.code}</Form.Label>
                                                    <FormControl type="text" id="form-code" name="code" placeholder={display.example.code}
                                                        value={toString(code)}
                                                        onChange={e => setCode(e.target.value)}
                                                        readOnly
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="12">
                                                <Form.Label htmlFor="form-description">{display.label.description}</Form.Label>
                                                <FormControl type="text" id="form-description" name="description" placeholder={display.example.description}
                                                    value={toString(description)}
                                                    onChange={e => setDescription(e.target.value)}
                                                    readOnly
                                                />
                                            </Col>
                                        </Row>
                                    </fieldset>
                                </Col>
                            </Row>
                            <Row  className="align-items-center">
                                <Col sm="4">
                                    <Button variant="contained" color="secondary" className="ms-2" onClick={(e) => { onClick_FilterProduct(e) }}>
                                        {display.legend.search_product}<BsSliders />
                                    </Button>
                                </Col>
                                <Col sm="8">
                                    <FormGroup className="form-row-divider d-flex justify-content-between">
                                        <Form.Label column sm="4" htmlFor="form-sale">Valor de Venda</Form.Label>
                                        <FormControl type="text" id="form-sale" name="sale" placeholder={display.example.sale} required
                                            className="text-right"
                                            value={toString(sale)}
                                            onChange={e => setSale(floatMask(e.target.value))}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container>
                            <Row>
                                <Col sm="12" className="d-flex justify-content-between">
                                    <Button variant="contained" color="secondary" onClick={ (e) => props.onClick_Close(e) } >{display.buttom.cancel}</Button>{' '}
                                    <Button variant="contained" color="primary" onClick={ (e) => onClick_Confirm(e) }  disabled={isEmpty(product)}>{display.buttom.confirm}</Button>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

    return (
        <>
        { viewCadastreForm() }
        <StockFilter 
            team={team}
            category={category}

            stockNotInList={stockInList}
            show={showFilterProduct}

            onClick_Close={(event: any) => { 
                setShowFilterProduct(false);
            }}
            onClick_Confirm={(event: any, value: any) => { 
                setShowFilterProduct(false);
                onClick_ConfirmFilterProduct(event, value); 
            }}
        />
        </>
    )
}

export default CatalogCadastreModal;
