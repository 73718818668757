import axios from "axios";
import env from "react-dotenv";

class PostOfficesService {
    
    API_POST_OFFICES = env.API_POST_OFFICES;

    async findZipcode(zipcode: string) {
        const url = `${this.API_POST_OFFICES}/${zipcode}/json`;

        return axios
            .get(url
            ).then((response: any) => {
                return response.data; 
            }).catch((error: any) => {
                console.log(`Exception in 'PostOfficesService' from method get 'findZipcode'`);
                if (error.code === "ERR_NETWORK") {
                    throw new Error(`Ops !!! Não foi possivel conectar a URL solicitada.`);
                } else {
                    const responseMessage = (
                        error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                    throw new Error(responseMessage);
                }
            });
    }

}

// eslint-disable-next-line import/no-anonymous-default-export
export default new PostOfficesService();