import { cnpjMask, phoneMask } from '../../utilities/masks';
import { Tooltip as TooltipMaterial } from '@mui/material';
import { usePartnerContext } from "../../contexts/partner.context";

export interface ICadastreHeaderProps {
    title: any,
    description: any
}

export const CadastreHeader: React.FC<ICadastreHeaderProps> = (props: ICadastreHeaderProps) => {

    const { partner, showDefinedPartner } = usePartnerContext();

    return (
        <div className="page-header">
            <div className='title'>
                <h4 className="page-title">{props.title}</h4>
                <div className="page-reserved ">{props.description}</div>
            </div>
            <TooltipMaterial title="Empresas do grupo">
                <div className="page-corporation isClickable" onClick={() => showDefinedPartner(true) } >
                    {partner && partner.company && <div>
                        <div><strong>{partner.company.fantasy}</strong>{' - '}{cnpjMask(partner.company.registrationPj)}{' - '}{phoneMask(partner.company.telephone)}</div>
                    </div>}  
                </div>
            </TooltipMaterial>
        </div>
    )
}

export default CadastreHeader;
