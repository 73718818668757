import authService from "./auth.service";

export default function authHeader(useData: boolean = false): {} {

    // let localUser = localStorage.getItem('user') || '{}';
    // const user = JSON.parse(localUser);
    const user = authService.currentUser();
    const partner = authService.currentPartner();
    
    if(user && user.authorization) {
        return { 
            'User-Key': user.id,
            'Partner-Key': partner.id,
            'X-Access-Token': user.authorization ,
            'Content-Type': useData ? 'multipart/form-data' : 'application/json'
        };
    } else {
        return {};
    }
}

