import { useEffect, useMemo, useState } from "react";
import { Row, Col, Container, Modal } from "react-bootstrap";
import { FaSearch, } from "react-icons/fa";
import TableContainer from "../../components/table-container.component";
import { useAlertMessageContext } from "../../contexts/alert-message.context";
import srvService from "../../services/srv.service";
import { floatMask } from "../../utilities/masks";
import { AlertMessageEnum, getUnit } from "../../utilities/types";
import { isEmpty } from "../../utilities/validators";
import { Button, FormControl, FormHelperText, Input, InputAdornment, InputLabel, LinearProgress } from '@mui/material'
import { Tooltip as TooltipMaterial } from '@mui/material';
import { FaTimes } from "react-icons/fa";
import SearchIcon from '@mui/icons-material/Search';
import styles from './stock.filter.module.scss';
import { TFilter } from "../../types/FilterType";
import { DataGrid, GridColDef, GridSelectionModel, GridToolbar } from '@mui/x-data-grid'
import VisibilityIcon from '@mui/icons-material/Visibility';
import authService from "../../services/auth.service";
import { useTranslateContext } from "../../contexts/translate.context";

export interface IStockFilterProps {
    show: any,
    onClick_Close: any,
    onClick_Confirm: any,

    team?: any,
    category?: any,
    stockNotInList: any
}

export const StockFilter: React.FC<IStockFilterProps> = (props: IStockFilterProps) => {

    const alertContext = useAlertMessageContext();
    const { display } = useTranslateContext();

    const title : string = display.title.stock_filter;

    const [ isLoading, setLoading ] = useState(false);

    let noChangePaging = true;
    let noPaged = true;

    const [ textSearch, setTextSearch ] = useState('');
    const [ pageSize, setPageSize ] = useState(10);
    const [ page, setPage ] = useState(0);
    const [ pageFilled, setPageFilled ] = useState<number[]>([]);

    const [ recordCount, setRecordCount ] = useState(0);
    const [ record, setRecord ] = useState({} as any);
    const [ records, setRecords ] = useState([] as any);
    const [ originalRecords, setOriginalRecords ] = useState([]);

    const [ selectionModel, setSelectionModel ] = useState<GridSelectionModel>([]);

    const columnsRecord = useMemo(() => [{
        Header: display.label.code,
        accessor: "product.code"
    },{
        Header: display.label.category,
        Cell: (props: any) => {
            const category = props.row.original.product.category;

            return (
                <div className="white-space">
                    <div>{category?.group} {category.subgroup ? ` / ${category.subgroup}` : ''}</div>
                    <div>{category.name}</div>
                </div>
            );
        }
    },{
        Header: display.label.description,
        accessor: "product.description",
        Cell: (row: any) => (<div>{row.value}</div>)
    },{
        Header: display.label.stock,
        accessor: "actual",
    },{
        Header: display.label.unit,
        accessor: "product.unit",
        Cell: (row: any) => (<div className="white-space">{getUnit(row.value).name}</div>)
    },{
        Header: display.label.value,
        accessor: "sale",
        Cell: (row: any) => (<div className="white-space">{floatMask(row.value)}</div>)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }], []); // columnsRecord

    const columns: GridColDef[] = [
        { field: 'product.code', headerName: display.label.code, align: 'center', headerAlign: 'center', valueGetter: getCode },
        { field: 'product.category', headerName: display.label.category, flex: 1, minWidth: 100, align: 'center', headerAlign: 'center', renderCell: getCategory },
        { field: 'product.description', headerName: display.label.description, flex: 1, minWidth: 100, align: 'center', headerAlign: 'center', valueGetter: getDescription },
        { field: 'actual', headerName: display.label.stock, align: 'center', headerAlign: 'center' },
        { field: 'product.unit', headerName: display.label.unit, align: 'center', headerAlign: 'center', renderCell: getUnitName },
        { field: 'sale', headerName: display.label.value, align: 'center', headerAlign: 'center' }
    ];

    function getCode(param: any) {
        const { product } = param.row;
        return product.code
    }

    function getDescription(param: any) {
        const { product } = param.row;
        return product.description
    }

    function getReference(param: any) {
        const { product } = param.row;
        return product.reference
    }

    function getUsability(param: any) {
        const { product } = param.row;
        if (product.usability) {
            return (<TooltipMaterial title={product.usability} className="white-space"><VisibilityIcon sx={{fontSize: 22}} /></TooltipMaterial>)
        } else {
            return '-'
        }
    }

    function getCategory(param: any) {
        const { product } = param.row;
        return (<div className="white-space">{!isEmpty(product.category) ? product.category.group +' \\ '+ (isEmpty(product.category.subgroup) ? product.category.name : product.category.subgroup +' \\ '+ product.category.name) : ''}</div>)
    }
    
    function getUnitName(param: any) {
        const { product } = param.row;
        return (<div className="white-space">{getUnit(product.unit).name}</div>)
    }

    async function searchFilter() { // pageSize: number, page: number
        setLoading(true);
        try {
            const partner = authService.currentPartner();

            if (pageFilled.indexOf(page) === -1) {
                pageFilled.push(page);

                const filter = {
                    attributes: [ 'id', 'control', 'notify', 'sellZero', 'minimum', 'actual', 'margin', 'sale', 
                        'product.code', 'product.reference', 'product.description', 'product.usability', 'product.unit',
                        'product.category.id', 'product.category.material', 'product.category.team', 'product.category.group', 'product.category.subgroup', 'product.category.name'
                    ],
                    where: {
                        partnerId: partner.id
                    },
                    limit: pageSize,
                    offset: page * pageSize
                } as TFilter;

                if (!isEmpty(props.team))
                    filter.where['product.category.team'] = props.team;
                if (!isEmpty(props.category)) {
                    if (typeof props.category === 'object')
                        filter.where['product.category.id'] = props.category.id;
                    else
                        filter.where['product.category.id'] = props.category;
                }

                if (!isEmpty(props.team)) {
                    filter['where'] = { 'product.category.team': props.team };
                }

                let stockFilter = await srvService.stockFilter(filter); // {count: 0, rows: []} as any; 
                setRecordCount(stockFilter.count);
                const array = [] as any;
                records.forEach((record: any) => array.push(record));

                stockFilter.rows.forEach((record: any) => array.push(record));
                setRecords(array);
                setOriginalRecords(array);
            }
        } catch(error: any) {
            await alertContext.show(AlertMessageEnum.FAIL, title, error);
        } finally {
            setLoading(false);
        }
    } // searchfilter
    
    const onChangePageSize = (newPageSize: number) => {
        setRecords([]);
        setOriginalRecords([]);
        setRecordCount(0);
        setPageFilled([]);
        setPageSize(newPageSize);
        setPage(0);
    }
    
    const onChangePage = (newPage: number) => {
        setPage(newPage);
    }

    useEffect(() => {
        if (props.show) {
            searchFilter();
        }
    }, [pageSize, page]); // useEffect

    useEffect(() => {
        setRecords([]);
        setOriginalRecords([]);
        setRecordCount(0);
        setPageFilled([]);
        setPage(0);
        if (props.show) {
            searchFilter();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show]); // useEffect

    const filterRecords = (key?: string, value?: string) => {

        const filtered: any = []
        records.filter((record: any) => {
          if (
            record.product.description
              .toLocaleLowerCase()
              .includes(textSearch.toLocaleLowerCase())
          )
          filtered.push(record)
          return record
        })
        setRecords(filtered)
      return ''
    }

    useEffect(() => {
        if (textSearch.length >= 3) {
            filterRecords()
        } else if (textSearch.length < 3) {
          setRecords(originalRecords)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [textSearch]);
    
    return (
        <>
            <Modal
                className={styles.filter}
                size="lg"
                show={props.show}
                centered
            >
                <Modal.Header>
                    <Modal.Title><FaSearch />{title}</Modal.Title>
                    <div className="card-actions float-right">
                        <FaTimes className="isClickable" size={22} onClick={props.onClick_Close} />
                    </div>
                </Modal.Header>
                <Modal.Body style={{ minHeight : 500 }}>
                    <Row>
                        <Col>
                            <FormControl fullWidth sx={{width: 300, marginBottom: 2 }} variant="standard">
                                <InputLabel className={styles.label} htmlFor="standard-adornment-amount">Pesquisar</InputLabel>
                                <Input id="standard-adornment-amount"
                                    value={textSearch}
                                    onChange={(e) => setTextSearch(e.target.value)}
                                    endAdornment = {
                                        <InputAdornment position="end">
                                            <SearchIcon className='isClickable' />
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText id="component-helper-text">
                                    Descrição
                                </FormHelperText>
                            </FormControl>
                            <div style={{ height: 402, width: '100%', marginTop: 0 }}>
                                <DataGrid
                                    rows={records}
                                    rowCount={recordCount}
                                    getRowId={row => row.id}
                                    loading={isLoading}
                                    columns={columns}
                                    pageSize={pageSize}
                                    onPageSizeChange={newPageSize => onChangePageSize(newPageSize)}
                                    page={page}
                                    onPageChange={newPage => onChangePage(newPage) }
                                    
                                    rowsPerPageOptions={[10, 25, 50, 75, 100]}
                                    disableSelectionOnClick
                                    // onCellClick={(e) => console.log(e)}
                                    onRowClick={(row) => {
                                        const dataRow = records[row.id];
                                        setRecord(dataRow); 
                                    }}
                                    onSelectionModelChange={(newSelectionModel: any) => {
                                        setSelectionModel(newSelectionModel)
                                    }}
                                    components={{
                                        LoadingOverlay: LinearProgress
                                    }}
                                    selectionModel={selectionModel}
                                />
                            </div>
                        </Col>
                    </Row>                    
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row>
                            <Col sm="8">
                                <div className="filter-selectred"><strong>{display.label.selected_record}:</strong> <span>{!isEmpty(record) ? record.product.code : ''}</span></div>
                            </Col>
                            <Col sm="4" className="d-flex justify-content-end">
                                <Button variant="contained" color="secondary" className="me-2" onClick={ (e) => props.onClick_Close(e) } >{display.buttom.cancel}</Button>{' '}
                                <Button variant="contained" color="primary" onClick={ (e) => props.onClick_Confirm(e, record) }  disabled={isEmpty(record)}>{display.buttom.confirm}</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default StockFilter;
