import { useEffect, useState } from "react";
import { Form, Modal, Spinner } from "react-bootstrap";
import { FaRegIdCard, FaTimes } from "react-icons/fa";
import { useAlertMessageContext } from "../../contexts/alert-message.context";
import { useConfirmMessageContext } from "../../contexts/confirm-message.context";
import lclService from "../../services/lcl.service";
import { floatToString, notNullToDate, toFloat, toInt } from "../../utilities/auxiliary-functions";
import { PERSON_PESSOA_FISICA } from "../../utilities/constants";
import { cpfMask, floatMask, numberMask, phoneMask, removeFormatDate, removeMask, zipcodeMask } from "../../utilities/masks";
import { isCpf, isEmpty } from "../../utilities/validators";
import VesselResponsibleRecord from "../records/vessel-responsible.record";
import PeopleFilter from "../filters/people.filter";
import { Button } from '@mui/material'
import { AlertMessageEnum } from "../../utilities/types";
import { openDocument } from "../../utilities/view-document";
import cduService from "../../services/cdu.service";
import { useTranslateContext } from "../../contexts/translate.context";

export interface IVesselResponsibleCadastreModalProps {
    isEdit: boolean,
    isShared?: boolean,
    quotaAvailable?: string,
    quotaAmount?: string,
    vesselCost?: string,

    show: boolean,
    vessel: any,
    responsibleId: string,
    record: any;
    onSubmitModal: any,
    onCancelModal: any
}

export const VesselResponsibleCadastreModal: React.FC<IVesselResponsibleCadastreModalProps> = (props: IVesselResponsibleCadastreModalProps) => {

    const alertContext = useAlertMessageContext();
    const confirmContext = useConfirmMessageContext();
    const { display } = useTranslateContext();

    const title : string = display.title.responsible_cadastre;

    const [ invalidatedCadastreForm, setInvalidatedCadastreForm ] = useState(false);

    const [ sending, setSending ] = useState(false);
    const [ showFilterPeople, setShowFilterPeople ] = useState(false);

    // Responsible
    const [ id, setId ] = useState(0);
    const [ created, setCreated ] = useState('');
    const [ updated, setUpdated ] = useState('');

    const [ quota, setQuota ] = useState('');
    const [ average, setAverage ] = useState('');
    const [ amount, setAmount ] = useState('');
    const [ principal, setPrincipal ] = useState(false);
    const [ contactName, setContactName ] = useState('');
    const [ contactPhone, setContactPhone ] = useState('');
    const [ contactAddress, setContactAddress ] = useState('');
    // PersonalData
    const [ peopleId, setPeopleId ] = useState(0); 
    const [ tratament, setTratament ] = useState(''); 
    const [ fullname, setFullname ] = useState(''); 
    const [ gender, setGender ] = useState('');
    const [ birth, setBirth ] = useState('');
    // Phone
    const [ phoneId, setPhoneId ] = useState(0);
    const [ norm, setNorm ] = useState('');
    const [ operator, setOperator ] = useState('');
    const [ connection, setConnection ] = useState('');
    const [ sms, setSms ] = useState(0);
    const [ zap, setZap ] = useState(0);
    const [ major, setMajor ] = useState(0);
    const [ nationality, setNationality ] = useState('');
    const [ naturalness, setNaturalness ] = useState('');
    // PersonalDocument
    const [ registrationPf, setRegistrationPf ] = useState('');
    const [ registrationIdentity, setRegistrationIdentity ] = useState('');
    const [ issuerIdentity, setIssuerIdentity ] = useState('');
    const [ stateIdentity, setStateIdentity ] = useState('');
    const [ expedition, setExpedition ] = useState('');
    const [ driversLicense, setDriversLicense ] = useState('');
    const [ categoryDriversLicense, setCategoryDriversLicense ] = useState('');
    const [ validityDriversLicense, setValidityDriversLicense ] = useState('');
    // Address
    const [ addressId, setAddressId ] = useState(0);
    const [ zipcode, setZipcode ] = useState('');
    const [ state, setState ] = useState('');
    const [ city, setCity ] = useState('');
    const [ district, setDistrict ] = useState('');
    const [ place, setPlace ] = useState('');
    const [ habitation, setHabitation ] = useState('');
    const [ complement, setComplement ] = useState('');

    type ArtifactType = { id : number, norm : string, source : string, identy : string, document : string, filename : string };
    const DEFAULT_ARTIFACT: ArtifactType = { id : 0, norm : '', source : '', identy : '', document : '', filename : '' };
    
    const [ proofOfResidenceDocument, setProofOfResidenceDocument ] = useState(DEFAULT_ARTIFACT);
    const [ isChangedProofOfResidenceDocument, setChangedProofOfResidenceDocument ] = useState(false);

    async function isValidForm() {
        let result = true;
        let emptyRequired = false;

        if (isEmpty(fullname))
            emptyRequired = true;

        if (isEmpty(registrationPf))
            emptyRequired = true;
        if ((!isEmpty(registrationPf)) && (!isCpf(registrationPf))) {
            await alertContext.show(AlertMessageEnum.FAIL, title, display.message.invalid.registration_pf);
            result = false;
        }

        if (emptyRequired) {
            await alertContext.show(AlertMessageEnum.FAIL, title, display.message.invalid.required);
            result = false;
        }

        setInvalidatedCadastreForm(!result);
        return result;
    }

    function onClick_FilterPerson(event: any) {
        event.preventDefault();

        setShowFilterPeople(true);
    }

    function onClick_ConfirmFilterPeople(event: any, record: any) {
        event.preventDefault();
        fillRecordPeople(record);
    }

    async function onClick_Confirm(event : any) {
        event.preventDefault();

        const isValid = await isValidForm();
        if (isValid) {
            try {
                const isConfirmed = await confirmContext.show(title, display.message.confirm_record);
                if (isConfirmed) {
                    setSending(true);

                    const responsible = fillResponsible();
                    if(responsible.vesselId > 0) {
                        let returned = null;
                        if (responsible.id > 0)
                            returned = await lclService.saveVesselResponsible(responsible.id, responsible);
                        else
                            returned = await lclService.createVesselResponsible(responsible);
                        responsible.id = returned.id;

                        if (isChangedProofOfResidenceDocument && proofOfResidenceDocument) {
                            proofOfResidenceDocument.source = 'PEOPLE';
                            proofOfResidenceDocument.identy = String(responsible.ownerId);
        
                            if ((proofOfResidenceDocument.id > 0) && !isEmpty(proofOfResidenceDocument.document)) {
                                await cduService.saveArtifact(proofOfResidenceDocument.id, proofOfResidenceDocument);
                            } else if ((proofOfResidenceDocument.id > 0) && isEmpty(proofOfResidenceDocument.document)) {
                                await cduService.artifactDeleteById(proofOfResidenceDocument.id);
                            } else if ((proofOfResidenceDocument.id === 0) && !isEmpty(proofOfResidenceDocument.document)) {
                                await cduService.createArtifact(proofOfResidenceDocument);
                            }   
                        }
                        await alertContext.show(AlertMessageEnum.SUCCESS, title, display.message.the_record_has_been_saved_successfully);
                    }
                    props.onSubmitModal(event, responsible);
                    setSending(false);
                }
            } catch (error: any) {
                setSending(false);
                await alertContext.show(AlertMessageEnum.FAIL, title, error);
            }
        }
    }

    async function onClick_Cancel(event : any) {
        event.preventDefault();

        setInvalidatedCadastreForm(false);
        props.onCancelModal(event);
    }

    function clearResponsible() {
        // Responsible
        setId(0);
        setCreated('');
        setUpdated('');
        //
        setQuota('0');
        setAverage('0');
        setAmount('0');
        setPrincipal(false);
        setContactName('');
        setContactPhone('');
        setContactAddress('');
        // PersonalData
        setPeopleId(0); 
        setTratament(''); 
        setFullname(''); 
        setGender('');
        setBirth('');
        // Phone
        setPhoneId(0);
        setNorm('');
        setOperator('');
        setConnection('');
        setSms(0);
        setZap(0);
        setMajor(0);
        setNationality('');
        setNaturalness('');
        // PersonalDocument
        setRegistrationPf('');
        setRegistrationIdentity('');
        setIssuerIdentity('');
        setStateIdentity('');
        setExpedition('');
        setDriversLicense('');
        setCategoryDriversLicense('');
        setValidityDriversLicense('');
        // Address
        setAddressId(0);
        setZipcode('');
        setState('');
        setCity('');
        setDistrict('');
        setPlace('');
        setHabitation('');
        setComplement('');
    }

    function fillRecordPeople(people: any) {

        if (people != null) {

            setPeopleId(people.id);
            setTratament(people.tratament);
            setFullname(people.fullname);
            setGender(people.gender);
            setBirth(notNullToDate(people.birth, 'dd/mm/yyyy'));
            if (!isEmpty(people.phones)) {
                const phone = people.phones[0];

                setPhoneId(phone.id);
                setNorm(phone.norm);
                setOperator(phone.operator);
                setConnection(phoneMask(phone.connection));
                setSms(phone.sms);
                setZap(phone.zap);
                setMajor(phone.major);
            }
            setNationality(people.nationality);
            setNaturalness(people.naturalness);

            setRegistrationPf(cpfMask(people.registrationPf));
            setRegistrationIdentity(numberMask(people.registrationIdentity));
            setIssuerIdentity(people.issuerIdentity);
            setStateIdentity(people.stateIdentity);
            setExpedition(notNullToDate(people.expedition, 'dd/mm/yyyy'));
            setDriversLicense(numberMask(people.driversLicense));
            setCategoryDriversLicense(people.categoryDriversLicense);
            setValidityDriversLicense(notNullToDate(people.validityDriversLicense, 'dd/mm/yyyy'));

            if (!isEmpty(people.adresses)) {
                const addressPeople = people.adresses[0];

                setAddressId(addressPeople.addressId);
                setZipcode(zipcodeMask(addressPeople.address.zipcode));
                setState(addressPeople.address.state);
                setCity(addressPeople.address.city);
                setDistrict(addressPeople.address.district);
                setPlace(addressPeople.address.place);

                setHabitation(addressPeople.habitation);
                setComplement(addressPeople.complement);
            }
        
        }
    } // fillRecordPeople

    function fillRecord(responsible: any) {

        if (responsible != null) {
            setId(responsible.id);
            setCreated(responsible.created);
            setUpdated(responsible.updated);

            if (props.isShared) {
                if (!responsible.quota) {
                    setQuota('1');
                    const amount = toFloat(props.quotaAmount) * 1;
                    setAmount(floatToString(amount));
                    const average = (amount / toFloat(props.vesselCost)) * 100;
                    setAverage(floatToString(average));
                } else {
                    setQuota(responsible.quota);
                    setAverage(floatMask(responsible.average));
                    setAmount(floatMask(responsible.amount ? responsible.amount : props.quotaAmount));
                }
            } else {
                setQuota('0');
                setAverage('0');
                setAmount('0');
            }

            setPrincipal(responsible.principal === 1);    
            fillRecordPeople(responsible.owner);

            setContactName(responsible.contactName);
            setContactPhone(responsible.contactPhone);
            setContactAddress(responsible.contactAddress);
        }
    } // fillRecord

    function fillResponsible() {

        const address = [];
        address[0] = {
            peopleId : peopleId,
            addressId : addressId,
            address : {
                id : addressId,
                zipcode : removeMask(zipcode),
                state : state,
                city : city,
                district : district,
                place : place
            },
            correspondence : 1,
            norm : 'residential',
            habitation,
            complement
        };

        const phones = [];
        phones[0] = {
            id : phoneId,
            norm, 
            operator,
            connection : removeMask(connection),
            sms,
            zap,
            major
        };

        const dataPeople = {
            id : peopleId,
            tratament,
            fullname, 
            gender, 
            birth : removeFormatDate(birth), 
            phones, 
            nationality, 
            naturalness, 
            registrationPf : removeMask(registrationPf),
            registrationIdentity : removeMask(registrationIdentity),
            issuerIdentity,
            stateIdentity,
            expedition : removeFormatDate(expedition),
            driversLicense,
            categoryDriversLicense,
            validityDriversLicense : removeFormatDate(validityDriversLicense),
            document,
            address
        };

        const dataResponsible = {
            id,
            created, 
            updated,
            ownerType : PERSON_PESSOA_FISICA,
            ownerId : peopleId, 
            owner : dataPeople,
            vesselId : props.vessel.id,
            quota : removeMask(quota, '0'),
            average : removeMask(average, '0'),
            amount : removeMask(amount, '0'),
            principal : principal ? 1 : 0,
            contactName,
            contactPhone,
            contactAddress
        };

        return dataResponsible;
    }

    async function initialize() {
        if (props.isEdit) {
            try {
                const responsible = await lclService.vesselResponsibleById(Number(props.responsibleId));
                fillRecord(responsible);
                cduService.artifactByNorm('PEOPLE', responsible.ownerId, 'FILE_RESIDENCE').then((artifact: any) => {
                    if (artifact)
                        setProofOfResidenceDocument(artifact);
                });
            } catch(error: any) {
                await alertContext.show(AlertMessageEnum.FAIL, title, error);
            }
        } else {
            clearResponsible();
        }
    }

    useEffect(() => {
        if (props.show)
            initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show]);

    function changeProofOfResidenceDocument(event: any) {
        setChangedProofOfResidenceDocument(true);
        if (event.target.files && event.target.files.length > 0) {
            let reader = new FileReader();
            reader.onload = (e: any) => {
                let artfact: ArtifactType = {
                    id : proofOfResidenceDocument.id,
                    norm : 'FILE_RESIDENCE',
                    source : 'PEOPLE',
                    identy : String(peopleId),
                    document : e.target.result,
                    filename : event.target.files[0].name
                }
                setProofOfResidenceDocument(artfact);
            };
            reader.readAsDataURL(event.target.files[0]);
        } else {
            let artifact: ArtifactType = { id : proofOfResidenceDocument.id, norm : 'FILE_RESIDENCE', source : 'PEOPLE', identy : String(peopleId), document : '', filename : '' };
            setProofOfResidenceDocument(artifact);
        }
    }

    function clickViewDocument(event: any, title: string, url: any) {
        event.preventDefault();
        openDocument(title, url);
    }

    function changeQuota(event: any) {
        let value = toInt(event.target.value);
        if (value > toInt(event.target.max))
            value = toInt(event.target.max);
        if (value < toInt(event.target.min))
            value = toInt(event.target.min);
        setQuota(String(value));

        const amount = toFloat(props.quotaAmount) * value;
        setAmount(floatToString(amount));
        
        const average = (amount / toFloat(props.vesselCost)) * 100;
        setAverage(floatToString(average));
    }

    function viewCadastreForm() {
        return (
            <>
                <Modal show={props.show && !showFilterPeople}
                    size="lg"
                    aria-labelledby="modal-cadastre-responsible-form"
                    centered
                >
                    <Modal.Header>
                        <Modal.Title id="modal-cadastre-responsible-form"><FaRegIdCard />{title}</Modal.Title>
                        <FaTimes className="isClickable" onClick={ onClick_Cancel } size={26}/>
                    </Modal.Header>
                    <Modal.Body>
                        <Form className="form" id="form-cadastre-responsible" validated={invalidatedCadastreForm}>
                            <VesselResponsibleRecord
                                title={title}
                                isEdit={props.isEdit}
                                isShared={props.isShared}
                                quotaAvailable={props.quotaAvailable}
                                quotaAmount={props.quotaAmount}

                                created={created}
                                updated={updated}
                                quota={quota}
                                average={average}
                                amount={amount}
                                principal={principal}
                                tratament={tratament}
                                fullname={fullname}
                                gender={gender}
                                birth={birth}
                                norm={norm}
                                operator={operator}
                                connection={connection}
                                sms={sms}
                                zap={zap}
                                major={major}
                                nationality={nationality}
                                naturalness={naturalness}
                                registrationPf={registrationPf}
                                registrationIdentity={registrationIdentity}
                                issuerIdentity={issuerIdentity}
                                stateIdentity={stateIdentity}
                                expeditionIdentity={expedition}
                                driversLicense={driversLicense}
                                categoryDriversLicense={categoryDriversLicense}
                                validityDriversLicense={validityDriversLicense}
                                zipcode={zipcode}
                                state={state}
                                city={city}
                                district={district}
                                place={place}
                                habitation={habitation}
                                complement={complement}
                                contactName={contactName}
                                contactPhone={contactPhone}
                                contactAddress={contactAddress}
                                setCreated={(value: any) => { setCreated(value); }}
                                setUpdated={(value: any) => { setUpdated(value); }}
                                setQuota={(value: any) => { setQuota(value); }}
                                setAverage={(value: any) => { setAverage(value); }}
                                setAmount={(value: any) => { setAmount(value); }}
                                setPrincipal={(value: any) => { setPrincipal(value); }}
                                setTratament={(value: any) => { setTratament(value); }}
                                setFullname={(value: any) => { setFullname(value); }}
                                setGender={(value: any) => { setGender(value); }}
                                setBirth={(value: any) => { setBirth(value); }}
                                setNorm={(value: any) => { setNorm(value); }}
                                setOperator={(value: any) => { setOperator(value); }}
                                setConnection={(value: any) => { setConnection(value); }}
                                setSms={(value: any) => { setSms(value); }}
                                setZap={(value: any) => { setZap(value); }}
                                setMajor={(value: any) => { setMajor(value); }}
                                setNationality={(value: any) => { setNationality(value); }}
                                setNaturalness={(value: any) => { setNaturalness(value); }}
                                setRegistrationPf={(value: any) => { setRegistrationPf(value); }}  
                                setRegistrationIdentity={(value: any) => { setRegistrationIdentity(value); }}  
                                setIssuerIdentity={(value: any) => { setIssuerIdentity(value); }}  
                                setStateIdentity={(value: any) => { setStateIdentity(value); }}  
                                setExpeditionIdentity={(value: any) => { setExpedition(value); }}  
                                setDriversLicense={(value: any) => { setDriversLicense(value); }}  
                                setCategoryDriversLicense={(value: any) => { setCategoryDriversLicense(value); }} 
                                setValidityDriversLicense={(value: any) => { setValidityDriversLicense(value); }} 
                                setZipcode={(value: any) => { setZipcode(value); }}
                                setState={(value: any) => { setState(value); }}
                                setCity={(value: any) => { setCity(value); }}
                                setDistrict={(value: any) => { setDistrict(value); }}
                                setPlace={(value: any) => { setPlace(value); }}
                                setHabitation={(value: any) => { setHabitation(value); }}
                                setComplement={(value: any) => { setComplement(value); }}
                                setContactName={(value: any) => { setContactName(value); }}
                                setContactPhone={(value: any) => { setContactPhone(value); }}
                                setContactAddress={(value: any) => { setContactAddress(value); }}
                                
                                showFilterPeopleModal={(value: any) => { onClick_FilterPerson(value); }}

                                proofOfResidenceDocument={proofOfResidenceDocument}
                                changeProofOfResidenceDocument={changeProofOfResidenceDocument}
                                clickViewDocument={clickViewDocument}
                                changeQuota={changeQuota}
                            />
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="contained" color="secondary" onClick={ onClick_Cancel }>{display.buttom.cancel}</Button>
                        <Button variant="contained" color="primary" onClick={ onClick_Confirm } disabled={sending}>
                            { sending ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null }{'  '}
                            { display.buttom.save }
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

    return (
        <>
            { viewCadastreForm() }
            <PeopleFilter 
                show={showFilterPeople}

                onClick_Close={(event: any) => { 
                    setShowFilterPeople(false);
                }}
                onClick_Confirm={(event: any, value: any) => { 
                    setShowFilterPeople(false);
                    onClick_ConfirmFilterPeople(event, value); 
                }}
            />
        </>
    )
}

export default VesselResponsibleCadastreModal
