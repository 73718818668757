import { Col, Container, Form, FormControl, FormGroup, Row, Tab, Tabs } from "react-bootstrap";
import { BsSliders } from "react-icons/bs";

import { useTranslateContext } from "../../contexts/translate.context";

import { Button } from "@mui/material";
import { toBoolean, toString } from "../../utilities/auxiliary-functions";
import { floatMask, phoneMask } from "../../utilities/masks";
import AddressRecord from './address.record';
import PersonalDataRecord from './personal-data.record';
import PersonalDocumentRecord from './personal-document.record';

export interface IVesselResponsibleRecordProps {
    title: any,
    isEdit: boolean,
    isShared: any,
    quotaAvailable: any,
    quotaAmount: any,

    created?: any,
    updated?: any,

    quota?: any,
    average?: any,
    amount?: any,
    principal?: any,
    tratament?: any,
    fullname?: any,
    gender?: any,
    birth?: any,
    norm?: any,
    operator?: any,
    connection?: any,
    sms?: any,
    zap?: any,
    major?: any,
    nationality?: any,
    naturalness?: any,
    registrationPf?: any,
    registrationIdentity?: any,
    issuerIdentity?: any,
    stateIdentity?: any,
    expeditionIdentity?: any,
    driversLicense?: any,
    categoryDriversLicense?: any,
    validityDriversLicense?: any,
    zipcode?: any,
    state?: any,
    city?: any,
    district?: any,
    place?: any,
    habitation?: any,
    complement?: any,
    contactName?: any,
    contactPhone?: any,
    contactAddress?: any,

    setCreated?: any,
    setUpdated?: any,
    setQuota?: any,
    setAverage?: any,
    setAmount?: any,
    setPrincipal?: any,
    setTratament?: any,
    setFullname?: any,
    setGender?: any,
    setBirth?: any,
    setNorm?: any,
    setOperator?: any,
    setConnection?: any,
    setSms?: any,
    setZap?: any,
    setMajor?: any,
    setNationality?: any,
    setNaturalness?: any,
    setRegistrationPf?: any,
    setRegistrationIdentity?: any,
    setIssuerIdentity?: any,
    setStateIdentity?: any,
    setExpeditionIdentity?: any,
    setDriversLicense?: any,
    setCategoryDriversLicense?: any,
    setValidityDriversLicense?: any
    setZipcode?: any,
    setState?: any,
    setCity?: any,
    setDistrict?: any,
    setPlace?: any,
    setHabitation?: any,
    setComplement?: any,
    setContactName?: any,
    setContactPhone?: any,
    setContactAddress?: any,
    showFilterPeopleModal?: any,

    proofOfResidenceDocument: any,
    changeProofOfResidenceDocument: any,
    clickViewDocument: any,
    changeQuota: any
}

export const VesselResponsibleRecord: React.FC<IVesselResponsibleRecordProps> = (props: IVesselResponsibleRecordProps) => {

    const { display } = useTranslateContext();

    function onChange_Principal(event: any) {
        props.setPrincipal(!props.principal);
    }

    const handleFilterPeopleModal = (e: any) => {
        props.showFilterPeopleModal(e)
    }

    return (
        <>
            <Row>
                <Col sm={`${props.isShared ? 9 : 12}`}>
                    <fieldset style={{minHeight : 165}}>
                        <div className="d-flex align-items-center justify-content-start mt-2">
                            <legend style={{width: 'fit-content'}}>{display.legend.general_data}</legend>
                            {(!props.isEdit) && (
                                <div className="float-right">
                                    <Button color="secondary" size="small" variant="contained" className="mb-2 ms-3" onClick={(e: any) => handleFilterPeopleModal(e) }>
                                    {display.legend.search_person} <BsSliders className="ms-3" />
                                    </Button>
                                </div>
                            )}
                        </div>
                        <PersonalDataRecord
                            title={props.title}
                            isEdit={props.isEdit}

                            tratament={props.tratament}
                            fullname={props.fullname}
                            gender={props.gender}
                            birth={props.birth}
                            norm={props.norm}
                            operator={props.operator}
                            connection={props.connection}
                            sms={props.sms}
                            zap={props.zap}
                            major={props.major}
                            nationality={props.nationality}
                            naturalness={props.naturalness}
                            setTratament={(value: any) => { props.setTratament(value); }}  
                            setFullname={(value: any) => { props.setFullname(value); }}  
                            setGender={(value: any) => { props.setGender(value); }}  
                            setBirth={(value: any) => { props.setBirth(value); }}  
                            setNorm={(value: any) => { props.setNorm(value); }}
                            setOperator={(value: any) => { props.setOperator(value); }}
                            setConnection={(value: any) => { props.setConnection(value); }}
                            setSms={(value: any) => { props.setSms(value); }}
                            setZap={(value: any) => { props.setZap(value); }}
                            setMajor={(value: any) => { props.setMajor(value); }}
                            setNationality={(value: any) => { props.setNationality(value); }} 
                            setNaturalness={(value: any) => { props.setNaturalness(value); }} 
                        />
                    </fieldset>
                </Col>
                {props.isShared && (
                    <Col sm="3">
                        <fieldset style={{minHeight : 160}}>
                            <legend>{display.legend.participation}</legend>
                            <Row>
                                <Col sm="6">
                                    <FormGroup>
                                        <Form.Label htmlFor="form-quota">Qtd. Cotas</Form.Label>
                                        <FormControl type="number" id="form-quota" name="quota" placeholder={display.example.quota} required
                                            min={0} max={props.quotaAvailable}
                                            className="text-right"
                                            value={toString(props.quota)}
                                            onChange={e => props.changeQuota(e)}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm="6">
                                    <FormGroup>
                                        <Form.Label htmlFor="form-average">{display.label.average} (%)</Form.Label>
                                        <FormControl type="text" id="form-average" name="average" placeholder={display.example.average} required
                                            className="text-right"
                                            value={toString(props.average)}
                                            onChange={e => props.setAverage(floatMask(e.target.value))}
                                            readOnly
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12">
                                    <FormGroup>
                                        <Form.Label htmlFor="form-amount">Valor Cota (R$)</Form.Label>
                                        <FormControl type="text" id="form-amount" name="amount" placeholder={display.example.amount} required
                                            className="text-right"
                                            value={toString(props.amount)}
                                            onChange={e => props.setAmount(floatMask(e.target.value))}
                                            readOnly
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12">
                                    <FormGroup className="d-flex justify-content-between align-items-baseline mt-2">
                                        <Form.Label htmlFor="form-principal">Proprietário Principal</Form.Label>
                                        <Form.Check id="form-principal" name="principal" placeholder={display.example.principal} required
                                            checked={toBoolean(props.principal)}
                                            onChange={e => onChange_Principal(e)}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </fieldset>
                    </Col>
                )}
            </Row>
            <Row>
                <Col sm="12">
                    <Tabs defaultActiveKey="member-documentation" id="tabs-member" className="mb-3">
                        <Tab eventKey="member-documentation" title={display.subtitle.documentation}>
                            <Container className="ps-0 pe-0">
                                <fieldset style={{minHeight : 250}}>
                                    <legend>{display.legend.documentation}</legend>
                                    <PersonalDocumentRecord
                                        title={props.title}
                                        isEdit={props.isEdit}

                                        registrationPf={props.registrationPf}
                                        registrationIdentity={props.registrationIdentity}
                                        issuerIdentity={props.issuerIdentity}
                                        stateIdentity={props.stateIdentity}
                                        expeditionIdentity={props.expeditionIdentity}
                                        driversLicense={props.driversLicense}
                                        categoryDriversLicense={props.categoryDriversLicense}
                                        validityDriversLicense={props.validityDriversLicense}
                                        setRegistrationPf={(value: any) => { props.setRegistrationPf(value); }}  
                                        setRegistrationIdentity={(value: any) => { props.setRegistrationIdentity(value); }}  
                                        setIssuerIdentity={(value: any) => { props.setIssuerIdentity(value); }}  
                                        setStateIdentity={(value: any) => { props.setStateIdentity(value); }}  
                                        setExpeditionIdentity={(value: any) => { props.setExpeditionIdentity(value); }}  
                                        setDriversLicense={(value: any) => { props.setDriversLicense(value); }}  
                                        setCategoryDriversLicense={(value: any) => { props.setCategoryDriversLicense(value); }} 
                                        setValidityDriversLicense={(value: any) => { props.setValidityDriversLicense(value); }} 
                                    />
                                </fieldset>
                            </Container>
                        </Tab>
                        <Tab eventKey="member-personal-address" title={display.subtitle.address}>
                            <Container className="ps-0 pe-0">
                                <fieldset style={{minHeight : 250}}>
                                    <legend>{display.legend.address}</legend>
                                    <AddressRecord 
                                        title={props.title}
                                        isEdit={props.isEdit}

                                        zipcode={props.zipcode}
                                        state={props.state}
                                        city={props.city}
                                        district={props.district}
                                        place={props.place}
                                        habitation={props.habitation}
                                        complement={props.complement}
                                        setZipcode={(value: any) => { props.setZipcode(value); }} 
                                        setState={(value: any) => { props.setState(value); }} 
                                        setCity={(value: any) => { props.setCity(value); }} 
                                        setDistrict={(value: any) => { props.setDistrict(value); }} 
                                        setPlace={(value: any) => { props.setPlace(value); }} 
                                        setHabitation={(value: any) => { props.setHabitation(value); }} 
                                        setComplement={(value: any) => { props.setComplement(value); }}

                                        proofOfResidenceDocument={props.proofOfResidenceDocument}
                                        changeProofOfResidenceDocument={props.changeProofOfResidenceDocument}
                                        clickViewDocument={props.clickViewDocument}
                                    />
                                </fieldset>
                                </Container>
                        </Tab>
                        <Tab eventKey="member-emergency-contact" title={display.subtitle.emergency_contact}>
                            <Container className="ps-0 pe-0">
                                <fieldset style={{minHeight : 250}}>
                                    <legend>{display.legend.general_data}</legend>
                                    <Row>
                                        <Col sm="8">
                                            <FormGroup>
                                                <Form.Label htmlFor="form-contact-name">{display.label.contact_name}</Form.Label>
                                                <FormControl type="text" id="form-contact-name" name="contactName" placeholder={display.example.contact_name}
                                                    value={toString(props.contactName)}
                                                    onChange={e => props.setContactName(e.target.value)}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Form.Label htmlFor="form-contact-phone">{display.label.contact_phone}</Form.Label>
                                                <FormControl type="text" id="form-contact-phone" name="contactPhone" placeholder={display.example.contact_phone}
                                                    value={toString(props.contactPhone)}
                                                    onChange={e => props.setContactPhone(phoneMask(e.target.value))}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12">
                                            <FormGroup>
                                                <Form.Label htmlFor="form-contact-address">{display.label.contact_address}</Form.Label>
                                                <FormControl type="text" id="form-contact-address" name="contactAddress" placeholder={display.example.contact_address}
                                                    value={toString(props.contactAddress)}
                                                    onChange={e => props.setContactAddress(e.target.value)}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </fieldset>
                            </Container>
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
        </>
    );

}

export default VesselResponsibleRecord;
