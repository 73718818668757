import React, { useEffect, useState } from 'react'

import { useNavigate, useParams } from 'react-router-dom'

import { Button } from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'

import styles from './CheckIn.module.scss'
import CheckInCard from './components/check-in-card/CheckInCard'
import CheckInConfirmationModal from './components/check-in-confirmation-modal/CheckInConfirmationModal'
import { dateToString } from '../../utilities/auxiliary-functions'
import lclService from '../../services/lcl.service'
import Loader from '../../components/loader/loader'

const CheckIn: React.FC = () => {

    const [ openConfirmationModal, setOpenConfirmationModal ] = useState(false)
    const [ checkinDisabled, setCheckInDisabled ] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const [ modalType, setModalType ] = useState('')

    const navigate = useNavigate()
    const { reservation = '' } = useParams<'reservation'>()

    const [ partnerName, setPartnerName ] = useState('')
    const [ navegation, setNavegation ] = useState('')
    const [ departure, setDeparture ] = useState('')
    const [ forecast, setForecast ] = useState('')
    const [ move, setMove ] = useState(0)
    const [ checkIn, setCheckIn ] = useState('')
    const [ conductor, setConductor ] = useState('')
    const [ vesselName, setVesselName ] = useState('')
    const [ vesselType, setVesselType ] = useState('')
    const [ vesselModel, setVesselModel ] = useState('')
    const [ vesselYear, setVesselYear ] = useState('')
    const [ vesselEnrollment, setVesselEnrollment ] = useState('')
    const [ vesselSize, setVesselSize ] = useState('')
    const [ vesselColor, setVesselColor ] = useState('')
    const [ vesselOwners, setVesselOwners ] = useState([])
    const [ keepBookcaseName, setKeepBookcaseName ] = useState('')
    const [ keepShelfName, setKeepShelfName ] = useState('')
    const [ keepDrawerName, setKeepDrawerName ] = useState('')

    const handleConfirmationModal = (type: string) => {
        setOpenConfirmationModal(true)
        setModalType(type)
    }

    const handleCloseModal = (event: any, reason: any) => {
        if (reason && reason === 'backdropClick') return
        setOpenConfirmationModal(false)
        setModalType('')
    }

    const handleClickCheckIn = (action: string, registration: string) => {
        setCheckIn(action) // 'canceled' | 'confirmed'
        lclService.updateCheckIn(reservation, action, registration)
    }

    const confirm = () => {
        navigate(-1)
    }

    useEffect(() => {
        getReservation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getReservation = async() => {
        setLoading(true)

        const response = await lclService.getByLocator(reservation)

        if (response) {
            setPartnerName(response.partnerName)
            setNavegation(dateToString(response.navegation, 'dd/mm/yyyy'))
            setDeparture(dateToString(response.departure, 'HH:MM'))
            setForecast(dateToString(response.forecast, 'HH:MM'))
            setMove(response.move)
            setCheckIn(response.checkIn)
            setConductor(response.conductor)
            setVesselName(response.vesselName)
            setVesselType(response.vesselType)
            setVesselModel(response.vesselModel)
            setVesselYear(response.vesselYear)
            setVesselEnrollment(response.vesselEnrollment)
            setVesselSize(response.vesselSize)
            setVesselColor(response.vesselColor)
            setVesselOwners(response.vesselOwners)
            setKeepBookcaseName(response.keepBookcaseName)
            setKeepShelfName(response.keepShelfName)
            setKeepDrawerName(response.keepDrawerName)

            const today = new Date().toLocaleDateString()
            const reservationDate = new Date(
                new Date(response.navegation).setDate(
                new Date(response.navegation).getDate() + 1
                )
            ).toLocaleDateString()

            if (reservationDate !== today) {
                setCheckInDisabled(true)
            }
        }
        setLoading(false)
    }

  return (
    <div className={styles.checkIn}>
        <div className={styles.wrapper}>
        {loading ? (
            <Loader />
        ) : (
            <>
        <h2 className="text-center mb-3 mt-3">{partnerName}</h2>
            <section className={styles.reservation}>
            <CheckInCard
                navegation={navegation}
                departure={departure}
                forecast={forecast}
                locator={reservation}
                checkIn={checkIn}
                conductor={conductor}
                vesselName={vesselName}
                vesselType={vesselType}
                vesselModel={vesselModel}
                vesselYear={vesselYear}
                vesselEnrollment={vesselEnrollment}
                vesselSize={vesselSize}
                vesselColor={vesselColor}
                keepBookcaseName={keepBookcaseName}
                keepShelfName={keepShelfName}
                keepDrawerName={keepDrawerName}
            />
            </section>
            <section className={styles.checkInControls}>
            <div className="d-flex justify-content-center flex-column">
                {move === 0 && checkIn === 'confirmed' && (
                <Button
                    variant="contained"
                    color="success"
                    size="large"
                    className={styles.checkInButton}
                    onClick={() => handleConfirmationModal('requestDeparture')}
                >
                    <p>
                    SOLICITAR DESCIDA
                    <KeyboardDoubleArrowDownIcon
                        sx={{ fontSize: 26 }}
                        className="ms-2"
                    />
                    </p>
                </Button>
                )}
                {(move === 0 || move === 1) && checkIn === 'confirmed' && (
                <Button
                    variant="contained"
                    color="error"
                    size="large"
                    className={styles.cancelButton}
                    onClick={() => handleConfirmationModal('cancelCheckin')}
                >
                    <p>CANCELAR CHECK-IN</p>
                </Button>
                )}
                {checkIn === 'pending' && (
                <>
                    <div>
                    <Button
                        variant="contained"
                        color="success"
                        size="large"
                        disabled={checkinDisabled}
                        className={`${styles.checkInButton} ${
                        checkinDisabled ? styles.disabled : ''
                        } mb-3`}
                        onClick={() => handleConfirmationModal('confirm')}
                    >
                        EFETUAR CHECK-IN
                    </Button>
                    {checkinDisabled && (
                        <p className="mt-2 ms-3">
                        Check-in disponível em {navegation}
                        </p>
                    )}
                    </div>
                    <Button
                    variant="contained"
                    color="error"
                    size="large"
                    className={styles.cancelButton}
                    onClick={() => handleConfirmationModal('cancel')}
                    >
                    CANCELAR RESERVA
                    </Button>
                </>
                )}
                <Button
                variant="contained"
                color="primary"
                size="large"
                className={`${styles.logoutButton} mt-5`}
                onClick={() => handleConfirmationModal('logout')}
                >
                SAIR
                <LogoutIcon className="ms-2" />
                </Button>
            </div>
            </section>
            </>
        )}

        </div>

        <CheckInConfirmationModal
            open={openConfirmationModal}
            handleClose={handleCloseModal}
            height="fit-content"
            width={450}
            reservation={reservation}
            owners={vesselOwners}
            conductor={conductor}
            type={modalType}
            onConfirm={confirm}
            onCancel={handleCloseModal}
            onCancelReservation={(registration: string) =>
                handleClickCheckIn('canceled', registration)
            }
            onLogout={confirm}
            onCheckIn={(registration: string) =>
                handleClickCheckIn('confirmed', registration)
            }
        />
    </div>
  )
}

export default CheckIn
