import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Row, OverlayTrigger, Tooltip, Image } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { FaEye } from 'react-icons/fa'
import { RiEdit2Fill, RiNewspaperFill } from 'react-icons/ri'
import FinancialHeader from '../headers/financial.header'
import authService from '../../services/auth.service'
import lclService from '../../services/lcl.service'
import { dateToString, leftPad, toInt } from '../../utilities/auxiliary-functions'
import { AlertMessageEnum, getContractProgress } from '../../utilities/types'
import { ContractProgressEnum, PermissionEnum, VESSEL_JETSKI, VESSEL_LANCHA } from '../../utilities/constants'
import ContractCadastreModal from '../modals/contract.cadastre.modal'
import { VesselOwner } from '../../components/sub-render.component'
import { useAlertMessageContext } from '../../contexts/alert-message.context'
import styles from './contratcts.financial.module.scss'
import { BsReceipt } from 'react-icons/bs'
import cduService from '../../services/cdu.service'
import { openDocument } from '../../utilities/view-document'
import Jet from '../../assets/Jetski.svg';
import Lancha from '../../assets/Lancha.svg';
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { ThemeProvider } from '@mui/material/styles';
import theme from "../../theme";
import { useTranslateContext } from '../../contexts/translate.context'
import { usePartnerContext } from '../../contexts/partner.context'
import { isEmpty } from '../../utilities/validators'

export const FILTER_VESSEL_WITHOUT_ACTIVE_CONTRACT = 0;
export const FILTER_CONTRACTS_ACTIVE = 1;
export const FILTER_VESSELS_WITHOUT_CONTRACTS = 2;
export const FILTER_CONTRACTS_IN_PROGRESS = 3;
export const FILTER_CONTRACTS_INACTIVE = 4;

export interface IContratctsFinancialProps {
}

export const ContratctsFinancial: React.FC<IContratctsFinancialProps> = (props: IContratctsFinancialProps) => {
  
    const navigator = useNavigate();
    
    const alertContext = useAlertMessageContext();
    const { display } = useTranslateContext();
    const { partner } = usePartnerContext();

    const title = display.title.contratct;
    const description = '';

    let reloadPage = false;
    const [isLoading, setLoading] = useState(false);

    const [typeFilter, setTypeFilter] = useState(2);

    const [qtyVesselsWithoutContracts, setQtyVesselsWithoutContracts] = useState(0);
    const [qtyContractsInProgress, setQtyContractsInProgress] = useState(0);
    const [qtyContractsActive, setQtyContractsActive] = useState(0);
    const [qtyContractsInactive, setQtyContractsInactive] = useState(0);

    const [vesselsWithoutContracts, setVesselsWithoutContracts] = useState([] as any);
    const [contractsInProgress, setContractsInProgress] = useState([] as any);
    const [contractsActive, setContractsActive] = useState([] as any);
    const [contractsInactive, setContractsInactive] = useState([] as any);

    const [records, setRecords] = useState([] as any);

    const columnsVessel: GridColDef[] = [
        { field: 'identifyKey', headerName: 'Nome | ID', flex: 0.5, align: 'center', headerAlign: 'center', renderCell: renderNameAndId },
        { field: 'color', headerName: 'TIPO | COR', flex: 0.5, align: 'center', headerAlign: 'center', renderCell: renderNormAndColor },
        { field: 'enrollment', headerName: display.label.enrollment, flex: 0.5, align: 'center', headerAlign: 'center', renderCell: renderEnrollment },
        { field: 'manufacturer', headerName: display.label.manufacturer, flex: 0.5, align: 'center', headerAlign: 'center', renderCell: renderManufacturer },
        { field: 'brand', headerName: 'MAR | MOD', flex: 0.5, align: 'center', headerAlign: 'center', renderCell: renderBrandAndModel },
        {
            field: 'action',
            headerName: 'Ações',
            headerAlign: 'center',
            renderCell: renderVesselButtons,
            disableExport: true,
        }
    ];

    const columnsContract: GridColDef[] = [
        { field: 'contractKey', headerName: display.label.contract_key, flex: 0.5, align: 'center', headerAlign: 'center', renderCell: renderContractKey },
        { field: 'identifyKey', headerName: 'Nome | ID', flex: 0.5, align: 'center', headerAlign: 'center', renderCell: renderNameAndIdForContract },
        { field: 'color', headerName: 'TIPO | COR', flex: 0.5, align: 'center', headerAlign: 'center', renderCell: renderNormAndColorForContract },
        { field: 'enrollment', headerName: display.label.enrollment, flex: 0.5, align: 'center', headerAlign: 'center', renderCell: renderEnrollmentForContract },
        { field: 'manufacturer', headerName: display.label.manufacturer, flex: 0.5, align: 'center', headerAlign: 'center', renderCell: renderManufacturerForContract },
        { field: 'brand', headerName: 'MAR | MOD', flex: 0.5, align: 'center', headerAlign: 'center', renderCell: renderBrandAndModelForContract },
        { field: 'owner', headerName: display.label.owner, flex: 0.5, align: 'center', headerAlign: 'center', renderCell: renderOwner },
        { field: 'confection', headerName: display.label.confection, flex: 0.5, align: 'center', headerAlign: 'center', renderCell: renderConfection },
        { field: 'progress', headerName: display.label.progress, flex: 0.5, align: 'center', headerAlign: 'center', renderCell: renderProgress },
        {
            field: 'action',
            headerName: 'Ações',
            headerAlign: 'center',
            renderCell: renderContractButtons,
            disableExport: true,
        }
    ];

    function renderVesselButtons(param: any) {
        const vesselRow = param.row;

        return (
            <div className="d-flex w-100 justify-content-center">
                {authService.hasPermission(PermissionEnum.FINANCIAL_CONTRACT_CREATE) ? (
                    <OverlayTrigger overlay={
                        <Tooltip id="tooltip">{display.tooltips.to_create} {display.tooltips.contract}</Tooltip>
                    }>
                        <span onClick={e => onClick_AddContractForVessel(e, vesselRow)}>
                            <RiNewspaperFill size={18} />
                        </span>
                    </OverlayTrigger>
                ) : null}
            </div>
        )
    }

    function renderContractButtons(param: any) {
        const contractRow = param.row;

        return (
            <div className="d-flex w-100 justify-content-center">
                {toInt(contractRow.progress) !== ContractProgressEnum.FINISHED ? (
                    authService.hasPermission(PermissionEnum.FINANCIAL_CONTRACT_EDIT) ? (
                        <OverlayTrigger overlay={
                            <Tooltip id="tooltip">{display.tooltips.to_edit} {display.tooltips.contract}</Tooltip>
                        }>
                            <span onClick={e => onClick_EditContract(e, contractRow)}>
                                <RiEdit2Fill size={18} />
                            </span>
                        </OverlayTrigger>
                    ) : null
                ) : authService.hasPermission(PermissionEnum.FINANCIAL_CONTRACT_VIEW) ? (
                    <>
                        <OverlayTrigger overlay={
                            <Tooltip id="tooltip">{display.tooltips.to_view} {display.tooltips.contract}</Tooltip>
                        }>
                            <span onClick={e => onClick_EditContract(e, contractRow)}>
                                <FaEye size={18} />
                            </span>
                        </OverlayTrigger>
                        {(contractRow.artifact) ?
                            <OverlayTrigger overlay={
                                <Tooltip id="tooltip">{display.tooltips.to_view} {display.tooltips.contract}</Tooltip>
                            }>
                                <span onClick={e => onClick_ReportContract(e, contractRow.artifact)}>
                                    <BsReceipt size={18} />
                                </span>
                            </OverlayTrigger>
                        : null }
                    </>
                ) : null }
            </div>
        )
    }

    function renderContractKey(param: any) {
        const contract = param.row;

        return (<div className="white-space">{ dateToString(contract.confection, 'yyyymm') + leftPad(contract.id, 4, '0') }</div>);
    }

    function renderConfection(param: any) {
        const contract = param.row;

        return (<div className="white-space">{dateToString(contract.confection, 'dd/mm/yyyy')}</div>);
    }

    function renderProgress(param: any) {
        const contract = param.row;

        return (<div className="white-space">{getContractProgress(contract.progress).description}</div>);
    }

    function renderNameAndIdForContract(param: any) {
        const contract = param.row;

        const renders = [] as any;
        for (let ind = 0; ind < contract.vessels.length; ind++) {
            const contractVessel = contract.vessels[ind];
            const param = { row: contractVessel.vessel };
            renders.push(renderNameAndId(param, ind));
        }
        return renders;
    }

    function renderNameAndId(param: any, index: number = 0) {
        const vessel = param.row;
        return (
            <div key={`name-and-id-${index}`} className="d-flex flex-column align-items-center justify-content-center">
                <div className="white-space mt-2">{(vessel.name) ? vessel.name : '-'}</div>
                <div className="white-space mt-1">{vessel.identifyKey}</div>
            </div>
        );
    }

    function renderNormAndColorForContract(param: any) {
        const contract = param.row;

        const renders = [] as any;
        for (let ind = 0; ind < contract.vessels.length; ind++) {
            const contractVessel = contract.vessels[ind];
            const param = { row: contractVessel.vessel };
            renders.push(renderNormAndColor(param, ind));
        }
        return renders;
    }

    function renderNormAndColor(param: any, index: number = 0) {
        const vessel = param.row;

        return (
            toInt(vessel.typeVessel) === VESSEL_LANCHA ? (
                <div key={`norm-and-color-${index}`} className="d-flex flex-column align-items-center justify-content-center mb-2">
                    <div className="mt-2 d-flex justify-content-center mb-2">
                        <Image src={Lancha} className="jet" style={{height: 32}}/>
                        <small className="ms-2">{`${(vessel.vesselSize) ? vessel.vesselSize : '-'}'`}</small>
                    </div>
                    <span className={styles.vesselColor} style={{backgroundColor: `${vessel.color}`}}></span>
                </div>
            ):(
                <div key={`norm-and-color-${index}`} className="d-flex flex-column align-items-center justify-content-center mb-2">
                    <div className="d-flex justify-content-center mb-2">
                        <Image src={Jet} className="jet mt-3" style={{height: 32}}/>
                        <small className="ms-1 mt-3">{`10'`}</small>
                    </div>
                    <span className={styles.vesselColor} style={{backgroundColor: `${vessel.color}`}}></span>
                </div>
            )
        );
    }   

    function renderEnrollmentForContract(param: any) {
        const contract = param.row;

        const renders = [] as any;
        for (let ind = 0; ind < contract.vessels.length; ind++) {
            const contractVessel = contract.vessels[ind];
            const param = { row: contractVessel.vessel };
            renders.push(renderEnrollment(param, ind));
        }
        return renders;
    }

    function renderEnrollment(param: any, index: number = 0) {
        const vessel = param.row;

        return (
            <div key={`enrollment-${index}`} className="white-space">{vessel.enrollment}</div>
        );
    }

    function renderManufacturerForContract(param: any) {
        const contract = param.row;

        const renders = [] as any;
        for (let ind = 0; ind < contract.vessels.length; ind++) {
            const contractVessel = contract.vessels[ind];
            const param = { row: contractVessel.vessel };
            renders.push(renderManufacturer(param, ind));
        }
        return renders;
    }

    function renderManufacturer(param: any, index: number = 0) {
        const vessel = param.row;

        return (
            <div key={`manufacturer-${index}`} className="white-space">{vessel.manufacturer}</div>
        );
    }

    function renderBrandAndModelForContract(param: any) {
        const contract = param.row;

        const renders = [] as any;
        for (let ind = 0; ind < contract.vessels.length; ind++) {
            const contractVessel = contract.vessels[ind];
            const param = { row: contractVessel.vessel };
            renders.push(renderBrandAndModel(param, ind));
        }
        return renders;
    }

    function renderBrandAndModel(param: any, index: number = 0) {
        const vessel = param.row;
        return (
            <div key={`brand-and-model-${index}`} className="d-flex flex-column align-items-center justify-content-center">
                <div className="white-space mt-2">{vessel.brand}</div>
                <div className="white-space mt-1">{`${(vessel.model) ? vessel.model : '-'}`}</div>
            </div>
        );
    }

    function renderOwner(param: any) {
        const vessel = param.row;

        return <VesselOwner values={vessel} />
    }

    const [contractId, setContractId] = useState('');
    const [contract, setContract] = useState({} as any);
    const [isEditContract, setEditContract] = useState(false);
    const [showContract, setShowContract] = useState(false);

    async function searchfilter() {
        if (!isEmpty(partner)) {
            let contracts_default = null
            try {
                let contracts = null;
                if (authService.isProprietor()) {
                    const user = authService.currentUser();
                    const people = await cduService.peopleByUser(user.id);
                    contracts = await lclService.listContracts(partner.id, 'PF', people.id);
                } else
                    contracts = await lclService.listContracts(partner.id);

                for ( let c = 0; c < contracts.contractsActive.length; c++) {
                    const contract = contracts.contractsActive[c];

                    const artifact = await cduService.artifactByNorm('CONTRACT', `${contract.id}`, 'FILE_SIGNATURE');
                    contracts.contractsActive[c].artifact = artifact;
                }

                contracts_default = contracts.vesselsWithoutContracts;
                setVesselsWithoutContracts(contracts.vesselsWithoutContracts);
                setQtyVesselsWithoutContracts(contracts.vesselsWithoutContracts.length);
                setContractsInProgress(contracts.contractsInProgress);
                setQtyContractsInProgress(contracts.contractsInProgress.length);
                setContractsActive(contracts.contractsActive);
                setQtyContractsActive(contracts.contractsActive.length);
                setContractsInactive(contracts.contractsInactive);
                setQtyContractsInactive(contracts.contractsInactive.length);
            } catch (error: any) {
                setVesselsWithoutContracts([] as any);
            }
            viewFilter(FILTER_VESSELS_WITHOUT_CONTRACTS, contracts_default);
        } else {
            await alertContext.show(AlertMessageEnum.FAIL, title, 'Não foi definido uma Marina para exibir os contratos !');
            navigator(`/portal`);
        }
    } // searchfilter

    useEffect(() => {
        if (!isLoading && !reloadPage) {
            searchfilter()
            setLoading(true)
        }

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            reloadPage = !reloadPage
        }
    }, []) // useEffect

    /* START */

    function viewFilter(type: number, contracts?: any) {
        setTypeFilter(type)
        if (type === FILTER_VESSEL_WITHOUT_ACTIVE_CONTRACT) {
            setRecords([] as any);
        } else if (type === FILTER_CONTRACTS_ACTIVE) {
            setRecords(contractsActive);
        } else if (type === FILTER_CONTRACTS_INACTIVE) {
            setRecords(contractsInactive);
        } else if (type === FILTER_CONTRACTS_IN_PROGRESS) {
            setRecords(contractsInProgress);
        } else {
            if (vesselsWithoutContracts.length > 0) {
                setRecords(vesselsWithoutContracts);
            } else if (contracts) 
                setRecords(contracts);
            else 
                setRecords(vesselsWithoutContracts);
        }
    }

    function onClick_Filter(event: any, type: number) {
        event.preventDefault();
        viewFilter(type);
    }

    function onClick_AddContractForVessel(event: any, vessel: any) {
        event.preventDefault();

        const vessels = [] as any;
        vessels[0] = vessel;
        setEditContract(false);
        setContractId('new');
        setContract({
            id: 0,
            created: dateToString(new Date(), 'dd/mm/yyyy HH:MM:ss'),
            updated: dateToString(new Date(), 'dd/mm/yyyy HH:MM:ss'),
            vessels
        } as any);
        setShowContract(true);
    } // onClick_AddRecord

    function onClick_EditContract(event: any, contract: any) {
        event.preventDefault();

        setEditContract(true);
        setContractId(contract.id);
        setContract(contract);
        setShowContract(true);
    } // onClick_EditRecord

    function onClick_ReportContract(event: any, artifact: any) {
        event.preventDefault();
        openDocument(title, artifact.document);
    } // onClick_ReportContract

    /* END */

    function viewPage() {

        return (
            <Container fluid className="page-body contratcts">
                <Row>
                    <Col md={12} className="page-sweet">
                        <Card style={{ minHeight: 500 }}>
                            <Card.Header>
                                <Card.Title>{display.subtitle.contratcts}</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col>
                                        <div className="postits">
                                            <div className={`postit isClickable ${typeFilter === 2 ?  styles.active : ''}`} onClick={e => onClick_Filter(e, FILTER_VESSELS_WITHOUT_CONTRACTS)}>
                                                <div className="postit-body">
                                                    {qtyVesselsWithoutContracts}{' '}
                                                </div>
                                                <div className="postit-header">{display.legend.vessels_without_contracts}</div>
                                                <div className="postit-footer" />
                                            </div>
                                            <div className={`postit isClickable ${typeFilter === 3 ?  styles.active : ''}`} onClick={e => onClick_Filter(e, FILTER_CONTRACTS_IN_PROGRESS)}>
                                                <div className="postit-body">
                                                    {qtyContractsInProgress}
                                                </div>
                                                <div className="postit-header">{display.legend.contracts_in_progress}</div>
                                                <div className="postit-footer" />
                                            </div>
                                            <div className={`postit isClickable ${typeFilter === 1 ? styles.active : ''}`} onClick={e => onClick_Filter(e, FILTER_CONTRACTS_ACTIVE)}>
                                                <div className="postit-body">
                                                    {qtyContractsActive}
                                                </div>
                                                <div className="postit-header">{display.legend.contracts_active}</div>
                                                <div className="postit-footer" />
                                            </div>
                                            <div className={`postit isClickable ${typeFilter === 4 ? styles.active : ''}`} onClick={e => onClick_Filter(e, FILTER_CONTRACTS_INACTIVE)}>
                                                <div className="postit-body">
                                                    {qtyContractsInactive}
                                                </div>
                                                <div className="postit-header">Inativos</div>
                                                <div className="postit-footer" />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="d-flex justify-content-center mt-3 mb-0">
                                    <p className='mb-0'>(Clique nos status acima para filtrar os contratos)</p>
                                </div>
                                <Row><Col><hr /></Col></Row>
                                <Row>
                                    <Col>
                                        <ThemeProvider theme={theme}>
                                            <div style={{ height: 402, width: '100%', marginTop: 0 }}>
                                                <DataGrid
                                                    rows={records}
                                                    //rowCount={recordCount}
                                                    getRowId={row => row.id}
                                                    // loading={isLoading}
                                                    columns={typeFilter === FILTER_VESSELS_WITHOUT_CONTRACTS ? columnsVessel : columnsContract}
                                                    /*
                                                    pageSize={pageSize}
                                                    onPageSizeChange={newPageSize => onChangePageSize(newPageSize)}
                                                    page={page}
                                                    onPageChange={newPage => onChangePage(newPage) }
                                                    
                                                    rowsPerPageOptions={[10, 25, 50, 75, 100]}
                                                    disableSelectionOnClick
                                                    // onCellClick={(e) => console.log(e)}
                                                    onSelectionModelChange={(newSelectionModel: any) => {
                                                        setSelectionModel(newSelectionModel)
                                                    }}
                                                    components={{
                                                        Toolbar: GridToolbar,
                                                        LoadingOverlay: LinearProgress
                                                    }}
                                                    selectionModel={selectionModel}
                                                    */
                                                />
                                            </div>
                                        </ThemeProvider>    
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    } // viewCadastreList

    return (
        <div className="page financial">
            <FinancialHeader
                title={title}
                description={description}
            />
            {viewPage()}
            <ContractCadastreModal
                typeFilter={FILTER_VESSEL_WITHOUT_ACTIVE_CONTRACT}
                isEdit={isEditContract}
                show={showContract}
                contractId={contractId}
                record={contract}
                setShowContract={(value: any) => {
                    setShowContract(value)
                }}
                onSubmitModal={(event: any, record: any) => {
                    event.preventDefault()

                    setShowContract(false)
                    setEditContract(false)
                    searchfilter()
                }}
                onCancelModal={(event: any) => {
                    // event.preventDefault();

                    setEditContract(false)
                    setShowContract(false)
                }}
            />
        </div>
    );
}

export default ContratctsFinancial
