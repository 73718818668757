import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CadastreHeader from "../headers/cadastre.header";
import VesselCadastreForm from "./forms/vessel.cadastre.form";
import VesselCadastreList from "./lists/vessel.cadastre.list";
import { useTranslateContext } from "../../contexts/translate.context";

export interface IVesselCadastreProps {
    viewOnly?: boolean
}

export const VesselCadastre: React.FC<IVesselCadastreProps> = (props: IVesselCadastreProps) => {

    const { vesselId } = useParams();
    const { display } = useTranslateContext();

    const title = display.title.vessel;
    const description = '';

    let reloadPage = false;
    const [ isLoading, setLoading ] = useState(false);
    const [ recordSelected, setRecordSelected ] = useState({ param : vesselId || '', selected : ((vesselId === 'new') || (vesselId === '0') || (!Number.isNaN(Number.parseInt(vesselId ||'')))) });

    function setSelected(isRecord: boolean, recordId: string) {
        setRecordSelected({ param : recordId, selected : isRecord });
    }

    async function initialize() {
    }
  
    useEffect(() => {
        if (!isLoading && !reloadPage) {
            initialize();
            setLoading(true);
        }

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            reloadPage = !reloadPage;
        }
    }, []);
    
    return (
        <div className="page">
            <CadastreHeader title={title} description={description} />
            {(recordSelected.selected) ? (
                <VesselCadastreForm 
                    title={title}
                    vesselId={recordSelected.param}
                    setRecord={(event: any) => { 
                        event.preventDefault();
                        setSelected(false, '0'); 
                    }}
                    viewOnly={props.viewOnly}
                />
            ) : (
                <VesselCadastreList 
                    title={title}
                    setRecord={(event: any, recordId: string) => { 
                        event.preventDefault();
                        setSelected(true, recordId); 
                    }}
                    viewOnly={props.viewOnly}
                />
            )}
        </div>
    )
}

export default VesselCadastre;
