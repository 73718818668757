import { useEffect, useMemo, useState } from "react";
import { Row, Col, Container, Modal } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import { FaTimes } from "react-icons/fa";

import { useTranslateContext } from "../../contexts/translate.context";

import TableContainer from "../../components/table-container.component";
import { useAlertMessageContext } from "../../contexts/alert-message.context";
import cduService from "../../services/cdu.service";
import { toLowerCase } from "../../utilities/auxiliary-functions";
import { AlertMessageEnum, getCategory } from "../../utilities/types";
import { isEmpty } from "../../utilities/validators";
import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, Input, InputAdornment, InputLabel } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import styles from './category.filter.module.scss';
import { TFilter } from "../../types/FilterType";

export interface ICategoryFilterProps {
    show: any,
    onClick_Close: any,
    onClick_Confirm: any,

    team: any,
    categoryNotInList: any
}

export const CategoryFilter: React.FC<ICategoryFilterProps> = (props: ICategoryFilterProps) => {
    
    const alertContext = useAlertMessageContext();
    const { display } = useTranslateContext();

    const title : string = display.title.category_filter +' '+ display.label[toLowerCase(getCategory(props.team).name)];

    const [ textSearch, setTextSearch ] = useState('');
    const [ record, setRecord ] = useState({} as any);
    const [ records, setRecords ] = useState([] as any);
    const [ originalRecords, setOriginalRecords ] = useState([]);
    const [ selectedsMaterialType, setSelectedsMaterialType ] = useState<Array<number>>([]);

    const columnsRecord = useMemo(() => [{
        Header: display.label.category,
        accessor: "group"
    },{
        Header: display.label.subcategory,
        accessor: "subgroup"
    },{
        Header: display.label.description,
        accessor: "name",
        Cell: (row: any) => (<div className="white-space">{row.value}</div>)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }], []); // columnsRecord

    async function initialize() {
        try {
            if (props.show) {
                const filter = {
                    where: { team: props.team }
                } as TFilter;
                let categoryFilter = await cduService.categoryFilter(filter); // {count: 0, rows: []} as any; 
                setRecords(categoryFilter.rows);
                setOriginalRecords(categoryFilter.rows);
                setRecord({} as any);
            }
        } catch(error: any) {
            await alertContext.show(AlertMessageEnum.FAIL, title, error);
        }
    }

    useEffect(() => {
        initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show]); // useEffect

    const filterRecords = (key?: string, value?: string) => {

        const filtered: any = []
        records.filter((record: any) => {
          if (
            record.group
              .toLocaleLowerCase()
              .includes(textSearch.toLocaleLowerCase()) ||
            record.subgroup
              .toLocaleLowerCase()
              .includes(textSearch.toLocaleLowerCase()) ||
            record.name
              .toLocaleLowerCase()
              .includes(textSearch.toLocaleLowerCase())
          )
          filtered.push(record)
          return record
        })
        setRecords(filtered)
      return ''
    }

    useEffect(() => {
        if (textSearch.length >= 3) {
            filterRecords()
        } else if (textSearch.length < 3 && selectedsMaterialType.length === 0) {
          setRecords(originalRecords)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [textSearch, selectedsMaterialType]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, receivedMaterial: number) => {
        let filteredSelectedMaterialType = []
        if (selectedsMaterialType.includes(receivedMaterial)) {
            filteredSelectedMaterialType = selectedsMaterialType.filter((material: any) => material !== receivedMaterial)
            setSelectedsMaterialType(filteredSelectedMaterialType)
        } else {
            filteredSelectedMaterialType = [...selectedsMaterialType, receivedMaterial]
            setSelectedsMaterialType(filteredSelectedMaterialType)
        }
        filterByMaterials(filteredSelectedMaterialType)
    };

    const filterByMaterials = (filteredMaterials?: Array<number>) => {
        const filtered = originalRecords.filter((record: any) => filteredMaterials?.includes(record.material))
        setRecords(filtered)
    }
    
    return (
        <>
            <Modal
                className={styles.filter}
                size="lg"
                show={props.show}
                centered
            >
                <Modal.Header>
                    <Modal.Title><FaSearch />{title}</Modal.Title>
                    <div className="card-actions float-right">
                        <FaTimes className="isClickable" size={22} onClick={props.onClick_Close} />
                    </div>
                </Modal.Header>
                <Modal.Body style={{ minHeight : 620 }}>
                    <Row>
                        <Col>
                            <div className="d-flex justify-content-between mb-4">
                                <FormControl fullWidth sx={{width: 300, marginBottom: 2 }} variant="standard">
                                    <InputLabel className={styles.label} htmlFor="standard-adornment-amount">Pesquisar</InputLabel>
                                    <Input id="standard-adornment-amount"
                                        value={textSearch}
                                        onChange={(e) => setTextSearch(e.target.value)}
                                        endAdornment = {
                                            <InputAdornment position="end">
                                                <SearchIcon className='isClickable' />
                                            </InputAdornment>
                                        }
                                    />
                                    <FormHelperText id="component-helper-text">
                                        Categoria, Subcategoria ou Descrição
                                    </FormHelperText>
                                </FormControl>
                                <FormGroup className="d-flex flex-column">
                                    <FormControlLabel control={<Checkbox size="small" checked={selectedsMaterialType.includes(1)} onChange={(e) => handleChange(e, 1)} />} label="Administrativo" />
                                    <FormControlLabel control={<Checkbox size="small" checked={selectedsMaterialType.includes(2)} onChange={(e) => handleChange(e, 2)} />} label="Uso Consumo" />
                                    <FormControlLabel control={<Checkbox size="small" checked={selectedsMaterialType.includes(3)} onChange={(e) => handleChange(e, 3)} />} label="Operação" />
                                </FormGroup>
                                <FormGroup className="d-flex flex-column">
                                    <FormControlLabel control={<Checkbox size="small" checked={selectedsMaterialType.includes(4)} onChange={(e) => handleChange(e, 4)} />} label="Oficina" />
                                    <FormControlLabel control={<Checkbox size="small" checked={selectedsMaterialType.includes(5)} onChange={(e) => handleChange(e, 5)} />} label="Bistrô e Restaurante" />
                                </FormGroup>
                            </div>
                            <TableContainer columns={ columnsRecord } data={ records } viewPagination={true} setSelectedRow={ 
                                (event: any, dataRow: any) => { 
                                    setRecord(dataRow); 
                                }}
                            />
                        </Col>
                    </Row>                    
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row>
                            <Col sm="8">
                                <div className="filter-selectred"><strong>{display.label.selected_record}:</strong> <span>{!isEmpty(record) ? record.group +' \\ '+ (isEmpty(record.subgroup) ? record.name : record.subgroup +' \\ '+ record.name) : ''}</span></div>
                            </Col>
                            <Col sm="4">
                                <Button variant="contained" color="secondary" className="ms-2" onClick={ (e) => props.onClick_Close(e) } >{display.buttom.cancel}</Button>{' '}
                                <Button variant="contained" color="primary" onClick={ (e) => props.onClick_Confirm(e, record) }  disabled={isEmpty(record)}>{display.buttom.confirm}</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default CategoryFilter;
