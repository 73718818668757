import { useEffect, useState } from "react";

import { Container, Image } from "react-bootstrap";
import DashboardHeader from "../headers/dashboard.header";
import Loader from "../../components/loader/loader";

import { useMediaContext } from "../../contexts/media.context";
import styles from './CustomizeDashboard.module.scss';
import { ClockAndClimate } from "./components/ClockAndClimate";
import { Avatar, Box, Card, CardContent, CardHeader, Icon, IconButton, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridMoreVertIcon, GridValueGetterParams } from '@mui/x-data-grid';
import { ResumeBox, ResumeBoxCard } from "./components/ResumeBox";

import Jet from '../../assets/Jetski.svg';
import Lancha from '../../assets/Lancha.svg';
import Navegando from '../../assets/Navegando.svg';
import Abastecimento from '../../assets/Abastecimento.svg';
import Manutenca0_externa from '../../assets/Manutenca0_externa.svg';
import Movimento_entrada from '../../assets/Movimento_entrada.svg';
import Movimento_saida from '../../assets/Movimento_saida.svg';
import Higiene from '../../assets/Limpeza.svg';
import selo from '../../assets/selo.png';

import Users from '../../assets/Users.svg';
import ClockHistory from '../../assets/ClockHistory.svg';
import SportsScore from '../../assets/SportsScore.svg';
import { ArrowOutward, AspectRatio, GetApp, Launch, ResetTv, SettingsOverscan, Upload, WebAsset, WebAssetOff } from "@mui/icons-material";

export interface ICustomizeDashboardProps {
    
}

const CustomizeDashboard: React.FC<ICustomizeDashboardProps> = (props: ICustomizeDashboardProps) => {
    
	const title = 'Painel Instrumental';
	const description = "";

    const { isPortrait, isTv, isMonitor, isTablet, isSmart } = useMediaContext();
    const [ loading, setLoading ] = useState(false);
    const [ gridAsset, setGridAsset ] = useState(false);
    
    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            // handleClickRefresh(new Date());
            setLoading(false);
        }, 200)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 90 },
        {
          field: 'firstName',
          headerName: 'First name',
          width: 150,
          editable: true,
        },
        {
          field: 'lastName',
          headerName: 'Last name',
          width: 150,
          editable: true,
        },
        {
          field: 'age',
          headerName: 'Age',
          type: 'number',
          width: 110,
          editable: true,
        },
        {
          field: 'fullName',
          headerName: 'Full name',
          description: 'This column has a value getter and is not sortable.',
          sortable: false,
          width: 160,
          valueGetter: (params: GridValueGetterParams) =>
            `${params.row.firstName || ''} ${params.row.lastName || ''}`,
        },
    ];
    
    const rows = [
        { id: 1, lastName: 'Snow', firstName: 'Jon', age: 14 },
        { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 31 },
        { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 31 },
        { id: 4, lastName: 'Stark', firstName: 'Arya', age: 11 },
        { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
        { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
        { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
        { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
        { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
    ];

    function onClick_GridAsset(event: any) {
        setGridAsset(!gridAsset);
    }

    function viewDashboard() {
        return (
            <Container fluid className="page-body dashboards">
                <div className={styles.mainTitle}>
                    <h1>MOVIMENTAÇÃO</h1>
                </div>
                <div className={styles.mainInfo}>
                    <ClockAndClimate />
                </div>
                <ResumeBox>
                    <ResumeBoxCard label="EMBARCAÇÕES" datas={[{ image: Lancha, value: "0" }, { image: Jet, value: "0" }]} />
                    <ResumeBoxCard label="VIDAS" image={Users} value="0" />
                    <ResumeBoxCard label="NAVEGANDO" image={Navegando} value="0" rotating={true}/>
                    <ResumeBoxCard label="ATRASO" image={ClockHistory} value="0" />
                    <ResumeBoxCard label="FINALIZADOS" image={SportsScore} value="0" />
                    <ResumeBoxCard label="TEMP. MED. NAVEG." value="00:00" />
                </ResumeBox>
                <Box className="flex-grow-1 d-flex">
                    <Card className={`${gridAsset ? styles.cardTableMaximized : styles.cardTable} flex-grow-1`}>
                        <CardHeader className={styles.cardTableHeader}
                            // avatar={<Avatar aria-label="recipe">R</Avatar>}
                            title="Acompanhamento das Embarcações" 
                            // subheader="September 14, 2016"
                            action={
                                <IconButton aria-label="settings" onClick={e => onClick_GridAsset(e) }>
                                    {!gridAsset ? <WebAsset /> : <WebAssetOff />}
                                </IconButton>
                            }
                        />
                        <CardContent className={styles.cardTableContent} sx={{ height: gridAsset ? '96%' : '90%', width: '100%' }}>
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                pageSize={5}
                                rowsPerPageOptions={[5]}
                                checkboxSelection
                                disableSelectionOnClick
                                experimentalFeatures={{ newEditingApi: true }}
                            />
                        </CardContent>
                    </Card>
                </Box>
            </Container>
        );
    }

    return (
        <div className="dashboard">
            <DashboardHeader title={title} description={description} />
            {loading ? (
                <Loader />
            ) : (
                viewDashboard()
            )}
        </div>
    );
}

export default CustomizeDashboard;
