import { useEffect, useMemo, useState } from "react";
import { Badge, Button, Card, Col, Container, Form, FormGroup, ListGroup, Row, Spinner } from "react-bootstrap";
import { AiOutlinePartition } from "react-icons/ai";
import { MdNewReleases, MdOutlineApartment } from "react-icons/md";
import TableContainer from "../../components/table-container.component";
import { useAlertMessageContext } from "../../contexts/alert-message.context";
import { useConfirmMessageContext } from "../../contexts/confirm-message.context";
import cduService from "../../services/cdu.service";
import lclService from "../../services/lcl.service";
import { dateToString, toLowerCase } from "../../utilities/auxiliary-functions";
import { UserSituationEnum } from "../../utilities/constants";
import { cnpjMask } from "../../utilities/masks";
import { AlertMessageEnum, getPartnerSituation, getUserSituation, UserSituationType } from "../../utilities/types";
import { isEmpty } from "../../utilities/validators";
import SettingHeader from "../headers/settings.header";
import { TFilter } from "../../types/FilterType";
import { useTranslateContext } from "../../contexts/translate.context";

export interface IUserAccessPageProps {
}

export const UserAccessPage: React.FC<IUserAccessPageProps> = (props: IUserAccessPageProps) => {
    
    const alertContext = useAlertMessageContext();
    const confirmContext = useConfirmMessageContext();
    const { display } = useTranslateContext();

    const title = display.title.user_access;
    const description = display.description.user_access;

    let reloadPage = false;
    const [ isLoading, setLoading ] = useState(false);
    const [ sending, setSending ] = useState(false);
    const [ selectedSession, setSelectedSession ] = useState('releaseSession');

    const [ user, setUser ] = useState({} as any);
    const [ roles, setRoles ] = useState([] as any);
    const [ userRoles, setUserRoles ] = useState([] as any);
    const [ partners, setPartners ] = useState([] as any);
    const [ userPartners, setUserPartners ] = useState([] as any);

    const [peoples, setPeoples ] = useState([]);
    const columnsPeople = useMemo(() => [{
        Header: display.label.fullname,
        accessor: "fullname",
        Cell: (row: any) => (<div className="white-space">{row.value}</div>)
    },{
        Header: display.label.gender,
        accessor: "gender",
        Cell: (row: any) => (<div className="white-space">{row.value}</div>)
    },{
        Header: display.label.email,
        accessor: "email",
        Cell: (row: any) => (<div className="white-space">{row.value}</div>)
    },{
        Header: display.label.username,
        accessor: "user.username",
        Cell: (row: any) => (<div className="white-space">{row.value}</div>)
    },{
        Header: display.label.situation,
        accessor: "user.situation",
        Cell: (row: any) => (<div className="white-space">{display.label[toLowerCase(getUserSituation(row.value).name)]}</div>)
    },{
        Header: display.label.created,
        accessor: "user.created",
        Cell: (row: any) => (<div className="white-space">{dateToString(row.value, 'dd/mm/yyyy HH:MM')}</div>)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }], []); // columnsRecord

    async function searchfilter() {
        const attributes = [] as any;
        const where = {} as any;
        const order = [] as any;

        try {
            const peoples = await cduService.peopleAccess();
            setPeoples(peoples)
            const roles = await cduService.roleFilter(attributes, where, order);
            setRoles(roles);
            const filter = {} as TFilter;
            let partnerFilter = await lclService.partnerFilter(filter); // {count: 0, rows: []} as any; 
            setPartners(partnerFilter.rows);
        } catch (error: any) {
            alertContext.show(AlertMessageEnum.FAIL, title, error);
        }
    }

    useEffect(() => {
        if (!isLoading && !reloadPage) {
            searchfilter();
            setLoading(true);
        }

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            reloadPage = !reloadPage;
        }
    }, []);

    function onChange_User(event: any, field: string) {
        const localUser = {} as any;

        Object.keys(user).forEach((key: string) => {
            if (field === key)
                localUser[key] = event.target.value;
            else
                localUser[key] = user[key];
        });
        setUser(localUser);
    }

    function isUserProfile(roleId: number) {
        var ckecked = false;
        userRoles.forEach((userRole: any) => {
            if (userRole.roleId === roleId)
                ckecked = true;
        });
        return ckecked;
    }

    function onClick_UserProfile(event: any, roleId: number) {
        const localUserRole = [] as any;

        if (event.target.checked) {
            const newUserRole = {userId: user.id, roleId};
            for (var i = 0; i < userRoles.length; i++) {
                const userRole = userRoles[i];
                const currentUserRole = {userId: user.id, roleId: userRole.roleId};
                localUserRole[localUserRole.length] = currentUserRole;
            }
            localUserRole[localUserRole.length] = newUserRole;
        } else {
            for (var j = 0; j < userRoles.length; j++) {
                const userRole = userRoles[j];
                if (userRole.roleId !== roleId) {
                    const currentUserRole = {userId: user.id, roleId: userRole.roleId};
                    localUserRole[localUserRole.length] = currentUserRole;
                }
            }
        }
        setUserRoles(localUserRole);
    }
    
    function isUserPartnerPrincipal(partnerId: number) {
        var ckecked = false;
        userPartners.forEach((userPartner: any) => {
            if (userPartner.partnerId === partnerId)
                ckecked = userPartner.principal > 0;
        });
        return ckecked;
    }

    function isUserPartner(partnerId: number) {
        var ckecked = false;
        userPartners.forEach((userPartner: any) => {
            if (userPartner.partnerId === partnerId)
                ckecked = true;
        });
        return ckecked;
    }

    function onClick_UserPartner(event: any, partnerId: number) {
        const localUserPartners = [] as any;

        if (event.target.checked) {
            
            var principal = 0;
            if (userPartners.length === 0)
                principal = 1;

            const newUserPartner = {userId: user.id, partnerId, principal};
            for (var i = 0; i < userPartners.length; i++) {
                const userPartner = userPartners[i];
                const currentUserPartner = {userId: user.id, partnerId: userPartner.partnerId, principal: userPartner.principal};
                localUserPartners[localUserPartners.length] = currentUserPartner;
            }
            localUserPartners[localUserPartners.length] = newUserPartner;
        } else {
            // eslint-disable-next-line @typescript-eslint/no-redeclare
            var principal = 0;
            for (var j = 0; j < userPartners.length; j++) {
                const userPartner = userPartners[j];
                if (userPartner.partnerId !== partnerId) {
                    const currentUserPartner = {userId: user.id, partnerId: userPartner.partnerId, principal: userPartner.principal};
                    localUserPartners[localUserPartners.length] = currentUserPartner;
                } else {
                    principal = userPartner.principal;
                }
            }
            if (principal && (localUserPartners.length > 0))
                localUserPartners[0].principal = 1;
        }
        setUserPartners(localUserPartners);
    }

    function onClick_UserPartnerPrincipal(event: any, partnerId: number) {
        const localUserPartners = [] as any;

        for (var j = 0; j < userPartners.length; j++) {
            const userPartner = userPartners[j];
            var currentUserPartner = null;
            if (userPartner.partnerId !== partnerId) {
                currentUserPartner = {userId: user.id, partnerId: userPartner.partnerId, principal: 0};
            } else {
                currentUserPartner = {userId: user.id, partnerId: userPartner.partnerId, principal: 1};
            }

            localUserPartners[localUserPartners.length] = currentUserPartner;
        }

        setUserPartners(localUserPartners);
    }

    async function onClick_SaveUser(event: any) {
        event.preventDefault();

        try {
            setSending(true);
            const isConfirmed = await confirmContext.show(title, display.message.confirm_record);
            if (isConfirmed) {
                let salvedUser = null;
                if (user.id > 0)
                    salvedUser = await cduService.saveUser('PrivateInfo', user.id, user);
                else
                    salvedUser = await cduService.createUser('PrivateInfo', user);
                const localUser = {
                    id: salvedUser.id,
                    email : user.email,
                    peopleId : user.peopleId,
                    username : user.username,
                    situation : user.situation
                }
                setUser(localUser);
                searchfilter();
            }
            setSending(false);
        } catch (error: any) {
            await alertContext.show(AlertMessageEnum.FAIL, title, error);
            setSending(false);
        }
    }

    async function onClick_GenerateTokenUser(event: any) {
        event.preventDefault();

        try {
            setSending(true);
            if (user.id > 0) {
                const isConfirmed = await confirmContext.show(title, 'Deseja enviar um TOKEN ao usuário ?');
                if (isConfirmed) {
                    await cduService.userGenerateToken(user.email);
                }
            } else {
                await alertContext.show(AlertMessageEnum.FAIL, title, 'Usuário ainda não existe, salve primeiro');
            }
            setSending(false);
        } catch (error: any) {
            await alertContext.show(AlertMessageEnum.FAIL, title, error);
            setSending(false);
        }
    }

    async function onClick_SaveUserProfile(event: any) {
        event.preventDefault();

        try {
            setSending(true);
            if (user.id > 0) {
                const isConfirmed = await confirmContext.show(title, display.message.confirm_record);
                if (isConfirmed) {
                    await cduService.saveUserRoles(user.id, userRoles);
                }
            } else {
                await alertContext.show(AlertMessageEnum.FAIL, title, 'Usuário ainda não existe, salve primeiro');
            }
            setSending(false);
        } catch (error: any) {
            await alertContext.show(AlertMessageEnum.FAIL, title, error);
            setSending(false);
        }
    }
    
    async function onClick_SaveUserPartner(event: any) {
        event.preventDefault();

        try {
            setSending(true);
            if (user.id > 0) {
                const isConfirmed = await confirmContext.show(title, display.message.confirm_record);
                if (isConfirmed) {
                    await lclService.savePartnersUser(user.id, userPartners);
                }
            } else {
                await alertContext.show(AlertMessageEnum.FAIL, title, 'Usuário ainda não existe, salve primeiro');
            }
            setSending(false);
        } catch (error: any) {
            await alertContext.show(AlertMessageEnum.FAIL, title, error);
            setSending(false);
        }
    }
    
    function viewPage() {

        return (
            <Container fluid className="page-body user_access">
                <Row>
                    <Col sm="12" className="page-header">
                        <Card style={{width: '100%'}}>
                            <Card.Header>
                                <Card.Title>{display.legend.listing}</Card.Title>
                            </Card.Header>
                            <Card.Body style={{ minHeight : 500 }}>
                                <TableContainer className={'table-list-record'} columns={columnsPeople} data={peoples} 
                                    viewFilter={true} 
                                    setSelectedRow={async (event: any, dataRow: any) => { 
                                        try {
                                            let user = {
                                                id : dataRow.user ? dataRow.userId : 0,
                                                email : dataRow.email,
                                                peopleId : dataRow.id,
                                                username : dataRow.user ? dataRow.user.username : dataRow.fullname.split(' ')[0],
                                                situation : dataRow.user ? dataRow.user.situation : UserSituationEnum.INACTIVE
                                            }
                                            setUser(user);

                                            if (user.id > 0) {
                                                const attributes = [] as any;
                                                const where = {} as any;
                                                const order = [] as any;

                                                const userRoles = await cduService.userRolesFilter(user.id, attributes, where, order);
                                                setUserRoles(userRoles);

                                                const userPartners = await lclService.partnersUser(user.id);
                                                setUserPartners(userPartners);
                                            }
                                        } catch(error: any) {
                                            alertContext.show(AlertMessageEnum.FAIL, title, error);
                                        }
                                    }}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col><h5>Edição do usuário escolhido</h5></Col>
                </Row>
                <Row>
                    <Col sm="3" className="page-session">
                        <Card>
                            <Card.Header>
                                <Card.Title>{display.legend.information}</Card.Title>
                            </Card.Header>
                            <ul className={`list-group list-group-flush`}>
                                <li className={selectedSession === 'releaseSession' ? 'active' : ''}>
                                    <p className="list-group-item-action list-group-item" onClick={() => setSelectedSession('releaseSession')}>
                                        <MdNewReleases size={23} /><span>{display.legend.release}</span>
                                    </p>
                                </li>
                                <li className={selectedSession === 'rolesSession' ? 'active' : ''}>
                                    <p className="list-group-item-action list-group-item" onClick={() => setSelectedSession('rolesSession')}>
                                        <AiOutlinePartition size={23} /><span>{display.legend.profiles}</span>
                                    </p>
                                </li>
                                <li className={selectedSession === 'partnersSession' ? 'active' : ''}>
                                    <p className="list-group-item-action list-group-item" onClick={() => setSelectedSession('partnersSession')}>
                                        <MdOutlineApartment size={23} /><span>{display.legend.partners}</span>
                                    </p>
                                </li>
                            </ul>
                        </Card>
                    </Col>
                    <Col sm="9" className="page-sweet">
                        {selectedSession === 'releaseSession' && (
                        <Card>
                            <Card.Header>
                                <Card.Title>{display.legend.private_information}</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col sm="4">
                                        <FormGroup>
                                            <Form.Label htmlFor="form-username">{display.label.username}</Form.Label>
                                            <Form.Control id="form-username" name="username" required placeholder={display.example.username}
                                                value={user.username || ''}
                                                onChange={(e: any) => onChange_User(e, 'name')}
                                                readOnly
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="5">
                                        <FormGroup>
                                            <Form.Label htmlFor="form-email">{display.label.email}</Form.Label>
                                            <Form.Control id="form-email" name="email" required placeholder={display.example.email}
                                                value={user.email || ''}
                                                onChange={(e: any) => onChange_User(e, 'email')}
                                                readOnly
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="3">
                                        <FormGroup>
                                            <Form.Label htmlFor="form-mode">{display.label.situation}</Form.Label>
                                            <Form.Select id="form-mode" name="situation" 
                                                value={user.situation || ''}
                                                onChange={(e) => onChange_User(e, 'situation')}
                                            >
                                                { isEmpty(user.situation) ? (
                                                    <option value="">...</option>
                                                ) : null }
                                                { UserSituationType.map((iterator: any, idx: number) => (
                                                    <option key={idx} value={iterator.id}>{display.label[toLowerCase(iterator.name)]}</option>
                                                )) }
                                            </Form.Select>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Card.Body>
                            <Card.Footer>
                                <Button onClick={(e) => onClick_SaveUser(e)} disabled={sending}>
                                    { sending ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null }{'  '}
                                    { display.buttom.refresh }
                                </Button>
                                <Button variant="warning" onClick={(e) => onClick_GenerateTokenUser(e)} disabled={sending}>
                                    { sending ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null }{'  '}
                                    { display.buttom.generate_token }
                                </Button>
                            </Card.Footer>
                        </Card>
                        )}
                        {selectedSession === 'rolesSession' && (
                        <Card>
                            <Card.Header>
                                <Card.Title>{display.legend.profiles}</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col>
                                        <FormGroup className="roles-check">
                                            { roles.map((role: any, idx: number) => (
                                                <Form.Check key={`role-${idx}`}
                                                    type='checkbox'
                                                    id={`${role.name}-checkbox`}
                                                    label={role.name}
                                                    checked={isUserProfile(role.id)}
                                                    onChange={(e: any) => onClick_UserProfile(e, role.id)}
                                                />
                                            )) }
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Card.Body>
                            <Card.Footer>
                                <Button onClick={(e) => onClick_SaveUserProfile(e)} disabled={sending}>
                                    { sending ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null }{'  '}
                                    { display.buttom.refresh }
                                </Button>
                            </Card.Footer>
                        </Card>
                        )}
                        {selectedSession === 'partnersSession' && (
                        <Card>
                            <Card.Header>
                                <Card.Title>{display.legend.partners}</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col>
                                        <ListGroup>
                                            { partners.map((partner: any, idx: number) => (
                                                <ListGroup.Item key={`group-${idx}`} className="d-flex justify-content-between align-items-start">
                                                    <Form.Check 
                                                        type='radio'
                                                        id={`${partner.id}-radio`}
                                                        checked={isUserPartnerPrincipal(partner.id)}
                                                        onChange={e => onClick_UserPartnerPrincipal(e, partner.id)}
                                                    />
                                                    <Form.Check 
                                                        type='checkbox'
                                                        id={`${partner.id}-checkbox`}
                                                        checked={isUserPartner(partner.id)}
                                                        onChange={e => onClick_UserPartner(e, partner.id)}
                                                    />
                                                    <div className="w-70">
                                                        {partner.company && cnpjMask(partner.company.registrationPj)}<br />{partner.company && partner.company.socialReason}
                                                    </div>
                                                    <Badge>{display.label[toLowerCase(getPartnerSituation(partner.situation).name)]}</Badge>
                                                </ListGroup.Item>
                                            )) }
                                        </ListGroup>
                                    </Col>
                                </Row>
                            </Card.Body>
                            <Card.Footer>
                                <Button onClick={(e) => onClick_SaveUserPartner(e)} disabled={sending}>
                                    { sending ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null }{'  '}
                                    { display.buttom.refresh }
                                </Button>
                            </Card.Footer>
                        </Card>
                        )}
                    </Col>
                </Row>
            </Container>
        );    
    } // viewPage

    return (
        <div className="page">
            <SettingHeader title={title} description={description} />
            { viewPage() }
        </div>
    )
}

export default UserAccessPage;
