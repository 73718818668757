import { useEffect, useMemo, useState } from "react";
import { Col, Image, Modal, Row } from "react-bootstrap";
import { FaSearch, FaTimes } from "react-icons/fa";
import Jet from '../../assets/Jetski.svg';
import Lancha from '../../assets/Lancha.svg';
import { VesselOwner } from "../../components/sub-render.component";
import TableContainer from "../../components/table-container.component";
import { useAlertMessageContext } from "../../contexts/alert-message.context";
import authService from "../../services/auth.service";
import lclService from "../../services/lcl.service";
import { VESSEL_JETSKI } from "../../utilities/constants";
import { AlertMessageEnum } from "../../utilities/types";
import { isEmpty } from "../../utilities/validators";
import { FILTER_VESSEL_WITHOUT_ACTIVE_CONTRACT } from "../financial/contratcts.financial";
import { toInt } from "../../utilities/auxiliary-functions";
import { FormControl, FormHelperText, Input, InputAdornment, InputLabel } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import styles from './vessel.filter.module.scss';
import { TFilter } from "../../types/FilterType";
import { useTranslateContext } from "../../contexts/translate.context";
import { usePartnerContext } from "../../contexts/partner.context";

export interface IVesselFilterProps {
    show: any,
    owner?: any,
    vesselNotInList?: any,
    typeFilter?: any,
    subTitle?: any,
    onClick_Close: any,
    onClick_Confirm: any,
}

export const VesselFilter: React.FC<IVesselFilterProps> = (props: IVesselFilterProps) => {
    
    const alertContext = useAlertMessageContext();
    const { display } = useTranslateContext();
    const { partner } = usePartnerContext();

    const title : string = display.title.vessel_filter;

    const [ textSearch, setTextSearch ] = useState('');
    const [ records, setRecords ] = useState([]);
    const [originalRecords, setOriginalRecords ] = useState([]);

    const columnsRecord = useMemo(() => [{
            Header: 'Chave ID',
            accessor: "identifyKey"
        },{
            Header: display.label.name,
            accessor: "name"
        },{
            Header: display.label.enrollment,
            accessor: "enrollment",
            Cell: (row: any) => (<div className="white-space">{row.value}</div>)
        },{
            Header: 'Proprietário(s)',
            // accessor: 'owner',
            Cell: (row: any) => <VesselOwner values={row} />
        },{
            Header: display.label.type,
            Cell: (props: any) => {
                const vessel = props.row.original

                return (
                    <>
                    { toInt(vessel.typeVessel) === VESSEL_JETSKI ? (
                        <div className="d-flex justify-content-center">
                            <Image src={Jet} className="jet" style={{
                                height: 32}}/>
                            <small className="ms-1">10'</small>
                        </div>
                    ):(
                        <div className="d-flex justify-content-center">
                            <Image src={Lancha} className="jet" style={{
                                height: 32}}/>
                            <small className="ms-1">{`${vessel.vesselSize || 0}'`}</small>
                        </div>
                    )}
                    </>
                )
            }
        },{
            Header: display.label.color,
            accessor: "color",
            Cell: (row: any) => (<div className="white-space">
                <div className="table-column-color">
                    <span className="table-column-block30x30" style={{backgroundColor: `${row.value}`}}></span>
                </div>
            </div>)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }], []
    ); // columnsRecord

    async function initialize() {
        try {
            if (props.show) {
                const attributes = [] as any;
                const where = {
                    // vesselId : { notIn : props.vesselNotInList }
                } as any;
                const order = [] as any;

                if (props.owner) {
                    if (!isEmpty(props.owner.id)) {
                        where['ownerType'] = props.owner.type;
                        where['ownerId'] = props.owner.id;
                    }
                }
                /* Sempre incluir o partner na pesquisa */
                where['partnerId'] = partner.id;

                let vessels = null;
                if (props.typeFilter === FILTER_VESSEL_WITHOUT_ACTIVE_CONTRACT)
                    vessels = await lclService.vesselWithoutActiveContract(attributes, where, order);
                else {
                    const filter = {
                        where: {
                            'partners.partnerId': partner.id
                        },
                    } as TFilter;
                    let vesselFilter = await lclService.vesselFilter(filter); // {count: 0, rows: []} as any; 
                    vessels = vesselFilter.rows;
                }

                setRecords(vessels);
                setOriginalRecords(vessels);
            }
        } catch(error: any) {
            await alertContext.show(AlertMessageEnum.FAIL, title, error);
        }
    }

    useEffect(() => {
        initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show]); // useEffect

    const onSelectRecord = (e:any , dataRow: any) => {
        props.onClick_Confirm(e, dataRow)
    }

    const filterRecords = (key?: string, value?: string) => {

        const filtered: any = []
        records.filter((record: any) => {
          if (
            record.identifyKey
              .toLocaleLowerCase()
              .includes(textSearch.toLocaleLowerCase()) ||
            record.name
              .toLocaleLowerCase()
              .includes(textSearch.toLocaleLowerCase()) ||
            record.enrollment
              .toLocaleLowerCase()
              .includes(textSearch.toLocaleLowerCase())
          )
          filtered.push(record)
          return record
        })
        setRecords(filtered)
      return ''
    }

    useEffect(() => {
        if (textSearch.length >= 3) {
            filterRecords()
        } else if (textSearch.length < 3) {
          setRecords(originalRecords)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [textSearch]);

    return (
        <Modal
            className={styles.filter}
            size="lg"
            show={props.show}
            centered
        >
            <Modal.Header>
                <Modal.Title><FaSearch />{title}</Modal.Title>
                <div className="card-actions float-right">
                    <FaTimes className="isClickable" size={22} onClick={props.onClick_Close} />
                </div>
            </Modal.Header>
            <Modal.Body style={{ minHeight : 400 }}>
                <Row>
                    <Col>
                        <h6>{props.subTitle}</h6>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormControl fullWidth sx={{width: 300, marginBottom: 2 }} variant="standard">
                            <InputLabel className={styles.label} htmlFor="standard-adornment-amount">Pesquisar</InputLabel>
                            <Input id="standard-adornment-amount"
                                value={textSearch}
                                onChange={(e) => setTextSearch(e.target.value)}
                                endAdornment = {
                                    <InputAdornment position="end">
                                        <SearchIcon className='isClickable' />
                                    </InputAdornment>
                                }
                            />
                            <FormHelperText id="component-helper-text">
                                Nome ou CPF
                            </FormHelperText>
                        </FormControl>
                        <TableContainer columns={ columnsRecord } data={ records } viewPagination={true} setSelectedRow={ 
                            (event: any, dataRow: any) => onSelectRecord(event, dataRow)} 
                        />
                    </Col>
                </Row>                    
            </Modal.Body>
        </Modal>
    )
}

export default VesselFilter;
