import { useEffect, useState, useMemo } from "react";
import { Card, Col, Container, Form, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap";
import { FaEdit, FaSave, FaTrash } from "react-icons/fa";
import { useAlertMessageContext } from "../../../contexts/alert-message.context";
import { useConfirmMessageContext } from "../../../contexts/confirm-message.context";
import cduService from "../../../services/cdu.service";
import srvService from "../../../services/srv.service";
import { dateToString, notNullToDate } from "../../../utilities/auxiliary-functions";
import { PersonEnum } from "../../../utilities/constants";
import { cnpjMask, cpfMask, floatMask, removeFormatDate, removeMask } from "../../../utilities/masks";
import { isEmpty, isValidCpfCnpj } from "../../../utilities/validators";
import CompanyFilter from "../../filters/company.filter";
import PeopleFilter from "../../filters/people.filter";
import CatalogCadastreModal from "../../modals/catalog.cadastre.modal";
import CompanyModal from "../../modals/company.modal";
import PeopleModal from "../../modals/people.modal";
import PurveyorRecord from "../../records/purveyor.record";
import { Button } from '@mui/material'
import { AlertMessageEnum } from "../../../utilities/types";
import { useTranslateContext } from "../../../contexts/translate.context";
import { usePartnerContext } from "../../../contexts/partner.context";

export interface IPurveyorCadastreFormProps {
    title: string,
    purveyorId: string,
    setRecord: any
}

export const PurveyorCadastreForm: React.FC<IPurveyorCadastreFormProps> = (props: IPurveyorCadastreFormProps) => {
    
    const alertContext = useAlertMessageContext();
    const confirmContext = useConfirmMessageContext();
    const { display } = useTranslateContext();
    const { partner } = usePartnerContext();

    const [ invalidatedForm, setInvalidatedForm ] = useState(false);
    const [ isEditForm, setEditForm ] = useState(true);
    const [ isEditItem, setEditItem ] = useState(true);
    const [ showFilterPeople, setShowFilterPeople ] = useState(false);
    const [ showFilterCompany, setShowFilterCompany ] = useState(false);
    const [ showPeople, setShowPeople ] = useState(false);
    const [ showCompany, setShowCompany ] = useState(false);
    const [ showCalalog, setShowCalalog ] = useState(false);

    let reloadPage = false;
    const [ isLoading, setLoading ] = useState(false);
    const [ originRecord, setOriginRecord ] = useState({} as any);
    const [ sending, setSending ] = useState(false);

    /* TRATAMENTO PARTICULAR DO OBJETO QUE ESTA SENDO MANTIDO */
    // Purveyor
    const [ purveyorId, setPurveyorId ] = useState(0);
    const [ ownerType, setOwnerType ] = useState(PersonEnum.PESSOA_FISICA);
    const [ ownerId, setOwnerId ] = useState(0);
    const [ ownerName, setOwnerName ] = useState('');
    const [ ownerRegistry, setOwnerRegistry ] = useState('');
    const [ owner, setOwner ] = useState({} as any);
    const [ situation, setSituation ] = useState(0);
    const [ created, setCreated ] = useState(dateToString(new Date(), 'dd/mm/yyyy HH:MM:ss'));
    const [ updated, setUpdated ] = useState(dateToString(new Date(), 'dd/mm/yyyy HH:MM:ss'));

    const [ catalog, setCatalog ] = useState({} as any);
    const [ catalogs, setCatalogs ] = useState([] as any);
    const columnsCatalog = useMemo(() => [{
            Header: display.label.code,
            accessor: "product.code",
        },{
            Header: display.label.category,
            accessor: "product.category",
            Cell: (row: any) => (<div className="white-space">{!isEmpty(row.value) ? row.value.group +' \\ '+ (isEmpty(row.value.subgroup) ? row.value.name : row.value.subgroup +' \\ '+ row.value.name) : ''}</div>)
        },{
            Header: display.label.reference,
            accessor: "product.reference"
        },{
            Header: display.label.description,
            accessor: "product.description",
            Cell: (row: any) => (<div className="white-space">{row.value}</div>)
        },{
            Header: display.label.sale,
            accessor: 'sale',
            Cell: (row: any) => (<div className="white-space">{floatMask(row.value)}</div>)
        },{
            Header: display.legend.actions,
            accessor: "actions",
            Cell: (props: any) => {
                let purveyorRows = [] as any;
                const originals = props.rows;
                for (var o = 0; o < originals.length; o++) {
                    purveyorRows[purveyorRows.length] = originals[o].original;
                }
                const purveyorRow = props.row.original;

                return (
                    <div className="white-space table-actions">
                        <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.toEdit}</Tooltip>}>
                            <span onClick={(e) => onClick_EditItemRecord(e, purveyorRow)}>
                                <FaEdit size={18} />
                            </span>
                        </OverlayTrigger>
                        <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.delete}</Tooltip>}>
                            <span onClick={(e) => onClick_DeleteItemRecord(e, purveyorRows, purveyorRow)}>
                                <FaTrash size={18} />
                            </span>
                        </OverlayTrigger>
                    </div>
                );
            },
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[]); // columnsCatalog

    async function fillRecord(purveyor: any) {

        if (!isEmpty(purveyor)) {
            setPurveyorId(purveyor.id);

            setOwnerType(purveyor.ownerType);
            setOwnerId(purveyor.ownerId);
            setOwner(purveyor.owner);
            
            if (purveyor.ownerId) {
                try {
                    if (purveyor.ownerType === PersonEnum.PESSOA_FISICA) {
                        const people = await cduService.peopleById(purveyor.ownerId);
                        setOwnerRegistry(cpfMask(people.registrationPf));
                        setOwnerName(people.fullname);
                    } else if (purveyor.ownerType === PersonEnum.PESSOA_JURIDICA) {
                        const company = await cduService.companyById(purveyor.ownerId);
                        setOwnerRegistry(cnpjMask(company.registrationPj));
                        setOwnerName(company.fantasy);
                    }
                } catch (error: any) {
                    await alertContext.show(AlertMessageEnum.FAIL, props.title, error);
                }
            }

            setCatalogs(purveyor.catalogs);
            setSituation(purveyor.situation);

            setCreated(notNullToDate(purveyor.created, 'dd/mm/yyyy HH:MM:ss'));  
            setUpdated(notNullToDate(purveyor.updated, 'dd/mm/yyyy HH:MM:ss'));  
        }
    } // fillRecord

    async function isValidForm() {
        let result = true;
        let emptyRequired = false;

        if (isEmpty(ownerType))
            emptyRequired = true;
        if (isEmpty(ownerRegistry))
            emptyRequired = true;
        if (isEmpty(ownerId)) {
            emptyRequired = true;
            setOwnerName('')
            setOwnerRegistry('')
        }

        if (emptyRequired) {
            await alertContext.show(AlertMessageEnum.FAIL, props.title, display.message.invalid.required);
            result = false;
        }

        setInvalidatedForm(!result);
        return result;
    } // isValidForm

    function onClick_CancelPurveyor(event: any) {
        event.preventDefault();
        
        //navigator(`/portal/cadastre/purveyor`);
        props.setRecord(event, 0)
    } // onClick_CancelPurveyor

    function fillPurveyor() {

        const dataPurveyor = {
            id : purveyorId,
            ownerType,
            ownerId, 
            owner, 
            catalogs,
            situation, 
            created : removeFormatDate(created), 
            updated : removeFormatDate(updated),
        };

        return dataPurveyor;
    } // fillPurveyor

    async function onClick_SavePurveyor(event: any) {
        event.preventDefault();
        
        const isValid = await isValidForm();
        if (isValid) {
            try {
                const isConfirmed = await confirmContext.show(props.title, display.message.confirm_record);
                if (isConfirmed) {
                    setSending(true);

                    const purveyor = fillPurveyor();
                    let returned = null;
                    if (isEditForm)
                        returned = await srvService.savePurveyor(purveyor.id, purveyor);
                    else
                        returned = await srvService.createPurveyor(purveyor);
                    setSending(false);
                    await alertContext.show(AlertMessageEnum.SUCCESS, props.title, display.message.the_record_has_been_saved_successfully);
                    //navigator(`/portal/cadastre/purveyor`);
                    props.setRecord(event, returned);
                }
            } catch (error: any) {
                setSending(false);
                await alertContext.show(AlertMessageEnum.FAIL, props.title, error);
            }
        }
    } // onClick_SavePurveyor

    async function searchParam() {
        try {
            if (!Number.isNaN(Number.parseInt(props.purveyorId ||''))) {
                setEditForm(true);

                const purveyor = await srvService.purveyorById(Number(props.purveyorId));
                setOriginRecord(purveyor);
                fillRecord(purveyor);
            } else {
                setEditForm(false);
            }
        } catch(error: any) {
            await alertContext.show(AlertMessageEnum.FAIL, props.title, error);
        }
    } // searchParam

    async function searchOwner(event: any) {
        event.preventDefault(event);

        if (!isEmpty(ownerType)) {
            let registry = removeMask(ownerRegistry);
            if (isValidCpfCnpj(registry)) {
                try {
                    if (ownerType === PersonEnum.PESSOA_FISICA) {
                        const people = await cduService.peopleByRegistration(registry);
                        if (people) {
                            setOwnerId(people.id);
                            setOwnerRegistry(cpfMask(people.registrationPf));
                            setOwnerName(people.fullname);

                            setOwner(people);
                        } else {
                            await alertContext.show(AlertMessageEnum.WARN, props.title, 'O CPF não foi encontrado !!!');
                            setShowPeople(true);
                        }
                    } else if (ownerType === PersonEnum.PESSOA_JURIDICA) {
                        const company = await cduService.companyByRegistration(registry);
                        if (company) {
                            setOwnerId(company.id);
                            setOwnerRegistry(cnpjMask(company.registrationPj));
                            setOwnerName(company.fantasy);

                            setOwner(company);
                        } else {
                            await alertContext.show(AlertMessageEnum.WARN, props.title, 'O CNPJ não foi encontrado !!!');
                            setShowCompany(true);
                        }
                    } else
                        await alertContext.show(AlertMessageEnum.FAIL, props.title, 'Informação não encontrada.');
                } catch (error: any) {
                    await alertContext.show(AlertMessageEnum.FAIL, props.title, display.message.invalid.document_type);
                }
            } else {
                await alertContext.show(AlertMessageEnum.FAIL, props.title, display.message.the_document_is_not_valid);
            }
        } else {
            await alertContext.show(AlertMessageEnum.FAIL, props.title, display.message.owner_type_not_specified);
        }
    } // searchOwner

    function onClick_AddPerson(event: any) {
        event.preventDefault();

        if (ownerType === PersonEnum.PESSOA_FISICA)
            setShowPeople(true);
        else if (ownerType === PersonEnum.PESSOA_JURIDICA)
            setShowCompany(true);
    }

    function onClick_FilterPerson(event: any) {
        event.preventDefault();

        if (ownerType === PersonEnum.PESSOA_FISICA)
            setShowFilterPeople(true);
        else if (ownerType === PersonEnum.PESSOA_JURIDICA)
            setShowFilterCompany(true);
    }

    function onClick_ConfirmFilterPerson(event: any, record: any) {
        event.preventDefault();

        if (ownerType === PersonEnum.PESSOA_FISICA) {
            setOwnerId(record.id);
            setOwnerRegistry(cpfMask(record.registrationPf));
            setOwnerName(record.fullname);
            setOwner(record);
        } else if (ownerType === PersonEnum.PESSOA_JURIDICA) {
            setOwnerId(record.id);
            setOwnerRegistry(cnpjMask(record.registrationPj));
            setOwnerName(record.fantasy);
            setOwner(record);
        }
    }

    function onClick_AddItemRecord(event: any) {
        event.preventDefault();
        setCatalog({} as any);
        setEditItem(false);
        setShowCalalog(true);
    }

    function onClick_EditItemRecord(event: any, record: any) {
        event.preventDefault();
        setEditItem(true);
        setCatalog(record);
        setShowCalalog(true);
    }

    function onClick_DeleteItemRecord(event: any, records: any, record: any) {
        event.preventDefault();
    }

    function onClick_ConfirmCalalog(event: any, record: any) {
        event.preventDefault();
        if (!isEditItem) {
            setCatalogs([...catalogs, record]);
        } else {
            const local = [] as any;
            
            for (var i = 0; i < catalogs.length; i++) {
                const catalog = catalogs[i];
                if (catalog.productId === record.productId)
                    local[local.length] = record;
                else
                    local[local.length] = catalog;
            }
            setCatalogs(local);
        }
    }

    useEffect(() => {
        if (!isLoading && !reloadPage) {
            searchParam();
            setLoading(true);
        }

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            reloadPage = !reloadPage;
        }
    }, []); // useEffect

    function viewCadastreForm() {

        return (
            <Container fluid className="page-body purveyor">
                <Row>
                    <Col sm={12} className="page-sweet">
                        <Card>
                            <Card.Header>
                                {(originRecord.created || originRecord.updated) && (
                                    <div className="form-identy-registry float-right">
                                        <span className="white-space"><b>Criação: </b> {dateToString(originRecord.created, 'dd/mm/yyyy HH:MM:ss')}</span>
                                        <span className="white-space"><b>Atualização: </b> {dateToString(originRecord.updated, 'dd/mm/yyyy HH:MM:ss')}</span>
                                    </div>
                                )}
                                <Card.Title>{display.legend.record}</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Form className="form" id="form-cadastre" validated={invalidatedForm}>
                                    <PurveyorRecord
                                        title={props.title}
                                        isEdit={isEditForm}

                                        created={created}
                                        updated={updated}

                                        ownerType={ownerType}
                                        ownerId={ownerId}
                                        ownerName={ownerName}
                                        ownerRegistry={ownerRegistry}

                                        catalogs={catalogs}
                                        columnsCatalog={columnsCatalog}

                                        setOwnerType={(value: any) => { setOwnerType(value); }}
                                        setOwnerId={(value: any) => { setOwnerId(value); }}
                                        setOwnerName={(value: any) => { setOwnerName(value); }}
                                        setOwnerRegistry={(value: any) => { setOwnerRegistry(value); }}

                                        searchOwner={(event: any) => { searchOwner(event); }}
                                        filterPerson={(event: any) => { onClick_FilterPerson(event); }}
                                        addPerson={(event: any) => { onClick_AddPerson(event); }}
                                        addItemRecord={(event: any) => { onClick_AddItemRecord(event); }}
                                    />
                                </Form>
                            </Card.Body>
                            <Card.Footer className="text-right">
                                <Button className="me-2" onClick={ (e) => onClick_CancelPurveyor(e) } variant="contained" color="secondary">{display.buttom.cancel}</Button>
                                <Button onClick={ (e) => onClick_SavePurveyor(e) } disabled={sending} variant="contained" color="primary">
                                    { sending ? <Spinner className="me-2" as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <FaSave className="me-2" size={22} /> } {' '}
                                    {display.buttom.save}
                                </Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    } // viewCadastreForm

    return (
        <>
            { viewCadastreForm() }
            <PeopleFilter 
                show={showFilterPeople}

                onClick_Close={(event: any) => { 
                    setShowFilterPeople(false);
                }}
                onClick_Confirm={(event: any, value: any) => { 
                    setShowFilterPeople(false);
                    onClick_ConfirmFilterPerson(event, value); 
                }}
            />
            <CompanyFilter 
                show={showFilterCompany}

                onClick_Close={(event: any) => { 
                    setShowFilterCompany(false);
                }}
                onClick_Confirm={(event: any, value: any) => { 
                    setShowFilterCompany(false);
                    onClick_ConfirmFilterPerson(event, value); 
                }}
            />
            <PeopleModal 
                isEdit={false}
                show={showPeople}
                peopleId={ownerId}
                record={owner}

                registration={ownerRegistry}
            
                onSubmitModal={(event: any, record: any) => { 
                    setShowPeople(false);
                    setOwnerId(record.id);
                    setOwnerRegistry(cnpjMask(record.registrationPj));
                    setOwnerName(record.fullname);
                    setOwner(record);
                }}
                onCancelModal={(event: any) => { 
                    setShowPeople(false);
                }}
            />
            <CompanyModal 
                isEdit={false}
                show={showCompany}
                companyId={ownerId}
                record={owner}

                registration={ownerRegistry}

                onSubmitModal={(event: any, record: any) => { 
                    setShowCompany(false);
                    setOwnerId(record.id);
                    setOwnerRegistry(cnpjMask(record.registrationPj));
                    setOwnerName(record.fantasy);
                    setOwner(record);
                }}
                onCancelModal={(event: any) => { 
                    setShowCompany(false);
                }}
            />
            <CatalogCadastreModal
                show={showCalalog}
                purveyorId={purveyorId}
                productId={catalog.productId}
                record={catalog}
                onClick_Close={(event: any) => { 
                    setShowCalalog(false);
                }}
                onClick_Confirm={(event: any, value: any) => { 
                    setShowCalalog(false);
                    onClick_ConfirmCalalog(event, value); 
                }}
            />
        </>
    );

}

export default PurveyorCadastreForm;
