import SearchIcon from '@mui/icons-material/Search';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import TodayIcon from '@mui/icons-material/Today';
import { FormControl, FormHelperText, Input, InputAdornment, InputLabel } from '@mui/material';
import { useEffect, useMemo, useState } from "react";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Jet from '../../assets/Jetski.svg';
import Lancha from '../../assets/Lancha.svg';
import TableContainer from "../../components/table-container.component";
import { useAlertMessageContext } from "../../contexts/alert-message.context";
import lclService from "../../services/lcl.service";
import { AlertMessageEnum, getManufacturerName, getVessel } from "../../utilities/types";
import DashboardHeader from "../headers/dashboard.header";
import styles from './DeliveryOperation.module.scss';
import TimerIcon from '@mui/icons-material/Timer';
import CancelIcon from '@mui/icons-material/Cancel';
import DeliveryOrderModal from './delivery-order-modal/DeliveryOrderModal';
import { usePartnerContext } from '../../contexts/partner.context';
import { isEmpty } from '../../utilities/validators';

interface IDeliveryOperationProps {
    
}

export const DeliveryOperation: React.FC<IDeliveryOperationProps> = () => {
    
	const { partner } = usePartnerContext();
    const alertContext = useAlertMessageContext();
    const navigator = useNavigate();
  
    const title = "Delivery";
    const description = "";

    const [ textSearch, setTextSearch ] = useState('');
    const [ selectedOrder, setSelectedOrder ] = useState();
    const [ selectedFilter, setSelectedFilter] = useState('all')
    const [ originalRecords, setOriginalRecords ] = useState([]);
    const [ pendingOrders, setPendingOrders ] = useState([]);
    const [ canceledOrders, setCanceledOrders ] = useState([]);
    const [ finishedOrders, setFinishedOrders ] = useState([]);
    const [ records, setRecords ] = useState([]);
    const [ openConfirmationModal, setOpenConfirmationModal ] = useState(false)
    const [ modalType, setModalType ] = useState('')

    const handleCloseModal = (event: any, reason: any) => {
        if (reason && reason === 'backdropClick') return
        setOpenConfirmationModal(false)
        setModalType('')
    }

    const generateReservation = (length: number) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
          counter += 1;
        }
        return result.toUpperCase();
    }

    const generateOrder = (length: number) => {
        let result = '';
        const characters = '0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
          counter += 1;
        }
        return result.toUpperCase();
    }

    const generateDate = (start: any, end: any) => {
        return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
    }

    const generateOrderStatus  = () => {
        const options = ['Pendente', 'Cancelado', 'Finalizado']
        return options[Math.floor(Math.random() * options.length)];
    }
    const generateOperator  = () => {
        const options = ['Bruno', 'Roberio', 'Éber', '-']
        return options[Math.floor(Math.random() * options.length)];
    }
    const generatePayment  = () => {
        const options = ['Avulso', 'Mensalista']
        return options[Math.floor(Math.random() * options.length)];
    }

    const columnsRecord = useMemo(() => [{
        
        Header: 'Data | Hora',
        accessor: "date",
        Cell: (row: any) => {
            return (
                <>
                <div className={`mt-2 ${styles.info}`}>{new Date(row.value).toLocaleDateString('pt-BR')}</div>
                <div className={`mt-1 ${styles.info}`}>{new Date(row.value).toLocaleTimeString('pt-BR')}</div>
                </>
            )
            
            
        }
    },{
        Header: 'Nome | ID',
        accessor: "identifyKey",
        Cell: (row: any) => (
            <div className={`${styles.info} d-flex flex-column align-items-center justify-content-center`}>
                <div className={`${styles.info} white-space mt-2`}>{getName(row)}</div>
                <div className="white-space mt-1">{row.value}</div>
            </div>
        )
    },{
        Header: 'TIPO | COR',
        accessor: 'color',
        Cell: (row: any) => (
            getVessel(getVesselType(row)).id === 34 ? (
                <div className="d-flex flex-column align-items-center justify-content-center mb-2">
                    <div className="mt-2 d-flex justify-content-center mb-2">
                        <Image src={Lancha} className="jet" style={{height: 32}}/>
                        <small className="ms-2">{`${getVesselSize(row)}'`}</small>
                    </div>
                <span className={styles.vesselColor} style={{backgroundColor: `${row.value}`}}></span>
                </div>
            ):(
                <div className="d-flex flex-column align-items-center justify-content-center mb-2">
                    <div className="d-flex justify-content-center mb-2">
                        <Image src={Jet} className="jet mt-3" style={{height: 32}}/>
                        <small className="ms-1 mt-3">{`10'`}</small>
                    </div>
                    <span className={styles.vesselColor} style={{backgroundColor: `${row.value}`}}></span>
                </div>
            )
        )
    },{
        Header: 'MAR | MOD',
        accessor: "manufacturer",
        Cell: (row: any) => (
            <div className="d-flex flex-column align-items-center justify-content-center">
                <div className={`${styles.info} white-space mt-2`}>{getManufacturerName(row.value)}</div>
                <div className="white-space mt-1">{getModel(row)}</div>
            </div>
        )
    },{
        Header: 'Localizador',
        accessor: 'reservation',
        Cell: (row: any) => (
            <div className={`${styles.info} mt-4`}>
                {row.value}
            </div>
        )
    },{
        Header: 'Pedido | Status',
        accessor: 'orderData',
        Cell: (row: any) => (<>
            <div className="mt-2 d-flex flex-column align-items-center">
                <span className={`${styles.number} ${row.value} `}>{row.value?.number}</span>
                <span className={`${styles.orderStatus} mt-1 mb-1 ${row.value.status === 'Pendente' ? styles.pending : ''} ${row.value.status === 'Cancelado' ? styles.canceled : ''} ${row.value.status === 'Finalizado' ? styles.finished : ''}`}>{row.value?.status}</span>
            </div>
            </>
        )
    },{
        Header: 'Pagamento',
        accessor: 'payment',
        Cell: (row: any) => (
            <div className={`${styles.info} mt-4`}>
                {row.value}
            </div>
        )
    },{
        Header: 'Operador',
        accessor: 'operator',
        Cell: (row: any) => (
            <div className={`${styles.info} mt-4`}>
                {row.value}
            </div>
        )
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }], []); // columnsRecord

    const getName = (column: any) => {
        if (column?.data?.length) {
            const name =  column.data.find((movement: any) => movement?.identifyKey === column.row.original?.identifyKey)
            if (name)
                return name.name
        }
        return '-'
    }

    const getVesselType = (column: any) => {
        if (column?.data?.length) {
            const type =  column.data.find((movement: any) => movement?.identifyKey === column.row.original?.identifyKey)
            if (type)
                return type.typeVessel
        }
        return '-'
    }

    const getVesselSize = (column: any) => {
        if (column?.data?.length) {
            const type =  column.data.find((movement: any) => movement?.identifyKey === column.row.original?.identifyKey)
            if (type)
                return type.vesselSize
        }
        return '-'
    }

    const getModel = (column: any) => {
        if (column?.data?.length) {
            const model =  column.data.find((movement: any) => movement?.identifyKey === column.row.original?.identifyKey)
            if (model)
                return model.model
        }
        return '-'
    }


    async function getOrders() {
        if (!isEmpty(partner)) {
            try {
                const vesselList = await lclService.vesselList(partner.id);
                const orders = vesselList.rows;
                if (orders.length > 0) {
                    const parsedOrders = orders.map((order: any) => ({
                        ...order,
                        reservation: generateReservation(6),
                        date: generateDate(new Date(2023, 11, 17), new Date(2023, 11, 18)),
                        orderData: {
                            status: generateOrderStatus(),
                            number: generateOrder(5),
                        },
                        operator: generateOperator(),
                        payment: generatePayment()
                    }))?.sort(
                        (a: any, b: any) =>
                          Number(new Date(a.date)) - Number(new Date(b.date))
                      )
                    setRecords(parsedOrders);
                    setOriginalRecords(parsedOrders);
                    const pending = parsedOrders.filter((parsed: any) => 
                        parsed.orderData.status === 'Pendente'
                    )?.length || 0
                    setPendingOrders(pending)
                    const canceled = parsedOrders.filter((parsed: any) => 
                        parsed.orderData.status === 'Cancelado'
                    )?.length || 0
                    setCanceledOrders(canceled)
                    const finished = parsedOrders.filter((parsed: any) => 
                        parsed.orderData.status === 'Finalizado'
                    )?.length || 0
                    setFinishedOrders(finished)
                }
            } catch(error: any) {
                await alertContext.show(AlertMessageEnum.FAIL, 'Delivery', error);
            }
        } else {
            await alertContext.show(AlertMessageEnum.FAIL, 'Delivery', 'Não foi definido uma Marina para exibir o cadastro !');
            navigator(`/portal`);
        }
    } // searchfilter

    const filterOrderBySearch = (key?: string, value?: string) => {

        const filteredOrders: any = []
        records.filter((record: any) => {
          if (
            record.identifyKey
              .toLocaleLowerCase()
              .includes(textSearch.toLocaleLowerCase()) ||
            record.name
              .toLocaleLowerCase()
              .includes(textSearch.toLocaleLowerCase()) ||
            record.enrollment
              .toLocaleLowerCase()
              .includes(textSearch.toLocaleLowerCase())
          )
          filteredOrders.push(record)
          return record
        })
        setRecords(filteredOrders)
      return ''
    }

    useEffect(() => {
        if (textSearch.length >= 3) {
            filterOrderBySearch()
        } else if (textSearch.length < 3) {
          setRecords(originalRecords)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [textSearch]);

    

    useEffect(() => {
        getOrders()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const viewOrder = (order: any) => {
        setSelectedOrder(order)
        setOpenConfirmationModal(true)
    }

    const filterOrders = (filter: string) => {
        setSelectedFilter(filter)
        if (filter === 'all') {
            setRecords(originalRecords)
        }
        if (filter === 'pending') {
            const filteredOrders = originalRecords.filter((record: any) => record.orderData.status === 'Pendente')
            setRecords(filteredOrders)
        }
        if (filter === 'canceled') {
            const filteredOrders = originalRecords.filter((record: any) => record.orderData.status === 'Cancelado')
            setRecords(filteredOrders)
        }
        if (filter === 'finished') {
            const filteredOrders = originalRecords.filter((record: any) => record.orderData.status === 'Finalizado')
            setRecords(filteredOrders)
        }
    }

    const handleOrder = (order: any) => {
        console.log(order)
    }

    function viewDashboard() {

        return (
            <Container fluid className="dashboard-body financial">
                <Row>
                    <Col md={12} className="page-sweet">
                    <Card className={styles.vesselList}>
                        <Card.Header>
                            <Card.Title>Pedidos</Card.Title>
                        </Card.Header>
                        <Card.Body style={{ minHeight : 500 }}>
                            <div className="d-flex justify-content-between">
                                <FormControl fullWidth sx={{width: 300, marginBottom: 2 }} variant="standard">
                                    <InputLabel className={styles.label} htmlFor="standard-adornment-amount">Pesquisar
                                    </InputLabel>
                                    <Input id="standard-adornment-amount" value={textSearch} onChange={(e)=>
                                    setTextSearch(e.target.value)}
                                    endAdornment = {
                                    <InputAdornment position="end">
                                        <SearchIcon className='isClickable' />
                                    </InputAdornment>
                                    }
                                    />
                                    <FormHelperText id="component-helper-text">
                                        ID, Nome ou Registro da Embarcação
                                    </FormHelperText>
                                </FormControl>
                                <div className={styles.statusButtonsWrapper}>
                                    <div className={`${styles.statusButton} ${ selectedFilter==='all' ? styles.active
                                        : '' }`} role="presentation" onClick={() => filterOrders('all')}
                                        >
                                        <TodayIcon sx={{ fontSize: 32 }} />
                                        <span className="ms-2">{originalRecords?.length || 0}</span>
                                    </div>
                                    <div className={`${styles.statusButton} ${ selectedFilter==='pending' ?
                                        styles.active : '' }`} role="presentation" onClick={()=>
                                        filterOrders('pending')}
                                        >
                                        <TimerIcon color="warning" sx={{ fontSize: 32 }} />
                                        <span className="ms-2">{pendingOrders}</span>
                                    </div>
                                    <div className={`${styles.statusButton} ${ selectedFilter==='canceled' ?
                                        styles.active : '' }`} role="presentation" onClick={()=>
                                        filterOrders('canceled')}
                                        >
                                        <CancelIcon color="error" sx={{ fontSize: 32 }} />
                                        <span className="ms-2">{canceledOrders}</span>
                                    </div>
                                    <div className={`${styles.statusButton} ${ selectedFilter==='finished'?
                                        styles.active : '' }`} role="presentation" onClick={()=>
                                        filterOrders('finished')}
                                        >
                                        <SportsScoreIcon sx={{ fontSize: 32 }} />
                                        <span>{finishedOrders}</span>
                                    </div>
                                </div>

                            </div>

                            <TableContainer setSelectedRow={ (event: any, dataRow: any)=> {
                                viewOrder(dataRow);
                                }} className={'table-list-record'} columns={columnsRecord} data={records}
                                viewPagination={records.length > 10}/>
                        </Card.Body>
                    </Card>
                    </Col>
                </Row>
                <DeliveryOrderModal
                    open={openConfirmationModal}
                    handleClose={handleCloseModal}
                    height="fit-content"
                    width={450}
                    order={selectedOrder}
                    type={modalType}
                    onConfirm={(order: any) => handleOrder(order)}
                    onCancel={handleCloseModal}
                />
            </Container>
        );
    }

    return (
        <div className="dashboard">
            <DashboardHeader title={title} description={description} />
            {viewDashboard()}
        </div>
    );
}

export default DeliveryOperation;
