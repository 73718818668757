import { useEffect, useMemo, useState } from "react";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import { BsBarChartFill, BsCashCoin, BsFillCartCheckFill, BsWallet2 } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

import { useTranslateContext } from '../../contexts/translate.context';
import { usePartnerContext } from '../../contexts/partner.context';

import SearchIcon from '@mui/icons-material/Search';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import { FormControl, FormHelperText, Input, InputAdornment, InputLabel, Tooltip } from '@mui/material';
import Jet from '../../assets/Jetski.svg';
import Lancha from '../../assets/Lancha.svg';
import { VesselOwner } from '../../components/sub-render.component';
import TableContainer from "../../components/table-container.component";
import { useAlertMessageContext } from "../../contexts/alert-message.context";
import lclService from "../../services/lcl.service";
import { dateToString, toFloat, toInt } from "../../utilities/auxiliary-functions";
import { VESSEL_JETSKI } from "../../utilities/constants";
import { floatMask } from "../../utilities/masks";
import { AlertMessageEnum, getManufacturerName, getVessel } from "../../utilities/types";
import DashboardHeader from "../headers/dashboard.header";
import styles from './SharingManagementDashboard.module.scss';
import IconImageLightbox from '../../components/icon-image-lightbox/IconImageLightbox';
import { TFilter } from '../../types/FilterType';
import { isEmpty } from '../../utilities/validators';

interface ISharingManagementDashboardProps {
    
}

export const SharingManagementDashboard: React.FC<ISharingManagementDashboardProps> = () => {

    const { display } = useTranslateContext();
    const { partner } = usePartnerContext();

    const alertContext = useAlertMessageContext();
    const navigator = useNavigate();
  
    const title = "Gestão Compartilhamento";
    const description = "";

    const [ countLancha, setCountLancha ]  = useState(0);
    const [ countJetski, setCountJetski ]  = useState(0);

    const [ sumWaiting, setSumWaiting ] = useState('0.00');
    const [ sumParcel, setSumParcel ] = useState('0.00');
    const [ textSearch, setTextSearch ] = useState('');
    const [ originalRecords, setOriginalRecords ] = useState([]);
    const [ records, setRecords ] = useState([]);

    const columnsRecord = useMemo(() => [{
        Header: 'Nome | ID',
        accessor: "identifyKey",
        Cell: (row: any) => (
            <div className="d-flex flex-column align-items-center justify-content-center">
                <div className="white-space mt-2">{getName(row)}</div>
                <div className="white-space mt-1">{row.value}</div>
            </div>
        )
    },{
        Header: 'TIPO | COR',
        accessor: 'color',
        Cell: (row: any) => (
            getVessel(getVesselType(row)).id === 34 ? (
                <div className="d-flex flex-column align-items-center justify-content-center mb-2">
                    <div className="mt-2 d-flex justify-content-center mb-2">
                        <Image src={Lancha} className="jet" style={{height: 32}}/>
                        <small className="ms-2">{`${getVesselSize(row)}'`}</small>
                    </div>
                <span className={styles.vesselColor} style={{backgroundColor: `${row.value}`}}></span>
                </div>
            ):(
                <div className="d-flex flex-column align-items-center justify-content-center mb-2">
                    <div className="d-flex justify-content-center mb-2">
                        <Image src={Jet} className="jet mt-3" style={{height: 32}}/>
                        <small className="ms-1 mt-3">{`10'`}</small>
                    </div>
                    <span className={styles.vesselColor} style={{backgroundColor: `${row.value}`}}></span>
                </div>
            )
        )
    },{
        Header: display.label.enrollment,
        accessor: "enrollment",
        Cell: (row: any) => (<div className="white-space mt-4">{row.value}</div>)
    },{
        Header: display.label.drawer,
        //accessor: "bookcase",
        Cell: (props: any) => {
            const bookcase = props.row.original.bookcase;

            if (bookcase?.name) {
                return (
                    <Tooltip className="isClickable d-flex text-center justify-content-center w-100 mt-4" title={` ${bookcase?.name ? bookcase?.name | bookcase?.shelves[0]?.name | bookcase?.shelves[0]?.drawers[0]?.name : ''}`}>
                        <WarehouseIcon color="inherit" className="mt-1" />
                    </Tooltip>
                );
            }
        }

    },{
        Header: 'MAR | MOD',
        accessor: "manufacturer",
        Cell: (row: any) => (
            <div className="d-flex flex-column align-items-center justify-content-center">
                <div className="white-space mt-2">{getManufacturerName(row.value)}</div>
                <div className="white-space mt-1">{getModel(row)}</div>
            </div>
        )
    },{
        Header: 'Propriedade',
        Cell: (props: any) => (
            <div className="mt-4">
                <VesselOwner values={props} />
            </div>
        )
    },{
        Header: 'Cotas Disp.',
        accessor: "quotas",
        Cell: (props: any) => (
            <div className="mt-4">
                -
            </div>
        )
    },{
        Header: 'Foto',
        Cell: () => {

            return (
                <div className="white-space table-actions mt-4">
                    <IconImageLightbox alt='Imagem da embarcação' src="https://images.tcdn.com.br/img/img_prod/376504/carenagem_lateral_para_jet_ski_gti_2019_verde_esquerdo_9115_1_584cb76906bd698926a8bff8d8f81903.jpg" />
                </div>
            );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }], []); // columnsRecord

    const getName = (column: any) => {
        if (column?.data?.length) {
            const name =  column.data.find((movement: any) => movement?.identifyKey === column.row.original?.identifyKey)
            if (name)
                return name.name
        }
        return '-'
    }

    const getVesselType = (column: any) => {
        if (column?.data?.length) {
            const type =  column.data.find((movement: any) => movement?.identifyKey === column.row.original?.identifyKey)
            if (type)
                return type.typeVessel
        }
        return '-'
    }

    const getVesselSize = (column: any) => {
        if (column?.data?.length) {
            const type =  column.data.find((movement: any) => movement?.identifyKey === column.row.original?.identifyKey)
            if (type)
                return type.vesselSize
        }
        return '-'
    }

    const getModel = (column: any) => {
        if (column?.data?.length) {
            const model =  column.data.find((movement: any) => movement?.identifyKey === column.row.original?.identifyKey)
            if (model)
                return model.model
        }
        return '-'
    }

    function getMonthName(monthNumber: any) {
        let month = monthNumber.substring(6, 4)
        if (month.split('0')[0] === '') {
            month = month.split('0')[1]
        }
        var months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
        return months.slice(month -1)[0];
      }

    async function searchSharedVessels() {
        /* Sempre incluir o partner na pesquisa */
        if (!isEmpty(partner)) {
            try {
                const vessels = await lclService.vesselList(partner.id);
                setRecords(vessels.rows);
                setOriginalRecords(vessels.rows);
            } catch(error: any) {
                await alertContext.show(AlertMessageEnum.FAIL, 'Gestão Compartilhamento', error);
            }
        } else {
            await alertContext.show(AlertMessageEnum.FAIL, 'Gestão Compartilhamento', 'Não foi definido uma Marina para exibir o cadastro !');
            navigator(`/portal`);
        }
    } // searchfilter

    const filterVessels = (key?: string, value?: string) => {

        const filteredVessels: any = []
        records.filter((record: any) => {
          if (
            record.identifyKey
              .toLocaleLowerCase()
              .includes(textSearch.toLocaleLowerCase()) ||
            record.name
              .toLocaleLowerCase()
              .includes(textSearch.toLocaleLowerCase()) ||
            record.enrollment
              .toLocaleLowerCase()
              .includes(textSearch.toLocaleLowerCase())
          )
          filteredVessels.push(record)
          return record
        })
        setRecords(filteredVessels)
      return ''
    }

    useEffect(() => {
        if (textSearch.length >= 3) {
            filterVessels()
        } else if (textSearch.length < 3) {
          setRecords(originalRecords)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [textSearch]);

    async function searchfilter() {
        const dateStart = dateToString(new Date(), 'yyyy-mm-ddTHH:MM:ss.sssZ');
        const dateEnd = dateToString(new Date(), 'yyyy-mm-ddTHH:MM:ss.sssZ');
    
        if (!isEmpty(partner)) {
            try {
                const filter = {
                    where: { 
                        "partners.partnerId": partner.id
                    }
                } as TFilter;
                let vesselFilter = await lclService.vesselFilter(filter); /// {count: 0, rows: []} as any; 
                const vessels = vesselFilter.rows;

                let countJetski = 0;
                let countLancha = 0;
                vessels.forEach((vessel: any) => {
                    if (toInt(vessel.typeVessel) === VESSEL_JETSKI)
                        countJetski++
                    else
                        countLancha++
                });
                setCountJetski(countJetski);
                setCountLancha(countLancha);

                const resultMonthlyPayments = await lclService.monthlyPayment(partner.id, dateStart, dateEnd);
                if (resultMonthlyPayments.length > 0) {
                    const resultMonthlyPayment = resultMonthlyPayments[0];
                    
                    setSumWaiting(floatMask(resultMonthlyPayment.sumWaiting));
                    setSumParcel(floatMask(resultMonthlyPayment.sumParcel));
                }
                
                const resultMonthlyPaymentPerForms = await lclService.monthlyPaymentPerForm(partner.id, dateStart, dateEnd);
                const localPaymentPerForm = [] as any;
                for ( var mf = 0; mf < resultMonthlyPaymentPerForms.length; mf++ ) {
                    localPaymentPerForm[localPaymentPerForm.length] = {
                        name: resultMonthlyPaymentPerForms[mf].paymentForm,
                        value: toFloat(resultMonthlyPaymentPerForms[mf].sumParcel)
                    }
                }

                const resultMonthlyPaymentPerAccompanies = await lclService.monthlyPaymentPerAccompany(partner.id, dateStart, dateEnd);
                const localPaymentPerAccompany = [] as any;
                for ( var ma = 0; ma < resultMonthlyPaymentPerAccompanies.length; ma++ ) {
                    localPaymentPerAccompany[localPaymentPerAccompany.length] = {
                        name: getMonthName((resultMonthlyPaymentPerAccompanies[ma].monthy).toString()),
                        provided: toFloat(resultMonthlyPaymentPerAccompanies[ma].sumProvided),
                        realized: toFloat(resultMonthlyPaymentPerAccompanies[ma].sumRealized)
                    }
                }

            } catch(error: any) {
                await alertContext.show(AlertMessageEnum.FAIL, title, error);
            }
        } else {
            await alertContext.show(AlertMessageEnum.FAIL, title, 'Não foi definido uma Marina para exibir as finanças !');
            navigator(`/portal`);
        }
    } // searchfilter

    useEffect(() => {
        searchfilter();
        searchSharedVessels()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function viewDashboard() {

        return (
            <Container fluid className="dashboard-body financial">
                <Row>
                    <Col sm="12" className="dashboard-sweet">
                        <Row>
                            <Col sm="12">
                                <div className="financial-card">
                                    <div className="card-item ship">
                                        <div className="d-flex flex-column align-items-center">
                                            <h4 className="card-label">Embarcações</h4>
                                            <div className="data">
                                                <div className="card-data-group me-5">
                                                    <div className="img">
                                                        <Image style={{ height: 46}} src={Jet} className="jet mt-1"/>
                                                    </div>
                                                    <div className="card-data">{countJetski}</div>
                                                </div>
                                                <div className="card-data-group">
                                                    <div className="img">
                                                        <Image style={{ height: 46}} src={Lancha} className="jet mt-1"/>
                                                    </div>
                                                    <div className="card-data">{countLancha}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-item opened">
                                        <div className="d-flex flex-column align-items-center">
                                            <h4 className="card-label">Mensal Previsto</h4>
                                            <div className="data">
                                                <div>
                                                    <div className="img">
                                                        <BsBarChartFill size={38} />
                                                    </div>
                                                    <div className="card-data">{`R$ ${sumWaiting}`}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-item total">
                                        <div className="d-flex flex-column align-items-center">
                                            <h4 className="card-label">Mensal Realizado</h4>
                                            <div className="data">
                                                <div>
                                                    <div className="img">
                                                        <BsCashCoin size={38} />
                                                    </div>
                                                    <div className="card-data">{`R$ ${sumParcel}`}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-item monthly-payment">
                                        <div className="d-flex flex-column align-items-center">
                                            <h4 className="card-label">Cotas Vendidas</h4>
                                            <div className='d-flex align-items-center mt-2'>
                                                <div className='d-flex align-items-center mb-2'>
                                                    <div className="img">
                                                        <BsFillCartCheckFill className="mb-1" size={38} />
                                                    </div>
                                                    <div className="card-data">120</div>
                                                </div>
                                            </div>
                                    </div>
                                    </div>
                                    <div className="card-item delay">
                                        <div className="d-flex flex-column align-items-center">
                                            <h4 className="card-label">Cotas Disponíveis</h4>
                                                <div className='d-flex align-items-center mt-2'>
                                                    <div className="img">
                                                        <BsWallet2 size={38} />
                                                    </div>
                                                    <div className="card-data">40</div>
                                                </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </Col>
                        </Row>
                        <Row>
                    <Col md={12} className="page-sweet">
                        <Card className={styles.vesselList}>
                            <Card.Header>
                                <Card.Title>Embarcações Compartilhadas</Card.Title>
                            </Card.Header>
                            <Card.Body style={{ minHeight : 500 }}>
                                <FormControl fullWidth sx={{width: 300, marginBottom: 2 }} variant="standard">
                                    <InputLabel className={styles.label} htmlFor="standard-adornment-amount">Pesquisar</InputLabel>
                                    <Input id="standard-adornment-amount"
                                        value={textSearch}
                                        onChange={(e) => setTextSearch(e.target.value)}
                                        endAdornment = {
                                            <InputAdornment position="end">
                                                <SearchIcon className='isClickable' />
                                            </InputAdornment>
                                        }
                                    />
                                    <FormHelperText id="component-helper-text">
                                        ID, Nome ou Registro da Embarcação
                                    </FormHelperText>
                                </FormControl>
                                   
                                <TableContainer className={'table-list-record'} columns={columnsRecord} data={records} viewPagination={records.length > 10}/>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                    </Col>
                </Row>
            </Container>
        );
    }

    return (
        <div className="dashboard">
            <DashboardHeader title={title} description={description} />
            {viewDashboard()}
        </div>
    );
}

export default SharingManagementDashboard;
