import { useEffect, useState } from "react";
import { Card, Col, Container, Form, FormControl, FormGroup, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap";
import env from "react-dotenv";
import { BsCreditCard2FrontFill, BsHouseFill } from "react-icons/bs";
import { FaEye, FaSave } from "react-icons/fa";
import { IoIosCloudy, IoIosLock } from "react-icons/io";
import { IoDocumentLockSharp, IoDocumentsSharp } from "react-icons/io5";
import Resizer from 'react-image-file-resizer';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Button, FormHelperText, Input, InputAdornment, InputLabel, TextField, Tooltip as TooltipMaterial } from '@mui/material';
import Avatar from '../../assets/avatar.png';
import ImageLightBox from "../../components/image-lightbox/ImageLightbox";
import { useAlertMessageContext } from "../../contexts/alert-message.context";
import { useConfirmMessageContext } from "../../contexts/confirm-message.context";
import authService from "../../services/auth.service";
import cduService from "../../services/cdu.service";
import { calcArrival, dateToString, notNullToDate, toDate, toInt, toLowerCase, toString } from "../../utilities/auxiliary-functions";
import { cpfMask, floatMask, numberMask, phoneMask, removeFormatDate, removeMask, zipcodeMask } from "../../utilities/masks";
import { AlertMessageEnum, EngineTypes, FuelTypes, MakerTypes, ManufacturerTypes, OriginType, getColorDefault } from "../../utilities/types";
import { isCpf, isEmail, isEmpty } from "../../utilities/validators";
import AccessDataRecord from "../records/access-data.record";
import AddressRecord from "../records/address.record";
import MaritimeLicenseRecord from "../records/maritime-license.record";
import PersonalDataRecord from "../records/personal-data.record";
import PersonalDocumentRecord from "../records/personal-document.record";
import CadastreHeader from "../headers/cadastre.header";
import styles from './profile.page.module.scss';
import { openDocument } from '../../utilities/view-document';
import lclService from '../../services/lcl.service';
import { Vessel } from '../../types/Vessel';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { CameraAltOutlined } from '@mui/icons-material';
import { GridColDef, GridSelectionModel } from '@mui/x-data-grid';
import { ThemeProvider } from '@mui/material/styles';
import { DataGrid  } from '@mui/x-data-grid';
import theme from "../../theme";
import PlanMovementReport, { TPlanMovementReport } from '../reports/plan-movement.report';
import { TFilter } from '../../types/FilterType';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import SelectReservationModal from "../filters/select-reservation-filter";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import WashIcon from '@mui/icons-material/Wash';
import SelectServiceModal from './SelectServiceModal';
import DirectionsBoatFilledIcon from '@mui/icons-material/DirectionsBoatFilled';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import AddTaskIcon from '@mui/icons-material/AddTask';
import { useSnackbar } from 'notistack';
import { FormControl as MaterialFormControl } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslateContext } from "../../contexts/translate.context";
import { VESSEL_JETSKI } from "../../utilities/constants";

export interface IProfilePageProps {
}

export const ProfilePage: React.FC<IProfilePageProps> = (props: IProfilePageProps) => {

    const PUPLIC_DATA = 'publicData';
    const PERSONAL_DATA = 'personalData';
    const DOCUMENTATION_DATA = 'documentationData';
    const MARITIME_LICENSE_DATA = 'maritimeLicenseData';
    const PERSONAL_ADDRESS_DATA = 'personalAddressData';
    const ACCESS_DATA = 'accessData';

    const VESSEL_DATA = 'vesselData';
    const VESSEL_PHOTOS_ENGINES = 'vesselPhotosEngines';
    const VESSEL_INSURANCE = 'vesselInsurance';
    const VESSEL_MOVEMENTS = 'vesselMovements';
    const VESSEL_SERVICES = 'vesselServices';
    const CHECK_IN = 'checkin';

    const MIN_CHAR_PASS = env.MIN_CHAR_PASS;
    const MAX_CHAR_PASS = env.MAX_CHAR_PASS;

    const alertContext = useAlertMessageContext();
    const confirmContext = useConfirmMessageContext();
    const { display } = useTranslateContext();
    
    const navigate = useNavigate()

    const title = display.title.profile;
    const description = 'Cadastro';

    let reloadPage = false;
    const [ isLoading, setLoading ] = useState(false);

    const [ invalidatedFormPublicInfo, setInvalidatedFormPublicInfo ] = useState(false);
    const [ invalidatedFormPrivateInfo, setInvalidatedFormPrivateInfo ] = useState(false);
    const [ invalidatedFormAccessInfo, setInvalidatedFormAccessInfo ] = useState(false);

    const [ sendingPublicInfo, setSendingPublicInfo ] = useState(false);
    const [ sendingPrivateInfo, setSendingPrivateInfo ] = useState(false);
    const [ sendingAccessInfo, setSendingAccessInfo ] = useState(false);

    // PublicData
    const [ userId, setUserId ] = useState(0); 
    const [ username, setUsername ] = useState(''); 
    const [ biography, setBiography ] = useState(''); 
    const [ avatar, setAvatar ] = useState(''); 
    const [ email, setEmail ] = useState(''); 

    // PersonalData
    const [ peopleId, setPeopleId ] = useState(0); 
    const [ tratament, setTratament ] = useState(''); 
    const [ fullname, setFullname ] = useState(''); 
    const [ gender, setGender ] = useState('');
    const [ birth, setBirth ] = useState('');
    // Phone
    const [ phoneId, setPhoneId ] = useState(0);
    const [ norm, setNorm ] = useState('');
    const [ operator, setOperator ] = useState('');
    const [ connection, setConnection ] = useState('');
    const [ sms, setSms ] = useState(0);
    const [ zap, setZap ] = useState(0);
    const [ major, setMajor ] = useState(0);

    const [ nationality, setNationality ] = useState('');
    const [ naturalness, setNaturalness ] = useState('');
    // PersonalDocument
    const [ registrationPf, setRegistrationPf ] = useState('');
    const [ registrationIdentity, setRegistrationIdentity ] = useState('');
    const [ issuerIdentity, setIssuerIdentity ] = useState('');
    const [ stateIdentity, setStateIdentity ] = useState('');
    const [ expeditionIdentity, setExpeditionIdentity ] = useState('');
    const [ expedition, setExpedition ] = useState('');
    const [ driversLicense, setDriversLicense ] = useState('');
    const [ categoryDriversLicense, setCategoryDriversLicense ] = useState('');
    const [ validityDriversLicense, setValidityDriversLicense ] = useState('');
    // Maritime License
    const [ nauticalDocumentId, setNauticalDocumentId ] = useState(0);
    const [ categoryNaval, setCategoryNaval ] = useState('');
    const [ enrollmentNaval, setEnrollmentNaval ] = useState('');
    const [ validityNaval, setValidityNaval ] = useState('');
    const [ limitsNaval, setLimitsNaval ] = useState('');
    const [ commentsNaval, setCommentsNaval ] = useState('');
    const [ locationNaval, setLocationNaval ] = useState('');
    const [ expeditionNaval, setExpeditionNaval ] = useState('');
    // Address
    const [ addressId, setAddressId ] = useState(0);
    const [ zipcode, setZipcode ] = useState('');
    const [ state, setState ] = useState('');
    const [ city, setCity ] = useState('');
    const [ district, setDistrict ] = useState('');
    const [ place, setPlace ] = useState('');
    const [ habitation, setHabitation ] = useState('');
    const [ complement, setComplement ] = useState('');

    type ArtifactType = { id : number, norm : string, source : string, identy : string, document : string, filename : string };
    const DEFAULT_ARTIFACT: ArtifactType = { id : 0, norm : '', source : '', identy : '', document : '', filename : '' };
    
    const [ driversLicenseDocument, setDriversLicenseDocument ] = useState(DEFAULT_ARTIFACT);
    const [ proofOfResidenceDocument, setProofOfResidenceDocument ] = useState(DEFAULT_ARTIFACT);

    // AccessData
    const [ token, setToken ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ checkPassword, setCheckPassword ] = useState('');
    const [ selectedSession, setSelectedSession ] = useState(PUPLIC_DATA);
    const [ selectedVesselSession, setSelectedVesselSession ] = useState(VESSEL_DATA);
    const [ expandedVessels, setExpandedVessels ] = useState([99])
    const [ selectionModel, setSelectionModel ] = useState<GridSelectionModel>([]);

    const [ vessels, setVessels ] = useState<Array<Vessel>>([])

    const [ dateTimeStart, setDateTimeStart ] = useState(dateToString(new Date(), 'dd/mm/yyyy') + ' 00:00:00');
    const [ dateTimeEnd, setDateTimeEnd ] = useState(dateToString(new Date(), 'dd/mm/yyyy') + ' 23:59:59');
    const [ records, setRecords ] = useState([]);
    const [ originalRecords, setOriginalRecords ] = useState([]);
    const [ showFilter, setShowFilter]  = useState(false);
    const [ showSelectReservation, setShowSelectReservation]  = useState(false);
    const [ showSelectService, setShowSelectService]  = useState(false);
    const [ sending, setSending ] = useState(false);
    const [ searchParams, setSearchParams ] = useSearchParams();
    const [ pageFilled, setPageFilled ] = useState<number[]>([]);
    const [ reservation, setReservation ] = useState('HQCPWD');
    const [ searching, setSearching]  = useState(false);

    const { enqueueSnackbar } = useSnackbar()

    let noPaged = true;

    const handleKeypress = (e: any) => {
        if (e.charCode === 13) {
          search(e)
        }
      }

    const search = async (e: { preventDefault: () => void }) => {
        e.preventDefault()
        setSearching(true)
        try {
          const movement = await lclService.getByLocator(reservation)
          if (!isEmpty(movement)) {
            navigate(`/card/checkin/${reservation}`)
          } else {
            enqueueSnackbar('Localizador não encontrado', { variant: 'error' })
          }
        } catch (error: any) {
          enqueueSnackbar(
            (error as { message: string }).message ||
              'Ocorreu um problema, tente novamente',
            {
              variant: 'error'
            }
          )
        } finally {
          setSearching(false)
        }
      }

    const rowsMaintenance = [
        { id: 1, date: '08/08/2023', os: '0031', payment: 'Avulso' },
        { id: 2, date: '21/11/2023', os: '0057', payment: 'Avulso' }
    ];

    const rowsCleaning = [
        { id: 1, date: '01/10/2022', os: '00913', payment: 'Avulso' },
        { id: 2, date: '22/04/2023', os: '001414', payment: 'Mensalista' }
    ];

    const rowsDelivery = [
        { id: 1, date: '23/05/2023', os: '91918', payment: 'Mensalista' },
        { id: 2, date: '10/12/2023', os: '133414', payment: 'Mensalista' }
    ];

    const columnsMaitenance: GridColDef[] = [
        { field: 'date', headerName: 'Data', width: 110, flex: 1, align: 'center', headerAlign: 'center' },
        { field: 'os', headerName: 'O.S.' ,flex:1,  width: 70, align: 'center', headerAlign: 'center' },
        { field: 'payment', headerName: 'Pagamento',flex:1,  width: 70, align: 'center', headerAlign: 'center' },
        {
            field: 'action',
            headerName: 'Ver',
            headerAlign: 'center',
            renderCell: renderFakeButtons,
            disableExport: true,
        }
    ];

    const columnsDelivery: GridColDef[] = [
        { field: 'date', headerName: 'Data', width: 110, flex: 1, align: 'center', headerAlign: 'center' },
        { field: 'os', headerName: 'Pedido' ,flex:1,  width: 70, align: 'center', headerAlign: 'center' },
        { field: 'payment', headerName: 'Pagamento',flex:1,  width: 70, align: 'center', headerAlign: 'center' },
        {
            field: 'action',
            headerName: 'Ver',
            headerAlign: 'center',
            renderCell: renderFakeButtons,
            disableExport: true,
        }
    ];

    function renderFakeButtons(param: any) {

        return (
            <div className="d-flex w-100 justify-content-center">
                <OverlayTrigger overlay={<Tooltip id="tooltip">Visualizar Plano de Navegação</Tooltip>}>
                    <span>
                        <FaEye size={18} />
                    </span>
                </OverlayTrigger>
            </div>
        );
    }
    
    function onChange_Avatar(event: any) {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            Resizer.imageFileResizer(file, 100, 100, "JPEG", 100, 0,
                (uri: any) => {
                    setAvatar(uri);
                }, "base64"
            );
        } else {
            setAvatar('');
        }
    }

    async function isValidFormPublicInfo() {
        let result = true;
        let emptyRequired = false;

        if (isEmpty(username))
            emptyRequired = true;
        if (isEmpty(email))
            emptyRequired = true;
        if ((!isEmpty(email)) && (!isEmail(email))) {
            await alertContext.show(AlertMessageEnum.FAIL, title, display.message.invalid.email);
            result = false;
        }

        if (emptyRequired) {
            await alertContext.show(AlertMessageEnum.FAIL, title, display.message.invalid.required);
            result = false;
        }

        setInvalidatedFormPublicInfo(!result);
        return result;
    } // isValidFormPublicInfo

    async function isValidFormPrivateInfo() {
        let result = true;
        let emptyRequired = false;

        if (isEmpty(fullname))
            emptyRequired = true;
        if (isEmpty(registrationPf))
            emptyRequired = true;
        if ((!isEmpty(registrationPf)) && (!isCpf(registrationPf))) {
            await alertContext.show(AlertMessageEnum.FAIL, title, display.message.invalid.registration_pf);
            result = false;
        }

        if (emptyRequired) {
            await alertContext.show(AlertMessageEnum.FAIL, title, display.message.invalid.required);
            result = false;
        }

        setInvalidatedFormPrivateInfo(!result);
        return result;
    } // isValidFormPrivateInfo

    async function isValidFormAccessInfo() {
        let result = true;
        let emptyRequired = false;

        if (isEmpty(token))
            emptyRequired = true;
        if (isEmpty(password))
            emptyRequired = true;
        if ((!isEmpty(password)) && (!isEmpty(checkPassword))) {
            let isValid = true;
            if (password.length < MIN_CHAR_PASS)
                isValid = false;
            if (password.length > MAX_CHAR_PASS)
                isValid = false;
            if (password.search(/(?=.*[A-Z])/) < 0)
                isValid = false;
            if (password.search(/(?=.*[a-z])/) < 0)
                isValid = false;
            if (password.search(/([0-9])/) < 0)
                isValid = false;
            if (password.search(/(?=.*[!@#$%&? "])/) < 0)
                isValid = false;

            if (!isValid) {
                await alertContext.show(AlertMessageEnum.FAIL, title, display.message.invalid.password);
                result = false;
            }
        }

        if (emptyRequired) {
            await alertContext.show(AlertMessageEnum.FAIL, title, display.message.invalid.required);
            result = false;
        }

        setInvalidatedFormAccessInfo(!result);
        return result;
    } // isValidFormPrivateInfo

    function fillPublicInfo() {

        const dataPublicInfo = { 
            username, 
            biography,
            avatar,
            email
        }

        return dataPublicInfo;
    } // fillPublicInfo

    function fillPrivateInfo() {

        const adresses = [];
        adresses[0] = {
            peopleId : peopleId,
            addressId : addressId,
            address : {
                id : addressId,
                zipcode : removeMask(zipcode),
                state : state,
                city : city,
                district : district,
                place : place
            },
            correspondence : 1,
            norm : 'residential',
            habitation,
            complement
        };

        const phones = [];
        phones[0] = {
            id : phoneId,
            norm, 
            operator,
            connection : removeMask(connection),
            sms,
            zap,
            major
        };

        const documentsNautical = [];
        documentsNautical[0] = {
            id : nauticalDocumentId,
            category : categoryNaval,
            enrollment : enrollmentNaval,
            validity : removeFormatDate(validityNaval),
            limits : limitsNaval,
            comments : commentsNaval,
            location : locationNaval,
            expedition : removeFormatDate(expeditionNaval)
        };

        const dataPeople = {
            id: peopleId,
            tratament,
            fullname, 
            gender, 
            birth : removeFormatDate(birth), 
            phones, 
            documentsNautical,
            nationality, 
            naturalness, 
            registrationPf : removeMask(registrationPf),
            registrationIdentity : removeMask(registrationIdentity),
            issuerIdentity,
            stateIdentity,
            expedition : removeFormatDate(expedition), 
            driversLicense : removeMask(driversLicense),
            categoryDriversLicense,
            validityDriversLicense : removeFormatDate(validityDriversLicense), 
            adresses
        };

        const dataPrivateInfo = {
            // situation : USER_SITUATION_MODIFIED,
            peopleId,
            people : dataPeople
        };
        // dataPeople

        return dataPrivateInfo;
    } // fillPrivateInfo

    function fillAccessInfo() {

        const dataAccessInfo = { 
            username, 
            token,
            password,
        }

        return dataAccessInfo;
    } // fillAccessInfo

    async function onClick_SavePublicInfo(event: any) {
        event.preventDefault();
        
        const isValid = await isValidFormPublicInfo();
        if (isValid) {
            try {
                const isConfirmed = await confirmContext.show(title, display.message.confirm_record);

                if (isConfirmed) {
                    setSendingPublicInfo(true);

                    const userPublicInfo = fillPublicInfo();
                    await cduService.saveUser('PublicInfo', userId, userPublicInfo);

                    setSendingPublicInfo(false);
                    await alertContext.show(AlertMessageEnum.FAIL, title, display.message.the_record_has_been_saved_successfully);
                }
                const user = authService.currentUser()
                const updatedUserData = await cduService.userById(user.id)
                localStorage.removeItem('avatar')
                localStorage.setItem('avatar', JSON.stringify(updatedUserData.avatar))
                // window.location.reload();
            } catch (error: any) {
                setSendingPublicInfo(false);
                await alertContext.show(AlertMessageEnum.FAIL, title, error);
            }
        }
    }

    async function onClick_SavePrivateInfo(event: any) {
        event.preventDefault();

        const isValid = await isValidFormPrivateInfo();
        if (isValid) {
            try {
                const isConfirmed = await confirmContext.show(title, display.message.confirm_record);

                if (isConfirmed) {
                    setSendingPrivateInfo(true);

                    const userPrivateInfo = fillPrivateInfo();
                    await cduService.saveUser('PrivateInfo', userId, userPrivateInfo);

                    const dataArtifacts = [] as any;
                    dataArtifacts.push(driversLicenseDocument);
                    dataArtifacts.push(proofOfResidenceDocument);

                    for ( let i = 0; i < dataArtifacts.length; i++) {
                        const dataArtifact = dataArtifacts[i];

                        if (dataArtifact) {
                            dataArtifact.source = 'PEOPLE';
                            dataArtifact.identy = String(peopleId);
        
                            if ((dataArtifact.id > 0) && !isEmpty(dataArtifact?.document)) {
                                await cduService.saveArtifact(dataArtifact.id, dataArtifact);
                            } else if ((dataArtifact.id > 0) && isEmpty(dataArtifact?.document)) {
                                await cduService.artifactDeleteById(dataArtifact.id);
                            } else if ((dataArtifact.id === 0) && !isEmpty(dataArtifact?.document)) {
                                await cduService.createArtifact(dataArtifact);
                            }
                        }
                    }

                    setSendingPrivateInfo(false);
                    await alertContext.show(AlertMessageEnum.FAIL, title, display.message.the_record_has_been_saved_successfully);
                }
            } catch (error: any) {
                setSendingPrivateInfo(false);
                await alertContext.show(AlertMessageEnum.FAIL, title, error);
            }
        }
    }
    
    async function onClick_SaveAccessInfo(event: any) {
        event.preventDefault();

        const isValid = await isValidFormAccessInfo();
        if (isValid) {
            try {
                const isConfirmed = await confirmContext.show(title, display.message.confirm_record);
                if (isConfirmed) {
                    setSendingAccessInfo(true);

                    const userPrivateInfo = fillAccessInfo();
                    await cduService.saveUser('AccessInfo', userId, userPrivateInfo);

                    setSendingAccessInfo(false);
                    await alertContext.show(AlertMessageEnum.FAIL, title, display.message.the_record_has_been_saved_successfully);
                }
            } catch (error: any) {
                setSendingAccessInfo(false);
                await alertContext.show(AlertMessageEnum.FAIL, title, error);
            }
        }
    }

    function fillRecordPublicInfo(user: any) {

        if (!isEmpty(user)) {
            setUserId(user.id); 
            setUsername(user.username); 
            setBiography(user.biography); 
            setAvatar(user.avatar); 
            setEmail(user.email); 
        }
    }

    function fillRecordPrivateInfo(people: any) {

        if (!isEmpty(people)) {
            setPeopleId(people.id);
            setTratament(people.tratament);
            setFullname(people.fullname);
            setGender(people.gender);
            setBirth(dateToString(people.birth, 'dd/mm/yyyy HH:MM:ss'));
            if (!isEmpty(people.phones)) {
                const phone = people.phones[0];

                setPhoneId(phone.id);
                setNorm(phone?.norm);
                setOperator(phone.operator);
                setConnection(phoneMask(phone.connection));
                setSms(phone.sms);
                setZap(phone.zap);
                setMajor(phone.major);
            }
            setNationality(people.nationality);
            setNaturalness(people.naturalness);

            setRegistrationPf(cpfMask(people.registrationPf));
            setRegistrationIdentity(numberMask(people.registrationIdentity));
            setIssuerIdentity(people.issuerIdentity);
            setStateIdentity(people.stateIdentity);
            setExpedition(dateToString(people.expedition, 'dd/mm/yyyy'));
            setDriversLicense(numberMask(people.driversLicense));
            setCategoryDriversLicense(people.categoryDriversLicense);
            setValidityDriversLicense(dateToString(people.validityDriversLicense, 'dd/mm/yyyy'));

            if (!isEmpty(people.documentsNautical)) {
                const nauticalDocument = people.documentsNautical[0];
                
                setNauticalDocumentId(nauticalDocument.id);
                setCategoryNaval(nauticalDocument.category);
                setEnrollmentNaval(nauticalDocument.enrollment);
                setValidityNaval(notNullToDate(nauticalDocument.validity, 'dd/mm/yyyy'));
                setLimitsNaval(nauticalDocument.limits);
                setCommentsNaval(nauticalDocument.comments);
                setLocationNaval(nauticalDocument.location);
                setExpeditionNaval(notNullToDate(nauticalDocument.expedition, 'dd/mm/yyyy'));
            }

            if (!isEmpty(people.adresses)) {
                const addressPeople = people.adresses[0];

                setAddressId(addressPeople.addressId);
                setZipcode(zipcodeMask(addressPeople.address.zipcode));
                setState(addressPeople.address.state);
                setCity(addressPeople.address.city);
                setDistrict(addressPeople.address.district);
                setPlace(addressPeople.address.place);

                setHabitation(addressPeople.habitation);
                setComplement(addressPeople.complement);
            }
        }
    }

    function fillRecordAccessInfo(user: any) {

        if (!isEmpty(user)) {
            setToken(user.token);
            setPassword(user.password);
            setCheckPassword(user.password);
        }
    }

    async function initialize() {
        try {
            const currentUser = authService.currentUser();
            const user = await cduService.userById(currentUser.id);
            const people = await cduService.peopleByUser(currentUser.id);

            fillRecordPublicInfo(user);
            fillRecordPrivateInfo(people);
            fillRecordAccessInfo(user);
                
            cduService.artifactByNorm('PEOPLE', people.id, 'FILE_DRIVER').then((artifact: any) => {
                if (artifact)
                    setDriversLicenseDocument(artifact);
            });
            cduService.artifactByNorm('PEOPLE', people.id, 'FILE_RESIDENCE').then((artifact: any) => {
                if (artifact)
                    setProofOfResidenceDocument(artifact);
            });
        } catch (error: any) {
            alertContext.show(AlertMessageEnum.FAIL, title, error);
        }
    }

    useEffect(() => {
        if (!isLoading && !reloadPage) {
            initialize();
            setLoading(true);
        }

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            reloadPage = !reloadPage;
        }
    }, []);

    useEffect(() => {
        getVessels()
        searchFilter("01/09/2023 00:00:00", "28/09/2023 23:59:59")
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getVessels = async () => {
        const vesselsOwnerIds = [1, 2]
        let parsedVessels = []

        for (let i = 0; i < vesselsOwnerIds.length; i++) {
            const element = vesselsOwnerIds[i];
            
            const vesselData = await lclService.vesselById(element)
            let parsedData = vesselData
            if(vesselData.name) {
                /* Imagens */
                cduService.artifactByNorm('VESSEL', element?.toString(), 'PROW').then((image: any) => {
                    if (!image)
                        image = { id : 0, norm : 'PROW', source : 'VESSEL', identy : 1, document : '', filename : '' };
                    parsedData = {
                        ...parsedData,
                        images:{...parsedData.images, prow: image}
                    }
                });
    
                cduService.artifactByNorm('VESSEL', element?.toString(), 'STERN').then((image: any) => {
                    if (!image)
                        image = { id : 0, norm : 'STERN', source : 'VESSEL', identy : 1, document : '', filename : '' };
                    parsedData = {
                        ...parsedData,
                        images:{...parsedData.images, stern: image}
                    }
                });
    
                cduService.artifactByNorm('VESSEL', element?.toString(), 'PORTBOARD').then((image: any) => {
                    if (!image)
                        image = { id : 0, norm : 'PORTBOARD', source : 'VESSEL', identy : 1, document : '', filename : '' };
                    parsedData = {
                        ...parsedData,
                        images:{...parsedData.images, portBoard: image}
                    }
                });
    
                cduService.artifactByNorm('VESSEL', element?.toString(), 'STARBOARD').then((image: any) => {
                    if (!image)
                        image = { id : 0, norm : 'STARBOARD', source : 'VESSEL', identy : 1, document : '', filename : '' };
                    parsedData = {
                        ...parsedData,
                        images:{...parsedData.images, starBoard: image}
                    }
                });
    
                cduService.artifactByNorm('VESSEL', element?.toString(), 'PANEL').then((image: any) => {
                    if (!image)
                        image = { id : 0, norm : 'PANEL', source : 'VESSEL', identy : 1, document : '', filename : '' };
                    parsedData = {
                        ...parsedData,
                        images:{...parsedData.images, panel: image}
                    }
                });
    
                cduService.artifactByNorm('VESSEL', element?.toString(), 'INTERIOR').then((image: any) => {
                    if (!image)
                        image = { id : 0, norm : 'INTERIOR', source : 'VESSEL', identy : 1, document : '', filename : '' };
                    parsedData = {
                        ...parsedData,
                        images:{...parsedData.images, interior: image}
                    }
                });
    
                cduService.artifactByNorm('VESSEL', element?.toString(), 'CABIN').then((image: any) => {
                    if (!image)
                        image = { id : 0, norm : 'CABIN', source : 'VESSEL', identy : 1, document : '', filename : '' };
                    parsedData = {
                        ...parsedData,
                        images:{...parsedData.images, cabin: image}
                    }
                });
                const image = await lclService.vesselImageById(element)
                parsedData = {
                    ...parsedData,
                    order:  0 + element,
                    image: image,
                }
                parsedData = {
                    ...parsedData,
                }
            }

            parsedVessels.push(parsedData)
        }

        setVessels(parsedVessels)

    }

    const expand = (vessel: Vessel) => {
        if (expandedVessels.includes(vessel.order)) {
            setExpandedVessels(expandedVessels.filter((actual: number) => actual !== vessel.order))
            vessel.expanded = false
        } else {
            setExpandedVessels([...expandedVessels, vessel.order])
            vessel.expanded = true
        }

    }

    function changeDriversLicenseDocument(event: any) {
        if (event.target.files && event.target.files.length > 0) {
            let reader = new FileReader();
            reader.onload = (e: any) => {
                let artfact: ArtifactType = {
                    id : driversLicenseDocument.id,
                    norm : 'FILE_DRIVER',
                    source : 'PEOPLE',
                    identy : String(peopleId),
                    document : e.target.result,
                    filename : event.target.files[0].name
                }
                setDriversLicenseDocument(artfact);
            };
            reader.readAsDataURL(event.target.files[0]);
        } else {
            let artifact: ArtifactType = { id : driversLicenseDocument.id, norm : 'FILE_DRIVER', source : 'PEOPLE', identy : String(peopleId), document : '', filename : '' };
            setDriversLicenseDocument(artifact);
        }
    }


    function changeProofOfResidenceDocument(event: any) {
        if (event.target.files && event.target.files.length > 0) {
            let reader = new FileReader();
            reader.onload = (e: any) => {
                let artfact: ArtifactType = {
                    id : proofOfResidenceDocument.id,
                    norm : 'FILE_RESIDENCE',
                    source : 'PEOPLE',
                    identy : String(peopleId),
                    document : e.target.result,
                    filename : event.target.files[0].name
                }
                setProofOfResidenceDocument(artfact);
            };
            reader.readAsDataURL(event.target.files[0]);
        } else {
            let artifact: ArtifactType = { id : proofOfResidenceDocument.id, norm : 'FILE_RESIDENCE', source : 'PEOPLE', identy : String(peopleId), document : '', filename : '' };
            setProofOfResidenceDocument(artifact);
        }
    }

    function clickViewDocument(event: any, title: string, url: any) {
        event.preventDefault();
        openDocument(title, url);
    }

    const columns: GridColDef[] = [
        { field: 'dateMovement', headerName: display.label.date_movement, width: 110, flex: 1, align: 'center', headerAlign: 'center', valueGetter: getDateMovement },
        { field: 'locator', headerName: 'Localizador' ,flex:1,  width: 70, align: 'center', headerAlign: 'center' },
        { field: 'departure', headerName: display.label.departure,flex:1,  width: 70, align: 'center', headerAlign: 'center', valueGetter: getDeparture },
        {
            field: 'action',
            headerName: 'Ver',
            headerAlign: 'center',
            renderCell: renderButtons,
            disableExport: true,
        }
    ];

    function getDateMovement(param: any) {
        const movement = param.row;
        return dateToString(movement.dateMovement, 'dd/mm/yyyy');
    }

    function getDeparture(param: any) {
        const movement = param.row;
        return dateToString(movement.departure, 'HH:MM');
    }
    
    function renderButtons(param: any) {
        let movementRows = [] as any;
        const originals = param.row;
        for (var o = 0; o < originals.length; o++) {
            movementRows[movementRows.length] = originals[o].original;
        }
        const movementRow = param.row;

        return (
            <div className="d-flex w-100 justify-content-center">
                <OverlayTrigger overlay={<Tooltip id="tooltip">Visualizar Plano de Navegação</Tooltip>}>
                    <span onClick={(e) => onClick_EditMovement(e, movementRow)}>
                        <FaEye size={18} />
                    </span>
                </OverlayTrigger>
            </div>
        );
    }

    async function searchFilter(dateTimeStart: string, dateTimeEnd: string) {
        setLoading(true);

        /* Sempre incluir o partner na pesquisa */
        if (1) {
            try {
                setDateTimeStart("01/09/2023 00:00:00");
                setDateTimeEnd("28/09/2023 23:59:59");

                let localPageSize = 20;
                let localPage = 0;

                if (noPaged) {
                    if (searchParams.has('limit')) {
                        let paramPageSize = '2';
                        if (paramPageSize) {
                            // eslint-disable-next-line react-hooks/exhaustive-deps
                            localPageSize = Number.parseInt(paramPageSize);
                        }
                    }
                    if (searchParams.has('offset')) {
                        let paramPage = searchParams.get('offset');
                        if (paramPage) {
                            // eslint-disable-next-line react-hooks/exhaustive-deps
                            localPage = Number.parseInt(paramPage);
                        }
                    }
                }

                if (pageFilled.indexOf(localPage) === -1) {
                    const filter = {
                        attributes: [
                           'id', 'dateMovement', 'departure', 'estimated', 'status', 'locator', 'move', 'checkIn', 'registrationCheckIn', 'dateCheckIn', 
                            'sailorRequested', 'queuePosition', 'arrival', 'quantityPeople', 'quantityChildren',
                            'crews.kind', 
                            'crews.conductorId', 
                            'crews.conductor.peopleId',
                            'crews.conductor.people.fullname', 
                            'crews.conductor.people.gender', 
                            'crews.conductor.people.registrationPf',
                            'vessel.identifyKey', 'vessel.name', 'vessel.enrollment', 'vessel.typeVessel', 'vessel.vesselSize', 'vessel.color', 'vessel.quantityPeople', 'vessel.brand', 'vessel.model',
                        ],
                        where: {
                            partnerId: 1,
                            dateMovement: { start: removeFormatDate(dateTimeStart), end: removeFormatDate(dateTimeEnd) }
                        },
                        order: [ [ 'departure', 'ASC' ] ],
                        limit: localPageSize, 
                        offset: localPage * localPageSize
                    } as TFilter;
                    const movementVesselFilter = await lclService.movementVesselFilter(filter);
                    const array = [] as any;
                    records.forEach((record: any) => array.push(record));
                    movementVesselFilter.rows.forEach((record: any) => array.push(record));

                    setRecords(array);
                    setOriginalRecords(array);
                    setSending(false);

                    if (array.length > 0)
                        pageFilled.push(localPage);
                }
            } catch(error: any) {
                await alertContext.show(AlertMessageEnum.FAIL, title, error);
            } finally {
                setSending(false);
                setLoading(false);
                setShowFilter(false);
            }
        }
    } // searchFilter

    async function onClick_EditMovement(event: any, movement: any) {
        event.preventDefault();

        const currentMovement = await lclService.movementVesselById(movement.id);
        // const address = await cduService.addressById(props.partner.company.addressId);
        const planMovement : TPlanMovementReport = {
            company: {
                image: "",
                socialReason: "Marina Costa do Sul LTDA",
                fantasy: "Costa do Sul",
                registrationPj: "64.874.165/0001-75",
                telephone: "(51) 3724-1891",
                email: "atendimento@costadosul.com.br",
                address: "null - null - null - null - 58.036-290"
            },
            movement: {
                departure: dateToString(currentMovement.departure, 'dd/mm/yyyy'),
                estimated: dateToString(currentMovement.departure, 'HH:MM'),
                arrival: dateToString(toDate(removeFormatDate(calcArrival(currentMovement.departure, currentMovement.estimated)), 'yyyy-mm-ddTHH:MM:ss.sssZ'), 'HH:MM'),
                locator: currentMovement.locator,
                navigationPlan: currentMovement.navigationPlan
            },
            vessels: [{
                identifyKey: currentMovement.vessel.identifyKey,
                enrollment: currentMovement.vessel.enrollment,
                name: currentMovement.vessel.name,
                color: display.label[toLowerCase(getColorDefault(currentMovement.vessel.color).name)],
                brand: currentMovement.vessel.brand,
                model: currentMovement.vessel.model
            }],
            captain: {
                name: currentMovement?.crews[0]?.conductor?.people?.fullname,
                enrollment: currentMovement?.crews[0]?.conductor?.people?.documentsNautical[0]?.enrollment,
                expedition: dateToString(currentMovement?.crews[0]?.conductor?.people?.documentsNautical[0]?.expedition, 'dd/mm/yyyy'),
                validity: dateToString(currentMovement?.crews[0]?.conductor?.people?.documentsNautical[0]?.validity, 'dd/mm/yyyy'),
                limits: currentMovement?.crews[0]?.conductor?.people?.documentsNautical[0]?.limits
            },
            passengers: currentMovement.passengers.map((passenger: any) => {

                return {
                    name: passenger.people.fullname
                }
            }),
            payday: dateToString(new Date(), 'dd/mm/yyyy'),
        }
        PlanMovementReport(planMovement);
    } // onClick_EditRecord

    function viewPage() {

        return (
            <Container fluid className="page-body">
                <Row>
                    <Col sm="3" className="page-session">
                        <Card>
                            <Card.Header>
                                <Card.Title>{display.legend.information}</Card.Title>
                            </Card.Header>
                            <ul className={`${styles.sideMenu} list-group list-group-flush`}>
                                <li className={selectedSession === PUPLIC_DATA ? styles.active : ''}>
                                    <p className="list-group-item-action list-group-item" onClick={() => setSelectedSession(PUPLIC_DATA)}>
                                        <IoIosCloudy size={23} /><span>{display.legend.public_data}</span>
                                    </p>
                                </li>
                                <li className={selectedSession === PERSONAL_DATA ? styles.active : ''}>
                                    <p className="list-group-item-action list-group-item" onClick={() => setSelectedSession(PERSONAL_DATA)}>
                                        <IoDocumentsSharp size={23} /><span>{display.legend.personal_data}</span>
                                    </p>
                                </li>
                                <li className={selectedSession === DOCUMENTATION_DATA ? styles.active : ''}>
                                    <p className="list-group-item-action list-group-item" onClick={() => setSelectedSession(DOCUMENTATION_DATA)}>
                                        <IoDocumentLockSharp size={23} /><span>{display.legend.personal_document}</span>
                                    </p>
                                </li>
                                <li className={selectedSession === MARITIME_LICENSE_DATA ? styles.active : ''}>
                                    <p className="list-group-item-action list-group-item" onClick={() => setSelectedSession(MARITIME_LICENSE_DATA)}>
                                        <BsCreditCard2FrontFill size={23} /><span>{display.legend.maritime_license}</span>
                                    </p>
                                </li>
                                <li className={selectedSession === PERSONAL_ADDRESS_DATA ? styles.active : ''}>
                                    <p className="list-group-item-action list-group-item" onClick={() => setSelectedSession(PERSONAL_ADDRESS_DATA)}>
                                        <BsHouseFill size={23} /><span>{display.legend.personal_address}</span>
                                    </p>
                                </li>
                                <li className={selectedSession === ACCESS_DATA ? styles.active : ''}>
                                    <p className="list-group-item-action list-group-item" onClick={() => setSelectedSession(ACCESS_DATA)}>
                                        <IoIosLock size={23} /><span>{display.legend.access_data}</span>
                                    </p>
                                </li>
                            </ul>
                        </Card>
                    </Col>
                    <Col sm="9" className="page-sweet">
                        <Card id="session-registry-public-info" style={{ display : selectedSession === PUPLIC_DATA ? ' block' : 'none' }}>
                            <Form id="form-registry-public-info" validated={invalidatedFormPublicInfo}>
                                <Card.Header>
                                    <Card.Title>{display.legend.public_information}</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col sm="8">
                                            <FormGroup>
                                                <Form.Label htmlFor="form-username">Nome/Apelido</Form.Label>
                                                <Form.Control id="form-username" name="username" required placeholder=""
                                                    value={toString(username)}
                                                    onChange={(e: any) => setUsername(e.target.value)}
                                                    disabled
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Form.Label htmlFor="form-email">{display.label.email}</Form.Label>
                                                <Form.Control id="form-email" name="email" required placeholder={display.example.email}
                                                    value={toString(email)}
                                                    onChange={(e: any) => setEmail(e.target.value)}
                                                    disabled
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <ImageLightBox
                                                containerStyle={styles.imageWrapper}
                                                src={avatar ? avatar : Avatar}
                                                alt="foto de perfil"
                                                rounded={true}
                                            />
                                        <FormGroup as={Row} className={`mt-2 d-flex justify-content-center ${styles.imageControls}`}>
                                            <label htmlFor='input-file-avatar'><TooltipMaterial className="isClickable" title="Fazer upload de nova foto de perfil" ><CloudUploadIcon /></TooltipMaterial></label>
                                            <label className="text-right" onClick={(e) => onChange_Avatar(e)}><TooltipMaterial className="isClickable ms-2" title="Remover foto atual" ><HighlightOffIcon /></TooltipMaterial></label>
                                            <Form.Control type='file' id='input-file-avatar' accept="image/*" onChange={(e) => onChange_Avatar(e)} />
                                        </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Form.Label htmlFor="form-biography">Informações/Biografia</Form.Label>
                                                <Form.Control as="textarea" rows={3} id="form-biography" name="biography" placeholder={display.example.biography}
                                                    value={toString(biography)}
                                                    onChange={e=>setBiography(e.target.value)}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Card.Body>
                                <Card.Footer>
                                    <Row>
                                        <Col sm="12" className="text-right">
                                            <Button variant="contained" color="primary" onClick={ (e) => onClick_SavePublicInfo(e) } disabled={sendingPublicInfo}>
                                                { sendingPublicInfo ? <Spinner className='me-2' as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <FaSave className='me-2' size={22} /> } {' '}
                                                {display.buttom.save}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card.Footer>
                            </Form>
                        </Card>
                        <Card style={{ display : selectedSession === PERSONAL_DATA ? ' block' : 'none' }}>
                            <Form id="form-registry-private-information" validated={invalidatedFormPrivateInfo}>
                                <Card.Header>
                                    <Card.Title>{display.legend.private_information}</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <fieldset id="session-registry-private-info-personal-data">
                                            <legend>{display.legend.general_data}</legend>
                                            <PersonalDataRecord
                                                title={title}
                                                isEdit={true}

                                                tratament={tratament}
                                                fullname={fullname}
                                                gender={gender}
                                                birth={birth}
                                                norm={norm}
                                                operator={operator}
                                                connection={connection}
                                                sms={sms}
                                                zap={zap}
                                                major={major}
                                                nationality={nationality}
                                                naturalness={naturalness}
                                                setTratament={(value: any) => { setTratament(value); }}  
                                                setFullname={(value: any) => { setFullname(value); }}  
                                                setGender={(value: any) => { setGender(value); }}  
                                                setBirth={(value: any) => { setBirth(value); }}  
                                                setNorm={(value: any) => { setNorm(value); }}
                                                setOperator={(value: any) => { setOperator(value); }}
                                                setConnection={(value: any) => { setConnection(value); }}
                                                setSms={(value: any) => { setSms(value); }}
                                                setZap={(value: any) => { setZap(value); }}
                                                setMajor={(value: any) => { setMajor(value); }}
                                                setNationality={(value: any) => { setNationality(value); }} 
                                                setNaturalness={(value: any) => { setNaturalness(value); }} 
                                                />
                                        </fieldset>
                                    </Row>
                                </Card.Body>
                            </Form>
                        </Card>
                        <Card style={{ display : selectedSession === DOCUMENTATION_DATA ? ' block' : 'none' }}>
                            <Form id="form-registry-personal-document" validated={invalidatedFormPrivateInfo}>
                                <Card.Header>
                                    <Card.Title>{display.legend.personal_document}</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <fieldset id="session-registry-private-info-personal-document">
                                            <legend>{display.legend.documentation}</legend>
                                            <PersonalDocumentRecord
                                                title={title}
                                                isEdit={true}

                                                registrationPf={registrationPf}
                                                registrationIdentity={registrationIdentity}
                                                issuerIdentity={issuerIdentity}
                                                stateIdentity={stateIdentity}
                                                expeditionIdentity={expeditionIdentity}
                                                driversLicense={driversLicense}
                                                categoryDriversLicense={categoryDriversLicense}
                                                validityDriversLicense={validityDriversLicense}
                                                setRegistrationPf={(value: any) => { setRegistrationPf(value); }}  
                                                setRegistrationIdentity={(value: any) => { setRegistrationIdentity(value); }}  
                                                setIssuerIdentity={(value: any) => { setIssuerIdentity(value); }}  
                                                setStateIdentity={(value: any) => { setStateIdentity(value); }}  
                                                setExpeditionIdentity={(value: any) => { setExpeditionIdentity(value); }}  
                                                setDriversLicense={(value: any) => { setDriversLicense(value); }}  
                                                setCategoryDriversLicense={(value: any) => { setCategoryDriversLicense(value); }} 
                                                setValidityDriversLicense={(value: any) => { setValidityDriversLicense(value); }} 
                                            />
                                        </fieldset>
                                    </Row>
                                </Card.Body>
                            </Form>
                        </Card>
                        <Card style={{ display : selectedSession === MARITIME_LICENSE_DATA ? ' block' : 'none' }}>
                            <Form id="form-registry-maritime-license" validated={invalidatedFormPrivateInfo}>
                                <Card.Header>
                                    <Card.Title>{display.legend.maritime_license}</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <fieldset id="session-registry-private-info-maritime-license">
                                            <legend>{display.legend.maritime_license}</legend>
                                            <MaritimeLicenseRecord
                                                title={title}
                                                isEdit={true}
                                                isRequired={false}

                                                categoryNaval={categoryNaval}
                                                enrollmentNaval={enrollmentNaval}
                                                validityNaval={validityNaval}
                                                limitsNaval={limitsNaval}
                                                commentsNaval={commentsNaval}
                                                locationNaval={locationNaval}
                                                expeditionNaval={expeditionNaval}
                                                setCategoryNaval={(value: any) => { setCategoryNaval(value); }} 
                                                setEnrollmentNaval={(value: any) => { setEnrollmentNaval(value); }} 
                                                setValidityNaval={(value: any) => { setValidityNaval(value); }} 
                                                setLimitsNaval={(value: any) => { setLimitsNaval(value); }} 
                                                setCommentsNaval={(value: any) => { setCommentsNaval(value); }} 
                                                setLocationNaval={(value: any) => { setLocationNaval(value); }} 
                                                setExpeditionNaval={(value: any) => { setExpeditionNaval(value); }} 

                                                driversLicenseDocument={driversLicenseDocument}
                                                changeDriversLicenseDocument={(event: any) => { changeDriversLicenseDocument(event); }}
                                                clickViewDocument={clickViewDocument}
                                            />
                                        </fieldset>
                                    </Row>
                                </Card.Body>
                            </Form>
                        </Card>
                        <Card style={{ display : selectedSession === PERSONAL_ADDRESS_DATA ? ' block' : 'none' }}>
                            <Form id="form-registry-address" validated={invalidatedFormPrivateInfo}>
                                <Card.Header>
                                    <Card.Title>{display.legend.address}</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                    <fieldset id="session-registry-private-info-address">
                                        <legend>{display.legend.address}</legend>
                                        <AddressRecord 
                                            title={title}
                                            isEdit={true}

                                            zipcode={zipcode}
                                            state={state}
                                            city={city}
                                            district={district}
                                            place={place}
                                            habitation={habitation}
                                            complement={complement}
                                            setZipcode={(value: any) => { setZipcode(value); }} 
                                            setState={(value: any) => { setState(value); }} 
                                            setCity={(value: any) => { setCity(value); }} 
                                            setDistrict={(value: any) => { setDistrict(value); }} 
                                            setPlace={(value: any) => { setPlace(value); }} 
                                            setHabitation={(value: any) => { setHabitation(value); }} 
                                            setComplement={(value: any) => { setComplement(value); }}

                                            proofOfResidenceDocument={proofOfResidenceDocument}
                                            changeProofOfResidenceDocument={changeProofOfResidenceDocument}
                                            clickViewDocument={clickViewDocument}
                                        />
                                    </fieldset>
                                    </Row>
                                </Card.Body>
                            </Form>
                        </Card>
                        <Card id="session-registry-access-information"  style={{ display : selectedSession === ACCESS_DATA ? ' block' : 'none' }}>
                            <Form id="form-registry-access-info" validated={invalidatedFormAccessInfo}>
                                <Card.Header>
                                    <Card.Title>{display.legend.access_information}</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <AccessDataRecord 
                                        title={title}
                                        isEdit={true}

                                        token={token}
                                        password={password}
                                        checkPassword={checkPassword}
                                        setToken={(value: any) => { setToken(value); }} 
                                        setPassword={(value: any) => { setPassword(value); }} 
                                        setCheckPassword={(value: any) => { setCheckPassword(value); }}
                                    />
                                </Card.Body>
                            </Form>
                        </Card>
                    </Col>
                </Row>
                {vessels.length > 0 && (

                <Row className={styles.vessels}>
                    <Col sm="3" className="page-session">
                    <Card>
                        <Card.Header>
                            <Card.Title>Embarcações</Card.Title>
                        </Card.Header>
                        <ul className={`${styles.sideMenu} list-group list-group-flush`}>
                            <li className={selectedVesselSession===VESSEL_DATA ? styles.active : '' }>
                                <p className="list-group-item-action list-group-item" onClick={()=>
                                    setSelectedVesselSession(VESSEL_DATA)}>
                                    <DirectionsBoatFilledIcon sx={{fontSize: 24}}/><span>{vessels.length > 1 ? 'Dados das Embarcações' :
                                        'Dados da Embarcação'}</span>
                                </p>
                            </li>
                            <li className={selectedVesselSession===VESSEL_PHOTOS_ENGINES ? styles.active : '' }>
                                <p className="list-group-item-action list-group-item" onClick={()=>
                                    setSelectedVesselSession(VESSEL_PHOTOS_ENGINES)}>
                                    <CameraAltIcon sx={{fontSize: 24}} /><span>Fotos e Motores</span>
                                </p>
                            </li>
                            <li className={selectedVesselSession===VESSEL_INSURANCE ? styles.active : '' }>
                                <p className="list-group-item-action list-group-item" onClick={()=>
                                    setSelectedVesselSession(VESSEL_INSURANCE)}>
                                    <VerifiedUserIcon sx={{fontSize: 24}} /><span>Proteção</span>
                                </p>
                            </li>
                            <li className={selectedVesselSession===VESSEL_MOVEMENTS ? styles.active : '' }>
                                <p className="list-group-item-action list-group-item" onClick={()=>
                                    setSelectedVesselSession(VESSEL_MOVEMENTS)}>
                                    <ContentPasteIcon sx={{fontSize: 24}} /><span>Navegação</span>
                                </p>
                            </li>
                            <li className={selectedVesselSession===VESSEL_SERVICES ? styles.active : '' }>
                                <p className="list-group-item-action list-group-item" onClick={()=>
                                    setSelectedVesselSession(VESSEL_SERVICES)}>
                                    <MiscellaneousServicesIcon sx={{fontSize: 24}} /><span>Serviços</span>
                                </p>
                            </li>
                            <li className={selectedVesselSession===CHECK_IN ? styles.active : '' }>
                                <p className="list-group-item-action list-group-item" onClick={()=>
                                    setSelectedVesselSession(CHECK_IN)}>
                                    <AddTaskIcon sx={{fontSize: 24}} /><span>Check-in</span>
                                </p>
                            </li>
                        </ul>
                    </Card>
                    </Col>
                    <Col sm="9" className="page-sweet">
                    <Card id="session-registry-public-info"
                        style={{ display : selectedVesselSession === VESSEL_DATA ? ' block' : 'none' }}>
                        <Form id="form-registry-public-info" validated={invalidatedFormPublicInfo}>
                            <Card.Header>
                                <Card.Title>{vessels.length > 1 ? 'Dados das Embarcações' : 'Dados da Embarcação'}
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                {vessels?.map(vessel => (
                                <div className={styles.vessel}>
                                    <div className='d-flex align-items-end isClickable' onClick={()=> expand(vessel)}>

                                        <KeyboardArrowDownIcon color="inherit" className={`${styles.arrow} ${
                                            expandedVessels.includes(vessel.order) || vessel.expanded ? styles.rotate
                                            : '' } isClickable mt-0`} />
                                        {!expandedVessels.includes(vessel.order) && (
                                        <h6 className={`${ !expandedVessels.includes(vessel.order) || !vessel.expanded ?
                                            styles.fadeIn : styles.fadeOut } mb-0 ms-3 `} style={{paddingBottom: 2}}>
                                            {vessel.name} - {vessel.enrollment}</h6>

                                        )}
                                    </div>
                                    <div className={`${styles.vesselInfo} ${ expandedVessels.includes(vessel.order) ||
                                        vessel.expanded ? styles.fadeIn : styles.fadeOut }`}>

                                        <Row className={`${ expandedVessels.includes(vessel.order) || vessel.expanded ?
                                            styles.expanded : '' }`}>
                                            <Col sm="3" className='d-flex flex-column align-items-center'>
                                            <ImageLightBox containerStyle={styles.imageWrapper} src={vessel.image}
                                                alt="foto da embarcação" rounded={true} />
                                            <span className={styles.vesselColor}
                                                style={{backgroundColor: `${vessel.color}`}}></span>
                                            </Col>
                                            <Col sm="9">
                                            <Row>
                                                <Col sm="6">
                                                <FormGroup>
                                                    <Form.Label htmlFor="form-username">Nome da Embarcação</Form.Label>
                                                    <Form.Control id="form-username" name="username" disabled
                                                        placeholder="Nome da Embarcação"
                                                        value={toString(vessel.name)} />
                                                </FormGroup>
                                                </Col>
                                                <Col sm="6">
                                                <FormGroup>
                                                    <Form.Label htmlFor="form-enrollment">Registro</Form.Label>
                                                    <Form.Control id="form-enrollment" name="enrollment" disabled
                                                        placeholder="Registro" value={toString(vessel.enrollment)} />
                                                </FormGroup>
                                                </Col>

                                            </Row>
                                            <Row>
                                                <Col sm="3" >
                                                <FormGroup>
                                                    <Form.Label htmlFor="form-model">Tipo</Form.Label>
                                                    <Form.Control id="form-model" name="model" disabled
                                                        placeholder="Modelo" value={toInt(vessel.typeVessel) === VESSEL_JETSKI ? 'JetSki' : 'Lancha' } />
                                                </FormGroup>
                                                </Col>
                                                <Col sm="3" className="ps-2 pe-2">
                                                <FormGroup>
                                                    <Form.Label htmlFor="form-model">Modelo</Form.Label>
                                                    <Form.Control id="form-model" name="model" disabled
                                                        placeholder="Modelo" value={toString(vessel.model)} />
                                                </FormGroup>
                                                </Col>
                                                <Col sm="3" className="ps-2 pe-2">
                                                <FormGroup>
                                                    <Form.Label htmlFor="form-manufacturer">Fabricante</Form.Label>
                                                    <Form.Control id="form-manufacturer" name="manufacturer" disabled
                                                        placeholder="Fabricante"
                                                        value={(ManufacturerTypes.find(manufacturer=> manufacturer.id
                                                        ===
                                                        Number(vessel.manufacturer)))?.description || ''}

                                                        />
                                                </FormGroup>
                                                </Col>
                                                <Col sm="3">
                                                <FormGroup>
                                                    <Form.Label htmlFor="form-year">Ano</Form.Label>
                                                    <Form.Control id="form-year" name="year" disabled placeholder="Ano"
                                                        value={toString(vessel.year)} />
                                                </FormGroup>
                                                </Col>
                                            </Row>
                                            </Col>

                                        </Row>
                                    </div>
                                    <hr style={{color: 'white', borderTop: 'solid 1px white', opacity: 1}} />
                                </div>
                                ))}

                            </Card.Body>
                        </Form>
                    </Card>
                    <Card style={{ display : selectedVesselSession === VESSEL_PHOTOS_ENGINES ? ' block' : 'none' }}>
                        <Form>
                            <Card.Header>
                                <Card.Title>Fotos e Motores</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                {vessels?.map(vessel => (
                                <div className={styles.vessel}>

                                    <div className='d-flex align-items-end isClickable' onClick={()=> expand(vessel)}>

                                        <KeyboardArrowDownIcon color="inherit" className={`${styles.arrow} ${
                                            expandedVessels.includes(vessel.order) || vessel.expanded ? styles.rotate
                                            : '' } isClickable mt-0`} />
                                        {!expandedVessels.includes(vessel.order) && (
                                        <h6 className={`${ !expandedVessels.includes(vessel.order) || !vessel.expanded ?
                                            styles.fadeIn : styles.fadeOut } mb-0 ms-3 `} style={{paddingBottom: 2}}>
                                            {vessel.name} - {vessel.enrollment}</h6>

                                        )}
                                    </div>
                                    <div className={`${styles.vesselInfo} ${ expandedVessels.includes(vessel.order) ||
                                        vessel.expanded ? styles.fadeIn : styles.fadeOut }`}>

                                        <Row className={styles.imagesAndEngines}>
                                            <h5 className='text-center'
                                                style={{marginBottom: '-22px', borderBottom: 'solid 1px white', paddingBottom: 5}}>
                                                Fotos</h5>
                                            <div className="d-flex justify-content-between"
                                                style={{overflowX: 'scroll'}}>
                                                <div key={0}
                                                    className='d-flex flex-column align-items-center justify-content-center'>
                                                    <small
                                                        style={{marginBottom: vessel?.images?.prow?.document ? '-20px' : '-40px', marginRight: vessel?.images?.prow?.document ? 5 : 15, marginTop: vessel?.images?.prow?.document ? 20 : 40}}>Proa</small>
                                                    {!isEmpty(vessel?.images?.prow?.document) ? (
                                                    <>
                                                        <ImageLightBox containerStyle={`${styles.imageWrapper}
                                                            ${styles.vesselImage}`} src={vessel?.images?.prow?.document}
                                                            alt={display.label[toLowerCase(vessel?.images?.prow?.norm)]}
                                                            noBackground />
                                                    </>
                                                    ) : (
                                                    <div className={`${styles.imageWrapper}
                                                        ${styles.vesselImagePlaceholder}`}>
                                                        <CameraAltOutlined color='secondary' className={`isClickable
                                                            mb-1 ${styles.svg}`} sx={{fontSize: '73px'}} />

                                                    </div>
                                                    )}
                                                </div>
                                                <div key={1}
                                                    className='d-flex flex-column align-items-center justify-content-center'>
                                                    <small
                                                        style={{marginBottom: vessel?.images?.stern?.document ? '-20px' : '-40px', marginRight: vessel?.images?.stern?.document ? 5 : 15, marginTop: vessel?.images?.stern?.document ? 20 : 40}}>Popa</small>
                                                    {!isEmpty(vessel?.images?.stern?.document) ? (
                                                    <>
                                                        <ImageLightBox containerStyle={`${styles.imageWrapper}
                                                            ${styles.vesselImage}`}
                                                            src={vessel?.images?.stern?.document}
                                                            alt={display.label[toLowerCase(vessel?.images?.stern?.document)]}
                                                            noBackground />

                                                    </>
                                                    ) : (
                                                    <div className={`${styles.imageWrapper}
                                                        ${styles.vesselImagePlaceholder}`}>
                                                        <CameraAltOutlined color='secondary' className={`isClickable
                                                            mb-1 ${styles.svg}`} sx={{fontSize: '73px'}} />

                                                    </div>
                                                    )}
                                                </div>
                                                <div key={2}
                                                    className='d-flex flex-column align-items-center justify-content-center'>
                                                    <small
                                                        style={{marginBottom: vessel?.images?.portBoard?.document ? '-20px' : '-40px', marginRight: vessel?.images?.portBoard?.document ? 5 : 15, marginTop: vessel?.images?.portBoard?.document ? 20 : 40}}>Bombordo</small>
                                                    {!isEmpty(vessel?.images?.portBoard?.document) ? (
                                                    <>
                                                        <ImageLightBox containerStyle={`${styles.imageWrapper}
                                                            ${styles.vesselImage}`}
                                                            src={vessel?.images?.portBoard?.document}
                                                            alt={display.label[toLowerCase(vessel?.images?.portBoard?.norm)]}
                                                            noBackground />

                                                    </>
                                                    ) : (
                                                    <div className={`${styles.imageWrapper}
                                                        ${styles.vesselImagePlaceholder}`}>
                                                        <CameraAltOutlined color='secondary' className={`isClickable
                                                            mb-1 ${styles.svg}`} sx={{fontSize: '73px'}} />

                                                    </div>
                                                    )}
                                                </div>
                                                <div key={3}
                                                    className='d-flex flex-column align-items-center justify-content-center'>
                                                    <small
                                                        style={{marginBottom: vessel?.images?.starBoard?.document ? '-20px' : '-40px', marginRight: vessel?.images?.starBoard?.document ? 5 : 15, marginTop: vessel?.images?.starBoard?.document ? 20 : 40}}>Boreste</small>
                                                    {!isEmpty(vessel?.images?.starBoard?.document) ? (
                                                    <>
                                                        <ImageLightBox containerStyle={`${styles.imageWrapper}
                                                            ${styles.vesselImage}`}
                                                            src={vessel?.images?.starBoard?.document}
                                                            alt={display.label[toLowerCase(vessel?.images?.starBoard?.norm)]}
                                                            noBackground />

                                                    </>
                                                    ) : (
                                                    <div className={`${styles.imageWrapper}
                                                        ${styles.vesselImagePlaceholder}`}>
                                                        <CameraAltOutlined color='secondary' className={`isClickable
                                                            mb-1 ${styles.svg}`} sx={{fontSize: '73px'}} />

                                                    </div>
                                                    )}
                                                </div>
                                                <div key={4}
                                                    className='d-flex flex-column align-items-center justify-content-center'>
                                                    <small
                                                        style={{marginBottom: vessel?.images?.panel?.document ? '-20px' : '-40px', marginRight: vessel?.images?.panel?.document ? 5 : 15, marginTop: vessel?.images?.panel?.document ? 20 : 40}}>Painel</small>
                                                    {!isEmpty(vessel?.images?.panel?.document) ? (
                                                    <>
                                                        <ImageLightBox containerStyle={`${styles.imageWrapper}
                                                            ${styles.vesselImage}`}
                                                            src={vessel?.images?.panel?.document}
                                                            alt={display.label[toLowerCase(vessel?.images?.panel?.norm)]}
                                                            noBackground />

                                                    </>
                                                    ) : (
                                                    <div className={`${styles.imageWrapper}
                                                        ${styles.vesselImagePlaceholder}`}>
                                                        <CameraAltOutlined color='secondary' className={`isClickable
                                                            mb-1 ${styles.svg}`} sx={{fontSize: '73px'}} />

                                                    </div>
                                                    )}
                                                </div>
                                                <div key={5}
                                                    className='d-flex flex-column align-items-center justify-content-center'>
                                                    <small
                                                        style={{marginBottom: vessel?.images?.interior?.document ? '-20px' : '-40px', marginRight: vessel?.images?.interior?.document ? 5 : 15, marginTop: vessel?.images?.interior?.document ? 20 : 40}}>Interior</small>
                                                    {!isEmpty(vessel?.images?.interior?.document) ? (
                                                    <>
                                                        <ImageLightBox containerStyle={`${styles.imageWrapper}
                                                            ${styles.vesselImage}`}
                                                            src={vessel?.images?.interior?.document}
                                                            alt={display.label[toLowerCase(vessel?.images?.interior?.norm)]}
                                                            noBackground />

                                                    </>
                                                    ) : (
                                                    <div className={`${styles.imageWrapper}
                                                        ${styles.vesselImagePlaceholder}`}>
                                                        <CameraAltOutlined color='secondary' className={`isClickable
                                                            mb-1 ${styles.svg}`} sx={{fontSize: '73px'}} />

                                                    </div>
                                                    )}
                                                </div>
                                                <div key={6}
                                                    className='d-flex flex-column align-items-center justify-content-center'>
                                                    <small
                                                        style={{marginBottom: vessel?.images?.cabin?.document ? '-20px' : '-40px', marginRight: vessel?.images?.cabin?.document ? 5 : 15, marginTop: vessel?.images?.cabin?.document ? 20 : 40}}>Cabine</small>
                                                    {!isEmpty(vessel?.images?.cabin?.document) ? (
                                                    <>
                                                        <ImageLightBox containerStyle={`${styles.imageWrapper}
                                                            ${styles.vesselImage}`}
                                                            src={vessel?.images?.cabin?.document}
                                                            alt={display.label[toLowerCase(vessel?.images?.cabin?.norm)]}
                                                            noBackground />

                                                    </>
                                                    ) : (
                                                    <div className={`${styles.imageWrapper}
                                                        ${styles.vesselImagePlaceholder}`}>
                                                        <CameraAltOutlined color='secondary' className={`isClickable
                                                            mb-1 ${styles.svg}`} sx={{fontSize: '73px'}} />

                                                    </div>
                                                    )}
                                                </div>
                                            </div>
                                        </Row>
                                        <Row className={styles.imagesAndEngines}>
                                            {vessel.engines?.length > 0 && (

                                            <h5 className='text-center'
                                                style={{marginBottom: '-22px', borderBottom: 'solid 1px white', paddingBottom: 5}}>
                                                Motores</h5>
                                            )}
                                            {vessel.engines && vessel.engines.map((engine: any, index: number) => (
                                            <div className='mt-5'>
                                                <Row key={`engine-${index}`}>
                                                    <Col sm="9">
                                                    <Row>
                                                        <Col sm="3">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-validity-tr">{display.label.maker}
                                                            </Form.Label>
                                                            <FormControl id="form-model" name="model"
                                                                placeholder={display.example.maker}
                                                                value={MakerTypes.find(maker=> maker.id ===
                                                                Number(engine.maker))?.description} disabled
                                                                />
                                                        </FormGroup>
                                                        </Col>
                                                        <Col sm="3" className="ps-2 pe-2">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-validity-tr">Modelo</Form.Label>
                                                            <FormControl id="form-model" name="model"
                                                                placeholder={display.example.type_and_model}
                                                                value={toString(engine.model)} disabled />
                                                        </FormGroup>
                                                        </Col>
                                                        <Col sm="3" className="ps-2 pe-2">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-validity-tr">
                                                                {display.label.type_and_model}</Form.Label>
                                                            <FormControl id="form-model" name="model"
                                                                placeholder={display.example.type_and_model}
                                                                value={EngineTypes.find(type=> type.id ===
                                                                Number(engine.norm))?.description} disabled
                                                                />
                                                        </FormGroup>
                                                        </Col>
                                                        <Col sm="3">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-validity-tr">Ano</Form.Label>
                                                            <FormControl id="form-year" name="year"
                                                                value={toString(engine.year)} disabled />
                                                        </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm="3">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-validity-tr">Número de Série
                                                            </Form.Label>
                                                            <FormControl id="form-serial-number" name="serialNumber"
                                                                placeholder={display.example.serial_number}
                                                                value={toString(engine.serialNumber)} disabled />
                                                        </FormGroup>
                                                        </Col>
                                                        <Col sm="3" className="ps-2 pe-2">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-validity-tr">Potência (HP)
                                                            </Form.Label>
                                                            <FormControl id="form-potency" name="potency"
                                                                value={toString(engine.potency)} disabled />
                                                        </FormGroup>
                                                        </Col>
                                                        <Col sm="3" className="ps-2 pe-2">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-validity-tr">Combustível
                                                            </Form.Label>
                                                            <FormControl id="form-model" name="model"
                                                                placeholder={display.example.fuel}
                                                                value={FuelTypes.find(fuel=> fuel.id ===
                                                                Number(engine.fuel))?.description} disabled
                                                                />
                                                        </FormGroup>
                                                        </Col>
                                                        <Col sm="3">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-origin">Procedência</Form.Label>
                                                            <FormControl id="form-model" name="model"
                                                                placeholder={display.example.origin}
                                                                value={OriginType.find(origin=> origin.id ===
                                                                Number(engine.origin))?.description} disabled
                                                                />
                                                        </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    </Col>
                                                    <Col sm="3">
                                                    <div className="d-flex justify-content-center">
                                                        {!isEmpty(engine.image) ? (
                                                        <div
                                                            className='d-flex flex-column justify-content-center align-items-center'>
                                                            <ImageLightBox containerStyle={`${styles.imageWrapper}
                                                                ${styles.vesselImage}`} src={engine.image} alt={'Motor'}
                                                                noBackground />
                                                        </div>
                                                        ) : (
                                                        <div className={`${styles.imageWrapper}
                                                            ${styles.vesselImagePlaceholder}`}>
                                                            <CameraAltOutlined color='secondary' className={`isClickable
                                                                mb-3 ${styles.svg}`} sx={{fontSize: '73px'}} />
                                                        </div>
                                                        )}
                                                    </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                            ))}
                                        </Row>
                                    </div>
                                    <hr style={{color: 'white', borderTop: 'solid 1px white', opacity: 1}} />
                                </div>
                                ))}
                            </Card.Body>
                        </Form>
                    </Card>
                    <Card style={{ display : selectedVesselSession === VESSEL_INSURANCE ? ' block' : 'none' }}>
                        <Form id="form-registry-personal-document" validated={invalidatedFormPrivateInfo}>
                            <Card.Header>
                                <Card.Title>Proteção</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                {vessels?.map(vessel => (
                                <div className={styles.vessel}>
                                    <div className='d-flex align-items-end isClickable' onClick={()=> expand(vessel)}>
                                        <KeyboardArrowDownIcon color="inherit" className={`${styles.arrow} ${
                                            expandedVessels.includes(vessel.order) || vessel.expanded ? styles.rotate
                                            : '' } isClickable mt-0`} />
                                        {!expandedVessels.includes(vessel.order) && (
                                        <h6 className={`${ !expandedVessels.includes(vessel.order) || !vessel.expanded ?
                                            styles.fadeIn : styles.fadeOut } mb-0 ms-3 `} style={{paddingBottom: 2}}>
                                            {vessel.name} - {vessel.enrollment}</h6>

                                        )}
                                    </div>
                                    <div className={`${styles.vesselInfo} ${ expandedVessels.includes(vessel.order) ||
                                        vessel.expanded ? styles.fadeIn : styles.fadeOut }`}>
                                        <Row>
                                            <Col sm="3" className="ms-0 me-0">
                                            <FormGroup>
                                                <Form.Label htmlFor="form-insurance-registry">CNPJ</Form.Label>
                                                <FormControl id="form-insurance-registry" name="insuranceRegistry"
                                                    maxLength={14} placeholder={display.example.insurance_name}
                                                    value={toString(vessel.insuranceRegistry)} disabled />
                                            </FormGroup>
                                            </Col>
                                            <Col sm="3" className="ps-2 pe-2">
                                            <FormGroup>
                                                <Form.Label htmlFor="form-insurance-name">{display.label.insurance_name}
                                                </Form.Label>
                                                <FormControl id="form-insurance-name" name="insuranceName"
                                                    placeholder={display.example.insurance_name} disabled
                                                    value={toString(vessel.insuranceName)} />
                                            </FormGroup>
                                            </Col>
                                            <Col sm="2" className="ps-2 pe-2">
                                            <FormGroup>
                                                <Form.Label htmlFor="form-insurance-number">
                                                    {display.label.insurance_number}</Form.Label>
                                                <FormControl id="form-insurance-number" name="insuranceNumber"
                                                    placeholder={display.example.insurance_number} disabled
                                                    value={numberMask(vessel.insuranceNumber)} />
                                            </FormGroup>
                                            </Col>
                                            <Col sm="2" className="ps-2 pe-2">
                                            <FormGroup>
                                                <Form.Label htmlFor="form-insurance-validity">
                                                    {display.label.insurance_validity}</Form.Label>
                                                <FormControl id="form-insurance-validity" name="insuranceValidity"
                                                    maxLength={10} disabled
                                                    placeholder={display.example.insurance_validity}
                                                    value={dateToString(vessel.insuranceValidity, 'dd/mm/yyyy' )} />
                                            </FormGroup>
                                            </Col>
                                            <Col sm="2">
                                            <FormGroup>
                                                <Form.Label htmlFor="form-insurance-cost">{display.label.insurance_cost}
                                                </Form.Label>
                                                <FormControl id="form-insurance-cost" name="insuranceCost" readOnly
                                                    placeholder={display.example.insurance_cost} className="text-right"
                                                    value={`R$ ${floatMask(vessel.insuranceCost)}`} />
                                            </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="4">
                                            <FormGroup>
                                                <Form.Label htmlFor="form-insurance-contact">
                                                    {display.label.insurance_contact}</Form.Label>
                                                <FormControl id="form-insurance-contact" name="insuranceContact"
                                                    placeholder={display.example.insurance_contact}
                                                    value={toString(vessel.insuranceContact)} disabled />
                                            </FormGroup>
                                            </Col>
                                            <Col sm="2" className="ps-2 pe-2">
                                            <FormGroup>
                                                <Form.Label htmlFor="form-insurance-phone1">
                                                    {display.label.insurance_phone1}</Form.Label>
                                                <FormControl id="form-insurance-phone1" name="insurancePhone1"
                                                    placeholder={display.example.insurance_phone}
                                                    value={phoneMask(vessel.insurancePhone1)} disabled />
                                            </FormGroup>
                                            </Col>
                                            <Col sm="2" className="ps-2 pe-2">
                                            <FormGroup>
                                                <Form.Label htmlFor="form-insurance-phone2">
                                                    {display.label.insurance_phone2}</Form.Label>
                                                <FormControl id="form-insurance-phone2" name="insurancePhone2"
                                                    placeholder={display.example.insurance_phone}
                                                    value={phoneMask(vessel.insurancePhone2)} disabled />
                                            </FormGroup>
                                            </Col>
                                            <Col sm="4">
                                            <FormGroup>
                                                <Form.Label htmlFor="form-insurance-email">
                                                    {display.label.insurance_email}</Form.Label>
                                                <FormControl id="form-insurance-email" name="insuranceEmail"
                                                    placeholder={display.example.insurance_email}
                                                    value={toString(vessel.insuranceEmail)} disabled />
                                            </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                    <hr style={{color: 'white', borderTop: 'solid 1px white', opacity: 1}} />
                                </div>
                                ))}
                            </Card.Body>
                        </Form>
                    </Card>
                    <Card style={{ display : selectedVesselSession === VESSEL_MOVEMENTS ? ' block' : 'none' }}>
                        <Form id="form-registry-maritime-license" validated={invalidatedFormPrivateInfo}>
                            <Card.Header>
                                <Card.Title>Navegação</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                {vessels?.map(vessel => (
                                <div className={styles.vessel}>
                                    <div className='d-flex align-items-end isClickable' onClick={()=> expand(vessel)}>
                                        <KeyboardArrowDownIcon color="inherit" className={`${styles.arrow} ${
                                            expandedVessels.includes(vessel.order) || vessel.expanded ? styles.rotate
                                            : '' } isClickable mt-0`} />
                                        {!expandedVessels.includes(vessel.order) && (
                                        <h6 className={`${ !expandedVessels.includes(vessel.order) || !vessel.expanded ?
                                            styles.fadeIn : styles.fadeOut } mb-0 ms-3 `} style={{paddingBottom: 2}}>
                                            {vessel.name} - {vessel.enrollment}</h6>

                                        )}
                                    </div>
                                    <div className={`${styles.vesselInfo} ${ expandedVessels.includes(vessel.order) ||
                                        vessel.expanded ? styles.fadeIn : styles.fadeOut }`}>
                                        <Row>
                                            <ThemeProvider theme={theme}>
                                                <div style={{ height: 180, width: '100%', marginTop: 0 }}>
                                                    <DataGrid
                                                        rows={records?.sort(
                                                            (a: any, b: any) =>
                                                              Number(new Date(b.dateMovement)) -
                                                              Number(new Date(a.dateMovement))
                                                          ).filter((record: any) => record.vessel.enrollment === vessel.enrollment).slice(0, 2)}
                                                        getRowId={row => row.id}
                                                        loading={isLoading}
                                                        columns={columns}
                                                        disableSelectionOnClick
                                                        hideFooterSelectedRowCount
                                                        hideFooterPagination
                                                        hideFooter
                                                    />
                                                </div>
                                            </ThemeProvider>
                                        </Row>
                                    </div>
                                    <hr style={{color: 'white', borderTop: 'solid 1px white', opacity: 1}} />
                                </div>

                                ))}
                                <div className='d-flex justify-content-center'>
                                    <Button className='text-center' size='small' variant="contained" onClick={ () => setShowSelectReservation(true) } ><AddIcon />Agendar Navegação</Button>
                                </div>
                            </Card.Body>
                        </Form>
                    </Card>
                    <Card style={{ display : selectedVesselSession === VESSEL_SERVICES ? ' block' : 'none' }}>
                        <Form id="form-registry-address" validated={invalidatedFormPrivateInfo}>
                            <Card.Header>
                                <Card.Title>Serviços</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                {vessels?.map(vessel => (
                                <div className={styles.vessel}>
                                    <div className='d-flex align-items-end isClickable' onClick={()=> expand(vessel)}>
                                        <KeyboardArrowDownIcon color="inherit" className={`${styles.arrow} ${
                                            expandedVessels.includes(vessel.order) || vessel.expanded ? styles.rotate
                                            : '' } isClickable mt-0`} />
                                        {!expandedVessels.includes(vessel.order) && (
                                        <h6 className={`${ !expandedVessels.includes(vessel.order) || !vessel.expanded ?
                                            styles.fadeIn : styles.fadeOut } mb-0 ms-3 `} style={{paddingBottom: 2}}>
                                            {vessel.name} - {vessel.enrollment}</h6>

                                        )}
                                    </div>
                                    <div className={`${styles.vesselInfo} ${ expandedVessels.includes(vessel.order) ||
                                        vessel.expanded ? styles.fadeIn : styles.fadeOut }`}>
                                    <h5 className='mb-0 mt-3 bolder' style={{color: 'white'}}><ManageAccountsIcon className='mb-1' sx={{fontSize: 24, color: 'white'}} /> Manutenções</h5>
                                        <Row>
                                            <ThemeProvider theme={theme}>
                                                <div style={{ height: 180, width: '100%', marginTop: 0 }}>
                                                    <DataGrid
                                                        rows={rowsMaintenance?.sort(
                                                            (a: any, b: any) =>
                                                              Number(new Date(b.date)) -
                                                              Number(new Date(a.date))
                                                          )}
                                                        getRowId={row => row.id}
                                                        loading={isLoading}
                                                        columns={columnsMaitenance}
                                                        disableSelectionOnClick
                                                        hideFooterSelectedRowCount
                                                        hideFooterPagination
                                                        hideFooter
                                                    />
                                                </div>
                                            </ThemeProvider>
                                        </Row>
                                    </div>
                                    <div className={`${styles.vesselInfo} ${ expandedVessels.includes(vessel.order) ||
                                        vessel.expanded ? styles.fadeIn : styles.fadeOut }`}>
                                    <h5 className='mb-0 mt-3 bolder' style={{color: 'white'}}><WashIcon className='mb-1' sx={{fontSize: 24, color: 'white'}} /> Limpeza e Higienizacão</h5>
                                        <Row>
                                            <ThemeProvider theme={theme}>
                                                <div style={{ height: 180, width: '100%', marginTop: 0 }}>
                                                    <DataGrid
                                                        rows={rowsCleaning?.sort(
                                                            (a: any, b: any) =>
                                                              Number(new Date(b.date)) -
                                                              Number(new Date(a.date))
                                                          )}
                                                        getRowId={row => row.id}
                                                        loading={isLoading}
                                                        columns={columnsMaitenance}
                                                        disableSelectionOnClick
                                                        hideFooterSelectedRowCount
                                                        hideFooterPagination
                                                        hideFooter
                                                    />
                                                </div>
                                            </ThemeProvider>
                                        </Row>
                                    </div>
                                    <div className={`${styles.vesselInfo} ${ expandedVessels.includes(vessel.order) ||
                                        vessel.expanded ? styles.fadeIn : styles.fadeOut }`}>
                                    <h5 className='mb-0 mt-3 bolder' style={{color: 'white'}}><FastfoodIcon className='mb-1' sx={{fontSize: 24, color: 'white'}} /> Delivery</h5>
                                        <Row>
                                            <ThemeProvider theme={theme}>
                                                <div style={{ height: 180, width: '100%', marginTop: 0 }}>
                                                    <DataGrid
                                                        rows={rowsDelivery?.sort(
                                                            (a: any, b: any) =>
                                                              Number(new Date(b.date)) -
                                                              Number(new Date(a.date))
                                                          )}
                                                        getRowId={row => row.id}
                                                        loading={isLoading}
                                                        columns={columnsDelivery}
                                                        disableSelectionOnClick
                                                        hideFooterSelectedRowCount
                                                        hideFooterPagination
                                                        hideFooter
                                                    />
                                                </div>
                                            </ThemeProvider>
                                        </Row>
                                    </div>
                                    <hr style={{color: 'white', borderTop: 'solid 1px white', opacity: 1}} />
                                </div>

                                ))}
                                <div className='d-flex justify-content-center'>
                                    <Button className='text-center' size='small' variant="contained" onClick={ () => setShowSelectService(true) } ><AddIcon />Solicitar Serviço</Button>
                                </div>
                            </Card.Body>
                        </Form>
                    </Card>
                    <Card style={{ display : selectedVesselSession === CHECK_IN ? ' block' : 'none' }}>
                        <Form id="form-registry-personal-document" validated={invalidatedFormPrivateInfo}>
                            <Card.Header>
                                <Card.Title>Check-in</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <div className="d-flex align-items-center flex-column">
                                    <h5 className="mt-0 mb-2 gray-text">Digite seu localizador</h5>
                                        <MaterialFormControl fullWidth sx={{width: 300, marginBottom: 2 }}
                                            variant="standard">
                                            <InputLabel className={styles.label} htmlFor="standard-adornment-amount">
                                                Localizador</InputLabel>
                                            <Input id="standard-adornment-amount" value={reservation}
                                                onKeyPress={handleKeypress} onChange={e=>
                                            setReservation(e.target.value.toUpperCase())}
                                            endAdornment = {
                                            <InputAdornment position="end">
                                                <SearchIcon className='isClickable' />
                                            </InputAdornment>
                                            }
                                            />
                                        </MaterialFormControl>
                                        <FormHelperText id="component-helper-text">
                                            Preenchemos com seu localizador em aberto
                                        </FormHelperText>
                                        <div className="mt-4 justify-content-center d-flex">
                                            <Button disabled={searching || reservation==='' } variant="contained"
                                                color="primary" size="large" className={`${styles.submit}`}
                                                onClick={search}>
                                                {!searching ? 'BUSCAR' : 'AGUARDE...'}
                                            </Button>
                                        </div>
                                </div>
                            </Card.Body>
                        </Form>
                    </Card>
                    </Col>
                </Row>
                )}
            <SelectReservationModal 
                show={showSelectReservation}

                onClick_Close={(event: any) => { 
                    setShowSelectReservation(false);
                }}
                onClick_Confirm={(event: any, value: any) => { 
                    setShowSelectReservation(false);
                    // fillReservationSchedule(event); 
                }}
            />
            <SelectServiceModal 
                show={showSelectService}

                onClick_Close={(event: any) => { 
                    setShowSelectService(false);
                }}
                
            />
            </Container>
        );
    } // viewPage

    return (
        <div className="page">
            <CadastreHeader title={title} description={description} />
            { viewPage() }
        </div>
    )
}

export default ProfilePage;
