import { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';
import { Badge, Col, Form, ListGroup, Modal, Row } from 'react-bootstrap';
import { Button } from '@mui/material';
import { FaTimes } from 'react-icons/fa';
import { cnpjMask } from '../utilities/masks';
import authService from '../services/auth.service';
import lclService from '../services/lcl.service';
import { useAlertMessageContext } from './alert-message.context';
import { AlertMessageEnum } from '../utilities/types';
import { isEmpty } from '../utilities/validators';

interface IPartnerContext {
    partner: any,
    setPartner: React.Dispatch<React.SetStateAction<{}>>
    showDefinedPartner: (isShow: boolean) => void
};

const PartnerContext = createContext({} as IPartnerContext);

export const usePartnerContext = () => {
    return useContext(PartnerContext);
};

export const PartnerProvider: React.FC<PropsWithChildren> = ({ children }) => {
   
    const alertContext = useAlertMessageContext();

    const [ actualPartner, setActualPartner ] = useState({} as any);
    const [ tempPartner, setTempPartner ] = useState({} as any);
    const [ isShowDefined, setIsShowDefined ] = useState(false);
    const [ userPartners, setUserPartners ] = useState([] as any);
    
    const handleSetPartner = (newPartner: any) => {
        setActualPartner(newPartner);
    };

    const handleDefinedPartner = (isShow: boolean) => {
        setIsShowDefined(isShow);
    };

    const handleClose = () => {
        setIsShowDefined(false);
    }

    function onClose_DefinedPartner(event: any) {
        authService.changePartner(tempPartner);
        setActualPartner(tempPartner);
        setIsShowDefined(false);
        window.location.href = '/portal';
    }

    function isUserPartner(partnerId: number) {
        var ckecked = tempPartner ? partnerId === tempPartner.id : false;
        return ckecked;
    }

    function onChange_UserPartner(event: any, partnerId: number) {
        for (var up = 0; up < userPartners.length; up++) {
            const userPartner = userPartners[up];
            if (userPartner.partnerId === partnerId) {
                setTempPartner(userPartner.partner);
                break;
            }
        }
    }

    async function searchfilter() {
        try {
            const user = authService.currentUser();
            if (!isEmpty(user)) {
                const localUserPartners = await lclService.partnersUser(user.id);
                const localPartners = [] as any;
                for (var up = 0; up < localUserPartners.length; up++) {
                    const userPartner = localUserPartners[up];

                    const localPartner = await lclService.partnerById(userPartner.partnerId);
                    localPartners[localPartners.length] = {
                        partnerId: userPartner.partnerId,
                        principal: userPartner.principal,
                        partner: localPartner,
                        userId: userPartner.userId
                    }
                }
                setUserPartners(localPartners);
            }
        } catch (error: any) {
            alertContext.show(AlertMessageEnum.FAIL, 'Portal', error);
        }
    }

    useEffect(() => {
        setTempPartner(actualPartner);
        searchfilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShowDefined]);

    return (
        <PartnerContext.Provider value={{
            partner: actualPartner,
            setPartner: handleSetPartner,
            showDefinedPartner: handleDefinedPartner
        }}>
            {children}
            <Modal
                show={isShowDefined}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>Definir Parceiro</Modal.Title>
                    <div className="card-actions float-right">
                        <FaTimes className="isClickable" size={22} onClick={handleClose} />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <ListGroup className="partner">
                                { userPartners.map((userPartner: any, idx: number) => (
                                    <ListGroup.Item key={`listgroup-${idx}`} className="d-flex justify-content-between align-items-start"
                                        onClick={e => onChange_UserPartner(e, userPartner.partner.id)}
                                    >
                                        <Form.Check key={`check-${idx}`}
                                            type='radio'
                                            id={`${userPartner.partnerId}-radio`}
                                            checked={isUserPartner(userPartner.partner.id)}
                                            onChange={e => onChange_UserPartner(e, userPartner.partner.id)}
                                        />
                                        <div key={`label-${idx}`} className="w-90 d-flex flex-column">
                                            <span>{userPartner.partner.company && cnpjMask(userPartner.partner.company.registrationPj)}</span>
                                            <span>{userPartner.partner.company && userPartner.partner.company.socialReason}</span>
                                            <span>{userPartner.partner.company && userPartner.partner.company.fantasy}</span>
                                        </div>
                                        <Badge key={`badge-${idx}`} className="badge-principal">{userPartner.principal ? 'Principal' : ''}</Badge>
                                    </ListGroup.Item>
                                )) }
                            </ListGroup>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <span></span>
                    <Button variant="contained" onClick={(e: any) => onClose_DefinedPartner(e)}>Definir</Button>
                </Modal.Footer>
            </Modal>
        </PartnerContext.Provider>
    )
}
