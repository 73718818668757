import { isEmpty } from "./validators";

export function removeFormatDate(value: string): string {
    let result = '';

    if ((typeof value != 'undefined') && (value !== null)) {
        if (value.length === 0) {
            result = value;
        } else if (value.length === 8) {
            result = value.substr(4, 4) +'-'+ value.substr(2, 2) +'-'+ value.substr(0, 2) +'T00:00:00.000Z';
        } else if (value.length === 10) {
            result = value.substr(6, 4) +'-'+ value.substr(3, 2) +'-'+ value.substr(0, 2) +'T00:00:00.000Z';
        } else if (value.length === 14) {
            result = value.substr(4, 4) +'-'+ value.substr(2, 2) +'-'+ value.substr(0, 2) +'T'+ value.substr(8, 2) +':'+ value.substr(10, 2) +':'+ value.substr(12, 2) +'.000Z';
        } else if (value.length === 16) {
            result = value.substr(4, 4) +'-'+ value.substr(2, 2) +'-'+ value.substr(0, 2) +'T'+ value.substr(8, 2) +':'+ value.substr(10, 2) +':00.000Z';
        } else if (value.length === 19) {
            result = value.substr(6, 4) +'-'+ value.substr(3, 2) +'-'+ value.substr(0, 2) +'T'+ value.substr(11, 8) +'.000Z';
        } else if (value.length === 24) {
            result = value;
        } else {
            throw new Error(`Format data for out '(${value.length}) ${value}' not implemented.`);
        }
    }
    // 1977-02-12T20:30:16.000Z
    return result;
}

export function removeMask(value: string, padrao?: string): string {
    let result = '';
        value = String(value);

    if ((typeof value != 'undefined') && (value !== null)) {
        result = value.replaceAll(' ','')
            .replaceAll('.','')
            .replaceAll('-','')
            .replaceAll('(','')
            .replaceAll(')','')
            .replaceAll('/','')
            .replace(',','.');
    }

    if (isEmpty(result) && !isEmpty(padrao))
        result = padrao || '';
    return result;
}

export function cpfCnpjMask(value: string): string {
    let result = '';
        value = String(value);

    if ((typeof value != 'undefined') && (value !== null)) {
        result = value.replace(/\D/g,"");

        if (result.length === 11) {
            result = cpfMask(result);
        } else if (result.length === 14) {
            result = cnpjMask(result);
        } else {
            result = numberMask(result);
        }
    }
    return result;
}

export function cnpjMask(value: string): string {
    let result = '';
        value = String(value);

    if ((typeof value != 'undefined') && (value !== null))
        result = value.replace(/\D/g,"")
            .replace(/(\d{2})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1/$2')
            .replace(/(\d{4})(\d{1,2})/, '$1-$2')
            .replace(/(-\d{2})\d+?$/, '$1');
    return result;
}

export function cpfMask(value: string): string {
    let result = '';
        value = String(value);

    if ((typeof value != 'undefined') && (value !== null))
        result = value.replace(/\D/g,"")
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d{1,2})/, '$1-$2')
            .replace(/(-\d{2})\d+?$/, '$1');
    return result;
}

export function zipcodeMask(value: string): string {
    let result = '';
        value = String(value);

    if ((typeof value != 'undefined') && (value !== null))
        result = value.replace(/\D/g,"")
            .replace(/(\d{2})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d{1,2})/, '$1-$2')
            .replace(/(-\d{3})\d+?$/, '$1');
    return result;
}

export function phoneMask(value: string): string {
    let result = '';
        value = String(value);

    if ((typeof value != 'undefined') && (value !== null))
        result = value.replace(/\D/g,"")
            .replace(/\D/g,"")           
            .substring(0, 11)    
            .replace(/^(\d{2})(\d)/g,"($1) $2") 
            .replace(/(\d)(\d{4})$/,"$1-$2");
    return result;
}

export function dataMask(value: string): string { 
    let result = '';
        value = String(value);

    if ((typeof value != 'undefined') && (value !== null))
        result = value.replace(/\D/g,"")
            .replace(/(\d{2})(\d)/,"$1/$2")
            .replace(/(\d{2})(\d)/,"$1/$2");
    return result;
}

export function horaMask(value: string): string { 
    let result = '';
        value = String(value);

    if ((typeof value != 'undefined') && (value !== null))
        result = value.replace(/\D/g,"")
            .replace(/(\d{2})(\d)/,"$1:$2")
            .replace(/(\d{2})(\d)/,"$1:$2");
    return result;
}

export function datahoraMask(value: string): string { 
    let result = '';
        value = String(value);

    if ((typeof value != 'undefined') && (value !== null))
        result = value.replace(/\D/g,"")
            .replace(/(\d{12})(\d)/,"$1:$2")
            .replace(/(\d{10})(\d)/,"$1:$2")
            .replace(/(\d{8})(\d)/,"$1 $2")
            .replace(/(\d{4})(\d)/,"$1/$2")
            .replace(/(\d{2})(\d)/,"$1/$2");
    return result;
}

export function numberMask(value: string): string {
    let result = '';
        value = String(value);

    if ((typeof value != 'undefined') && (value !== null))
        result = value.replace(/\D/g,"")
            .replace(/(\d+)(\d{3})/,"$1.$2")
            .replace(/(\d+)(\d{3})/,"$1.$2")
            .replace(/(\d+)(\d{3})/,"$1.$2")
            .replace(/(\d+)(\d{3})/,"$1.$2")
            .replace(/(\d+)(\d{3})/,"$1.$2");
    return result;
}

export function floatMask(value: string, forceDecimal?: boolean): string {
    let result = '';
        value = String(value);

    if ((value === 'null') || (value === null) || (value === ''))
        value = '0.00';

    if (forceDecimal) {
        //if (value.length < 3) {
            if ((value.indexOf('.') === -1) && (value.indexOf(',') === -1)) {
                value = value +'.00';
            }
        //}
    }

    if (value.length > 3) { // 0,0
        let char = value.substring(value.length - 2, value.length - 1);
        if ((char === '.') || (char === ',')) {
            value = value +'0';
        }
    }

    if ((typeof value != 'undefined') && (value !== null))
        result = value.replace(/\D/g,"")
            .replace(/(\d+)(\d{2})/,"$1,$2")
            .replace(/(\d+)(\d{3})/,"$1.$2")
            .replace(/(\d+)(\d{3})/,"$1.$2")
            .replace(/(\d+)(\d{3})/,"$1.$2")
            .replace(/(\d+)(\d{3})/,"$1.$2");

    return result;
}

export function sizeMask(value: string, forceDecimal?: boolean): string {
    let result = '';
        value = String(value);

    if ((value === 'null') || (value === null) || (value === ''))
        value = '0.0';

    if (forceDecimal) {
        if (value.length < 2) {
            if ((value.indexOf('.') === -1) && (value.indexOf(',') === -1)) {
                value = value +'.0';
            }
        }
    }
    
    if ((typeof value != 'undefined') && (value !== null))
        result = value.replace(/\D/g,"")
            .replace(/(\d+)(\d{1})/,"$1,$2")
            .replace(/(\d+)(\d{3})/,"$1.$2")
            .replace(/(\d+)(\d{3})/,"$1.$2")
            .replace(/(\d+)(\d{3})/,"$1.$2")
            .replace(/(\d+)(\d{3})/,"$1.$2");

    return result;
}

export function protectCpf(value: string): string {
    return value.substring(0, 3) + '.***.***-' + value.substring(12, 14);
}

export function protectDate(value: string): string {
    return value.substring(0, 5) + '/****';
}
