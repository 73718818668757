import AddIcon from '@mui/icons-material/Add';
import { Button as ButtonMaterial } from '@mui/material';
import { useEffect, useState } from "react";
import { Card, Col, Container, OverlayTrigger, Popover, Row, Tooltip, Image } from "react-bootstrap";
import { FaEdit, FaTrash } from "react-icons/fa";
import { RiShipFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import Lancha from '../../assets/Lancha.svg';
import Jet from '../../assets/Jetski.svg';
import { useAlertMessageContext } from "../../contexts/alert-message.context";
import { useConfirmMessageContext } from "../../contexts/confirm-message.context";
import { useDeleteMessageContext } from "../../contexts/delete-message.context";
import authService from "../../services/auth.service";
import lclService from "../../services/lcl.service";
import { toInt } from "../../utilities/auxiliary-functions";
import { PermissionEnum, VESSEL_IATE, VESSEL_JETSKI, VESSEL_LANCHA } from "../../utilities/constants";
import { AlertMessageEnum } from "../../utilities/types";
import VesselFilter from "../filters/vessel.filter";
import OperationHeader from "../headers/operation.header";
import BookcaseCadastreModal from "../modals/bookcase.cadastre.modal";
import { useTranslateContext } from '../../contexts/translate.context';
import { usePartnerContext } from '../../contexts/partner.context';
import { isEmpty } from '../../utilities/validators';
import { TPagination } from '../../types/PaginationType';

export interface IKeepBoatsOperationProps {
}

export const KeepBoatsOperation: React.FC<IKeepBoatsOperationProps> = (props: IKeepBoatsOperationProps) => {
    
    const navigator = useNavigate();

    const alertContext = useAlertMessageContext();
    const confirmContext = useConfirmMessageContext();
    const deleteContext = useDeleteMessageContext();
    const { display } = useTranslateContext();
    const { partner } = usePartnerContext();

    const title = display.title.keep_boat;
    const description = '';
    
    let reloadPage = false;
    const [ isLoading, setLoading ] = useState(false);

    const [ bookcaseId, setBookcaseId ] = useState('');
    const [ bookcase, setBookcase ] = useState({} as any);
    const [ isEditBookcase, setEditBookcase ] = useState(false);
    const [ showBookcase, setShowBookcase ] = useState(false);

    const [ bookcases, setBookcases ] = useState([] as any);
    const [ showFilterVessel, setShowFilterVessel ] = useState(false);
    const [ drawerInProcess, setDrawerInProcess ] = useState({} as any);

    const [ vessels, setVessels ] = useState([] as any);

    async function searchfilter() {
        if (!isEmpty(partner)) {
            const attributes = [] as any;
            const where = {} as any;
            const order = [] as any;

            try {
                /* Sempre incluir o partner na pesquisa */
                where['partnerId'] = partner.id;

                const pagination = {

                } as TPagination;
                const bookcases = await lclService.bookcaseList(pagination);
                setBookcases(bookcases);
            } catch(error: any) {
                await alertContext.show(AlertMessageEnum.FAIL, title, error);
            }
        } else {
            await alertContext.show(AlertMessageEnum.FAIL, title, 'Não foi definido uma Marina para exibir as garagens !');
            navigator(`/portal`);
        }
    } // searchfilter

    useEffect(() => {
        if (!isLoading && !reloadPage) {
            searchfilter();
            setLoading(true);
        }

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            reloadPage = !reloadPage;
        }
    }, []); // useEffect

    function onClick_AddRecord(event: any) {
        event.preventDefault();

        setEditBookcase(false);
        setBookcaseId('new');
        setBookcase({} as any);
        setShowBookcase(true);
    } // onClick_AddRecord

    function onClick_EditRecord(event: any, bookcase: any) {
        event.preventDefault();

        setEditBookcase(true);
        setBookcaseId(bookcase.id);
        setBookcase(bookcase);
        setShowBookcase(true);
    } // onClick_EditRecord

    async function onClick_DeleteRecord(event: any, bookcase: any) {
        event.preventDefault();

        const isConfirmed = await deleteContext.show(title, display.message.delete_record, true);
        if (isConfirmed && isConfirmed.result) {
            try {
                await lclService.bookcaseDeleteById(bookcase.id, isConfirmed.message);
                searchfilter();
            } catch(error: any) {
                await alertContext.show(AlertMessageEnum.FAIL, title, error);
            }
        }
    } // onClick_DeleteRecord
    
    function onClick_AddKeep(event: any, drawer: any) {
        event.preventDefault();

        let vessels = [] as any;
        for (var i = 0; i < bookcases.length; i++) {
            const bookcase = bookcases[i];
            for (var j = 0; j < bookcase.shelves.length; j++) {
                const shelf = bookcase.shelves[j];
                for (var k = 0; k < shelf.drawers.length; k++) {
                    let drawer = shelf.drawers[k];
                    if (drawer.source === 'Vessel') {
                        vessels.push(toInt(drawer.identy));
                    }
                }
            }
        }
        setVessels(vessels);

        setDrawerInProcess(drawer);
        setShowFilterVessel(true);
    }

    async function onClick_RemKeep(event: any, drawerInProcess: any) {
        event.preventDefault();

        drawerInProcess['source'] = '';
        drawerInProcess['identy'] = '';
        try {
            await lclService.saveDrawer(drawerInProcess.id, drawerInProcess);

            const localBookcases = [] as any;
            for (var i = 0; i < bookcases.length; i++) {
                const bookcase = bookcases[i];
                const localShelves = [] as any;
                for (var j = 0; j < bookcase.shelves.length; j++) {
                    const shelf = bookcase.shelves[j];
                    const localDrawers = [] as any;
                    for (var k = 0; k < shelf.drawers.length; k++) {
                        var drawer = shelf.drawers[k];
                        if (drawer.id === drawerInProcess.id) {
                            drawer = drawerInProcess;
                        }
                        localDrawers[localDrawers.length] = drawer;
                    }
                    shelf.drawers = localDrawers;
                    localShelves[localShelves.length] = shelf;
                }
                bookcase.shelves = localShelves;
                localBookcases[localBookcases.length] = bookcase;
            }
            setBookcases(localBookcases);
        } catch (error: any) {
            await alertContext.show(AlertMessageEnum.FAIL, title, error);
        }
    }

    async function onClick_ConfirmFilterVessel(event: any, record: any) {
        event.preventDefault();

        try {
            const vessel = await lclService.vesselById(record.id);
            drawerInProcess['source'] = 'Vessel';
            drawerInProcess['identy'] = vessel.id;

            try {
                await lclService.saveDrawer(drawerInProcess.id, drawerInProcess);
                searchfilter();
            } catch (error: any) {
                await alertContext.show(AlertMessageEnum.FAIL, title, error);
            }
        } catch (error: any) {
            await alertContext.show(AlertMessageEnum.FAIL, title, error);
        }
    }

    async function  onClick_Keep(event: any, drawer: any) {
        event.preventDefault();

        if (drawer.source === 'Vessel') {
            const option = await confirmContext.show(title, 'Deseja remover a embarcação da gaveta ?');
            if (option)
                onClick_RemKeep(event, drawer);
        } else {
            onClick_AddKeep(event, drawer);
        }
    }

    function viewDrawerSource(drawer: any) {

        let image = null;
        let bgColorName = '';
        let colorName = 'inherit';
        let vesselName = '';
        let vesselEnrollment = '';
        
        if (drawer.source === 'Vessel') {
            const vessel = drawer.object;
            if (vessel) {
                vesselName = vessel.name;
                vesselEnrollment  = vessel.enrollment;
                bgColorName = vessel.color;

                if (bgColorName === '#000000')
                    colorName = '#FFFFFF';

                if (toInt(vessel.typeVessel) === VESSEL_IATE)
                    image = <RiShipFill size={48} />
                if (toInt(vessel.typeVessel) === VESSEL_JETSKI)
                    image =  <Image src={Jet} className="jet" style={{
                        height: 50, filter: 'invert(100%)'}}/>
                if (toInt(vessel.typeVessel) === VESSEL_LANCHA)
                    image = <Image src={Lancha} className="jet" style={{
                        height: 50 , filter: 'invert(100%)'}}/>
            }

            return (
                <>
                    <OverlayTrigger trigger={["hover","focus"]} overlay={
                        <Popover id="popover-basic">
                            <Popover.Body>
                                <div><strong>Nome: </strong>{vesselName}</div>
                                <div><strong>Matricula: </strong>{vesselEnrollment}</div>
                            </Popover.Body>
                        </Popover>
                    }>
                        <div className="card-drawer-object"
                            style={{ backgroundColor: bgColorName, color: colorName }}
                        >
                            <>{image}</>
                        </div>
                    </OverlayTrigger>
                </>
            )
        } else {
            return (
                <div className="card-drawer-object"></div>
            )
        }
    }

    function viewPage() {

        return (
            <Container fluid className="page-body keepboats">
                <Row>
                    <Col md={12} className="page-sweet">

                        <Card style={{ minHeight : 500 }}>
                            <Card.Header>
                                <div className="card-actions float-right d-flex justify-content-end">
                                    <div className="card-actions-time">
                                    </div>
                                        {authService.hasPermission(PermissionEnum.OPERATION_MOVEMENT_ADD) && 
                                                <ButtonMaterial size='small' variant="contained" onClick={(e) => onClick_AddRecord(e) } ><AddIcon />Posições</ButtonMaterial>
                                        }
                                </div>
                                <Card.Title>Posições de Guarda e Localização</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                {bookcases.map((bookcase: any, bookcaseIndex: number) => (
                                    <fieldset key={`book-${bookcaseIndex}`}>
                                        <legend>{bookcase.name}
                                            <div className="card-actions float-right">
                                                <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.toEdit}</Tooltip>}>
                                                    <span className="me-3" onClick={(e) => onClick_EditRecord(e, bookcase)}>
                                                        <FaEdit size={18} />
                                                    </span>
                                                </OverlayTrigger>
                                                <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.delete}</Tooltip>}>
                                                    <span onClick={(e) => onClick_DeleteRecord(e, bookcase)}>
                                                        <FaTrash size={18} />
                                                    </span>
                                                </OverlayTrigger>
                                            </div>
                                        </legend>
                                        <Row>
                                            <Col>
                                                <div className="card-bookcase">
                                                    {bookcase.shelves && bookcase.shelves.map((shelf: any, shelfIndex: number) => (
                                                        <div className="card-shelf" key={`shelf-${shelfIndex}`}>
                                                            <div className="card-shelf-item">
                                                                <div className="card-shelf-name">{shelf.name}</div>
                                                                <div className="card-shelf-items-2">
                                                                    {shelf.drawers && shelf.drawers.map((drawer: any, drawerIndex: number) => (
                                                                        <div className="card-drawer-3" key={`drawer-${drawerIndex}`}
                                                                            style={{backgroundColor: drawer.color}}
                                                                            onClick={(e) => onClick_Keep(e, drawer)}
                                                                        >
                                                                            <div className="card-drawer-title">{ drawer.source === 'Vessel' ? drawer.object && drawer.object.name : '' }</div>
                                                                            <div className="card-drawer-number"
                                                                                style={{color: drawer.color === '#000000' ? '#FFFFFF' : 'inherit'}}
                                                                            >{drawer.name}</div>
                                                                            <div className="card-drawer-source">
                                                                                {viewDrawerSource(drawer)}
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </Col>
                                        </Row>

                                    </fieldset>
                                ))}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    } // viewPage

    return (
        <div className="page">
            <OperationHeader title={title} description={description} />
            { viewPage() }
            <BookcaseCadastreModal 
                isEdit={isEditBookcase}

                show={showBookcase}
                bookcaseId={bookcaseId}
                record={bookcase}
                onSubmitModal={(event: any, record: any) => {
                    event.preventDefault();

                    setShowBookcase(false);
                    setEditBookcase(false);
                    searchfilter();
                }}
                onCancelModal={(event: any) => {
                    // event.preventDefault();

                    setEditBookcase(false);
                    setShowBookcase(false);
                }}
            />
            <VesselFilter 
                show={showFilterVessel}
                vesselNotInList={vessels}

                onClick_Close={(event: any) => { 
                    setShowFilterVessel(false);
                }}
                onClick_Confirm={(event: any, value: any) => { 
                    setShowFilterVessel(false);
                    onClick_ConfirmFilterVessel(event, value); 
                }}
            />

        </div>
    );

}

export default KeepBoatsOperation;