import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, useExpanded, usePagination, useSortBy } from 'react-table';
import { Table, Row, Col, FormControl, Button, Form } from 'react-bootstrap';
import { FaChevronLeft, FaChevronRight, FaStepBackward, FaStepForward } from 'react-icons/fa';

export interface IGlobalFilterProps {
    preGlobalFilteredRows: any,
    globalFilter: any,
    setGlobalFilter: any
}

const GlobalFilter: React.FC<IGlobalFilterProps> = (props: IGlobalFilterProps) => {

    const [value, setValue] = useState(props.globalFilter);
    const onChange = useAsyncDebounce((value: any) => {
        props.setGlobalFilter(value || undefined);
    }, 200);

    return (
        <Row className='d-flex'>
            <span className="ms-0 ps-0 me-1" style={{maxWidth: 'fit-content'}}>Busca rápida:</span>
            <FormControl
                value={value || ''}
                style={{maxWidth: 250}}
                onChange={(e: any) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
            />
        </Row>
    );

}

// https://github.com/TheWidlarzGroup/RT7-example/tree/102c5bbfddf9e01e556b84e81de51ef2cef3ba5e/src
// https://github.com/TanStack/table/discussions/2295
// https://thewidlarzgroup.com/react-table-7/

export interface ITableContainerProps {
    columns: any,
    data: any,
    className?: any,
    viewFilter?: boolean,
    viewPagination?: boolean,
    hidePagination?: boolean,
    autoPagination?: boolean,
    linesSize?: number,
    setSelectedRow?: any,
    renderComponent?: any
}

const TableContainer: React.FC<ITableContainerProps> = (props: ITableContainerProps) => {
  
    const columns = props.columns;
    const data = props.data
    
    const renderRowSubComponent = props.renderComponent;
    const viewFilter = typeof props.viewFilter !== 'undefined' ? props.viewFilter : false;
    const setSelectedRow = props.setSelectedRow;


    const filterTypes = useMemo(() => ({
        text: (rows: any, id: any, filterValue: any) => {
            return rows.filter((row: any) => {
                const rowValue = row.values[id];
                return rowValue !== undefined ? 
                    String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase()) : 
                    true;
            });
        }
    }), []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        visibleColumns,
        preGlobalFilteredRows,
        setGlobalFilter,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize }
    } = useTable(
        { 
            columns, 
            data,
            // defaultColumn,
            filterTypes,
            initialState: { pageIndex: 0, pageSize: props.linesSize || 10 }
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        useExpanded,
        usePagination
    );
    
    const onChange_InSelect = (event: any) => {
        setPageSize(Number(event.target.value));
    };

    const onChange_InInput = (event: any) => {
        const page = event.target.value ? Number(event.target.value) - 1 : 0;
        gotoPage(page);
    };

    const viewPagination = props.viewPagination
    const hidePagination = props.hidePagination

    useEffect(() => {
        const interval = setInterval(() => {
            if(props.autoPagination) {
                if(canNextPage) {
                    nextPage()
                   
                    } else {
                        gotoPage(0)
                    }
            }
        }, 10000);
        return () => clearInterval(interval);
      }, [canNextPage, gotoPage, nextPage, props.autoPagination]);

      useEffect(() => {
        setPageSize(props.linesSize || 10)
      }, [props.linesSize, setPageSize]);
    
    return (
        <>
            <Table responsive {...getTableProps()} className={props.className}>
                <thead>
                    { viewFilter && (
                    <tr>
                        <th colSpan={visibleColumns.length} className="ps-0 pb-3" >
                            <GlobalFilter
                                preGlobalFilteredRows={preGlobalFilteredRows}
                                globalFilter={''}
                                setGlobalFilter={setGlobalFilter}
                            />
                        </th>
                    </tr>
                    )}
                    {headerGroups.map((headerGroup, index) => (
                        <tr {...headerGroup.getHeaderGroupProps()} key={index.toString()}>
                        {headerGroup.headers.map((column: any, index: any) => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())} className='isClickable'>
                                    {column.render('Header')}
                                        <span>
                                            {column.isSorted ? column.isSortedDesc ? '🔽' : '🔼' : ''}
                                        </span>
                                </th>
                                ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                        prepareRow(row);
                        return (
                            <Fragment key={row.getRowProps().key}>
                                <tr className='isClickable' {...row.getRowProps()} onClick={ (e) => { if (typeof setSelectedRow === 'function')  setSelectedRow(e, row.original) } }>
                                    {row.cells.map((cell: any) => {
                                        return (
                                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                        );
                                    })}
                                </tr>
                                {row.isExpanded && (
                                    <tr>
                                        <td colSpan={visibleColumns.length}>
                                            {renderRowSubComponent(row)}
                                        </td>
                                    </tr>
                                )}
                            </Fragment>
                        );
                    })}

                </tbody>
            </Table>
            { viewPagination && !hidePagination && (
                <>
                    <Row style={{ maxWidth: 1000, margin: '0 auto', textAlign: 'center', justifyContent: 'center' }}>
                        <Col xs={5} md={2}>
                            <Button style={{ background: 'transparent' }}
                                onClick={() => gotoPage(0)}
                                disabled={!canPreviousPage}
                            >
                                <FaStepBackward style={{ color: 'white' }}/>
                            </Button>
                            <Button style={{ background: 'transparent' }}
                                onClick={previousPage}
                                disabled={!canPreviousPage}
                            >
                                <FaChevronLeft style={{ color: 'white' }}/>
                            </Button>
                        </Col>
                        <Col md={1} className="lineSelector">
                            <FormControl
                                type='number'
                                min={1}
                                style={{ width: 60 }}
                                max={pageOptions.length}
                                defaultValue={pageIndex + 1}
                                onChange={onChange_InInput}
                            />
                        </Col>
                        <Col md={2} className="lineSelector">
                            <Form.Select
                                value={pageSize}
                                onChange={onChange_InSelect}
                            >
                                {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                                    <option key={pageSize} value={pageSize}>
                                    {pageSize} linhas
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>
                        <Col xs={5} md={2}>
                            <Button style={{ background: 'transparent' }}
                                onClick={nextPage} 
                                disabled={!canNextPage}
                            >
                                <FaChevronRight style={{ color: 'white' }}/>
                            </Button>
                            <Button style={{ background: 'transparent' }}
                                onClick={() => gotoPage(pageCount - 1)}
                                disabled={!canNextPage}
                            >
                                <FaStepForward style={{ color: 'white' }}/>
                            </Button>
                        </Col>
                    </Row>
                    <Row style={{ justifyContent: 'center' }}>
                        <Col style={{ marginTop: 7, maxWidth: 1000, textAlign: 'center', justifyContent: 'center' }}>
                            Página{' '}
                            <strong>
                                {pageIndex + 1} de {pageOptions.length}
                            </strong>
                        </Col>
                    </Row>
                </>
            )}
      </>
    )
}

export default TableContainer;