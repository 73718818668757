import { useEffect, useState } from "react";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import { Doughnut } from 'react-chartjs-2';
import { useNavigate } from "react-router-dom";

import { usePartnerContext } from "../../contexts/partner.context";

import Jet from '../../assets/Jetski.svg';
import Lancha from '../../assets/Lancha.svg';
import { useAlertMessageContext } from "../../contexts/alert-message.context";
import lclService from "../../services/lcl.service";
import { dateToString, toFloat, toInt } from "../../utilities/auxiliary-functions";
import { VESSEL_JETSKI } from "../../utilities/constants";
import { AlertMessageEnum } from "../../utilities/types";
import DashboardHeader from "../headers/dashboard.header";
import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip
  } from 'chart.js'
import { TFilter } from "../../types/FilterType";
import { isEmpty } from "../../utilities/validators";

interface IDeliveryManagementDashboardProps {
    
}

export const DeliveryManagementDashboard: React.FC<IDeliveryManagementDashboardProps> = () => {
    
	const { partner } = usePartnerContext();
    
    const alertContext = useAlertMessageContext();
    const navigator = useNavigate();

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend,
        ArcElement
    )
  
    const title = "Gestão Delivery";
    const description = "";

    const [ countLancha, setCountLancha ]  = useState(0);
    const [ countJetski, setCountJetski ]  = useState(0);

    function getMonthName(monthNumber: any) {
        let month = monthNumber.substring(6, 4)
        if (month.split('0')[0] === '') {
            month = month.split('0')[1]
        }
        var months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
        return months.slice(month -1)[0];
    }
  
    async function searchfilter() {
        const dateStart = dateToString(new Date(), 'yyyy-mm-ddTHH:MM:ss.sssZ');
        const dateEnd = dateToString(new Date(), 'yyyy-mm-ddTHH:MM:ss.sssZ');

        if (!isEmpty(partner)) {
            try {
                const filter = {
                    attributes: [ 'typeVessel' ],
                    where: { 
                        'partners.partnerId': partner.id
                    }
                } as TFilter;
                let vesselFilter = await lclService.vesselFilter(filter); // {count: 0, rows: []} as any; 
                const vessels = vesselFilter.rows;

                let countJetski = 0;
                let countLancha = 0;
                vessels.forEach((vessel: any) => {
                    if (toInt(vessel.typeVessel) === VESSEL_JETSKI)
                        countJetski++
                    else
                        countLancha++
                });
                setCountJetski(countJetski);
                setCountLancha(countLancha);

                
                const resultMonthlyPaymentPerForms = await lclService.monthlyPaymentPerForm(partner.id, dateStart, dateEnd);
                const localPaymentPerForm = [] as any;
                for ( var mf = 0; mf < resultMonthlyPaymentPerForms.length; mf++ ) {
                    localPaymentPerForm[localPaymentPerForm.length] = {
                        name: resultMonthlyPaymentPerForms[mf].paymentForm,
                        value: toFloat(resultMonthlyPaymentPerForms[mf].sumParcel)
                    }
                }

                const resultMonthlyPaymentPerAccompanies = await lclService.monthlyPaymentPerAccompany(partner.id, dateStart, dateEnd);
                const localPaymentPerAccompany = [] as any;
                for ( var ma = 0; ma < resultMonthlyPaymentPerAccompanies.length; ma++ ) {
                    localPaymentPerAccompany[localPaymentPerAccompany.length] = {
                        name: getMonthName((resultMonthlyPaymentPerAccompanies[ma].monthy).toString()),
                        provided: toFloat(resultMonthlyPaymentPerAccompanies[ma].sumProvided),
                        realized: toFloat(resultMonthlyPaymentPerAccompanies[ma].sumRealized)
                    }
                }

            } catch(error: any) {
                await alertContext.show(AlertMessageEnum.FAIL, title, error);
            }
        } else {
            await alertContext.show(AlertMessageEnum.FAIL, title, 'Não foi definido uma Marina para exibir as finanças !');
            navigator(`/portal`);
        }
    } // searchfilter

    useEffect(() => {
        searchfilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const beverageType = {
        labels: ['Alcoólica', 'Não Alcoólica', 'Naturais'],
        datasets: [
          {
            data: [10, 85, 5],
            backgroundColor: ['gray', '#6783E6', '#16BE94'],
            borderWidth: 0
          }
        ]
    }
    const foodType = {
        labels: ['Lanches', 'Pratos', 'Doces', 'Outros'],
        datasets: [
          {
            data: [15, 35, 40, 10],
            backgroundColor: ['gray', '#6783E6', '#16BE94', 'purple'],
            borderWidth: 0
          }
        ]
    }
    const consumptionType = {
        labels: ['Carvão', 'Gelo', 'Sal', 'Outros'],
        datasets: [
          {
            data: [5, 75, 9, 11],
            backgroundColor: ['gray', '#6783E6', '#16BE94', 'purple', '#E5C710'],
            borderWidth: 0
          }
        ]
    }
    const dataType = {
        labels: ['Pix', 'Dinheiro', 'Crédito', 'Débito'],
        datasets: [
          {
            data: [15, 23, 27, 18],
            backgroundColor: ['gray', '#6783E6', '#16BE94', 'purple', '#E5C710'],
            borderWidth: 0
          }
        ]
    }
    const typeOptions = {
        responsive: true,
        cutout: 120,
        gridLines: {
          display: false
        },
        plugins: {
          legend: {
            position: 'bottom' as const,
            labels: {
              color: '#FFFFFF',
              font: {
                size: 12,
                family: "'Montserrat', sans-serif"
              }
            }
          },
          datalabels: {
            color: 'white',
            font: {
              size: 14,
              family: "'Montserrat', sans-serif"
            },
            formatter(value: any) {
              return `${value}%`
            }
          }
        }
      }



    function viewDashboard() {

        return (
            <Container fluid className="dashboard-body financial">
                <Row>
                    <Col sm="12" className="dashboard-sweet">
                        <Row>
                            <Col sm="12">
                                <div className="financial-card">
                                    <div className="card-item ship">
                                        <div className="d-flex flex-column align-items-center">
                                            <h4 className="card-label">Embarcações</h4>
                                            <div className="data">
                                                <div className="card-data-group me-5">
                                                    <div className="img">
                                                        <Image style={{
                        height: 46}} src={Jet} className="jet mt-1"/>
                                                    </div>
                                                    <div className="card-data" style={{fontSize: 22}}>{countJetski}</div>
                                                </div>
                                                <div className="card-data-group">
                                                    <div className="img">
                                                        <Image style={{
                        height: 46}} src={Lancha} className="jet mt-1"/>

                                                    </div>
                                                    <div className="card-data" style={{fontSize: 22}}>{countLancha}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card-item total">
                                        <div className="d-flex flex-column align-items-center">
                                            <h4 className="card-label">Solicitados</h4>
                                            <div className='d-flex align-items-center mt-2'>
                                                <div className='d-flex align-items-center'>
                                                    <div className="card-data" style={{fontSize: 22}}>5</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-item monthly-payment">
                                        <div className="d-flex flex-column align-items-center">
                                            <h4 className="card-label">Preparação</h4>
                                            <div className='d-flex align-items-center mt-2'>
                                                <div className='d-flex align-items-center'>
                                                    <div className="card-data" style={{fontSize: 22}}>10</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-item delay">
                                        <div className="d-flex flex-column align-items-center">
                                            <h4 className="card-label">Finalizados</h4>
                                            <div className='d-flex align-items-center mt-2'>
                                                <div className='d-flex align-items-center'>
                                                    <div className="card-data" style={{fontSize: 22}}>5</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-item opened">
                                        <div className="d-flex flex-column align-items-center">
                                            <h4 className="card-label">Fila</h4>
                                            <div className='d-flex align-items-center mt-2'>
                                                <div className='d-flex align-items-center'>
                                                    <div className="card-data" style={{fontSize: 22}}>120</div>
                                                </div>
                                            </div>
                                    </div>
                                    </div>
                                    
                                    
                                </div>
                            </Col>
                        </Row>
                        <Row>
                    <Col md={12} className="page-sweet">
                        <Card className='m-3 mt-4'>
                            <Card.Header>
                                <Card.Title>Pedidos</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col md={3}>
                                    <div className="d-flex flex-column align-items-center">
                                        <h4 className='mb-3'>Bebidas</h4>
                                        <Doughnut className='mb-3' data={beverageType} options={typeOptions}/>
                                        <div className="card-item total" style={{width: '20vw'}}>
                                            <div className="d-flex flex-column align-items-center">
                                                <h4 className="card-label">Total</h4>
                                                <div className='d-flex align-items-center mt-2'>
                                                    <div className='d-flex align-items-center'>
                                                        <div className="card-data" style={{fontSize: 22, marginBottom: 10}}>R$ 1293,10</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-item total mt-3" style={{width: '20vw'}}>
                                            <div className="d-flex flex-column align-items-center">
                                                <h4 className="card-label">Ticket Médio</h4>
                                                <div className='d-flex align-items-center mt-2'>
                                                    <div className='d-flex align-items-center'>
                                                        <div className="card-data" style={{fontSize: 22, marginBottom: 10}}>R$ 31,13</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </Col>
                                    <Col md={3}>
                                    <div className="d-flex flex-column align-items-center">
                                        <h4 className='mb-3'>Alimentação</h4>
                                        <Doughnut className='mb-3' data={foodType} options={typeOptions}/>
                                        <div className="card-item total" style={{width: '20vw'}}>
                                            <div className="d-flex flex-column align-items-center">
                                                <h4 className="card-label">Total</h4>
                                                <div className='d-flex align-items-center mt-2'>
                                                    <div className='d-flex align-items-center'>
                                                        <div className="card-data" style={{fontSize: 22, marginBottom: 10}}>R$ 2031,30</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-item total mt-3" style={{width: '20vw'}}>
                                            <div className="d-flex flex-column align-items-center">
                                                <h4 className="card-label">Ticket Médio</h4>
                                                <div className='d-flex align-items-center mt-2'>
                                                    <div className='d-flex align-items-center'>
                                                        <div className="card-data" style={{fontSize: 22, marginBottom: 10}}>R$ 14,29</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </Col>
                                    <Col md={3}>
                                    <div className="d-flex flex-column align-items-center">
                                        <h4 className='mb-3'>Uso Consumo</h4>
                                        <Doughnut className='mb-3' data={consumptionType} options={typeOptions}/>
                                        <div className="card-item total" style={{width: '20vw'}}>
                                            <div className="d-flex flex-column align-items-center">
                                                <h4 className="card-label">Total</h4>
                                                <div className='d-flex align-items-center mt-2'>
                                                    <div className='d-flex align-items-center'>
                                                        <div className="card-data" style={{fontSize: 22, marginBottom: 10}}>R$ 4291,17</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-item total mt-3" style={{width: '20vw'}}>
                                            <div className="d-flex flex-column align-items-center">
                                                <h4 className="card-label">Ticket Médio</h4>
                                                <div className='d-flex align-items-center mt-2'>
                                                    <div className='d-flex align-items-center'>
                                                        <div className="card-data" style={{fontSize: 22, marginBottom: 10}}>R$ 90,12</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </Col>
                                    <Col md={3}>
                                    <div className="d-flex flex-column align-items-center">
                                        <h4 className='mb-3'>Faturamento</h4>
                                        <Doughnut className='mb-3' data={dataType} options={typeOptions}/>
                                        <div className="card-item total" style={{width: '20vw'}}>
                                            <div className="d-flex flex-column align-items-center">
                                                <h4 className="card-label">Total</h4>
                                                <div className='d-flex align-items-center mt-2'>
                                                    <div className='d-flex align-items-center'>
                                                        <div className="card-data" style={{fontSize: 22, marginBottom: 10}}>R$ 10002,15</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-item total mt-3" style={{width: '20vw'}}>
                                            <div className="d-flex flex-column align-items-center">
                                                <h4 className="card-label">Ticket Médio</h4>
                                                <div className='d-flex align-items-center mt-2'>
                                                    <div className='d-flex align-items-center'>
                                                        <div className="card-data" style={{fontSize: 22, marginBottom: 10}}>R$ 131,90</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                    </Col>
                </Row>
            </Container>
        );
    }

    return (
        <div className="dashboard">
            <DashboardHeader title={title} description={description} />
            {viewDashboard()}
        </div>
    );
}

export default DeliveryManagementDashboard;
