import axios from "axios";
import env from "react-dotenv";
import { leftPad, toUpperCase } from "../utilities/auxiliary-functions";
import { USER_SITUATION_ACTIVED } from "../utilities/constants";
import { PermissionType, getPermission } from "../utilities/types";
import { isEmpty } from "../utilities/validators";
import authHeader from "./auth-header";

import locationService from "./location.service";
import { usePartnerContext } from "../contexts/partner.context";

class AuthService {
    
    API_SSO = env.API_SSO;
    API_LCL = env.API_LCL;

    TIME_EXPIRES = 100;

    USER = env.DISTRIBUTION === "Homologation" ? '@AppNtsHmlPortal:user' : env.DISTRIBUTION === "Production" ? '@AppNtsPortal:user' : '@AppNtsLcPortal:user';
    LOCATION = env.DISTRIBUTION === "Homologation" ? '@AppNtsHmlPortal:location' : env.DISTRIBUTION === "Production" ? '@AppNtsPortal:location' : '@AppNtsLcPortal:location';
    PARTNER = env.DISTRIBUTION === "Homologation" ? '@AppNtsHmlPortal:partner' : env.DISTRIBUTION === "Production" ? '@AppNtsPortal:partner' : '@AppNtsLcPortal:partner';

    async validToken(force: boolean = true) {
        const userAuth = this.currentUser();
        
        if (isEmpty(userAuth)) {
            window.location.href = "/";
        } else {
            if (force)
                this.setStorage(this.USER, JSON.stringify(userAuth), this.TIME_EXPIRES * 60); // default: 1h
        }
    }

    isMaster(): boolean {
        const user = this.currentUser();
        let exist = false;
        if (!isEmpty(user) && user.roles) {
            for (var r = 0; r < user.roles.length; r++) {
                let role = user.roles[r];
                if (role.name === 'MASTER') {
                    exist = true;
                    break;
                }
            }
        }
        return exist;
    }

    isGroupAssociate(): boolean {
        const user = this.currentUser();
        let exist = false;
        if (!isEmpty(user) && user.roles) {
            for (var r = 0; r < user.roles.length; r++) {
                let role = user.roles[r];
                if (String(role.name).startsWith('ASSOCIATE')) {
                    exist = true;
                    break;
                }
            }
        }
        return exist;
    }

    isAssociate(): boolean {
        const user = this.currentUser();
        let exist = false;
        if (!isEmpty(user) && user.roles) {
            for (var r = 0; r < user.roles.length; r++) {
                let role = user.roles[r];
                if (role.name === 'ASSOCIATE') {
                    exist = true;
                    break;
                }
            }
        }
        return exist;
    }

    isAssociateOperator(): boolean {
        const user = this.currentUser();
        let exist = false;
        if (!isEmpty(user) && user.roles) {
            for (var r = 0; r < user.roles.length; r++) {
                let role = user.roles[r];
                if (role.name === 'ASSOCIATE_OPERATOR') {
                    exist = true;
                    break;
                }
            }
        }
        return exist;
    }

    isProprietor(): boolean {
        const user = this.currentUser();
        let exist = false;
        if (!isEmpty(user) && user.roles) {
            for (var r = 0; r < user.roles.length; r++) {
                let role = user.roles[r];
                if (role.name === 'PROPRIETOR') {
                    exist = true;
                    break;
                }
            }
        }
        return exist;
    }

    permissionIn(name: string): boolean {
        const itemName = getPermission(toUpperCase(name));
        return this.hasPermission(itemName.id);
    } 

    hasPermission(menu: number): boolean {
        const itemPermission = getPermission(menu);

        let position = 0;
        const permissions = Object.keys(PermissionType);

        PermissionType.forEach((element: any, index: number) => {
            if (element === itemPermission)
                position = index;
        });

        const user = this.currentUser();
        if (user) {
            const role = user.roles[0];
            if (role) {
                let binary = leftPad('0', permissions.length, '0');

                let hexa = role.permission;
                let binaries = '';
                while (hexa.length > 0) {
                    let trecho = hexa.substring(0, 1);
                    let bbin = (parseInt(trecho, 16).toString(2)).padStart(4, '0');
                    binaries += bbin;
                    hexa = hexa.substring(1, hexa.length);
                }
                binary = leftPad(binaries, 128, '0').substring(128 - permissions.length, 128);

                const bin = binary.substring(position, position + 1);
                const hasPermission = bin === '1';
                return hasPermission;
            } else 
                return false;
        } else {
            window.location.href = `/portal`;
            return false;
        }
    }

    async login(username: string, password: string) {
        let ip = "";
        try {
            ip = await locationService.getIp() as any;
        } catch(error: any) {
            console.log(`Exception in 'AuthService' from method get 'loginIp'`);
            if (error.code === "ERR_NETWORK") {
                throw new Error(`Ops !!! Não foi possivel conectar a URL solicitada.`);
            } else {
                const responseMessage = (
                    error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                    error.message ||
                    error.toString();
                throw new Error(responseMessage);
            }
        }

        try {
            const response = await axios.post(this.API_SSO + '/signin', { username, password, ip });
            let data = response.data; 
            if (data.success) {
                let user = response.data.object; 
                if (user.authorization && (user.situation === USER_SITUATION_ACTIVED)) {
                    this.setStorage(this.USER, JSON.stringify(user), this.TIME_EXPIRES * 60); // default: 1h
                    localStorage.setItem('avatar', JSON.stringify(user.avatar))
                    try {
                        let permition = this.getStorage(this.PARTNER);
                        if (isEmpty(permition)) {
                            permition = await this.permissionInPartner();
                            this.setStorage(this.PARTNER, JSON.stringify(permition));
                        }
                    } catch (error: any) {
                        console.log(`Error in ${this.PARTNER}: ${error.message}`);
                    }
                    try {
                        let location = this.getStorage(this.LOCATION);
                        if (isEmpty(location)) {
                            location = await locationService.now();
                            this.setStorage(this.LOCATION, JSON.stringify(location));
                        }
                    } catch (error: any) {
                        console.log(`Error in ${this.LOCATION}: ${error.message}`);
                    }
                }
                return user;
            } else {
                throw new Error(data.message);
            }
        } catch (error: any) {
            console.log(`Exception in 'AuthService' from method get 'login'`);
            if (error.code === "ERR_NETWORK") {
                throw new Error(`Ops !!! Não foi possivel conectar a URL solicitada.`);
            } else {
                const responseMessage = (
                    error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                    error.message ||
                    error.toString();
                throw new Error(responseMessage);
            }
        }
    }

    async approveEntryInApp(email: string, password: string) {

        try {
            const response = await axios.post(this.API_SSO + '/approve_entry_in_app', { email, password });
            let data = response.data; 
            if (data.success) {
                let user = response.data.object; 
                return user;
            } else {
                throw new Error(data.message);
            }
        } catch(error: any) {
            console.log(`Exception in 'AuthService' from method get 'approveEntryInApp'`);
            if (error.code === "ERR_NETWORK") {
                throw new Error(`Ops !!! Não foi possivel conectar a URL solicitada.`);
            } else {
                const responseMessage = (
                    error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                    error.message ||
                    error.toString();
                throw new Error(responseMessage);
            }
        }
    }

    async permissionInPartner() {
        const userJson = this.getStorage(this.USER);
        const user = JSON.parse(userJson);
        const url = this.API_LCL + `/partner/permission_user/${user.id}`;

        try {
            const response = await axios.get(url, { headers: authHeader() });

            let data = response.data; 
            if (data.success) {
                let partner = response.data.object; 
                return partner;
            } else {
                throw new Error(data.message);
            }
        } catch(error: any) {
            console.log(`Exception in 'AuthService' from method get 'permissionInPartner'`);
            if (error.code === "ERR_NETWORK") {
                throw new Error(`Ops !!! Não foi possivel conectar a URL solicitada.`);
            } else {
                const responseMessage = (
                    error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                    error.message ||
                    error.toString();
                throw new Error(responseMessage);
            }
        }
    }

    logout() {
        this.removeStorage(this.USER);
        this.removeStorage(this.LOCATION);
        this.removeStorage(this.PARTNER);
        localStorage.removeItem('avatar')
    }

    currentUser() {
        let local = this.getStorage(this.USER);
        if (local)
            return JSON.parse(local);
        return {};
    }
  
    currentPartner() {
        let local = this.getStorage(this.PARTNER);
        if (local)
            return JSON.parse(local);
        return {};
    }

    changePartner(partner: any) {
        try {
            this.setStorage(this.PARTNER, JSON.stringify(partner));
        } catch (error: any) {
            console.log(`Error in ${this.PARTNER}: ${error.message}`);
        }
    }

    currentLocation() {
        let local = this.getStorage(this.LOCATION);
        if (local)
            return JSON.parse(local);
        return {};
    }

    // https://stackoverflow.com/questions/2326943/when-do-items-in-html5-local-storage-expire
    /*  removeStorage: removes a key from localStorage and its sibling expiracy key
        params:
            key <string>     : localStorage key to remove
        returns:
            <boolean> : telling if operation succeeded
    */
    removeStorage(key: string): boolean {
        try {
            localStorage.removeItem(key);
            localStorage.removeItem(key + '_expires');
        } catch(e) {
            console.log('removeStorage: Error removing key ['+ key + '] from localStorage: ' + JSON.stringify(e) );
            return false;
        }
        return true;
    }

    /*  getStorage: retrieves a key from localStorage previously set with setStorage().
        params:
            key <string> : localStorage key
        returns:
            <string> : value of localStorage key
            null : in case of expired key or failure
    */
    getStorage(key: string): any {
        if (key === this.USER) {
            let now = Date.now();  //epoch time, lets deal only with integer
            // set expiration for storage
            let expires = localStorage.getItem(`${key}_expires`);
            if (expires === undefined || expires === null) { 
                expires = '0'; 
            }

            if (Number(expires) < now) {// Expired
                this.removeStorage(this.USER);
                this.removeStorage(this.LOCATION);
                this.removeStorage(this.PARTNER);
                return null;
            } else {
                try {
                    let value = localStorage.getItem(key);
                    return value;
                } catch(error: any) {
                    console.log(`setStorage.expires: Error setting key [${key}] in localStorage: ${JSON.stringify(error)}`);
                    return null;
                }
            }
        } else {
            try {
                let value = localStorage.getItem(key);
                return value;
            } catch(error: any) {
                console.log(`setStorage: Error setting key [${key}] in localStorage: ${JSON.stringify(error)}`);
                return null;
            }
        }
    }

    /*  setStorage: writes a key into localStorage setting a expire time
        params:
            key <string>     : localStorage key
            value <string>   : localStorage value
            expires <number> : (Opcional) number of seconds from now to expire the key
        returns:
            <boolean> : telling if operation succeeded
    */
    setStorage(key: string, value: string, expires?: number): boolean {

        if (expires === undefined || expires === null) {
            localStorage.setItem(key, value);
        } else {
            expires = Math.abs(expires); //make sure it's positive
            let now = Date.now();  //millisecs since epoch time, lets deal only with integer
            let schedule = now + expires * 1000; 
            try {
                localStorage.setItem(key, value);
                localStorage.setItem(`${key}_expires`, String(schedule));
            } catch(error: any) {
                console.log(`setStorage: Error setting key [${key}] in localStorage: ${JSON.stringify(error)}`);
                return false;
            }
        }

        return true;
    }
        
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AuthService();