import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CadastreHeader from "../headers/cadastre.header";
import MemberCadastreForm from "./forms/member.cadastre.form";
import MemberCadastreList from "./lists/member.cadastre.list";
import { useTranslateContext } from "../../contexts/translate.context";

export interface IMemberCadastreProps {
    viewOnly?: boolean
}

export const MemberCadastre: React.FC<IMemberCadastreProps> = (props: IMemberCadastreProps) => {

    const { memberId } = useParams();
    const { display } = useTranslateContext();

    const title = display.title.member;
    const description = '';

    let reloadPage = false;
    const [ isLoading, setLoading ] = useState(false);
    const [ recordSelected, setRecordSelected ] = useState({ param : memberId || '', selected : ((memberId === 'new') || (memberId === '0') || (!Number.isNaN(Number.parseInt(memberId ||'')))) });

    function setSelected(isRecord: boolean, recordId: string) {
        setRecordSelected({ param : recordId, selected : isRecord });
    }

    async function initialize() {
    }
    
    useEffect(() => {
        if (!isLoading && !reloadPage) {
            initialize();
            setLoading(true);
        }

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            reloadPage = !reloadPage;
        }
    }, []);
    
    return (
        <div className="page">
            <CadastreHeader title={title} description={description} />
            {(recordSelected.selected) ? (
                <MemberCadastreForm 
                    title={title}
                    memberId={recordSelected.param}
                    setRecord={(event: any) => { 
                        event.preventDefault();
                        setSelected(false, '0'); 
                    }}
                    viewOnly={props.viewOnly}
                />
            ) : (
                <MemberCadastreList
                    title={title}
                    setRecord={(event: any, recordId: string) => { 
                        event.preventDefault();
                        setSelected(true, recordId); 
                    }}
                    viewOnly={props.viewOnly}
                />
            )}
        </div>
    )
}

export default MemberCadastre;
