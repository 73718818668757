import { PropsWithChildren, useEffect, useState } from "react";

import { Box, Tab, Tabs, Typography } from "@mui/material";
import { Container } from "react-bootstrap";
import { v4 as uuid } from "uuid";
import Jet from "../../assets/Jetski.svg";
import Lancha from "../../assets/Lancha.svg";
import Loader from "../../components/loader/loader";
import lclService from "../../services/lcl.service";
import { dateToString, toDate, toInt } from "../../utilities/auxiliary-functions";
import { removeFormatDate } from "../../utilities/masks";
import OperationHeader from "../headers/operation.header";
import styles from "./HourlyOccupation.module.scss";
import authService from "../../services/auth.service";
import cduService from "../../services/cdu.service";

interface ITabPanelProps {
    index: number,
    value: number,
    props?: any
}

export const TabPanel:React.FC<PropsWithChildren<ITabPanelProps>> = ({ index, value, props, children }) => {

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...props}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

interface IHourlyOccupationProps {
    
}

export const HourlyOccupation: React.FC<IHourlyOccupationProps> = () => {

    const [ value, setValue ] = useState(0);
    const [ loading, setLoading ] = useState(false);
    const [ disabledHours, setDisabledHours ] = useState([] as any);
    const [ records, setRecords ] = useState([] as any);
    const [ intervalOperation, setIntervalOperation ] = useState(5);

    const [ morning, setMorning ] = useState(0);
    const [ afternoon, setAfternoon ] = useState(0);
    const [ night, setNight ] = useState(0);

    const today = new Date();
    const selectedDepartureHour = "";

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const parseHour = (hour: string): string => {
        if (hour.length === 1) {
        return `0${hour}`;
        }
        return hour;
    };

    const handleHour = (hour: string) => {
        return hour;
    };

    const reserveHour = (hour: string, index: number) => {
        const reserve = parseHour((intervalOperation * index).toString());
        const record = records.filter((movement: any) => dateToString(movement.departure, 'HH:MM') === `${hour}:${reserve}`)[0];

        return (
        <>
        <span>
            {` ${hour}:${reserve}`}
        </span>
        <div className="d-flex justify-content-center mt-1">
            <img
            alt={record.vesselType === '33' ? 'JetSki' : 'Lancha'}
            src={record.vesselType === '33' ? Jet : Lancha}
            className={record.vesselType === '33' ? 'jet' : 'lancha'}
            style={{
                height: 28,
            }}
            />
            <small className="ms-1">{`${record.vesselSize}'`}</small>
        </div>
        <span
            className={styles.vesselColor}
            style={{ backgroundColor: record.vesselColor }}
        />
        </>
        );
    }

    useEffect(() => {
        setLoading(true);
        const dateDefault = removeFormatDate(dateToString(today, "dd/mm/yyyy"));
        const partner = authService.currentPartner();
        
        const attributes_parameter = [] as any;
        const where_parameter = {
            companyId: partner.company.id,
            code: '101'
        } as any;
        const order_parameter = [['group', 'ASC'], ['code', 'ASC']] as any;
        cduService
            .parameterList(attributes_parameter, where_parameter, order_parameter)
            .then((result: any) => {
                if (result && result.length > 0) {
                    const parameter = result[0];
                    setIntervalOperation(toInt(parameter.content));
                }
            });

        lclService
            .getAppointmentByDate(dateDefault, partner.id)
            .then((result: any) => {
                setRecords(result);
                if (result) {
                    const localMorning = result.filter((movement: any) => {
                        const nDate = toDate(movement.departure, 'yyyy-mm-ddTHH:MM:ss.sssZ');
                        return nDate.getHours() < 12;
                    });
                    setMorning(Math.round((localMorning.length / ((60 / intervalOperation) * 5)) * 100));

                    const localAfternoon = result.filter((movement: any) => {
                        const nDate = toDate(movement.departure, 'yyyy-mm-ddTHH:MM:ss.sssZ');
                        return nDate.getHours() >= 12 && nDate.getHours() < 18;
                    });
                    setAfternoon(Math.round((localAfternoon.length / ((60 / intervalOperation) * 5)) * 100));

                    const localNight = result.filter((movement: any) => {
                        const nDate = toDate(movement.departure, 'yyyy-mm-ddTHH:MM:ss.sssZ');
                        return nDate.getHours() >= 18;
                    });
                    setNight(Math.round((localNight.length / ((60 / intervalOperation) * 5)) * 100));

                    const local = result.map((movement: any) => dateToString(movement.departure, 'HH:MM'));
                    setDisabledHours(local);
                    setLoading(false);
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function viewPage() {
        return (
        <Container fluid className={`page-body movements`}>
            {loading ? (
            <Loader />
            ) : (
            <div className={styles.hourlyOccupation}>
                <Box
                sx={{
                    width: "100%",
                    background: "rgba(255, 255, 255, 0.055)",
                    borderRadius: "4px",
                }}
                >
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="fullWidth"
                    >
                    <Tab
                        style={{ fontSize: 18 }}
                        label={`Manhã (${morning}%)`}
                        {...a11yProps(0)}
                    />
                    <Tab
                        style={{ fontSize: 18 }}
                        label={`Tarde (${afternoon}%)`}
                        {...a11yProps(1)}
                    />
                    <Tab
                        style={{ fontSize: 18 }}
                        label={`Noite (${night}%)`}
                        {...a11yProps(2)}
                    />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <span className={styles.hourWrapper}>
                    <span className="d-flex w-100">
                        <span className="d-flex justify-content-between w-100">
                        {Array(60 / intervalOperation)
                            .fill(0)
                            ?.map(
                            (
                                option: any,
                                index: {
                                toString: () => React.Key | null | undefined;
                                }
                            ) => (
                                <span
                                role="presentation"
                                onClick={() =>
                                    handleHour(
                                    `08:${parseHour(
                                        (
                                        intervalOperation * Number(index)
                                        ).toString()
                                    )}`
                                    )
                                }
                                key={uuid()}
                                className={`${
                                    disabledHours.includes(
                                    `08:${parseHour(
                                        (
                                        intervalOperation * Number(index)
                                        ).toString()
                                    )}`
                                    )
                                    ? styles.unavailable
                                    : ""
                                } ${styles.hour} ${
                                    selectedDepartureHour ===
                                    `08:${parseHour(
                                    (intervalOperation * Number(index)).toString()
                                    )}`
                                    ? styles.selected
                                    : ""
                                }`}
                                >
                                {disabledHours.includes(
                                    `08:${parseHour(
                                    (intervalOperation * Number(index)).toString()
                                    )}`
                                ) ? (
                                    <div
                                    className={`d-flex flex-column align-items-center justify-content-center ${styles.vesselWrapper}`}
                                    >
                                    <span>
                                        {" "}
                                        08:
                                        {parseHour(
                                        (
                                            intervalOperation * Number(index)
                                        ).toString()
                                        )}
                                    </span>
                                    <div className="d-flex justify-content-center mt-1">
                                        <img
                                        alt="JetSki"
                                        src={Jet}
                                        className="jet"
                                        style={{
                                            height: 32,
                                        }}
                                        />
                                        <small className="ms-1">{`10'`}</small>
                                    </div>
                                    <span
                                        className={styles.vesselColor}
                                        style={{ backgroundColor: "yellow" }}
                                    />
                                    </div>
                                ) : (
                                    <span className="d-flex flex-column align-items-center">
                                    08:
                                    {parseHour(
                                        (
                                        intervalOperation * Number(index)
                                        ).toString()
                                    )}
                                    <span className={styles.availableHour}>
                                        Livre
                                    </span>
                                    </span>
                                )}
                                </span>
                            )
                            )}
                        </span>
                    </span>
                    <span className={`d-flex w-100 ${styles.grayLine}`}>
                        <span className="d-flex justify-content-between w-100">
                        {Array(60 / intervalOperation)
                            .fill(0)
                            ?.map(
                            (
                                option: any,
                                index: {
                                toString: () => React.Key | null | undefined;
                                }
                            ) => (
                                <span
                                role="presentation"
                                onClick={() =>
                                    handleHour(
                                    `09:${parseHour(
                                        (
                                        intervalOperation * Number(index)
                                        ).toString()
                                    )}`
                                    )
                                }
                                key={uuid()}
                                className={`${styles.grayLine} ${
                                    selectedDepartureHour ===
                                    `09:${parseHour(
                                    (intervalOperation * Number(index)).toString()
                                    )}`
                                    ? styles.selected
                                    : ""
                                } ${
                                    disabledHours.includes(
                                    `09:${parseHour(
                                        (
                                        intervalOperation * Number(index)
                                        ).toString()
                                    )}`
                                    )
                                    ? styles.unavailable
                                    : ""
                                } ${styles.hour}`}
                                >
                                {disabledHours.includes(
                                    `09:${parseHour(
                                    (intervalOperation * Number(index)).toString()
                                    )}`
                                ) ? (
                                    <div
                                    className={`d-flex flex-column align-items-center justify-content-center ${styles.vesselWrapper}`}
                                    >
                                    {reserveHour('09', Number(index))}
                                    {/*
                                    <span>
                                        {" "}
                                        09:
                                        {parseHour(
                                        (
                                            intervalOperation * Number(index)
                                        ).toString()
                                        )}
                                    </span>
                                    <div className="d-flex justify-content-center mt-1">
                                        <img
                                        alt="JetSki"
                                        src={Jet}
                                        className="jet"
                                        style={{
                                            height: 28,
                                        }}
                                        />
                                        <small className="ms-1">{`10'`}</small>
                                    </div>
                                    <span
                                        className={styles.vesselColor}
                                        style={{ backgroundColor: "yellow" }}
                                    />
                                    */}
                                    </div>
                                ) : (
                                    <span className="d-flex flex-column align-items-center">
                                    09:
                                    {parseHour(
                                        (
                                        intervalOperation * Number(index)
                                        ).toString()
                                    )}
                                    <span className={styles.availableHour}>
                                        Livre
                                    </span>
                                    </span>
                                )}
                                </span>
                            )
                            )}
                        </span>
                    </span>
                    <span className="d-flex w-100">
                        <span className="d-flex justify-content-between w-100">
                        {Array(60 / intervalOperation)
                            .fill(0)
                            ?.map(
                            (
                                option: any,
                                index: {
                                toString: () => React.Key | null | undefined;
                                }
                            ) => (
                                <span
                                role="presentation"
                                onClick={() =>
                                    handleHour(
                                    `10:${parseHour(
                                        (
                                        intervalOperation * Number(index)
                                        ).toString()
                                    )}`
                                    )
                                }
                                key={uuid()}
                                className={`${
                                    disabledHours.includes(
                                    `10:${parseHour(
                                        (
                                        intervalOperation * Number(index)
                                        ).toString()
                                    )}`
                                    )
                                    ? styles.unavailable
                                    : ""
                                } ${styles.hour} ${
                                    selectedDepartureHour ===
                                    `10:${parseHour(
                                    (intervalOperation * Number(index)).toString()
                                    )}`
                                    ? styles.selected
                                    : ""
                                }`}
                                >
                                {disabledHours.includes(
                                    `10:${parseHour(
                                    (intervalOperation * Number(index)).toString()
                                    )}`
                                ) ? (
                                    <div
                                    className={`d-flex flex-column align-items-center justify-content-center ${styles.vesselWrapper}`}
                                    >
                                    <span>
                                        {" "}
                                        10:
                                        {parseHour(
                                        (
                                            intervalOperation * Number(index)
                                        ).toString()
                                        )}
                                    </span>
                                    <div className="d-flex justify-content-center mt-1">
                                        <img
                                        alt="JetSki"
                                        src={Jet}
                                        className="jet"
                                        style={{
                                            height: 28,
                                        }}
                                        />
                                        <small className="ms-1">{`10'`}</small>
                                    </div>
                                    <span
                                        className={styles.vesselColor}
                                        style={{ backgroundColor: "yellow" }}
                                    />
                                    </div>
                                ) : (
                                    <span className="d-flex flex-column align-items-center">
                                    10:
                                    {parseHour(
                                        (
                                        intervalOperation * Number(index)
                                        ).toString()
                                    )}
                                    <span className={styles.availableHour}>
                                        Livre
                                    </span>
                                    </span>
                                )}
                                </span>
                            )
                            )}
                        </span>
                    </span>
                    <span className={`d-flex w-100 ${styles.grayLine}`}>
                        <span className="d-flex justify-content-between w-100">
                        {Array(60 / intervalOperation)
                            .fill(0)
                            ?.map(
                            (
                                option: any,
                                index: {
                                toString: () => React.Key | null | undefined;
                                }
                            ) => (
                                <span
                                role="presentation"
                                onClick={() =>
                                    handleHour(
                                    `11:${parseHour(
                                        (
                                        intervalOperation * Number(index)
                                        ).toString()
                                    )}`
                                    )
                                }
                                key={uuid()}
                                className={`${styles.grayLine} ${
                                    disabledHours.includes(
                                    `11:${parseHour(
                                        (
                                        intervalOperation * Number(index)
                                        ).toString()
                                    )}`
                                    )
                                    ? styles.unavailable
                                    : ""
                                } ${styles.hour} ${
                                    selectedDepartureHour ===
                                    `11:${parseHour(
                                    (intervalOperation * Number(index)).toString()
                                    )}`
                                    ? styles.selected
                                    : ""
                                }`}
                                >
                                {disabledHours.includes(
                                    `11:${parseHour(
                                    (intervalOperation * Number(index)).toString()
                                    )}`
                                ) ? (
                                    <div
                                    className={`d-flex flex-column align-items-center justify-content-center ${styles.vesselWrapper}`}
                                    >
                                    <span>
                                        {" "}
                                        11:
                                        {parseHour(
                                        (
                                            intervalOperation * Number(index)
                                        ).toString()
                                        )}
                                    </span>
                                    <div className="d-flex justify-content-center mt-1">
                                        <img
                                        alt="JetSki"
                                        src={Jet}
                                        className="jet"
                                        style={{
                                            height: 28,
                                        }}
                                        />
                                        <small className="ms-1">{`10'`}</small>
                                    </div>
                                    <span
                                        className={styles.vesselColor}
                                        style={{ backgroundColor: "yellow" }}
                                    />
                                    </div>
                                ) : (
                                    <span className="d-flex flex-column align-items-center">
                                    11:
                                    {parseHour(
                                        (
                                        intervalOperation * Number(index)
                                        ).toString()
                                    )}
                                    <span className={styles.availableHour}>
                                        Livre
                                    </span>
                                    </span>
                                )}
                                </span>
                            )
                            )}
                        </span>
                    </span>
                    <span className="d-flex w-100">
                        <span className="d-flex justify-content-between w-100">
                        {Array(60 / intervalOperation)
                            .fill(0)
                            ?.map(
                            (
                                option: any,
                                index: {
                                toString: () => React.Key | null | undefined;
                                }
                            ) => (
                                <span
                                role="presentation"
                                onClick={() =>
                                    handleHour(
                                    `12:${parseHour(
                                        (
                                        intervalOperation * Number(index)
                                        ).toString()
                                    )}`
                                    )
                                }
                                key={uuid()}
                                className={`${
                                    disabledHours.includes(
                                    `12:${parseHour(
                                        (
                                        intervalOperation * Number(index)
                                        ).toString()
                                    )}`
                                    )
                                    ? styles.unavailable
                                    : ""
                                } ${styles.hour} ${
                                    selectedDepartureHour ===
                                    `12:${parseHour(
                                    (intervalOperation * Number(index)).toString()
                                    )}`
                                    ? styles.selected
                                    : ""
                                }`}
                                >
                                {disabledHours.includes(
                                    `12:${parseHour(
                                    (intervalOperation * Number(index)).toString()
                                    )}`
                                ) ? (
                                    <div
                                    className={`d-flex flex-column align-items-center justify-content-center ${styles.vesselWrapper}`}
                                    >
                                    <span>
                                        {" "}
                                        12:
                                        {parseHour(
                                        (
                                            intervalOperation * Number(index)
                                        ).toString()
                                        )}
                                    </span>
                                    <div className="d-flex justify-content-center mt-1">
                                        <img
                                        alt="JetSki"
                                        src={Jet}
                                        className="jet"
                                        style={{
                                            height: 28,
                                        }}
                                        />
                                        <small className="ms-1">{`10'`}</small>
                                    </div>
                                    <span
                                        className={styles.vesselColor}
                                        style={{ backgroundColor: "yellow" }}
                                    />
                                    </div>
                                ) : (
                                    <span className="d-flex flex-column align-items-center">
                                    12:
                                    {parseHour(
                                        (
                                        intervalOperation * Number(index)
                                        ).toString()
                                    )}
                                    <span className={styles.availableHour}>
                                        Livre
                                    </span>
                                    </span>
                                )}
                                </span>
                            )
                            )}
                        </span>
                    </span>
                    </span>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <span className={styles.hourWrapper}>
                    <span className="d-flex w-100">
                        <span className="d-flex justify-content-between w-100">
                        {Array(60 / intervalOperation)
                            .fill(0)
                            ?.map(
                            (
                                option: any,
                                index: {
                                toString: () => React.Key | null | undefined;
                                }
                            ) => (
                                <span
                                role="presentation"
                                onClick={() =>
                                    handleHour(
                                    `13:${parseHour(
                                        (
                                        intervalOperation * Number(index)
                                        ).toString()
                                    )}`
                                    )
                                }
                                key={uuid()}
                                className={`${
                                    disabledHours.includes(
                                    `13:${parseHour(
                                        (
                                        intervalOperation * Number(index)
                                        ).toString()
                                    )}`
                                    )
                                    ? styles.unavailable
                                    : ""
                                } ${styles.hour} ${
                                    selectedDepartureHour ===
                                    `13:${parseHour(
                                    (intervalOperation * Number(index)).toString()
                                    )}`
                                    ? styles.selected
                                    : ""
                                }`}
                                >
                                {disabledHours.includes(
                                    `13:${parseHour(
                                    (intervalOperation * Number(index)).toString()
                                    )}`
                                ) ? (
                                    <div
                                    className={`d-flex flex-column align-items-center justify-content-center ${styles.vesselWrapper}`}
                                    >
                                    <span>
                                        {" "}
                                        13:
                                        {parseHour(
                                        (
                                            intervalOperation * Number(index)
                                        ).toString()
                                        )}
                                    </span>
                                    <div className="d-flex justify-content-center mt-1">
                                        <img
                                        alt="JetSki"
                                        src={Jet}
                                        className="jet"
                                        style={{
                                            height: 28,
                                        }}
                                        />
                                        <small className="ms-1">{`10'`}</small>
                                    </div>
                                    <span
                                        className={styles.vesselColor}
                                        style={{ backgroundColor: "yellow" }}
                                    />
                                    </div>
                                ) : (
                                    <span className="d-flex flex-column align-items-center">
                                    13:
                                    {parseHour(
                                        (
                                        intervalOperation * Number(index)
                                        ).toString()
                                    )}
                                    <span className={styles.availableHour}>
                                        Livre
                                    </span>
                                    </span>
                                )}
                                </span>
                            )
                            )}
                        </span>
                    </span>
                    <span className={`d-flex w-100 ${styles.grayLine}`}>
                        <span className="d-flex justify-content-between w-100">
                        {Array(60 / intervalOperation)
                            .fill(0)
                            ?.map(
                            (
                                option: any,
                                index: {
                                toString: () => React.Key | null | undefined;
                                }
                            ) => (
                                <span
                                role="presentation"
                                onClick={() =>
                                    handleHour(
                                    `14:${parseHour(
                                        (
                                        intervalOperation * Number(index)
                                        ).toString()
                                    )}`
                                    )
                                }
                                key={uuid()}
                                className={`${styles.grayLine} ${
                                    selectedDepartureHour ===
                                    `14:${parseHour(
                                    (intervalOperation * Number(index)).toString()
                                    )}`
                                    ? styles.selected
                                    : ""
                                } ${
                                    disabledHours.includes(
                                    `14:${parseHour(
                                        (
                                        intervalOperation * Number(index)
                                        ).toString()
                                    )}`
                                    )
                                    ? styles.unavailable
                                    : ""
                                } ${styles.hour}`}
                                >
                                {disabledHours.includes(
                                    `14:${parseHour(
                                    (intervalOperation * Number(index)).toString()
                                    )}`
                                ) ? (
                                    <div
                                    className={`d-flex flex-column align-items-center justify-content-center ${styles.vesselWrapper}`}
                                    >
                                    <span>
                                        {" "}
                                        14:
                                        {parseHour(
                                        (
                                            intervalOperation * Number(index)
                                        ).toString()
                                        )}
                                    </span>
                                    <div className="d-flex justify-content-center mt-1">
                                        <img
                                        alt="JetSki"
                                        src={Jet}
                                        className="jet"
                                        style={{
                                            height: 28,
                                        }}
                                        />
                                        <small className="ms-1">{`10'`}</small>
                                    </div>
                                    <span
                                        className={styles.vesselColor}
                                        style={{ backgroundColor: "yellow" }}
                                    />
                                    </div>
                                ) : (
                                    <span className="d-flex flex-column align-items-center">
                                    14:
                                    {parseHour(
                                        (
                                        intervalOperation * Number(index)
                                        ).toString()
                                    )}
                                    <span className={styles.availableHour}>
                                        Livre
                                    </span>
                                    </span>
                                )}
                                </span>
                            )
                            )}
                        </span>
                    </span>
                    <span className="d-flex w-100">
                        <span className="d-flex justify-content-between w-100">
                        {Array(60 / intervalOperation)
                            .fill(0)
                            ?.map(
                            (
                                option: any,
                                index: {
                                toString: () => React.Key | null | undefined;
                                }
                            ) => (
                                <span
                                role="presentation"
                                onClick={() =>
                                    handleHour(
                                    `15:${parseHour(
                                        (
                                        intervalOperation * Number(index)
                                        ).toString()
                                    )}`
                                    )
                                }
                                key={uuid()}
                                className={`${
                                    disabledHours.includes(
                                    `15:${parseHour(
                                        (
                                        intervalOperation * Number(index)
                                        ).toString()
                                    )}`
                                    )
                                    ? styles.unavailable
                                    : ""
                                } ${styles.hour} ${
                                    selectedDepartureHour ===
                                    `15:${parseHour(
                                    (intervalOperation * Number(index)).toString()
                                    )}`
                                    ? styles.selected
                                    : ""
                                }`}
                                >
                                {disabledHours.includes(
                                    `15:${parseHour(
                                    (intervalOperation * Number(index)).toString()
                                    )}`
                                ) ? (
                                    <div
                                    className={`d-flex flex-column align-items-center justify-content-center ${styles.vesselWrapper}`}
                                    >
                                    <span>
                                        {" "}
                                        15:
                                        {parseHour(
                                        (
                                            intervalOperation * Number(index)
                                        ).toString()
                                        )}
                                    </span>
                                    <div className="d-flex justify-content-center mt-1">
                                        <img
                                        alt="JetSki"
                                        src={Jet}
                                        className="jet"
                                        style={{
                                            height: 28,
                                        }}
                                        />
                                        <small className="ms-1">{`10'`}</small>
                                    </div>
                                    <span
                                        className={styles.vesselColor}
                                        style={{ backgroundColor: "yellow" }}
                                    />
                                    </div>
                                ) : (
                                    <span className="d-flex flex-column align-items-center">
                                    15:
                                    {parseHour(
                                        (
                                        intervalOperation * Number(index)
                                        ).toString()
                                    )}
                                    <span className={styles.availableHour}>
                                        Livre
                                    </span>
                                    </span>
                                )}
                                </span>
                            )
                            )}
                        </span>
                    </span>
                    <span className={`d-flex w-100 ${styles.grayLine}`}>
                        <span className="d-flex justify-content-between w-100">
                        {Array(60 / intervalOperation)
                            .fill(0)
                            ?.map(
                            (
                                option: any,
                                index: {
                                toString: () => React.Key | null | undefined;
                                }
                            ) => (
                                <span
                                role="presentation"
                                onClick={() =>
                                    handleHour(
                                    `16:${parseHour(
                                        (
                                        intervalOperation * Number(index)
                                        ).toString()
                                    )}`
                                    )
                                }
                                key={uuid()}
                                className={`${styles.grayLine} ${
                                    disabledHours.includes(
                                    `16:${parseHour(
                                        (
                                        intervalOperation * Number(index)
                                        ).toString()
                                    )}`
                                    )
                                    ? styles.unavailable
                                    : ""
                                } ${styles.hour} ${
                                    selectedDepartureHour ===
                                    `16:${parseHour(
                                    (intervalOperation * Number(index)).toString()
                                    )}`
                                    ? styles.selected
                                    : ""
                                }`}
                                >
                                {disabledHours.includes(
                                    `16:${parseHour(
                                    (intervalOperation * Number(index)).toString()
                                    )}`
                                ) ? (
                                    <div
                                    className={`d-flex flex-column align-items-center justify-content-center ${styles.vesselWrapper}`}
                                    >
                                    <span>
                                        {" "}
                                        16:
                                        {parseHour(
                                        (
                                            intervalOperation * Number(index)
                                        ).toString()
                                        )}
                                    </span>
                                    <div className="d-flex justify-content-center mt-1">
                                        <img
                                        alt="JetSki"
                                        src={Jet}
                                        className="jet"
                                        style={{
                                            height: 28,
                                        }}
                                        />
                                        <small className="ms-1">{`10'`}</small>
                                    </div>
                                    <span
                                        className={styles.vesselColor}
                                        style={{ backgroundColor: "yellow" }}
                                    />
                                    </div>
                                ) : (
                                    <span className="d-flex flex-column align-items-center">
                                    16:
                                    {parseHour(
                                        (
                                        intervalOperation * Number(index)
                                        ).toString()
                                    )}
                                    <span className={styles.availableHour}>
                                        Livre
                                    </span>
                                    </span>
                                )}
                                </span>
                            )
                            )}
                        </span>
                    </span>
                    <span className="d-flex w-100">
                        <span className="d-flex justify-content-between w-100">
                        {Array(60 / intervalOperation)
                            .fill(0)
                            ?.map(
                            (
                                option: any,
                                index: {
                                toString: () => React.Key | null | undefined;
                                }
                            ) => (
                                <span
                                role="presentation"
                                onClick={() =>
                                    handleHour(
                                    `17:${parseHour(
                                        (
                                        intervalOperation * Number(index)
                                        ).toString()
                                    )}`
                                    )
                                }
                                key={uuid()}
                                className={`${
                                    disabledHours.includes(
                                    `17:${parseHour(
                                        (
                                        intervalOperation * Number(index)
                                        ).toString()
                                    )}`
                                    )
                                    ? styles.unavailable
                                    : ""
                                } ${styles.hour} ${
                                    selectedDepartureHour ===
                                    `17:${parseHour(
                                    (intervalOperation * Number(index)).toString()
                                    )}`
                                    ? styles.selected
                                    : ""
                                }`}
                                >
                                {disabledHours.includes(
                                    `17:${parseHour(
                                    (intervalOperation * Number(index)).toString()
                                    )}`
                                ) ? (
                                    <div
                                    className={`d-flex flex-column align-items-center justify-content-center ${styles.vesselWrapper}`}
                                    >
                                    <span>
                                        {" "}
                                        17:
                                        {parseHour(
                                        (
                                            intervalOperation * Number(index)
                                        ).toString()
                                        )}
                                    </span>
                                    <div className="d-flex justify-content-center mt-1">
                                        <img
                                        alt="JetSki"
                                        src={Jet}
                                        className="jet"
                                        style={{
                                            height: 28,
                                        }}
                                        />
                                        <small className="ms-1">{`10'`}</small>
                                    </div>
                                    <span
                                        className={styles.vesselColor}
                                        style={{ backgroundColor: "yellow" }}
                                    />
                                    </div>
                                ) : (
                                    <span className="d-flex flex-column align-items-center">
                                    17:
                                    {parseHour(
                                        (
                                        intervalOperation * Number(index)
                                        ).toString()
                                    )}
                                    <span className={styles.availableHour}>
                                        Livre
                                    </span>
                                    </span>
                                )}
                                </span>
                            )
                            )}
                        </span>
                    </span>
                    </span>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <span className={styles.hourWrapper}>
                    <span className="d-flex w-100">
                        <span className="d-flex justify-content-between w-100">
                        {Array(60 / intervalOperation)
                            .fill(0)
                            ?.map(
                            (
                                option: any,
                                index: {
                                toString: () => React.Key | null | undefined;
                                }
                            ) => (
                                <span
                                role="presentation"
                                onClick={() =>
                                    handleHour(
                                    `18:${parseHour(
                                        (
                                        intervalOperation * Number(index)
                                        ).toString()
                                    )}`
                                    )
                                }
                                key={uuid()}
                                className={`${
                                    disabledHours.includes(
                                    `18:${parseHour(
                                        (
                                        intervalOperation * Number(index)
                                        ).toString()
                                    )}`
                                    )
                                    ? styles.unavailable
                                    : ""
                                } ${styles.hour} ${
                                    selectedDepartureHour ===
                                    `18:${parseHour(
                                    (intervalOperation * Number(index)).toString()
                                    )}`
                                    ? styles.selected
                                    : ""
                                }`}
                                >
                                {disabledHours.includes(
                                    `18:${parseHour(
                                    (intervalOperation * Number(index)).toString()
                                    )}`
                                ) ? (
                                    <div
                                    className={`d-flex flex-column align-items-center justify-content-center ${styles.vesselWrapper}`}
                                    >
                                    <span>
                                        {" "}
                                        18:
                                        {parseHour(
                                        (
                                            intervalOperation * Number(index)
                                        ).toString()
                                        )}
                                    </span>
                                    <div className="d-flex justify-content-center mt-1">
                                        <img
                                        alt="JetSki"
                                        src={Jet}
                                        className="jet"
                                        style={{
                                            height: 28,
                                        }}
                                        />
                                        <small className="ms-1">{`10'`}</small>
                                    </div>
                                    <span
                                        className={styles.vesselColor}
                                        style={{ backgroundColor: "yellow" }}
                                    />
                                    </div>
                                ) : (
                                    <span className="d-flex flex-column align-items-center">
                                    18:
                                    {parseHour(
                                        (
                                        intervalOperation * Number(index)
                                        ).toString()
                                    )}
                                    <span className={styles.availableHour}>
                                        Livre
                                    </span>
                                    </span>
                                )}
                                </span>
                            )
                            )}
                        </span>
                    </span>
                    <span className="d-flex w-100">
                        <span className="d-flex justify-content-between w-100">
                        {Array(60 / intervalOperation)
                            .fill(0)
                            ?.map(
                            (
                                option: any,
                                index: {
                                toString: () => React.Key | null | undefined;
                                }
                            ) => (
                                <span
                                role="presentation"
                                onClick={() =>
                                    handleHour(
                                    `19:${parseHour(
                                        (
                                        intervalOperation * Number(index)
                                        ).toString()
                                    )}`
                                    )
                                }
                                key={uuid()}
                                className={`${
                                    disabledHours.includes(
                                    `19:${parseHour(
                                        (
                                        intervalOperation * Number(index)
                                        ).toString()
                                    )}`
                                    )
                                    ? styles.unavailable
                                    : ""
                                } ${styles.hour} ${
                                    selectedDepartureHour ===
                                    `19:${parseHour(
                                    (intervalOperation * Number(index)).toString()
                                    )}`
                                    ? styles.selected
                                    : ""
                                }`}
                                >
                                {disabledHours.includes(
                                    `19:${parseHour(
                                    (intervalOperation * Number(index)).toString()
                                    )}`
                                ) ? (
                                    <div
                                    className={`d-flex flex-column align-items-center justify-content-center ${styles.vesselWrapper}`}
                                    >
                                    <span>
                                        {" "}
                                        19:
                                        {parseHour(
                                        (
                                            intervalOperation * Number(index)
                                        ).toString()
                                        )}
                                    </span>
                                    <div className="d-flex justify-content-center mt-1">
                                        <img
                                        alt="JetSki"
                                        src={Jet}
                                        className="jet"
                                        style={{
                                            height: 28,
                                        }}
                                        />
                                        <small className="ms-1">{`10'`}</small>
                                    </div>
                                    <span
                                        className={styles.vesselColor}
                                        style={{ backgroundColor: "yellow" }}
                                    />
                                    </div>
                                ) : (
                                    <span className="d-flex flex-column align-items-center">
                                    19:
                                    {parseHour(
                                        (
                                        intervalOperation * Number(index)
                                        ).toString()
                                    )}
                                    <span className={styles.availableHour}>
                                        Livre
                                    </span>
                                    </span>
                                )}
                                </span>
                            )
                            )}
                        </span>
                    </span>
                    <span className={`d-flex w-100 ${styles.grayLine}`}>
                        <span className="d-flex justify-content-between w-100">
                        {Array(60 / intervalOperation)
                            .fill(0)
                            ?.map(
                            (
                                option: any,
                                index: {
                                toString: () => React.Key | null | undefined;
                                }
                            ) => (
                                <span
                                role="presentation"
                                onClick={() =>
                                    handleHour(
                                    `20:${parseHour(
                                        (
                                        intervalOperation * Number(index)
                                        ).toString()
                                    )}`
                                    )
                                }
                                key={uuid()}
                                className={`${styles.grayLine} ${
                                    selectedDepartureHour ===
                                    `20:${parseHour(
                                    (intervalOperation * Number(index)).toString()
                                    )}`
                                    ? styles.selected
                                    : ""
                                } ${
                                    disabledHours.includes(
                                    `20:${parseHour(
                                        (
                                        intervalOperation * Number(index)
                                        ).toString()
                                    )}`
                                    )
                                    ? styles.unavailable
                                    : ""
                                } ${styles.hour}`}
                                >
                                {disabledHours.includes(
                                    `20:${parseHour(
                                    (intervalOperation * Number(index)).toString()
                                    )}`
                                ) ? (
                                    <div
                                    className={`d-flex flex-column align-items-center justify-content-center ${styles.vesselWrapper}`}
                                    >
                                    <span>
                                        {" "}
                                        20:
                                        {parseHour(
                                        (
                                            intervalOperation * Number(index)
                                        ).toString()
                                        )}
                                    </span>
                                    <div className="d-flex justify-content-center mt-1">
                                        <img
                                        alt="JetSki"
                                        src={Jet}
                                        className="jet"
                                        style={{
                                            height: 28,
                                        }}
                                        />
                                        <small className="ms-1">{`10'`}</small>
                                    </div>
                                    <span
                                        className={styles.vesselColor}
                                        style={{ backgroundColor: "yellow" }}
                                    />
                                    </div>
                                ) : (
                                    <span className="d-flex flex-column align-items-center">
                                    20:
                                    {parseHour(
                                        (
                                        intervalOperation * Number(index)
                                        ).toString()
                                    )}
                                    <span className={styles.availableHour}>
                                        Livre
                                    </span>
                                    </span>
                                )}
                                </span>
                            )
                            )}
                        </span>
                    </span>
                    <span className="d-flex w-100">
                        <span className="d-flex justify-content-between w-100">
                        {Array(60 / intervalOperation)
                            .fill(0)
                            ?.map(
                            (
                                option: any,
                                index: {
                                toString: () => React.Key | null | undefined;
                                }
                            ) => (
                                <span
                                role="presentation"
                                onClick={() =>
                                    handleHour(
                                    `21:${parseHour(
                                        (
                                        intervalOperation * Number(index)
                                        ).toString()
                                    )}`
                                    )
                                }
                                key={uuid()}
                                className={`${
                                    disabledHours.includes(
                                    `21:${parseHour(
                                        (
                                        intervalOperation * Number(index)
                                        ).toString()
                                    )}`
                                    )
                                    ? styles.unavailable
                                    : ""
                                } ${styles.hour} ${
                                    selectedDepartureHour ===
                                    `21:${parseHour(
                                    (intervalOperation * Number(index)).toString()
                                    )}`
                                    ? styles.selected
                                    : ""
                                }`}
                                >
                                {disabledHours.includes(
                                    `21:${parseHour(
                                    (intervalOperation * Number(index)).toString()
                                    )}`
                                ) ? (
                                    <div
                                    className={`d-flex flex-column align-items-center justify-content-center ${styles.vesselWrapper}`}
                                    >
                                    <span>
                                        {" "}
                                        21:
                                        {parseHour(
                                        (
                                            intervalOperation * Number(index)
                                        ).toString()
                                        )}
                                    </span>
                                    <div className="d-flex justify-content-center mt-1">
                                        <img
                                        alt="JetSki"
                                        src={Jet}
                                        className="jet"
                                        style={{
                                            height: 28,
                                        }}
                                        />
                                        <small className="ms-1">{`10'`}</small>
                                    </div>
                                    <span
                                        className={styles.vesselColor}
                                        style={{ backgroundColor: "yellow" }}
                                    />
                                    </div>
                                ) : (
                                    <span className="d-flex flex-column align-items-center">
                                    21:
                                    {parseHour(
                                        (
                                        intervalOperation * Number(index)
                                        ).toString()
                                    )}
                                    <span className={styles.availableHour}>
                                        Livre
                                    </span>
                                    </span>
                                )}
                                </span>
                            )
                            )}
                        </span>
                    </span>
                    <span className={`d-flex w-100 ${styles.grayLine}`}>
                        <span className="d-flex justify-content-between w-100">
                        {Array(60 / intervalOperation)
                            .fill(0)
                            ?.map(
                            (
                                option: any,
                                index: {
                                toString: () => React.Key | null | undefined;
                                }
                            ) => (
                                <span
                                role="presentation"
                                onClick={() =>
                                    handleHour(
                                    `22:${parseHour(
                                        (
                                        intervalOperation * Number(index)
                                        ).toString()
                                    )}`
                                    )
                                }
                                key={uuid()}
                                className={`${styles.grayLine} ${
                                    disabledHours.includes(
                                    `22:${parseHour(
                                        (
                                        intervalOperation * Number(index)
                                        ).toString()
                                    )}`
                                    )
                                    ? styles.unavailable
                                    : ""
                                } ${styles.hour} ${
                                    selectedDepartureHour ===
                                    `22:${parseHour(
                                    (intervalOperation * Number(index)).toString()
                                    )}`
                                    ? styles.selected
                                    : ""
                                }`}
                                >
                                {disabledHours.includes(
                                    `22:${parseHour(
                                    (intervalOperation * Number(index)).toString()
                                    )}`
                                ) ? (
                                    <div
                                    className={`d-flex flex-column align-items-center justify-content-center ${styles.vesselWrapper}`}
                                    >
                                    <span>
                                        {" "}
                                        22:
                                        {parseHour(
                                        (
                                            intervalOperation * Number(index)
                                        ).toString()
                                        )}
                                    </span>
                                    <div className="d-flex justify-content-center mt-1">
                                        <img
                                        alt="JetSki"
                                        src={Jet}
                                        className="jet"
                                        style={{
                                            height: 28,
                                        }}
                                        />
                                        <small className="ms-1">{`10'`}</small>
                                    </div>
                                    <span
                                        className={styles.vesselColor}
                                        style={{ backgroundColor: "yellow" }}
                                    />
                                    </div>
                                ) : (
                                    <span className="d-flex flex-column align-items-center">
                                    22:
                                    {parseHour(
                                        (
                                        intervalOperation * Number(index)
                                        ).toString()
                                    )}
                                    <span className={styles.availableHour}>
                                        Livre
                                    </span>
                                    </span>
                                )}
                                </span>
                            )
                            )}
                        </span>
                    </span>
                    </span>
                </TabPanel>
                </Box>
            </div>
            )}
        </Container>
        );
    }

    return (
        <div className="page">
            <OperationHeader title="Gestão Horária" description="" />
            {viewPage()}
        </div>
    );
}

export default HourlyOccupation;
