import { Col, Form, FormControl, FormGroup, InputGroup, Row } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";

import { useTranslateContext } from "../../contexts/translate.context";

import { isEmpty } from "../../utilities/validators";
import { toString } from '../../utilities/auxiliary-functions';
import AddressRecord from './address.record';
import PersonalDataRecord from './personal-data.record';
import PersonalDocumentRecord from './personal-document.record';
import { SocietyType } from "../../utilities/types";
import { cpfMask } from "../../utilities/masks";

export interface ISocietyRecordProps {
    title: any,
    isEdit: boolean,

    societyType?: any,
    created?: any,
    updated?: any,
    tratament?: any,
    fullname?: any,
    gender?: any,
    birth?: any,
    norm?: any,
    operator?: any,
    connection?: any,
    sms?: any,
    zap?: any,
    major?: any,
    nationality?: any,
    naturalness?: any,
    registrationPf?: any,
    registrationIdentity?: any,
    issuerIdentity?: any,
    stateIdentity?: any,
    expeditionIdentity?: any,
    driversLicense?: any,
    categoryDriversLicense?: any,
    validityDriversLicense?: any,
    sector?: any,
    office?: any,
    remuneration?: any,
    workdayFirst?: any,
    workdayStop?: any,
    workdayStart?: any,
    workdayLast?: any,
    zipcode?: any,
    state?: any,
    city?: any,
    district?: any,
    place?: any,
    habitation?: any,
    complement?: any,
    setSocietyType?: any,
    setCreated?: any,
    setUpdated?: any,
    setTratament?: any,
    setFullname?: any,
    setGender?: any,
    setBirth?: any,
    setNorm?: any,
    setOperator?: any,
    setConnection?: any,
    setSms?: any,
    setZap?: any,
    setMajor?: any,
    setNationality?: any,
    setNaturalness?: any,
    setRegistrationPf?: any,
    setRegistrationIdentity?: any,
    setIssuerIdentity?: any,
    setStateIdentity?: any,
    setExpeditionIdentity?: any,
    setDriversLicense?: any,
    setCategoryDriversLicense?: any,
    setValidityDriversLicense?: any
    setSector?: any,
    setOffice?: any,
    setRemuneration?: any,
    setWorkdayFirst?: any,
    setWorkdayStop?: any,
    setWorkdayStart?: any,
    setWorkdayLast?: any
    setZipcode?: any,
    setState?: any,
    setCity?: any,
    setDistrict?: any,
    setPlace?: any,
    setHabitation?: any,
    setComplement?: any,
    onClick_SearchPeople?: any,

    proofOfResidenceDocument: any,
    changeProofOfResidenceDocument: any,
    clickViewDocument: any
}

export const SocietyRecord: React.FC<ISocietyRecordProps> = (props: ISocietyRecordProps) => {

    const { display } = useTranslateContext();

    return (
        <>
            <Row>
                <Col sm="12">
                    <Row>
                        { !props.isEdit &&
                        <Col sm="3">
                            <FormGroup>
                                <Form.Label htmlFor="form-registration_pf">{display.label.registration_pf}</Form.Label>
                                <InputGroup className="mb-2">
                                    <FormControl type="text" id="form-registration_pf" name="registration_pf"
                                        placeholder={'Digite o CPF'} required readOnly={props.isEdit}
                                        value={toString(props.registrationPf)} onChange={e=>
                                        props.setRegistrationPf(cpfMask(e.target.value))}
                                        />
                                        <InputGroup.Text>
                                            <a href="/#" onClick={props.onClick_SearchPeople}>
                                                <FaSearch />
                                            </a>
                                        </InputGroup.Text>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        }
                        <Col>
                            <FormGroup>
                                <Form.Label htmlFor="form-society-type">{display.label.society_type}</Form.Label>
                                <Form.Select id="form-society-type" name="society-type" required
                                    value={toString(props.societyType)}
                                    onChange={(e: any) => props.setSocietyType(e.target.value)}
                                >
                                    { isEmpty(props.societyType) ? (
                                        <option value="">...</option>
                                    ) : null }
                                    { SocietyType.map((iterator: any, idx: any) => (
                                        <option key={idx} value={iterator.id}>{iterator.description}</option>)
                                    ).sort((a: any, b: any) => { 
                                        return a.props.children > b.props.children ? 1 : -1; 
                                    }) }
                                </Form.Select>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row><Col><hr /></Col></Row>
                    <fieldset>
                        <legend>{display.legend.general_data}</legend>
                        <PersonalDataRecord
                            title={props.title}
                            isEdit={props.isEdit}

                            tratament={props.tratament}
                            fullname={props.fullname}
                            gender={props.gender}
                            birth={props.birth}
                            norm={props.norm}
                            operator={props.operator}
                            connection={props.connection}
                            sms={props.sms}
                            zap={props.zap}
                            major={props.major}
                            nationality={props.nationality}
                            naturalness={props.naturalness}
                            setTratament={(value: any) => { props.setTratament(value); }}  
                            setFullname={(value: any) => { props.setFullname(value); }}  
                            setGender={(value: any) => { props.setGender(value); }}  
                            setBirth={(value: any) => { props.setBirth(value); }}  
                            setNorm={(value: any) => { props.setNorm(value); }}
                            setOperator={(value: any) => { props.setOperator(value); }}
                            setConnection={(value: any) => { props.setConnection(value); }}
                            setSms={(value: any) => { props.setSms(value); }}
                            setZap={(value: any) => { props.setZap(value); }}
                            setMajor={(value: any) => { props.setMajor(value); }}
                            setNationality={(value: any) => { props.setNationality(value); }} 
                            setNaturalness={(value: any) => { props.setNaturalness(value); }} 
                            />
                    </fieldset>
                    <fieldset>
                        <legend>{display.legend.documentation}</legend>
                        <PersonalDocumentRecord
                            title={props.title}
                            isEdit={props.isEdit}

                            registrationPf={props.registrationPf}
                            registrationIdentity={props.registrationIdentity}
                            issuerIdentity={props.issuerIdentity}
                            stateIdentity={props.stateIdentity}
                            expeditionIdentity={props.expeditionIdentity}
                            driversLicense={props.driversLicense}
                            categoryDriversLicense={props.categoryDriversLicense}
                            validityDriversLicense={props.validityDriversLicense}
                            setRegistrationPf={(value: any) => { props.setRegistrationPf(value); }}  
                            setRegistrationIdentity={(value: any) => { props.setRegistrationIdentity(value); }}  
                            setIssuerIdentity={(value: any) => { props.setIssuerIdentity(value); }}  
                            setStateIdentity={(value: any) => { props.setStateIdentity(value); }}  
                            setExpeditionIdentity={(value: any) => { props.setExpeditionIdentity(value); }}  
                            setDriversLicense={(value: any) => { props.setDriversLicense(value); }}  
                            setCategoryDriversLicense={(value: any) => { props.setCategoryDriversLicense(value); }} 
                            setValidityDriversLicense={(value: any) => { props.setValidityDriversLicense(value); }} 
                        />
                    </fieldset>
                    <fieldset>
                        <legend>{display.legend.address}</legend>
                        <AddressRecord 
                            title={props.title}
                            isEdit={props.isEdit}

                            zipcode={props.zipcode}
                            state={props.state}
                            city={props.city}
                            district={props.district}
                            place={props.place}
                            habitation={props.habitation}
                            complement={props.complement}
                            setZipcode={(value: any) => { props.setZipcode(value); }} 
                            setState={(value: any) => { props.setState(value); }} 
                            setCity={(value: any) => { props.setCity(value); }} 
                            setDistrict={(value: any) => { props.setDistrict(value); }} 
                            setPlace={(value: any) => { props.setPlace(value); }} 
                            setHabitation={(value: any) => { props.setHabitation(value); }} 
                            setComplement={(value: any) => { props.setComplement(value); }}

                            proofOfResidenceDocument={props.proofOfResidenceDocument}
                            changeProofOfResidenceDocument={props.changeProofOfResidenceDocument}
                            clickViewDocument={props.clickViewDocument}
                        />
                    </fieldset>
                </Col>
            </Row>
        </>
    );

}

export default SocietyRecord;
