import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { Button, Tooltip as TooltipMaterial } from '@mui/material';
import { useEffect, useState } from "react";
import { Col, Container, Form, FormControl, FormGroup, Modal, Row } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import { useAlertMessageContext } from "../../contexts/alert-message.context";
import { useConfirmMessageContext } from "../../contexts/confirm-message.context";
import cduService from "../../services/cdu.service";
import { toFloat, toInt, toLowerCase, toString, toStringFloat } from "../../utilities/auxiliary-functions";
import { CategoryEnum } from "../../utilities/constants";
import { floatMask, numberMask } from "../../utilities/masks";
import { AlertMessageEnum, CategoryType } from "../../utilities/types";
import { isEmpty } from "../../utilities/validators";
import CategoryFilter from "../filters/category.filter";
import StockFilter from "../filters/stock.filter";
import { TFilter } from '../../types/FilterType';
import { useTranslateContext } from '../../contexts/translate.context';

export interface IBudgetItemCadastreModalProps {
    stockItemNotInList: any,

    show: any,
    onClick_Close: any,
    onClick_Confirm: any
}

export const BudgetItemCadastreModal: React.FC<IBudgetItemCadastreModalProps> = (props: IBudgetItemCadastreModalProps) => {
    
    const alertContext = useAlertMessageContext();
    const confirmContext = useConfirmMessageContext();
    const { display } = useTranslateContext();

    const title : string = display.title.stockitem_cadastre;

    const [ invalidatedCadastreForm, setInvalidatedCadastreForm ] = useState(false);

    const [ showFilterCategory, setShowFilterCategory ] = useState(false);
    const categoryInList: any = []
    const [ showFilterProduct, setShowFilterProduct ] = useState(false);
    const stockInList: any = []
    const STOCK_DEFAULT = {
        stock: { 
            id: '0',
            product: { 
                code: '',
                unit: '',
                description: '',
                reference: '',
                categoryId: CategoryEnum.PRODUCT,
                category: { 
                    team: '2', 
                    group: '', 
                    subgroup: '', 
                    name: ''
                },
                purchase: '0'
            },
            sale: '0'
        },
        quantity: '1',
        amount: '0'
    } as any;

    const [ record, setRecord ] = useState(STOCK_DEFAULT);

    function setField(fieldname: string, value: any) {
        let data = {} as any;
        let exist = false;

        const keys = Object.keys(record);
        for( var i = 0; i < keys.length; i++) {
            data[keys[i]] = record[keys[i]];
        }
        
        if (fieldname === 'team') {
            data['stock'] = {} as any;
            data['stock']['product'] = {} as any;
            data['stock']['product']['category'] = {} as any;
            data['stock']['product']['category']['team'] = value;
            exist = true;
        }
        if (fieldname === 'product') {
            data['stock'] = {} as any;
            data['stock']['product'] = value;
            data['stock']['product']['sale'] = 0;
            exist = true;
        }
        if (fieldname === 'category') {
            data['stock'] = {} as any;
            data['stock']['product']['category'] = value;
            exist = true;
        }

        if (!exist)
            data[fieldname] = value;
        setRecord(data);
    }

    async function initialize() {
        setRecord(STOCK_DEFAULT);
        // setField('team', CategoryEnum.PRODUCT);
        // treatCategory(CategoryEnum.PRODUCT);
    }

    useEffect(() => {
        if (props.show)
            initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show]);

    async function isValidForm() {
        let result = true;
        let emptyRequired = false;

        if (isEmpty(record.stock.product.category.team)) {
            console.log('team')
            emptyRequired = true;
        }

        if (isEmpty(record.stock.product.categoryId)) {
            console.log('categoryId')
            emptyRequired = true;
        }

        if (isEmpty(record.stock.id)) {
            console.log('id')
            emptyRequired = true;
        }

        if (toInt(record.quantity) === 0) {
            console.log('quantity')
            emptyRequired = true;
        }

        if (emptyRequired) {
            await alertContext.show(AlertMessageEnum.FAIL, title, display.message.invalid.required);
            result = false;
        }

        setInvalidatedCadastreForm(!result);
        return result;
    }

    async function onClick_Confirm(event: any) {
        event.preventDefault();

        const isValid = await isValidForm();
        if (isValid) {
            setInvalidatedCadastreForm(false);
            const isConfirmed = await confirmContext.show(title, display.message.confirm_record);
            if (isConfirmed) {
                props.onClick_Confirm(event, record);
            }
        }
    }

    function onClick_FilterProduct(event: any) {
        event.preventDefault();
        setShowFilterProduct(true);
    }

    async function onClick_ConfirmFilterCategory(event: any, category: any) {
        event.preventDefault();
        setField('category', category);
    }

    async function onClick_ConfirmFilterStock(event: any, stock: any) {
        event.preventDefault();

        const amount = stock.sale * toInt(record.quantity);
        record.amount = toStringFloat(amount);
        setField('stock', stock);
    }

    function onChange_Team(event: any) {
        event.preventDefault();

        setField('team', Number(event.target.value));
        treatCategory(event.target.value);
    }

    function onChange_Quantity(event: any) {
        event.preventDefault();
        let amount = toFloat(record.stock.sale) * event.target.value;
        if (isNaN(amount))
            amount = 0;
        record.amount = toStringFloat(amount);
        setField('quantity', numberMask(event.target.value));
    }

    async function treatCategory(team: number) {
        const filter = {
            where: { team }
        } as TFilter;
        let categoryFilter = await cduService.categoryFilter(filter); // {count: 0, rows: []} as any; 
    }

    function viewCadastreForm() {
        return (
            <>
                <Modal
                    show={props.show && !(showFilterCategory || showFilterProduct)}
                    centered
                >
                    <Modal.Header>
                        <Modal.Title>{title}</Modal.Title>
                        <div className="card-actions float-right">
                            <FaTimes className="isClickable" size={22} onChange={props.onClick_Close} />
                        </div>
                    </Modal.Header>
                    <Modal.Body style={{ minHeight : 200 }}>
                        <Form className="form" id="form-cadastre-stock-item" validated={invalidatedCadastreForm}>
                            <Row>
                                <Form.Label column sm="2" className="ms-0" htmlFor="form-team">{display.label.group}</Form.Label>
                                <Col sm="12">
                                    <FormGroup as={Row} className="form-row-divider align-items-center">
                                        <Col sm="4" className="ps-0">
                                            <Form.Select id="form-team" name="team" 
                                                value={toString(record?.stock?.product?.category?.team)}
                                                onChange={(e) => onChange_Team(e)}
                                            >
                                                {isEmpty(record?.stock?.product?.category?.team) && <option value="">...</option>}
                                                {CategoryType.map((iterator: any, idx: number) => (
                                                    (iterator.id !== CategoryEnum.VESSEL) ? (
                                                        <option key={idx} value={iterator.id}>{display.label[toLowerCase(iterator.name)]}</option>
                                                    ) : null
                                                ))}
                                            </Form.Select>
                                        </Col>
                                        <Col sm="6">
                                            <TooltipMaterial className="isClickable" title="Adicionar itens" >
                                                <Button size="small" variant="contained" color="primary" className="ms-2" onClick={e => onClick_FilterProduct(e)} >
                                                    <PlaylistAddIcon  />
                                                </Button>
                                            </TooltipMaterial>
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12">
                                    <hr/>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12">
                                    <fieldset>
                                        <Row>
                                            <Col sm="6">
                                                <FormGroup>
                                                    <Form.Label htmlFor="form-reference">{display.label.reference}</Form.Label>
                                                    <FormControl type="text" id="form-reference" name="reference" placeholder={display.example.reference}
                                                        value={toString(record?.stock?.product && record?.stock?.product?.reference)}
                                                        onChange={e => setField('reference', e.target.value)}
                                                        readOnly
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col sm="6">
                                                <FormGroup>
                                                    <Form.Label htmlFor="form-code">{display.label.code}</Form.Label>
                                                    <FormControl type="text" id="form-code" name="code" placeholder={display.example.code}
                                                        value={toString(record?.stock?.product && record?.stock?.product?.code)}
                                                        onChange={e => setField('code', e.target.value)}
                                                        readOnly
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="9">
                                                <Form.Label htmlFor="form-description">{display.label.description}</Form.Label>
                                                <FormControl type="text" id="form-description" name="description" placeholder={display.example.description}
                                                    value={toString(record?.stock?.product && record?.stock?.product?.description)}
                                                    onChange={e => setField('description', e.target.value)}
                                                    readOnly
                                                />
                                            </Col>
                                            <Col sm="3">
                                                <Form.Label htmlFor="form-sale">{display.label.amount}</Form.Label>
                                                <FormControl type="text" id="form-sale" name="sale" placeholder={display.example.sale}
                                                    className="text-right"
                                                    value={floatMask(record?.stock && record?.stock?.sale)}
                                                    onChange={e => setField('sale', e.target.value)}
                                                    readOnly
                                                />
                                            </Col>
                                        </Row>
                                    </fieldset>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="6">
                                    <FormGroup as={Row} className="form-row-divider">
                                        <Form.Label column htmlFor="form-quantity">{display.label.quantity}</Form.Label>
                                        <Col>
                                            <FormControl type="number" id="form-quantity" name="quantity" placeholder={display.example.quantity}
                                                min={1}
                                                className="text-right"
                                                value={toString(record.quantity)}
                                                onChange={e => onChange_Quantity(e)}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col sm="6">
                                    <FormGroup as={Row} className="form-row-divider">
                                        <Form.Label column htmlFor="form-amount">{display.label.amount}</Form.Label>
                                        <Col>
                                            <FormControl type="text" id="form-amount" name="amount" placeholder={display.example.amount}
                                                className="text-right"
                                                value={toString(record.amount)}
                                                onChange={e => setField('amount', floatMask(e.target.value))}
                                                readOnly
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container>
                            <Row>
                                <Col sm="12" className="d-flex justify-content-between">
                                    <Button variant="contained" color="secondary" className="ms-2" onClick={ (e) => props.onClick_Close(e) } >{display.buttom.cancel}</Button>{' '}
                                    <Button variant="contained" color="primary" onClick={ (e) => onClick_Confirm(e) }  disabled={isEmpty(record)}>{display.buttom.confirm}</Button>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

    return (
        <>
        { viewCadastreForm() }
        <CategoryFilter 
            team={record?.stock?.product?.category?.team}
            categoryNotInList={categoryInList}
            show={showFilterCategory}

            onClick_Close={(event: any) => { 
                setShowFilterCategory(false);
            }}
            onClick_Confirm={(event: any, value: any) => { 
                setShowFilterCategory(false);
                onClick_ConfirmFilterCategory(event, value); 
            }}
        />
        <StockFilter 
            team={record?.stock?.product?.category?.team}
            category={record?.stock?.product?.category}

            stockNotInList={stockInList}
            show={showFilterProduct}

            onClick_Close={(event: any) => { 
                setShowFilterProduct(false);
            }}
            onClick_Confirm={(event: any, value: any) => { 
                setShowFilterProduct(false);
                onClick_ConfirmFilterStock(event, value); 
            }}
        />
        </>
    )
}

export default BudgetItemCadastreModal;
